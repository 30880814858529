<template>
    <div>
        <nl-table
            :columns.sync="columns"
            :checked-rows.sync="checkedRows"
            :left-buttons="buttons"
            :get-data="loadAsyncData"
            :actions="actions"
            :force-update="forceUpdated"
        >
            <template #statusSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small">
                    <option :value="null">
                        {{ $t('precommande.all') }}
                    </option>
                    <option value="A faire">
                        {{ $t('precommande.status.todo') }}
                    </option>
                    <option value="En rédaction">
                        {{ $t('precommande.status.inprogress') }}
                    </option>
                    <option value="Attente positionnement">
                        {{ $t('precommande.status.awaiting') }}
                    </option>
                    <option value="Fait">
                        {{ $t('precommande.status.done') }}
                    </option>
                </b-select>
            </template>

            <template #status="{ props }">
                <Status :status="props.row.status" content="" />
            </template>

            <template #url="{ props }">
                <span v-if="props.row.statut === 'termine'">{{ props.row.url }}</span>
                <span v-else-if="props.row.statut === 'annule' || props.row.statut === 'refuse'">{{
                    $t('status.canceled')
                }}</span>
                <span v-else>{{ $t('tracking_order.availablewhenpublished') }}</span>
            </template>

            <template
                #keyword="{
                    props: {
                        row: { keyword }
                    }
                }"
            >
                <a role="button" @click="goToSearch(keyword)">
                    {{ keyword }}
                </a>
            </template>

            <template #secondaryKeywords="{ props }">
                <div
                    class="d-flex flex-wrap justify-content-md-start justify-content-end"
                    style="gap: 0.5rem; min-width: 200px"
                >
                    <b-tag v-for="keyword in props.row.secondaryKeywords" :key="keyword.name" type="is-warning">
                        <a role="button" @click="goToSearch(keyword.name)">
                            {{ keyword.name }}
                        </a>
                    </b-tag>
                </div>
            </template>

            <template #localeSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small">
                    <option :value="null">
                        {{ $t('precommande.all') }}
                    </option>
                    <option value="fr">Français</option>
                    <option value="en">English</option>
                </b-select>
            </template>

            <template #locale="{ props }">
                <flag :iso="'en' === props.row.locale ? 'us' : 'fr'" />
            </template>

            <template #project="{ props }">
                <span>{{ findProject(props.row.project_id) }}</span>
            </template>

            <template #created_at="{ props }">
                {{ moment(props.row.created_at).format('DD/MM/YYYY') }}
            </template>

            <template #date_expiration="{ props }">
                <span id="date_expiration">{{ moment(props.row.date_expiration).locale('fr').fromNow() }}</span>
            </template>
        </nl-table>
        <Toaster ref="toaster" />
    </div>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable'
    import { mapActions, mapMutations, mapState } from 'vuex'
    import Toaster from '@/components/UI/Toaster.vue'

    export default {
        name: 'PreordersTable',
        components: {
            Toaster,
            NlTable
        },
        props: {
            type: {
                type: String,
                required: true
            },
            status: {
                type: String,
                required: false,
                default: null
            }
        },

        data() {
            return {
                selectedProject: null,
                editProject: null,
                appUrl: process.env.LARAVEL_APP_URL,

                forceUpdated: false,

                checkedRows: [],
                buttons: [
                    {
                        label: this.$t('precommande.add'),
                        icon: 'plus-circle',
                        type: 'is-warning',
                        action: () => this.$router.push('/client/preorder/new')
                    }
                ],
                columns: [
                    {
                        label: this.$t('precommande.reference.short'),
                        field: 'id',
                        centered: true,
                        width: '9rem',
                        sortable: true,
                        searchable: true,
                        custom: false,
                        customSearch: false
                    },
                    {
                        label: this.$t('global.status'),
                        field: 'status',
                        centered: true,
                        width: '9rem',
                        sortable: true,
                        searchable: true,
                        custom: true,
                        customSearch: true,
                        hide: !this.type
                    },
                    {
                        label: this.$t('precommande.columns.locale'),
                        field: 'locale',
                        centered: true,
                        width: '9rem',
                        sortable: true,
                        searchable: true,
                        custom: true,
                        customSearch: true
                    },
                    {
                        label: this.$t('precommande.columns.keyword'),
                        field: 'keyword',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        custom: true
                    },
                    {
                        label: this.$t('precommande.columns.secondarykeyword'),
                        field: 'secondaryKeywords',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        custom: true
                    },
                    {
                        label: this.$t('precommande.columns.brief'),
                        field: 'brief',
                        centered: true,
                        sortable: true,
                        visible: false,
                        searchable: true
                    },
                    {
                        label: this.$t('global.project'),
                        field: 'project',
                        centered: true,
                        sortable: false,
                        searchable: false,
                        custom: true
                    },
                    {
                        label: this.$t('precommande.columns.nbArticle'),
                        field: 'nbArticle',
                        centered: true,
                        sortable: true,
                        searchable: true
                    },
                    {
                        label: this.$t('precommande.columns.createdat'),
                        field: 'created_at',
                        centered: true,
                        sortable: true,
                        searchable: false,
                        custom: true
                    },
                    {
                        label: this.$t('precommande.columns.expires'),
                        field: 'date_expiration',
                        centered: true,
                        sortable: true,
                        searchable: false,
                        custom: true
                    }
                ],
                actions: [
                    {
                        label: 'Relancer la précommande',
                        action: () => {
                            this.updatePreorders('relaunch', this.checkedRows)
                        }
                    },
                    {
                        label: 'Arrêter la précommande',
                        action: () => {
                            this.updatePreorders('stop', this.checkedRows)
                        }
                    }
                ]
            }
        },
        computed: {
            ...mapState('client_preorders', {
                preorders: state => state.preorders,
                config: state => state.preordersConfig
            }),
            ...mapState('project_selector', {
                projects: state => state.projects
            }),
            ...mapState({
                project: 'project'
            })
        },
        watch: {
            project() {
                this.forceUpdated = !this.forceUpdated
            }
        },
        methods: {
            ...mapActions('client_preorders', {
                getPreorders: 'list'
            }),
            ...mapMutations('search', {
                setFilters: 'setFilters'
            }),
            loadAsyncData(params) {
                /* this.$emit('updateTabs')*/
                return this.getPreorders({
                    status: this.type,
                    project: this.project.id,
                    params
                })
            },
            findProject(id) {
                let project = this.projects.find(project => project.id === id)
                return project ? project.name : ''
            },
            updatePreorders(action, checkedRows) {
                let ids = checkedRows.map(row => row.id)
                this.axios
                    .put('/preorders', {
                        action: action,
                        ids: ids
                    })
                    .then(() => {
                        this.$refs.toaster.fire(
                            'success',
                            this.$t('commande.validation.front.saveddetails'),
                            this.$t('commande.validation.front.saved')
                        )
                    })
                    .catch(error => {
                        this.$refs.toaster.fire('error', error.message, this.$t('global.new-error'))
                    })
                    .finally(() => {
                        this.forceUpdated = !this.forceUpdated
                    })
            },
            updateTabs() {
                this.$emit('updateTabs')
            },
            goToSearch(keyword) {
                const route = this.$router.resolve({
                    name: 'SearchByKeywords',
                    query: { keyword }
                })
                window.open(route.href, '_blank')
            }
        }
    }
</script>
