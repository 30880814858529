var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('hr'),_c('div',{staticClass:"d-flex justify-content-between align-items-center cursor-pointer",on:{"click":() => {
                _vm.visibleTab = !_vm.visibleTab
            }}},[_vm._m(0),(!_vm.visibleTab)?_c('i',{staticClass:"fas fa-caret-down fa-lg"}):_vm._e(),(_vm.visibleTab)?_c('i',{staticClass:"fas fa-caret-up fa-lg"}):_vm._e()]),_c('collapse-transition',{attrs:{"duration":500,"mode":"out-in","origin":"center"}},[(_vm.visibleTab)?_c('div',{staticClass:"d-lg-flex"},[_c('div',{staticClass:"col-lg-6"},[_c('h4',{staticClass:"text-center"},[_vm._v("Top")]),_c('b-table',{staticClass:"table-striped",attrs:{"data":_vm.top}},[_c('b-table-column',{attrs:{"label":"ID","field":"Top"},scopedSlots:_vm._u([{key:"default",fn:function(props){return _c('div',{},[_vm._v(" "+_vm._s(props.row.id)+" ")])}}],null,false,3871329040)}),_c('b-table-column',{attrs:{"label":"NDD","field":"Site"},scopedSlots:_vm._u([{key:"default",fn:function(props){return _c('div',{},[_vm._v(" "+_vm._s(props.row.ndd)+" ")])}}],null,false,1119340147)}),_c('b-table-column',{attrs:{"label":"Commandes","field":"Commandes","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return _c('div',{},[_vm._v(" "+_vm._s(props.row.count)+" "),_c('span',{class:_vm.computeVariation(props.row.count, props.row.count_variation) > 0
                                        ? 'text-success'
                                        : 'text-danger'},[(_vm.computeVariation(props.row.count, props.row.count_variation) > 0)?_c('i',{staticClass:"fas fa-level-up-alt"}):_c('i',{staticClass:"fas fa-level-down-alt"}),_vm._v(_vm._s(_vm.computeVariation(props.row.count, props.row.count_variation)))])])}}],null,false,1297984663)}),_c('b-table-column',{attrs:{"label":"CA","centered":"","field":"CA"},scopedSlots:_vm._u([{key:"default",fn:function(props){return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(props.row.sum))+" "),_c('span',{class:_vm.computeVariation(props.row.sum, props.row.sum_variation) > 0
                                        ? 'text-success'
                                        : 'text-danger'},[(_vm.computeVariation(props.row.sum, props.row.sum_variation) > 0)?_c('i',{staticClass:"fas fa-level-up-alt"}):_c('i',{staticClass:"fas fa-level-down-alt"}),_vm._v(_vm._s(_vm.computeVariation(props.row.sum, props.row.sum_variation))+" €")])])}}],null,false,1425467965)})],1)],1),_c('div',{staticClass:"vl"}),_c('div',{staticClass:"col-lg-6"},[_c('h4',{staticClass:"text-center"},[_vm._v("Flop")]),_c('b-table',{staticClass:"table-striped",attrs:{"data":_vm.flop}},[_c('b-table-column',{attrs:{"label":"ID","field":"Top"},scopedSlots:_vm._u([{key:"default",fn:function(props){return _c('div',{},[_vm._v(" "+_vm._s(props.row.id)+" ")])}}],null,false,3871329040)}),_c('b-table-column',{attrs:{"label":"NDD","field":"Site"},scopedSlots:_vm._u([{key:"default",fn:function(props){return _c('div',{},[_vm._v(" "+_vm._s(props.row.ndd)+" ")])}}],null,false,1119340147)}),_c('b-table-column',{attrs:{"label":"Commandes","field":"Commandes","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return _c('div',{},[_vm._v(" "+_vm._s(props.row.count)+" "),_c('span',{class:_vm.computeVariation(props.row.count, props.row.count_variation) > 0
                                        ? 'text-success'
                                        : 'text-danger'},[(_vm.computeVariation(props.row.count, props.row.count_variation) > 0)?_c('i',{staticClass:"fas fa-level-up-alt"}):_c('i',{staticClass:"fas fa-level-down-alt"}),_vm._v(_vm._s(_vm.computeVariation(props.row.count, props.row.count_variation)))])])}}],null,false,1297984663)}),_c('b-table-column',{attrs:{"label":"CA","centered":"","field":"CA"},scopedSlots:_vm._u([{key:"default",fn:function(props){return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(props.row.sum))+" "),_c('span',{class:_vm.computeVariation(props.row.sum, props.row.sum_variation) > 0
                                        ? 'text-success'
                                        : 'text-danger'},[(_vm.computeVariation(props.row.sum, props.row.sum_variation) > 0)?_c('i',{staticClass:"fas fa-level-up-alt"}):_c('i',{staticClass:"fas fa-level-down-alt"}),_vm._v(_vm._s(_vm.computeVariation(props.row.sum, props.row.sum_variation))+" €")])])}}],null,false,1425467965)})],1)],1)]):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('i',{staticClass:"fas fa-star mr-2"}),_vm._v("Top / Flop des sites")])
}]

export { render, staticRenderFns }