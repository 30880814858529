export default {
    setUser(state, data) {
        state.user = data
    },

    setGains(state, data) {
        state.gains = data
    },

    setWithdrawalThisMonth(state, data) {
        state.withdrawal_this_month = data
    },

    setFailedOrder(state, data) {
        state.failed_order = data
    },

    setWithdrawGains(state, data) {
        state.withdrawGains = data
    },

    setBonus(state, data) {
        state.bonus = data
    }
}
