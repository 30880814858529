<template>
    <b-table
        :data="orders"
        :debounce-search="200"
        :hoverable="true"
        :mobile-cards="true"
        :paginated="isPaginated"
        :pagination-simple="isPaginated"
        :per-page="perPage"
        :sticky-header="true"
        :striped="true"
        default-sort="created_at"
        default-sort-direction="desc"
    >
        <b-table-column field="related_order.status" label="Terminée" sortable width="0">
            <div slot="default" slot-scope="props">
                <p v-if="props.row.related_order">
                    <span
                        v-if="props.row.related_order.status === 'termine' || props.row.related_order.status === 'done'"
                    >
                        <i class="fas fa-check-circle text-success" />
                    </span>
                    <span v-else>
                        <i class="fas fa-times text-danger" />
                    </span>
                </p>
            </div>
        </b-table-column>
        <b-table-column field="entity_id" label="Numéro commande" sortable>
            <div slot="default" slot-scope="props">
                <div class="d-flex">
                    <div class="pr-2">
                        <div v-if="props.row.entity_type === 1 && props.row.entity_id" class="tag is-warning">
                            Commande
                        </div>
                        <div v-if="props.row.entity_type === 2" class="tag is-info">Précommande</div>
                    </div>
                    <div>
                        <p v-if="props.row.entity_id" class="font-weight-bold">#{{ props.row.entity_id }}</p>
                        <p v-else class="font-weight-bold">-</p>
                    </div>
                </div>
                <span v-if="props.row.status === 'manual'" class="text-muted"> Créé manuellement par un admin </span>
            </div>
        </b-table-column>

        <b-table-column field="words" label="Nombre de mots rédigés" sortable>
            <div slot="default" slot-scope="props">
                <p>{{ props.row.words }}</p>
            </div>
        </b-table-column>

        <b-table-column field="words" label="Prix" sortable numeric>
            <div slot="default" slot-scope="props">
                <p>{{ ((props.row.price * (props.row.words / 100)) / 100).toFixed(2) }}€</p>
            </div>
        </b-table-column>

        <b-table-column field="created_at" label="Date de réalisation" sortable>
            <div slot="default" slot-scope="props">
                <p>{{ moment(props.row.created_at).format('L') }}</p>
            </div>
        </b-table-column>

        <b-table-column field="content_lang" label="Langue" sortable>
            <div slot="default" slot-scope="props">
                <template v-if="props.row.related_order">
                    <p>
                        {{ props.row.related_order.lang.startsWith('fr') ? '🇫🇷' : '🇺🇸' }}
                    </p>
                </template>
            </div>
        </b-table-column>
    </b-table>
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'TabRedactorSummaryOrders',

        filters: {
            date(value) {
                return moment(value).format('DD-MM-YYYY')
            }
        },

        props: ['paginatedData'],

        data() {
            return {
                orders: [],

                isPaginated: true,
                perPage: 15
            }
        },

        watch: {
            paginatedData(newValue) {
                this.orders = newValue
                this.checkedRows = []
            }
        },

        created() {
            this.orders = this.paginatedData && this.paginatedData.length > 0 ? this.paginatedData : []
        },

        mounted() {},

        methods: {}
    }
</script>
