<template>
    <div>
        <form class="" @submit.prevent="onSubmit">
            <nl-modal :title="'Brief ' + type" :open-modal="show" @hide="$emit('discard')">
                <div class="form-group">
                    <textarea v-model="value" class="form-control" rows="10" />
                </div>

                <template v-slot:footer>
                    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-secondary">Save changes</button>
                </template>
            </nl-modal>
        </form>
    </div>
</template>

<script>
    import NlModal from '../UI/NlModal'

    export default {
        components: { NlModal },
        props: {
            type: {
                required: true
            },
            brief: {
                required: true
            },
            show: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                value: null
            }
        },
        watch: {
            brief(brief) {
                this.value = brief
            }
        },
        mounted() {
            this.value = this.brief
        },
        methods: {
            onSubmit: function () {
                this.$emit('save', this.value)
            }
        }
    }
</script>

<style scoped></style>
