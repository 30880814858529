<template>
    <nl-modal ref="modal" title="Lancer l'appel à crédits" :add-legacy-events="false" position="center" size="md">
        <div class="d-flex justify-content-center flex-column">
            <h5 class="text-center">
                Êtes vous sur de vouloir lancer l'appel à crédits pour le mois de
                {{ moment(selectedMonth, 'YYYY-MM').format('MMMM') }} ?
            </h5>
            <b-field label="Mois" horizontal>
                <b-select v-model="selectedMonth">
                    <option v-for="month in getMonths()" :value="month.format('YYYY-MM')" :key="month.unix()">
                        {{ month.format('MMMM YYYY') }}
                    </option>
                </b-select>
            </b-field>
        </div>
        <template #footer>
            <b-button type="is-primary" @click="hide"> Annuler</b-button>
            <b-button type="is-warning" @click="send"> Valider</b-button>
        </template>
    </nl-modal>
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'CampaignCreditModal',
        data() {
            return {
                nextMonth: moment().add(1, 'month'),
                selectedMonth: null
            }
        },
        created() {
            this.selectedMonth = this.nextMonth.format('YYYY-MM')
        },
        methods: {
            getMonths() {
                const months = []
                for (let i = -3; i <= 3; i++) {
                    months.push(moment().add(i, 'months'))
                }

                return months
            },
            show() {
                this.$refs.modal.show()
            },
            hide() {
                this.$refs.modal.hide()
            },
            send() {
                this.axios
                    .post('/admin/accompagnements/quotation', { month: this.selectedMonth })
                    .then(response => {
                        this.$store.dispatch('toast', {
                            title: 'Création des ordres de paiement',
                            type: 'success'
                        })
                        this.$emit('ordersCreated')
                    })
                    .catch(error => {
                        this.$store.dispatch('toast', {
                            title: 'Une erreur est survenue pendant la création des ordres de paiement',
                            type: 'error'
                        })
                    })
                    .finally(() => {
                        this.hide()
                    })
            }
        }
    }
</script>

<style scoped></style>