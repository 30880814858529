<template>
    <div class="row px-xl-5 mb-xl-5">
        <div class="p-2 col-md-12 mt-xl-5">
            <div class="card">
                <div class="card-header row px-4 py-3 text-light">
                    <div class="col-md-8">
                        <h3 class="mb-0">
                            {{ $t('profil.orders.submenu') }}
                        </h3>
                    </div>
                </div>

                <div class="card-body px-4 py-0">
                    <v-form @submit="save">
                        <div class="row">
                            <div class="col-md-12 col-lg-6">
                                <v-textfield
                                    v-model="profile.email_commande_autre"
                                    class-label="form-control-label-profil"
                                    :label="$t('profil.orders.add-receiver')"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-6">
                                <v-checkbox v-model="profile.recevoir_email_commande">
                                    {{ $t('profil.orders.send-me-a-copy') }}
                                </v-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-auto mr-auto">&nbsp;</div>
                            <div class="col-auto">
                                <div class="row mb-0">
                                    <button class="btn btn-secondary mr-2" :disabled="loading">
                                        {{ $t('global.save') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <b-loading v-model="loading" :is-full-page="false" />
                    </v-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AbstractProfile from './AbstractProfile.vue'
    import FileUpload from '@/components/UI/Upload/FileUpload.vue'

    export default {
        name: 'AccountOrderMail',
        components: {
            FileUpload
        },
        mixins: [AbstractProfile],
        data: () => ({
            attestationType: null
        }),
        computed: {
            attestationsTypes() {
                if (this.profile.sum_withdraw >= 5000 && this.isFrench) {
                    return [{ text: this.$t('user.attestation.urssaf'), id: 'urssaf' }]
                } else if (this.isFrench) {
                    return [
                        { text: this.$t('user.attestation.urssaf'), id: 'urssaf' },
                        { text: this.$t('user.attestation.kbis'), id: 'kbis' },
                        { text: this.$t('user.attestation.insee'), id: 'insee' }
                    ]
                } else {
                    return [{ text: this.$t('user.attestation.others'), id: 'other' }]
                }
            },
            needNewAttestation() {
                if (this.profile.attestations && this.profile.attestations.length) {
                    const last = this.profile.attestations[this.profile.attestations.length - 1]
                    if (last.status === 'expired' || last.status === 'refused') {
                        return true
                    }
                    return false
                }
                return true
            },
            attestationStatus() {
                if (this.profile.attestations.length) {
                    const last = this.profile.attestations[this.profile.attestations.length - 1]
                    if (last.status === 'new') {
                        return 'En cours de validation'
                    }
                    if (last.status === 'validate') {
                        return 'Valide'
                    }
                    if (last.status === 'expired') {
                        return 'Expiré'
                    }
                }
                return null
            },
            isAttestationNeeded() {
                if (this.profile.country === 'FR') {
                    return true
                }
                return false
            }
        },
        watch: {
            profile() {
                if (this.profile.sum_withdraw >= 5000) {
                    this.attestationType = 'urssaf'
                }
            }
        },
        created() {},
        methods: {
            save: function () {
                this.loading = true

                this.$emit('save', {
                    callback: () => {
                        this.loading = false
                    }
                })
            },
            uploadAttestation(file) {
                if (file[0] && file[0].file && this.attestationType) {
                    this.savingAttestation = true
                    this.saveAttestation({
                        url: file[0].file.url,
                        type: this.attestationType
                    })
                        .then(data => {
                            this.$emit('onFileUploadSuccess', data.message)
                        })
                        .catch(error => {
                            if (error.data && error.data.message) {
                                this.$emit('onFileUploadError', error.data.message)
                            } else {
                                let msg = []
                                if (error.data.errors) {
                                    for (const [key, value] of Object.entries(error.data.errors)) {
                                        value.forEach(txt => msg.push(txt))
                                    }
                                }

                                this.$emit('onFileUploadError', msg)
                            }
                        })
                }
            },
            uploadError: function (error) {
                console.log(error)
                let msg = []
                if (error.data.errors) {
                    for (const [key, value] of Object.entries(error.data.errors)) {
                        value.forEach(txt => msg.push(txt))
                    }
                }

                this.$emit('onFileUploadError', msg.join('<br>'))
            }
        }
    }
</script>

<style>
    .warning-attestaion {
        font-size: 14px;
        color: orange;
    }
</style>
