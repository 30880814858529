<template>
    <b-autocomplete
        class="autocomplete"
        v-model="input"
        @select="v => $emit('input', v?.id)"
        :data="filteredThematics"
        :placeholder="$t('global.theme')"
        :size="size"
        expanded
        clearable
        append-to-body
        field="localized_translations.name"
        group-field="localized_translations.name"
        group-options="children"
        open-on-focus
    />
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'NlThematicFilter',
        props: {
            value: {
                type: Number
            },
            size: {
                type: String,
                required: false,
                default: ''
            }
        },
        data() {
            return {
                loading: true,
                input: ''
            }
        },
        computed: {
            ...mapState('group', {
                groups: 'groups'
            }),

            filteredThematics() {
                return this.computedGroups.filter(option => {
                    return (
                        option.localized_translations.name
                            .toString()
                            .toLowerCase()
                            .indexOf(this.input?.toLowerCase()) >= 0
                    )
                })
            },

            computedGroups() {
                return this.groups.map(group => {
                    return {
                        ...group,
                        children: [
                            {
                                id: group.id,
                                localized_translations: group.localized_translations
                            },
                            ...group.children
                        ]
                    }
                })
            }
        },
        methods: {
            ...mapActions('group', {
                getGroups: 'getAll'
            })
        },
        async created() {
            if (!this.groups?.length) {
                await this.getGroups()
            }

            this.loading = false
        }
    }
</script>

<style>
    .dropdown-item {
        padding: 0.25rem 0.5rem !important;
    }
</style>
