<template>
    <div class="d-flex align-items-start">
        <select
            name=""
            :class="[...inputClass, 'align-self-center']"
            :value="hour"
            @change="updateDuration($event.target.value, minute)"
        >
            <option v-for="(h, k) in hours" :key="k" :value="k">
                {{ h }}
            </option>
        </select>
        <div class="pl-1 pr-4 align-self-center">h</div>
        <select
            name=""
            :class="[...inputClass, 'align-self-center']"
            :value="minute"
            @change="updateDuration(hour, $event.target.value)"
        >
            <option v-for="(m, k) in minutes" :key="k" :value="k">
                {{ m }}
            </option>
        </select>
        <div class="pl-1 pr-4 align-self-center">m</div>
    </div>
</template>

<script>
    export default {
        props: {
            model: {
                type: Number
            },
            inputClass: {
                default: null
            }
        },
        data() {
            return {
                hour: 0,
                minute: 0
            }
        },
        computed: {
            hours() {
                let hours = []
                for (let i = 0; i <= 10; i++) {
                    hours[i] = String(i).padStart(2, '0')
                }
                return hours
            },
            minutes() {
                return [0, 15, 30, 45].reduce((array, minute) => {
                    array[minute] = String(minute).padStart(2, '0')
                    return array
                }, {})
            }
        },
        watch: {
            model(value) {
                this.setFormattedDuration(value)
            }
        },
        mounted() {
            this.setFormattedDuration(this.model)
        },
        methods: {
            updateDuration(hour, minute) {
                this.hour = hour
                this.minute = minute
                this.durationUpdated()
            },
            setFormattedDuration(duration) {
                let hours = duration / 60
                this.hour = Math.floor(hours)
                let minutes = (hours - this.hour) * 60
                this.minute = Math.round(minutes)
            },
            durationUpdated() {
                this.$emit('input', parseInt(this.hour) * 60 + parseInt(this.minute))
            }
        }
    }
</script>

<style scoped></style>
