<template>
    <div>
        <b-loading v-model="globalLoader" />
        <b-table
            :sticky-header="false"
            :data="earnings"
            :mobile-cards="true"
            :debounce-search="200"
            :row-class="
                (row, index) =>
                    row.status == 'valider' && row.envoi_reglement && !showValidated
                        ? 'is-hidden'
                        : row.status == 'valider' && row.envoi_reglement
                        ? 'is-paid'
                        : ''
            "
        >
            <b-table-column field="id" sortable :searchable="isSearchable" label="#" centered>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Réf..."
                        />
                    </div>
                </div>

                <template v-slot="props"> #{{ props.row.id }}</template>
            </b-table-column>

            <b-table-column field="created_at" label="Date" sortable :searchable="isSearchable" width="10%">
                <div slot="default" slot-scope="props">
                    {{ moment(props.row.created_at).format('DD/MM/YYYY') }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Date..."
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="user"
                sortable
                :searchable="isSearchable"
                :custom-search="searchByUser"
                :label="$t('withdrawal-winnings.user')"
                centered
            >
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('withdrawal-winnings.user') + '...'"
                        />
                    </div>
                </div>

                <div slot="default" slot-scope="props">
                    <p class="mb-1">
                        {{ props.row.user ? props.row.user.firstname : '' }}
                        {{ props.row.user ? props.row.user.lastname : '' }}
                        <span v-if="props.row.user && props.row.user.company">/ {{ props.row.user.company }}</span>
                        <LoginAs v-if="props.row.user" :user-id="props.row.user.id" class="ml-1" />
                    </p>
                    <p class="mb-1">
                        {{ props.row.user ? props.row.user.email : '' }}
                    </p>
                </div>
            </b-table-column>

            <b-table-column
                field="info_paiement"
                sortable
                :searchable="isSearchable"
                :label="$t('withdrawal-winnings.payment-info')"
                centered
            >
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('withdrawal-winnings.payment-info') + '...'"
                        />
                    </div>
                </div>

                <div slot="default" slot-scope="props">
                    <small>{{ props.row.info_paiement }}</small>
                </div>
            </b-table-column>

            <!--            <b-table-column-->
            <!--                field="generation"-->
            <!--                sortable-->
            <!--                :searchable="isSearchable"-->
            <!--                :label="$t('withdrawal-winnings.type')"-->
            <!--                centered-->
            <!--            >-->
            <!--                <div slot="searchable" slot-scope="props">-->
            <!--                    <div class="w-100">-->
            <!--                        <input-->
            <!--                            v-model="props.filters[props.column.field]"-->
            <!--                            type="text"-->
            <!--                            class="form-control form-control-sm"-->
            <!--                            :placeholder="$t('withdrawal-winnings.type') + '...'"-->
            <!--                        />-->
            <!--                    </div>-->
            <!--                </div>-->

            <!--                <div slot="default" slot-scope="props">-->
            <!--                    <span v-if="props.row.generation === 'auto'" class="tag is-primary">Autogénéré</span>-->
            <!--                    <span v-else class="tag is-warning">Manuel</span>-->
            <!--                </div>-->
            <!--            </b-table-column>-->


            <b-table-column
                field="numero"
                sortable
                :searchable="isSearchable"
                label="Facture"
                centered
            >
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Numéro..."
                        />
                    </div>
                </div>

                <div slot="default" slot-scope="props">
                    {{ props.row.numero }}

                    <span class="tag is-success ml-2">
                        <a class="downloadPDF" @click="download(props.row)">
                            <i class="fas fa-file-download text-white"/>
                        </a>
                    </span>
                </div>
            </b-table-column>

            <b-table-column
                field="montant_ht"
                sortable
                :searchable="isSearchable"
                :label="$t('withdrawal-winnings.amount-ht')"
                centered
            >
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('withdrawal-winnings.amount-ht') + '...'"
                        />
                    </div>
                </div>

                <div slot="default" slot-scope="props">{{ props.row.montant_ht }}€</div>
            </b-table-column>

            <b-table-column
                field="montant_tva"
                sortable
                :searchable="isSearchable"
                :custom-search="searchByTotalAmount"
                :label="$t('withdrawal-winnings.amount-ttc')"
                centered
            >
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('withdrawal-winnings.amount-ttc') + '...'"
                        />
                    </div>
                </div>

                <div slot="default" slot-scope="props">
                    {{ (parseFloat(props.row.montant_ht) + parseFloat(props.row.montant_tva)).toFixed(2) }}€
                </div>
            </b-table-column>

            <b-table-column
                field="nb_erreur"
                sortable
                :searchable="isSearchable"
                :label="$t('withdrawal-winnings.validation-error')"
                centered
            >
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('withdrawal-winnings.validation-error') + '...'"
                        />
                    </div>
                </div>

                <div slot="default" slot-scope="props">
                    <span
                        v-if="props.row.nb_erreur > 0 && props.row.validation_error === 'a_valider'"
                        class="tag is-danger"
                        >{{ props.row.nb_erreur }} erreur(s)</span
                    >
                    <span v-else class="tag is-success">Validé {{ props.row.nb_erreur }} erreur(s)</span>
                </div>
            </b-table-column>

            <b-table-column
                field="status"
                sortable
                :searchable="isSearchable"
                :label="$t('withdrawal-winnings.validation-compta')"
                centered
            >
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('withdrawal-winnings.validation-compta') + '...'"
                        />
                    </div>
                </div>

                <div slot="default" slot-scope="props">
                    <span v-if="props.row.status === 'a_valider'" class="tag is-info">À valider</span>
                    <span v-else class="tag is-success">Validé</span>
                </div>
            </b-table-column>

            <b-table-column
                field="date_validation"
                sortable
                :searchable="isSearchable"
                :custom-search="searchByDateValidation"
                :label="$t('withdrawal-winnings.validation-date')"
                centered
            >
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('withdrawal-winnings.validation-date') + '...'"
                        />
                    </div>
                </div>

                <div v-if="props.row.date_validation" slot="default" slot-scope="props">
                    {{ moment(props.row.date_validation).format('DD/MM/YYYY') }}
                </div>
            </b-table-column>

            <b-table-column
                field="date_reglement_prevue"
                sortable
                :searchable="isSearchable"
                :custom-search="searchByDateReglementPrevue"
                :label="$t('withdrawal-winnings.payment-date')"
                centered
            >
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('withdrawal-winnings.payment-date') + '...'"
                        />
                    </div>
                </div>

                <div v-if="props.row.date_reglement_prevue" slot="default" slot-scope="props">
                    {{ moment(props.row.date_reglement_prevue).format('DD/MM/YYYY') }}
                </div>
            </b-table-column>

            <b-table-column
                field="payment"
                sortable
                :searchable="isSearchable"
                :custom-search="searchByPaid"
                :label="$t('withdrawal-winnings.payment')"
                centered
            >
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('withdrawal-winnings.payment') + '...'"
                        />
                    </div>
                </div>

                <div slot="default" slot-scope="props">
                    <b-field>
                        <b-switch
                            v-model="props.row.envoi_reglement"
                            type="is-warning"
                            :true-value="1"
                            :false-value="0"
                            :disabled="props.row.status !== 'valider'"
                            @input="sendNewPayment(props.row.id)"
                        >
                            {{ props.row.envoi_reglement == 1 ? 'Oui' : 'Non' }}
                        </b-switch>
                    </b-field>
                </div>
            </b-table-column>

            <b-table-column
                field="remarques"
                sortable
                :searchable="isSearchable"
                :label="$t('withdrawal-winnings.notes')"
                centered
            >
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('withdrawal-winnings.notes') + '...'"
                        />
                    </div>
                </div>

                <div slot="default" slot-scope="props">
                    <b-tooltip
                        v-if="props.row.remarques"
                        :label="props.row.remarques"
                        :triggers="['click']"
                        :auto-close="['outside', 'escape']"
                        multilined
                        size=""
                    >
                        <b-button label="Voir" type="is-dark" size="is-small" />
                    </b-tooltip>
                </div>
            </b-table-column>

            <b-table-column field="actions" sortable :label="$t('withdrawal-winnings.actions')" centered>
                <div slot="default" slot-scope="props">
                    <b-button v-if="props.row.status === 'valider'" type="is-primary" @click="openModal(props.row)">
                        Modifier
                    </b-button>

                    <b-dropdown v-else aria-role="list">
                        <template #trigger="{ active }">
                            <b-button
                                label="Actions"
                                type="is-warning"
                                :icon-right="active ? 'caret-up' : 'caret-down'"
                            />
                        </template>

                        <b-dropdown-item aria-role="listitem" @click="openModal(props.row)"> Modifer</b-dropdown-item>
                        <b-dropdown-item
                            v-if="props.row.generation === 'auto'"
                            aria-role="listitem"
                            @click="generatePdf(props.row.id)"
                        >
                            Générer la facture
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="props.row.status === 'a_valider' && props.row.validation_error === 'valider'"
                            aria-role="listitem"
                            @click="validateWithdraw(props.row.id)"
                        >
                            Valider le retrait
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="props.row.validation_error === 'a_valider'"
                            aria-role="listitem"
                            @click="validateErrors(props.row.id)"
                        >
                            Valider les erreurs
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="props.row.status !== 'valider' || props.row.validation_error !== 'valider'"
                            aria-role="listitem"
                            @click="$refs.confirmModal.openModal(props.row)"
                        >
                            <span class="text-danger">Supprimer la demande de retrait</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </b-table-column>
        </b-table>

        <b-modal
            v-model="modalIsOpen"
            has-modal-card
            trap-focus
            :destroy-on-hide="true"
            aria-role="dialog"
            aria-label="Modal Modification"
            aria-modal
            :props="modalProps"
        >
            <template #default="props">
                <div class="modal-card box p-4">
                    <b-loading v-model="modalLoader" :is-full-page="false" />
                    <form v-if="modalProps">
                        <b-field label="Montant de la TVA">
                            <b-input v-model="modalProps.montant_tva" type="number" min="0" required />
                        </b-field>
                        <b-field label="Remarques">
                            <b-input v-model="modalProps.remarques" />
                        </b-field>

                        <div class="buttons justify-content-end mt-5">
                            <button class="button is-light" @click.prevent="props.close">Fermer</button>
                            <button class="button is-warning" @click.prevent="saveEditWithdrawal">
                                Confirmer les changements
                            </button>
                        </div>
                    </form>
                </div>
            </template>
        </b-modal>

        <WithdrawalConfirmModal ref="confirmModal" />
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import Download from "@/utils/download";

    export default {
        name: 'TabsWithdrawalWinnings',
        props: ['data', 'date', 'showValidated'],

        data() {
            return {
                earnings: [],

                modalIsOpen: false,
                modalProps: null,
                modalLoader: false,

                globalLoader: false,
                showAll: false,
                isSearchable: true
            }
        },

        watch: {
            data() {
                this.earnings = this.data
            },

            showAll() {
                if (this.showAll) {
                    this.$emit('hideTab', null)
                }
            }
        },

        created() {
            this.earnings = this.data

            this.$parent.$on('show-all', date => {
                if (date === this.date) {
                    this.showAll = !this.showAll
                }
            })

            this.$parent.$on('hide-tab', date => {
                this.checkHiddenRows()
            })
        },

        methods: {
            ...mapActions('billing', ['downloadUserInvoice']),
            generatePdf(id) {
                this.globalLoader = true

                this.axios.post(`/withdrawals/${id}/generate-pdf`).finally(() => {
                    this.globalLoader = false
                })
            },

            checkHiddenRows() {
                let filteredEarnings = this.earnings
                filteredEarnings = filteredEarnings.filter(earning => earning.envoi_reglement == 0)

                if (!filteredEarnings.length && !this.showAll) {
                    this.$emit('hideTab', this.date)
                }
            },

            sendNewPayment(id) {
                this.globalLoader = true

                this.axios
                    .post(`/withdrawals/${id}/send`)
                    .then(response => {
                        this.buefyAlert(response.data.message, 'is-success')
                    })
                    .catch(error => {
                        let message = error.response ? error.response.data.message : 'Une erreur est survenu'
                        this.buefyAlert(message, 'is-danger')
                    })
                    .finally(() => {
                        this.globalLoader = false
                    })
            },

            saveEditWithdrawal() {
                if (!this.modalProps.montant_tva || this.modalProps.montant_tva < 0) {
                    return
                }

                this.modalLoader = true
                this.axios
                    .patch(`/withdrawals/${this.modalProps.id}`, {
                        nom_facture: this.modalProps.nom_facture,
                        montant_tva: this.modalProps.montant_tva,
                        remarques: this.modalProps.remarques
                    })
                    .then(response => {
                        this.buefyAlert(response.data.message, 'is-success')
                    })
                    .catch(error => {
                        let message = error.response ? error.response.data.message : 'Une erreur est survenu'
                        this.buefyAlert(message, 'is-danger')
                    })
                    .finally(() => {
                        this.modalIsOpen = false
                        this.modalLoader = false
                    })
            },

            validateErrors(id) {
                this.globalLoader = true

                this.axios
                    .post(`/withdrawals/${id}/validate-errors`)
                    .then(response => {
                        this.earnings = this.earnings.map(earning => {
                            if (earning.id == id) {
                                earning.validation_error = 'valider'
                            }

                            return earning
                        })

                        this.buefyAlert(response.data.message, 'is-success')
                    })
                    .catch(error => {
                        let message = error.response ? error.response.data.message : 'Une erreur est survenu'
                        this.buefyAlert(message, 'is-danger')
                    })
                    .finally(() => {
                        this.globalLoader = false
                    })
            },

            validateWithdraw(id) {
                this.globalLoader = true

                this.axios
                    .post(`/withdrawals/${id}/validate-withdraw`)
                    .then(response => {
                        this.earnings = this.earnings.map(earning => {
                            if (earning.id == id) {
                                earning.date_validation = this.moment()
                                earning.date_reglement_prevue = this.moment().add(14, 'days')
                                earning.status = 'valider'
                            }

                            return earning
                        })

                        this.buefyAlert(response.data.message, 'is-success')
                    })
                    .catch(error => {
                        let message = error.response ? error.response.data.message : 'Une erreur est survenu'
                        this.buefyAlert(message, 'is-danger')
                    })
                    .finally(() => {
                        this.globalLoader = false
                    })
            },

            openModal(row) {
                this.modalProps = row
                this.modalIsOpen = true
            },

            buefyAlert(msg, type = 'is-success', duration = 5000) {
                this.$buefy.snackbar.open({
                    message: msg,
                    type: type,
                    duration: duration,
                    position: 'is-bottom'
                })
            },

            searchByUser(obj, string) {
                return (
                    obj.user.email.includes(string) ||
                    obj.user.firstname.includes(string) ||
                    obj.user.lastname.includes(string)
                )
            },
            searchByTotalAmount(obj, string) {
                return parseInt(obj.montant_ht) + parseInt(obj.montant_tva) == string
            },
            searchByPaid(obj, string) {
                return string == 'Oui' ? obj.envoi_reglement == 1 : obj.envoi_reglement == 0
            },
            searchByCreatedAt(obj, string) {
                let itemDate = this.moment(obj.created_at).startOf('day').format('L')

                if (itemDate.includes(string)) {
                    return true
                }

                return false
            },
            searchByDateValidation(obj, string) {
                let itemDate = this.moment(obj.date_validation).startOf('day').format('L')

                if (itemDate.includes(string)) {
                    return true
                }

                return false
            },
            searchByDateReglementPrevue(obj, string) {
                let itemDate = this.moment(obj.date_reglement_prevue).startOf('day').format('L')

                if (itemDate.includes(string)) {
                    return true
                }

                return false
            },
            download(invoice) {
                this.downloadUserInvoice(invoice.log_id).then((response) => {
                    Download.download(
                        response,
                        `Facture_${invoice.numero}`
                    )
                })
            }
        }
    }
</script>

<style scoped>
    .is-paid {
        background: #d7fbd7;
    }

    .is-left {
        left: initial !important;
    }

    .dropdown-content {
        box-shadow: none;
    }
</style>
