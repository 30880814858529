<template>
    <div>
        <div class="d-flex" style="gap: 10px">
            <StatCard color="success" :icon="'fas fa-check'">
                <template v-slot:counter>
                    <h3>{{ publishCount }}</h3>
                </template>
                <template v-slot:text>
                    <span>Terminées</span>
                </template>
            </StatCard>
            <StatCard color="warning" :icon="'fas fa-spinner'">
                <template v-slot:counter>
                    <h3>{{ waitingCount }}</h3>
                </template>
                <template v-slot:text>
                    <span>A valider</span>
                </template>
            </StatCard>
            <StatCard color="info" :icon="'fas fa-pencil-alt'">
                <template v-slot:counter>
                    <h3>{{ progressCount }}</h3>
                </template>
                <template v-slot:text>
                    <span>A traiter</span>
                </template>
            </StatCard>
            <StatCard color="danger" :icon="'fas fa-exclamation'">
                <template v-slot:counter>
                    <h3>{{ errorCount }}</h3>
                </template>
                <template v-slot:text>
                    <span>Terminées avec erreurs</span>
                </template>
            </StatCard>
            <StatCard color="danger" :icon="'fas fa-ban'">
                <template v-slot:counter>
                    <h3>{{ cancelCount }}</h3>
                </template>
                <template v-slot:text>
                    <span>Annulé</span>
                </template>
            </StatCard>
            <StatCard color="dark" :icon="'fas fa-times'">
                <template v-slot:counter>
                    <h3>{{ refuseCount }}</h3>
                </template>
                <template v-slot:text>
                    <span>Refusé</span>
                </template>
            </StatCard>
        </div>

        <b-table
            :data="contents"
            :mobile-cards="true"
            :debounce-search="400"
            default-sort-direction="desc"
            :striped="true"
            per-page="10"
            :loading="loading"
            :paginated="true"
        >
            <b-table-column
                centered
                field="id"
                width="1.2em"
                sortable
                searchable
                :label="$t('global.ref')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">#{{ props.row.id }}</div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.ref') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="created_at"
                width="2em"
                sortable
                searchable
                label="Date"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    {{ moment(props.row.created_at).format('DD/MM/YYYY') }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="date"
                            class="form-control form-control-sm"
                            :placeholder="'Date...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="statut"
                sortable
                searchable
                :custom-search="searchStatus"
                :label="$t('global.status')"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.statut == 'a_valider'" class="tag is-warning"
                        ><i class="far fa-handshake mr-1" /> A valider</span
                    >
                    <span v-else-if="props.row.statut == 'en_cours'" class="tag is-info"
                        ><i class="far fa-handshake mr-1" /> A traiter</span
                    >
                    <span
                        v-else-if="props.row.statut == 'termine' && !isTerminateWithErrors(props.row)"
                        class="tag is-success"
                    >
                        <i class="far fa-handshake mr-1" /> Terminée</span
                    >
                    <span
                        v-else-if="props.row.statut == 'termine' && isTerminateWithErrors(props.row)"
                        class="tag is-danger"
                    >
                        <i class="fas fa-exclamation-circle mr-1" data-toggle="tooltip" data-html="true" />
                        {{ $t('partenaire-commandes.terminate-error') }}</span
                    >
                    <span v-else-if="props.row.statut == 'refuse'" class="tag is-danger">
                        <i class="far fa-handshake mr-1" /> Refusé</span
                    >
                    <span v-else-if="props.row.statut == 'annule'" class="tag is-danger"
                        ><i class="far fa-handshake mr-1" /> Annulé</span
                    >
                    <span v-else class="tag is-danger"><i class="far fa-handshake mr-1" /> Erreur</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                        <option :value="props.filters[status]" selected disabled hidden>
                            {{ $t('global.choose') }}
                        </option>
                        <option value="all">Tous</option>
                        <option value="a_valider">A valider</option>
                        <option value="en_cours">A traiter</option>
                        <option value="termine">Terminée</option>
                        <option value="refuse">Refusé</option>
                        <option value="annule">Annulé</option>
                        <option value="">Erreur</option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column
                centered
                width="1em"
                field="content_lang"
                sortable
                searchable
                :label="$t('language.title')"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    <span class="">
                        <flag class="rounded shadow-sm" :iso="props.row.content_lang == 'fr_fr' ? 'fr' : 'us'" />
                    </span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option :value="props.filters[props.column.field]" selected disabled hidden>
                                {{ $t('language.title') + '...' }}
                            </option>
                            <option value="">
                                {{ $t('precommande.all') }}
                            </option>
                            <option value="fr_fr">
                                {{ $t('language.French') }}
                            </option>
                            <option value="com_en">
                                {{ $t('language.English') }}
                            </option>
                        </select>
                    </div>
                </div>
            </b-table-column>

            <b-table-column field="url" sortable searchable label="Page" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <span>{{ props.row.url }}</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="'Page ...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="bought_kw"
                sortable
                searchable
                :label="$t('global.keywords')"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    <button class="button is-light text-decoration-none" @click="displayKeywords(props.row)">
                        <b-icon icon="eye" size="is-small" />
                    </button>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.keywords') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="option_mots"
                sortable
                searchable
                :custom-search="searchByWordsCount"
                :label="$t('global.content')"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    <p>{{ props.row.option_mots + 200 }} {{ $t('global.words') }}</p>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.content') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="link_url"
                sortable
                searchable
                :label="$t('redactor.link-anchors')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <p class="mb-0">
                        {{ props.row.link_url }}
                    </p>
                    <p class="mb-0">
                        {{ props.row.link_anchor }}
                    </p>
                    <div v-if="props.row.link_second_url">
                        <p class="mb-0">
                            {{ props.row.link_second_url }}
                        </p>
                        <p class="mb-0">
                            {{ props.row.link_second_anchor }}
                        </p>
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('redactor.link-anchors') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="price_paid"
                width="2em"
                sortable
                searchable
                :custom-search="searchByPrice"
                :label="$t('global.price')"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    <p>{{ props.row.price_paid.toFixed(2) }}€</p>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.price') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="gain"
                width="2em"
                sortable
                searchable
                :custom-search="searchByGain"
                label="Gains"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    <p>{{ (props.row.commission / 100) * props.row.price_paid }}€</p>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.price') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="date_publication_prevue"
                centered
                sortable
                searchable
                :label="$t('redactor.publishdate')"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    <p>
                        {{
                            props.row.date_publication_prevue
                                ? moment(props.row.date_publication_prevue).format('DD/MM/YYYY')
                                : 'ASAP'
                        }}
                    </p>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="date"
                            class="form-control form-control-sm"
                            :placeholder="$t('redactor.publishdate') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="redaction"
                sortable
                searchable
                :label="$t('partenaire-site.redaction')"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ props.row.redaction === 'nextlevel' ? 'Nextlevel' : $t('partenaire-site.partner') }}</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option :value="props.filters[props.column.field]" selected disabled hidden>
                                {{ $t('partenaire-site.redaction') + '...' }}
                            </option>
                            <option value="">
                                {{ $t('precommande.all') }}
                            </option>
                            <option value="nextlevel">Nextlevel</option>
                            <option value="proprietaire">Proprietaire</option>
                        </select>
                    </div>
                </div>
            </b-table-column>
            <b-table-column centered field="actions" label="Actions" cell-class="align-middle text-center">
                <div slot="default" slot-scope="props">
                    <div class="is-flex">
                        <a
                            href="#"
                            class="rounded button is-light rounded-lg text-decoration-none mr-1"
                            @click.prevent="contentModalOpen(props.row)"
                        >
                            <b-icon icon="cog" />
                        </a>
                        <a
                            class="rounded button is-light rounded-lg text-decoration-none mr-1"
                            @click.prevent="openNoteModal(props.row.id)"
                        >
                            <b-icon pack="fas" icon="comment-alt" />
                        </a>
                    </div>
                </div>
            </b-table-column>
        </b-table>

        <ContentModal ref="contentModal" @updateContent="updateContent" />
        <NoteModal
            ref="noteModal"
            modal-type="content"
            :type-array="['Support', 'Developpement', 'Commercial', 'Communication', 'Comptabilité', 'Autre']"
        />

        <nl-modal :open-modal="keywordsModal" title="Keywords" size="xl" @hidden="close">
            <b-table :data="keywords" :striped="true" default-sort="position">
                <b-table-column field="keyword" label="Mot-clé" sortable>
                    <template v-slot="{ row }">
                        {{ row }}
                    </template>
                </b-table-column>
            </b-table>
        </nl-modal>
    </div>
</template>

<script>
    import ContentModal from './ContentModal'
    import NoteModal from '../Notes/NoteModal'

    export default {
        name: 'SitesOrdersTable',
        components: { ContentModal, NoteModal },
        props: {
            tabData: {}
        },
        data() {
            return {
                contents: [],
                isSearchable: false,
                loading: false,
                isMobileWidth: window.innerWidth < 768,
                keywordsModal: false,
                keywords: [],
                isNarrowed: true,
                sortIconSize: 'is-small',
                status: null,
                modificationActive: false,
                modificationRow: {},
                publishCount: 0,
                errorCount: 0,
                refuseCount: 0,
                cancelCount: 0,
                progressCount: 0,
                waitingCount: 0
            }
        },
        watch: {
            tabData: {
                handler(newValue) {
                    this.contents = []
                    for (const responseElement of newValue.content) {
                        for (const responseElementElement of responseElement.contents) {
                            this.contents.push(responseElementElement)
                        }
                    }

                    this.countStatus()

                    if (this.loading) {
                        this.loading = false
                    }
                },
                deep: true
            }
        },
        methods: {
            openNoteModal(contentId) {
                this.$refs.noteModal.openModal(contentId)
            },
            displayKeywords(content) {
                this.keywords = content.keywords.split(',')
                this.keywordsModal = true
            },
            searchByWordsCount(obj, string) {
                return obj.option_mots + 200 == string
            },
            searchByPrice(obj, string) {
                return (obj.price_paid + '').indexOf(string) > -1
            },
            searchByGain(obj, string) {
                return (obj.commission * obj.price_paid + '').indexOf(string) > -1
            },
            searchStatus(obj, string) {
                if (string == 'all') {
                    return true
                }

                return obj.statut == string
            },
            close() {
                this.keywordsModal = false
            },
            contentModalOpen(content) {
                this.$refs.contentModal.openModal(content)
            },
            updateContent(content) {
                this.axios
                    .put(`/admin/partner-details/contents/${content.id}`, content)
                    .then(() => {
                        this.$emit('reloadData', this.tabData.index)
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
            },
            isTerminateWithErrors(order) {
                if (
                    order.statut === 'termine' &&
                    order.link_status == 'ok' &&
                    (!order.link_second_anchor || order.link_second_status == 'ok') &&
                    !order.verification_autres_liens_externes &&
                    (order.verification_code_reponse_http == null || order.verification_code_reponse_http == 200)
                ) {
                    return false
                }

                return true
            },
            countStatus() {
                this.publishCount = this.contents.filter(
                    element => element.statut == 'termine' && !this.isTerminateWithErrors(element)
                ).length
                this.errorCount = this.contents.filter(
                    element => element.statut == 'termine' && this.isTerminateWithErrors(element)
                ).length
                this.refuseCount = this.contents.filter(element => element.statut == 'refuse').length
                this.cancelCount = this.contents.filter(element => element.statut == 'annule').length
                this.progressCount = this.contents.filter(element => element.statut == 'en_cours').length
                this.waitingCount = this.contents.filter(element => element.statut == 'a_valider').length
            }
        }
    }
</script>

<style>
    .-mt-1 {
        margin-top: -0.5em;
    }

    .media-content p.text {
        margin-bottom: 0;
    }
</style>
