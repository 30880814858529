import ApiService from '../../../services/api.service'

export default {
    list(context, params) {
        context.commit('setLoading')
        return new Promise((resolve, reject) => {
            ApiService.get('/notifications', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    delete(context) {
        context.commit('setLoading')
        return new Promise((resolve, reject) => {
            ApiService.delete('/notifications')
                .then(({ data }) => {
                    context.commit('setList', data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    setRead(context, notification_id) {
        return new Promise((resolve, reject) => {
            ApiService.put('/notifications/' + notification_id, {
                lu: true
            })
                .then(({ data }) => {
                    console.log(data)
                    context.dispatch('list')
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    setReadAll(context) {
        console.log('should mark as read , all unread!')
        return new Promise((resolve, reject) => {
            ApiService.post('/notifications/all/read', {
                lu: true
            })
                .then(({ data }) => {
                    console.log(data)
                    context.dispatch('list')
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    }
}
