<template>
    <div>
        <div class="col-12 p-0 row">
            <div class="form-group col-md-6">
                <label class="label">Version<span class="text-danger">*</span></label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">https://google</div>
                    </div>
                    <select v-model="selectedVersion" class="form-control custom-select">
                        <option value="fr">.fr</option>
                        <option value="com">.com</option>
                        <option value="co.uk">.co.uk</option>
                        <option value="ch">.ch</option>
                        <option value="be">.be</option>
                        <option value="ca">.ca</option>
                    </select>
                </div>
            </div>
            <div class="form-group col-md-6" v-if="selectedVersion === 'fr'">
                <label class="label">Code postal<span class="text-danger">*</span></label>
                <div class="input-group">
                    <input id="budget_mensuel" v-model="zipcode" type="number" class="form-control w-50" required="" />
                </div>
            </div>
        </div>

        <div class="col-12 p-0">
            <div class="form-group">
                <label>Mots-clés</label>
                <div class="row justify-content-between align-items-center">
                    <div class="col-md-11">
                        <textarea v-model="keywords" cols="30" rows="5" class="form-control" />
                        <small class="text-muted"> Séparer les mots clés d'un saut de ligne ou d'une virgule </small>
                    </div>
                    <div class="col-md-1 text-right">
                        <button type="button" class="btn btn-warning" :disabled="!keywords" @click="fetch">Go</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 p-0">
            <b-table v-if="results.length" :data="results" checkable :checked-rows.sync="checkedRows">
                <b-table-column v-slot="props" field="keyword" label="Mot clé" width="15%">
                    <span>{{ props.row.mot_cle }}</span>
                </b-table-column>
                <b-table-column v-slot="props" field="url" label="URL" width="30%">
                    <input v-model="props.row.url" type="text" class="form-control form-control-sm" />
                </b-table-column>
                <b-table-column v-slot="props" field="position" label="Position" width="1em" cell-class="text-center">
                    <span>{{ props.row.position_initiale }}</span>
                </b-table-column>
                <b-table-column v-slot="props" field="rd" label="RD" width="1em" cell-class="text-center">
                    <span>{{ props.row.rd_kw }}</span>
                </b-table-column>
                <b-table-column v-slot="props" field="note" label="Note">
                    <input v-model="props.row.note_divers" type="text" class="form-control form-control-sm" />
                </b-table-column>
            </b-table>
            <div v-if="loadingModal" class="mt-5" style="height: 150px">
                <b-loading v-model="loadingModal" :is-full-page="false" />
                <p class="mt-4 text-center">Récupération des données en cours...</p>
            </div>
        </div>
        <div v-if="!checkedRows.length" class="notification is-danger is-light mt-3">
            <p class="mb-0">
                <i class="fas fa-exclamation-triangle mr-1" />
                Pensez à bien sélectionner les mots clés désirés.
            </p>
        </div>

        <div v-if="results.length && !loadingModal && $route.query.id" class="col-12 my-4 row justify-content-end">
            <div class="buttons">
                <button class="btn btn-light mr-3" @click="$emit('closeModal')">Annuler</button>
                <button class="btn btn-warning" :disabled="!checkedRows.length" @click="save">Enregistrer</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'NewCampaignUrl',

        props: {
            url: {
                required: true,
                type: String
            },
            showAll: {
                required: false,
                default: true,
                type: Boolean
            }
        },
        data() {
            return {
                loadingModal: false,
                selectedLang: 'fr',
                selectedVersion: 'fr',
                zipcode: null,
                keywords: null,
                checkedRows: [],
                results: [],
                isFetching: false
            }
        },
        methods: {
            ...mapActions('campaigns', ['fetchGoogleResults', 'createCampaignKeywords']),
            fetch() {
                this.isFetching = true
                this.loadingModal = true

                this.fetchGoogleResults({
                    keywords: this.keywords.trim().split(/\n/g),
                    lang: this.selectedLang,
                    version: this.selectedVersion,
                    ...(this.zipcode && { zipcode: this.zipcode }),
                    url: encodeURI(this.url)
                })
                    .then(data => {
                        this.results = data
                    })
                    .finally(() => {
                        this.loadingModal = false
                        this.isFetching = false
                    })
            },

            save() {
                this.isFetching = true
                this.loadingModal = true

                this.createCampaignKeywords({
                    campaignId: this.$route.query.id,
                    params: {
                        keywords: this.checkedRows,
                        lang: this.selectedLang,
                        version: this.selectedVersion
                    }
                }).finally(() => {
                    this.loadingModal = false
                    this.isFetching = false
                    this.$emit('closeModal')
                })
            },

            rowsChecked(rows) {
                this.checkedRows = rows
            },

            clean() {
                this.checkedRows = []
                this.results = []
                this.keywords = null
            },

            getCheckedRows() {
                return this.checkedRows
            }
        }
    }
</script>
