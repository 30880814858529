import axios from '@/config/backend'

export default class Search {
    constructor() {
        this.url = process.env.VUE_APP_LARAVEL_URL + '/api'
    }

    getSearchKeyword() {
        return this.url + '/search_keyword'
    }

    getSearchUrl() {
        return this.url + '/search_url'
    }

    getPrepareSearchUrl() {
        return this.url + '/search_url/prepare'
    }

    getSearchDomain() {
        return this.url + '/search_domain'
    }

    getPrepareDomain() {
        return this.url + '/search_domain/prepare'
    }

    getSearchGSC() {
        return this.url + '/search_gsc'
    }

    getPrepareGSC() {
        return this.url + '/search_gsc/prepare'
    }

    getFilterUrl() {
        return this.url + '/filter/init'
    }

    getFavoritesUrl() {
        return this.url + '/favorite/load'
    }

    getInfos() {
        return this.url + '/user/infos'
    }

    getAutocompletePartner() {
        return this.url + '/user/partner/search'
    }

    getSitesGSC() {
        return this.url + '/gsc/sites'
    }

    getBuyLinkUrl() {
        return this.url + '/buy/buylink'
    }

    initFilter(callbackSuccess, callbackError) {
        axios
            .get(this.getFilterUrl())
            .then(response => {
                if (callbackSuccess) {
                    callbackSuccess(response)
                }
            })
            .catch(error => {
                if (callbackError) {
                    callbackError(error)
                }
            })
    }

    loadFavorites(data, callbackSuccess, callbackError) {
        axios
            .post(this.getFavoritesUrl(), data)
            .then(response => {
                if (callbackSuccess) {
                    callbackSuccess(response)
                }
            })
            .catch(error => {
                if (callbackError) {
                    callbackError(error)
                }
            })
    }

    userInfos(callbackSuccess, callbackError) {
        axios
            .get(this.getInfos())
            .then(response => {
                if (callbackSuccess) {
                    callbackSuccess(response)
                }
            })
            .catch(error => {
                if (callbackError) {
                    callbackError(error)
                }
            })
    }

    load(data, callbackSuccess, callbackError) {
        axios
            .post(this.getSearchKeyword(), data)
            .then(response => {
                if (callbackSuccess) {
                    callbackSuccess(response)
                }
            })
            .catch(error => {
                if (callbackError) {
                    callbackError(error)
                }
            })
    }

    searchURL(data, callbackSuccess, callbackError) {
        axios
            .post(this.getSearchUrl(), data)
            .then(response => {
                if (callbackSuccess) {
                    callbackSuccess(response)
                }
            })
            .catch(error => {
                if (callbackError) {
                    callbackError(error)
                }
            })
    }

    searchURLPrepare(data, callbackSuccess, callbackError) {
        axios
            .post(this.getPrepareSearchUrl(), data)
            .then(response => {
                if (callbackSuccess) {
                    callbackSuccess(response)
                }
            })
            .catch(error => {
                if (callbackError) {
                    callbackError(error)
                }
            })
    }

    searchDomain(data, callbackSuccess, callbackError) {
        axios
            .post(this.getSearchDomain(), data)
            .then(response => {
                if (callbackSuccess) {
                    callbackSuccess(response)
                }
            })
            .catch(error => {
                if (callbackError) {
                    callbackError(error)
                }
            })
    }

    searchDomainPrepare(data, callbackSuccess, callbackError) {
        axios
            .post(this.getPrepareDomain(), data)
            .then(response => {
                if (callbackSuccess) {
                    callbackSuccess(response)
                }
            })
            .catch(error => {
                if (callbackError) {
                    callbackError(error)
                }
            })
    }

    searchGSC(data, callbackSuccess, callbackError) {
        axios
            .post(this.getSearchGSC(), data)
            .then(response => {
                if (callbackSuccess) {
                    callbackSuccess(response)
                }
            })
            .catch(error => {
                if (callbackError) {
                    callbackError(error)
                }
            })
    }

    searchGSCPrepare(data, callbackSuccess, callbackError) {
        axios
            .post(this.getPrepareGSC(), data)
            .then(response => {
                if (callbackSuccess) {
                    callbackSuccess(response)
                }
            })
            .catch(error => {
                if (callbackError) {
                    callbackError(error)
                }
            })
    }

    loadSitesGSC(data, callbackSuccess, callbackError) {
        axios
            .get(this.getSitesGSC(), { params: data })
            .then(response => {
                if (callbackSuccess) {
                    callbackSuccess(response)
                }
            })
            .catch(error => {
                if (callbackError) {
                    callbackError(error)
                }
            })
    }

    searchPartner(data, callbackSuccess, callbackError) {
        axios
            .get(this.getAutocompletePartner(), { params: data })
            .then(response => {
                if (callbackSuccess) {
                    callbackSuccess(response)
                }
            })
            .catch(error => {
                if (callbackError) {
                    callbackError(error)
                }
            })
    }

    buyLink(data) {
        return axios.post(this.getBuyLinkUrl(), data)
    }
}
