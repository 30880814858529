<template>
    <div id="paypal-wrapper">
        <div id="paypal-button-container"></div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: 'PaypalButton',
    props: {
        createNewOrderPaypal: {
            type: Function,
            required: true
        },
        amount: {
            type: Number,
            required: true
        }
    },
    mounted() {
        const CLIENT_ID = process.env.VUE_APP_PAYPAL_CLIENT_ID;
        if(window.paypal) {
            this.createPaypalButton();
        } else {
            const script = document.createElement('script');
            script.src = `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=EUR`;
            script.addEventListener('load', this.createPaypalButton);
            document.body.appendChild(script);
        }
    },
    methods: {
        ...mapActions('invoices', { approvedPaypal: 'approvedPaypal', cancelPaypal: 'cancelPaypal' }),

        createPaypalButton() {
            const options = {
                buttonHeight: 50,
                fundingSource: 'card'
            }

            if (!window.paypal) {
                console.error('PayPal SDK not found!');
                return;
            }

            const fundingSources = [
                {
                    source: 'paypal', // window.paypal.FUNDING.PAYPAL,
                    style: {
                        layout: 'horizontal', // horizontal/vertical
                        color: 'white', // couleur
                        shape: '', // arrondi
                        label: 'pay', // text sur le bouton
                        tagline: false, // texte sous le bouton
                        height: options.buttonHeight,
                    },
                },
                {
                    source: 'venmo', // window.paypal.FUNDING.VENMO,
                    style: {
                        layout: 'horizontal',
                        shape: 'pill',
                        tagline: false,
                        height: options.buttonHeight,
                    },
                },
                {
                    source: 'paylater', // window.paypal.FUNDING.PAYLATER,
                    style: {
                        layout: 'horizontal',
                        shape: 'pill',
                        tagline: false,
                        height: options.buttonHeight,
                    },
                },
                {
                    source: 'credit', // window.paypal.FUNDING.CREDIT,
                    style: {
                        layout: 'horizontal',
                        shape: 'pill',
                        tagline: false,
                        height: options.buttonHeight,
                    },
                },
                {
                    source: 'card', // window.paypal.FUNDING.CARD,
                    style: {
                        layout: 'horizontal',
                        color: 'black',
                        shape: 'pill',
                        tagline: false,
                        height: options.buttonHeight,
                    },
                },
            ];
            const fundingSource = fundingSources.find(f => f.source === options.fundingSource || 'card');

            const button = window.paypal.Buttons({
                fundingSource: fundingSource.source,
                onInit: (data, actions) => this.buttonOnInit(data, actions),
                onClick: (data, actions) => this.buttonOnClick(data, actions),
                createOrder: (data, actions) => this.createNewOrderPaypal(data, actions),
                onApprove: (data, actions) => this.buttonOnApprove(data, actions),
                style: fundingSource.style || {},
                onCancel: (data, actions) => this.buttonOnCancel(data, actions),
                onError: err => console.error('PayPal Button Error', err),
            });

            // On vérifie qu'on n'a le droit de l'afficher
            if (button.isEligible()) {
                button.render('#paypal-button-container');
            }
        },

        /**
         * lancer à l'initialisation du bouton
         *
         * @param data
         * @param actions
         */
        buttonOnInit(data, actions) {
            actions.enable();
            this.paypalButtonEnable = true;
        },

        /**
         * lancer après un click sur le bouton
         *
         * @param data
         * @param actions
         * @returns {boolean}
         */
        buttonOnClick(data, actions) {

        },

        /**
         * Retourne les données permettant de valider un paiement
         *
         * @param data
         * @param actions
         * @returns {Promise<any>}
         */
        async buttonOnApprove(data, actions) {
            $('#paypal-wrapper').after('<div id="paypal_load_after_paid">Paiement en cours de validation ...</div>')

            let params = {
                orderID: data.orderID,
                payerID: data.payerID,
            }
            const paypalData = await this.approvedPaypal(params);

            // redirection
            if(paypalData.status === 'COMPLETED') {
                this.order_id = null
                void this.$router.push('/buy-credits/card-confirmation?status=' + paypalData.status)
            } else {
                this.cancelPaypal(data)
                $('#paypal_load_after_paid').html('Paiement échoué, veuillez réessayer !').css('color', 'red');
                setTimeout(() => {
                    $('#paypal_load_after_paid').remove();
                }, 5000);
            }
        },

        async buttonOnCancel(data, actions) {
            this.cancelPaypal(data)
            $('#paypal_load_after_paid').html('Paiement annulé').css('color', 'red');
            setTimeout(() => {
                $('#paypal_load_after_paid').remove();
            }, 5000);
        }
    }
};
</script>
