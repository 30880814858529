<template>
    <div class="form-group">
        <label v-if="label" :for="'frm_consultant_' + type">{{ label }}</label>
        <select
            :id="'frm_consultant_' + type"
            class="form-control"
            :value="consultant ? consultant.id : ''"
            @change="update($event)"
        >
            <option value="">-</option>
            <option v-for="c in available" :key="c.id" :value="c.id">
                {{ c.firstname }} {{ c.lastname }} ({{ c.email }})
            </option>
        </select>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'Consultant',
        props: {
            type: {
                type: String,
                required: true
            },
            available: {
                type: Array,
                required: true
            },
            consultant: {
                type: Object
            },
            label: {
                type: String
            }
        },
        computed: {
            ...mapState('campaigns', ['campaign'])
        },
        methods: {
            ...mapActions('campaigns', ['updateCampaign']),
            update: function (e) {
                let key

                switch (this.type) {
                    case 'onPage':
                        key = 'accompagnant_onpage_id'
                        break
                    case 'onSite':
                        key = 'accompagnant2_id'
                        break
                    case 'offSite':
                        key = 'accompagnant_id'
                        break
                }

                if (key) {
                    this.updateCampaign({
                        campaignId: this.campaign.id,
                        [key]: e.target.value
                    })
                }
            }
        }
    }
</script>
