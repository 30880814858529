<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 v-if="reinit_minimum_gain.active" class="h4 p-0 mb-0">
                        {{ $t('partenaire-mot-cles.reinitminigain') }}
                    </h4>
                    <h4 v-if="blacklist_url.active" class="h4 p-0 mb-0">
                        {{ $t('partenaire-mot-cles.blacklist') }}
                    </h4>
                    <h4 v-if="minimum_gain.active" class="h4 p-0 mb-0">
                        {{ $t('partenaire-mot-cles.minigain') }}
                    </h4>
                    <h4 v-if="globalReset.active" class="h4 p-0 mb-0">
                        {{ $t('partenaire-mot-cles.global-min-gain-define') }}
                    </h4>
                </header>
                <div class="card-content">
                    <div class="content">
                        <form>
                            <section v-if="reinit_minimum_gain.active" class="my-5">
                                <p>{{ $t('partenaire-mot-cles.reinitminigaindescription') }}.</p>

                                <p>{{ $t('partenaire-mot-cles.reinitminigaindescription_02') }}.</p>
                            </section>

                            <section v-if="blacklist_url.active" class="my-5">
                                <p>{{ $t('partenaire-mot-cles.blacklistdescription') }}.</p>

                                <p>{{ $t('partenaire-mot-cles.blacklistdescription_02') }}</p>
                            </section>

                            <section v-if="globalReset.active" class="my-5">
                                <p>{{ $t('partenaire-mot-cles.global-reset-desc') }}</p>
                            </section>

                            <section v-if="minimum_gain.active" class="my-5">
                                <p>{{ $t('partenaire-site.minigainexplanation') }}.</p>
                                <p>
                                    {{ $t('partenaire-mot-cles.minigainAllURL') }}
                                    <a href="/partner/sites">{{ $t('partenaire-mot-cles.minigainAllURL_02') }}</a
                                    >, {{ $t('partenaire-mot-cles.minigainAllURL_03') }}.
                                </p>

                                <b-field>
                                    <b-input
                                        v-model="minimum_gain.number"
                                        expanded
                                        placeholder="0"
                                        type="number"
                                        min="0"
                                    />
                                    <p class="control">
                                        <b-button class="button is-light">
                                            <i class="fas fa-euro-sign" />
                                        </b-button>
                                    </p>
                                </b-field>
                            </section>

                            <div class="buttons justify-content-end">
                                <button
                                    v-if="blacklist_url.active"
                                    class="button is-danger"
                                    @click.prevent="validate('blacklist_url')"
                                >
                                    {{ $t('partenaire-mot-cles.blacklist') }}
                                </button>
                                <button
                                    v-if="reinit_minimum_gain.active"
                                    class="button is-danger"
                                    @click.prevent="validate('reinit_minimum_gain')"
                                >
                                    {{ $t('partenaire-mot-cles.reinit') }}
                                </button>
                                <button
                                    v-if="globalReset.active"
                                    class="button is-danger"
                                    @click.prevent="validate('globalReset')"
                                >
                                    {{ $t('partenaire-mot-cles.reinit') }}
                                </button>
                                <button class="button is-light" @click.prevent="closeAndResetModal">
                                    {{ $t('global.cancel') }}
                                </button>
                                <button
                                    v-if="minimum_gain.active"
                                    class="button is-warning"
                                    @click.prevent="validate('minimum_gain')"
                                >
                                    {{ $t('global.validate') }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'SiteKeywordsConfirmModal',
        props: ['checkedRows'],
        data() {
            return {
                modalIsOpen: false,
                selectedUrls: [],
                siteId: null,

                minimum_gain: {
                    active: false,
                    number: 0
                },
                blacklist_url: {
                    active: false
                },
                reinit_minimum_gain: {
                    active: false
                },
                globalReset: {
                    active: false
                }
            }
        },

        watch: {
            checkedRows(newValue) {
                this.selectedUrls = newValue.length ? newValue.map(row => row.url) : []
            }
        },

        created() {
            this.selectedUrls = this.checkedRows.length ? this.checkedRows.map(row => row.url) : []

            this.siteId = this.$route.query.id
        },

        methods: {
            validate(type) {
                switch (type) {
                    case 'reinit_minimum_gain':
                        this.$emit('bulkReinitMinimumGain', {
                            urls: this.selectedUrls,
                            siteId: this.siteId
                        })
                        break
                    case 'blacklist_url':
                        this.$emit('bulkBlacklist', {
                            urls: this.selectedUrls,
                            siteId: this.siteId
                        })
                        break
                    case 'minimum_gain':
                        this.$emit('bulkMinimumGain', {
                            ids: this.checkedRows.map(i => i.id),
                            floor_price: this.minimum_gain.number
                        })
                        break
                    case 'globalReset':
                        this.$emit('resetMinimumGainGlobal')
                        break
                    default:
                        break
                }

                this.$emit('uncheckRows')
                this.closeAndResetModal()
            },

            openModal(type) {
                this.modalIsOpen = true

                switch (type) {
                    case 'prix-plancher':
                        this.minimum_gain.active = true
                        break
                    case 'blacklist':
                        this.blacklist_url.active = true
                        break
                    case 'reinitialiser':
                        this.reinit_minimum_gain.active = true
                        break
                    case 'global-reset':
                        this.globalReset.active = true
                        break

                    default:
                        break
                }
            },

            closeAndResetModal() {
                this.modalIsOpen = false

                this.minimum_gain = {
                    active: false,
                    number: 0
                }
                this.blacklist_url = {
                    active: false
                }
                this.reinit_minimum_gain = {
                    active: false
                }
                this.globalReset = {
                    active: false
                }
            }
        }
    }
</script>

<style>
    label[for^='scrape_'] {
        color: #363636;
        display: block;
        font-size: 1rem;
        font-weight: 700;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
