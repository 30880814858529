import ApiService from '../../../services/api.service'

export default {
    list(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get('/orders/invoices', params)
                .then(({ data }) => {
                    resolve(data.orders)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    create(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/orders/invoices', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    createPaypal(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/orders/invoices', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    approvedPaypal(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/orders/invoices-paypal-return', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    cancelPaypal(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/orders/invoices-paypal-cancel', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    }
}
