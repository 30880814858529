import { render, staticRenderFns } from "./TransfertGainsModal.vue?vue&type=template&id=64270bd3&scoped=true&"
import script from "./TransfertGainsModal.vue?vue&type=script&lang=js&"
export * from "./TransfertGainsModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64270bd3",
  null
  
)

export default component.exports