import axios from '@/config/backend'

export default class AccompagnementApi {
    constructor() {
        this.legacybasePath = process.env.VUE_APP_LEGACY_URL + '/accompagnement'
        this.LaravelbasePath = process.env.VUE_APP_LARAVEL_URL + '/api'
    }

    getAccompagnements(params) {
        let statusParams = new URLSearchParams()
        statusParams.set('status', status)
        // TODO voir avec Jérémy si le paramètre config.params est toujours utile ?
        // refacto de /admin/accompagnements
        return this.requestLaravelBackend('get', '/campaigns/project/' + params.project, {
            params: {
                status: params.status,
                'filter[project_id]': params.project
            }
        })
    }

    getProjects(user_id) {
        return this.requestLaravelBackend('get', '/crud/projects?all=true&filter[user_id]=' + user_id)
    }

    searchByUrl(url) {
        return this.requestLegacyBackend('get', '/search.php?url=' + url)
    }

    getProperties(id) {
        return this.requestLegacyBackend('get', '/properties.php?id=' + id)
    }

    updateProperties(id, data) {
        return this.requestLegacyBackend('post', '/properties.php?id=' + id + '&action=update', { data: data })
    }

    getCustomers() {
        return this.requestLegacyBackend('get', '/customers.php')
    }

    getConsultants() {
        return this.requestLegacyBackend('get', '/agency/consultants')
    }

    getContents(id) {
        return this.requestLegacyBackend('get', '/content.php?id=' + id)
    }

    getOrderData(id, stat_id) {
        return this.requestLegacyBackend('get', '/content.php?id=' + id + '&action=content-info&stat_id=' + stat_id)
    }

    attachContents(id, contents) {
        return this.requestLegacyBackend('post', '/content.php?id=' + id + '&action=attach-content', {
            data: {
                contents: contents
            }
        })
    }

    updateContent(id, content_id, period) {
        return this.requestLegacyBackend(
            'post',
            '/content.php?id=' + id + '&action=update-content&content_id=' + content_id,
            {
                data: {
                    period: period
                }
            }
        )
    }

    detachContent(id, content_id) {
        return this.requestLegacyBackend(
            'post',
            '/content.php?id=' + id + '&action=detach-content&content_id=' + content_id
        )
    }

    getUrlAndKeywords(id) {
        return this.requestLaravelBackend('get', `/campaigns/url/${id}/suggestions`)
    }

    updateUrlAndKeywords(id, data) {
        return this.requestLegacyBackend('post', '/url-and-keyword.php?id=' + id + '&action=update', {
            data: {
                urls: data
            }
        })
    }

    syncMonitorank(id) {
        return this.requestLegacyBackend('post', '/url-and-keyword.php?id=' + id + '&action=sync-monitorank')
    }

    updatePosition(id) {
        return this.requestLegacyBackend('post', '/url-and-keyword.php?id=' + id + '&action=update-position')
    }

    getBudgets(id) {
        return this.requestLegacyBackend('get', '/budget.php?id=' + id)
    }

    createBudgets(id) {
        return this.requestLegacyBackend('post', '/budget.php?id=' + id + '&action=create')
    }

    resetBudgets(id) {
        return this.requestLegacyBackend('post', '/budget.php?id=' + id + '&action=reset')
    }

    updateBudget(id, data) {
        return this.requestLegacyBackend('post', '/budget.php?id=' + id + '&action=update', { data: { budget: data } })
    }

    extendBudget(id, duration) {
        return this.requestLegacyBackend('post', '/budget.php?id=' + id + '&action=extend', {
            data: { duration: duration }
        })
    }

    getFollowup(id) {
        return this.requestLegacyBackend('get', '/followup.php?id=' + id)
    }

    getFollowupDownloadLink(id) {
        return this.basePath + '/followup.php?id=' + id + '&action=download'
    }

    createFollowup(id, data) {
        return this.requestLegacyBackend('post', '/followup.php?id=' + id + '&action=create', { data: data })
    }

    updateFollowup(id, data) {
        return this.requestLegacyBackend('post', '/followup.php?id=' + id + '&action=update', { data: data })
    }

    deleteFollowup(id, followup_id) {
        return this.requestLegacyBackend('post', '/followup.php?id=' + id + '&action=delete&followup=' + followup_id)
    }

    getActions(id) {
        return this.requestLegacyBackend('get', '/actions.php?id=' + id)
    }

    updateActionsStatus(id, thematic, date, status) {
        return this.requestLegacyBackend('post', '/actions.php?id=' + id + '&action=update', {
            data: {
                thematic: thematic,
                date: date,
                status: status
            }
        })
    }

    getUrlSuggestion(id, type, query) {
        return this.requestLegacyBackend('get', '/url-suggestion-for-buy-modal.php', {
            params: {
                id: id,
                type: type,
                q: query
            }
        })
    }

    /** Private **/
    requestLegacyBackend(method, url, config) {
        return new Promise((resolve, reject) => {
            axios({
                ...config,
                method: method,
                url: this.legacybasePath + url
            })
                .then(data => {
                    resolve(data.data)
                })
                .catch(error => {
                    // Error 😨
                    if (error.response) {
                        reject(error.response.data.error)
                    } else {
                        reject(error.message)
                    }
                    console.log(error)
                })
        })
    }

    requestLaravelBackend(method, url, config) {
        return new Promise((resolve, reject) => {
            axios({
                ...config,
                method: method,
                url: this.LaravelbasePath + url
            })
                .then(data => {
                    resolve(data) // not the same response that for Legacy
                })
                .catch(error => {
                    // Error 😨
                    if (error.response) {
                        reject(error.response.data.error)
                    } else {
                        reject(error.message)
                    }
                    console.log(error)
                })
        })
    }
}
