export default () => ({
    user: null,
    locale: null,
    credit: null,
    security: {
        hideResults: false,
        isFavorite: false,
        seeKeyword: false,
        isAdmin: false,
        isProspect: false
    }
})
