<template>
    <div class="p-2 bg-white">
        <AssignRedactorModal ref="assignRedactorModal" @redactor:selected="doAssignRedactor" />

        <AddPendingMessage ref="addPendingMessage" @save="savePendingMessage" />

        <div class="d-flex justify-content-end bg-white">
            <div class="pt-2 px-2 d-flex align-items-center justify-content-md-end">
                <p class="control mr-2 mt-2 mb-2">Résultats par page</p>
                <input
                    v-model="config.per_page"
                    class="form-control w-25"
                    type="number"
                    @keypress.enter="loadAsyncData"
                />
            </div>
            <div class="pt-2 px-2 d-flex align-items-center justify-content-md-end">
                <b-dropdown aria-role="list" :close-on-click="false" position="is-bottom-left">
                    <template #trigger="{ active }">
                        <b-button
                            :label="$t('global.columns')"
                            type="is-info"
                            class="mr-2"
                            :icon-right="active ? 'caret-up' : 'caret-down'"
                        />
                    </template>

                    <b-dropdown-item v-for="(col, index) in columns" :key="index" aria-role="listitem">
                        <b-field>
                            <b-checkbox v-model="col.active">
                                {{ col.name }}
                            </b-checkbox>
                        </b-field>
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>

        <b-table
            :data="writings.data"
            :default-sort="['id', 'desc']"
            :loading="loading"
            class="table-striped"
            :narrowed="true"
            :mobile-cards="true"
            :per-page="config.per_page"
            :show-detail-icon="true"
            :striped="true"
            :total="writings.total"
            backend-filtering
            backend-pagination
            backend-sorting
            paginated
            @sort="onSort"
            @page-change="onPageChange"
            @filters-change="onFilterChange"
        >
            <b-table-column centered field="isPrio" label="Actions" sortable width="0" cell-class="align-middle">
                <div slot="default" slot-scope="props" class="d-flex">
                    <div class="cursor-pointer mr-4">
                        <div v-if="loadingCancel === true && loadingIndex === props.index">
                            <i class="fas fa-times fa-spin" />
                        </div>
                        <div v-else>
                            <b-tooltip size="is-small" position="is-right" label="Supprimer">
                                <a href="#" class="text-danger" @click="showDeleteModal(props.row, props.index)">
                                    <i class="fas fa-times" />
                                </a>
                            </b-tooltip>
                        </div>
                    </div>

                    <div class="mr-4">
                        <div v-if="loading && loadingIndex && loadingIndex === props.index">
                            <i class="fas fa-cog fa-spin" />
                        </div>
                        <div v-else class="cursor-pointer">
                            <span v-if="props.row.isPrio">
                                <i class="fas fa-flag prio" @click="setPrio(props.index, props.row.id, false)" />
                            </span>
                            <span v-else>
                                <i class="far fa-flag" @click="setPrio(props.index, props.row.id, true)" />
                            </span>
                        </div>
                    </div>

                    <div class="cursor-pointer mr-4">
                        <div v-if="loadingPending === true && loadingIndex === props.index">
                            <i class="far fa-hourglass fa-spin" />
                        </div>
                        <div v-else>
                            <span v-if="props.row.writing_status_id === 10">
                                <b-tooltip
                                    size="is-small"
                                    position="is-right"
                                    :label="props.row.reason || 'Mettre cette commande à traiter'"
                                >
                                    <i
                                        class="fas fa-hourglass prio"
                                        @click="setPendingWriting(props.row, false, props.index)"
                                    />
                                </b-tooltip>
                            </span>
                            <span v-else>
                                <b-tooltip size="is-small" position="is-right" label="Mettre cette commande en attente">
                                    <i class="far fa-hourglass" @click="showPendingModal(props.row, props.index)" />
                                </b-tooltip>
                            </span>
                        </div>
                    </div>

                    <div class="cursor-pointer mr-4">
                        <b-tooltip label="Force review" position="is-top">
                            <a href="#" @click.prevent="doForceReview(props.index)">
                                <i
                                    class="fas fa-sync"
                                    :class="{
                                        'text-danger': props.row.force_review == 1,
                                        'fa-spin': forceReviewIndex === props.index
                                    }"
                                />
                            </a>
                        </b-tooltip>
                    </div>

                    <div class="cursor-pointer">
                        <div v-if="loadingFinished === true && loadingIndex === props.index">
                            <i class="far fa-check fa-spin" />
                        </div>
                        <div v-else>
                            <b-tooltip size="is-small" position="is-right" label="Mettre en terminer">
                                <a
                                    href="#"
                                    class="text-success"
                                    @click="showSetWritingFinishedModal(props.row, props.index)"
                                >
                                    <i class="fas fa-check" />
                                </a>
                            </b-tooltip>
                        </div>
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                :searchable="isSearchable"
                centered
                field="entity_type"
                label="Type"
                sortable
                width="0"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.entity_type === 1">Content</span>
                    <span v-else-if="props.row.entity_type === 2">Preorder</span>
                    <span v-else>Spot</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option :value="undefined">Tous</option>
                            <option value="1">Content</option>
                            <option value="2">Preorder</option>
                            <option value="6">Spot</option>
                        </select>
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                :searchable="isSearchable"
                centered
                field="entity_id"
                label="Ref"
                sortable
                width="0"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <div class="tag" :class="[props.row.entity_type === 1 ? 'is-warning' : 'is-info']">
                        #{{ props.row.entity_id }}
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            class="form-control form-control-sm"
                            placeholder="Réf..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                :searchable="isSearchable"
                centered
                field="created_at"
                label="Date"
                sortable
                width="0"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    {{ moment(props.row.created_at).format('L') }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <input
                        v-model="props.filters[props.column.field]"
                        class="form-control form-control-sm"
                        placeholder="Date..."
                        type="date"
                    />
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="redactor_id"
                label="Assignée"
                :searchable="isSearchable"
                width="0"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props" class="d-flex">
                    <span v-if="props.row.redactor_id" class="tag is-success">
                        Oui
                        <b-tooltip
                            v-if="props.row.redactor && props.row.redactor.user"
                            :label="props.row.redactor.user.email"
                            class="ml-2"
                        >
                            <b-icon icon="info-circle" size="is-small" />
                        </b-tooltip>
                    </span>

                    <div v-if="!props.row.redactor_id">
                        <span class="tag is-danger cursor-pointer" @click="openAssignModal(props.row.id, props.index)">
                            Non
                        </span>
                    </div>

                    <button
                        v-if="props.row.redactor_id"
                        class="button tag is-small ml-2 is-light"
                        title="Retirer de la commande"
                        @click.prevent="openConfirmModal(props.index, props.row)"
                    >
                        <b-icon icon="times" size="is-small" />
                    </button>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <select v-model="props.filters['is_assigned']" class="form-control form-control-sm">
                            <option :value="props.filters[props.column.field]">Tous</option>
                            <option value="true">Oui</option>
                            <option value="false">Non</option>
                        </select>
                    </div>
                </div>
            </b-table-column>

            <b-table-column label="Client" :searchable="isSearchable" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <div v-if="props.row.entity_type === 1">
                        <p class="mb-0">
                            {{ props.row.related_order.buyer_firstname }}
                            {{ props.row.related_order.buyer_lastname }}<br />

                            <span class="text-muted">
                                {{ props.row.related_order.buyer_email }}
                            </span>
                        </p>
                    </div>
                    <div v-else-if="props.row.entity_type === 2 && props.row.related_order != null">
                        <p v-if="props.row.related_order.campaign.project != null" class="mb-0">
                            {{ props.row.related_order.campaign.project.name }}
                        </p>
                        <p v-else class="mb-0">-</p>
                    </div>
                    <div v-else>
                        <p class="mb-0">-</p>
                    </div>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters['with_client']"
                            class="form-control form-control-sm"
                            placeholder="Client..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                :label="$t('partenaire-site.partner')"
                :searchable="isSearchable"
                sortable
                field="partner.email"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.partner_id">
                        {{ props.row.partner_email }}
                        <LoginAs :user-id="props.row.partner_id" class="ml-auto" />
                    </span>
                    <span v-else> Nextlevel </span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters['with_partner']"
                            class="form-control form-control-sm"
                            placeholder="Partenaire..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                :label="$t('redactor.bought-keywords')"
                :searchable="isSearchable"
                field="keyword"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <div>
                        <p v-if="props.row.related_order != null">
                            {{ props.row.related_order.keyword }}
                        </p>
                        <p v-else>-</p>
                        <span v-if="props.row.entity_type === 1">{{ props.row.page }}</span>
                        <span v-else>{{ props.row.site?.url }}</span>
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            class="form-control form-control-sm"
                            placeholder="Mots clés..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                :label="$t('redactor.link-anchors')"
                :searchable="isSearchable"
                field="primary_link"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <div v-if="props.row.entity_type === 1">
                        <p>
                            {{ $t('global.link') }} :
                            <span>{{ props.row.primary_link }}</span>
                        </p>
                        <p>
                            {{ $t('global.anchor') }} :
                            <span class="text-muted">{{ props.row.primary_anchor }}</span>
                        </p>

                        <template v-if="props.row.secondary_link">
                            <hr />

                            <p>
                                {{ $t('global.link') }} 2 :
                                <span>{{ props.row.secondary_link }}</span>
                            </p>
                            <p>
                                {{ $t('global.anchor') }} 2 :
                                <span class="text-muted">{{ props.row.secondary_anchor }}</span>
                            </p>
                        </template>
                    </div>
                    <div v-else>
                        <p>-</p>
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            :placeholder="$t('redactor.link') + '...'"
                            class="form-control form-control-sm"
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                :label="$t('redactor.nbWords')"
                :searchable="isSearchable"
                field="words_count"
                sortable
                cell-class="align-middle"
                :visible="columns.words_count.active"
            >
                <div slot="default" slot-scope="props">
                    {{ props.row.words_count }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            class="form-control form-control-sm"
                            placeholder="Option mots..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column field="has_wordpress_plugin" label="Plugin" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <p v-if="props.row.has_wordpress_plugin == 1" class="mb-0">
                        <i class="fas fa-check-square text-success" />
                    </p>
                    <p v-else class="mb-0">
                        <i class="fas fa-times text-primary" />
                    </p>
                </div>
            </b-table-column>

            <b-table-column
                :label="$t('language.title')"
                :searchable="isSearchable"
                field="lang"
                sortable
                cell-class="align-middle"
                :visible="columns.lang.active"
            >
                <div slot="default" slot-scope="props">
                    <p class="mb-0">
                        <flag :iso="props.row.lang == 'fr_fr' ? 'fr' : 'us'" />
                    </p>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            :placeholder="$t('language.title') + '...'"
                            class="form-control form-control-sm"
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                :searchable="isSearchable"
                centered
                field="desired_publication_date"
                label="Deadlines"
                sortable
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.desired_publication_date">{{
                        moment(props.row.desired_publication_date).fromNow()
                    }}</span>
                    <span v-else>ASAP</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            class="form-control form-control-sm"
                            placeholder="Deadlines..."
                            type="date"
                        />
                    </div>
                </div>
            </b-table-column>
        </b-table>

        <input ref="copyInput" type="hidden" />

        <RedactorConfirmModal ref="confirmModal" @unassignRedactorWriting="doUnassignOrder" />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'WritingsUnassignedOrInProgressTable',
        props: ['indexType'],
        data: function () {
            return {
                search_field: '',
                loading: false,
                loadingPending: false,
                loadingCancel: false,
                loadingFinished: false,
                status: 'Tous',

                selectedRedactor: null,

                forceReviewIndex: -1,

                options: [],
                loadingIndex: null,

                writingId: null,
                writingIndex: null,

                isSearchable: true,
                isMobileWidth: window.innerWidth < 768
            }
        },
        created() {},
        computed: {
            ...mapState('redactors', {
                writings: state => state.writings,
                config: state => state.writingsConfig,

                wordsRemainingToWrite: state => state.wordsRemainingToWrite,
                maxWordsPerDay: state => state.maxWordsPerDay,

                columns: state => state.columns
            }),
            dailyWordsCount() {
                return (
                    Math.round((parseInt(this.wordsRemainingToWrite) / parseInt(this.maxWordsPerDay)) * 100) / 100
                ).toFixed(1)
            }
        },
        methods: {
            ...mapActions('redactors', [
                'getWritingDashboard',
                'setPriority',
                'setPending',
                'forceReview',
                'unassignWriting',
                'findRedactor',
                'assignRedactor',
                'deleteWriting',
                'setWritingFinished'
            ]),

            openAssignModal(id, index) {
                this.writingId = id
                this.writingIndex = index
                this.$refs.assignRedactorModal.show()
            },

            showPendingModal(writing, index) {
                this.$store.commit('redactors/setWriting', writing)

                this.writingId = writing.id
                this.writingIndex = index

                this.$refs.addPendingMessage.show()
            },

            showDeleteModal(writing, index) {
                const orderType = writing.entity_type === 1 ? 'commande' : 'précommande'

                this.$buefy.dialog.confirm({
                    title: 'Êtes-vous sûr ?',
                    message: `Êtes-vous sûr de vouloir supprimer la rédaction pour la <b>${orderType} #${writing.entity_id}</b>`,
                    confirmText: 'Supprimer',
                    cancelText: 'Annuler',
                    type: 'is-danger',
                    onConfirm: () => this.doDeleteWriting(writing, index),
                    onCancel: () => {}
                })
            },

            doDeleteWriting(writing, index) {
                this.writingIndex = index
                this.loadingCancel = true

                this.deleteWriting(writing)
                    .then(() => {
                        this.writings.data.splice(index, 1)
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.writingIndex = -1
                        this.loadingCancel = false
                    })
            },

            showSetWritingFinishedModal(writing, index) {
                const orderType = writing.entity_type === 1 ? 'commande' : 'précommande'

                this.$buefy.dialog.confirm({
                    title: 'Êtes-vous sûr ?',
                    message: `Mettre la <b>${orderType} #${writing.entity_id}</b> en terminé ?
                            Cela ne publiera rien, personne ne sera crédité, et vous devrez gérer la publication vous-même manuellement.`,
                    confirmText: 'Mettre en terminer',
                    cancelText: 'Annuler',
                    type: 'is-warning',
                    onConfirm: () => this.doSetWritingFinished(writing, index),
                    onCancel: () => {}
                })
            },

            doSetWritingFinished(writing, index) {
                this.writingIndex = index
                this.loadingCancel = true

                this.setWritingFinished(writing)
                    .then(() => {
                        this.writings.data.splice(index, 1)
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.writingIndex = -1
                        this.loadingCancel = false
                    })
            },

            onSearch(search, loading) {
                if (search.length) {
                    loading(true)
                    this.search(loading, search)
                }
            },

            search: function (loading, search) {
                this.findRedactor(search).then(data => {
                    this.options = data.redactors
                    loading(false)
                })
            },

            doAssignRedactor(redactor) {
                this.$refs.assignRedactorModal.hide()

                this.assignRedactor({
                    redactor: redactor,
                    id: this.writingId
                })
                    .then(() => {
                        let writing = this.writings.data[this.writingIndex]
                        writing.redactor = redactor
                        writing.redactor_id = redactor.id

                        this.writings.data.splice(this.writingIndex, 1, writing)
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'La commande a bien été attribué au rédacteur',
                            message: ''
                        })
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.writingId = -1
                        this.writingIndex = -1
                    })
            },

            doForceReview(index) {
                let writing = this.writings.data[index]
                this.forceReviewIndex = index

                this.forceReview(writing.id)
                    .then(data => {
                        writing.force_review = data.writing.force_review
                        this.writings.data.splice(index, 1, writing)
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.forceReviewIndex = -1
                    })
            },

            openConfirmModal(index, writing) {
                var data = {
                    index,
                    writing
                }
                this.$refs.confirmModal.openModal('processing', data)
            },

            doUnassignOrder(data) {
                this.loading = true
                this.unassignWriting(data.writing)
                    .then(() => {
                        var writing = this.writings.data[data.index]
                        writing.redactor = null
                        writing.redactor_id = null

                        this.writings.data.splice(data.index, 1, writing)
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },

            setPrio: function (index, id, isPrio) {
                this.loading = true
                this.loadingIndex = index

                this.setPriority({ id: id, priority: isPrio })
                    .then(() => {
                        let writing = this.writings.data[index]
                        writing.isPrio = isPrio

                        this.writings.data.splice(index, 1, writing)
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },

            savePendingMessage() {
                this.setPendingWriting(this.$store.state.redactors.writing, true, this.writingIndex)

                this.$refs.addPendingMessage.hide()
            },

            setPendingWriting(writing, isPending, index) {
                this.loadingPending = true
                this.loadingIndex = index

                let reason = isPending ? writing.reason : null

                this.setPending({ id: writing.id, pending: isPending, reason: reason })
                    .then(data => {
                        let writing = this.writings.data[index]
                        writing.writing_status_id = data.status
                        writing.reason = data.reason

                        this.writings.data.splice(index, 1, writing)
                        this.$store.commit('redactors/setWriting', writing)
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loadingPending = false
                    })
            },

            togglePendingOrder(order) {
                if (order.pending_content) {
                    this.$emit('togglePendingOrder', { order: order, reason: null })
                } else {
                    this.$refs.confirmModal.openModal('pending', order)
                }
            },

            searchWordsCount(item, inputStr) {
                if (item.content.option_mots + 200 == inputStr) {
                    return true
                }

                return false
            },

            copyContent(e, content) {
                let testingCodeToCopy = this.$refs.copyInput
                testingCodeToCopy.setAttribute('type', 'text')

                testingCodeToCopy.value = content
                testingCodeToCopy.select()

                try {
                    document.execCommand('copy')
                    var btn = e.currentTarget

                    btn.classList.replace('btn-light', 'btn-info')
                    setTimeout(() => btn.classList.replace('btn-info', 'btn-light'), 2000)
                } catch (err) {
                    console.error('No copy ' + err)
                }
                testingCodeToCopy.setAttribute('type', 'hidden')
            },

            loadAsyncData() {
                this.loading = true
                this.getWritingDashboard('processing')
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            onSort(field, order) {
                this.config.sort = field
                this.config.sortDirection = order

                this.loadAsyncData()
            },
            onFilterChange(filter) {
                var filters = {}
                Object.keys(filter).forEach(element => {
                    filters[element] = filter[element]
                })
                this.config.filters = filters

                this.loadAsyncData()
            },
            onPageChange(page) {
                this.config.page = page

                this.loadAsyncData()
            }
        },
        mounted() {}
    }
</script>

<style scoped>
    .assign-redactor {
        min-height: 400px !important;
    }
</style>
