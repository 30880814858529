<template>
    <div :class="[tabClass]" @click="activate()">
        <slot />
    </div>
</template>

<script>
    export default {
        data: () => ({
            active: false,
            visible: true,
            originalWidth: 0
        }),
        computed: {
            text() {
                return this.$el.textContent
            },
            tabClass() {
                const c = ['tab']
                if (this.active === true) {
                    c.push('active')
                }
                if (this.visible === false) {
                    c.push('hidden')
                }
                return c
            }
        },
        mounted() {
            this.originalWidth = this.$el.offsetWidth
            this.$parent.addItem(this)
        },
        methods: {
            activate() {
                this.$parent.activateItem(this)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tab {
        padding: 10px 20px;
        white-space: nowrap;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        margin-bottom: -1px;
        border: 1px solid transparent;

        &.active {
            background-color: #f8f9fa;
            border: 1px solid #dee2e6;
            border-bottom: 1px solid #f8f9fa;
        }
    }

    .tab:first-child {
        margin-left: 0;
    }

    .tab:last-child {
        margin-right: 0;
    }

    .tab:hover {
        cursor: pointer;
    }

    .active {
    }

    .hidden {
        visibility: hidden;
    }
</style>
