<template>
    <div v-if="!allLoaded" class="box p-5" style="position: relative">
        <b-loading active :is-full-page="false" />
    </div>
    <ValidationObserver ref="formObserver" v-else v-slot="{ handleSubmit }">
        <form
            id="form-filter"
            :class="`d-flex flex-column ${wrapperClass || 'box'}`"
            style="gap: 0.5rem"
            @submit.prevent="handleSubmit(onSubmit)"
        >
            <div class="d-flex flex-wrap" style="gap: 0.5rem">
                <ValidationProvider :name="name" :rules="rules" class="flex-grow-1">
                    <b-field expanded class="flex-grow-1">
                        <b-input
                            v-if="!isGSC"
                            autofocus
                            autocomplete="on"
                            icon="search"
                            :name="name"
                            :placeholder="placeholder"
                            :type="inputType"
                            :value="filters[inputKey]"
                            @input="updateFilters(inputKey, $event, !urlSearch)"
                            @keydown.enter="onSubmit(true)"
                        />
                        <b-autocomplete
                            v-else
                            v-model="siteName"
                            expanded
                            icon-right="angle-down"
                            open-on-focus
                            :custom-formatter="site => site.siteUrl.replace('sc-domain:', '')"
                            :placeholder="$t('gsc.chooseSite')"
                            :data="filteredSites"
                            :disabled="isGSC && !tokenValid"
                            @select="updateFilters('site', $event.siteUrl.replace('sc-domain:', ''))"
                        />
                    </b-field>
                </ValidationProvider>
                <b-button
                    icon-left="search"
                    type="is-warning"
                    :disabled="isGSC && !tokenValid"
                    @click="onSubmit(true)"
                />
                <template v-if="isGSC">
                    <b-button
                        @click="$emit('googleRevoke')"
                        icon-left="google"
                        icon-pack="fab"
                        type="is-danger"
                        v-if="tokenValid"
                    >
                        {{ $t('gsc.logout') }}
                    </b-button>
                    <b-button @click="$emit('googleLogin')" icon-left="google" icon-pack="fab" type="is-success" v-else>
                        {{ $t('gsc.login') }}
                    </b-button>
                </template>
                <b-button
                    :icon-left="extended ? 'minus' : 'plus'"
                    type="is-info"
                    @click="setExtended(!extended)"
                    :label="String(!extended ? $t('next-level.filtersmore') : $t('next-level.filtersless'))"
                />
            </div>
            <div v-if="urlSearch" class="filter-wrapper">
                <b-field :label="$t('searchform.position')" style="grid-column: span 2">
                    <div class="d-flex" style="gap: 0.5rem">
                        <b-input
                            class="w-100"
                            :placeholder="$t('searchform.positionmin')"
                            :controls="false"
                            :value="filters.positionMin"
                            @input="updateFilters('positionMin', $event)"
                            expanded
                        />
                        <b-input
                            class="w-100"
                            :placeholder="$t('searchform.positionmax')"
                            :controls="false"
                            :value="filters.positionMax"
                            @input="updateFilters('positionMax', $event)"
                            expanded
                        />
                    </div>
                </b-field>
                <b-field :label="$t('searchform.volume')" style="grid-column: span 2">
                    <div class="d-flex" style="gap: 0.5rem">
                        <b-input
                            class="w-100"
                            :placeholder="$t('searchform.volumemin')"
                            :controls="false"
                            :value="filters.volumeMin"
                            @input="updateFilters('volumeMin', $event)"
                            expanded
                        />
                        <b-input
                            class="w-100"
                            :placeholder="$t('searchform.volumemax')"
                            :controls="false"
                            :value="filters.volumeMax"
                            @input="updateFilters('volumeMax', $event)"
                            expanded
                        />
                    </div>
                </b-field>
                <b-field :label="$t('searchform.withkeyword')">
                    <b-input
                        class="w-100"
                        :placeholder="$t('global.keyword')"
                        :controls="false"
                        :value="filters.keywordSearch"
                        @input="updateFilters('keywordSearch', $event)"
                        expanded
                    />
                </b-field>
            </div>
            <div v-if="isGSC" class="filter-wrapper">
                <b-field label="URL" style="grid-column: span 2">
                    <b-input
                        class="w-100"
                        placeholder="Url"
                        :controls="false"
                        :value="filters.urlSearch"
                        @input="updateFilters('urlSearch', $event)"
                        expanded
                    />
                </b-field>
                <b-field :label="$t('searchform.withkeyword')" style="grid-column: span 2">
                    <b-input
                        class="w-100"
                        :placeholder="$t('searchform.withkeyword')"
                        :controls="false"
                        :value="filters.keywordSearch"
                        @input="updateFilters('keywordSearch', $event)"
                        expanded
                    />
                </b-field>
                <b-field label="Position" style="grid-column: span 2">
                    <b-slider
                        rounded
                        :value="[filters.positionMin, filters.positionMax]"
                        @change="updateRangeFilters('position', $event, 'positionMin', 'positionMax')"
                        :min="0"
                        :max="100"
                    >
                        <b-slider-tick :value="0">0</b-slider-tick>
                        <b-slider-tick :value="25">25</b-slider-tick>
                        <b-slider-tick :value="50">50</b-slider-tick>
                        <b-slider-tick :value="75">75</b-slider-tick>
                        <b-slider-tick :value="100">100</b-slider-tick>
                    </b-slider>
                </b-field>
                <b-field :label="$t('searchform.impressions')">
                    <div class="d-flex" style="gap: 0.5rem">
                        <b-input
                            class="w-100"
                            placeholder="Min."
                            :controls="false"
                            :value="filters.price_min"
                            @change="updateFilters('impressionsMin', $event)"
                            expanded
                        />
                        <b-input
                            class="w-100"
                            placeholder="Max."
                            :controls="false"
                            :value="filters.price_max"
                            @input="updateFilters('impressionsMax', $event)"
                            expanded
                        />
                    </div>
                </b-field>
                <b-field :label="$t('searchform.clicks')">
                    <div class="d-flex" style="gap: 0.5rem">
                        <b-input
                            class="w-100"
                            :placeholder="$t('searchform.clicks-min')"
                            :controls="false"
                            :value="filters.price_min"
                            @input="updateFilters('clicksMin', $event)"
                            expanded
                        />
                        <b-input
                            class="w-100"
                            :placeholder="$t('searchform.clicks-max')"
                            :controls="false"
                            :value="filters.price"
                            @input="updateFilters('clicksMax', $event)"
                            expanded
                        />
                    </div>
                </b-field>
            </div>
            <hr />
            <div class="filter-wrapper">
                <ValidationProvider name="thematics" class="flex-grow-1">
                    <b-field :label="$t('global.theme')" class="filter-large">
                        <b-taginput
                            v-model="thematics"
                            field="localized_translations.name"
                            group-field="localized_translations.name"
                            group-options="children"
                            :data="filteredThematics"
                            :placeholder="$t('global.theme')"
                            expanded
                            autocomplete
                            @typing="getFilteredThematics"
                            open-on-focus
                        />
                    </b-field>
                </ValidationProvider>
                <b-field label="Afficher les sites où j'ai déjà acheté">
                    <b-switch
                        :value="filters.allowBought"
                        @input="updateFilters('allowBought', $event)"
                        type="is-warning"
                    >
                        {{ filters.allowBought ? $t('global.yes') : $t('global.no') }}
                    </b-switch>
                </b-field>
                <b-field :label="$t('global.price')">
                    <div class="d-flex" style="gap: 0.5rem">
                        <b-input
                            class="w-100"
                            :placeholder="$t('global.minprice')"
                            :controls="false"
                            :value="filters.price_min"
                            @input="updateFilters('price_min', $event)"
                            expanded
                        />
                        <b-input
                            class="w-100"
                            :placeholder="$t('global.maxprice')"
                            :controls="false"
                            :value="filters.price_max"
                            @input="updateFilters('price_max', $event)"
                            expanded
                        />
                    </div>
                </b-field>
                <b-field label="Source" class="filter-large">
                    <div class="source-selector">
                        <div :class="{ active: filters.source === null }" @click="updateFilters('source', null)">
                            {{ $t('global.all') }}
                        </div>
                        <div :class="{ active: filters.source === 'nl' }" @click="updateFilters('source', 'nl')">
                            NextLevel
                        </div>
                        <div
                            :class="{ active: filters.source === 'partner' }"
                            @click="updateFilters('source', 'partner')"
                        >
                            {{ $t('favorites.partners') }}
                        </div>
                    </div>
                </b-field>
            </div>
            <b-collapse v-model="extended" animation="slide">
                <div class="d-flex flex-column" style="gap: 0.5rem">
                    <hr />
                    <div class="filter-wrapper">
                        <b-field :label="$t('favorites.excludedtheme')" class="filter-large">
                            <b-taginput
                                v-model="thematicsExcluded"
                                :data="filteredThematicsExcluded"
                                field="localized_translations.name"
                                group-field="localized_translations.name"
                                group-options="children"
                                :placeholder="$t('favorites.excludedtheme')"
                                expanded
                                autocomplete
                                @typing="getFilteredThematicsExcluded"
                                open-on-focus
                            />
                        </b-field>
                        <b-field :label="$t('favorites.partners')" v-if="isAdmin">
                            <b-autocomplete
                                clearable
                                expanded
                                field="text"
                                :data="partners"
                                :placeholder="$t('favorites.partners')"
                                v-model="partnerName"
                                @input="getPartners"
                                @select="updateFilters('partner', $event?.id || null)"
                            />
                        </b-field>
                        <b-field :label="$t('tableau-keywords.categorysite')">
                            <b-select :value="filters.type" @input="updateFilters('type', $event)" expanded>
                                <option :value="null">
                                    {{ $t('global.all') }}
                                </option>
                                <option
                                    v-for="(categorie, key) in filterOptions.trie_categorie"
                                    :value="key"
                                    :key="`trie_categorie_${key}`"
                                >
                                    {{ categorie }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field :label="$t('favorites.noadultfilter')">
                            <b-switch :value="filters.adult" @input="updateFilters('adult', $event)" type="is-warning">
                                {{ filters.adult ? $t('global.yes') : $t('global.no') }}
                            </b-switch>
                        </b-field>
                    </div>
                    <hr />
                    <div class="filter-wrapper">
                        <b-field :label="$t('tableau-keywords.siteAge')" v-if="isAdmin">
                            <b-select :value="filters.siteAge" @input="updateFilters('siteAge', $event)" expanded>
                                <option :value="null">
                                    {{ $t('global.all') }}
                                </option>
                                <option
                                    v-for="(site_age, key) in filterOptions.trie_site_age"
                                    :value="key"
                                    :key="`trie_site_age_${key}`"
                                >
                                    {{ site_age }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field label="Top" class="filter-small">
                            <b-select :value="filters.top" @input="updateFilters('top', $event)" expanded>
                                <option :value="null">
                                    {{ $t('global.all') }}
                                </option>
                                <option
                                    v-for="(top, i) in filterOptions.trie_top_number"
                                    :value="top"
                                    :key="`top_${i}`"
                                >
                                    Top {{ top }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field :label="$t('global.quantity')" class="filter-small">
                            <b-select :value="filters.volume" @input="updateFilters('volume', $event)" expanded>
                                <option :value="null">
                                    {{ $t('global.all') }}
                                </option>
                                <option
                                    v-for="(volume, i) in filterOptions.trie_volume_number"
                                    :value="volume"
                                    :key="`volume_${i}`"
                                >
                                    Min. {{ volume }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field label="Nb KW" class="filter-small">
                            <b-select
                                :value="filters.keywordsCount"
                                @input="updateFilters('keywordsCount', $event)"
                                expanded
                            >
                                <option :value="null">
                                    {{ $t('global.all') }}
                                </option>
                                <option
                                    v-for="(nbKw, i) in filterOptions.trie_nb_kw_number"
                                    :value="nbKw"
                                    :key="`nbKw_${i}`"
                                >
                                    Min. {{ nbKw }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field label="Nb top 10" class="filter-small">
                            <b-select
                                :value="filters.topTenCount"
                                @input="updateFilters('topTenCount', $event)"
                                expanded
                            >
                                <option :value="null">
                                    {{ $t('global.all') }}
                                </option>
                                <option
                                    v-for="(nbTop, i) in filterOptions.trie_nb_top10_number"
                                    :value="nbTop"
                                    :key="`nbTop_${i}`"
                                >
                                    Min. {{ nbTop }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field :label="$t('tableau-keywords.urlindice')" class="filter-small">
                            <b-select :value="filters.index" @input="updateFilters('index', $event)" expanded>
                                <option :value="null">
                                    {{ $t('global.all') }}
                                </option>
                                <option
                                    v-for="(indiceUrl, i) in filterOptions.trie_indice_url_number"
                                    :value="indiceUrl"
                                    :key="`indiceUrl_${i}`"
                                >
                                    Min. {{ indiceUrl }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                    <template v-if="security.displaySiteKpi">
                        <hr />
                        <div class="filter-wrapper">
                            <b-field label="RD">
                                <div class="d-flex" style="gap: 0.5rem">
                                    <b-input
                                        class="w-100"
                                        :placeholder="$t('searchform.rank-domain-min')"
                                        :controls="false"
                                        :value="filters.rd_min"
                                        @input="updateFilters('rd_min', $event)"
                                        expanded
                                    />
                                    <b-input
                                        class="w-100"
                                        :placeholder="$t('searchform.rank-domain-max')"
                                        :controls="false"
                                        :value="filters.rd_max"
                                        @input="updateFilters('rd_max', $event)"
                                        expanded
                                    />
                                </div>
                            </b-field>
                            <b-field label="DR">
                                <b-slider
                                    rounded
                                    :value="[filters.dr_min, filters.dr_max]"
                                    @change="updateRangeFilters('dr', $event)"
                                    :min="0"
                                    :max="100"
                                >
                                    <b-slider-tick :value="0">0</b-slider-tick>
                                    <b-slider-tick :value="25">25</b-slider-tick>
                                    <b-slider-tick :value="50">50</b-slider-tick>
                                    <b-slider-tick :value="75">75</b-slider-tick>
                                    <b-slider-tick :value="100">100</b-slider-tick>
                                </b-slider>
                            </b-field>
                            <b-field label="TF">
                                <b-slider
                                    rounded
                                    :value="[filters.tf_min, filters.tf_max]"
                                    @change="updateRangeFilters('tf', $event)"
                                    :min="0"
                                    :max="100"
                                >
                                    <b-slider-tick :value="0">0</b-slider-tick>
                                    <b-slider-tick :value="25">25</b-slider-tick>
                                    <b-slider-tick :value="50">50</b-slider-tick>
                                    <b-slider-tick :value="75">75</b-slider-tick>
                                    <b-slider-tick :value="100">100</b-slider-tick>
                                </b-slider>
                            </b-field>
                            <b-field label="CF">
                                <b-slider
                                    rounded
                                    :value="[filters.cf_min, filters.cf_max]"
                                    @input="updateRangeFilters('cf', $event)"
                                    :min="0"
                                    :max="100"
                                >
                                    <b-slider-tick :value="0">0</b-slider-tick>
                                    <b-slider-tick :value="25">25</b-slider-tick>
                                    <b-slider-tick :value="50">50</b-slider-tick>
                                    <b-slider-tick :value="75">75</b-slider-tick>
                                    <b-slider-tick :value="100">100</b-slider-tick>
                                </b-slider>
                            </b-field>
                        </div>
                    </template>
                    <div class="d-flex justify-content-end">
                        <b-button
                            icon-left="undo-alt"
                            type="is-info is-light"
                            @click="resetFilters"
                            :label="$t('global.reinitialize')"
                        />
                    </div>
                </div>
            </b-collapse>
        </form>
    </ValidationObserver>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import { mapActions, mapMutations, mapState } from 'vuex'
    import { isEqual } from 'lodash'
    import { cleanObject } from '@/helpers/objectHelpers'
    import { throttle } from 'lodash/function'
    import debounce from 'lodash/debounce'

    export default {
        name: 'NlSearchFilters',
        props: {
            filterThematic: Array,
            displayThematic: {
                type: Boolean,
                default: true
            },
            inputKey: {
                type: String,
                default: 'keyword'
            },
            inputType: {
                type: String,
                default: 'text'
            },
            isGSC: {
                type: Boolean,
                default: false
            },
            name: {
                type: String,
                default: 'search'
            },
            placeholder: {
                type: String,
                default() {
                    return this.$t('global.research')
                }
            },
            rules: {
                type: String,
                required: false,
                default: ''
            },
            sites: {
                type: Array,
                required: false
            },
            tokenValid: {
                type: Boolean,
                default: false
            },
            campaignProject: {
                type: Object,
                required: false,
                default: () => ({})
            },
            urlSearch: {
                type: Boolean,
                required: false,
                default: false
            },
            value: {
                type: Object,
                required: false
            },
            wrapperClass: {
                type: String,
                default: ''
            },
            forceSubmit: {
                type: Boolean,
                required: false
            },
            isFavorite: {
                type: Boolean,
                required: false
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data: function () {
            return {
                filterOptions: {},
                partners: [],
                thematics: [],
                thematicsExcluded: [],
                filteredThematics: [],
                filteredThematicsExcluded: [],
                groups: [],
                partnerName: null,
                siteName: '',
                prevFilters: null
            }
        },
        created() {
            this.getFiltersOptions().then(data => {
                this.filterOptions = data
            })
            this.getGroups().then(data => {
                this.groups = data
                this.thematics = data.find(e => e.id === this.filters.thematic[0])
                    ? [data.find(e => e.id === this.filters.thematic[0])]
                    : []
                this.getFilteredThematics('')
                this.getFilteredThematicsExcluded('')
            })
        },
        computed: {
            ...mapState('auth', {
                isAdmin: state => {
                    if (state.user) {
                        return state.user.role === 'admin' || state.connectedAs
                    }
                    return false
                }
            }),
            ...mapState('auth', {
                security: 'security'
            }),
            ...mapState({
                project: state => state.project
            }),
            ...mapState('search', {
                filters: 'filters',
                extended: 'filterExtended'
            }),
            projectId() {
                return this.campaignProject?.id ?? this.project?.id
            },
            computedGroups() {
                return this.groups.map(group => {
                    return {
                        ...group,
                        children: [
                            {
                                id: group.id,
                                localized_translations: group.localized_translations
                            },
                            ...group.children
                        ]
                    }
                })
            },
            filteredSites() {
                if (this.sites) {
                    return [...this.sites]
                        .filter(site => {
                            return (
                                site.siteUrl
                                    .toString()
                                    .toLowerCase()
                                    .replace('sc-domain:', '')
                                    .indexOf(this.siteName.toLowerCase()) >= 0
                            )
                        })
                        .sort((a, b) => {
                            if (a.name < b.name) return -1
                            if (a.name > b.name) return 1
                            return 0
                        })
                }
                return []
            },
            allLoaded() {
                return (
                    (this.project || this.campaignProject) &&
                    (!this.$route.query.keyword || decodeURI(this.$route.query.keyword) === this.filters.keyword)
                )
            }
        },
        watch: {
            thematics(thematics) {
                this.updateFilters(
                    'thematic',
                    thematics.map(e => e.id)
                )
            },
            thematicsExcluded(thematicsExcluded) {
                this.updateFilters(
                    'thematicExcluded',
                    thematicsExcluded.map(e => e.id)
                )
            },
            forceSubmit() {
                this.onSubmit()
            },
            project(project) {
                this.updateFilters('url', project?.website)
            }
        },
        methods: {
            ...mapActions('search', {
                getAutocompletePartners: 'getAutocompletePartners',
                getFiltersOptions: 'getFiltersOptions',
                resetSearch: 'resetSearch'
            }),
            ...mapActions('group', {
                getGroups: 'getAll'
            }),
            ...mapMutations('search', {
                setFilters: 'setFilters',
                setExtended: 'setFilterExtended',
                setMeta: 'setMeta',
                resetResults: 'resetResults'
            }),
            getPartners(value) {
                if (value !== '') {
                    this.getAutocompletePartners(value).then(data => {
                        this.partners = data
                    })
                }
            },
            getFilteredThematics(text) {
                this.filteredThematics = this.computedGroups.filter(option => {
                    return option.localized_translations.name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
                })
            },
            getFilteredThematicsExcluded(text) {
                this.filteredThematicsExcluded = this.computedGroups.filter(option => {
                    return option.localized_translations.name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
                })
            },
            updateFilters: debounce(function (index, value, updateUrl = false) {
                this.setFilters({
                    ...this.filters,
                    [index]: value
                })

                if (updateUrl) {
                    this.updateUrl(index, value)
                }

                this.setMeta({
                    page: 1,
                    total: 0
                })

                this.onSubmit()
            }, 500),
            updateRangeFilters(index, value, customKeyMin = null, customKeyMax = null) {
                this.setFilters({
                    ...this.filters,
                    [customKeyMin || index + '_min']: value[0] === 0 ? null : value[0],
                    [customKeyMax || index + '_max']: value[1] === 100 ? null : value[1]
                })

                this.setMeta({
                    page: 1,
                    total: 0
                })

                this.onSubmit()
            },
            async onSubmit(force = false) {
                const isValid = await this.$refs.formObserver.validate()

                if (
                    !isValid ||
                    (!this.filters[this.inputKey] && (this.urlSearch || !this.thematics.length) && !this.isFavorite)
                ) {
                    this.resetResults()
                    return
                }

                const params = {
                    ...cleanObject(this.filters)
                }

                this.prevFilters = params

                if (!isEqual(this.prevFilters, this.filters) || force) {
                    this.$emit('search', params)
                }
            },
            resetFilters() {
                this.thematics = []
                this.thematicsExcluded = []
                this.partnerName = ''
                this.resetSearch(false)
            },
            updateUrl(key, value) {
                const urlSearchParams = new URLSearchParams(window.location.search)
                const params = {
                    ...Object.fromEntries(urlSearchParams.entries()),
                    [key]: encodeURIComponent(value)
                }
                const searchParams = new URLSearchParams(params)

                history.pushState({}, null, this.$route.path + '?' + searchParams.toString())

                this.$route.query[key] = encodeURIComponent(value)
            }
        },
        mounted() {
            let filters = {}
            if (!this.filters.url || this.campaignProject) {
                filters = {
                    ...{
                        /*lang: this.campaignProject?.lang || this.project?.project_lang,*/
                        url: this.campaignProject?.website || this.project?.website
                    }
                }
            }
            if (this.$route.query.keyword) {
                filters = {
                    ...filters,
                    ...{
                        keyword: decodeURI(this.$route.query.keyword)
                    }
                }
            }
            if (Object.keys(filters)) {
                this.setFilters(filters)
            }
        },
        beforeDestroy() {
            this.resetFilters()
        }
    }
</script>

<style lang="scss" scoped>
    .collapse-icon {
        transition: all 0.2s ease;
    }

    .collapse-icon-open {
        transform: rotate(180deg);
    }

    .collapse {
        display: block !important;
    }

    .collapse-list {
        display: flex;
        flex-flow: column;
        padding-left: 32px;
    }

    ::v-deep .collapse-list > * {
        margin-bottom: 0.875rem;
    }

    .filter-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-auto-flow: row dense;
        gap: 1.625rem;

        & > * {
            grid-column: span 2;
        }
    }

    .filter-small {
        grid-column: span 1;
    }

    .filter-large {
        grid-column: span 3;
    }

    ::v-deep .field {
        display: flex;
        flex-flow: column;
        margin-bottom: 0;
    }

    ::v-deep .switch {
        align-items: center;
        flex: 1 1 auto;
        margin: 0;
    }

    .source-selector {
        display: flex;
        background-color: #f9f9f9;
        border-radius: 6px;
        gap: 0.5rem;
        color: #a9a8bd;

        > * {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 1 auto;
            cursor: pointer;
            border-radius: 6px;
            height: 40px;
        }

        > *:hover,
        .active {
            color: black;
        }

        > .active {
            background-color: #f9bc14;
        }
    }

    @media screen and (max-width: 425px) {
        .filter-wrapper {
            display: flex;
            flex-direction: column;
        }
    }
</style>