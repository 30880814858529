<template>
    <nl-modal :open-modal="openModal" :title="$t('projets.project')" size="xl" @hidden="close">
        <form class="form-inline">
            <div class="form-group mr-sm-2">
                <label class="mr-2" for="project-name">{{ $t('projets.create') }}</label>
                <input
                    v-model="newProject.name"
                    type="text"
                    class="form-control mr-2"
                    :placeholder="$t('projets.name')"
                    required=""
                />
                <div class="input-group mr-2">
                    <input
                        v-model="newProject.budget"
                        type="text"
                        class="form-control"
                        :placeholder="$t('projets.budget')"
                    />
                    <div class="input-group-append">
                        <span class="input-group-text">€</span>
                    </div>
                </div>
                <input
                    v-model="newProject.destinataires_mails"
                    type="text"
                    class="form-control mr-2"
                    :placeholder="$t('projets.mailrecipient')"
                    autocomplete="off"
                    required=""
                />
            </div>
            <button type="submit" class="btn btn-primary mr-2" @click.prevent="create">
                {{ $t('projets.createproject') }}
            </button>
        </form>
        <div>
            <table class="table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th>{{ $t('projets.project') }}</th>
                        <th>{{ $t('projets.spendings') }}</th>
                        <th>{{ $t('projets.budget') }}</th>
                        <th>{{ $t('projets.mailrecipient') }}</th>
                        <th>{{ $t('global.action') }}Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(project, index) in projects" :key="index">
                        <td>
                            <div v-if="editIndex != index">
                                {{ project.name }}
                            </div>
                            <div v-else class="form-inline">
                                <div class="form-group">
                                    <input
                                        v-model="editProject.name"
                                        type="text"
                                        class="form-control form-control-sm"
                                        autocomplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            {{ project.paid_price | currency }}
                        </td>
                        <td>
                            <div v-if="editIndex != index">
                                {{ project.budget | currency }}
                            </div>
                            <div v-else class="form-inline">
                                <div class="form-group">
                                    <input
                                        v-model="editProject.budget"
                                        type="text"
                                        class="form-control form-control-sm"
                                        autocomplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            <div v-if="editIndex != index">
                                {{ project.destinataires_mails }}
                            </div>
                            <div v-else class="form-inline">
                                <div class="form-group">
                                    <input
                                        v-model="editProject.destinataires_mails"
                                        type="text"
                                        class="form-control form-control-sm"
                                        autocomplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            <div v-if="editIndex != index">
                                <button v-if="moving.type" class="btn btn-sm btn-primary mr-2" @click="moveTo(project)">
                                    {{ $t('projets.mooveto') }}
                                </button>
                                <button
                                    v-if="project.name != 'non-classe'"
                                    class="btn btn-sm btn-primary project-rename-btn mr-2"
                                    @click="edit(project, index)"
                                >
                                    {{ $t('global.modify') }}
                                </button>
                                <button
                                    v-if="project.name != 'non-classe'"
                                    class="btn btn-sm btn-danger project-delete-btn"
                                    @click="askDelete(project)"
                                >
                                    {{ $t('global.delete') }}
                                </button>
                            </div>
                            <div v-else>
                                <button class="btn btn-sm btn-primary project-rename-submit-btn mr-2" @click="update">
                                    {{ $t('global.validate') }}
                                </button>
                                <button class="btn btn-sm btn-danger project-rename-cancel-btn" @click="cancelEdit">
                                    {{ $t('global.cancel') }}
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <template #footer>
            <button class="btn btn-sm btn-primary" @click="close">Annuler</button>
        </template>
    </nl-modal>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import { EventManager } from '../EventManager'

    export default {
        components: {
            EventManager
        },
        data: () => ({
            openModal: false,
            editProject: {},
            editIndex: null,
            newProject: {},
            moving: {
                type: null,
                object: null
            }
        }),

        computed: {
            ...mapGetters('projects', ['projects'])
        },
        created() {
            EventManager.$on('movingProject', this.movingProject)
            EventManager.$on('onModalProject', this.open)
        },
        mounted() {
            this.getProjects()
        },
        methods: {
            ...mapActions('projects', {
                getProjects: 'list',
                updateProject: 'update',
                deleteProject: 'delete',
                createProject: 'create'
            }),
            ...mapActions('favorites', { moveFavorite: 'moveToProject' }),
            movingProject(data) {
                this.moving.type = data.type
                this.moving.object = data.object
                this.open()
            },
            open() {
                this.openModal = true
            },
            close() {
                this.moving.type = null
                this.moving.object = null
                this.openModal = false
            },
            edit(project, index) {
                this.editProject = Object.assign({}, project)
                this.editIndex = index
            },
            cancelEdit() {
                this.editProject = Object.assign({})
                this.editIndex = null
            },
            update() {
                this.updateProject(this.editProject).then(() => {
                    this.cancelEdit()
                    this.getProjects()
                })
            },
            askDelete(project) {
                if (confirm(this.$t('next-level.confirm'))) {
                    this.deleteProject(project).then(() => {
                        this.getProjects()
                    })
                }
            },
            create() {
                if (this.newProject.name) {
                    this.newProject.project_lang = this.$store.state.locale
                    this.createProject(this.newProject).then(() => {
                        this.newProject = Object.assign({})
                        this.getProjects()
                    })
                }
            },
            moveTo(project) {
                if (this.moving.type == 'favorite') {
                    if (this.moving.object.originalProject !== project.id) {
                        this.moveFavorite({
                            stat_id: this.moving.object.stat_id,
                            original_project_id: this.moving.object.originalProject,
                            new_project_id: project.id
                        })
                    }
                }
                EventManager.$emit('movedProject', {
                    type: this.moving.type,
                    object: this.moving.object
                })
                this.close()
            }
        }
    }
</script>

<style></style>
