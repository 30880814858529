<template>
    <div>
        <div>
            <button v-if="!readonly" class="button is-small is-light" @click="open()">
                {{ keyword.rd_kw }}
            </button>
            <span v-else>
                {{ keyword.rd_kw }}
            </span>
        </div>
        <nl-modal title="RefDomains" :open-modal="openModal" size="xl" @hidden="openModal = false">
            <div v-if="loadingModal" class="d-flex justify-content-between align-items-center" style="height: 300px">
                <b-loading v-model="loadingModal" :is-full-page="false" />
            </div>

            <div v-else>
                <p class="text-right -mt-1">
                    <button class="button is-small is-light" @click="refresh">
                        <i class="fas fa-sync-alt mr-1" />
                        Rafraîchir
                    </button>
                </p>

                <div v-if="backlinks && backlinks.length">
                    <b-table :data="backlinks">
                        <b-table-column field="anchor" label="Ancre">
                            <div slot="default" slot-scope="props">
                                <span>{{ props.row.majestic.anchor }}</span>
                            </div>
                        </b-table-column>

                        <b-table-column field="url" label="URL" width="30%">
                            <div slot="default" slot-scope="props">
                                <span v-for="(h, i) in formatHosts(props.row.hosts)" :key="`host_${i}`"
                                    >{{ h.url }}
                                    <a
                                        target="_blank"
                                        :href="`https://fr.majestic.com/reports/site-explorer/referring-domains?q=${keyword.url}&oq=${keyword.url}&IndexDataSource=F#links`"
                                        ><i v-if="h.counter > 10" class="fas fa-exclamation-triangle"
                                    /></a>

                                    <br />
                                </span>
                            </div>
                        </b-table-column>

                        <b-table-column label="Optimisation">
                            <div slot="default" slot-scope="props">
                                <span>{{ computePercentage(props.row.hosts) }}</span>
                            </div>
                        </b-table-column>

                        <b-table-column field="is_optimized" label="Optimisé">
                            <div slot="default" slot-scope="props">
                                <div>
                                    <b-switch
                                        v-model="props.row.majestic.is_optimized"
                                        :true-value="1"
                                        :false-value="0"
                                        type="is-success"
                                    >
                                        {{ props.row.majestic.is_optimized ? 'Optimisé' : 'Non-optimisé' }}
                                    </b-switch>
                                </div>
                            </div>
                        </b-table-column>
                    </b-table>

                    <div class="mt-4 d-flex justify-content-end">
                        <button class="button is-light mr-2" @click="$emit('closeModal')">Annuler</button>
                        <button class="button is-warning" @click="saveRefDomains">Enregistrer</button>
                    </div>
                </div>

                <div v-else class="notification is-light is-warning">Aucun RD trouvé !</div>
            </div>
        </nl-modal>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'CampaignRefDomains',

        props: {
            keyword: {
                required: true,
                type: Object
            },
            readonly: {
                required: false,
                default: false,
                type: Boolean
            }
        },

        data() {
            return {
                backlinks: [],
                loadingModal: false,
                openModal: false
            }
        },

        watch: {
            lastUrlOpen: {
                handler() {
                    this.loadingModal = !this.loadingModal
                }
            }
        },

        methods: {
            ...mapActions('campaigns', ['storeRD']),
            saveRefDomains() {
                this.loadingModal = true

                this.backlinks.forEach(element => {
                    if (element.majestic.anchor === null || element.majestic.anchor == '') {
                        element.majestic.anchor = 'aucun ancre de lien'
                    }
                })

                let params = this.backlinks.map(e => {
                    return {
                        anchor: e.majestic.anchor,
                        is_optimized: e.majestic.is_optimized,
                        hosts: Object.keys(e.hosts).map(i => {
                            return {
                                counter: e.hosts[i].counter,
                                host: i
                            }
                        })
                    }
                })

                this.storeRD({ id: this.keyword.id, rd: params })
                    .catch(e => {
                        console.error(e)
                    })
                    .finally(() => {
                        this.$emit('closeModal')
                        this.loadingModal = false
                    })
            },

            refresh() {
                this.loadingModal = true
                this.getBackLinksInformations(true)
            },
            formatBacklinks(backlinks) {
                if (backlinks.length > 0) {
                    let usedAnchor = []
                    backlinks.forEach(e => {
                        if (!usedAnchor.includes(e.anchor)) {
                            usedAnchor.push(e.anchor)
                        }
                    })
                    return usedAnchor
                } else {
                    return null
                }
            },
            formatHosts(hosts) {
                let tab = []
                tab = Object.keys(hosts).map(e => {
                    return {
                        url: e,
                        counter: hosts[e].counter
                    }
                })
                return tab
            },
            computePercentage(hosts) {
                let hostsCounter = 0
                this.backlinks.forEach(e => {
                    hostsCounter = hostsCounter + Object.keys(e.hosts).length
                })
                return Math.round((Object.keys(hosts).length / hostsCounter) * 100 * 100) / 100 + '%'
            },
            open(refresh = false) {
                this.openModal = true

                if (!this.backlinks.length > 0 || refresh) {
                    this.getBackLinksInformations(refresh)
                }
            },
            getBackLinksInformations(refresh = false) {
                this.loadingModal = true

                this.axios
                    .get(`/campaigns/refdomains/${this.keyword.id}${refresh ? '/refresh' : ''}`)
                    .then(({ data }) => {
                        this.backlinks = data.backlinks
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.loadingModal = false))
            }
        }
    }
</script>
