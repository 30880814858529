export default {
    checkRole: state => roles => {
        if (roles && state.user) {
            if (!Array.isArray(roles)) {
                roles = [roles]
            }
            for (let idx = 0; idx < roles.length; idx++) {
                const role = roles[idx]
                if (role === state.user.role) {
                    return true
                }
            }
        }
        return false
    },

    user: state => {
        return state.user
    },

    locale(state) {
        return state.locale
    },

    credit(state) {
        return state.credit
    },

    security(state) {
        return state.security
    }
}
