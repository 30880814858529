<template>
    <div>
        <b-table
            :data="accompagnementsList.data"
            :paginated="true"
            default-sort-direction="desc"
            :striped="true"
            :debounce-search="200"
            :loading="isLoading"
            :default-sort="[sortField, sortOrder]"
            :per-page="accompagnementsList.per_page"
            :total="accompagnementsList.total"
            backend-sorting
            backend-pagination
            backend-filtering
            @page-change="onPageChange"
            @sort="onSort"
            @filters-change="onFilterChange"
        >
            <b-table-column field="url" sortable :searchable="isSearchable" label="Site" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    {{ props.row.url }}
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        step="0.01"
                        class="form-control form-control-sm"
                        placeholder="Site"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="name"
                sortable
                :searchable="isSearchable"
                :label="$t('mes-accompagnements.sitecategory')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    {{ props.row.name }}
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        step="0.01"
                        class="form-control form-control-sm"
                        :placeholder="$t('mes-accompagnements.sitecategory')"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="duree"
                sortable
                :searchable="isSearchable"
                :label="$t('mes-accompagnements.time')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    {{ props.row.duree }}
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm"
                        :placeholder="$t('mes-accompagnements.time')"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="budget_mensuel"
                sortable
                :searchable="isSearchable"
                :label="$t('mes-accompagnements.budget')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">{{ props.row.budget_mensuel }} €</div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm"
                        :placeholder="$t('mes-accompagnements.budget')"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="budget_global"
                sortable
                :searchable="isSearchable"
                :label="$t('mes-accompagnements.budgetglobal')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">{{ props.row.budget_global }} €</div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm"
                        :placeholder="$t('mes-accompagnements.budgetglobal')"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="debut_accompagnement"
                sortable
                :searchable="isSearchable"
                :label="$t('mes-accompagnements.datestart')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    {{ getFormattedDate(props.row.debut_accompagnement) }}
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="date"
                        class="form-control form-control-sm"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="fin_accompagnement"
                sortable
                :searchable="isSearchable"
                :label="$t('mes-accompagnements.dateend')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    {{ getFormattedDate(props.row.fin_accompagnement) }}
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="date"
                        class="form-control form-control-sm"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="statut"
                sortable
                :searchable="isSearchable"
                :label="$t('global.status')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.statut === 'a_confirmer'" class="tag is-info">
                        {{ $t('accompagnement.status.a_confirmer') }}
                    </span>
                    <span v-else-if="props.row.statut === 'a_faire'" class="tag is-warning">
                        {{ $t('accompagnement.status.a_faire') }}
                    </span>
                    <span v-else-if="props.row.statut === 'en_cours'" class="tag is-warning is-light">
                        {{ $t('accompagnement.status.en_cours') }}
                    </span>
                    <span v-else-if="props.row.statut === 'fait'" class="tag is-success">
                        {{ $t('accompagnement.status.fait') }}
                    </span>
                    <span v-else-if="props.row.statut === 'en_attente_credit'" class="tag is-danger">
                        {{ $t('accompagnement.status.en_attente_credit') }}
                    </span>
                    <span v-else-if="props.row.statut === 'en_attente_retour_client'" class="tag is-danger">
                        {{ $t('accompagnement.status.en_attente_retour_client') }}
                    </span>
                    <span v-else-if="props.row.statut === 'termine'" class="tag is-dark">
                        {{ $t('accompagnement.status.termine') }}
                    </span>
                    <span v-else class="tag is-dark">Inconnu</span>
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                        <option :value="props.filters[props.column.field]" selected>
                            {{ $t('accompagnement.all') }}
                        </option>
                        <option value="a_confirmer">
                            {{ $t('accompagnement.status.a_confirmer') }}
                        </option>
                        <option value="a_faire">
                            {{ $t('accompagnement.status.a_faire') }}
                        </option>
                        <option value="en_cours">
                            {{ $t('accompagnement.status.en_cours') }}
                        </option>
                        <option value="fait">
                            {{ $t('accompagnement.status.fait') }}
                        </option>
                        <option value="en_attente_credit">
                            {{ $t('accompagnement.status.en_attente_credit') }}
                        </option>
                        <option value="en_attente_retour_client">
                            {{ $t('accompagnement.status.en_attente_retour_client') }}
                        </option>
                        <option value="termine">
                            {{ $t('accompagnement.status.termine') }}
                        </option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column label="Actions">
                <div slot="default" slot-scope="props">
                    <a
                        :href="'/campaigns/details?id=' + props.row.id"
                        title="Voir"
                        target="_blank"
                        class="rounded button px-2 is-light rounded-lg text-decoration-none"
                    >
                        <i class="fas fa-eye" />
                    </a>
                </div>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    import Select from '../../Form/Select.vue'
    import Status from '../../Campaigns/Status'

    export default {
        name: 'UserCampaignTable',
        components: { Select, Status },
        props: {
            accompagnements: { type: Array, required: true },
            isAdmin: { type: Boolean, required: false, default: false }
        },

        data: function () {
            return {
                accompagnementsList: {},

                //buefy data
                isSearchable: true,
                sortField: 'id',
                sortOrder: 'desc',
                filters: '',
                page: 1,
                isLoading: false,

                // data info
                appUrl: process.env.APP_URL,

                //other
                isMobileWidth: window.innerWidth < 768
            }
        },
        watch: {
            accompagnements: {
                handler(newValue) {
                    this.accompagnementsList = newValue
                },
                deep: true
            }
        },

        created() {
            this.accompagnementsList =
                this.accompagnements && this.accompagnements.total && this.accompagnements.total > 0
                    ? this.accompagnements
                    : {}
        },

        methods: {
            /** Table filtering, sorting and pagination methods */
            loadAsyncData() {
                this.isLoading = true
                var self = this

                this.$emit('loadAsyncData', {
                    page: this.page,
                    sortField: this.sortField,
                    sortOrder: this.sortOrder,
                    filters: this.filters,
                    includes: '',
                    path: this.accompagnementsList.path,
                    index: this.indexType,
                    per_page: this.perPage,

                    callback() {
                        self.isLoading = false
                    }
                })
            },
            getCampaignPeriod(infos) {
                return new Date(JSON.parse(infos).period).toLocaleDateString('fr-fr', {
                    month: 'long',
                    year: 'numeric'
                })
            },
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order

                this.loadAsyncData()
            },

            onFilterChange(filter) {
                var filters = []
                Object.keys(filter).forEach(element => {
                    var currentFilter = {
                        key: element,
                        value: filter[element]
                    }
                    filters.push(currentFilter)
                })
                this.filters = filters
                this.loadAsyncData()
            },

            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },

            getFormattedDate(date) {
                return new Date(date).toLocaleDateString('fr-fr', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric'
                })
            }
        }
    }
</script>
