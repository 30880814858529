<template>
    <div>
        <b-button
            type="is-light"
            :disabled="!canScrap || isLoading"
            :loading="!canScrap || isLoading"
            @click.prevent="scrapUniqueUrl"
        >
            <i class="fas fa-redo-alt" />
        </b-button>
        <Toaster ref="toaster" />
    </div>
</template>

<script>
    import Toaster from '@/components/UI/Toaster'
    import { mapActions } from 'vuex'

    export default {
        name: 'ScrapButton',
        components: {
            Toaster
        },
        props: {
            callBack: {
                required: false,
                default: () => {},
                type: Function
            },
            item: {
                required: true,
                type: Object
            },
            canScrap: {
                required: false,
                default: true
            }
        },
        data: function () {
            return {
                isLoading: false
            }
        },
        methods: {
            ...mapActions('sites', ['scrapUrl']),
            scrapUniqueUrl() {
                this.isLoading = true
                this.scrapUrl({
                    siteId: this.item.id,
                    url: this.item.url
                })
                    .then(data => {
                        this.callBack(data)
                    })
                    .catch(error => {
                        this.$refs.toaster.fire('error', error)
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
        }
    }
</script>
