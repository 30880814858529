<template>
    <div class="d-flex flex-column justify-content-end">
        <b-field>
            <b-switch>Validé Compta</b-switch>
        </b-field>
        <b-field>
            <b-switch v-model="user.actif === 1 ? true : false" type="is-success"> Compte Actif</b-switch>
        </b-field>
        <b-button class="mb-2" type="is-primary"> Se connecter avec Urls</b-button>
        <b-button type="is-primary"> Se connecter sans Urls</b-button>
    </div>
</template>

<script>
    export default {
        name: 'PartnerDetailsStatus',
        props: {
            user: {}
        }
    }
</script>
