<template>
    <div>
        <b-tabs
            v-model="activeTab"
            @input="
                value => {
                    openOnce[value] = true
                }
            "
        >
            <b-tab-item
                v-for="tab in tabs"
                :key="tab.index"
                :label="tab.label"
                :icon="tab.icon"
                :disabled="tab.disabled"
            >
                <UrlTable v-if="openOnce[tab.index]" type="saleable" :status="tab.type" />
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
    import UrlTable from '@/components/Admin/Sites/Urls/UrlTable.vue'

    export default {
        name: 'UrlTabs',
        components: { UrlTable },
        props: {
            site: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                activeTab: 0,
                openOnce: {
                    0: true
                }
            }
        },
        computed: {
            tabs() {
                return [
                    {
                        type: 'all',
                        label: `Tous (${this.site.url_unique})`,
                        icon: 'list',
                        disabled: false,
                        index: 0,
                        status: false
                    },
                    {
                        type: 'saleable',
                        label: `Vendables (${this.site.url_active})`,
                        icon: 'check',
                        disabled: false,
                        index: 1,
                        status: true
                    },
                    {
                        type: 'not_saleable',
                        label: `Invendables (${this.site.url_inactive})`,
                        icon: 'times',
                        disabled: false,
                        index: 2,
                        status: true
                    },
                    {
                        type: 'not_scrapped',
                        label: `Non scrappées`,
                        icon: 'clock',
                        disabled: false,
                        index: 3,
                        status: true
                    }
                ]
            }
        }
    }
</script>
