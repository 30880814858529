<template>
    <span>
        <a href="#" class="rounded button is-warning rounded-lg text-decoration-none mr-1" @click.prevent="handleInput">
            <i class="fas fa-plus-circle mr-2" />
            Ajouter un collaborateur
        </a>

        <CreateConsultantModal ref="modal" @hide="$emit('reload')" />
    </span>
</template>

<script>
    import CreateConsultantModal from '../Modal/CreateConsultantModal'

    export default {
        name: 'CreateEmployeeButton',
        components: {
            CreateConsultantModal
        },
        data: () => ({}),
        methods: {
            handleInput() {
                this.$store.commit('consultants/setAction', 'create')

                this.$refs.modal.reset()
                this.$refs.modal.show()
            }
        }
    }
</script>
