<template>
    <b-table
        :loading="isLoading"
        :data="keywords.data"
        :mobile-cards="true"
        :checked-rows.sync="checkedRows"
        :checkable="!readonly"
        :custom-is-checked="(props, row) => props.id === row.id"
        paginated
        pagination-rounded
        backend-pagination
        :total="total"
        :per-page="per_page"
        default-sort="ID"
        default-sort-direction="DESC"
        striped
        @page-change="onPageChange"
    >
        <b-table-column field="ID" sortable label="ID">
            <div slot="default" slot-scope="props">
                {{ props.row.id }}
            </div>
        </b-table-column>
        <b-table-column field="to_work_on" :visible="!readonly">
            <div slot="default" slot-scope="props">
                <b-switch
                    v-model="props.row.to_work_on"
                    type="is-success"
                    :disabled="readonly"
                    :true-value="props.row.to_work_on != null ? props.row.to_work_on : true"
                    :false-value="null"
                    @input="changeStatus([props.row.id], props.row.to_work_on)"
                />
            </div>
            <div v-if="!readonly" slot="header">
                <b-switch
                    v-show="!readonly && checkedRows.length > 0"
                    v-model="mainSwitch"
                    type="is-success"
                    @input="
                        changeStatus(
                            checkedRows.map(e => e.id),
                            mainSwitch
                        )
                    "
                />
            </div>
        </b-table-column>
        <b-table-column field="query_id" :label="$t('mes-accompagnements.request')">
            <div slot="default" slot-scope="props">
                <span v-if="props.row.query_id">
                    <i class="fa fa-chart-line text-success" />
                </span>
                <span v-else>
                    <i class="fa fa-chart-line text-danger" />
                </span>
                <a role="button" @click="goToSearch(props.row.mot_cle)">
                    {{ props.row.mot_cle }}
                </a>
                <nl-clipboard-button :copy="props.row.mot_cle" />
                <b-tooltip v-if="props.row.note_divers" position="is-top">
                    <template v-slot:content>
                        {{ props.row.note_divers }}
                    </template>
                    <i class="fas fa-comment-dots text-danger" />
                </b-tooltip>
            </div>
        </b-table-column>
        <b-table-column field="url" :label="$t('mes-accompagnements.urltopush')">
            <div slot="default" slot-scope="props">
                <div class="d-flex align-items-center">
                    <span>{{ getPathName(props.row.url) }}</span>
                    <nl-clipboard-button :copy="props.row.url" />
                </div>
            </div>
        </b-table-column>
        <b-table-column field="opti_kw" label="RD" centered>
            <div slot="default" slot-scope="props">
                <campaign-ref-domains :keyword="props.row" :readonly="readonly" />
            </div>
        </b-table-column>
        <b-table-column field="id" :label="$t('mes-accompagnements.opti')" centered>
            <div slot="default" slot-scope="props">{{ props.row.opti_kw }}%</div>
        </b-table-column>
        <b-table-column field="position_initiale" :label="$t('mes-accompagnements.manualposition-alt')" centered>
            <div slot="default" slot-scope="props">
                {{ props.row.position_initiale }}
            </div>
        </b-table-column>
        <b-table-column field="position" :label="$t('mes-accompagnements.lastposition-alt')" centered>
            <div slot="default" slot-scope="props">
                <div class="d-flex flex-column">
                    <span v-if="props.row.position === -1"> OUT </span>
                    <span v-else-if="props.row.position > 0">
                        {{ props.row.position }}
                    </span>
                    <span v-else> - </span>
                    <span
                        v-if="props.row.check_date"
                        class="badge"
                        :class="`${isToday(new Date(props.row.check_date)) ? 'badge-success' : 'badge-danger'}`"
                    >
                        {{ $d(Date.parse(props.row.check_date), 'short') }}
                    </span>
                </div>
            </div>
        </b-table-column>
        <b-table-column field="position_variation" :label="$t('mes-accompagnements.variation-alt')" centered>
            <div slot="default" slot-scope="props">
                <a href="#" @click="$emit('openPositionModal', props.row)">
                    <strong v-if="props.row.position === -1 || !props.row.position" class="text-danger"> OUT </strong>
                    <strong v-else-if="props.row.position_variation === 0">=</strong>
                    <strong v-else-if="props.row.position_variation > 0" class="text-success">
                        + {{ props.row.position_variation }}
                    </strong>
                    <strong v-else-if="props.row.position_variation < 0" class="text-danger">
                        - {{ Math.abs(props.row.position_variation) }}
                    </strong>
                </a>
            </div>
        </b-table-column>
        <b-table-column field="url_extension" :label="$t('language.title')" centered>
            <div slot="default" slot-scope="props" class="d-flex">
                <flag
                    :title="`google.${props.row.url_extension}`"
                    class="rounded shadow-sm d-block mr-2"
                    :iso="props.row.lang === 'en' ? 'gb' : props.row.lang"
                />
                <div :title="`google.${props.row.url_extension}`">.{{ props.row.url_extension }}</div>
            </div>
        </b-table-column>
        <b-table-column field="actions" :label="$t('global.actions')">
            <div slot="default" slot-scope="props">
                <url-form v-if="!readonly" :url="props.row" @updateKeyword="updateKeyword" @deleteKeyword="deleteKeyword" />
            </div>
            <div slot="header">
                <button
                    v-if="!readonly && checkedRows.length > 0"
                    class="rounded button is-light text-danger rounded-lg text-decoration-none"
                    @click.prevent="deleteKeyword(null, true)">
                    <b-icon icon="trash" type="is-danger" class="m-0" />
                </button>
            </div>
        </b-table-column>
        <b-table-column field="partner_availability" sortable label="Dispo Partenaire">
            <div slot="default" slot-scope="props">
                <b-switch
                    v-model="props.row.partner_availability"
                    type="is-success"
                    true-value="1"
                    false-value="0"
                    :disabled="readonly"
                    @input="changeAvailability(props.row.id, props.row.partner_availability)"
                >
                    {{ props.row.partner_availability ? 'Oui' : 'Non' }}
                </b-switch>
            </div>
        </b-table-column>
    </b-table>
</template>

<script>
    import { mapMutations, mapState } from 'vuex'
    import UrlForm from './UrlForm'
    import NlClipboardButton from '@/components/UI/NlClipboardButton.vue'
    import CampaignRefDomains from '@/components/Campaigns/CampaignRefDomains.vue'

    export default {
        name: 'UrlTable',
        components: { CampaignRefDomains, NlClipboardButton, UrlForm },
        props: {
            keywords: {
                required: true
            },
            isLoading: {
                type: Boolean,
                default: false,
                required: true
            },
            readonly: {
                type: Boolean,
                default: false
            },
            total: {
                type: Number,
                default: 0,
                required: true
            },
            per_page: {
                type: Number,
                default: 0,
                required: true
            },
            page: {
                type: Number,
                default: 0,
                required: true
            }
        },
        data() {
            return {
                checkedRows: [],
                mainSwitch: true
            }
        },
        computed: {
            ...mapState('campaigns', {
                url: state => state.campaign.information.url
            })
        },
        methods: {
            ...mapMutations('search', {
                setFilters: 'setFilters'
            }),
            getPathName(url) {
                return new URL(url).pathname
            },
            isToday: function (someDate) {
                const today = new Date()
                return (
                    someDate.getDate() === today.getDate() &&
                    someDate.getMonth() === today.getMonth() &&
                    someDate.getFullYear() === today.getFullYear()
                )
            },
            onPageChange(page) {
                this.$emit('pageChange', page)
            },
            changeStatus(ids, status) {
                this.$emit('changeStatus', ids, status)
            },
            changeAvailability(id, availability) {
                this.$emit('changeAvailability', id, availability)
            },
            updateKeyword() {
                this.$emit('updateKeyword')
            },
            deleteKeyword(props, bulk = false) {
                let url = null
                if (bulk === true) {
                    url = this.checkedRows
                } else {
                    url = [props]
                }
                this.$emit('deleteKeyword', url)
            },
            goToSearch(keyword) {
                const route = this.$router.resolve({
                    name: 'SearchByKeywords',
                    query: { keyword }
                })
                window.open(route.href, '_blank')
            }
        }
    }
</script>
