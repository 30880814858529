<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">Export</h4>
                </header>
                <div class="card-content">
                    <div class="content d-flex flex-column">
                        <b-loading v-model="isLoading" :is-full-page="false" />

                        <div v-if="!isExported" class="mx-auto my-4">
                            <p>
                                {{ $t('partenaire-commandes.choose-format') }}
                            </p>

                            <div class="form-group">
                                <select id="format" v-model="formatFile" class="form-control" name="format">
                                    <option value="csv">CSV</option>
                                    <option value="xlsx">XLSX</option>
                                </select>
                            </div>

                            <button class="button is-warning" @click="generateExport">
                                {{ $t('partenaire-commandes.get-data') }}
                            </button>
                        </div>

                        <div v-else-if="!isLoading && isExported" class="mx-auto my-4">
                            <button class="button is-warning" @click="download">
                                {{ $t('global.download') }}
                            </button>
                        </div>

                        <div class="buttons justify-content-end">
                            <button class="button is-light" @click.prevent="closeAndResetModal">
                                {{ $t('global.close') }}
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'PartnerOrdersExportModal',
        props: {
            url: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                modalIsOpen: false,
                isLoading: false,

                isExported: false,
                file: null,
                formatFile: 'csv'
            }
        },

        methods: {
            download() {
                window.location.href = this.file
            },

            getFile(fileLink) {
                this.isLoading = false

                if (!fileLink) {
                    return
                }

                this.file = fileLink
                this.isExported = true
            },

            generateExport() {
                this.isLoading = true
                let link = null

                this.axios
                    .get(this.url + `?format=${this.formatFile}`)
                    .then(response => {
                        link = response.data.download_link
                        this.getFile(link)
                    })
                    .catch(error => {
                        this.$store.dispatch('toast', {
                            title: error.response.data.message,
                            type: 'error'
                        })
                    })
            },

            openModal() {
                this.modalIsOpen = true
            },

            closeAndResetModal() {
                this.modalIsOpen = false
                this.isLoading = false
                this.isExported = false
                this.file = null
            }
        }
    }
</script>

<style>
    label[for^='scrape_'] {
        color: #363636;
        display: block;
        font-size: 1rem;
        font-weight: 700;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
