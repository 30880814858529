<template>
    <div v-if="isConnected()">
        <div class="d-flex">
            <BackTopTop />
            <sidebar style="z-index: 101" />
            <div class="app-container">
                <header class="position-sticky">
                    <LogoutAs v-if="connectedAs" />
                    <TopBar>
                        <template v-if="$screen.width > 1240" #projectSelector>
                            <router-view name="projects" />
                            <router-view name="siteSelector" />
                        </template>
                    </TopBar>
                </header>
                <div v-if="$screen.width <= 1240 || $screen.width <= 768" class="subheader">
                    <div class="title-alt">
                        <h1 class="nl-top-bar-title mb-0">
                            {{ title }}
                        </h1>
                    </div>
                    <div class="project-selector-container">
                        <router-view name="projects" />
                        <router-view name="siteSelector" />
                    </div>
                </div>
                <main>
                    <fade-transition :duration="250" mode="out-in" origin="center">
                        <router-view :key="$route.fullPath" />
                    </fade-transition>
                </main>
                <footer>
                    <AppFooter />
                    <TacsModal :user="user" />
                </footer>
            </div>
        </div>
    </div>
    <div v-else>
        <fade-transition :duration="250" mode="out-in" origin="center">
            <router-view :key="$route.fullPath" />
        </fade-transition>
    </div>
</template>

<style lang="scss">
    .app-container {
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        flex-flow: column;
        height: 100vh;
    }

    main {
        flex: 1 1 auto;
        padding: 1rem;

        .box,
        > * {
            max-width: 100%;
        }
    }

    header.position-sticky {
        position: sticky;
        top: 0;
        z-index: var(--z-fixed);
    }

    .subheader {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem;
        gap: 1.5rem;
    }

    .subheader:empty {
        padding: 1rem;
    }

    .title-alt {
        display: flex;
        flex: 1 1 auto;
    }

    .project-selector-container {
        display: flex;
        flex: 1 1 auto;
        justify-content: flex-end;
    }

    @media screen and (min-width: 1024px) {
        .title-alt {
            display: none;
        }
    }

    @media screen and (max-width: 768px) {
        main {
            padding: 0.75rem;
        }
    }
</style>

<script>
    import AppFooter from '@/components/UI/Menu/AppFooter'
    import TopBar from '@/components/UI/Menu/TopBar'
    import LogoutAs from '@/components/Admin/LogoutAs'
    import TacsModal from '@/components/Tacs/TacsModal'
    import { mapState } from 'vuex'
    import { FadeTransition } from 'vue2-transitions'
    import BackTopTop from '@/components/UI/BackTopTop'
    import NlStorage from '@/services/nl.storage'

    export default {
        name: 'App',
        components: {
            BackTopTop,
            TopBar,
            LogoutAs,
            FadeTransition,
            TacsModal,
            AppFooter
        },
        data: function () {
            return {}
        },
        computed: {
            ...mapState('auth', {
                user: state => state.user,
                connectedAs: state => state.connectedAs
            }),
            ...mapState(['title']),
            isMenuOpen() {
                return this.$store.state.isMenuOpen
            }
        },
        watch: {
            $route(val) {
                let publicRoutes = ['/login', '/register', '/reset', '/reset/password']
                if (publicRoutes.includes(val.path)) {
                    document.body.classList.add('public-body')
                } else {
                    document.body.classList.remove('public-body')
                }
            }
        },
        mounted() {
            this.isMobileDevice()
            this.addZendeskSupport()
            window.addEventListener('resize', this.isMobileDevice)
        },
        methods: {
            isConnected() {
                let token = NlStorage.getItem('token')
                return token && token.length > 0
            },
            isMobileDevice() {
                let isMobile = window.matchMedia('only screen and (max-width: 991px)').matches
                console.log(isMobile)
                this.$store.commit('setIsMobile', isMobile)
            },
            addZendeskSupport() {
                let zendeskScript = document.createElement('script')
                zendeskScript.id = 'ze-snippet'
                zendeskScript.src =
                    'https://static.zdassets.com/ekr/snippet.js?key=6a6d4e05-b5f9-40bd-9e5f-d39037709b54'
                document.body.appendChild(zendeskScript)
            }
        }
    }
</script>