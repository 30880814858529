<template>
    <div>
        <div class="d-flex gap-2">
            <button class="rounded button is-light rounded-lg text-decoration-none mr-1" @click="showForm = true">
                <b-icon icon="pen"></b-icon>
            </button>
            <button
                class="rounded button is-light text-danger rounded-lg text-decoration-none mr-1"
                @click.prevent="$emit('deleteKeyword', url)"
            >
                <b-icon icon="trash" type="is-danger"></b-icon>
            </button>
        </div>

        <ValidationObserver v-slot="{ invalid }" ref="form" v-if="urlInformation">
            <form class="" @submit.prevent="onSubmit">
                <nl-modal :title="$t('global.edit')" :openModal="showForm" @hide="showForm = false">
                    <div class="row">
                        <div class="col-12">
                            <ValidationProvider
                                tag="div"
                                v-slot="{ errors }"
                                name="Mots clés de la page"
                                class="form-group"
                            >
                                <label for="frm-url-mot-cle">Mots clés de la page</label>
                                <input
                                    type="text"
                                    id="frm-url-mot-cle"
                                    v-model="urlInformation.mot_cle"
                                    class="form-control form-control-lg"
                                    :disabled="urlInformation.query_id"
                                    :class="{ 'is-invalid': errors.length > 0 }"
                                />
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col-12">
                            <ValidationProvider
                                tag="div"
                                v-slot="{ errors }"
                                name="Page à travailler"
                                rules="url"
                                class="form-group"
                            >
                                <label for="frm-url-url">Page à travailler</label>
                                <input
                                    type="text"
                                    id="frm-url-url"
                                    v-model="urlInformation.url"
                                    @change="loadRefDomains"
                                    class="form-control form-control-lg"
                                    :class="{ 'is-invalid': errors.length > 0 }"
                                />
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </ValidationProvider>
                        </div>

                        <div class="col-sm-3">
                            <div class="form-group">
                                <label for="frm-url-position-initiale">Position Initiale</label>
                                <input
                                    type="number"
                                    id="frm-url-position-initiale"
                                    class="input"
                                    v-model="urlInformation.position_initiale"
                                />
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <div class="form-group">
                                <label for="frm-url-extension">{{ $t('global.url-extension') }}</label>
                                <select
                                    class="select w-100"
                                    name="url-lang"
                                    id="frm-url-extension"
                                    v-model="urlInformation.url_extension"
                                >
                                    <option value="fr">.fr</option>
                                    <option value="com">.com</option>
                                    <option value="co.uk">.co.uk</option>
                                    <option value="ch">.ch</option>
                                    <option value="be">.be</option>
                                    <option value="ca">.ca</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-sm-2">
                            <div class="form-group">
                                <label for="frm-url-lang">{{ $t('language.title') }}</label>
                                <select
                                    class="select w-100"
                                    name="lang"
                                    id="frm-url-lang"
                                    v-model="urlInformation.lang"
                                >
                                    <option value="fr">FR</option>
                                    <option value="us">US</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-12">
                            <ValidationProvider tag="div" v-slot="{ errors }" name="Note" class="form-group" rules="">
                                <label for="frm-url-note">Note</label>
                                <textarea
                                    id="frm-url-note"
                                    v-model="urlInformation.note_divers"
                                    class="form-control"
                                    :class="{ 'is-invalid': errors.length > 0 }"
                                ></textarea>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <template slot="footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Annuler</button>
                        <button type="submit" class="btn btn-secondary" :disabled="invalid">Enregistrer</button>
                    </template>
                </nl-modal>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
    import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
    import { numeric, required } from 'vee-validate/dist/rules'
    import validationUrl from '../../utils/validationUrl'

    import NlModal from '../UI/NlModal'
    import { mapActions } from 'vuex'

    extend('required', required)
    extend('numeric', numeric)
    extend('url', validationUrl)

    export default {
        name: 'UrlForm',
        components: {
            ValidationObserver,
            ValidationProvider,
            NlModal
        },
        props: {
            url: {
                type: Object,
                required: false,
                default: null
            },
            urls: {
                type: Array,
                required: false,
                default: null
            }
        },
        data() {
            return {
                showForm: false,
                urlInformation: null,
                loadingRefDomains: false
            }
        },
        methods: {
            ...mapActions('campaigns', ['editCampaignKeyword']),
            setInformation: function (info) {
                this.urlInformation = { ...info }
                if (this.$refs.form) {
                    this.$refs.form.reset()
                }
            },
            onSubmit() {
                this.$emit('updateKeyword')
                this.showForm = false
                let data = {
                    id: this.urlInformation.id,
                    payload: {
                        ...(this.urlInformation.position_initiale && {
                            position_initiale: this.urlInformation.position_initiale
                        }),
                        ...(this.urlInformation.lang && {
                            lang: this.urlInformation.lang
                        }),
                        ...(this.urlInformation.url_extension && {
                            url_extension: this.urlInformation.url_extension
                        }),
                        ...(this.urlInformation.url && {
                            url: this.urlInformation.url
                        }),
                        ...(this.urlInformation.mot_cle && {
                            mot_cle: this.urlInformation.mot_cle
                        }),
                        note_divers: this.urlInformation.note_divers
                    }
                }

                this.editCampaignKeyword(data).then(() => {
                    this.$emit('updateKeyword')
                }).catch((e) => {
                    this.$store.dispatch('toastError', e)
                })
            },

            loadRefDomains() {
                this.loadingRefDomains = true

                this.axios
                    .get(`/campaigns/backlinks-count?url=${encodeURIComponent(this.urlInformation.url)}`)
                    .then(response => {
                        this.urlInformation.rd_kw = response.data.rd_count
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.loadingRefDomains = false))
            }
        },
        computed: {
            ids() {
                if (!this.urls) {
                    return [this.url.id]
                }
                return this.urls.map(e => e.id)
            }
        },
        watch: {
            show: function (isShow) {
                if (!isShow && this.$refs.form) {
                    this.$nextTick(() => {
                        this.$refs.form.reset()
                    })
                }
            },
            url: function (url) {
                this.setInformation(url)

                if (this.urlInformation && this.urlInformation.url && this.urlInformation.rd_kw < 1) {
                    this.loadRefDomains()
                }
            }
        },
        mounted() {
            this.setInformation(this.url)
        }
    }
</script>

<style scoped>
    .icon {
        margin: 0;
    }

    .icon:first-child:last-child {
        margin-left: calc(-0.5em - 1px) !important;
        margin-right: calc(-0.5em - 1px) !important;
    }
</style>
