// VUE and dependancies
import CKEditor from '@ckeditor/ckeditor5-vue2'
// 3rd parties lib
import Buefy from 'buefy'
import moment from 'moment'
import PortalVue from 'portal-vue'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
import Transitions from 'vue2-transitions'
import VueAxios from 'vue-axios'
import VueClipboard from 'vue-clipboard2'
import Fragment from 'vue-fragment'
import vSelect from 'vue-select'
import VueSweetalert2 from 'vue-sweetalert2'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

import App from './App.vue'
// Config
import axios from './config/backend'
import GlobalComponents from './config/components'
import './config/filters'
import mixins from './config/mixins'
import './config/socketio'
import './config/styles'
import i18n from './i18n/i18n.js'
import titleMixin from './mixins/titleMixin'
import router from './router'
import store from './store'

const files = require.context('./components', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Setup $screen variable
Vue.prototype.$screen = Vue.observable({
    width: window.innerWidth,
    height: window.innerHeight
})

window.addEventListener('resize', () => {
    Vue.prototype.$screen.width = window.innerWidth
    Vue.prototype.$screen.height = window.innerHeight
})

moment.locale(process.env.VUE_APP_I18N_LOCALE)
Vue.prototype.moment = moment

Vue.config.productionTip = true
Vue.use(GlobalComponents)
Vue.use(VueAxios, axios)
Vue.use(VueClipboard)
Vue.use(VueSweetalert2)
Vue.use(VueTelInput)
Vue.use(PortalVue)
Vue.use(Transitions)
Vue.use(CKEditor)
Vue.component('v-select', vSelect)
Vue.mixin(mixins)
Vue.mixin(titleMixin)
Vue.use(Buefy, {
    defaultIconPack: 'fas'
})
Vue.use(PortalVue)
Vue.use(Fragment.Plugin)
let Vue2FiltersConfig = {
    number: {
        thousandsSeparator: "'"
    },
    currency: {
        symbol: '€',
        decimalDigits: 2,
        thousandsSeparator: '',
        decimalSeparator: ',',
        spaceBetweenAmountAndSymbol: true,
        symbolOnLeft: false
    }
}
Vue.use(Vue2Filters, Vue2FiltersConfig)

new Vue({
    store,
    router,
    beforeCreate() {
        this.$store.dispatch('initialiseStore')
    },
    i18n,
    render: h => h(App)
}).$mount('#app')
