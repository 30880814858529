export default {
    list(state) {
        return state.list
    },
    total(state) {
        return state.total
    },
    isLoading(state) {
        return state.isLoading
    },
    loaded(state) {
        return state.loaded
    }
}
