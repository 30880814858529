<template>
    <div v-if="sites !== null">
        <div class="ml-auto w-25 d-flex align-items-center justify-content-end">
            <b-select
                v-model="config.per_page"
                icon="align-center"
                :disabled="isLoading === true"
                @input="loadAsyncData"
            >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
            </b-select>
        </div>
        <section>
            <b-tabs animated @input="onTabChange">
                <b-tab-item :label="`Disponibles (${sites.sitesAvailable.count})`">
                    <AvailableSitesTable
                        :sites="sites.sitesAvailable"
                        :project-id="projectId"
                        :groups="groupsList"
                        :is-loading="isLoading"
                        @loadAsyncData="loadAsyncData"
                    />
                </b-tab-item>
                <b-tab-item :label="`Avec commandes (${sites.sitesWithContents.count})`">
                    <AvailableSitesTable
                        :sites="sites.sitesWithContents"
                        :project-id="projectId"
                        :groups="groupsList"
                        :is-loading="isLoading"
                        @loadAsyncData="loadAsyncData"
                    />
                </b-tab-item>
                <b-tab-item :label="`Avec précommandes NL (${sites.sitesWithPreoders.count})`">
                    <AvailableSitesTable
                        :sites="sites.sitesWithPreoders"
                        :project-id="projectId"
                        :groups="groupsList"
                        :is-loading="isLoading"
                        @loadAsyncData="loadAsyncData"
                    />
                </b-tab-item>
                <b-tab-item :label="`Avec précommandes partenaires (${sites.sitesWithCommandesArticles.count})`">
                    <AvailableSitesTable
                        :sites="sites.sitesWithCommandesArticles"
                        :project-id="projectId"
                        :groups="groupsList"
                        :is-loading="isLoading"
                        @loadAsyncData="loadAsyncData"
                    />
                </b-tab-item>
                <b-tab-item :label="`Blacklistés (${sites.sitesBlacklisted.count})`">
                    <AvailableSitesTable
                        :sites="sites.sitesBlacklisted"
                        :project-id="projectId"
                        :groups="groupsList"
                        :is-loading="isLoading"
                        @loadAsyncData="loadAsyncData"
                    />
                </b-tab-item>
            </b-tabs>
        </section>
    </div>
    <div v-else style="position: relative; min-height: 50px">
        <b-loading v-model="globalLoading" :is-full-page="false" />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import AvailableSitesTable from './AvailableSitesTable'

    export default {
        name: 'DomainPostingTable',
        components: { AvailableSitesTable },
        props: {
            projectId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                isLoading: false,
                globalLoading: true
            }
        },
        created() {
            this.getAll()
            this.loadAsyncData()
        },
        computed: {
            ...mapState('group', {
                groups: state => state.groups
            }),
            ...mapState('sites', {
                sites: state => state.domainPosting,
                config: state => state.domainPostingConfig
            }),
            groupsList() {
                let groups = []
                if (this.groups.length > 0) {
                    this.groups.forEach(group => {
                        groups.push({
                            text: group.localized_translations.name,
                            value: group.id
                        })
                        group.children.forEach(child => {
                            if (child.localized_translations) {
                                groups.push({
                                    text: child.localized_translations.name,
                                    value: child.id
                                })
                            }
                        })
                    })
                }
                return groups
            }
        },
        methods: {
            ...mapActions('group', ['getAll']),
            ...mapActions('sites', ['getDomainPosting']),
            roundAmount(amount) {
                return Math.round(amount * 100) / 100
            },
            loadAsyncData() {
                this.isLoading = true
                this.getDomainPosting(this.projectId)
                    .catch(error => {
                        console.log(error)
                        this.$store.dispatch('toastError', error)
                    })
                    .finally(() => {
                        this.isLoading = false
                        this.exportReady = false
                    })
            },
            onTabChange() {
                this.config.page = 1
                this.config.per_page = 25
                this.config.filters = {}
                this.loadAsyncData()
            }
        }
    }
</script>
