<template>
    <fragment>
        <a href="#" class="nav_link d-flex" @click.prevent="redirectOrOpen">
            <i class="nav_icon" :class="icon" />
            <span class="nav_name">
                {{ title }}
            </span>
            <i class="fas fa-caret-down collapse-icon" :class="{ 'collapse-icon-open': subMenuOpen === title }" />
        </a>
        <b-collapse animation="slide" :open="isMenuOpen && subMenuOpen === title">
            <div class="collapse-list">
                <slot />
            </div>
        </b-collapse>
    </fragment>
</template>

<script>
    export default {
        name: 'SidebarDropdown',
        props: {
            title: {
                type: String,
                required: true
            },
            to: {
                type: String,
                default: ''
            },
            icon: {
                type: String,
                default: ''
            }
        },
        computed: {
            subMenuOpen: {
                get() {
                    return this.$store.state.subMenuOpen
                },
                set(value) {
                    this.$store.commit('setSubMenuOpen', value)
                }
            },
            isMenuOpen: {
                get() {
                    return this.$store.state.isMenuOpen
                }
            }
        },
        methods: {
            redirectOrOpen() {
                if (this.isMenuOpen) {
                    this.subMenuOpen = this.subMenuOpen === this.title ? null : this.title
                } else {
                    this.$route.fullPath !== this.to ? this.$router.push(this.to) : this.$router.go()
                }
            }
        }
    }
</script>

<style scoped>
    .collapse-icon {
        transition: all 0.2s ease;
    }

    .collapse-icon-open {
        transform: rotate(180deg);
    }

    .collapse {
        display: block !important;
    }

    .collapse-list {
        display: flex;
        flex-flow: column;
        padding-left: 32px;
    }

    /deep/ .collapse-list > * {
        margin-bottom: 0.875rem;
    }

    .sidebar__link {
        color: #fff !important;
    }

    .sidebar__submenu {
        display: flex;
        flex-direction: column;
        margin: -1rem 0 1rem;
    }

    /deep/ .sidebar__sublink {
        color: #fff !important;
        font-size: 0.875rem;
        text-decoration: none;
    }

    /deep/ .sidebar__sublink:hover {
        color: #f9bc14 !important;
        text-decoration: none;
    }
</style>
