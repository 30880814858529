<template>
    <nav class="nl-top-bar">
        <div class="nl-top-bar-left">
            <div v-if="!isMenuOpen" class="header_toggle pl-3" @click.prevent="setMenuOpen()">
                <i id="header-toggle" class="fas fa-bars" />
            </div>
        </div>
        <div class="nl-top-bar-middle">
            <h1 class="nl-top-bar-title mb-0">
                {{ title }}
            </h1>
            <img class="logo-full" src="@/assets/img/top-logo-fond-noir.png" alt="logo-large" />
        </div>
        <div class="nl-top-bar-right">
            <slot name="projectSelector" />
            <slot name="siteSelector" />
            <KeywordMenu />
            <CreditGainMenu />
            <NotificationsMenu />
            <UserMenu />
        </div>
    </nav>
</template>

<style lang="scss" scoped>
    .nl-top-bar {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 100;
        transition: 0.5s;
        background-color: var(--first-color);
        padding: 1rem;
        gap: 1rem;
    }

    .nl-top-bar-right {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: flex-end;
        gap: 1.5rem;
    }

    .logo-full {
        display: none;
    }

    #header-toggle {
        font-size: 1.5rem;
    }

    @media screen and (max-width: 1023px) {
        .nl-top-bar-title {
            display: none;
        }
    }

    @media screen and (min-width: 768px) {
        .nl-top-bar {
            background-color: white;
            box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
        }

        .nl-top-bar-middle {
            flex: 1 1 auto;
        }

        .nl-top-bar-title-alt {
            display: none;
        }
    }

    @media screen and (max-width: 767px) {
        .nl-sidebar-top:before {
            content: '';
            width: 1rem;
            display: block;
        }

        .nl-top-bar-right {
            display: none;
        }

        .nl-top-bar-left {
            position: absolute;
            left: 0;

            * {
                color: white;
            }
        }

        .logo-full {
            display: block;
        }
    }
</style>

<script>
    import { mapState } from 'vuex'
    import NotificationsMenu from '@/components/UI/Menu/NotificationsMenu'
    import UserMenu from '@/components/UI/Menu/UserMenu'
    import CreditGainMenu from '@/components/UI/Menu/CreditGainMenu'
    import KeywordMenu from '@/components/UI/Menu/KeywordMenu'

    export default {
        name: 'TopBar',
        components: {
            KeywordMenu,
            NotificationsMenu,
            UserMenu,
            CreditGainMenu
        },
        data() {
            return {
                displayDark: false
            }
        },
        computed: {
            ...mapState('auth', {
                user: state => state.user,
                role: state => state.user.role
            }),
            ...mapState({
                homepage: state => state.homepage,
                title: state => state.title
            }),
            isMenuOpen: {
                get() {
                    return this.$store.state.isMenuOpen
                },
                set(value) {
                    this.$store.commit('setIsMenuOpen', value)
                }
            }
        },
        mounted() {
            const linkColor = document.querySelectorAll('.nav_link')

            function colorLink() {
                if (linkColor) {
                    linkColor.forEach(l => l.classList.remove('active'))
                    this.classList.add('active')
                }
            }

            linkColor.forEach(l => l.addEventListener('click', colorLink))
        },
        methods: {
            checkRole(role) {
                if (this.user.role === 'admin') {
                    return true
                }

                return this.user.role === role
            },
            setMenuOpen() {
                this.isMenuOpen = !this.isMenuOpen
            }
        }
    }
</script>
