
import NlStorage from '@/services/nl.storage'

export default () => ({
    user: null,
    token: null,
    refresh_token: null,
    expires_in: null,
    url: null,
    fromAdmin: false,

    connectedAs: NlStorage.getItem('connectedAs') ? JSON.parse(NlStorage.getItem('connectedAs')) : false,

    tokens: NlStorage.getItem('tokens') ? JSON.parse(NlStorage.getItem('tokens')) : [],

    animation: 'animate__heartBeat',
    credit: null,
    security: {
        hideResults: false,
        isFavorite: false,
        seeKeyword: false,
        isAdmin: false,
        isProspect: false
    }
})
