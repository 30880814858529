export default {
    setFilters(state, data) {
        state.filters = {
            ...state.filters,
            ...data
        }
    },
    setFilterExtended(state, data) {
        state.filterExtended = data
    },
    setMeta(state, data) {
        state.meta = {
            ...state.meta,
            ...data
        }
    },
    setResults(state, { data }) {
        state.results = data
    },
    resetResults(state) {
        state.results = []
    }
}
