<template>
    <span>
        <button class="button is-warning" @click="open">
            <slot><i class="fas fa-plus-circle mr-2" />Ajouter une catégorie</slot>
        </button>
        <nl-modal ref="modal" :title="model.id ? 'Editer un catégorie' : 'Ajouter une catégorie'" @hidden="close">
            <template>
                <nl-alert ref="alert" />
                <v-form ref="addUserForm">
                    <div class="form-group">
                        <text-field v-model="model.fr" label="Traduction FR" rules="required" name="Traduction FR" />
                    </div>
                    <div class="form-group">
                        <text-field v-model="model.en" label="Traduction EN" rules="required" name="Traduction EN" />
                    </div>
                    <div class="form-group">
                        <nl-select
                            v-model="model.parent_id"
                            :items="parents"
                            name="Parent"
                            label="Parent"
                            item-text="name"
                        />
                    </div>
                    <div class="form-group">
                        <v-checkbox v-model="model.is_thematique">Thématique ?</v-checkbox>
                    </div>
                </v-form>
            </template>
            <template #footer>
                <button class="btn btn-primary" @click="save">
                    {{ $t('global.save') }}
                </button>
                <button class="btn btn-danger" @click="close">
                    {{ $t('global.cancel') }}
                </button>
            </template>
        </nl-modal>
    </span>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        props: {
            categorie: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            openModal: false,
            model: {}
        }),
        mounted() {
            if (this.categorie.id) {
                this.assignModel(this.categorie)
            }
            if (!this.isLoading && !this.loaded) {
                this.getGroups()
            }
        },
        computed: {
            ...mapGetters('group', {
                groups: 'list',
                loaded: 'loaded',
                isLoading: 'isLoading'
            }),
            parents() {
                return [{ name: 'Aucun', id: null }, ...this.groups.filter(item => item.parent_id == null)]
            }
        },
        methods: {
            ...mapActions('group', {
                getGroups: 'list',
                updateCategorie: 'update',
                addCategorie: 'add'
            }),
            close() {
                this.$refs.modal.hide()
                this.openModal = false
            },
            open() {
                this.openModal = true
                this.$refs.modal.show()
            },
            save() {
                if (this.model.id) {
                    this.updateCategorie(this.model).then(group => {
                        this.close()
                        this.$emit('reload')
                    })
                } else {
                    this.addCategorie(this.model).then(group => {
                        this.close()
                        this.model = {}
                        this.$emit('reload')
                    })
                }
            },
            assignModel(categorie) {
                this.model = {
                    id: categorie.id,
                    fr: this.getTranslate(categorie, 'fr'),
                    en: this.getTranslate(categorie, 'en'),
                    parent_id: categorie.parent_id,
                    is_thematique: categorie.is_thematique
                }
            },
            getTranslate(item, lang) {
                return item.translations.find(t => t.lang == lang).name
            }
        }
    }
</script>

<style></style>
