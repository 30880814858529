<template>
    <div>
        <p class="small text-muted font-weight-bold">Nombres d'URL proposées : {{ urlsList.total }}</p>

        <b-table
            :data="urlsList.data"
            :mobile-cards="true"
            :paginated="true"
            :current-page.sync="page"
            default-sort-direction="desc"
            :striped="true"
            :debounce-search="200"
            class="table-striped"
            :loading="isLoading"
            :default-sort="[sortField, sortOrder]"
            :per-page="urlsList.per_page"
            :total="urlsList.total"
            backend-sorting
            backend-pagination
            backend-filtering
            @page-change="onPageChange"
            @sort="onSort"
            @filters-change="onFilterChange"
        >
            <b-table-column
                field="users.user"
                sortable
                :searchable="isSearchable"
                label="Partenaire"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    {{ props.row.user }}
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Partenaire"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="commandes_articles.keyword"
                sortable
                :searchable="isSearchable"
                label="Mot-clé"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    {{ props.row.keyword }}
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Client"
                    />
                </div>
            </b-table-column>

            <b-table-column field="url" sortable :searchable="isSearchable" label="URL" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    {{ props.row.url }}
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="URL"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="url_submitted_at"
                sortable
                :searchable="isSearchable"
                label="Date de soumission"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    {{ props.row.url_submitted_at }}
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="date"
                        class="form-control form-control-sm"
                        placeholder="Date de soumission"
                    />
                </div>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    export default {
        name: 'AdminListingTab',
        props: ['urls'],

        data: function () {
            return {
                urlsList: {},

                //buefy data
                isSearchable: true,
                sortField: '-url_submitted_at',
                sortOrder: 'desc',
                filters: '',
                page: 1,
                isLoading: false,

                // data info
                appUrl: process.env.APP_URL,

                //other
                isMobileWidth: window.innerWidth < 768
            }
        },
        watch: {
            urls: {
                handler(newValue) {
                    this.urlsList = newValue
                },
                deep: true
            }
        },

        created() {
            this.urlsList = this.urls && this.urls.total && this.urls.total > 0 ? this.urls : {}
        },

        methods: {
            /** Table filtering, sorting and pagination methods */
            loadAsyncData() {
                this.isLoading = true
                var self = this

                this.$emit('loadAsyncData', {
                    page: this.page,
                    sortField: this.sortField,
                    sortOrder: this.sortOrder,
                    filters: this.filters,
                    includes: '',
                    path: this.urlsList.path,
                    index: this.indexType,
                    per_page: this.perPage,

                    callback() {
                        self.isLoading = false
                    }
                })
            },
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order

                this.loadAsyncData()
            },

            onFilterChange(filter) {
                var filters = []
                Object.keys(filter).forEach(element => {
                    var currentFilter = {
                        key: element,
                        value: filter[element]
                    }
                    filters.push(currentFilter)
                })
                this.filters = filters
                this.loadAsyncData()
            },

            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            }
        }
    }
</script>
