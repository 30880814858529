<script>
    export default {
        name: 'ButtonMixin',
        props: {
            label: {
                type: String,
                required: false,
                default: 'Voir',
                description: 'Tooltip label'
            },
            classes: {
                type: String,
                required: false,
                default: 'rounded button is-light rounded-lg text-decoration-none mr-1 is-info',
                description: 'Class to apply to a element'
            },
            icon: {
                type: String,
                required: false,
                default: ''
            },
            position: {
                type: String,
                required: false,
                default: 'is-top'
            }
        }
    }
</script>
