<template>
    <div class="card">
        <div class="card-content">
            <div class="media">
                <div class="media-left">
                    <button class="button is-rounded btn-lg">
                        <i class="fas fa-user" style="font-size: 1.3em" />
                    </button>
                </div>
                <div class="media-content">
                    <p class="title is-5">{{ user.firstname }} {{ user.lastname }}</p>
                    <p class="subtitle is-6">
                        {{ user.email }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ConfigMailUserCard',
        props: ['user']
    }
</script>
<style scoped lang="scss">
    .button {
        cursor: move;
    }

    .media {
        display: flex;
    }

    .card {
        border-radius: 15px;
    }

    .subtitle {
        color: #f9bc14;

        margin-bottom: 0;
        font-size: 0.85rem;
        font-family: Poppins, sans-serif;
    }
</style>
