<template>
    <div class="p-2 bg-white">
        <b-table
            :data="preorders"
            :loading="loading"
            class="table-striped"
            :narrowed="true"
            :mobile-cards="true"
            :show-detail-icon="true"
            :striped="true"
        >
            <b-table-column centered field="id" label="Ref" width="0" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <div class="tag is-info">#{{ props.row.entity_id }}</div>
                </div>
            </b-table-column>

            <b-table-column field="thematic.name" label="Thématique" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    {{ props.row.thematic.name }}
                </div>
            </b-table-column>

            <b-table-column centered field="words_count" label="Nombre de mots" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    {{ props.row.words_count }}
                </div>
            </b-table-column>

            <b-table-column centered field="writing_status_id" label="Statut" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.writing_status_id == 1"> A faire </span>
                    <span v-if="props.row.writing_status_id == 2"> En cours de rédaction </span>
                    <span v-if="props.row.writing_status_id == 3 || props.row.writing_status_id == 11">
                        En relecture
                    </span>
                    <span v-if="props.row.writing_status_id == 6"> Terminé </span>
                    <span v-if="props.row.writing_status_id == 10"> En attente (erreur sur la commande) </span>
                </div>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'AssignedPreordersTable',
        props: ['indexType'],
        data: function () {
            return {
                loading: false,
                isAffectingWriting: false,
                isAffectingIndex: -1
            }
        },
        created() {},
        computed: {
            ...mapState('redactors', {
                preorders: state => state.preorders
            })
        },
        mounted() {},
        methods: {}
    }
</script>
