<template>
    <div>
        <b-loading :is-full-page="false" :active="!consultants" />
        <div class="card">
            <div class="card-header">Consultants</div>
            <div v-if="consultants">
                <ul class="list-group list-group-flush" v-if="readonly || !available_consultants">
                    <li v-for="type in types" :key="type" class="list-group-item d-flex justify-content-between p-2">
                        <span class="text-muted mr-1">{{ type }}</span>
                        <span class="ml-auto text-truncate" v-if="consultants[type]">
                            {{ consultants[type].firstname }} {{ consultants[type].lastname }}
                        </span>
                    </li>
                </ul>
                <div v-else class="card-body">
                    <div v-for="type in types" v-bind:key="type">
                        <consultant
                            v-if="available_consultants[type].length > 0"
                            :type="type"
                            :label="type"
                            :available="available_consultants[type]"
                            :consultant="consultants[type]"
                            class="mb-2"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Consultant from './Consultant'
    import { mapState } from 'vuex'

    const TYPES = ['offSite', 'onSite']
    export default {
        name: 'Consultants',
        components: { Consultant },
        props: {
            readonly: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                types: TYPES
            }
        },
        computed: {
            ...mapState('campaigns', {
                consultants: state => state.campaign?.consultants ?? null,
                available_consultants: state => state.campaign?.meta?.available_consultants ?? null
            })
        }
    }
</script>
