import ApiService from '../../../services/api.service'

function prepareParams(config) {
    let field = config.sort

    if (config.sortDirection === 'desc') {
        field = '-' + field
    }

    let params = {
        page: config.page,
        include: config.include,
        sort: field,
        per_page: config.per_page
    }

    if (config.filters) {
        for (const [key, value] of Object.entries(config.filters)) {
            params[`filter[${key}]`] = value
        }
    }

    return params
}

export default {
    /*
    |--------------------------------------------------------------------------
    | Admin actions
    |--------------------------------------------------------------------------
    */
    getRedactorDashboard({ state, commit }, showInactiveRedactor) {
        return new Promise((resolve, reject) => {
            let params = prepareParams(state.redactorsConfig)
            params.inactive = showInactiveRedactor

            ApiService.get(`/redactor/dashboard`, params)
                .then(({ data }) => {
                    commit('setRedactors', data.redactors)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getDashboardCount() {
        return new Promise((resolve, reject) => {
            ApiService.get(`/redactor/dashboard/count`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getRedactorByStatus(context, { status, params }) {
        return new Promise((resolve, reject) => {
            params.inactive = status

            ApiService.get(`/redactor/dashboard`, params)
                .then(({ data }) => {
                    resolve(data.redactors)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getWritingDashboard({ state, commit }, status) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/writings/status/${status}`, prepareParams(state.writingsConfig))
                .then(({ data }) => {
                    commit('setWritings', data.writings)

                    if (data.maxWordsPerDay) {
                        commit('setMaxWordsPerDay', data.maxWordsPerDay)
                    }

                    if (data.wordsRemainingToWrite) {
                        commit('setWordsRemainingToWrite', data.wordsRemainingToWrite)
                    }

                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getWritingByStatus(context, { status, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/writings${status ? '/status/' + status : ''}`, params)
                .then(({ data }) => {
                    resolve(data.writings)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    creditRedactor({ state }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/redactor/${state.redactor.id}/credit`, state.creditLog)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    findRedactor(context, query) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/redactor/find`, {
                query: query
            })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    searchImages(context, query) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/pixabay`, {
                query: query.replace(/  +/g, ' ').split(' ').join('+')
            })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    assignRedactor(context, data) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/writings/${data.id}/assign/${data.redactor.id}`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    assignCommands(context, { redactor, commands, commandsType }) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/assign-commands/${commandsType}/${redactor}`, { commands })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    saveRedactor({ state }) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/redactors/${state.redactor.id}`, state.redactor)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    saveRedactorBlacklists({ state }, blacklist) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/redactors/${state.redactor.id}/blacklists`, {
                group_id: blacklist
            })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    saveRedactorPrice({ state }, data) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/redactors/${state.redactor.id}/prices`, data)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    removeRedactorLangs({ state }, price) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/redactors/${state.redactor.id}/prices/${price.lang}`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    removeRedactorBlacklists({ state }, blacklist) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/redactors/${state.redactor.id}/blacklists/${blacklist.id}`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    setWritingPublished(context, writingId) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/writings/${writingId}/publish`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    retry(context, writingId) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/writings/${writingId}/retry`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    /*
|--------------------------------------------------------------------------
| Redactors actions
|--------------------------------------------------------------------------
*/
    getRedactorAssignedWritings(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/writings`, params)
                .then(({ data }) => {
                    context.commit('setWritings', data.writings)
                    resolve(data.writings)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    askForWriting(context, user) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/redactors/${user}/new`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getWriting({ commit }, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/writings/${id}`)
                .then(({ data }) => {
                    commit('setWriting', data.writing)

                    if (data.categories) {
                        commit('setCategories', data.categories)
                    }

                    if (data.authors) {
                        commit('setAuthors', data.authors)
                    }

                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    declineWriting({ rootState }, data) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/redactors/${rootState.auth.user.id}/decline/${data.id}`, data)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    timeoutWriting(context, id) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/writings/${id}/timeout`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateWriting({ state, commit }, data) {
        return new Promise((resolve, reject) => {
            let writing = state.writing

            ApiService.put(`/writings/${writing.id}`, data)
                .then(({ data }) => {
                    commit('setWriting', Object.assign(writing, data.writing))
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getRedactor({ commit }, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/redactors/${params.id}`)
                .then(({ data }) => {
                    commit('setRedactor', data.redactor)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getSpotRedactors() {
        return new Promise((resolve, reject) => {
            ApiService.get(`/spot-redactors`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    declinePublication({ state }) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/writings/${state.writing.id}/decline`, {
                content: state.writing.content,
                reason: state.writing.reason
            })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    saveWriting({ state }, tempSave = true) {
        let data = {
            content: state.writing.content,
            tempSave
        }

        if (state.writing.entity_type === 2 || state.writing.entity_type === 6) {
            data = {
                ...data,
                image: state.writing.image,
                category: state.writing.category,
                author: state.writing.author
            }
        }

        return new Promise((resolve, reject) => {
            ApiService.post(`/writings/${state.writing.id}`, data)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    setWritingReviewed({ state }) {
        let data = {
            content: state.writing.content
        }

        if (state.writing.entity_type === 2) {
            data.image = state.writing.image
            data.category = state.writing.category
            data.author = state.writing.author
        }

        return new Promise((resolve, reject) => {
            ApiService.put(`/writings/${state.writing.id}/reviewed`, data)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getRedactorInfo({ commit }, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/redactors/${params.id}/dashboard`, params)
                .then(({ data }) => {
                    commit('setRedactor', data.redactor)
                    commit('setPreorders', data.preorders)

                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getRedactorWritings({ commit }, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/redactors/${params.id}/writings`, params)
                .then(({ data }) => {
                    let redactorWritring = {
                        total: data.total_orders,
                        words: data.total_words,
                        amount: data.total_amount,
                        writingsPerDay: data.writingsPerDay
                    }

                    if (data.logs) {
                        commit('setLogs', data.logs)
                    }

                    commit('setRedactor', data.redactor)
                    commit('setRedactorWritings', redactorWritring)

                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    setPriority(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/writings/${params.id}/priority`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    setPending(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/writings/${params.id}/pending`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    forceReview(context, id) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/writings/${id}/review`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    generateContent(context, { writing, params }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/writings/${writing}/generate`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    unassignWriting(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/writings/${params.id}/unassign`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    killDuplicateCredits() {
        return new Promise((resolve, reject) => {
            ApiService.get(`/killduplicate`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    updateStatus(context, writing) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/writings/${writing.id}/status/${writing.writing_status_id}`, {
                params: { writing: writing }
            })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    resetFilters({ commit }) {
        commit('resetWritingsConfig')
    },

    deleteWriting(context, writing) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/writings/${writing.id}`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    setWritingFinished(context, writing) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/writings/${writing.id}/status/4`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
