<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">Export</h4>
                </header>
                <div class="card-content">
                    <div class="content d-flex flex-column">
                        <b-loading v-model="isLoading" :is-full-page="false" />

                        <form v-if="!isExported" class="mx-auto my-4">
                            <p>
                                {{ $t('partenaire-commandes.choose-format') }}
                            </p>

                            <div class="form-group">
                                <select id="format" v-model="formatFile" class="form-control" name="format">
                                    <option value="csv">CSV</option>
                                    <option value="xlsx">XLSX</option>
                                </select>
                            </div>

                            <div class="row mb-3">
                                <div class="form-group col-12 col-md-6">
                                    <label for="date_from">{{ $t('redactor.from') }}</label>
                                    <input
                                        id="date_from"
                                        v-model="dateFrom"
                                        type="date"
                                        class="form-control"
                                        required
                                    />
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="date_to">{{ $t('redactor.to') }}</label>
                                    <input id="date_to" v-model="dateTo" type="date" class="form-control" required />
                                </div>
                                <div class="col-12">
                                    <small v-if="errorMessage" class="text-danger">{{ errorMessage }}</small>
                                </div>
                            </div>

                            <button class="button is-warning" @click.prevent="generateExport">
                                {{ $t('partenaire-commandes.get-data') }}
                            </button>
                        </form>

                        <div v-else-if="!isLoading && isExported" class="mx-auto my-4">
                            <button class="button is-warning" @click="download">
                                {{ $t('global.download') }}
                            </button>
                        </div>

                        <div class="buttons justify-content-end">
                            <button class="button is-light" @click.prevent="closeAndResetModal">
                                {{ $t('global.close') }}
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'TrackingOrderExportModal',
        data() {
            return {
                modalIsOpen: false,
                isLoading: false,

                isExported: false,
                file: null,
                formatFile: 'csv',

                dateFrom: null,
                dateTo: null,
                errorMessage: null
            }
        },

        created() {
            let date = new Date()
            this.dateFrom = this.moment().clone().startOf('month').format('YYYY-MM-DD')
            this.dateTo = this.moment(date).format('YYYY-MM-DD')
        },

        methods: {
            download() {
                window.location.href = this.file
            },

            getFile(fileLink) {
                this.isLoading = false

                if (!fileLink) {
                    return
                }

                this.file = fileLink
                this.isExported = true
            },

            generateExport() {
                this.errorMessage = null

                if (!this.dateFrom || !this.dateTo) {
                    return
                }

                if (this.dateTo < this.dateFrom) {
                    this.errorMessage = this.$t('suivi.invalid-date')
                    return
                }

                this.isLoading = true

                this.$emit('exportOrders', {
                    format: this.formatFile,
                    from: this.dateFrom,
                    to: this.dateTo,
                    callback: this.getFile
                })
            },

            openModal() {
                this.modalIsOpen = true
            },

            closeAndResetModal() {
                this.modalIsOpen = false
                this.isLoading = false
                this.isExported = false
                this.file = null
            }
        }
    }
</script>

<style>
    label[for^='scrape_'] {
        color: #363636;
        display: block;
        font-size: 1rem;
        font-weight: 700;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
