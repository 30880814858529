export default {
    setTotal(state, data) {
        state.total = data.urls.meta.total
    },
    setSites(state, data) {
        state.sites = data
    },
    setKeywords(state, data) {
        state.keywords = data
    },
    setRestrictions(state, data) {
        state.restrictions = data
    },
    setSitesOnSales(state, data) {
        state.sellingWebsites = data
    },
    setDomainPosting(state, data) {
        state.domainPosting = data
    },
    setSitesAvailables(state, data) {
        state.sitesAvailables = data
    },
    setOwnership(state, data) {
        state.ownership = data
    }
}
