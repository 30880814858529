<template>
    <div class="overflow-x-auto mt-2">
        <div class="d-flex justify-content-between">
            <b-field grouped group-multiline class="flex-grow-1">
                <div class="control">
                    <b-taglist attached>
                        <b-tag type="is-warning" size="is-medium">
                            {{ $t('global.orders') }}
                        </b-tag>
                        <b-tag type="is-dark" size="is-medium">
                            {{ ordersLength }}
                        </b-tag>
                    </b-taglist>
                </div>

                <div class="control">
                    <b-taglist attached>
                        <b-tag type="is-warning" size="is-medium">
                            {{ $t('redactor.earnings') }}
                        </b-tag>
                        <b-tag type="is-dark" size="is-medium"> {{ totalEarnings }}€</b-tag>
                    </b-taglist>
                </div>
            </b-field>
            <PerPageSelector v-model="perPage" :loading="loading" @perPageChange="loadAsyncData" />
            <TableColumnSelect v-model="columns" />
        </div>

        <b-table
            :data="orders.data"
            :mobile-cards="true"
            :debounce-search="400"
            default-sort-direction="desc"
            :total="orders.total"
            :striped="true"
            :per-page="perPage"
            :loading="loading"
            :paginated="true"
            pagination-rounded
            backend-filtering
            backend-pagination
            backend-sorting
            @filters-change="onFilterChange"
            @page-change="onPageChange"
            @sort="onSort"
        >
            <b-table-column
                centered
                field="id"
                width="1.2em"
                sortable
                :searchable="isSearchable"
                :label="columns[0].label"
                :visible="columns[0].visible"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">#{{ props.row.id }}</div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.ref') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="created_at"
                width="2em"
                sortable
                :searchable="isSearchable"
                :custom-search="searchDate"
                :label="columns[1].label"
                :visible="columns[1].visible"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    {{ moment(props.row.created_at).format('DD/MM/YYYY') }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="date"
                            class="form-control form-control-sm"
                            :placeholder="'Date...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="statut"
                sortable
                :searchable="isSearchable"
                :custom-search="searchStatus"
                :label="columns[2].label"
                :visible="columns[2].visible"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.statut == 'a_valider'" class="tag is-warning"
                        ><i class="far fa-handshake mr-1" /> {{ $t('status.waitingforvalidation') }}</span
                    >
                    <span v-else-if="props.row.statut == 'en_cours'" class="tag is-info"
                        ><i class="far fa-handshake mr-1" /> {{ $t('status.publication') }}</span
                    >
                    <span v-else-if="props.row.statut == 'termine'" class="tag is-success"
                        ><i class="far fa-handshake mr-1" /> {{ $t('status.published') }}</span
                    >
                    <span v-else-if="props.row.statut == 'refuse'" class="tag is-danger"
                        ><i class="far fa-handshake mr-1" /> {{ $t('status.refused') }}</span
                    >
                    <span v-else-if="props.row.statut == 'annule'" class="tag is-danger"
                        ><i class="far fa-handshake mr-1" /> {{ $t('status.canceled') }}</span
                    >
                    <span v-else class="tag is-danger"><i class="far fa-handshake mr-1" /> Erreur</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                        <option :value="undefined">
                            {{ $t('precommande.all') }}
                        </option>
                        <option value="a_valider">
                            {{ $t('status.waitingforvalidation') }}
                        </option>
                        <option value="en_cours">
                            {{ $t('status.publication') }}
                        </option>
                        <option value="termine">
                            {{ $t('status.published') }}
                        </option>
                        <option value="erreur">
                            {{ $t('status.refused') }}
                        </option>
                        <option value="annule">
                            {{ $t('status.canceled') }}
                        </option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column
                centered
                width="1em"
                field="content_lang"
                sortable
                :searchable="isSearchable"
                :label="columns[3].label"
                :visible="columns[3].visible"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    <span class="">
                        <flag class="rounded shadow-sm" :iso="props.row.content_lang == 'fr_fr' ? 'fr' : 'us'" />
                    </span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option :value="undefined">
                                {{ $t('precommande.all') }}
                            </option>
                            <option value="fr_fr">
                                {{ $t('language.French') }}
                            </option>
                            <option value="com_en">
                                {{ $t('language.English') }}
                            </option>
                        </select>
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="url"
                sortable
                :searchable="isSearchable"
                :label="columns[4].label"
                :visible="columns[4].visible"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ props.row.url }}</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="'Page ...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="bought_kw"
                :label="columns[5].label"
                :visible="columns[5].visible"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    <button
                        v-if="props.row.statut === 'termine'"
                        class="button is-light"
                        @click="openOrderDetailModal(props.row)"
                    >
                        {{ $t('suivi.seedatas') }}
                    </button>
                    <span v-else style="min-width: 120px; display: block">-</span>
                </div>
            </b-table-column>

            <b-table-column
                field="option_mots"
                sortable
                :searchable="isSearchable"
                :custom-search="searchByWordsCount"
                :label="columns[6].label"
                :visible="columns[6].visible"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    <p>{{ props.row.option_mots + 200 }} {{ $t('global.words') }}</p>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option :value="undefined">
                                {{ $t('precommande.all') }}
                            </option>
                            <option value="0">200</option>
                            <option value="100">300</option>
                            <option value="200">400</option>
                            <option value="300">500</option>
                            <option value="400">600</option>
                            <option value="500">700</option>
                            <option value="600">800</option>
                            <option value="700">900</option>
                        </select>
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="link_anchor"
                :searchable="isSearchable"
                :label="columns[7].label"
                :visible="columns[7].visible"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <p class="mb-0">
                        {{ props.row.link_url }}
                    </p>
                    <p class="mb-0">
                        {{ props.row.link_anchor }}
                    </p>
                    <div v-if="props.row.link_second_url">
                        <p class="mb-0">
                            {{ props.row.link_second_url }}
                        </p>
                        <p class="mb-0">
                            {{ props.row.link_second_anchor }}
                        </p>
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('redactor.link-anchors') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="price_paid"
                width="2em"
                sortable
                :custom-search="searchByPrice"
                :label="columns[8].label"
                :visible="columns[8].visible"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    <p>{{ props.row.earning.toFixed(2) }}€</p>
                </div>
            </b-table-column>

            <b-table-column
                field="sold_nl"
                width="2em"
                sortable
                :searchable="isSearchable"
                :label="columns[9].label"
                :visible="columns[9].visible"
                cell-class="align-middle text-center"
            >
                <template v-slot:header="{ column }">
                    <b-tooltip label="Commande Nextlevel <24 mois à garder (Vente site)" append-to-body dashed>
                        {{ column.label }}
                    </b-tooltip>
                </template>
                <div slot="default" slot-scope="props">
                    <p>
                        <span v-if="props.row.sold_nl" class="tag is-success">Oui</span>
                        <span v-else class="tag is-danger">Non</span>
                    </p>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option :value="undefined">
                                {{ $t('precommande.all') }}
                            </option>
                            <option value="1">Oui</option>
                            <option value="0">Non</option>
                        </select>
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="date_publication_prevue"
                centered
                sortable
                :searchable="isSearchable"
                :label="columns[10].label"
                :visible="columns[10].visible"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    <p>
                        {{
                            props.row.date_publication_prevue
                                ? moment(props.row.date_publication_prevue).format('DD/MM/YYYY')
                                : 'ASAP'
                        }}
                    </p>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="date"
                            class="form-control form-control-sm"
                            :placeholder="$t('redactor.publishdate') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="redaction"
                sortable
                :searchable="isSearchable"
                :label="columns[11].label"
                :visible="columns[11].visible"
                cell-class="align-middle text-center"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ props.row.redaction === 'nextlevel' ? 'Nextlevel' : $t('partenaire-site.partner') }}</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option :value="undefined">
                                {{ $t('precommande.all') }}
                            </option>
                            <option value="nextlevel">Nextlevel</option>
                            <option value="proprietaire">Proprietaire</option>
                        </select>
                    </div>
                </div>
            </b-table-column>
        </b-table>
        <nl-modal :open-modal="keywordsModal" title="Keywords" size="xl" @hidden="close">
            <b-table :data="keywords" :striped="true" default-sort="position">
                <b-table-column field="keyword" label="Mot-clé" sortable>
                    <template v-slot="{ row }">
                        {{ row }}
                    </template>
                </b-table-column>
            </b-table>
        </nl-modal>
        <OrderDetailModal ref="orderDetailModal" :from-sites="true" />
    </div>
</template>

<script>
    import TableColumnSelect from '../UI/Table/TableColumnSelect'
    import PerPageSelector from '../UI/Table/PerPageSelector'
    import OrderDetailModal from '@/components/Content/OrderDetailModal.vue'

    export default {
        name: 'SitesOrdersTable',
        components: { OrderDetailModal, PerPageSelector, TableColumnSelect },
        props: ['data', 'ordersLength', 'totalEarnings'],

        data() {
            return {
                orders: [],
                isSearchable: true,
                loading: false,
                perPage: 25,
                isMobileWidth: window.innerWidth < 768,
                keywordsModal: false,
                keywords: [],
                isNarrowed: true,
                sortIconSize: 'is-small',
                columns: [
                    {
                        label: 'ID'
                    },
                    {
                        label: 'Date'
                    },
                    {
                        label: this.$t('global.status')
                    },
                    {
                        label: this.$t('language.title')
                    },
                    {
                        label: 'Page'
                    },
                    {
                        label: 'Infos URL'
                    },
                    {
                        label: this.$t('global.content')
                    },
                    {
                        label: this.$t('redactor.link-anchors')
                    },
                    {
                        label: this.$t('global.price')
                    },
                    {
                        label: 'Vente NL'
                    },
                    {
                        label: this.$t('redactor.publishdate')
                    },
                    {
                        label: this.$t('partenaire-site.redaction')
                    }
                ]
            }
        },
        watch: {
            data: {
                handler(newValue) {
                    this.orders = newValue

                    if (this.loading) {
                        this.loading = false
                    }
                },
                deep: true
            }
        },

        created() {
            this.orders = this.data
        },

        methods: {
            searchDate(item, inputStr) {
                var itemDate = this.moment(item.created_at).startOf('day').format('L')

                if (itemDate.includes(inputStr)) {
                    return true
                }

                return false
            },
            openOrderDetailModal(data) {
                this.$refs.orderDetailModal.showModal(data)
            },
            displayKeywords(content) {
                this.keywords = content.keywords.split(',')
                this.keywordsModal = true
            },
            searchByWordsCount(obj, string) {
                return obj.option_mots + 200 == string
            },

            searchByPrice(obj, string) {
                return (obj.earning + '').indexOf(string) > -1
            },

            searchStatus(obj, string) {
                if (string == 'all') {
                    return true
                }

                return obj.statut == string
            },
            close() {
                this.keywordsModal = false
            },
            loadAsyncData() {
                this.loading = true

                this.$emit('loadAsyncData', {
                    page: this.page,
                    filters: this.filters,
                    sortField: this.sortField,
                    sortOrder: this.sortOrder,
                    per_page: this.perPage,

                    callback() {
                        this.loading = false
                    }
                })
            },
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onFilterChange(filter) {
                var filters = []
                Object.keys(filter).forEach(element => {
                    var currentFilter = {
                        key: element,
                        value: filter[element]
                    }
                    filters.push(currentFilter)
                })
                this.filters = filters
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order

                this.loadAsyncData()
            }
        }
    }
</script>

<style>
    .-mt-1 {
        margin-top: -0.5em;
    }

    .media-content p.text {
        margin-bottom: 0;
    }
</style>
