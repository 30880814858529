<template>
    <span>
        <a href="#" class="rounded button is-light rounded-lg text-decoration-none mr-1" @click.prevent="openModal()">
            <b-icon icon="pencil-alt" />
        </a>
        <b-modal v-model="modal">
            <template #default="props">
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">Modifier le consultant</h4>
                </header>
                <div class="box">
                    <b-loading v-if="loading" v-model="loading" :is-full-page="false" />
                    <div v-else>
                        <div class="modal-body">
                            <b-field label="Email">
                                <b-input type="email" disabled :value="consultant.user.email" maxlength="30" />
                            </b-field>
                            <b-field label="Nom">
                                <b-input v-model="consultant.user.lastname" type="text" />
                            </b-field>
                            <b-field label="Prénom">
                                <b-input v-model="consultant.user.firstname" type="text" />
                            </b-field>
                            <b-field label="Mot de passe">
                                <b-input v-model="password" type="password" />
                            </b-field>
                        </div>
                        <div class="modal-footer">
                            <b-button type="button" class="btn btn-danger" @click="props.close">Annuler</b-button>
                            <b-button type="button" class="btn btn-primary" @click="save">Modifier</b-button>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
    </span>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        props: {
            consultant: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            modal: false,
            type: null,
            loading: false,
            form: {},
            password: null
        }),
        computed: {},
        methods: {
            ...mapActions('consultants', {
                updateConsultant: 'update'
            }),
            openModal() {
                this.modal = true
            },
            save() {
                this.loading = true
                this.updateConsultant({
                    id: this.consultant.id,
                    password: this.password,
                    firstname: this.consultant.user.firstname,
                    lastname: this.consultant.user.lastname
                })
                    .then(() => {
                        this.$store.dispatch('toast', {
                            title: 'Consultant modifié',
                            type: 'success'
                        })

                        this.modal = false
                        this.password = nulll
                        this.$emit('reload')
                    })
                    .catch(error => {
                        this.$store.dispatch('toast', {
                            title: 'Une erreur est survenue lors de la mise à jourddd',
                            type: 'error'
                        })
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        }
    }
</script>

<style></style>
