<template>
    <label class="mb-4"
        ><input :ref="name" :name="name" :checked="isChecked" type="checkbox" class="mr-2" @change="changeValue" />
        <slot />
    </label>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                default: ''
            },
            value: {
                type: [Boolean, Number],
                default: 0
            },
            rules: {
                type: [Object, String],
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            trueValue: {
                type: [Boolean, Number],
                default: 1
            },
            falseValue: {
                type: [Boolean, Number],
                default: 0
            }
        },
        data: () => ({
            disabled: false
            //isChecked: false
        }),
        computed: {
            isChecked() {
                return this.value === this.trueValue
            }
        },
        created() {
            //this.isChecked = this.value === this.trueValue
        },
        methods: {
            changeValue() {
                if (this.disabled) {
                    return
                }

                const isChecked = !this.isChecked
                const val = isChecked ? this.trueValue : this.falseValue
                this.$emit('input', val)
                //this.isChecked = isChecked
            }
        }
    }
</script>

<style lang="scss" scoped>
    label {
        white-space: nowrap;
    }
</style>
