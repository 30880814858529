<template>
    <b-notification :closable="false" role="alert" type="is-info is-light">
        <p class="pb-0 mb-0">
            <i class="fa fa-exclamation-triangle mr-1" />
            Vous relisez actuellement une commande écrite par
            {{ writing.redactor.user.firstname }}
            {{ writing.redactor.user.lastname }}
        </p>
    </b-notification>
</template>
<script>
    export default {
        name: 'ReviewWritingNotification',
        props: {
            writing: {
                type: Object,
                required: true
            }
        }
    }
</script>
