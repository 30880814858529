<template>
    <fragment>
        <router-link class="nav_link" to="/admin/orders">
            <i class="fas fa-shopping-cart nav_icon" />
            <span class="nav_name">{{ $t('global.orders') }}</span>
        </router-link>

        <router-link class="nav_link" to="/admin/redactor">
            <i class="fas fa-pen nav_icon" />
            <span class="nav_name">Redactor</span>
        </router-link>

        <router-link class="nav_link" to="/admin/campaigns">
            <i class="fas fa-life-ring nav_icon" />
            <span class="nav_name">{{ $t('layout.counseilling') }}</span>
        </router-link>

        <router-link class="nav_link" to="/consultant/campaigns">
            <i class="fas fa-user-tie nav_icon" />
            <span class="nav_name">{{ $t('layout.advisors') }} NL</span>
        </router-link>

        <router-link class="nav_link" to="/admin/sites">
            <i class="fas fa-sitemap nav_icon" />
            <span class="nav_name">{{ $t('global.sites') }}</span>
        </router-link>

        <router-link class="nav_link" to="/admin/spots/sites">
            <i class="fas fa-link nav_icon" />
            <span class="nav_name">Spots</span>
        </router-link>

        <router-link class="nav_link" to="/admin/spots/orders">
            <i class="fas fa-link nav_icon" />
            <span class="nav_name">Spots commandes</span>
        </router-link>

        <router-link class="nav_link" to="/admin/spots/content-items">
            <i class="fas fa-link nav_icon" />
            <span class="nav_name">Contenus spots générés</span>
        </router-link>

        <router-link class="nav_link" to="/admin/users">
            <i class="fas fa-users nav_icon" />
            <span class="nav_name">{{ $t('layout.users') }}</span>
        </router-link>

        <SidebarDropdown title="Précommandes" to="/admin/pre-orders/statistics" icon="fas fa-pencil-ruler">
            <router-link to="/admin/orders/report" class="sidebar__sublink">
                <span class="nav_name">{{ $t('layout.invoicessummary') }}</span>
            </router-link>

            <router-link to="/admin/pre-orders/statistics" class="sidebar__sublink">
                <span class="nav_name">Statistiques Précommandes</span>
            </router-link>

            <router-link to="/admin/sites/availability" class="sidebar__sublink">
                <span class="nav_name">{{ $t('layout.availablesites') }}</span>
            </router-link>

            <router-link to="/admin/orders/listing" class="sidebar__sublink">
                <span class="nav_name">URL proposées</span>
            </router-link>

            <router-link to="/admin/keywords/new" class="sidebar__sublink">
                <span class="nav_name">{{ $t('layout.addkeyword') }}</span>
            </router-link>
        </SidebarDropdown>

        <SidebarDropdown title="Facturation" to="/admin/withdrawal-requests" icon="fas fa-wallet">
            <router-link to="/admin/invoices" class="sidebar__sublink">
                <span class="nav_name">Facturation</span>
            </router-link>

            <router-link to="/admin/withdrawal-requests" class="sidebar__sublink">
                <span class="nav_name">{{ $t('layout.whidraw') }}</span>
            </router-link>

            <router-link to="/admin/partners" class="sidebar__sublink">
                <span class="nav_name">{{ $t('layout.partner-admin') }}</span>
            </router-link>

            <router-link to="/admin/coupons" class="sidebar__sublink">
                <span class="nav_name">{{ $t('layout.promocode') }}</span>
            </router-link>
        </SidebarDropdown>

        <SidebarDropdown title="Configuration" to="/admin/config" icon="fas fa-cogs">
            <router-link to="/admin/config" class="sidebar__sublink">
                <span class="nav_name">{{ $t('layout.config') }}</span>
            </router-link>

            <router-link to="/admin/config/mails" class="sidebar__sublink">
                <span class="nav_name">{{ $t('layout.configmails') }}</span>
            </router-link>

            <router-link to="/admin/groups" class="sidebar__sublink">
                <span class="nav_name">{{ $t('global.categories') }}</span>
            </router-link>

            <router-link to="/admin/last-researches" class="sidebar__sublink">
                <span class="nav_name">{{ $t('layout.seeallthesearch') }}</span>
            </router-link>
        </SidebarDropdown>
    </fragment>
</template>
<script>
    export default {
        name: 'AdminMenu'
    }
</script>