<script>
    export default {
        props: {
            vid: {
                type: String,
                default: undefined
            },
            name: {
                type: String,
                default: ''
            },
            value: {
                type: [String, Boolean, Number],
                default: ''
            },
            rules: {
                type: [Object, String],
                default: ''
            }
        },
        data: () => ({
            id: null
        }),
        computed: {
            input: {
                get() {
                    return this.value
                },
                set(value) {
                    return this.$emit('input', value)
                }
            },
            definitiveName() {
                return this.name ? this.name : this.id
            }
        },
        mounted() {
            this.id = 'field_' + this._uid
        },
        methods: {
            onInput(value) {
                this.input = value
            }
        }
    }
</script>
