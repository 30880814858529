<template>
    <div class="card-header align-items-center">
        <div
            v-if="!edit"
            @click="open"
            class="d-flex justify-content-between align-items-center cursor-pointer"
            style="width: 100%"
        >
            <span>Client</span>
            <i v-if="!campaign.meta.is_started && !readonly" class="fas fa-pen" />
        </div>
        <b-autocomplete
            v-show="edit"
            class="w-100"
            ref="input"
            placeholder="Entrer nom, prénom ou email"
            autocomplete="none"
            clearable
            :custom-formatter="formatText"
            :data="data"
            :loading="isFetching"
            :check-infinite-scroll="true"
            :open-on-focus="true"
            @typing="getAsyncData"
            @select="option => update(option)"
            @infinite-scroll="getMoreAsyncData"
            @keydown.esc.native="close"
        />
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import debounce from 'lodash/debounce'

    export default {
        name: 'CustomerSelector',
        props: {
            campaign: {
                required: true,
                default: Object
            },
            readonly: {
                required: false,
                default: true
            }
        },
        data: function () {
            return {
                edit: false,

                // autocomplete
                data: [],
                page: 1,
                totalPages: 1,
                isFetching: false,
                input: ''
            }
        },
        methods: {
            ...mapActions('users', ['getCustomers']),
            ...mapActions('campaigns', ['updateCampaign']),
            getAsyncData: debounce(function (filter) {
                // String update
                if (this.filter !== filter) {
                    this.filter = filter
                    this.data = []
                    this.page = 1
                    this.totalPages = 1
                }
                // String cleared
                if (!filter.length) {
                    this.data = []
                    this.page = 1
                    this.totalPages = 1
                    return
                }
                // Reached last page
                if (this.page > this.totalPages) {
                    return
                }
                this.isFetching = true
                this.getCustomers({
                    filters: this.filter.split(' ').filter(e => e !== null)
                })
                    .then(res => (this.data = res.data))
                    .catch(e => {
                        throw e
                    })
                    .finally(() => (this.isFetching = false))
            }, 500),
            getMoreAsyncData: debounce(function () {
                this.getAsyncData(this.filter)
            }, 250),

            formatText(i) {
                return `${i.email} - ${i.firstname} ${i.lastname}`
            },
            close() {
                this.edit = false
            },
            open() {
                if (!this.campaign.meta.is_started && !this.readonly) {
                    this.edit = true
                    this.$nextTick(() => {
                        this.$refs.input.focus()
                    })
                }
            },
            update(value) {
                if (value?.id) {
                    this.$emit('loading', true)
                    this.updateCampaign({
                        campaignId: this.campaign.id,
                        user_id: value.id
                    }).then(() => this.$emit('loading', false))

                    this.close()
                }
            }
        }
    }
</script>

<style scoped></style>
