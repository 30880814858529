<template>
    <span>
        <a
            href="#"
            class="rounded button is-warning rounded-lg text-decoration-none mr-1"
            @click.prevent="handleInput(target)"
        >
            Affecter à une agence
        </a>

        <AffectUserToAgencyModal ref="modal" @hide="$emit('reload')" />
    </span>
</template>

<script>
    import AffectUserToAgencyModal from '../Modal/AffectUserToAgencyModal'

    export default {
        name: 'AffectUserToAgencyButton',
        components: {
            AffectUserToAgencyModal
        },
        model: {
            prop: 'target',
            event: 'click'
        },
        props: {
            target: {
                type: Object,
                required: true
            }
        },
        data: () => ({}),
        methods: {
            handleInput(value) {
                this.$store.commit('consultants/setNewUser', {
                    user: value,
                    type: 'manager'
                })
                this.$refs.modal.show()
            }
        }
    }
</script>
