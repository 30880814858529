var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"width":"100%","overflow":"auto","position":"relative","border-radius":"6px"}},[_c('b-loading',{attrs:{"active":_vm.loading,"is-full-page":false}}),_c('table',{staticClass:"table is-striped"},[_c('colgroup',[(_vm.detailed)?_c('col',{staticStyle:{"background":"white"},attrs:{"span":1}}):_vm._e(),_vm._l((_vm.surHeaders),function(header,key){return _c('col',{key:`col_${key}`,style:({ backgroundColor: key % 2 === 0 ? 'white' : '#fef3c7' }),attrs:{"span":header.colspan}})})],2),_c('thead',[(_vm.surHeaders)?_c('tr',[(_vm.detailed)?_c('th'):_vm._e(),_vm._l((_vm.surHeaders),function(header,key){return _c('th',{key:`surHeader_${key}`,attrs:{"colspan":header.colspan}},[_vm._v(" "+_vm._s(header.label)+" ")])})],2):_vm._e(),_c('tr',[(_vm.detailed)?_c('th'):_vm._e(),_vm._l((_vm.filteredColumns),function(column,index){return _c('th',{key:`header_${index}`,style:({
                            verticalAlign: 'middle',
                            borderBottom: _vm.meta.sortBy === column.field ? '2px solid #7a7a7a' : '2px solid #dee2e6',
                            hoverBorderBottom: '2px solid #7a7a7a'
                        })},[_c('div',{staticClass:"d-flex justify-content-center align-items-center",class:{'cursor-pointer': column.sortable},staticStyle:{"gap":"0.5rem"},on:{"click":function($event){column.sortable ? _vm.onSort(column.sortField || column.field) : null}}},[(column.tooltip)?_c('b-tooltip',{attrs:{"label":column.tooltip,"dashed":""}},[_c('span',{class:{'cursor-pointer': column.sortable}},[_vm._v(" "+_vm._s(column.label)+" ")])]):[_vm._v(" "+_vm._s(column.label)+" ")],(_vm.meta.sortBy === column.field)?_c('b-icon',{style:({
                                    transform: _vm.meta.orderBy === 'desc' ? 'rotate(180deg)' : 'rotate(0)',
                                    transition: 'transform .2s'
                                }),attrs:{"icon":"arrow-up","size":"is-small"}}):_vm._e()],2)])})],2)]),_c('tbody',_vm._l((_vm.data),function(row){return _c('fragment',{key:`row_${row.id}`},[_c('tr',_vm._l((_vm.filteredColumns),function(column,j){return _c('td',{key:`row_${row.id}-col_${j}`},[(column.custom)?_vm._t(_vm.getSlotName(column.field),null,{"props":{ column, row }}):_c('NlTableCell',{attrs:{"centered":column.centered,"default-value":column.defaultValue,"field-option":column.fieldOption,"filter":column.filter,"prefix":column.prefix,"suffix":column.suffix,"type":column.type,"value":_vm.extractValue(row, column.field)}})],2)}),0)])}),1),(_vm.totalField)?_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":_vm.filteredColumns.length - 1}}),_c('td',[_vm._v("Total")]),_c('td',[_vm._v(_vm._s(_vm.data.reduce((a, b) => a + b[_vm.totalField], 0))+"€")])])]):_vm._e()])],1),_c('b-pagination',{attrs:{"rounded":"","current":_vm.meta.page,"per-page":_vm.meta.perPage,"total":_vm.meta.total},on:{"change":_vm.onPageChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }