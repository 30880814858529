<template>
    <ValidationObserver v-if="followup" v-slot="{ invalid }" ref="form">
        <form class="" @submit.prevent="onSubmit()">
            <nl-modal
                :title="followup.id ? 'Modification du suivi' : 'Ajouter un suivi'"
                :open-modal="show"
                @hide="$emit('discard')"
            >
                <div class="row">
                    <div class="col-12 col-md-6">
                        <ValidationProvider
                            v-slot="{ errors }"
                            tag="div"
                            name="Date"
                            rules="required"
                            class="form-group"
                        >
                            <label for="frm_followup_date">Date</label>
                            <flat-pickr
                                id="frm_followup_date"
                                v-model="followup.date"
                                :config="datePickrConfig"
                                class="form-control"
                                :class="{ 'is-invalid': errors.length > 0 }"
                            />
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-md-6">
                        <ValidationProvider
                            v-slot="{ errors }"
                            tag="div"
                            name="Duration"
                            rules="required"
                            class="form-group"
                        >
                            <label for="frm_followup_duration">Durée</label>
                            <nl-duration
                                id="frm_followup_duration"
                                v-model="followup.duration"
                                :input-class="['form-control', errors.length > 0 ? 'is-invalid' : '']"
                            />
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </ValidationProvider>
                    </div>
                </div>

                <ValidationProvider v-slot="{ errors }" tag="div" name="Période" rules="required" class="form-group">
                    <label for="frm_followup_period">Période</label>
                    <select
                        id="frm_followup_period"
                        v-model="followup.period"
                        name=""
                        class="form-control"
                        :class="{ 'is-invalid': errors.length > 0 }"
                    >
                        <option v-for="(date, i) in periods" :key="i" :value="date">
                            {{ $d(Date.parse(date), 'month') }}
                        </option>
                    </select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" tag="div" name="Thématique" rules="required" class="form-group">
                    <label for="frm_followup_thematic">Thématique</label>
                    <select
                        id="frm_followup_thematic"
                        v-model="followup.thematic"
                        name=""
                        class="form-control"
                        :class="{ 'is-invalid': errors.length > 0 }"
                    >
                        <option v-for="(thematic, i) in thematicsAvailable" :key="i" :value="thematic">
                            {{ $t('accompagnement.followup.thematic.' + thematic) }}
                        </option>
                    </select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ errors }"
                    ref="taskValidationProvider"
                    tag="div"
                    name="Tâche"
                    rules="required"
                    class="form-group"
                >
                    <label for="frm_followup_task">Tâche</label>
                    <vue-typeahead-bootstrap
                        id="frm_followup_task"
                        v-model="followup.task"
                        placeholder="Tâche"
                        :data="tasksAvailable"
                        :background-variant-resolver="d => ''"
                        :show-on-focus="true"
                        :input-class="errors.length > 0 ? 'is-invalid' : ''"
                    />
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" tag="div" name="Statut" rules="required" class="form-group">
                    <label for="frm_followup_status">Statut</label>
                    <select
                        id="frm_followup_status"
                        v-model="followup.status"
                        name=""
                        class="form-control"
                        :class="{ 'is-invalid': errors.length > 0 }"
                    >
                        <option v-for="(status, i) in statusAvailable" :key="i" :value="status">
                            {{ $t('accompagnement.followup.status.' + status) }}
                        </option>
                    </select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" tag="div" name="Commentaire" class="form-group">
                    <label for="frm_followup_comment">Commentaire</label>
                    <textarea
                        id="frm_followup_comment"
                        v-model="followup.comment"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length > 0 }"
                    />
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </ValidationProvider>

                <template v-slot:footer>
                    <button v-if="followup.id" type="button" class="btn btn-danger mr-auto" @click="deleteFollowup()">
                        <i class="fas fa-trash-alt" />
                        <span>Supprimer ce suivi</span>
                    </button>

                    <button type="button" class="btn btn-primary" data-dismiss="modal">Anuler</button>
                    <button
                        type="submit"
                        class="btn btn-secondary"
                        :disabled="invalid"
                        v-html="followup.id ? 'Modification du suivi' : 'Ajouter un suivi'"
                    />
                </template>
            </nl-modal>
        </form>
    </ValidationObserver>
</template>

<script>
    import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
    import NlModal from '../UI/NlModal'
    import { required } from 'vee-validate/dist/rules'
    import flatPickr from 'vue-flatpickr-component'
    import { French } from 'flatpickr/dist/l10n/fr'
    import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
    import NlDuration from '../UI/NlDuration'
    import moment from 'moment'
    import { mapState } from 'vuex'

    extend('required', required)

    export default {
        components: {
            NlDuration,
            flatPickr,
            ValidationObserver,
            ValidationProvider,
            NlModal,
            VueTypeaheadBootstrap
        },
        props: {
            content: {
                type: Object
            },
            show: {
                type: Boolean,
                default: false
            },
            statusAvailable: {
                required: true,
                type: Array,
                default: () => []
            },
            thematicsAvailable: {
                required: true,
                type: Array,
                default: () => []
            },
            tasksAvailable: {
                type: Array,
                default: () => []
            }
        },
        data: function () {
            return {
                followup: null,
                datePickrConfig: {
                    locale: French,
                    altFormat: 'd/m/Y',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    disableMobile: 'true'
                }
            }
        },
        computed: {
            ...mapState('campaigns', {
                startDate: state => state.campaign.information.start_at,
                duration: state => state.campaign.information.duration
            }),
            periods() {
                let date = moment(this.startDate).startOf('month')

                let periods = []

                Array.from(Array(this.duration).keys()).forEach(() => {
                    periods.push(date.format('YYYY-MM-DD'))

                    date.add(1, 'month')
                })

                return periods
            }
        },
        watch: {
            show(isShow) {
                if (!isShow && this.$refs.form) {
                    this.$nextTick(() => {
                        this.$refs.form.reset()
                    })
                }
            },
            content(content) {
                this.setFollowup(content)
            }
        },
        mounted() {
            this.setFollowup(this.content)
        },
        methods: {
            setFollowup(content) {
                this.followup = {
                    task: '',
                    ...content
                }
                if (!this.followup.date) {
                    this.followup.date = new Date()
                }
                if (this.$refs.form) {
                    this.$refs.form.reset()
                }
            },
            onSubmit: function () {
                this.$emit('save', {
                    ...this.followup
                })
            },
            deleteFollowup: function () {
                this.$emit('delete', this.followup.id)
            }
        }
    }
</script>

<style scoped></style>
