<template>
    <div>
        <OwnerChangeModal ref="ownerChangeModal" @refreshSite="refreshSite"></OwnerChangeModal>

        <nl-table
            :actions="actions"
            :checked-rows.sync="checkedRows"
            :columns.sync="columns"
            :force-update="forceUpdate"
            :get-data="loadAsyncData"
            :left-buttons="leftButtons"
            :searchable="false"
            centered
            default-sort="nb_mots_cles_seobserver"
            default-sort-direction="desc"
            sortable
            ref="nlTable"
        >
            <template #actif="{ props: { row } }">
                <b-tooltip
                    v-if="row.actif === -2 || row.actif === -4"
                    :label="
                        row.desactivation_site_to != null
                            ? moment(row.desactivation_site_to).format('L') +
                              ' au ' +
                              moment(row.scheduled_at).format('L')
                            : row.status_updated_at != null
                            ? moment(row.status_updated_at).format('L')
                            : 'Pas de date d\'enregistré'
                    "
                    position="is-right"
                >
                    <GroupSiteStatus :site="row" />
                </b-tooltip>
                <GroupSiteStatus v-else :site="row" />
                <b-tooltip
                    v-if="(row.notes !== '' && row.notes != null && row.actif === -2) || row.actif === -4"
                    :label="row.notes"
                    position="is-right"
                >
                    <i class="fas fa-comment-dots text-danger" />
                </b-tooltip>
            </template>

            <template
                #actifSearch="{
                    props: {
                        filters,
                        column: { field }
                    }
                }"
            >
                <b-select size="is-small" v-model="filters[field]">
                    <option :value="null">Tous</option>
                    <option value="1">
                        {{ $t('group-site.status.activated') }}
                    </option>
                    <option value="0">
                        {{ $t('group-site.status.deleted') }}
                    </option>
                    <option value="-1">
                        {{ $t('group-site.status.validation') }}
                    </option>
                    <option value="-2">
                        {{ $t('group-site.status.desactivated') }}
                    </option>
                    <option value="-3">
                        {{ $t('group-site.status.refused') }}
                    </option>
                    <option value="-4">
                        {{ $t('group-site.status.desactivated_partner') }}
                    </option>
                    <option value="-5">
                        {{ $t('group-site.status.amelioration') }}
                    </option>
                </b-select>
            </template>

            <template
                #notes_customerSearch="{
                    props: {
                        filters,
                        column: { field }
                    }
                }"
            >
                <b-select v-model="filters[field]" size="is-small">
                    <option :value="null">{{ $t('global.all') }}</option>
                    <option v-for="(note, index) in customerNoteReasons" :key="index" :value="index">{{ note }}</option>
                </b-select>
            </template>

            <template
                #notes_customer="{
                    props: {
                        row: { notes_customer }
                    }
                }"
            >
                <span>{{ getCustomerNotesReason(notes_customer) }}</span>
            </template>

            <template
                #langSearch="{
                    props: {
                        filters,
                        column: { field }
                    }
                }"
            >
                <b-select v-model="filters[field]" size="is-small">
                    <option :value="null">
                        {{ $t('global.all') }}
                    </option>
                    <option value="fr_fr">Français</option>
                    <option value="en_en">English</option>
                </b-select>
            </template>

            <template #lang="{ props }">
                <flag :iso="'en' === props.row.lang ? 'us' : 'fr'" />
            </template>

            <template #ndd="{ props: { row, column } }">
                <ColumnEditable
                    :type="'input'"
                    :init-value="row.ndd"
                    :site-id="row.id"
                    :field="column.field"
                    @input-blur="sendEditColumn($event)"
                />
            </template>

            <template #url="{ props: { row, column } }">
                <ColumnEditable
                    :type="'input'"
                    :init-value="row.url"
                    :site-id="row.id"
                    :field="column.field"
                    @input-blur="sendEditColumn($event)"
                />
            </template>

            <template
                #group_id="{
                    props: {
                        row: { id, group_id },
                        column: { field }
                    }
                }"
            >
                <ColumnEditable
                    :type="'select'"
                    :groups="groups"
                    :init-value="group_id"
                    :site-id="id"
                    :field="field"
                    @input-blur="sendEditColumn($event)"
                >
                    <option v-for="group in groups" :key="group.id" :value="group.id">
                        <div v-if="group.parent_id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ group.name }}</div>

                        <div v-else>
                            {{ group.name }}
                        </div>
                    </option>
                </ColumnEditable>
            </template>

            <template
                #group_idSearch="{
                    props: {
                        filters,
                        column: { field }
                    }
                }"
            >
                <b-select v-model="filters[field]" size="is-small">
                    <option :value="null">
                        {{ $t('global.all') }}
                    </option>
                    <option v-for="group in groups" :key="group.id" :value="group.id">
                        <div>{{ group.name }}</div>
                    </option>
                </b-select>
            </template>

            <template #email="{ props: { row } }">
                <span>
                    <a :href="'/admin/partners/details?id=' + row.partenaire_id" target="_blank">
                        {{ row.email != null ? row.email : 'NextLevel' }}
                    </a>
                </span>
            </template>

            <template #ownerAction="{ props: { row, index } }">
                <div class="d-flex">
                <LoginAs v-if="row.email != null" :user-id="row.partenaire_id" />

                    <b-tooltip label="Changer le propriétaire" class="ml-2">
                        <b-button type="is-secondary is-light" size="is-small" @click="openOwnerChangeModal(row, index)">
                            <i class="fas fa-sync-alt"></i>
                        </b-button>
                    </b-tooltip>
                </div>
            </template>

            <template
                #concerned_url="{
                    props: {
                        row: {
                            id,
                            actif,
                            scrape_whitelist,
                            scrape_blacklist,
                            scrape_sitemap,
                            admin_scrape_blacklist,
                            prix_minimum_filtre,
                            outrepasser_liens
                        }
                    }
                }"
            >
                <span v-if="actif !== 0 && actif !== -3">
                    <a
                        :href="`/admin/sites/restrictions?id=${id}`"
                        target="_blank"
                        v-if="scrape_whitelist || scrape_blacklist || scrape_sitemap || admin_scrape_blacklist"
                    >
                        <span class="mr-1" v-if="scrape_sitemap">Sitemap</span>
                        <span class="mr-1" v-if="scrape_whitelist">Whitelist</span>
                        <span v-if="scrape_blacklist">Blacklist</span>
                        <span v-if="admin_scrape_blacklist">Blacklist Admin</span>
                    </a>
                    <span v-else>{{ $t('partenaire-site.all-site') }}</span>
                    <i v-if="prix_minimum_filtre > 0" class="fas fa-euro-sign text-warning" />
                    <i v-if="outrepasser_liens === 1" class="fas fa-lock-open" />
                </span>
            </template>

            <template #site_type="{ props: { row, column } }">
                <ColumnEditable
                    :type="'select'"
                    :init-value="row.site_type"
                    :site-id="row.id"
                    :field="column.field"
                    @input-blur="sendEditColumn($event)"
                >
                    <option value="">Type de site</option>
                    <option value="or">Or</option>
                    <option value="argent">Argent</option>
                    <option value="bronze">Bronze</option>
                    <option value="vipor">VIP Or</option>
                    <option value="vipargent">VIP Argent</option>
                    <option value="vipbronze">VIP Bronze</option>
                    <option value="media">Media</option>
                </ColumnEditable>
            </template>

            <template
                #site_typeSearch="{
                    props: {
                        filters,
                        column: { field }
                    }
                }"
            >
                <b-select v-model="filters[field]" size="is-small">
                    <option :value="null">Tous</option>
                    <option value="or">Or</option>
                    <option value="argent">Argent</option>
                    <option value="bronze">Bronze</option>
                    <option value="vipor">VIP Or</option>
                    <option value="vipargent">VIP Argent</option>
                    <option value="vipbronze">VIP Bronze</option>
                    <option value="media">Media</option>
                </b-select>
            </template>

            <template #wp_data="{ props: { row, index } }">
                <NlWordpress :site-data="row" site-type="site" @pluginDeleted="removePlugin(index)"/>
            </template>

            <template #nb_mots_cles_seobserverSearch="{ props: { filters } }">
                <div class="d-flex">
                    <b-numberinput
                        v-model="filters['min_nb_mots_cles_seobserver']"
                        :controls="false"
                        size="is-small"
                        placeholder="min."
                    />
                    <b-numberinput
                        v-model="filters['max_nb_mots_cles_seobserver']"
                        :controls="false"
                        size="is-small"
                        placeholder="max."
                    />
                </div>
            </template>

            <template
                #nb_keywords="{
                    props: {
                        row: { id, nb_keywords }
                    }
                }"
            >
                <a
                    target="_blank"
                    :href="`/admin/sites/keywords?id=${id}`"
                    class="button is-light text-decoration-none"
                >
                    <span class="mr-1">{{ nb_keywords }}</span>
                    <b-icon icon="eye" size="is-small" />
                </a>
            </template>

            <template #nb_keywordsSearch="{ props: { filters } }">
                <div class="d-flex">
                    <b-numberinput
                        v-model="filters['min_nb_keywords']"
                        :controls="false"
                        size="is-small"
                        placeholder="min."
                    />
                    <b-numberinput
                        v-model="filters['max_nb_keywords']"
                        :controls="false"
                        size="is-small"
                        placeholder="max."
                    />
                </div>
            </template>

            <template
                #orders_count="{
                    props: {
                        row: { id, orders_count }
                    }
                }"
            >
                <a target="_blank" :href="`/admin/sites/orders?id=${id}`" class="button is-light text-decoration-none">
                    <span class="mr-1">{{ orders_count }}</span>
                    <b-icon icon="eye" size="is-small" />
                </a>
            </template>

            <template #orders_countSearch="{ props: { filters } }">
                <div class="d-flex">
                    <b-numberinput
                        v-model="filters['min_orders_count']"
                        :controls="false"
                        size="is-small"
                        placeholder="min."
                    />
                    <b-numberinput
                        v-model="filters['max_orders_count']"
                        :controls="false"
                        size="is-small"
                        placeholder="max."
                    />
                </div>
            </template>

            <template
                #majestic_dataRd="{
                    props: {
                        row: { majestic_data }
                    }
                }"
            >
                <b-tag v-if="!majestic_data">-</b-tag>
                <b-tag v-else-if="majestic_data.rd > 25" type="is-success">
                    {{ majestic_data.rd }}
                </b-tag>
                <b-tag v-else-if="majestic_data.rd > 20 && rd <= 25" type="is-warning">
                    {{ majestic_data.rd }}
                </b-tag>
                <b-tag v-else type="is-danger">
                    {{ majestic_data.rd }}
                </b-tag>
            </template>

            <template #majestic_data.rdSearch="{ props: { filters } }">
                <div class="d-flex">
                    <b-numberinput
                        v-model="filters['min_rd_count']"
                        :controls="false"
                        size="is-small"
                        placeholder="min."
                    />
                    <b-numberinput
                        v-model="filters['max_rd_count']"
                        :controls="false"
                        size="is-small"
                        placeholder="max."
                    />
                </div>
            </template>

            <template
                #health="{
                    props: {
                        row: { health }
                    }
                }"
            >
                <span v-if="health >= 200 && health < 300" class="tag is-success">
                    {{ health }}
                </span>
                <span v-else-if="health >= 300 && health < 400" class="tag is-warning">
                    {{ health }}
                </span>
                <span v-else class="tag is-danger">
                    {{ health ? health : '500' }}
                </span>
            </template>

            <template
                #healthSearch="{
                    props: {
                        filters,
                        column: { field }
                    }
                }"
            >
                <b-select v-model="filters[field + '_range']" size="is-small">
                    <option :value="null">Tous</option>
                    <option value="200">2XX</option>
                    <option value="300">3XX</option>
                    <option value="400">4XX</option>
                    <option value="500">5XX</option>
                </b-select>
            </template>

            <template #sold="{ props }">
                <span v-if="props.row.sold" class="tag is-success">Oui</span>
                <span v-else class="tag is-danger">Non</span>
            </template>

            <template
                #soldSearch="{
                    props: {
                        filters,
                        column: { field }
                    }
                }"
            >
                <b-select v-model="filters[field]" size="is-small">
                    <option :value="null">Tous</option>
                    <option value="1">Oui</option>
                    <option value="0">Non</option>
                </b-select>
            </template>

            <template
                #redaction="{
                    props: {
                        row: { id, redaction },
                        column: { field }
                    }
                }"
            >
                <ColumnEditable
                    :type="'select'"
                    :init-value="redaction"
                    :site-id="id"
                    :field="field"
                    @input-blur="sendEditColumn($event)"
                >
                    <option :value="null">Tous</option>
                    <option value="nextlevel">NL</option>
                    <option value="proprietaire">Partenaire</option>
                </ColumnEditable>
            </template>

            <template
                #redactionSearch="{
                    props: {
                        filters,
                        column: { field }
                    }
                }"
            >
                <b-select v-model="filters[field]" size="is-small">
                    <option :value="null">Tous</option>
                    <option value="nextlevel">NL</option>
                    <option value="proprietaire">Partenaire</option>
                </b-select>
            </template>

            <template
                #accept_preorders_auto="{
                    props: {
                        row: { accept_preorders_auto }
                    }
                }"
            >
                <span v-if="accept_preorders_auto" class="tag is-success">Oui</span>
                <span v-else class="tag is-danger">Non</span>
            </template>

            <template
                #accept_preorders_autoSearch="{
                    props: {
                        filters,
                        column: { field }
                    }
                }"
            >
                <b-select v-model="filters[field]" size="is-small">
                    <option :value="null">Tous</option>
                    <option value="1">Oui</option>
                    <option value="0">Non</option>
                </b-select>
            </template>

            <template
                #actions="{
                    props: {
                        row: { id }
                    }
                }"
            >
                <div class="is-flex">
                    <a
                        target="_blank"
                        :href="`/admin/sites/${id}/urls`"
                        class="rounded button is-light rounded-lg text-decoration-none mr-1"
                    >
                        <b-icon icon="link" />
                    </a>
                    <a
                        target="_blank"
                        :href="`/admin/sites/preorders?id=${id}`"
                        class="rounded button is-light rounded-lg text-decoration-none mr-1"
                    >
                        <b-icon pack="fas" icon="paint-brush" />
                    </a>
                    <a
                        class="rounded button is-light rounded-lg text-decoration-none mr-1"
                        @click.prevent="openNoteModal(id)"
                    >
                        <b-icon pack="fas" icon="comment-alt" />
                    </a>
                </div>
            </template>
        </nl-table>

        <NoteModal
            ref="noteModal"
            :type-array="['Support', 'Developpement', 'Commercial', 'Communication', 'Comptabilité', 'Autre']"
            @send="addNoteToSite"
        />

        <GroupSiteConfirmModal
            ref="confirmModal"
            :checked-rows="checkedRows"
            :groups="groups"
            @uncheckRows="checkedRows = []"
        />
        <SalesModal ref="salesModal" />
    </div>
</template>

<script>
    import GroupSiteConfirmModal from './GroupSiteConfirmModal'
    import ColumnEditable from './ColumnEditable'
    import GroupSiteStatus from './GroupSiteStatus'
    import NlWordpress from '@/components/UI/Wordpress/NlWordpress.vue'
    import NoteModal from '@/components/Admin/Notes/NoteModal.vue'
    import SalesModal from '@/components/Sites/SalesModal.vue'
    import OwnerChangeModal from '@/components/Admin/Sites/OwnerChangeModal.vue'
    import {mapActions, mapState} from 'vuex'

    export default {
        name: 'GroupSiteTable',
        components: {
            SalesModal,
            NoteModal,
            NlWordpress,
            ColumnEditable,
            GroupSiteConfirmModal,
            GroupSiteStatus,
            OwnerChangeModal
        },
        props: {
            tabInfo: {
                type: Object
            },
            groups: {
                type: Array
            },
            partners: {
                type: Array
            },
            forceUpdate: {
                type: Boolean
            },
            leftButtons: {
                type: Array,
                required: true
            },
            status: {
                type: Number,
                required: false
            }
        },

        data() {
            return {
                sites: [],
                isSearchable: true,
                bulkActionType: null,
                checkedRows: [],
                loading: false,
                selected: null,
                customerNoteReasons: [
                    'Pas assez de RD',
                    'Aucun contenu',
                    'Trop de liens sortants',
                    'Design',
                    'One page',
                    'Mentions légales'
                ],
                columns: [
                    {
                        label: 'ID',
                        field: 'id',
                        searchable: true
                    },
                    {
                        label: this.$t('global.status'),
                        field: 'actif',
                        custom: true,
                        searchable: true,
                        customSearch: true,
                        sortable: false,
                        hide: this.tabInfo.type !== 'all'
                    },
                    {
                        label: 'Date désactivation',
                        field: 'status_updated_at',
                        searchable: true,
                        inputType: 'date',
                        type: 'date',
                        fieldOption: {
                            dateFormat: 'medium_date'
                        },
                        hide: !['desactivated', 'desactivated_nl'].includes(this.tabInfo.type)
                    },
                    {
                        label: this.$t('global.locale'),
                        field: 'lang',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        custom: true,
                        customSearch: true,
                        visible: false
                    },
                    {
                        label: 'NDD',
                        field: 'ndd',
                        visible: false,
                        custom: true,
                        searchable: true
                    },
                    {
                        label: 'URL',
                        field: 'url',
                        centered: false,
                        custom: true,
                        searchable: true,
                        sortable: true
                    },
                    {
                        label: 'Motif',
                        field: 'notes_customer',
                        custom: true,
                        searchable: true,
                        customSearch: true,
                        sortable: false,
                        hide: !['waiting_improvement'].includes(this.tabInfo.type)
                    },
                    {
                        label: this.$t('group-site.table.group'),
                        field: 'group_id',
                        visible: false,
                        searchable: true,
                        custom: true,
                        customSearch: true
                    },
                    {
                        label: this.$t('group-site.table.owner'),
                        field: 'email',
                        custom: true,
                        searchable: true,
                        centered: false,
                    },
                    {
                        label: '',
                        field: 'ownerAction',
                        custom: true,
                        searchable: false,
                        centered: false,
                    },
                    {
                        label: this.$t('partenaire-site.url'),
                        field: 'concerned_url',
                        custom: true,
                        hide: ['deleted', 'waiting_improvement'].includes(this.tabInfo.type),
                        sortable: false
                    },
                    {
                        label: this.$t('group-site.table.type'),
                        field: 'site_type',
                        custom: true,
                        searchable: true,
                        customSearch: true,
                        sortable: false
                    },
                    {
                        centered: false,
                        label: this.$t('group-site.table.WP'),
                        field: 'wp_data',
                        custom: true,
                        customSearch: true,
                        sortable: false,
                        hide: this.tabInfo.type === 'waiting_activation'
                    },
                    {
                        label: this.$t('group-site.table.seobserver_keywords'),
                        field: 'nb_mots_cles_seobserver',
                        searchable: true,
                        customSearch: true,
                        sortable: true
                    },
                    {
                        label: this.$t('group-site.table.seobserver_update'),
                        field: 'seobserver_updated_at',
                        type: 'date',
                        fieldOption: {
                            dateFormat: 'medium_date'
                        }
                    },
                    {
                        label: this.$t('group-site.table.nl_keywords'),
                        field: 'nb_keywords',
                        searchable: true,
                        customSearch: true,
                        custom: true
                    },
                    {
                        label: this.$t('group-site.table.nb_orders'),
                        field: 'orders_count',
                        custom: true,
                        searchable: true,
                        customSearch: true,
                        hide: ['waiting_improvement', 'waiting_activation'].includes(this.tabInfo.type)
                    },
                    {
                        label: 'Santé',
                        field: 'health',
                        visible: false,
                        custom: true,
                        searchable: true,
                        customSearch: true
                    },
                    {
                        label: 'RD',
                        field: 'majestic_data.rd',
                        custom: true,
                        searchable: true,
                        customSearch: true,
                        hide: !['all', 'activated'].includes(this.tabInfo.type)
                    },
                    {
                        label: 'Vendu',
                        field: 'sold',
                        custom: true,
                        searchable: true,
                        customSearch: true,
                        sortable: false
                    },
                    {
                        label: 'Rédaction',
                        field: 'redaction',
                        custom: true,
                        searchable: true,
                        customSearch: true,
                        sortable: false
                    },
                    {
                        label: 'Préco auto',
                        field: 'accept_preorders_auto',
                        custom: true,
                        searchable: true,
                        customSearch: true
                    },
                    {
                        label: "Date d'ajout",
                        field: 'created_at',
                        visible: false,
                        searchable: true,
                        inputType: 'date',
                        type: 'date',
                        fieldOption: {
                            dateFormat: 'medium_date'
                        }
                    },
                    {
                        label: 'XPATH',
                        field: 'xpath_field',
                        visible: false,
                        searchable: true
                    },
                    {
                        label: this.$t('global.actions'),
                        field: 'actions',
                        custom: true
                    }
                ],
                actions: [
                    {
                        label: this.$t('global.activate'),
                        action: () => {
                            this.openConfirmModal('activate')
                        }
                    },
                    {
                        label: "En attente d'amélioration",
                        action: () => {
                            this.openConfirmModal('waitingActivate')
                        }
                    },
                    {
                        label: this.$t('global.desactivate'),
                        action: () => {
                            this.openConfirmModal('desactivate')
                        }
                    },
                    {
                        label: this.$t('global.delete'),
                        action: () => {
                            this.openConfirmModal('delete')
                        }
                    },
                    {
                        label: 'Rédaction',
                        action: () => {
                            this.openConfirmModal('redaction')
                        }
                    },
                    {
                        label: 'Type de site',
                        action: () => {
                            this.openConfirmModal('type')
                        }
                    },
                    {
                        label: 'Groupe',
                        action: () => {
                            this.openConfirmModal('group')
                        }
                    },
                    {
                        label: 'Mettre en vente',
                        action: () => {
                            this.openSalesModal()
                        }
                    }
                ],
                filters: '',
                page: 1,
                perPage: 50,

                isMobileWidth: window.innerWidth < 768,
                editRedaction: null,
                editCategory: null,
                editWordsMax: null
            }
        },
        computed: {
            site: {
                get() {
                    return this.$store.state.sites.ownership.site
                },
                set(value) {
                    let ownership = this.$store.state.sites.ownership
                    ownership.site = value

                    this.$store.commit('sites/setOwnership', ownership)
                }
            }
        },
        methods: {
            ...mapActions('sites', ['getSites', 'getSite']),

            removePlugin(index) {
                let site = this.$refs.nlTable.asyncData[index]
                site.wp_data = null

                this.$refs.nlTable.asyncData.splice(index, 1, site)
            },

            refreshSite(site, index) {
                console.log(index)
                this.$refs.nlTable.asyncData.splice(index, 1, site)
            },

            loadAsyncData(params) {
                if (this.status) {
                    params.filter['actif'] = this.status
                }

                return this.getSites(params)
            },

            findGroup(groupId) {
                let group = this.groups.find(group => group.id == groupId)
                return group.name
            },
            openOwnerChangeModal(site, index) {
                if((this.site && this.site.ndd !== site.ndd) || !this.site) {
                    this.getSite(site.id).then((data) => {
                        this.site = data
                        this.$refs.ownerChangeModal.openModal(index)
                    }).catch((e) => {
                        this.$store.dispatch('toastError', e)
                    })
                } else {
                    this.$refs.ownerChangeModal.openModal(index)
                }
            },
            openConfirmModal(bulkActionType) {
                this.$refs.confirmModal.openModal(bulkActionType)
            },
            openSalesModal() {
                this.$refs.salesModal.openModal(this.checkedRows)
            },
            columnSelection(column) {
                return this.columns.find(col => col.name == column).visible
            },

            openNoteModal(siteId) {
                this.$refs.noteModal.openModal(siteId)
            },

            addNoteToSite(data) {
                this.axios
                    .post('/admin/note/site', data)
                    .then(() => {})
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
            },

            openWPModal(site) {
                this.$emit('openWPModal', {
                    url: site.url,
                    api_key: site.wp_data !== null ? site.wp_data.token : '',
                    id: site.id,
                    get_infos: site.wp_data !== null ? site.wp_data.get_infos : false,
                    get_categories: site.wp_data !== null ? site.wp_data.get_categories : false,
                    get_authors: site.wp_data !== null ? site.wp_data.get_authors : false,
                    post_articles: site.wp_data !== null ? site.wp_data.post_articles : false
                })
            },

            sendBulkAction() {
                let listIds = this.checkedRows.map(site => site.id)

                switch (this.bulkActionType) {
                    case 'desactivate':
                        this.$refs.confirmModal.openModal(this.bulkActionType)
                        break
                    case 'activate':
                        this.$emit('activateBulkSites', {
                            ids: listIds,
                            action: 'activate'
                        })
                        this.checkedRows = []
                        break
                    case 'waitingActivate':
                        this.$refs.confirmModal.openModal(this.bulkActionType)
                        break
                    case 'delete':
                        this.$refs.confirmModal.openModal(this.bulkActionType)
                        break
                    case 'redaction':
                        this.$refs.confirmModal.openModal(this.bulkActionType)
                        break
                    case 'type':
                        this.$refs.confirmModal.openModal(this.bulkActionType)
                        break
                    case 'group':
                        this.$refs.confirmModal.openModal(this.bulkActionType)
                        break
                    case 'listOnSale':
                        this.$refs.salesModal.openModal(this.checkedRows)
                        break
                    default:
                        break
                }
            },

            closeFlyEdit() {
                this.editRedaction = null
                this.editCategory = null
                this.editWordsMax = null
            },

            handleEditCategory(id) {
                this.editCategory = id
            },

            sendEditColumn(data) {
                this.axios.post(`/admin/sites/edit-column`, {
                    id: data.id,
                    field: data.field,
                    value: data.value
                })
            },
            computeWpStatus(data) {
                if (data.is_active && data.get_infos && data.get_categories && data.get_authors && data.post_articles) {
                    return 'is-success'
                } else if (
                    data.is_active &&
                    (!data.get_infos || !data.get_categories || !data.get_authors || !data.post_articles)
                ) {
                    return 'is-warning'
                } else {
                    return 'is-danger'
                }
            },
            getCustomerNotesReason(note) {
                if (note && note.charAt(0) >= 0 && note.charAt(0) <= 4) {
                    return this.customerNoteReasons[note.charAt(0)]
                } else {
                    return '-'
                }
            }
        }
    }
</script>

<style>
    .min-width-110 {
        min-width: 110px;
    }

    .-mt-1 {
        margin-top: -0.5em;
    }

    .media-content p.text {
        margin-bottom: 0;
    }

    .is-editable {
        padding: 0.5em;
        border-radius: 5px;
        cursor: pointer;
    }

    .is-editable:hover {
        background: #eee;
    }
</style>