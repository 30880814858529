<template>
    <nl-modal ref="modal" :title="`Générer le contenu IA`" :add-legacy-events="false" position="center" size="md">
        <div v-if="writing">
            <p>
                Nombre de mots souhaités :
                <b-tag>{{ writing.words_count }}</b-tag>
            </p>
            <p>
                Ancre 1
                <b-tag>{{ writing.primary_anchor }}</b-tag>
                ->
                <strong
                    ><a :href="writing.primary_link" target="_blank">{{ writing.primary_link }}</a></strong
                >
            </p>
            <p v-if="writing.secondary_anchor && writing.secondary_link">
                Ancre 2
                <b-tag>{{ writing.secondary_anchor }}</b-tag>
                /
                <strong
                    ><a :href="writing.secondary_link" target="_blank">{{ writing.secondary_link }}</a></strong
                >
            </p>
            Ouvrir la page cible :
            <strong
                ><a :href="writing.page" target="_blank">{{ writing.page }}</a></strong
            >
        </div>
        <hr />
        <div v-if="writing" class="d-flex justify-content-around">
            <div>
                <h5>Brief</h5>
                {{ writing.brief ? writing.brief : 'RAS' }}
            </div>
            <div>
                <h5>Brief partenaire</h5>
                {{ writing.brief_partner ? writing.brief_partner : 'RAS' }}
            </div>
        </div>
        <hr />
        <div>
            <b-field label="H2">
                <b-input v-model="titles[0]" expanded> </b-input>
            </b-field>

            <b-field v-if="writing && writing.words_count > 300" label="H2">
                <b-input v-model="titles[1]" expanded> </b-input>
            </b-field>
        </div>
        <template #footer>
            <b-button type="is-primary" @click="hide">Annuler</b-button>
            <b-button type="is-warning" @click="onSave"> Générer</b-button>
        </template>
    </nl-modal>
</template>

<script>
    export default {
        name: 'GenerateContentModal',
        data() {
            return {
                writing: null,
                index: null,
                showModal: true,
                titles: []
            }
        },
        methods: {
            show(writing, index) {
                this.writing = writing
                this.index = index
                this.$refs.modal.show()
                this.titles = []
                if (this.writing.titles_for_ai) {
                    this.titles = JSON.parse(this.writing.titles_for_ai)
                }
            },
            hide() {
                this.$refs.modal.hide()
            },
            onSave() {
                this.$emit('generate-content', { writing: this.writing, titles: this.titles, index: this.index })
                this.hide()
            }
        }
    }
</script>