<template>
    <div>
        <b-button label="Note" icon-left="sticky-note" type="is-danger" size="is-small" @click="active = true" />

        <b-modal v-model="active">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <p class="modal-card-title m-0">Note</p>
                </header>
                <div class="card-content">
                    {{ note }}
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'PartnerSiteNoteModal',
        props: {
            note: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                active: false
            }
        }
    }
</script>
