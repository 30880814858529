import { render, staticRenderFns } from "./CampaignLaunchingMail.vue?vue&type=template&id=4fc88da0&"
import script from "./CampaignLaunchingMail.vue?vue&type=script&lang=js&"
export * from "./CampaignLaunchingMail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports