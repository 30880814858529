<template>
    <b-modal v-model="active">
        <template>
            <header class="px-4 py-3 has-background-warning shadow-sm">
                <h4 class="h4 p-0 mb-0">Mettre des sites en ventes</h4>
            </header>
            <div class="modal-body">
                <h3 class="text-center">Options</h3>
                <div class="d-flex align-items-center justify-content-around">
                    <b-field label="Prix">
                        <b-input v-model="price" placeholder="€" />
                    </b-field>
                    <b-field label="Vendable">
                        <b-switch v-model="saleable" true-value="1" false-value="0" type="is-success" />
                    </b-field>
                    <b-field label="GNews">
                        <b-switch v-model="gnews" true-value="1" false-value="0" type="is-success" />
                    </b-field>
                </div>
                <h3 class="text-center">Sites</h3>
                <b-table :data="content" :striped="true" :debounce-search="200" class="table-striped">
                    <b-table-column field="id" label="ID" cell-class="align-middle">
                        <div slot="default" slot-scope="props">#{{ props.row.id }}</div>
                    </b-table-column>
                    <b-table-column field="ndd" label="ndd" cell-class="align-middle">
                        <div slot="default" slot-scope="props">
                            {{ props.row.ndd }}
                        </div>
                    </b-table-column>
                    <b-table-column field="proprio" label="Propriétaire" cell-class="align-middle">
                        <div slot="default" slot-scope="props" class="text-center">
                            <span v-if="props.row.proprio !== null" class="tag is-success p-2 text-center"
                                >Partenaire</span
                            >
                            <span v-else class="tag is-warning text-dark p-2 text-center">NextLevel</span>
                        </div>
                    </b-table-column>
                    <b-table-column field="orders_count" label="Nb de commandes" cell-class="align-middle">
                        <div slot="default" slot-scope="props">
                            {{ props.row.orders_count }}
                        </div>
                    </b-table-column>
                    <b-table-column field="keywords" label="MC" cell-class="align-middle">
                        <div slot="default" slot-scope="props">
                            {{ props.row.keywords }}
                        </div>
                    </b-table-column>
                    <b-table-column field="CA" label="CA" cell-class="align-middle">
                        <div slot="default" slot-scope="props">{{ roundAmount(props.row.ca) }}€</div>
                    </b-table-column>
                </b-table>
            </div>
            <div class="modal-footer">
                <b-button type="button" class="btn btn-secondary" @click="listSites"> Sauvegarder</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'SalesModal',
        data() {
            return {
                active: false,
                content: [],
                price: 0,
                saleable: 0,
                gnews: 0
            }
        },
        watch: {
            content() {
                this.saleable = 0
                this.content.forEach(site => {
                    if (site.saleable === 1) {
                        this.saleable = 1
                    }
                })
            }
        },
        methods: {
            ...mapActions('sites', ['listSitesOnSale']),
            ...mapActions('orders_reports', ['getReports']),
            openModal(content) {
                this.content = content
                this.active = true
            },
            closeModal() {
                this.active = false
            },
            listSites() {
                this.listSitesOnSale({
                    ids: this.ids,
                    price: Number(this.price),
                    saleable: this.saleable,
                    gnews: this.gnews
                })
                    .then(() => {
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'Le site a bien mis en vente'
                        })
                        this.closeModal()
                        this.getReports()
                    })
                    .catch(e => {
                        this.$store.dispatch('toast', {
                            type: 'error',
                            title: 'Une erreur est survenue',
                            message: e.message
                        })
                    })
            },
            roundAmount(amount) {
                return Math.round(amount * 100) / 100
            }
        },
        computed: {
            ids() {
                return this.content.map(row => row.id)
            }
        }
    }
</script>

<style></style>
