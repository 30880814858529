<template>
    <div class="mb-3">
        <b-field :label="label">
            <b-input v-model="value" :type="type" :min="min" :max="max" />
        </b-field>
        <b-button type="is-primary" @click="$emit('update', { key: configKey, data: form, type: 'input' })">
            Modifier
        </b-button>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'InputConfig',
        props: ['configKey', 'label', 'type', 'min', 'max', 'filter'],
        computed: {
            ...mapState('config', {
                config: state => state.config
            }),
            value: {
                get() {
                    if (this.form === '') return
                    return this.filter === 'cents' ? this.form / 100 : this.form
                },
                set(newVal) {
                    this.form = this.filter === 'cents' ? newVal * 100 : newVal
                }
            }
        },
        data() {
            return {
                form: []
            }
        },
        created() {
            this.form = this.config[this.configKey][0].value
        }
    }
</script>

<style scoped></style>
