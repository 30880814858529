<template>
    <span>
        <span v-if="currentOrder.link_status === 'en_attente'" class="tag is-light">
            {{ $t('partenaire-commandes.pending') }}
        </span>

        <span
            v-else-if="
                currentOrder.link_status === 'ok' &&
                (!currentOrder.link_second_anchor || currentOrder.link_second_status === 'ok')
            "
            class="tag is-light"
        >
            OK
        </span>

        <span v-else class="tag is-light is-danger">
            {{ $t('global.error') }}
            <span v-if="currentOrder.link_status_message">
                -
                {{
                    currentOrder.link_status_message == 'anchor_not_found' ||
                    currentOrder.link_status_message == 'link_not_found'
                        ? $t('crawler.exceptions.' + currentOrder.link_status_message)
                        : currentOrder.link_status_message
                }}
            </span>
            <span v-if="currentOrder.link_second_status_message">
                -
                {{
                    currentOrder.link_second_status_message == 'anchor_not_found' ||
                    currentOrder.link_second_status_message == 'link_not_found'
                        ? '-' + $t('crawler.exceptions.' + currentOrder.link_second_status_message)
                        : '-' + currentOrder.link_second_status_message
                }}
            </span>
        </span>
    </span>
</template>

<script>
    export default {
        name: 'PartnerOrdersStatusToBeTreated',
        props: ['order'],

        data() {
            return {
                currentOrder: null
            }
        },

        watch: {
            order: {
                immediate: true,
                deep: true,
                handler(newValue) {
                    this.currentOrder = newValue
                }
            }
        },

        created() {
            this.currentOrder = this.order
        }
    }
</script>
