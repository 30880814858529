<template>
    <div>
        <nl-modal title="Evolution des positions" size="xl" :open-modal="positionModal" @hidden="positionModal = false">
            <RankCharts :infos="positionInfo" />
        </nl-modal>
        <b-table
            :sticky-header="false"
            :data="accompagnements.data"
            :mobile-cards="hasMobileCards"
            :checked-rows.sync="checkedRows"
            :current-page.sync="accompagnements.current_page"
            :total="accompagnements.total"
            backend-sorting
            backend-filtering
            backend-pagination
            :loading="loading"
            :paginated="isPaginated"
            :per-page="accompagnements.per_page"
            :row-class="(row, index) => row.isHeader === true && 'isHeader'"
            :debounce-search="400"
            class="my-table"
            @page-change="onPageChange"
            @sort="onSort"
            @filters-change="onFilterChange"
        >
            <b-table-column field="url" label="Url à pousser" searchable cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.isHeader === true" class="d-flex align-items-center">
                        <h5 class="mb-0">{{ props.row.url }}</h5>
                        <div class="btn-group ml-3">
                            <a
                                class="btn btn-outline-primary d-flex align-items-center"
                                :href="`https://app.monitorank.com/daily/keywords?view=8004&entity=${props.row.monitorankId}&field=ranking`"
                                target="_blank"
                            >
                                <img src="@/assets/img/monitorank-logo.png" style="width: 20px; filter: grayscale()" />
                            </a>
                            <a
                                target="_blank"
                                :href="'/admin/campaigns/details?id=' + props.row.id"
                                title="Voir"
                                class="btn btn-outline-primary"
                                ><i class="fas fa-eye"
                            /></a>
                            <button
                                type="button"
                                class="btn btn-outline-primary"
                                @click="getPositionFromMonitorank(props.row.id)"
                            >
                                <i data-v-615051d1="" class="fas fa-download" />
                                Relever MR
                            </button>
                        </div>
                    </span>
                    <span v-else>
                        <a :href="props.row.accompagnement_url" target="_blank">{{ props.row.accompagnement_url }}</a>
                    </span>
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="URL"
                    />
                </div>
            </b-table-column>

            <b-table-column field="mot_cle" label="Requêtes" searchable>
                <div slot="default" slot-scope="props">
                    <div v-if="props.row.isHeader === false">
                        <div class="d-flex align-items-center">
                            <span class="mr-2">
                                <span v-if="props.row.query_id">
                                    <i class="fa fa-chart-line text-success" />
                                </span>
                                <span v-else>
                                    <i class="fa fa-chart-line text-danger" />
                                </span>
                            </span>

                            <a :href="'/search/keyword?keyword_search=' + props.row.mot_cle">{{ props.row.mot_cle }}</a>

                            <span v-if="props.row.note_divers">
                                <i
                                    class="fas fa-comment-dots text-danger"
                                    data-toggle="tooltip"
                                    data-html="true"
                                    title=""
                                    :data-original-title="props.row.note_divers"
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Requête"
                    />
                </div>
            </b-table-column>

            <b-table-column
                v-slot="props"
                field="position_initiale"
                label="1ère Position"
                numeric
                cell-class="align-middle"
            >
                <div class="d-flex align-items-center justify-content-center">
                    <span v-if="props.row.position_initiale">
                        {{ props.row.position_initiale }}
                    </span>
                </div>
            </b-table-column>

            <b-table-column v-slot="props" field="position" label="Dernière Position" numeric cell-class="align-middle">
                <div v-if="props.row.isHeader === true">
                    <span
                        v-if="props.row.date"
                        class="badge"
                        :class="isToday(props.row.date) ? 'badge-success' : 'badge-warning'"
                        >{{ moment(props.row.date).format('L') }}</span
                    >
                </div>
                <div v-else>
                    <span v-if="!props.row.position"> - </span>
                    <span v-else-if="props.row.position <= 0"> OUT </span>
                    <span v-else>
                        {{ props.row.position }}
                    </span>
                </div>
            </b-table-column>

            <b-table-column v-slot="props" field="position" label="Variation" numeric cell-class="align-middle">
                <div v-if="props.row.isHeader === false">
                    <a
                        @click="
                            () => {
                                openPositionModal(props.row)
                            }
                        "
                    >
                        <span v-if="!props.row.position || props.row.position <= 0">
                            <span class="text-danger">OUT</span>
                        </span>
                        <span v-else>
                            <span v-if="props.row.position == props.row.position_initiale"> = </span>
                            <span v-else-if="parseInt(props.row.position) < parseInt(props.row.position_initiale)">
                                <span class="text-success"
                                    >+ {{ props.row.position_initiale - props.row.position }}</span
                                >
                            </span>
                            <span v-else>
                                <span class="text-danger"
                                    >- {{ props.row.position - props.row.position_initiale }}</span
                                >
                            </span>
                        </span>
                    </a>
                </div>
            </b-table-column>
        </b-table>
    </div>
</template>

<style>
    .isHeader {
        background-color: #ebeced;
    }
</style>

<script>
    import ApiService from '@/services/api.service'

    export default {
        name: 'TabRank',
        props: ['accompagnements'],
        data: function () {
            return {
                accompagnementsList: [],

                //buefy data
                checkedRows: [],
                hasMobileCards: true,
                isPaginated: true,
                loading: false,
                currentPage: 1,
                perPage: 1000,
                sortField: 'accompagnement_id',
                sortOrder: 'desc',
                filters: '',
                positionModal: false,
                page: 1,
                positionInfo: {}
            }
        },
        watch: {
            accompagnements: function (newValue) {
                this.accompagnementsList = newValue
                this.checkedRows = []
            }
        },
        created() {
            this.accompagnementsList = this.accompagnements
        },
        mounted() {},
        methods: {
            isToday(date) {
                let today = new Date()
                date = new Date(date)
                return (
                    date.getDate() === today.getDate() &&
                    date.getMonth() === today.getMonth() &&
                    date.getFullYear() === today.getFullYear()
                )
            },
            getPositionFromMonitorank(id) {
                this.loading = true
                ApiService.put(`/campaigns/${id}/positions`)
                    .then(({ data }) => {
                        this.loadAsyncData()
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            loadAsyncData() {
                this.loading = true

                this.$emit('loadAsyncData', {
                    page: this.page,
                    sortField: this.sortField,
                    sortOrder: this.sortOrder,
                    filters: this.filters,
                    includes: '',
                    path: this.accompagnementsList.path,
                    index: this.indexType,
                    per_page: this.perPage,

                    callback: () => {
                        this.loading = false
                    }
                })
            },

            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order

                this.loadAsyncData()
            },

            onFilterChange(filter) {
                var filters = []
                Object.keys(filter).forEach(element => {
                    var currentFilter = {
                        key: element,
                        value: filter[element]
                    }
                    filters.push(currentFilter)
                })
                this.filters = filters
                this.loadAsyncData()
            },

            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            openPositionModal(infos) {
                this.positionInfo = infos
                this.positionModal = true
            }
        }
    }
</script>
