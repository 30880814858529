export default {
    download(response, filename) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should

        let newBlob = new Blob([response.data], {
            type: response.headers['content-type']
        })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        let link = document.createElement('a')
        link.href = data
        link.download = filename + this.extension(response.headers['content-type'])
        link.click()
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
        }, 100)
    },
    extension(contentType) {

        switch (contentType) {
            case 'image/jpg':
                return '.jpg'
            case 'application/pdf':
                return '.pdf'
            default:
                return ''
        }
    }
}
