<template>
    <div>
        <b-tag type="is-danger" size="is-small" style="cursor: pointer" @click="openModal">
            <b-icon icon="eye" class="mr-2" />
            {{ externalLinksCount }}
        </b-tag>
        <b-modal v-model="open">
            <template>
                <header class="modal-card-head has-background-warning">
                    <p class="modal-card-title m-0">Liens externes</p>
                </header>
                <section class="modal-card-body">
                    <NlTable :async="false" :loading="loading" :columns.sync="columns" :data="externalLinks.data" />
                </section>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable.vue'
    import { mapActions } from 'vuex'

    export default {
        name: 'PartnerExternalLinksModal',
        components: { NlTable },
        props: {
            urlId: {
                type: Number,
                required: true
            },
            externalLinksCount: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                open: false,
                loading: true,
                externalLinks: [],
                columns: [
                    {
                        centered: false,
                        custom: false,
                        field: 'link',
                        label: 'Url',
                        searchable: false,
                        sortable: false
                    }
                ]
            }
        },
        methods: {
            ...mapActions('urls', ['getExternalLinksByPartnerUrl']),

            openModal() {
                if (this.externalLinks.length === 0) {
                    this.loadAsyncData()
                }
                this.open = true
            },

            loadAsyncData() {
                this.getExternalLinksByPartnerUrl({
                    site_id: this.$route.params.id,
                    url_id: this.urlId
                }).then(response => {
                    this.externalLinks = response
                    this.loading = false
                })
            }
        }
    }
</script>
