<template>
    <b-tooltip label="Rétrograder en consultant">
        <b-button type="is-success is-light" class="mr-1" @click.prevent="$emit('click')">
            <span v-if="loading" class="icon">
                <i class="fas fa-circle-notch fa-spin" />
            </span>
            <b-icon v-if="!loading" icon="arrow-down" />
        </b-button>
    </b-tooltip>
</template>

<script>
    export default {
        name: 'DemoteButton',
        props: {
            manager: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            loading: false
        })
    }
</script>
