<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 v-if="activateData.active" class="h4 p-0 mb-0">
                        {{ $t('group-site.modal.activate_sites') }}
                    </h4>
                    <h4 v-else-if="desactivateData.active" class="h4 p-0 mb-0">
                        {{ $t('partenaire-site.desactivationwebsites') }}
                    </h4>
                    <h4 v-else-if="deleteData.active" class="h4 p-0 mb-0">
                        {{ $t('partenaire-site.deletesites') }}
                    </h4>
                    <h4 v-else class="h4 p-0 mb-0">
                        {{ $t('global.modify') }}
                    </h4>
                </header>
                <div class="card-content">
                    <div class="content">
                        <form>
                            <section v-if="activateData.active" class="mb-5">
                                <p class="h4 text-center">
                                    {{ $t('group-site.modal.confirm.activationwebsites-text') }}
                                </p>
                            </section>
                            <section v-if="desactivateData.active" class="row mb-3">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="label" for="choice">{{ $t('partenaire-site.choice') }}</label>
                                        <select
                                            id="choice"
                                            v-model="desactivateData.choice"
                                            name="choice"
                                            class="form-control"
                                            autocomplete="off"
                                        >
                                            <option value="1">
                                                {{ $t('partenaire-site.desactivationtemporary') }}
                                            </option>
                                            <option value="2">
                                                {{ $t('partenaire-site.desactivationwithoutdate') }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div v-if="showDesactivateDate" class="col-md-6">
                                    <div class="form-group">
                                        <label class="label" for="scheduled_at">{{
                                            $t('partenaire-site.startdate')
                                        }}</label>
                                        <input
                                            id="scheduled_at"
                                            v-model="desactivateData.scheduled_at"
                                            type="date"
                                            class="form-control"
                                            required
                                        />
                                    </div>
                                </div>

                                <div v-if="showDesactivateDate" class="col-md-6">
                                    <div class="form-group">
                                        <label class="label" for="desactivation_site_to">{{
                                            $t('partenaire-site.enddate')
                                        }}</label>
                                        <input
                                            id="desactivation_site_to"
                                            v-model="desactivateData.desactivation_site_to"
                                            type="date"
                                            class="form-control"
                                            required
                                        />
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="label" for="notes">{{ $t('group-site.modal.notes') }}</label>
                                        <textarea
                                            id="notes"
                                            v-model="desactivateData.notes"
                                            class="form-control w-100"
                                        />
                                    </div>
                                </div>
                            </section>

                            <section v-if="deleteData.active" class="row mb-3">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="label" for="deleteNotes">{{
                                            $t('group-site.modal.notes')
                                        }}</label>
                                        <textarea
                                            id="deleteNotes"
                                            v-model="deleteData.notes"
                                            class="form-control w-100"
                                        />
                                    </div>
                                </div>
                            </section>

                            <section v-if="waitingActivateData.active" class="row mb-3">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="label" for="waitingActivateDataChoice">Réponses types</label>
                                        <select
                                            id="waitingActivateDataChoice"
                                            v-model="waitingActivateData.choice"
                                            class="form-control"
                                            @change="waitingActivateNotes"
                                        >
                                            <option value="" selected="">-----</option>
                                            <option value="1">Aucun contenu</option>
                                            <option value="2">Trop de liens sortants</option>
                                            <option value="3">Design</option>
                                            <option value="5">Mentions légales</option>
                                        </select>
                                    </div>
                                </div>
                            </section>

                            <section v-if="waitingActivateData.active" class="row mb-3">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="label" for="waitingImprovNotes">Motif</label>
                                        <textarea
                                            id="waitingImprovNotes"
                                            v-model="waitingActivateData.notes"
                                            class="form-control w-100"
                                        />
                                    </div>
                                </div>
                            </section>

                            <section v-if="redactionData.active" class="mb-4">
                                <div class="form-group">
                                    <label class="label" for="redaction">Rédaction</label>
                                    <select
                                        id="redaction"
                                        v-model="redactionData.redaction"
                                        name="redaction"
                                        class="form-control"
                                        autocomplete="off"
                                    >
                                        <option value="nextlevel">NextLevel</option>
                                        <option value="proprietaire">Partenaire</option>
                                    </select>
                                </div>
                            </section>

                            <section v-if="typeData.active" class="mb-4">
                                <div class="form-group">
                                    <label class="label" for="siteType">Type de sites</label>
                                    <select
                                        id="siteType"
                                        v-model="typeData.type"
                                        name="redaction"
                                        class="form-control"
                                        autocomplete="off"
                                    >
                                        <option value="">Type de site</option>
                                        <option value="or">Or</option>
                                        <option value="argent">Argent</option>
                                        <option value="bronze">Bronze</option>
                                        <option value="vipor">VIP Or</option>
                                        <option value="vipargent">VIP Argent</option>
                                        <option value="vipbronze">VIP Bronze</option>
                                        <option value="media">Media</option>
                                    </select>
                                </div>
                            </section>

                            <section v-if="groupData.active" class="mb-4">
                                <div class="form-group">
                                    <label class="label" for="group">Groupes</label>
                                    <select
                                        id="group"
                                        v-model="groupData.group"
                                        name="redaction"
                                        class="form-control"
                                        autocomplete="off"
                                    >
                                        <option v-for="group in groups" :key="group.id" :value="group.id">
                                            <div v-if="group.parent_id">
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ group.name }}
                                            </div>

                                            <div v-else>
                                                {{ group.name }}
                                            </div>
                                        </option>
                                    </select>
                                </div>
                            </section>

                            <div class="buttons justify-content-end">
                                <button class="button is-light" @click.prevent="closeAndResetModal">
                                    {{ $t('global.cancel') }}
                                </button>
                                <button class="button is-warning" @click.prevent="validate">
                                    {{ $t('global.validate') }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'GroupSiteConfirmModal',
        props: ['checkedRows', 'groups'],
        data() {
            return {
                modalIsOpen: false,
                showDesactivateDate: true,
                currentType: null,
                activateData: {
                    active: false
                },
                desactivateData: {
                    active: false,
                    choice: 1,
                    scheduled_at: null,
                    desactivation_site_to: null,
                    notes: null
                },
                deleteData: {
                    active: false,
                    choice: this.$t('partenaire-site.dontownthesite'),
                    notes: ''
                },
                waitingActivateData: {
                    active: false,
                    choice: '',
                    notes: ''
                },
                redactionData: {
                    active: false,
                    redaction: 'nextlevel'
                },
                typeData: {
                    active: false,
                    type: ''
                },
                groupData: {
                    active: false,
                    group: ''
                }
            }
        },
        watch: {
            'desactivateData.choice': function () {
                if (this.desactivateData.choice == 2) {
                    this.showDesactivateDate = false
                } else {
                    this.showDesactivateDate = true
                }
            }
        },
        methods: {
            validate() {
                let listIds = this.checkedRows.map(site => site.id)

                switch (this.currentType) {
                    case 'activate':
                        this.$parent.$emit('activateBulkSites', {
                            ids: listIds,
                            action: 'activate'
                        })
                        break
                    case 'desactivate':
                        this.$parent.$emit('desactivateBulkSites', {
                            ids: listIds,
                            scheduled_at: this.desactivateData.scheduled_at,
                            desactivation_site_to: this.desactivateData.desactivation_site_to,
                            choice_type: this.desactivateData.choice,
                            notes: this.desactivateData.notes
                        })
                        break
                    case 'delete':
                        this.$parent.$emit('deleteBulkSites', {
                            ids: listIds,
                            notes: this.deleteData.notes.length > 0 ? this.deleteData.notes : null
                        })
                        break
                    case 'waitingActivate':
                        this.$parent.$emit('waitingActivateBulkSites', {
                            ids: listIds,
                            choice_type: this.waitingActivateData.choice,
                            notes: this.waitingActivateData.notes
                        })
                        break
                    case 'redaction':
                        this.$parent.$emit('redactionBulkSites', {
                            ids: listIds,
                            redaction: this.redactionData.redaction
                        })
                        break
                    case 'type':
                        this.$parent.$emit('typeBulkSites', {
                            ids: listIds,
                            type: this.typeData.type
                        })
                        break
                    case 'group':
                        this.$parent.$emit('groupBulkSites', {
                            ids: listIds,
                            group: this.groupData.group
                        })
                        break
                    default:
                        break
                }

                this.$emit('uncheckRows')
                this.closeAndResetModal()
            },

            openModal(type) {
                this.modalIsOpen = true

                switch (type) {
                    case 'activate':
                        this.activateData.active = true
                        this.currentType = 'activate'
                        break
                    case 'desactivate':
                        this.desactivateData.active = true
                        this.currentType = 'desactivate'
                        break
                    case 'delete':
                        this.deleteData.active = true
                        this.currentType = 'delete'
                        break
                    case 'waitingActivate':
                        this.waitingActivateData.active = true
                        this.currentType = 'waitingActivate'
                        break
                    case 'redaction':
                        this.redactionData.active = true
                        this.currentType = 'redaction'
                        break
                    case 'type':
                        this.typeData.active = true
                        this.currentType = 'type'
                        break
                    case 'group':
                        this.groupData.active = true
                        this.currentType = 'group'
                        break
                    default:
                        break
                }
            },

            closeAndResetModal() {
                this.modalIsOpen = false
                this.currentType = null

                this.desactivateData = {
                    active: false,
                    choice: 1,
                    scheduled_at: null,
                    desactivation_site_to: null
                }
                this.deleteData = {
                    active: false,
                    choice: this.$t('partenaire-site.dontownthesite'),
                    notes: ''
                }
                this.waitingActivateData = {
                    active: false,
                    choice: '',
                    notes: ''
                }
                this.redactionData = {
                    active: false,
                    redaction: 'nextlevel'
                }
                this.typeData = {
                    active: false,
                    type: ''
                }
                this.groupData = {
                    active: false,
                    group: ''
                }
            },

            waitingActivateNotes() {
                let arrayChoiceResponse = [
                    "Bonjour, nous apprécions votre demande de partenariat sur NextLevel. Votre site est mis en attente d\\'améliorations car il ne remplit pas encore tous nos critères de qualité. Nous gardons votre site en attente, il sera accepté dès qu'il présentera plus de 20 domaines référents.",
                    "Bonjour, nous apprécions votre demande de partenariat sur NextLevel. Votre site est mis en attente d'améliorations car il ne possède pas assez de mots-clés. N'hésitez pas à créer de nouveaux contenus afin que nous puissions valider votre site. Nous revérifierons cet aspect dans les semaines à venir.",
                    "Bonjour, nous apprécions votre demande de partenariat sur NextLevel. Votre site est mis en attente d'améliorations car il dispose de trop de liens sortants dans les contenus ce qui lui empêche d'avoir des pages en vente sur Nextlevel. Nous revérifierons cet aspect dans les semaines à venir.",
                    'Bonjour, nous apprécions votre demande de partenariat sur NextLevel. Nous mettons votre site en attente pour cause de design, merci de mettre un design plus dynamique et imagé. Nous vous encourageons aussi à intégrer un favicon. Nous revérifierons votre site dans les prochaines semaines.',
                    'Votre site est un OnePage.',
                    "Bonjour, nous apprécions votre demande de partenariat sur NextLevel. Votre site est mis en attente d'améliorations car il ne possède aucunes mentions légales. Nous vous invitons à mettre vos mentions légales à jour de votre côté et à contacter notre support afin que nous puissions valider votre site."
                ]

                this.waitingActivateData.notes = arrayChoiceResponse[this.waitingActivateData.choice]
            }
        }
    }
</script>

<style>
    label[for^='scrape_'] {
        color: #363636;
        display: block;
        font-size: 1rem;
        font-weight: 700;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
