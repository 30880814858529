<template>
    <div class="d-flex align-items-center" style="gap: 1rem">
        <div class="d-flex align-items-center flex-wrap" style="gap: 1rem">
            <div class="flex-shrink-0">
                <span class="font-weight-bold">Site :</span>
                <h3 class="font-weight-bold mb-0 text-nowrap">
                    {{ campaign.information.url }}
                    <nl-clipboard-button :copy="campaign.information.url" />
                </h3>
            </div>
            <div class="flex-shrink-0">
                <span class="font-weight-bold">Budget mensuel :</span>
                <h3
                    v-if="budget"
                    :class="`font-weight-bold mb-0 ${
                        parseInt(budget.budget) - parseInt(budget.depense) < 40 ? 'text-success' : 'text-warning'
                    }`"
                >
                    {{ budget.depense }} / {{ budget.budget }} €
                </h3>
                <h3 v-else class="font-weight-bold mb-0">{{ campaign.information.budget_mensuel }} €</h3>
            </div>
            <div class="flex-shrink-0">
                <span class="font-weight-bold">Creation :</span>
                <h3 class="font-weight-bold mb-0">
                    {{ $d(Date.parse(campaign.information.created_at), 'short') }}
                </h3>
            </div>
            <div class="flex-shrink-0">
                <span class="font-weight-bold">Début :</span>
                <h3 v-if="campaign.information.start_at" class="font-weight-bold mb-0">
                    {{ $d(Date.parse(campaign.information.start_at), 'short') }}
                </h3>
                <h3 v-else class="font-weight-bold mb-0 text-danger">A définir</h3>
            </div>
            <div class="flex-shrink-0">
                <span class="font-weight-bold">Durée :<br /></span>
                <h3 class="mb-0">
                    <span class="font-weight-bold">{{ campaign.information.duration }} mois</span>
                    <small v-if="campaign.information.end_at" class="text-muted"
                        >({{ $d(Date.parse(campaign.information.end_at), 'short') }})</small
                    >
                </h3>
            </div>
            <div v-if="!readonly" class="flex-shrink-0">
                <a
                    href="#"
                    class="btn btn-sm btn-icon btn-outline-primary rounded-circle"
                    title="Edit"
                    @click.prevent="showPropertiesForm = true"
                >
                    <i class="fas fa-pen" />
                </a>
            </div>
        </div>

        <div class="ml-auto d-flex justify-content-end" style="gap: 1rem">
            <start-campaign-modal v-show="!campaign.meta.status.includes(campaign.information.status) && !readonly" />
            <b-button
                v-if="!readonly && campaign && campaign.mails && !campaign.mails.launching"
                :disabled="!campaign.consultants.offSite"
                type="is-warning"
                @click="$emit('openModalEmailLaunch')"
            >
                Mail de lancement
            </b-button>
            <status
                v-if="campaign.meta.status.includes(campaign.information.status) || readonly"
                :aria-current="campaign.information.status"
                :status="campaign.information.status"
                :available="campaign.meta.status"
                :readonly="readonly"
                :loading="changeStatusLoading"
                size="large"
                @change="changeStatus"
            />
        </div>
        <properties-form
            v-if="!readonly"
            :show="showPropertiesForm"
            :campaign="campaign"
            @discard="showPropertiesForm = false"
        />
    </div>
</template>

<script>
    import Status from './Status'
    import PropertiesForm from './PropertiesForm'
    import StartCampaignModal from './StartCampaignModal'
    import moment from 'moment'

    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'Properties',
        components: { StartCampaignModal, PropertiesForm, Status },
        props: {
            campaign: {
                type: Object,
                required: true
            },
            readonly: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                showPropertiesForm: false,
                changeStatusLoading: false
            }
        },
        computed: {
            ...mapState('contents', {
                budget: state =>
                    state.offsite.data.periods.find(
                        value => value.period === moment().startOf('month').format('YYYY-MM-DD')
                    )
            })
        },
        methods: {
            ...mapActions('campaigns', ['updateCampaign']),

            changeStatus(status) {
                this.changeStatusLoading = true
                this.updateCampaign({
                    campaignId: this.campaign.id,
                    statut: status
                }).then(() => {
                    this.changeStatusLoading = false
                })
            }
        }
    }
</script>
