<template>
    <div class="row px-xl-5 mb-xl-5">
        <div class="col-md-12 text-center">
            <h3 class="my-xl-5">
                {{ $t('profil.sponsorship.intro') }}
            </h3>
        </div>

        <div class="col-md-12 text-center mb-4">
            <div class="d-flex justify-content-center">
                <div class="col-md-6 col-lg-3 p-3">
                    <div class="stat h-100">
                        <div>
                            {{ totalFilleulsAmount > 0 ? totalFilleulsAmount : 0 | currency }}
                        </div>
                        <div class="label">
                            {{ $t('profil.sponsorship.gains-totaux') }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 p-3">
                    <div class="stat h-100">
                        <div>{{ profile.filleuls ? profile.filleuls.length : 0 }}</div>
                        <div class="label">
                            {{ $t('profil.sponsorship.sponsee-total') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-2 col-md-12 mt-xl-3">
            <div class="card">
                <div class="card-header row px-4 py-3 text-light" style="">
                    <div class="col-md-8">
                        <h3 class="mb-0">Parrainage</h3>
                    </div>
                </div>

                <div class="card-body px-4 py-0">
                    <div class="row">
                        <div class="col-md-12 col-lg-6">
                            <p>{{ $t('profil.sponsorship.url') }}</p>
                            <div class="url">
                                <input
                                    ref="sponsorshipLink"
                                    disabled="disabled"
                                    class="form-control pr-5"
                                    type="text"
                                    :value="vue_app_url + `/register?ref=${profile.id}`"
                                />
                                <button
                                    v-clipboard:copy="vue_app_url + `/register?ref=${profile.id}`"
                                    class="btn btn-secondary ml-2"
                                >
                                    {{ $t('global.copy') }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <v-form @submit="save">
                        <div class="row">
                            <div class="col-md-12 col-lg-6">
                                <nl-select
                                    v-model="profile.type_parrainage"
                                    :items="typesParrainage"
                                    :label="$t('profil.sponsorship.compensation')"
                                />
                            </div>
                        </div>
                        <div class="row pb-0 mb-4">
                            <div class="col-auto ml-3">
                                <div class="row mb-0">
                                    <button class="btn btn-secondary mr-2" :disabled="loading">
                                        {{ $t('global.save') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <b-loading v-model="loading" :is-full-page="false" />
                    </v-form>

                    <div class="row mt-5 mb-0">
                        <div class="col-md-12 col-lg-6">
                            <h2>{{ $t('profil.sponsorship.sponsee') }}</h2>
                        </div>
                    </div>

                    <div v-if="profile.filleuls && profile.filleuls.length" class="row">
                        <div class="col">
                            <b-table :data="profile.filleuls" :striped="true">
                                <b-table-column field="filleul" :label="$t('profil.sponsorship.sponsee')">
                                    <div slot="default" slot-scope="{ row }">
                                        {{ row.firstname }} {{ row.lastname }}
                                    </div>
                                </b-table-column>
                                <b-table-column field="depot" :label="$t('profil.sponsorship.depot')">
                                    <div slot="default" slot-scope="{ row }">
                                        {{ row.montant > 0 ? 'Oui' : 'Non' }}
                                    </div>
                                </b-table-column>
                                <b-table-column field="montant" :label="$t('profil.sponsorship.gains')">
                                    <div slot="default" slot-scope="{ row }">
                                        {{ row.montant > 0 ? row.montant : '-' | currency }}
                                    </div>
                                </b-table-column>
                            </b-table>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col">
                            <p>{{ $t('profil.sponsorship.unused') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AbstractProfile from './AbstractProfile.vue'

    export default {
        name: 'AccountSponsorship',
        mixins: [AbstractProfile],
        data: function () {
            return {
                vue_app_url: process.env.VUE_APP_URL
            }
        },
        created() {},
        methods: {
            copy: function () {
                var copyText = this.$refs.sponsorshipLink
                copyText.select()
                document.execCommand('copy')
            },
            save: function () {
                this.loading = true

                this.$emit('save', {
                    callback: () => {
                        this.loading = false
                    }
                })
            }
        }
    }
</script>
