<template>
    <a
        class="theme-card"
        role="button"
        @click="
            () => {
                setFilters({ thematic: [group.id] })
                $router.push({ name: 'SearchByKeywords' })
            }
        "
    >
        <span class="theme-card-icon">
            {{ icon }}
        </span>
        <transition name="fade" mode="out-in">
            <div class="theme-card-content">
                <div>
                    <span class="theme-card-header">
                        {{ group.localized_translations.name }}
                    </span>
                </div>
                <span v-if="formattedChildren" class="theme-card-list-item">
                    {{ formattedChildren }}
                </span>
            </div>
        </transition>
    </a>
</template>

<script>
    import { mapMutations } from 'vuex'

    export default {
        name: 'ThemeCard',
        props: {
            group: {
                type: Object,
                required: true
            }
        },
        methods: {
            ...mapMutations('search', {
                setFilters: 'setFilters'
            })
        },
        computed: {
            language() {
                return this.$store.getters.navigatorLanguage
            },
            formattedChildren() {
                return this.group.children
                    .map(e => {
                        return e.localized_translations.name.replace(`${this.group.localized_translations.name} : `, '')
                    })
                    .join(', ')
            },
            icon() {
                switch (this.group.id) {
                    case 53:
                        return '🐶'
                    case 65:
                        return '🎭'
                    case 31:
                        return '👨🏻‍⚖️'
                    case 17:
                        return '💄'
                    case 97:
                        return '🏷'
                    case 108:
                        return '🔮'
                    case 67:
                        return '🏢'
                    case 112:
                        return '🎉'
                    case 19:
                        return '🏦'
                    case 117:
                        return '👨🏻‍🍳'
                    case 98:
                        return '🌱'
                    case 45:
                        return '🖥'
                    case 134:
                        return '🏠'
                    case 72:
                        return '🃏'
                    case 127:
                        return '🎨'
                    case 126:
                        return '🏡'
                    case 51:
                        return '👗'
                    case 111:
                        return '👨‍👩‍👧‍👦'
                    case 129:
                        return '💘'
                    case 57:
                        return '👵🏻'
                    case 11:
                        return '🏥'
                    case 37:
                        return '🧪'
                    case 135:
                        return '🔞'
                    case 38:
                        return '🔞'
                    case 26:
                        return '🏃🏼‍♀️'
                    case 80:
                        return '🚬'
                    case 100:
                        return '🛫'
                    case 124:
                        return '🚧'
                    case 4:
                        return '🏖'
                    default:
                        return '🚀'
                }
            }
        }
    }
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }

    .theme-card {
        cursor: pointer;
        border-radius: 10px;
        color: #000;
        transition: all 0.2s;
        overflow: hidden;
        display: flex;
        flex: 1 1 auto;
    }

    .theme-card:hover {
        transform: translateY(-10px);
        text-decoration: none;
    }

    .theme-card:hover .theme-card-header {
        text-decoration: underline;
    }

    .theme-card-icon,
    .theme-card-content {
        padding: 1rem;
    }

    .theme-card-content {
        flex: 1 1 auto;
        display: flex;
        flex-flow: column;
        background-color: rgba(0, 0, 0, 0.05);
    }

    .theme-card-content:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .theme-card-icon {
        font-size: 3rem;
        background-color: #f9bc14;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 80px;
        flex-shrink: 0;
        flex-grow: 0;
        overflow: hidden;
    }

    .theme-card-header {
        text-align: center;
        font-size: 1.2rem;
        font-weight: 700;
    }

    .theme-card-list-item {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow-wrap: break-word;
        margin-top: auto;
    }
</style>
