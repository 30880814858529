<template>
    <b-modal v-model="activeModal">
        <template>
            <header class="px-4 py-3 has-background-warning shadow-sm">
                <h4 class="h4 p-0 mb-0">Relancer la vérification</h4>
            </header>
            <div class="p-3" v-if="contents.length > 0">
                <div v-for="content in contents" :key="'_content_' + content.id" class="pb-3">
                    <div>
                        <strong>{{ content.url }}</strong>
                    </div>
                    <div>
                        <div>
                            <i
                                class="fas mr-1"
                                :class="
                                    content.result.response_code === 200
                                        ? 'fa-check text-success'
                                        : 'fa-times text-danger'
                                "
                            />
                            Code HTTP : <strong>{{ content.result.response_code }}</strong>
                        </div>
                        <div>
                            <i
                                class="fas mr-1"
                                :class="
                                    !content.result.external_links ? 'fa-check text-success' : 'fa-times text-danger'
                                "
                            />
                            Liens externes :
                            <strong>{{ content.result.external_links }}</strong>
                        </div>
                        <div>
                            <i
                                class="fas mr-1"
                                :class="!content.result.first_link ? 'fa-check text-success' : 'fa-times text-danger'"
                            />
                            Premier lien : <strong>{{ content.result.first_link }}</strong>
                        </div>
                        <div>
                            <i
                                class="fas mr-1"
                                :class="!content.result.second_link ? 'fa-check text-success' : 'fa-times text-danger'"
                            />
                            Second lien : <strong>{{ content.result.second_link }}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="p-3 text-center"><i class="fas fa-cog fa-spin"></i> {{ $t('global.loading') }}...</div>
        </template>
    </b-modal>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        name: 'ErrorMailModal',
        data() {
            return {
                activeModal: false,
                data: null,
                contents: [],
                selectAction: null,
                results: []
            }
        },
        computed: {
            ...mapGetters('auth', { me: 'user' })
        },
        methods: {
            ...mapActions('orders', ['checkContent']),
            openModal(data) {
                this.data = data
                this.activeModal = true
                this.doCheckContent()
                this.initEvents()
            },
            closeModal() {
                this.data = null
                this.activeModal = false
                this.$emit('refresh')
            },
            doCheckContent() {
                this.checkContent(this.data).then(({ contents }) => {
                    this.contents = contents
                })
            },
            initEvents() {
                this.$echo.private(`order.checking.3643`).listen('.OrderChecked', event => {
                    this.results.push(event)
                })
            },
            closeEvents() {},
            getResultByContentId(contentId) {
                const result = this.results.find(r => r.contentId === contentId)
                return result ?? null
            }
        }
    }
</script>

<style></style>
