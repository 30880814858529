<template>
    <SidebarDropdown :title="$t('global.research')" :to="'/search'" :icon="'fas fa-search'">
        <router-link to="/search/keyword" class="sidebar__sublink">
            <span class="nav_name">{{ $t('layout.researchkeyword') }}</span>
        </router-link>
        <router-link to="/search/domain" class="sidebar__sublink">
            <span class="nav_name">{{ $t('layout.researchdomain') }}</span>
        </router-link>
        <router-link to="/search/url" class="sidebar__sublink">
            <span class="nav_name">{{ $t('layout.researchurl') }}</span>
        </router-link>
        <router-link to="/search/theme" class="sidebar__sublink">
            <span class="nav_name">{{ $t('layout.researchtheme') }}</span>
        </router-link>
        <!--    <router-link to="/search/gsc" class="sidebar__sublink">-->
        <!--      <span class="nav_name">Google Search Console</span>-->
        <!--    </router-link>-->
    </SidebarDropdown>
</template>
<script>
    import { mapState } from 'vuex'

    export default {
        name: 'SearchMenu',
        computed: {
            ...mapState('auth', {
                user: state => state.user
            })
        }
    }
</script>
