<template>
    <slide-x-left-transition :duration="600">
        <WritingSlidePanel v-if="step === -1" key="anchor">
            <ChangeAnchorPanel ref="changeAnchor" @anchor:changed="step = 0" />
            <template v-slot:previous>
                <button class="button btn-primary" @click.prevent="step++">
                    {{ $t('global.prev') }}
                </button>
            </template>
            <template v-slot:next>
                <button class="button btn-secondary" @click.prevent="storeNewAnchor">Valider</button>
            </template>
        </WritingSlidePanel>

        <WritingSlidePanel v-if="step === 0" key="default">
            <div v-if="this.isWritingInProgress()" class="buttons">
                <b-button
                    :class="['button', !this.isWritingInProgress() ? 'is-success' : 'is-primary']"
                    :disabled="!canPublish"
                    :loading="storeLoading"
                    @click.prevent="$emit('publish')"
                >
                    Envoyer le contenu
                </b-button>
            </div>
            <RedactorBriefPanel v-if="isOrder" />
            <RedactorPreorderBriefPanel v-if="isPreorder" />
            <RedactorSpotBriefPanel v-if="isSpot" />
            <template v-slot:previous>
                <button v-if="isOrder" class="button btn-secondary" @click.prevent="step--">
                    {{ $t('redactor.anchor-edit') }}
                </button>
            </template>
            <template v-slot:next>
                <button class="button btn-danger" @click.prevent="step++">
                    {{ $t('redactor.refuse') }}
                </button>
            </template>
        </WritingSlidePanel>

        <template v-if="isWritingInProgress()">
            <WritingSlidePanel v-if="step === 1">
                <DeclineWritingPanel />
                <template v-slot:previous>
                    <button class="button btn-primary" @click.prevent="step--">
                        {{ $t('global.prev') }}
                    </button>
                </template>
                <template v-slot:next>
                    <button class="button btn-secondary" @click.prevent="step++">
                        {{ $t('global.next') }}
                    </button>
                </template>
            </WritingSlidePanel>

            <WritingSlidePanel v-if="step === 2">
                <textarea id="refusalTextId" v-model="decline.text" class="form-control" name="refusalText" rows="10" />
                <template v-slot:previous>
                    <button class="button btn-secondary" @click.prevent="step--">
                        {{ $t('global.prev') }}
                    </button>
                </template>
                <template v-slot:next>
                    <button class="button btn-primary" @click.prevent="$emit('decline')">
                        {{ $t('redactor.refuse') }}
                    </button>
                </template>
            </WritingSlidePanel>
        </template>

        <template v-if="isWritingInReview()">
            <WritingSlidePanel v-if="step === 1">
                <div class="form-group">
                    <label for="declineWriting"> Ajouter une note pour le rédacteur </label>
                    <textarea id="declineWriting" v-model="writing.reason" class="form-control" rows="10" />
                </div>
                <template v-slot:previous>
                    <button class="button btn-primary" @click.prevent="step--">
                        {{ $t('global.prev') }}
                    </button>
                </template>
                <template v-slot:next>
                    <button class="button btn-danger" @click.prevent="$emit('review:fail')">
                        {{ $t('redactor.refuse') }}
                    </button>
                </template>
            </WritingSlidePanel>
        </template>
    </slide-x-left-transition>
</template>

<script>
    import WritingSlidePanel from '../SlidePanels/WritingSlidePanel'
    import ChangeAnchorPanel from '../SlidePanels/ChangeAnchorPanel'

    import { mapState } from 'vuex'

    export default {
        name: 'CustomerBrief',
        components: {
            ChangeAnchorPanel,
            WritingSlidePanel
        },

        props: {
            canPublish: {
                type: Boolean,
                required: true
            },
            storeLoading: {
                type: Boolean,
                required: true
            }
        },

        data() {
            return {
                step: 0
            }
        },

        computed: {
            ...mapState('redactors', {
                writing: state => state.writing,
                decline: state => state.decline
            }),
            isOrder() {
                return this.writing.entity_type === 1
            },
            isPreorder() {
                return this.writing.entity_type === 2
            },
            isSpot() {
                return this.writing.entity_type === 6
            }
        },

        methods: {
            storeNewAnchor() {
                this.$refs.changeAnchor.storeNewAnchor()
            },

            isWritingInProgress() {
                return this.writing.writing_status_id === 2
            },

            isWritingInReview() {
                return this.writing.writing_status_id === 3
            },

            isWritingDone() {
                return this.writing.writing_status_id === 6
            }
        }
    }
</script>

<style scoped>
    .overflow-x-hidden {
        overflow-x: hidden;
    }
</style>