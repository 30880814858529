<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <h2>Importation</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <nl-alert ref="alert" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 d-flex flex-column">
                <AutocompleteUser v-model="userId" label="Recherche par utilisateur" />
                <button class="btn btn-secondary mt-2" @click="searchByUser">Recherche</button>
            </div>
            <div class="col-md-4 d-flex flex-column">
                <text-field
                    v-model="quoteId"
                    label="Recherche par numéro de devis"
                    rules="required"
                    name="Recherche par numéro de devis"
                    type="number"
                />
                <button class="btn btn-secondary mt-2" @click="searchByQuote">Recherche</button>
            </div>
            <div class="col-md-4 d-flex flex-column">
                <text-field
                    v-model="invoiceId"
                    label="Recherche par numéro de facture"
                    rules="required"
                    name="Recherche par numéro de facture"
                    type="number"
                />
                <button class="btn btn-secondary mt-2" @click="searchByInvoice">Recherche</button>
            </div>
        </div>
        <div v-if="quotes.length || invoices.length" class="row">
            <div class="col-md-12">
                <b-table :data="list" :mobile-cards="true" :striped="true" class="table-striped" :total="list.length">
                    <b-table-column field="id" sortable label="ID">
                        <div slot="default" slot-scope="props">
                            {{ props.row.id }}
                        </div>
                    </b-table-column>

                    <b-table-column field="quotationIdFacturation" label="Type">
                        <div slot="default" slot-scope="props">
                            {{ props.row.invoice_ref ? 'Facture' : 'Devis' }}
                        </div>
                    </b-table-column>

                    <b-table-column field="title" sortable label="Libellé">
                        <div slot="default" slot-scope="props">
                            {{ props.row.title }}
                        </div>
                    </b-table-column>

                    <b-table-column field="total" sortable label="Montant HT">
                        <div slot="default" slot-scope="props">
                            {{ props.row.total }}
                        </div>
                    </b-table-column>

                    <b-table-column field="total_with_vat" sortable label="Montant TTC">
                        <div slot="default" slot-scope="props">
                            {{ props.row.total_with_vat }}
                        </div>
                    </b-table-column>

                    <b-table-column field="currency" sortable label="Devise">
                        <div slot="default" slot-scope="props">
                            {{ props.row.currency }}
                        </div>
                    </b-table-column>

                    <b-table-column field="payment_status" label="Status">
                        <div slot="default" slot-scope="props">
                            {{ getStatus(props.row) }}
                        </div>
                    </b-table-column>

                    <b-table-column label="Actions">
                        <div slot="default" slot-scope="props">
                            <div class="dropdown">
                                <button
                                    v-if="!props.row.order"
                                    class="btn btn-primary btn-sm"
                                    type="button"
                                    @click="importRow(props.row)"
                                >
                                    Importer
                                </button>
                                <span v-else class="tag is-success">Déjà importé</span>
                            </div>
                        </div>
                    </b-table-column>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import AutocompleteUser from '../../UI/Autocomplete/User.vue'
    import NlAlert from '../../UI/NlAlert.vue'

    export default {
        components: {
            AutocompleteUser,
            NlAlert
        },
        data: () => ({
            userId: null,
            invoiceId: null,
            quoteId: null,
            quotes: [],
            invoices: [],
            error: null
        }),
        computed: {
            list() {
                let invoices = this.invoices
                let quotes = this.quotes
                return [...quotes, ...invoices]
            }
        },
        methods: {
            ...mapActions('billing', {
                searchByUserAction: 'searchByUser',
                searchByQuoteAction: 'searchByQuote',
                searchByInvoiceAction: 'searchByInvoice',
                importAction: 'import'
            }),
            getStatus(row) {
                if (row.invoice_ref) {
                    if (parseInt(row.balance) > 0) {
                        return 'A payer'
                    } else {
                        return 'Payée'
                    }
                } else {
                    if (row.invoice_ids.length) {
                        return 'Soldé'
                    } else {
                        return 'En attente'
                    }
                }
            },
            searchByUser() {
                this.$refs.alert.clean()
                this.error = null
                this.quotes = []
                this.invoices = []
                this.searchByUserAction(this.userId)
                    .then(({ data }) => {
                        this.quotes = data.quotes
                        this.invoices = data.invoices
                    })
                    .catch(({ data }) => {
                        this.$refs.alert.displayMsg('danger', [data.error])
                    })
            },
            searchByQuote() {
                this.$refs.alert.clean()
                this.error = null
                this.quotes = []
                this.invoices = []
                this.searchByQuoteAction(this.quoteId)
                    .then(({ data }) => {
                        this.quotes = data.quotes
                    })
                    .catch(({ data }) => {
                        this.$refs.alert.displayMsg('danger', [data.error])
                    })
            },
            searchByInvoice() {
                this.$refs.alert.clean()
                this.error = null
                this.quotes = []
                this.invoices = []
                this.searchByInvoiceAction(this.invoiceId)
                    .then(({ data }) => {
                        this.invoices = data.invoices
                    })
                    .catch(({ data }) => {
                        this.$refs.alert.displayMsg('danger', [data.error])
                    })
            },
            importRow(row) {
                this.importAction(row).then(({ data }) => {
                    let order = data.order
                    if (order.id) {
                        if (order.typeFacturationPro == 'quotation') {
                            let idx = this.quotes.findIndex(l => l.id == row.id)
                            let quotes = this.quotes
                            let q = [...quotes]
                            q[idx].order = order
                            this.quotes = [...q]
                        } else {
                            let idx = this.invoices.findIndex(l => l.id == row.id)
                            let invoices = this.invoices
                            let i = [...invoices]
                            i[idx].order = order
                            this.invoices = [...i]
                        }
                    }
                })
            }
        }
    }
</script>

<style></style>
