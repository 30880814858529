export default {
    setRedactors(state, data) {
        state.redactors = data
    },
    setRedactor(state, data) {
        state.redactor = data
    },
    setRedactorWritings(state, data) {
        state.redactorWritring = data
    },
    setWordsRemainingToWrite(state, data) {
        state.wordsRemainingToWrite = data
    },
    setMaxWordsPerDay(state, data) {
        state.maxWordsPerDay = data
    },
    setWritings(state, data) {
        state.writings = data
    },
    setWriting(state, data) {
        state.writing = data
    },
    setDateFrom(state, data) {
        state.date_from = data
    },
    setDateTo(state, data) {
        state.date_to = data
    },
    setWritingContent(state, data) {
        state.writing.content = data
    },
    setLogs(state, data) {
        state.logs = data
    },
    setPreorders(state, data) {
        state.preorders = data
    },
    setAuthors(state, data) {
        state.authors = data
    },
    setCategories(state, data) {
        state.categories = data
    },
    setCurrentTab(state, data) {
        state.currentTab = data
    },
    saveWritingsConfig(state) {
        state.savedWritingsConfig = Object.assign({}, state.writingsConfig)
    },
    resetWritingsConfig(state) {
        state.writingsConfig = Object.assign({}, state.savedWritingsConfig)
    }
}
