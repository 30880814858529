import SiteSelector from '@/components/Form/SiteSelector.vue'
import { ROLES } from '@/config/roles'

const metaGroup = {
    roles: [ROLES.PARTNER, ROLES.AGENCY, ROLES.MANAGER, ROLES.CONSULTANT]
}

export default [
    {
        path: '/partner',
        name: 'PartnerDashboard',
        components: {
            default: () => import('../views/Dashboard/PartnerDashboard.vue'),
            siteSelector: SiteSelector
        },
        meta: metaGroup
    },
    {
        path: '/partner/sites',
        name: 'PartnerSites',
        component: () => import('../views/PartnerSites/PartnerSites.vue'),
        meta: metaGroup
    },
    {
        path: '/partner/sites-sellings',
        alias: '/vente-de-sites.php',
        name: 'VenteDeSites',
        component: () => import('../views/Site/WebsiteSelling.vue'),
        meta: {
            isSeo: true,
            roles: [ROLES.PARTNER, ROLES.PROSPECT]
        }
    },
    {
        path: '/partner/orders',
        alias: '/partenaire-commandes.php',
        name: 'PartnerOrders',
        component: () => import('../views/PartnerSites/PartnerOrders.vue'),
        meta: metaGroup
    },
    {
        path: '/partner/orders/details',
        alias: '/partenaire-commandes-detail.php',
        name: 'PartnerOrdersDetails',
        component: () => import('../views/PartnerSites/PartnerOrdersDetails.vue'),
        meta: metaGroup
    },
    {
        path: '/partner/earnings',
        name: 'PartenaireGains',
        components: {
            default: () => import('../views/PartnerSites/PartnerGains.vue')
        },
        meta: metaGroup
    },
    {
        path: '/partner/needs',
        name: 'PartnerPreOrders',
        component: () => import('../views/PartnerPreOrders/PartnerPreOrders.vue'),
        meta: metaGroup
    },
    {
        path: '/partner/alerts',
        name: 'Alertes Partenaires',
        components: {
            default: () => import('../views/AlertPartner/List.vue')
        },
        meta: metaGroup
    },
    {
        path: '/partner/site/:id/urls',
        name: 'PartnerKeywords',
        alias: ['/partner/sites/keywords', '/partenaire-mots-cles.php'],
        component: () => import('../views/PartnerSites/PartnerUrls.vue'),
        meta: metaGroup
    },
    {
        path: '/partner/sites/orders',
        name: 'SitesOrders',
        alias: '/sites-commandes.php',
        component: () => import('../views/PartnerSites/SitesOrders.vue'),
        meta: metaGroup
    },
    {
        path: '/partner/sites/preorders',
        alias: '/sites-preorders.php',
        name: 'SitesPreorders',
        component: () => import('../views/PartnerSites/PartnerSitesPreorders.vue'),
        meta: metaGroup
    },
    {
        path: '/partner/withdraw',
        name: 'PartnerWithdrawal',
        component: () => import('../views/PartnerSites/PartnerWithdrawal.vue'),
        meta: metaGroup
    },
    {
        path: '/partner/withdraw/complete',
        name: 'PartnerWithdrawalComplete',
        component: () => import('../views/PartnerSites/PartnerWithdrawalComplete.vue'),
        meta: metaGroup
    }




]
