<template>
    <div>
        <b-button outlined icon-left="fas fa-redo-alt" type="is-info" class="ml-1" @click="show = true">
            Prolonger
            <i class="fas fa-repeat" />
        </b-button>
        <form class="" @submit.prevent="update">
            <nl-modal title="Prolonger l'accompagnement" :open-modal="show" @hide="show = false">
                <b-loading v-model="isLoading" :is-full-page="false" />
                <div class="form-group">
                    <label for="frm_extend_duration">Durée</label>
                    <select id="frm_extend_duration" v-model="duration" class="form-control">
                        <option v-for="n in 24" :key="n" :value="n">{{ n }} mois</option>
                    </select>
                </div>

                <template v-slot:footer>
                    <b-button type="is-dark" :disabled="isLoading" @click="show = false"> Annuler</b-button>
                    <b-button type="is-warning" native-type="submit" :disabled="isLoading"> Prolonger</b-button>
                </template>
            </nl-modal>
        </form>
    </div>
</template>

<script>
    import NlModal from '../UI/NlModal'
    import { mapActions, mapState } from 'vuex'

    export default {
        components: { NlModal },
        data() {
            return {
                duration: 1,
                isLoading: false,
                show: false
            }
        },
        computed: {
            ...mapState('campaigns', {
                campaignId: state => state.campaign.id,
                isStarted: state => state.campaign.meta.is_started,
                campaignDuration: state => state.campaign.information.duration
            })
        },
        methods: {
            ...mapActions('campaigns', ['extendCampaign']),
            update() {
                this.isLoading = true
                this.extendCampaign({
                    id: this.campaignId,
                    duration: this.duration
                })
                    .then(() => {
                        this.$store.dispatch('toast', {
                            message: this.$t('campaign-manager.notification.budget.extendValidation', {
                                duration: this.duration
                            }),
                            type: 'success',
                            closeButton: true
                        })
                    })
                    .catch(() => {
                        this.$store.dispatch('toast', {
                            message: this.$t('campaign-manager.notification.budget.extendError'),
                            type: 'error',
                            closeButton: true
                        })
                    })
                    .finally(() => {
                        this.isLoading = false
                        this.show = false
                    })
            }
        }
    }
</script>
