<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="1280" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">Relecture</h4>
                </header>
                <div class="card-content">
                    <div class="content">
                        <section v-if="order">
                            <b-notification
                                v-if="order.content_review.checked_at"
                                type="is-danger is-light"
                                :closable="true"
                                role="alert"
                            >
                                <p :class="order.content_redactor ? 'mb-1' : 'mb-0'">
                                    <i class="fa fa-exclamation-triangle mr-1" />
                                    La commande a déjà été corrigée, celle-ci est en attente du rédacteur. Les nouvelles
                                    modifications écraserons les précédentes.
                                </p>
                                <p v-if="order.content_redactor" class="mb-0 font-weight-bold">
                                    Le rédacteur est en train de réctifier la commande (il est suceptible de ne pas voir
                                    vos nouvelles remarques).
                                </p>
                            </b-notification>

                            <b-notification
                                v-if="order.content_review.reason"
                                type="is-danger is-light"
                                :closable="true"
                                role="alert"
                            >
                                <p class="mb-0">{{ order.content_review.reason }}.</p>
                            </b-notification>

                            <b-notification
                                v-if="order.duplicate_information"
                                :type="
                                    order.duplicate_information.duplicate_percentage < 26
                                        ? 'is-success is-light'
                                        : 'is-danger is-light'
                                "
                                :closable="false"
                                role="alert"
                            >
                                <p :class="[order.duplicate_information.duplicate_links ? '' : 'mb-0']">
                                    La vérification donne
                                    <strong>{{ order.duplicate_information.duplicate_percentage }}%</strong>
                                    de contenu dupliqué.
                                </p>

                                <ul v-if="order.duplicate_information.duplicate_links">
                                    <li
                                        v-for="(count, url) in order.duplicate_information.duplicate_links"
                                        :key="count"
                                    >
                                        {{ `${url} (x${count})` }}
                                    </li>
                                </ul>
                            </b-notification>

                            <section class="row mb-3">
                                <div class="col-12 bg-light p-4 mb-3">
                                    <h3>Rédacteur</h3>
                                    <p>
                                        {{
                                            `${order.content_review.redactor.user.firstname}
                                        ${order.content_review.redactor.user.lastname}
                                        (${order.content_review.redactor.user.email})`
                                        }}
                                    </p>
                                </div>

                                <div class="col-12 bg-light p-4 mb-3">
                                    <h3>Informations de commande</h3>
                                    <ul>
                                        <li class="mb-2">
                                            {{ $t('redactor.page') }} :
                                            <span class="text-muted">{{ order.url }}</span>
                                        </li>

                                        <li class="mb-2">
                                            {{ $t('redactor.link') }} :
                                            <span>{{ order.link_url }}</span>
                                        </li>

                                        <li class="mb-2">
                                            {{ $t('global.anchor') }} :
                                            <span>{{ order.link_anchor }}</span>
                                        </li>

                                        <li v-if="order.link_second_url" class="mb-2">
                                            {{ $t('redactor.link') }} 2:
                                            <span>{{ order.link_second_url }}</span>
                                        </li>

                                        <li v-if="order.link_second_url" class="mb-2">
                                            {{ $t('global.anchor') }} 2:
                                            <span>{{ order.link_second_anchor }}</span>
                                        </li>

                                        <li class="mb-2">
                                            {{ $t('redactor.nbWords') }} :
                                            <span class="text-muted">{{ 200 + order.option_mots }}</span>
                                        </li>

                                        <li class="mb-2">
                                            {{ $t('global.brief') }} :
                                            <span class="text-muted">{{ order.brief }}</span>
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-12 bg-light p-4 mb-3">
                                    <h3>Contenu rédigé</h3>
                                    <div class="position-relative">
                                        <button
                                            class="button is-small is-light is-info btn-absolute"
                                            @click.prevent="showFormatted = !showFormatted"
                                        >
                                            <span v-if="showFormatted">
                                                <i class="fas fa-edit mr-1" />
                                                Éditer le contenu
                                            </span>
                                            <span v-else>Voir le contenu formaté</span>
                                        </button>
                                        <div v-if="showFormatted" v-html="order.content_review.content" />
                                        <div v-else class="pt-4">
                                            <textarea
                                                v-model="order.content_review.content"
                                                class="form-control"
                                                rows="15"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 bg-light p-4 mb-3">
                                    <h3>Ajouter une note</h3>
                                    <div class="form-group">
                                        <label class="label"
                                            >Dans le cas où le contenu est refusé, vous pouvez ajouter une note pour le
                                            rédacteur</label
                                        >
                                        <textarea v-model="order.content_review.notes" class="form-control" rows="5" />
                                    </div>
                                </div>
                            </section>

                            <b-notification type="is-warning is-light" :closable="true" role="alert">
                                <p class="mb-0">
                                    <i class="fa fa-info-circle mr-1" />
                                    Vos éventuelles corrections seront prises en compte dans les deux cas.
                                </p>
                            </b-notification>

                            <div class="buttons justify-content-end">
                                <button class="button is-light" @click.prevent="closeAndResetModal">
                                    <i class="fas fa-times mr-2" />
                                    {{ $t('global.cancel') }}
                                </button>

                                <button class="button is-danger" @click.prevent="refuse">
                                    <i class="fas fa-ban mr-2" />
                                    Renvoyer la commande au rédacteur
                                </button>

                                <button
                                    v-if="!order.content_redactor"
                                    class="button is-success"
                                    @click.prevent="validate"
                                >
                                    <i class="fas fa-check mr-2" />
                                    Valider et poster la commande
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'RedactorReviewModal',
        props: [],
        data() {
            return {
                modalIsOpen: false,
                order: null,
                showFormatted: true,

                typeUnassigned: {
                    active: false
                },
                typePending: {
                    active: false,
                    reason: null
                }
            }
        },

        methods: {
            validate() {
                this.$parent.$emit('reviewSuccess', {
                    order_id: this.order.id,
                    content: this.order.content_review.content
                })

                this.closeAndResetModal()
            },

            refuse() {
                this.$parent.$emit('reviewInvalid', {
                    order_id: this.order.id,
                    notes: this.order.content_review.notes,
                    content: this.order.content_review.content
                })
                this.closeAndResetModal()
            },

            openModal(order) {
                this.modalIsOpen = true
                this.order = order

                //
            },

            closeAndResetModal() {
                this.modalIsOpen = false
                this.order = null
                this.showFormatted = true
            }
        }
    }
</script>

<style scoped>
    .btn-absolute {
        position: absolute;
        top: -30px;
        right: 0;
    }
</style>
