<template>
    <div
        class="d-flex"
        :class="{
            'justify-content-center': centered
        }"
    >
        <template v-if="prefix && value && computedField">
            {{ prefix }}
        </template>
        {{ computedField || '-' }}
        <template v-if="suffix && value && computedField">
            {{ suffix }}
        </template>
    </div>
</template>

<script>
    export default {
        props: {
            centered: {
                type: Boolean,
                default: false
            },
            suffix: {
                type: String,
                required: false,
                default: null
            },
            prefix: {
                type: String,
                required: false,
                default: null
            },
            type: {
                required: false,
                type: String,
                default: 'text'
            },
            value: {
                type: [String, Number],
                required: false,
                default: null
            },
            fieldOption: {
                type: Object,
                required: false,
                default: null
            },
            defaultValue: {
                type: [String, Number],
                required: false,
                default: null
            },
            filter: {
                type: [RegExp, String],
                required: false,
                default: ''
            }
        },
        computed: {
            computedField() {
                const value =
                    this.value && this.filter ? this.value.replace(this.filter, '') : this.value || this.defaultValue
                switch (this.type) {
                    case 'text':
                        return value
                    case 'date':
                        return value ? this.$d(Date.parse(value), this.fieldOption.dateFormat) : ''
                    default:
                        return value
                }
            }
        }
    }
</script>
