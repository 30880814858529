import axios from '@/config/backend'
import ApiService from '@/services/api.service'

export default {
    login({ commit, dispatch }, { email, password }) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.VUE_APP_LARAVEL_URL}/oauth/token`, {
                    grant_type: 'password',
                    client_id: process.env.VUE_APP_CLIENT_ID,
                    client_secret: process.env.VUE_APP_CLIENT_SECRET,
                    username: email,
                    password: password,
                    scope: '*'
                })
                .then(response => {
                    if (!response.data.access_token || response.data.access_token.length <= 0) {
                        reject('Token not found')
                    }

                    commit('setTokenData', response.data)

                    dispatch('auth/me', null, { root: true })
                        .then(() => {
                            dispatch('initialiseStore', null, { root: true })
                            dispatch('setMenuPreference', null, { root: true })

                            resolve()
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    resetPassword({ rootState }, email) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/password/reset`, {
                    email: email,
                    locale: rootState.locale
                })
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    loginAs(context, data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/admin/login`, data)
                .then(response => {
                    context.commit('addToken', data)

                    context.commit('setUser', response.data.user)
                    context.commit('setToken', response.data.token)

                    context.commit('setConnectedAs', true)
                    context.commit('setFromAdmin', response.data.fromAdmin)

                    void context.dispatch('resetMenu', null, { root: true })
                    void context.dispatch('initialiseStore', null, { root: true })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    void context.dispatch('project_selector/projects', null, { root: true })
                    void context.dispatch('project_selector/getDefaultProject', null, {
                        root: true
                    })
                })
        })
    },

    logoutAs(context) {
        return new Promise(resolve => {
            context.commit('subToken')
            context.commit('setFromAdmin', false)
            void context.dispatch('resetMenu', null, { root: true })
            void context.dispatch('initialiseStore', null, { root: true })
            resolve()
            void context.dispatch('project_selector/projects', null, { root: true })
            void context.dispatch('project_selector/getDefaultProject', null, {
                root: true
            })
        })
    },

    updatePasswordReset(context, data) {
        return new Promise((resolve, reject) => {
            console.log(data.redirectUrl)
            axios
                .post(data.redirectUrl, {
                    password: data.password,
                    password_confirmation: data.password_confirmation
                })
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    me({ commit, state }, force = false) {
        if (state.user && !force) {
            return state.user
        }

        return new Promise((resolve, reject) => {
            ApiService.get('/user')
                .then(({ data }) => {
                    commit('setUser', data)
                    commit('setFromAdmin', data.fromAdmin)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    update(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.put('/user', model)
                .then(({ data }) => {
                    context.commit('setUser', data.user)
                    context.commit('setLocale', data.user.locale, { root: true })
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    updatePassword(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.put('/user/password', model)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    delete() {
        return new Promise((resolve, reject) => {
            ApiService.delete('/user')
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    saveAttestation(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put('/user/save-attestation', params)
                .then(({ data }) => {
                    context.commit('setAttestation', data.user)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    getInfos(context) {
        return new Promise((resolve, reject) => {
            ApiService.get('/user/infos')
                .then(({ data }) => {
                    context.commit('setUserInfo', data)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    becomePartner(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/user/become-partner', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getCredits(state, params) {
        return new Promise((resolve, reject) => {
            ApiService.get('/credit/index', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getIncomes(state, params) {
        return new Promise((resolve, reject) => {
            ApiService.get('/partner-gain/index', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
