<template>
    <div>
        <div class="row">
            <div v-if="user" class="col-4">
                <StatCard :color="user.credit_amount >= 0 ? 'success' : 'danger'" :icon="'fas fa-euro-sign'">
                    <template v-slot:counter>
                        <h3>{{ (user.credit_amount ? parseInt(user.credit_amount * 100) / 100 : 0) | toCurrency }}</h3>
                    </template>
                    <template v-slot:text>
                        <span>Crédits</span>
                    </template>
                </StatCard>
                <b-field label="Crédit">
                    <b-input v-model="amount" type="number" step="0.01" required />
                </b-field>
                <b-field label="Motif">
                    <b-input v-model="log" type="text" required />
                </b-field>
                <b-button type="is-primary" @click="save"> Modifier les crédits</b-button>
            </div>

            <b-table
                class="col-8 pt-0"
                :data="creditLog"
                :loading="loading"
                paginated
                :striped="true"
                :per-page="10"
                default-sort="created_at"
                default-sort-direction="desc"
            >
                <b-table-column field="created_at" sortable searchable label="Date" cell-class="align-middle">
                    <div slot="default" slot-scope="props">
                        <span>{{ props.row.created_at }}</span>
                    </div>
                    <div slot="searchable" slot-scope="props">
                        <div class="w-100">
                            <input
                                v-model="props.filters[props.column.field]"
                                type="date"
                                class="input is-small"
                                placeholder="#..."
                            />
                        </div>
                    </div>
                </b-table-column>
                <b-table-column field="log" sortable searchable :label="$t('credits.event')" cell-class="align-middle">
                    <div slot="default" slot-scope="props">
                        <span v-if="!props.row.log.includes('<strong>')">{{ props.row.log }}</span>
                        <span v-else v-html="props.row.log" />
                    </div>
                    <div slot="searchable" slot-scope="props">
                        <div class="w-100">
                            <input
                                v-model="props.filters[props.column.field]"
                                type="text"
                                class="input is-small"
                                :placeholder="$t('credits.event')"
                            />
                        </div>
                    </div>
                </b-table-column>
                <b-table-column field="montant" sortable searchable label="Montant" cell-class="align-middle">
                    <div slot="default" slot-scope="props">
                        <span>{{ props.row.montant | toCurrency }}</span>
                    </div>
                    <div slot="searchable" slot-scope="props">
                        <div class="w-100">
                            <input
                                v-model="props.filters[props.column.field]"
                                type="number"
                                class="input is-small"
                                :placeholder="$t('credits.amount')"
                            />
                        </div>
                    </div>
                </b-table-column>
                <b-table-column
                    field="balance_before"
                    sortable
                    searchable
                    :label="$t('credits.amount_before')"
                    cell-class="align-middle"
                >
                    <div slot="default" slot-scope="props">
                        <span>{{ props.row.balance_before | toCurrency }}</span>
                    </div>
                    <div slot="searchable" slot-scope="props">
                        <div class="w-100">
                            <input
                                v-model="props.filters[props.column.field]"
                                type="number"
                                class="input is-small"
                                :placeholder="$t('credits.amount_before')"
                            />
                        </div>
                    </div>
                </b-table-column>
                <b-table-column
                    field="balance_after"
                    sortable
                    searchable
                    :label="$t('credits.amount_after')"
                    cell-class="align-middle"
                >
                    <div slot="default" slot-scope="props">
                        <span>{{ props.row.balance_after | toCurrency }}</span>
                    </div>
                    <div slot="searchable" slot-scope="props">
                        <div class="w-100">
                            <input
                                v-model="props.filters[props.column.field]"
                                type="number"
                                class="input is-small"
                                :placeholder="$t('credits.amount_after')"
                            />
                        </div>
                    </div>
                </b-table-column>
            </b-table>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UserCreditTab',
        props: {
            tabData: {}
        },
        data() {
            return {
                loading: false,
                creditLog: [],
                user: {},
                amount: '',
                log: ''
            }
        },
        watch: {
            tabData: {
                handler(newValue) {
                    this.creditLog = newValue.content
                    this.user = newValue.user
                    this.loading = false
                },
                deep: true
            }
        },
        methods: {
            close() {
                this.amount = null
                this.log = null
            },
            save() {
                this.loading = true
                this.axios
                    .put(`/admin/users/${this.user.id}/credit`, {
                        id: this.user.id,
                        amount: this.amount,
                        log: this.log
                    })
                    .then(response => {
                        this.user.credit_amount = parseFloat(this.user.credit_amount, 10) + parseFloat(this.amount, 10)
                        this.$emit('reloadData', this.tabData.index)
                        this.close()
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => (this.loading = false))
            }
        }
    }
</script>
