<template>
    <nl-modal ref="modal" title="Créer un nouveau collaborateur" :add-legacy-events="false" position="right" size="lg">
        <template v-slot:header>
            <div class="bg-antracite text-white w-100 text-center p-4">
                <h1>
                    {{ $store.state.consultants.action === 'create' ? 'Créer un nouveau' : 'Modifier le' }}
                    collaborateur
                </h1>
            </div>
        </template>

        <div>
            <b-tabs size="is-medium" class="block">
                <b-tab-item label="Informations" icon="pen" value="info">
                    <div v-if="action === 'update'" class="py-5 px-2 mb-2 bg-light">
                        <div v-if="consultant.type === 'consultant'">
                            <div class="d-flex align-items-center">
                                <h4 class="mr-4">Promouvoir en manager :</h4>
                                <PromoteButton :consultant="consultant" @click="doPromote" />
                            </div>
                        </div>

                        <div v-if="consultant.type === 'manager'">
                            <div class="d-flex align-items-center">
                                <h4 class="mr-4">Rétrograder en consultant :</h4>
                                <DemoteButton :manager="consultant" @click="doDemote" />
                            </div>
                        </div>
                    </div>

                    <CreateConsultantForm />
                    <div class="text-center">
                        <button
                            class="btn-action btn-third mt-2 mb-2 mb-xl-4"
                            :disabled="loading"
                            @click.prevent="save"
                        >
                            <i v-show="loading" class="fas fa-circle-notch fa-spin mr-1" />
                            {{ $store.state.consultants.action === 'create' ? 'Créer' : 'Modifier' }}
                            le
                            <span v-show="consultant.type === 'manager'">manager</span>
                            <span v-show="consultant.type === 'consultant'">consultant</span>
                        </button>
                    </div>
                </b-tab-item>
                <b-tab-item
                    :label="`Consultants (${consultants.length})`"
                    icon="user"
                    value="consultants"
                    :visible="action === 'update'"
                >
                    <b-table
                        :data="consultants"
                        :mobile-cards="true"
                        :narrow="true"
                        detailed
                        detail-key="id"
                        :show-detail-icon="false"
                        :opened-detailed="defaultOpenedDetails"
                    >
                        <b-table-column label="Consultant" cell-class="align-middle text-left" width="110px">
                            <template v-slot="props">
                                <b-tooltip label="Affecter à un autre manager" position="is-right">
                                    <button class="btn btn-secondary" @click.prevent="edit(props.row.id)">
                                        <i class="fa fa-random" />
                                    </button>
                                </b-tooltip>
                                <b-tooltip label="Retirer" position="is-right">
                                    <button class="btn btn-secondary" @click.prevent="remove(props.index, props.row)">
                                        <i class="fa fa-times" style="min-width: 15px" />
                                    </button>
                                </b-tooltip>
                            </template>
                        </b-table-column>
                        <b-table-column cell-class="align-middle text-left">
                            <template v-slot="props">
                                <router-link :to="`/consultant-details.php?id=${props.row.id}`">
                                    {{ props.row.firstname }} {{ props.row.lastname }}
                                </router-link>
                                <br />
                                <div class="font-italic">
                                    <small><i class="fa fa-at" /> {{ props.row.email }}</small>
                                </div>
                            </template>
                        </b-table-column>
                        <b-table-column field="credit_amount" label="Crédit restant" numeric cell-class="align-middle">
                            <template v-slot="props">
                                {{ props.row.credit_amount | toCurrency }}
                            </template>
                        </b-table-column>

                        <template #detail="props">
                            <div class="d-flex">
                                <div class="p-0">
                                    <b-field label="Attacher le consultant au manager...">
                                        <SearchManager
                                            store
                                            @select="option => (props.row.manager_id = option ? option.id : null)"
                                        />
                                    </b-field>
                                </div>
                                <div class="p-0 flex-grow-1" />
                                <div class="p-0 flex-shrink-1 d-flex flex-column justify-content-end">
                                    <button
                                        class="btn btn-primary d-block my-1"
                                        :disabled="updating"
                                        @click.prevent="defaultOpenedDetails = []"
                                    >
                                        Annuler
                                    </button>
                                    <button
                                        class="btn btn-secondary d-block my-1"
                                        :disabled="updating"
                                        @click.prevent="reaffectConsultant(props.index, props.row)"
                                    >
                                        Sauvegarder
                                    </button>
                                </div>
                            </div>
                        </template>
                    </b-table>
                </b-tab-item>
            </b-tabs>
        </div>

        <template v-slot:footer>
            <div class="d-flex w-100 justify-content-start">
                <b-button type="button" class="btn btn-danger" @click="$refs.modal.hide()"> Annuler</b-button>
            </div>
        </template>
    </nl-modal>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import CreateConsultantForm from '../Form/CreateConsultantForm'
    import AgencyModal from './AgencyModal'

    export default {
        name: 'CreateConsultantModal',
        components: {
            CreateConsultantForm
        },
        mixins: [AgencyModal],
        data: () => ({
            loading: false,
            defaultOpenedDetails: [1],
            updating: false,
            consultants: []
        }),
        computed: {
            ...mapState('consultants', {
                consultant: state => state.newEmployee,
                action: state => state.action
            }),
            isCreation() {
                return this.action === 'create'
            }
        },
        methods: {
            ...mapActions('consultants', [
                'createManager',
                'createConsultant',
                'updateManager',
                'updateConsultant',
                'getManager',
                'affectConsultant',
                'demote',
                'promote'
            ]),
            show() {
                if (this.consultant.type === 'manager') {
                    this.consultants = this.consultant.consultants
                }

                this.$emit('show')
                this.$refs.modal.show()
            },
            save() {
                this.loading = true
                let endpoint

                if (this.$store.state.consultants.action === 'create') {
                    endpoint = this.consultant.type === 'manager' ? this.createManager() : this.createConsultant()
                } else {
                    endpoint = this.consultant.type === 'manager' ? this.updateManager() : this.updateConsultant()
                }

                endpoint
                    .then(() => {
                        this.reset()
                        this.$emit('reload')
                        this.hide()
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            reset() {
                this.$store.commit('consultants/setNewEmployee', {
                    type: 'consultant',
                    email: null,
                    lastname: null,
                    firstname: null,
                    password: null,
                    budget_initial: null,
                    manager_id: null
                })
            },
            edit(id) {
                this.defaultOpenedDetails = [id]
            },
            reaffectConsultant(index, consultant) {
                this.updating = true
                const payload = {
                    manager_id: consultant.manager_id,
                    id: consultant.id
                }
                this.affectConsultant(payload)
                    .then(data => {
                        this.defaultOpenedDetails = []
                        this.manager.consultants.splice(index, 1)

                        let managerIndex = this.managers.data.findIndex(manager => manager.id === consultant.manager_id)
                        if (managerIndex >= 0) {
                            let manager = this.managers.data[managerIndex]
                            manager.consultants.push(consultant)

                            this.managers.data.splice(managerIndex, 1, manager)
                        }

                        managerIndex = this.managers.data.findIndex(manager => manager.id === this.manager.id)
                        if (managerIndex >= 0) {
                            this.managers.data.splice(managerIndex, 1, this.manager)
                        }
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.updating = false
                    })
            },
            remove(index, consultant) {
                this.updating = true
                const payload = {
                    manager_id: null,
                    id: consultant.id
                }

                this.affectConsultant(payload)
                    .then(data => {
                        consultant.manager_id = null
                        this.manager.consultants.splice(index, 1)

                        this.consultants.data.push(consultant)

                        let managerIndex = this.managers.data.findIndex(manager => manager.id === this.manager.id)
                        if (managerIndex >= 0) {
                            this.managers.data.splice(managerIndex, 1, this.manager)
                        }
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            doDemote() {
                this.loading = true

                this.demote(this.consultant)
                    .then(data => {
                        let consultants = this.$store.state.consultants.consultants

                        if (!consultants || !consultants.data) {
                            this.$emit('reload')
                            return
                        }

                        consultants.data.push(data.consultant)

                        this.$store.commit('consultants/setConsultants', consultants)

                        let managers = this.$store.state.consultants.managers
                        let index = managers.data.findIndex(manager => manager.id === this.consultant.id)

                        if (index >= 0) {
                            managers.data.splice(index, 1)
                            this.$store.commit('consultants/setManagers', managers)
                        }

                        this.$store.commit('consultants/decrementManager')

                        this.$emit('reload')
                        this.hide()
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            doPromote() {
                this.loading = true

                this.promote(this.consultant)
                    .then(data => {
                        let managers = this.$store.state.consultants.managers

                        if (!managers || !managers.data) {
                            this.$emit('reload')
                            return
                        }

                        managers.data.push(data.manager)

                        this.$store.commit('consultants/setManagers', managers)

                        let consultants = this.$store.state.consultants.consultants
                        let index = consultants.data.findIndex(consultant => consultant.id === this.consultant.id)

                        if (index >= 0) {
                            consultants.data.splice(index, 1)
                            this.$store.commit('consultants/setConsultants', consultants)
                        }

                        this.$store.commit('consultants/incrementManager')

                        this.$emit('reload')
                        this.hide()
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
        mounted() {}
    }
</script>
