<template>
    <b-table :data="certificates">
        <b-table-column field="type" sortable label="Type">
            <div slot="default" slot-scope="props">
                <span>{{ props.row.type }}</span>
            </div>
        </b-table-column>
        <b-table-column field="date" sortable label="Date d'upload">
            <div slot="default" slot-scope="props">
                <span>{{ moment(props.row.created_at).format('L') }}</span>
            </div>
        </b-table-column>
        <b-table-column field="status" sortable label="Status">
            <div slot="default" slot-scope="props">
                <span v-if="props.row.status === 'validate'" class="badge badge-success">Validé</span>
                <span v-else-if="props.row.status === 'new'" class="badge badge-warning">Nouvelle</span>
                <span v-else-if="props.row.status === 'expired'" class="badge badge-danger">Expiré</span>
                <span v-else class="badge badge-danger">Refusé</span>
            </div>
        </b-table-column>
        <b-table-column field="expiration" sortable label="Expiration">
            <div slot="default" slot-scope="props">
                <span v-if="props.row.status === 'validate'">{{ moment(props.row.expiration).format('L') }}</span>
            </div>
        </b-table-column>
        <b-table-column field="download" sortable label="Télécharger">
            <div slot="default" slot-scope="props">
                <!--        <span>{{ props.row.url }}</span>-->
                <b-button type="is-primary" @click="download(props.row)"> Télécharger</b-button>
            </div>
        </b-table-column>
        <b-table-column field="actions" sortable label="Actions">
            <div slot="default" slot-scope="props">
                <div v-if="expirationInput && actionRow == props.row.id">
                    <b-field label="Date d'expiration">
                        <b-input v-model="expirationDate" type="date" />
                    </b-field>
                    <b-button type="is-success" @click="validateCertificate(props.row.id, props.row)">
                        Valider
                    </b-button>
                    <b-button type="is-primary" @click="expirationInput = false"> Annuler</b-button>
                </div>
                <div v-else-if="urssafInput && actionRow == props.row.id">
                    <b-field label="Code URSSAF">
                        <b-input v-model="codeUrssaf" type="text" />
                    </b-field>
                    <b-button type="is-success" @click="checkUrssaf(props.row.id, props.row)"> Valider</b-button>
                    <b-button type="is-primary" @click="urssafInput = false"> Annuler</b-button>
                </div>
                <div v-else-if="refuseInput && actionRow == props.row.id">
                    <b-field label="Raison">
                        <b-select v-model="reason">
                            <option value="olderThan6Month">Attestation de plus de 6 mois</option>
                            <option value="impossibleToValidate">Impossible à valider URSSAF</option>
                            <option value="cantRead">Illisible</option>
                            <option value="urssafMandatory">URSSAF obligatoire</option>
                            <option value="other">Autres (champs libres)</option>
                        </b-select>
                    </b-field>
                    <b-field v-if="reason === 'other'" label="Commentaire">
                        <b-input v-model="comment" type="text" />
                    </b-field>
                    <b-button type="is-success" @click="refuseCertificate(props.row.id, props.row)"> Valider</b-button>
                    <b-button type="is-primary" @click="refuseInput = false"> Annuler</b-button>
                </div>
                <div v-else>
                    <b-button
                        v-if="props.row.status === 'new'"
                        type="is-success"
                        @click=";(expirationInput = true), (actionRow = props.row.id)"
                    >
                        Valider
                    </b-button>
                    <b-button
                        v-if="props.row.status === 'new' && props.row.type === 'urssaf'"
                        type="is-success"
                        @click=";(urssafInput = true), (actionRow = props.row.id)"
                    >
                        URSSAF
                    </b-button>
                    <b-button
                        v-if="props.row.status === 'new'"
                        type="is-warning"
                        @click=";(refuseInput = true), (actionRow = props.row.id)"
                    >
                        Refuser
                    </b-button>
                    <b-button
                        v-if="props.row.status !== 'validate' || props.row.status === 'new'"
                        type="is-danger"
                        @click="deleteCertificate(props.row.id)"
                    >
                        Effacer
                    </b-button>
                </div>
            </div>
        </b-table-column>
    </b-table>
</template>

<script>
    import Download from '@/utils/download'
    import { mapActions } from 'vuex'

    export default {
        name: 'CertificateTable',
        props: {
            certificates: {},
            user: {},
            tabData: {}
        },
        data() {
            return {
                expirationInput: false,
                actionRow: 0,
                refuseInput: false,
                urssafInput: false,
                expirationDate: null,
                codeUrssaf: null,
                reason: null,
                comment: null
            }
        },
        methods: {
            ...mapActions('user_attestations', {
                downloadAttestation: 'download'
            }),
            validateCertificate(certificateId) {
                this.axios
                    .put('/user-attestation/' + certificateId, {
                        id: certificateId,
                        expiration: this.expirationDate,
                        status: 'validate'
                    })
                    .then(() => {
                        let certificate = this.certificates.find(element => element.id === certificateId)
                        certificate.expiration = this.expirationDate
                        certificate.status = 'validate'
                        this.$emit('reloadData')
                        this.expirationInput = false
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
            },
            checkUrssaf(certificateId) {
                this.axios
                    .post(`/user-attestation/${certificateId}/validate-urssaf`, {
                        code: this.codeUrssaf
                    })
                    .then(() => {
                        let certificate = this.certificates.find(element => element.id === certificateId)
                        certificate.expiration = this.expirationDate
                        certificate.status = 'validate'
                        this.$emit('reloadData')
                        this.urssafInput = false
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
            },
            refuseCertificate(certificateId) {
                this.axios
                    .put('/user-attestation/' + certificateId, {
                        id: certificateId,
                        status: 'refused',
                        reason: this.reason,
                        comment: this.comment
                    })
                    .then(() => {
                        let certificate = this.certificates.find(element => element.id === certificateId)
                        certificate.status = 'refuse'
                        this.refuseInput = false
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
            },
            deleteCertificate(certificateId) {
                this.axios
                    .delete('/user-attestation/' + certificateId)
                    .then(() => {
                        this.certificates.splice(
                            this.certificates.indexOf(this.certificates.find(element => element.id === certificateId)),
                            1
                        )
                        this.$emit('reloadData', this.tabData.index)
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
            },
            download(attestation) {
                this.downloadAttestation(attestation.id).then(response => {
                    Download.download(
                        response,
                        this.user.company == null ? `${this.user.firstname} ${this.user.lastname}` : this.user.company
                    )
                })
            }
        }
    }
</script>
