const NlStorage = {
    version: process.env.VUE_APP_VERSION,

    getItem(key) {
        return localStorage.getItem(`nextlevel.${this.version}.${key}`)
    },

    setItem(key, value) {
        return localStorage.setItem(`nextlevel.${this.version}.${key}`, value)
    },

    removeItem(key) {
        localStorage.removeItem(`nextlevel.${this.version}.${key}`)
    },

    clear() {
        localStorage.clear()
    }
}

export default NlStorage
