<template>
    <div class="">
        <div v-if="alert && alert.length" class="mb-2">
            <b-notification type="is-warning" aria-close-label="Close notification" role="alert" :message="alert" />
        </div>

        <div class="d-flex flex-column flex-md-row justify-content-between">
            <div class="d-flex flex-column flex-md-row">
                <div class="mr-md-5 mt-md-2-5">
                    <b-dropdown aria-role="list" :close-on-click="false">
                        <template #trigger="{ active }">
                            <b-button
                                :label="$t('global.columns')"
                                type="is-info"
                                size="is-small"
                                :icon-right="active ? 'caret-up' : 'caret-down'"
                            />
                        </template>

                        <b-dropdown-item v-for="(col, index) in columns" :key="index" aria-role="listitem">
                            <b-field>
                                <b-checkbox v-model="col.active">
                                    {{ col.name }}
                                </b-checkbox>
                            </b-field>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>

                <div class="px-2 d-flex align-items-center">
                    <input v-model="perPage" type="number" class="form-control w-25" @keypress.enter="loadAsyncData" />
                    <p class="control ml-2 mt-2 mb-2">
                        {{ $t('global.per-page') }}
                    </p>
                </div>
            </div>

            <form v-if="type !== 'accompagnementsCompleted'" class="d-flex flex-column flex-md-row">
                <b-field>
                    <b-select v-model="bulkActionType" :placeholder="$t('global.actions')">
                        <option value="status">Status</option>
                        <option value="advisor">Consultant</option>
                        <option v-if="type === 'accompagnementsWaitingValidation'" value="delete">Supprimer</option>
                        <option value="payment">Paiement</option>
                        <option value="audit">Audit</option>
                    </b-select>
                </b-field>
                <b-button
                    :disabled="!checkedRows.length || bulkActionType === null"
                    class="mb-2 mb-md-0 ml-md-2"
                    type="is-warning"
                    @click="openConfirmModal"
                >
                    {{ $t('global.modify') }}
                </b-button>
            </form>
        </div>

        <b-table
            :sticky-header="false"
            :data="accompagnements.data"
            :mobile-cards="true"
            :debounce-search="400"
            default-sort-direction="desc"
            :striped="true"
            :loading="loading"
            :paginated="true"
            :default-sort="[sortField, sortOrder]"
            :per-page="accompagnements.per_page"
            :total="accompagnements.total"
            backend-sorting
            backend-pagination
            backend-filtering
            :has-detailed-visible="row => row.repartitions.length"
            custom-detail-row
            detailed
            :checked-rows.sync="checkedRows"
            checkbox-position="right"
            checkable
            @page-change="onPageChange"
            @sort="onSort"
            @filters-change="onFilterChange"
        >
            <b-table-column
                v-if="
                    type === 'all' ||
                    type === 'accompagnementsInProgress' ||
                    type === 'accompagnementsLastMonth' ||
                    type === 'accompagnementsPrevMonths'
                "
                centered
                field="statut"
                width="9em"
                sortable
                :searchable="isSearchable"
                :label="$t('global.status')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <status
                        :status="props.row.statut"
                        :available="statusList"
                        @change="
                            props.row.statut = $event
                            editAccompagnement({ ...props.row, ...{ statut: $event } })
                        "
                    />
                </div>

                <div slot="searchable" slot-scope="props">
                    <select v-model="props.filters[props.column.field]" class="form-control p-1">
                        <option :value="props.filters['status']" selected disabled hidden>Statuts</option>
                        <option :value="null" selected>Tous</option>
                        <option v-if="type === 'all'" value="a_confirmer">En attente de validation</option>
                        <option value="a_faire">À faire</option>
                        <option value="en_cours">En cours</option>
                        <option value="fait">Fait</option>
                        <option value="en_attente_credit">En attente de crédit</option>
                        <option value="en_attente_retour_client">En attente retour client</option>
                        <option v-if="type === 'all'" value="termine">Terminé</option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column
                field="url"
                sortable
                :searchable="isSearchable"
                :label="$t('global.site')"
                width="20%"
                centered
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span>
                        <AdminAccompagnementMonitorankFlag :monitorank-status="props.row.monitorank_data" />
                        {{ props.row.url }}
                    </span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.site')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="client"
                :searchable="isSearchable"
                :label="$t('global.client')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <div>
                        {{ props.row.user.email }}
                        <LoginAs
                            :user-id="props.row.user_id"
                            :manager-id="props.row.id"
                            :redirect-to="'/admin/campaigns/details?id=' + props.row.id"
                        />
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.client')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="duree"
                sortable
                :searchable="isSearchable"
                :label="$t('global.time.duration')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <div>{{ props.row.duree }} {{ $t('global.month') }}</div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.time.duration')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="budget_inprogress"
                :searchable="isSearchable"
                :label="$t('global.current-month')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <div v-if="props.row.current">
                        {{ parseInt(props.row.current.budget) + parseInt(props.row.current.budget_onsite) }}€
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.current-month')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="budget_offsite_inprogress"
                :searchable="isSearchable"
                :label="$t('global.budget') + ' Offsite'"
                cell-class="align-middle"
                :visible="columns.budget_offsite.active"
            >
                <div slot="default" slot-scope="props">
                    <div v-if="props.row.current">{{ props.row.current.budget }}€</div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.budget')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="budget_onsite_inprogress"
                :searchable="isSearchable"
                :label="$t('global.budget') + ' Onsite'"
                cell-class="align-middle"
                :visible="columns.budget_onsite.active"
            >
                <div slot="default" slot-scope="props">
                    <div v-if="props.row.current">{{ props.row.current.budget_onsite }}€</div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.budget')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="credits"
                :searchable="isSearchable"
                :label="$t('global.credits')"
                cell-class="align-middle"
                :visible="columns.credits.active"
            >
                <div slot="default" slot-scope="props">
                    <div>{{ props.row.user.credit_amount.toFixed(2) }}€</div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.credits')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="type_facturation"
                sortable
                :searchable="isSearchable"
                :label="$t('global.payment')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <div>
                        <span
                            v-if="!editInvoiceType || editInvoiceType != props.row.id"
                            class="is-editable"
                            @click="editInvoiceType = props.row.id"
                        >
                            <span
                                v-for="(type, index) in invoicingTypes"
                                v-show="props.row.type_facturation === type.value"
                                :key="index"
                            >
                                {{ type.name }}
                            </span>
                        </span>

                        <div v-if="editInvoiceType == props.row.id" class="form-group form-group-sm">
                            <select
                                v-model="props.row.type_facturation"
                                class="form-control"
                                @change="editAccompagnement(props.row)"
                                @keydown.esc="closeFlyEdit"
                            >
                                <option v-for="(type, index) in invoicingTypes" :key="index" :value="type.value">
                                    {{ type.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.payment')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="analyse_onpage"
                sortable
                :searchable="isSearchable"
                :label="$t('global.audit') + ' Onpage'"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <b-switch
                        v-model="props.row.analyse_onpage"
                        :true-value="1"
                        :false-value="0"
                        type="is-success"
                        @input="editAccompagnement(props.row)"
                    >
                        {{
                            props.row.analyse_onpage
                                ? $t('accompagnement.status.fait')
                                : $t('accompagnement.status.non_fait')
                        }}
                    </b-switch>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.audit')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="audit_advisor"
                :searchable="isSearchable"
                :label="`${$t('layout.advisor')} ${$t('global.audit')}`"
                cell-class="align-middle"
                :visible="columns.audit_advisor.active"
            >
                <div slot="default" slot-scope="props">
                    <span
                        v-if="!editOnPageAdvisor || editOnPageAdvisor != props.row.id"
                        class="is-editable"
                        @click="editOnPageAdvisor = props.row.id"
                    >
                        <span v-if="props.row.accompagnant_onpage_id">
                            {{ getAdvisorById(props.row.accompagnant_onpage_id, 'onpage').firstname }}
                        </span>
                        <span v-else>
                            <i class="fas fa-plus-circle text-muted" />
                        </span>
                    </span>

                    <div v-if="editOnPageAdvisor == props.row.id" class="form-group form-group-sm">
                        <select
                            v-model="props.row.accompagnant_onpage_id"
                            class="form-control"
                            @change="editAccompagnement(props.row)"
                            @keydown.esc="closeFlyEdit"
                        >
                            <option :value="null">Aucun</option>
                            <option v-for="advisor in advisors.onpage" :key="advisor.id" :value="advisor.id">
                                {{ `${advisor.firstname} ${advisor.lastname}` }}
                            </option>
                        </select>
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <select v-model="props.filters[props.column.field]" class="form-control p-1">
                        <option :value="props.filters['audit_advisor']" selected disabled hidden>
                            {{ $t('layout.advisor') }}
                        </option>
                        <option :value="null">Tous</option>
                        <option v-for="advisor in advisors.onpage" :key="advisor.id" :value="advisor.id">
                            {{ `${advisor.firstname} ${advisor.lastname}` }}
                        </option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="offsite_advisor"
                :searchable="isSearchable"
                :label="`${$t('layout.advisor')} Offsite`"
                cell-class="align-middle"
                :visible="columns.offsite_advisor.active"
            >
                <div slot="default" slot-scope="props">
                    <span
                        v-if="!editOffSiteAdvisor || editOffSiteAdvisor != props.row.id"
                        class="is-editable"
                        @click="editOffSiteAdvisor = props.row.id"
                    >
                        <span v-if="props.row.accompagnant_id">
                            {{ getAdvisorById(props.row.accompagnant_id, 'offsite').firstname }}
                        </span>
                        <span v-else>
                            <i class="fas fa-plus-circle text-muted" />
                        </span>
                    </span>

                    <div v-if="editOffSiteAdvisor == props.row.id" class="form-group form-group-sm">
                        <select
                            v-model="props.row.accompagnant_id"
                            class="form-control"
                            @change="editAccompagnement(props.row)"
                            @keydown.esc="closeFlyEdit"
                        >
                            <option :value="null">Aucun</option>
                            <option v-for="advisor in advisors.offsite" :key="advisor.id" :value="advisor.id">
                                {{ `${advisor.firstname} ${advisor.lastname}` }}
                            </option>
                        </select>
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <select v-model="props.filters[props.column.field]" class="form-control p-1">
                        <option :value="props.filters['offsite_advisor']" selected disabled hidden>
                            {{ $t('layout.advisor') }}
                        </option>
                        <option :value="null">Tous</option>
                        <option v-for="advisor in advisors.offsite" :key="advisor.id" :value="advisor.id">
                            {{ `${advisor.firstname} ${advisor.lastname}` }}
                        </option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="onsite_advisor"
                :searchable="isSearchable"
                :label="`${$t('layout.advisor')} Onsite`"
                cell-class="align-middle"
                :visible="columns.onsite_advisor.active"
            >
                <div slot="default" slot-scope="props">
                    <span
                        v-if="!editOnSiteAdvisor || editOnSiteAdvisor != props.row.id"
                        class="is-editable"
                        @click="editOnSiteAdvisor = props.row.id"
                    >
                        <span v-if="props.row.accompagnant2_id">
                            {{ getAdvisorById(props.row.accompagnant2_id, 'onsite').firstname }}
                        </span>
                        <span v-else>
                            <i class="fas fa-plus-circle text-muted" />
                        </span>
                    </span>

                    <div v-if="editOnSiteAdvisor == props.row.id" class="form-group form-group-sm">
                        <select
                            v-model="props.row.accompagnant2_id"
                            class="form-control"
                            @change="editAccompagnement(props.row)"
                            @keydown.esc="closeFlyEdit"
                        >
                            <option :value="null">Aucun</option>
                            <option v-for="advisor in advisors.onsite" :key="advisor.id" :value="advisor.id">
                                {{ `${advisor.firstname} ${advisor.lastname}` }}
                            </option>
                        </select>
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <select v-model="props.filters[props.column.field]" class="form-control p-1">
                        <option :value="props.filters['onsite_advisor']" selected disabled hidden>
                            {{ $t('layout.advisor') }}
                        </option>
                        <option :value="null">Tous</option>
                        <option v-for="advisor in advisors.onsite" :key="advisor.id" :value="advisor.id">
                            {{ `${advisor.firstname} ${advisor.lastname}` }}
                        </option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="created_at"
                width="4em"
                sortable
                :searchable="isSearchable"
                :label="$t('global.time.date')"
                cell-class="align-middle"
                :visible="columns.created_at.active"
            >
                <div slot="default" slot-scope="props">
                    {{ moment(props.row.created_at).format('DD/MM/YYYY') }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.time.date') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="debut_accompagnement"
                width="4em"
                sortable
                :searchable="isSearchable"
                :label="$t('mes-accompagnements.datestart')"
                cell-class="align-middle"
                :visible="columns.debut_accompagnement.active"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.debut_accompagnement">
                        {{ moment(props.row.debut_accompagnement).format('DD/MM/YYYY') }}
                    </span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.time.date') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="fin_accompagnement"
                width="4em"
                sortable
                :searchable="isSearchable"
                :label="$t('mes-accompagnements.dateend')"
                cell-class="align-middle"
                :visible="columns.fin_accompagnement.active"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.fin_accompagnement">
                        {{ moment(props.row.fin_accompagnement).format('DD/MM/YYYY') }}
                    </span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.time.date') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column centered field="mail_recap" label="Rapport" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <b-switch
                        v-model="props.row.mail_recap"
                        :true-value="1"
                        :false-value="0"
                        type="is-success"
                        @input="editAccompagnement(props.row)"
                    >
                        {{
                            props.row.mail_recap
                                ? $t('accompagnement.status.fait')
                                : $t('accompagnement.status.non_fait')
                        }}
                    </b-switch>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="launching_mail"
                width="4em"
                :label="columns.launching_mail.name"
                cell-class="align-middle"
                :visible="columns.launching_mail.active"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.launching_mail && props.row.launching_mail.sent_at">
                        <i
                            class="fas fa-check text-success"
                            :title="`Envoyé le ${moment(props.row.launching_mail.sent_at).format('DD/MM/YYYY')}`"
                        />
                    </span>
                    <span v-else>
                        <i class="fas fa-times text-danger" />
                    </span>
                </div>
            </b-table-column>

            <b-table-column :label="$t('global.actions')" cell-class="align-middle" centered width="7em">
                <div slot="default" slot-scope="props">
                    <div class="buttons justify-content-center">
                        <a
                            :href="'/admin/campaigns/details?id=' + props.row.id"
                            class="rounded button px-2 is-light rounded-lg text-decoration-none"
                            :title="$t('global.show')"
                        >
                            <i class="fas fa-eye" />
                        </a>
                        <button
                            v-if="props.row.statut === 'a_confirmer'"
                            class="rounded button px-2 is-light is-danger rounded-lg text-decoration-none"
                            :title="$t('global.delete')"
                            @click="
                                checkedRows = [props.row]
                                openConfirmModal('delete')
                            "
                        >
                            <i class="fas fa-trash-alt" />
                        </button>
                    </div>
                </div>
            </b-table-column>

            <template slot="detail" slot-scope="props">
                <tr>
                    <td colspan="100%" class="p-4">
                        <AdminAccompagnementDetailedRow
                            :accompagnement="props.row"
                            :status-list="statusList"
                            @editAccompagnement="e => editAccompagnement(e)"
                        />
                    </td>
                    <span class="is-hidden">{{ props.row.id }}</span>
                </tr>
            </template>
        </b-table>

        <AdminAccompagnementModal
            ref="confirmModal"
            :checked-rows="checkedRows"
            :payment-types="invoicingTypes"
            :status-list="statusList"
            :advisors="advisors"
            @uncheckRows="checkedRows = []"
            @refresh="refresh"
        />
    </div>
</template>

<script>
    import Status from '../../Campaigns/Status'
    import AdminAccompagnementDetailedRow from './AdminAccompagnementDetailedRow'
    import AdminAccompagnementModal from './AdminAccompagnementModal'

    import { mapActions } from 'vuex'

    export default {
        name: 'AdminAccompagnementTable',
        components: {
            AdminAccompagnementModal,
            AdminAccompagnementDetailedRow,
            Status
        },
        props: {
            items: {
                type: Object,
                default: () => ({})
            },
            alert: {
                type: Array,
                default: () => []
            },
            type: {
                type: String,
                required: true
            },
            advisors: {
                type: Object,
                required: true
            },
            defaultColumns: {
                type: Array,
                default: () => []
            },
            url: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                isSearchable: true,
                bulkActionType: null,
                checkedRows: [],
                loading: false,
                perPage: 200,
                accompagnements: {},
                sortField: 'id',
                sortOrder: 'desc',
                filters: '',
                page: 1,

                editOnPageAdvisor: null,
                editOffSiteAdvisor: null,
                editOnSiteAdvisor: null,
                editInvoiceType: null,

                columns: {
                    budget_offsite: {
                        active: false,
                        name: this.$t('global.budget') + ' Offsite'
                    },
                    budget_onsite: {
                        active: false,
                        name: this.$t('global.budget') + ' Onsite'
                    },
                    credits: {
                        active: false,
                        name: this.$t('global.credits')
                    },
                    audit_advisor: {
                        active: false,
                        name: `${this.$t('layout.advisor')} ${this.$t('global.audit')}`
                    },
                    offsite_advisor: {
                        active: false,
                        name: `${this.$t('layout.advisor')} Offsite`
                    },
                    onsite_advisor: {
                        active: false,
                        name: `${this.$t('layout.advisor')} Onsite`
                    },
                    created_at: {
                        active: false,
                        name: this.$t('global.created-at')
                    },
                    debut_accompagnement: {
                        active: false,
                        name: this.$t('mes-accompagnements.datestart')
                    },
                    fin_accompagnement: {
                        active: false,
                        name: this.$t('mes-accompagnements.dateend')
                    },
                    mail_recap: {
                        active: false,
                        name: 'Rapport'
                    },
                    launching_mail: {
                        active: false,
                        name: 'Email de lancement'
                    }
                },

                invoicingTypes: [
                    { value: 'facture', name: 'Facture' },
                    { value: 'abo_cb', name: 'Abonnement' },
                    { value: 'sepa', name: 'SEPA' },
                    { value: 'credit', name: 'Crédits' },
                    { value: 'ne_rien_faire', name: 'Ne rien faire' }
                ],

                statusList: [
                    'a_confirmer',
                    'a_faire',
                    'en_cours',
                    'fait',
                    'en_attente_credit',
                    'en_attente_retour_client',
                    'termine'
                ]
            }
        },

        mounted() {
            this.applyDefaultColumns()
            this.loadAsyncData()
        },
        methods: {
            ...mapActions('campaigns', ['updateCampaignStatus']),
            applyDefaultColumns() {
                if (this.defaultColumns.length > 0) {
                    this.defaultColumns.forEach(column => {
                        this.columns[column].active = true
                    })
                }
            },
            openConfirmModal(type) {
                if (!type.target) {
                    this.bulkActionType = type
                }

                this.$refs.confirmModal.openModal(this.bulkActionType)
            },

            getAdvisorById(advisorId, type) {
                return this.advisors[type].find(advisor => advisor.id === advisorId)
            },

            editAccompagnement(accompagnement) {
                this.isLoading = true

                let formData = new FormData()
                formData.append('_method', 'PATCH')

                for (const [key, value] of Object.entries(accompagnement)) {
                    if (value == null) {
                        formData.append(key, '')
                    } else {
                        formData.append(key, value)
                    }
                }

                this.axios
                    .post(`/admin/accompagnements/${accompagnement.id}`, formData)
                    .then(() => {
                        this.snackNotif('Accompagnement mis à jour')
                        this.$store.dispatch('toast', {
                            title: 'Accompagnement mis à jour',
                            message: 'Accompagnement mis à jour',
                            type: 'success'
                        })
                    })
                    .catch(error => {
                        this.$store.dispatch('toast', {
                            title: 'Une erreur est survenue lors de la mise à jour',
                            message: 'Une erreur est survenue lors de la mise à jour',
                            type: 'error'
                        })
                    })
                    .finally(() => this.closeFlyEdit())
            },

            closeFlyEdit() {
                this.editOnPageAdvisor = null
                this.editOffSiteAdvisor = null
                this.editOnSiteAdvisor = null
                this.editInvoiceType = null
            },

            /** Table filtering, sorting and pagination methods */
            loadAsyncData() {
                this.loading = true

                var field = this.sortField
                var route
                if (this.sortOrder === 'desc') {
                    field = '-' + this.sortField
                }
                var params = {
                    page: this.page,
                    include: this.includes,
                    sort: field
                }
                if (this.filters && this.filters.length > 0) {
                    this.filters.forEach(element => {
                        params[`filter[${element.key}]`] = element.value
                    })
                }
                let perPage = this.perPage ? this.perPage : 20
                perPage = this.url.includes('?') ? `&per_page=${perPage}` : `?per_page=${perPage}`
                this.axios
                    .get(this.url + perPage, {
                        params
                    })
                    .then(response => {
                        this.accompagnements = response.data.accompagnements
                    })
                    .catch(error => {
                        this.snackNotif(error.response.data.message, 'is-danger')
                        console.error(error)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order

                this.loadAsyncData()
            },

            onFilterChange(filter) {
                var filters = []
                Object.keys(filter).forEach(element => {
                    var currentFilter = {
                        key: element,
                        value: filter[element]
                    }
                    filters.push(currentFilter)
                })
                this.filters = filters
                this.loadAsyncData()
            },

            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            applyDeleteItems(ids) {
                this.counts.all = this.counts.all - ids.length
                this.counts.waitingValidation = this.counts.waitingValidation - ids.length

                if (this.accompagnementsWaitingValidation.hasOwnProperty('data')) {
                    this.accompagnementsWaitingValidation.data = this.accompagnementsWaitingValidation.data.filter(
                        acc => {
                            return !ids.includes(acc.id)
                        }
                    )
                }

                if (this.accompagnements.hasOwnProperty('data')) {
                    this.accompagnements.data = this.accompagnements.data.filter(acc => {
                        return !ids.includes(acc.id)
                    })
                }
            },
            refresh() {
                this.$emit('refresh')
            }
        }
    }
</script>

<style>
    @media screen and (min-width: 768px) {
        .mt-md-2-5 {
            margin-top: 0.75em;
        }
    }

    .th-wrap {
        justify-content: center;
    }
</style>
