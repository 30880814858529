import ApiService from '../../../services/api.service'

export default {
    list(context, params) {
        const { sort, page, rowsPerPage, filter } = params ? params : [null, null, null, null]

        return new Promise((resolve, reject) => {
            ApiService.get('/partner_alert', {
                sort,
                page,
                rowsPerPage,
                filter
            })
                .then(({ data }) => {
                    context.commit('setList', data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    get(context, alertId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/partner_alert/' + alertId)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    create(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.post('/partner_alert', model)
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    update(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.put('/partner_alert/' + model.id, model)
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    delete(context, alertId) {
        return new Promise((resolve, reject) => {
            ApiService.delete('/partner_alert/' + alertId)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    }
}
