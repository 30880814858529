<template>
    <div>
        <div v-if="preloading">
            <button class="btn btn-secondary"><i class="fas fa-cog fa-spin" /> {{ $t('global.loading') }}...</button>
        </div>

        <div v-else>
            <b-loading v-model="isLoading" :is-full-page="true" />

            <div class="d-flex" style="gap: 10px">
                <StatCard :color="'success'" :icon="'fas fa-check'">
                    <template v-slot:counter>
                        <h3>{{ counts.activated }}</h3>
                    </template>
                    <template v-slot:text>
                        <span>Sites acceptés</span>
                    </template>
                </StatCard>
                <StatCard :color="'warning'" :icon="'fas fa-check'">
                    <template v-slot:counter>
                        <h3>{{ counts.desactivatedNL + counts.desactivated }}</h3>
                    </template>
                    <template v-slot:text>
                        <span>Sites désactivés (Nextlevel et partenaire)</span>
                    </template>
                </StatCard>
                <StatCard :color="'info'" :icon="'fas fa-pencil-alt'">
                    <template v-slot:counter>
                        <h3>
                            {{ counts.waiting_activation + counts.waiting_improvement }}
                        </h3>
                    </template>
                    <template v-slot:text>
                        <span>Sites en attente (Validation et amélioration)</span>
                    </template>
                </StatCard>
                <StatCard :color="'danger'" :icon="'fas fa-trash-alt'">
                    <template v-slot:counter>
                        <h3>{{ counts.deleted }}</h3>
                    </template>
                    <template v-slot:text>
                        <span>Sites supprimés</span>
                    </template>
                </StatCard>
            </div>

            <section>
                <!--        <b-tabs
                    v-model="activeTab"
                    @input="getTabData"
                >
                  <b-tab-item
                      :label="tab.label"
                      :icon="tab.icon"
                      :value="tab.type"
                      v-for="tab in tabs"
                      :key="tab.index"
                      :disabled="tab.disabled"
                  >-->
                <GroupSiteTableWithoutTab
                    :tab-info="tab"
                    :groups="groups"
                    :users="users"
                    :partners="partners"
                    :display-user="displayUser"
                    @loadAsyncData="loadAsyncSitesData"
                    @openModal="openModalFromTable"
                    @openWPModal="openWPModal"
                    @deleteBulkSites="deleteBulkSites"
                    @delete="deleteSite"
                    @desactivateBulkSites="desactivateBulkSites"
                    @activateBulkSites="activateBulkSites"
                    @reactivateBulkSites="reactivateBulkSites"
                    @waitingActivateBulkSites="waitingActivateBulkSites"
                    @redactionBulkSites="redactionBulkSites"
                    @typeBulkSites="typeBulkSites"
                    @groupBulkSites="groupBulkSites"
                    @editSite="editSite"
                />
                <!--          </b-tab-item>
                </b-tabs>-->
            </section>
        </div>

        <GroupSiteModal
            ref="siteModal"
            :data="form"
            :groups="groups"
            :partners="partners"
            @cleanForm="resetForm"
            @saveSite="saveSite"
            @editSite="editSite"
        />
    </div>
</template>

<script>
    export default {
        props: {
            displayUser: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                wordpressInformations: [],
                sites: [],
                groups: [],
                alerts: [],
                counts: {},
                users: [],
                partners: [],

                activeTab: 0,
                tabs: [],
                tab: {},

                form: {},

                activedSites: [],
                deletedSites: [],
                waitingActivationSites: [],
                desactivatedNLSites: [],
                desactivatedSites: [],
                waitingImprovementSites: [],

                preloading: true,
                isLoading: false,

                statusActive: 1,
                statusDelete: 0,
                statusWaitingActivation: -1,
                statusDesactiveNL: -2,
                statusRefuse: -3,
                statusDesactivePartner: -4,
                statusWaitingImprove: -5
            }
        },

        created() {
            this.getData()
            this.resetForm()
        },

        methods: {
            getData() {
                let siteAndWordpressData = this.axios
                    .get(
                        `/admin/partner-details/sites/${this.$route.query.id}?filter[partenaire_id]=${this.$route.query.id}`,
                        { index: this.statusActive }
                    )
                    .then(response => {
                        this.wordpressInformations = {
                            text: response.data.wordpress.text,
                            url_plugin: response.data.wordpress.url_plugin
                        }

                        this.users = response.data.users
                        this.partners = response.data.partners
                        this.sites = response.data.sites
                        this.groups = response.data.groups
                        this.alerts = response.data.alerts
                        let counts = response.data.counts

                        let countActive = counts.find(key => key.actif == this.statusActive)
                        let countDelete = counts.find(key => key.actif == this.statusDelete)
                        let countActivation = counts.find(key => key.actif == this.statusWaitingActivation)
                        let countDesactivateNL = counts.find(key => key.actif == this.statusDesactiveNL)
                        let countDesactivate = counts.find(key => key.actif == this.statusDesactivePartner)
                        let countImprove = counts.find(key => key.actif == this.statusWaitingImprove)
                        this.counts = {
                            activated: countActive ? countActive.total : 0,
                            deleted: countDelete ? countDelete.total : 0,
                            waiting_activation: countActivation ? countActivation.total : 0,
                            desactivatedNL: countDesactivateNL ? countDesactivateNL.total : 0,
                            desactivated: countDesactivate ? countDesactivate.total : 0,
                            waiting_improvement: countImprove ? countImprove.total : 0
                        }

                        this.$emit(
                            'countSites',
                            this.counts.activated +
                                this.counts.deleted +
                                this.counts.waiting_activation +
                                this.counts.desactivatedNL +
                                this.counts.desactivated +
                                this.counts.waiting_improvement
                        )

                        this.tab = {
                            type: 'all',
                            label: this.$t('global.all') + ` (${this.sites.total})`,
                            icon: 'sitemap',
                            content: this.sites,
                            content_key: 'sites',
                            disabled: false,
                            index: 0,
                            status: ''
                        }

                        this.formatTab()
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })

                Promise.all([siteAndWordpressData]).then(() => {
                    this.preloading = false
                })
            },

            saveSite(formData) {
                this.isLoading = true

                let form = new FormData()
                for (const [key, value] of Object.entries(formData)) {
                    if (value == null) {
                        form.append(key, '')
                    } else {
                        form.append(key, value)
                    }
                }

                this.axios
                    .post(`/admin/sites/create`, form, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        this.snackNotif(this.$t('partenaire-site.valid-save-site'))
                        this.sites.data = [...this.sites.data, response.data.site]
                        this.sites.total = this.sites.total + 1

                        this.formatTab()
                    })
                    .catch(error => {
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            editSite(formData) {
                this.isLoading = true

                let form = new FormData()
                form.append('_method', 'PATCH')

                for (const [key, value] of Object.entries(formData)) {
                    if (value == null) {
                        form.append(key, '')
                    } else {
                        form.append(key, value)
                    }
                }

                this.axios
                    .post(`/admin/sites/edit/${formData.id}`, form, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        /*this.snackNotif(this.$t('partenaire-site.valid-edit-site'));*/
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'Le rédacteur a bien été crédité',
                            message: ''
                        })

                        this.sites.data = this.sites.data.map(site => {
                            if (formData.id === site.id) {
                                site.scrape_sitemap =
                                    response.data.site.scrape_sitemap != null ? response.data.site.scrape_sitemap : null
                                site.scrape_whitelist =
                                    response.data.site.scrape_whitelist != null
                                        ? response.data.site.scrape_whitelist
                                        : null
                                site.scrape_blacklist =
                                    response.data.site.scrape_blacklist != null
                                        ? response.data.site.scrape_blacklist
                                        : null
                                site.admin_scrape_blacklist =
                                    response.data.site.admin_scrape_blacklist != null
                                        ? response.data.site.admin_scrape_blacklist
                                        : null
                                site.scrape_whitelist_count =
                                    response.data.site.scrape_whitelist_count != null
                                        ? response.data.site.scrape_whitelist_count
                                        : null
                                site.scrape_blacklist_count =
                                    response.data.site.scrape_blacklist_count != null
                                        ? response.data.site.scrape_blacklist_count
                                        : null
                                site.admin_scrape_blacklist_count =
                                    response.data.site.admin_scrape_blacklist_count != null
                                        ? response.data.site.admin_scrape_blacklist_count
                                        : null
                                site.refus_concurrent_mfa = response.data.site.refus_concurrent_mfa
                                site.commission = response.data.site.commission
                                site.prix_minimum_base = response.data.site.prix_minimum_base
                                site.prix_minimum_filtre = response.data.site.prix_minimum_filtre
                            }

                            return site
                        })

                        this.formatTab()
                    })
                    .catch(error => {
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            deleteSite(formData) {
                this.isLoading = true

                this.axios
                    .delete(`/admin/sites/delete/${formData.id}`, {
                        data: {
                            notes: formData.notes
                        }
                    })
                    .then(response => {
                        this.snackNotif(this.$t('partenaire-site.valid-edit-site'))
                        this.sites.data = this.sites.data.map(site => {
                            if (formData.id === site.id) {
                                return response.data.site
                            }

                            return site
                        })

                        this.formatTab()
                    })
                    .catch(error => {
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            deleteBulkSites(params) {
                this.isLoading = true

                this.axios
                    .delete(`/admin/sites/bulk-delete`, {
                        data: {
                            notes: params.notes,
                            ids: JSON.stringify(params.ids)
                        }
                    })
                    .then(() => {
                        this.snackNotif(this.$t('partenaire-site.valid-delete-site'))
                        this.sites.data = this.sites.data.map(site => {
                            if (params.ids.includes(site.id)) {
                                site.actif = this.statusDelete
                            }

                            return site
                        })
                        if (this.activedSites.hasOwnProperty('data')) {
                            this.activedSites.data = this.activedSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.actif = this.statusDelete
                                }

                                return site
                            })
                        }
                        if (this.waitingActivationSites.hasOwnProperty('data')) {
                            this.waitingActivationSites.data = this.waitingActivationSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.actif = this.statusDelete
                                }

                                return site
                            })
                        }
                        if (this.waitingImprovementSites.hasOwnProperty('data')) {
                            this.waitingImprovementSites.data = this.waitingImprovementSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.actif = this.statusDelete
                                }

                                return site
                            })
                        }
                        if (this.refusedSites.hasOwnProperty('data')) {
                            this.refusedSites.data = this.refusedSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.actif = this.statusDelete
                                }

                                return site
                            })
                        }
                        if (this.desactivatedSites.hasOwnProperty('data')) {
                            this.desactivatedSites.data = this.desactivatedSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.actif = this.statusDelete
                                }

                                return site
                            })
                        }

                        this.formatTab()
                    })
                    .catch(error => {
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            desactivateBulkSites(params) {
                this.isLoading = true

                this.axios
                    .patch(`/admin/sites/bulk-desactivate`, {
                        ids: JSON.stringify(params.ids),
                        choice_type: params.choice_type,
                        scheduled_at: params.scheduled_at,
                        desactivation_site_to: params.desactivation_site_to,
                        notes: params.notes
                    })
                    .then(() => {
                        this.snackNotif(this.$t('partenaire-site.valid-desactive-site'))

                        this.sites.data = this.sites.data.map(site => {
                            if (params.ids.includes(site.id)) {
                                site.actif = this.statusDesactiveNL
                                site.desactivation_site_to = params.desactivation_site_to
                                site.scheduled_at = params.scheduled_at
                            }

                            return site
                        })
                        if (this.activedSites.hasOwnProperty('data')) {
                            this.activedSites.data = this.activedSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.actif = this.statusDesactiveNL
                                    site.desactivation_site_to = params.desactivation_site_to
                                    site.scheduled_at = params.scheduled_at
                                }

                                return site
                            })
                        }
                        if (this.waitingActivationSites.hasOwnProperty('data')) {
                            this.waitingActivationSites.data = this.waitingActivationSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.actif = this.statusDesactiveNL
                                    site.desactivation_site_to = params.desactivation_site_to
                                    site.scheduled_at = params.scheduled_at
                                }

                                return site
                            })
                        }

                        this.formatTab()
                    })
                    .catch(error => {
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            activateBulkSites(params) {
                this.isLoading = true

                this.axios
                    .patch(`/admin/sites/bulk-activate`, {
                        ids: JSON.stringify(params.ids)
                    })
                    .then(() => {
                        this.snackNotif(this.$t('partenaire-site.valid-reactive-site'))

                        this.sites.data = this.sites.data.map(site => {
                            if (params.ids.includes(site.id)) {
                                site.actif = this.statusActive
                                site.desactivation_site_to = null
                                site.scheduled_at = null
                            }

                            return site
                        })
                        if (this.waitingActivationSites.hasOwnProperty('data')) {
                            this.waitingActivationSites.data = this.waitingActivationSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.actif = this.statusActive
                                    site.desactivation_site_to = null
                                    site.scheduled_at = null
                                }

                                return site
                            })
                        }
                        if (this.activedSites.hasOwnProperty('data')) {
                            this.activedSites.data = this.activedSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.actif = this.statusActive
                                    site.desactivation_site_to = null
                                    site.scheduled_at = null
                                }

                                return site
                            })
                        }

                        this.formatTab()
                    })
                    .catch(error => {
                        console.log(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            reactivateBulkSites(params) {
                this.isLoading = true

                this.axios
                    .patch(`/admin/sites/bulk-reactivate`, {
                        ids: JSON.stringify(params.ids)
                    })
                    .then(() => {
                        this.snackNotif(this.$t('partenaire-site.valid-reactive-site'))

                        this.sites.data = this.sites.data.map(site => {
                            if (params.ids.includes(site.id)) {
                                site.actif = this.statusActive
                                site.desactivation_site_to = null
                                site.scheduled_at = null
                            }

                            return site
                        })
                        if (this.waitingActivationSites.hasOwnProperty('data')) {
                            this.waitingActivationSites.data = this.waitingActivationSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.actif = this.statusActive
                                    site.desactivation_site_to = null
                                    site.scheduled_at = null
                                }

                                return site
                            })
                        }
                        if (this.activedSites.hasOwnProperty('data')) {
                            this.activedSites.data = this.activedSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.actif = this.statusActive
                                    site.desactivation_site_to = null
                                    site.scheduled_at = null
                                }

                                return site
                            })
                        }

                        this.formatTab()
                    })
                    .catch(error => {
                        console.log(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            waitingActivateBulkSites(params) {
                this.isLoading = true

                this.axios
                    .patch(`/admin/sites/bulk-waiting-activate`, {
                        ids: JSON.stringify(params.ids),
                        choice_type: params.choice_type,
                        notes: params.notes
                    })
                    .then(() => {
                        this.snackNotif("Le site est maintenant en attente d'amélioration")

                        this.sites.data = this.sites.data.map(site => {
                            if (params.ids.includes(site.id)) {
                                site.actif = this.statusWaitingImprove
                                site.desactivation_site_to = null
                                site.scheduled_at = null
                            }

                            return site
                        })
                        if (this.waitingActivationSites.hasOwnProperty('data')) {
                            this.waitingActivationSites.data = this.waitingActivationSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.actif = this.statusWaitingImprove
                                    site.desactivation_site_to = null
                                    site.scheduled_at = null
                                }

                                return site
                            })
                        }
                        if (this.activedSites.hasOwnProperty('data')) {
                            this.activedSites.data = this.activedSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.actif = this.statusWaitingImprove
                                    site.desactivation_site_to = null
                                    site.scheduled_at = null
                                }

                                return site
                            })
                        }

                        this.formatTab()
                    })
                    .catch(error => {
                        console.log(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            redactionBulkSites(params) {
                this.isLoading = true

                this.axios
                    .patch(`/admin/sites/redaction`, {
                        ids: JSON.stringify(params.ids),
                        redaction: params.redaction
                    })
                    .then(() => {
                        this.snackNotif(this.$t('partenaire-site.valid-edit-sites'))
                        this.sites.data = this.sites.data.map(site => {
                            if (params.ids.includes(site.id)) {
                                site.redaction = params.redaction
                            }

                            return site
                        })
                        if (this.waitingActivationSites.hasOwnProperty('data')) {
                            this.waitingActivationSites.data = this.waitingActivationSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.redaction = params.redaction
                                }

                                return site
                            })
                        }
                        if (this.activedSites.hasOwnProperty('data')) {
                            this.activedSites.data = this.activedSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.redaction = params.redaction
                                }

                                return site
                            })
                        }

                        this.formatTab()
                    })
                    .catch(error => {
                        console.log(error)
                        this.snackNotif(error.response.data.message, 'is-error')
                    })
                    .finally(() => (this.isLoading = false))
            },

            typeBulkSites(params) {
                this.isLoading = true

                this.axios
                    .patch(`/admin/sites/type`, {
                        ids: JSON.stringify(params.ids),
                        type: params.type
                    })
                    .then(() => {
                        this.snackNotif(this.$t('partenaire-site.valid-edit-sites'))
                        this.sites.data = this.sites.data.map(site => {
                            if (params.ids.includes(site.id)) {
                                site.site_type = params.type
                            }

                            return site
                        })
                        if (this.waitingActivationSites.hasOwnProperty('data')) {
                            this.waitingActivationSites.data = this.waitingActivationSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.site_type = params.type
                                }

                                return site
                            })
                        }
                        if (this.activedSites.hasOwnProperty('data')) {
                            this.activedSites.data = this.activedSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.site_type = params.type
                                }

                                return site
                            })
                        }

                        this.formatTab()
                    })
                    .catch(error => {
                        console.log(error)
                        this.snackNotif(error.response.data.message, 'is-error')
                    })
                    .finally(() => (this.isLoading = false))
            },

            groupBulkSites(params) {
                this.isLoading = true

                this.axios
                    .patch(`/admin/sites/group`, {
                        ids: JSON.stringify(params.ids),
                        group: params.group
                    })
                    .then(() => {
                        this.snackNotif(this.$t('partenaire-site.valid-edit-sites'))
                        this.sites.data = this.sites.data.map(site => {
                            if (params.ids.includes(site.id)) {
                                site.group_id = params.group
                            }

                            return site
                        })
                        if (this.waitingActivationSites.hasOwnProperty('data')) {
                            this.waitingActivationSites.data = this.waitingActivationSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.group_id = params.group
                                }

                                return site
                            })
                        }
                        if (this.activedSites.hasOwnProperty('data')) {
                            this.activedSites.data = this.activedSites.data.map(site => {
                                if (params.ids.includes(site.id)) {
                                    site.group_id = params.group
                                }

                                return site
                            })
                        }

                        this.formatTab()
                    })
                    .catch(error => {
                        console.log(error)
                        this.snackNotif(error.response.data.message, 'is-error')
                    })
                    .finally(() => (this.isLoading = false))
            },

            formatTab() {
                this.reloadCount()

                this.tab = {
                    type: 'all',
                    label: this.$t('global.all') + ` (${this.sites.total})`,
                    icon: 'sitemap',
                    content: this.sites,
                    content_key: 'sites',
                    disabled: false,
                    index: 0,
                    status: ''
                }
            },

            reloadCount() {
                this.axios
                    .get(
                        `/admin/partner-details/sites-counts/${this.$route.query.id}?filter[partenaire_id]=${this.$route.query.id}`
                    )
                    .then(response => {
                        let counts = response.data.counts
                        let countActive = counts.find(key => key.actif == this.statusActive)
                        let countDelete = counts.find(key => key.actif == this.statusDelete)
                        let countActivation = counts.find(key => key.actif == this.statusWaitingActivation)
                        let countDesactivateNL = counts.find(key => key.actif == this.statusDesactiveNL)
                        let countDesactivate = counts.find(key => key.actif == this.statusDesactivePartner)
                        let countImprove = counts.find(key => key.actif == this.statusWaitingImprove)
                        this.counts = {
                            activated: countActive ? countActive.total : 0,
                            deleted: countDelete ? countDelete.total : 0,
                            waiting_activation: countActivation ? countActivation.total : 0,
                            desactivatedNL: countDesactivateNL ? countDesactivateNL.total : 0,
                            desactivated: countDesactivate ? countDesactivate.total : 0,
                            waiting_improvement: countImprove ? countImprove.total : 0
                        }

                        this.$emit(
                            'countSites',
                            this.counts.activated +
                                this.counts.deleted +
                                this.counts.waiting_activation +
                                this.counts.desactivatedNL +
                                this.counts.desactivated +
                                this.counts.waiting_improvement
                        )
                    })
                    .finally()
            },

            loadAsyncSitesData(data) {
                var field = data.sortField
                var route

                if (data.sortOrder === 'desc') {
                    field = '-' + data.sortField
                }

                var params = {
                    page: data.page,
                    per_page: data.per_page,
                    include: data.includes,
                    sort: field
                }

                if (data.filters && data.filters.length > 0) {
                    data.filters.forEach(element => {
                        if (
                            (element.key === 'partner.email' && element.value === 'nextlevel') ||
                            element.value === 'nl'
                        ) {
                            params.partenaire_null = 'nextlevel'
                        } else {
                            params[`filter[${element.key}]`] = element.value
                        }
                    })
                }

                if (this.activeTab !== 0) {
                    let activeTabStatus = this.tabs.find(tab => tab.index == this.activeTab).status
                    route = data.path + '?index=' + activeTabStatus
                } else {
                    route = data.path
                }

                this.axios
                    .get(
                        `/admin/partner-details/sites/${this.$route.query.id}?filter[partenaire_id]=${this.$route.query.id}`,
                        {
                            params
                        }
                    )
                    .then(response => {
                        if (this.activeTab !== 0) {
                            let siteIndex = this.tabs.find(tab => tab.index == this.activeTab).content_key
                            this[siteIndex] = response.data.sites
                        } else {
                            this.sites = response.data.sites
                        }
                        this.formatTab()
                    })
                    .finally()
            },

            openModal() {
                this.$refs.siteModal.openModal()
            },

            openWPModal(site) {
                this.$refs.wpModal.openModal(site)
            },

            openModalFromTable(site) {
                this.$refs.siteModal.openModal(site)
            },

            resetForm() {
                this.form = {
                    url: '',
                    lang: 'fr_fr',
                    type_cms: 'wordpress',
                    group_id: 1,
                    site_type: 'bronze',
                    redaction: 'nextlevel',
                    validation: 'simple',
                    scrape_sitemap: '',
                    scrape_whitelist: [],
                    scrape_blacklist: [],
                    refus_concurrent_mfa: 'non',
                    lien_secondaire_autorise: 1,
                    contenu_redige_max: 700,
                    ancres_acceptees: 'toutes',
                    planification_autorisee: 1,
                    prix_minimum_filtre: 0,
                    clear_whitelist: false,
                    clear_blacklist: false
                }
            }
        }
    }
</script>
