import ApiService from '../../../services/api.service'
import { getCache, setCache } from '@/helpers/localStorageHelper'

export default {
    getKeywordsCount() {
        return (
            getCache('keywordsCount') ??
            new Promise((resolve, reject) => {
                ApiService.get('/keywords/count')
                    .then(({ data }) => {
                        setCache('keywordsCount', data, 21600000)
                        resolve(data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        )
    },

    getKeywords(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/keywords`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    getStats(context, type) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/keywords/stats/' + type)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    getAggregate(context, type) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/keywords/stats/keywords-aggregates/' + type)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    upload(context, formData) {
        return new Promise((resolve, reject) => {
            ApiService.post('/admin/keywords/upload', formData)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    count(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/keywords/count`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    }
}
