<template>
    <form>
        <b-field label="Email" class="pb-0 mb-0">
            <b-input v-model="consultant.email" type="email" icon="at" maxlength="255" class="pb-0 mb-0" />
        </b-field>

        <b-field label="Prénom">
            <b-input v-model="consultant.firstname" type="text" icon="user" />
        </b-field>

        <b-field label="Nom">
            <b-input v-model="consultant.lastname" type="text" icon="user" />
        </b-field>

        <b-field label="Mot de passe">
            <b-input v-model="consultant.password" type="password" icon="lock" password-reveal expanded />
            <p class="control">
                <b-button class="button is-primary" @click.prevent="createPassword"> Générer</b-button>
            </p>
        </b-field>

        <div v-show="this.$store.state.consultants.action === 'create'">
            <b-field label="Rôle du collaborateur">
                <b-radio v-model="consultant.type" name="name" native-value="consultant"> Consultant</b-radio>
                <b-radio v-model="consultant.type" name="name" native-value="manager"> Manager</b-radio>
            </b-field>

            <b-field v-if="consultant.type === 'consultant'" label="Attacher le consultant au manager...">
                <SearchManager store @select="option => (consultant.manager_id = option ? option.id : null)" />
            </b-field>

            <b-field label="Budget initial" class="mb-3">
                <b-input
                    v-model="consultant.budget_initial"
                    type="number"
                    step="0.01"
                    icon="euro-sign"
                    expanded
                    placeholder="HT"
                />
                <p class="control">
                    <b-button
                        class="button is-primary"
                        @click.prevent="consultant.budget_initial = Number.parseFloat(me.credit_amount)"
                    >
                        / {{ me.credit_amount | toCurrency }}
                    </b-button>
                </p>
            </b-field>
        </div>
    </form>
</template>

<script>
    import { mapState } from 'vuex'
    import SearchManager from '@/components/Agency/Button/Search/SearchManager'

    export default {
        name: 'CreateConsultantForm',
        components: { SearchManager },
        data() {
            return {
                name: '',
                selected: null
            }
        },
        computed: {
            ...mapState('consultants', {
                consultant: 'newEmployee',
                managers: 'managers'
            }),
            ...mapState('auth', {
                me: state => state.user
            })
        },
        methods: {
            createPassword() {
                const length = 12
                const wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ~!@-#$+abcdefghijklmnopqrstuvwxyz~!@-#$+'

                this.consultant.password = Array.from(crypto.getRandomValues(new Uint32Array(length)))
                    .map(x => wishlist[x % wishlist.length])
                    .join('')
            }
        }
    }
</script>
