<template>
    <span>
        <a href="#" class="rounded button is-light rounded-lg text-decoration-none mr-1" @click.prevent="displayLog">
            <b-icon icon="history" />
        </a>
        <b-modal v-model="modal">
            <template #default="props">
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">Historique des crédits</h4>
                </header>
                <div class="box">
                    <b-loading v-if="loading" v-model="loading" :is-full-page="false" />
                    <div v-else>
                        <div class="modal-body">
                            <b-table
                                :sticky-header="true"
                                :data="logs"
                                :mobile-cards="true"
                                paginated
                                :striped="true"
                                :loading="loading"
                                :per-page="15"
                                default-sort="created_at"
                                default-sort-direction="desc"
                            >
                                <b-table-column field="created_at" sortable label="Date">
                                    <template v-slot="props">
                                        {{ props.row[props.column.field] }}
                                    </template>
                                </b-table-column>
                                <b-table-column field="log" sortable label="Log">
                                    <template v-slot="props">
                                        <span v-html="props.row[props.column.field]" />
                                    </template>
                                </b-table-column>
                                <b-table-column field="montant" sortable label="Montant">
                                    <template v-slot="props">
                                        {{ props.row[props.column.field] | currency }}
                                    </template>
                                </b-table-column>
                            </b-table>
                        </div>
                        <div class="modal-footer">
                            <b-button type="button" class="btn btn-primary" @click="props.close">Ok</b-button>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
    </span>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        props: {
            consultant: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            modal: false,
            loading: false,
            logs: []
        }),
        methods: {
            ...mapActions('consultants', {
                getCreditLogForConsultant: 'getCreditsLog',
                disabledConsultant: 'disabled'
            }),
            displayLog() {
                this.modal = true
                this.loading = true
                this.getCreditLogForConsultant(this.consultant.id).then(({ credits }) => {
                    this.logs = credits
                    this.loading = false
                })
            }
        }
    }
</script>

<style></style>
