<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">Ajouter</h4>
                </header>
                <div class="card-content">
                    <div class="content">
                        <form enctype="multipart/form-data">
                            <div class="form-group" style="position: relative">
                                <b-loading :is-full-page="false" v-model="isPartnersLoading"></b-loading>
                                <label class="label" for="partner">{{ $t('group-site.modal.partner') }}</label>
                                <select2 id="partner" v-model="formData.partner" :options="options" class="w-100" />
                            </div>

                            <div class="form-group">
                                <label class="label" for="urls">{{ $t('group-site.modal.urls') }}</label>
                                <textarea id="urls" v-model="formData.urls" class="form-control" cols="30" rows="10" />
                            </div>

                            <div class="form-group">
                                <label class="label" for="lang">{{ $t('group-site.modal.lang') }}</label>
                                <select id="lang" v-model="formData.lang" class="form-control" name="">
                                    <option value="fr_fr">Français</option>
                                    <option value="com_en">Anglais</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label class="label" for="group_id">{{ $t('group-site.modal.group') }}</label>
                                <select
                                    id="group_id"
                                    v-model="formData.group_id"
                                    name="group_id"
                                    class="form-control"
                                    autocomplete="off"
                                >
                                    <option v-for="group in groups" :key="group.id" :value="group.id">
                                        <div v-if="group.parent_id">
                                            {{ group.name }}
                                        </div>

                                        <div v-else>
                                            {{ group.name }}
                                        </div>
                                    </option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label class="label" for="type">{{ $t('group-site.modal.type') }}</label>
                                <select
                                    id="type"
                                    v-model="formData.site_type"
                                    name="type"
                                    class="form-control"
                                    autocomplete="off"
                                >
                                    <option value="bronze">Site Bronze</option>
                                    <option value="argent">Site Argent</option>
                                    <option value="or">Site Or</option>
                                    <option value="vipbronze">Site VIP Bronze</option>
                                    <option value="vipargent">Site VIP Argent</option>
                                    <option value="vipor">Site VIP Or</option>
                                    <option value="media">Média</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label class="label" for="redaction">{{ $t('group-site.modal.redaction') }}</label>
                                <select id="redaction" v-model="formData.redaction" class="form-control">
                                    <option value="proprietaire">Partenaire</option>
                                    <option value="nextlevel">NextLevel</option>
                                </select>
                            </div>

                            <div class="buttons justify-content-end">
                                <button class="button is-light" @click.prevent="closeAndResetModal">
                                    {{ $t('global.cancel') }}
                                </button>
                                <button class="button is-warning" @click.prevent="saveSite">
                                    {{ $t('global.add') }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import Select2 from 'v-select2-component'
    import { mapActions } from 'vuex'

    export default {
        name: 'GroupSiteModal',
        components: { Select2 },
        props: ['groups'],
        data() {
            return {
                modalIsOpen: false,
                formData: {},
                partners: [],
                isPartnersLoading: true
            }
        },
        computed: {
            options() {
                return this.partners.map(i => {
                    return {
                        id: i.id,
                        text: i.user
                    }
                })
            }
        },

        created() {
            this.initForm()
        },

        methods: {
            ...mapActions('sites', {
                getPartners: 'getPartners'
            }),
            async getPartnerList() {
                await this.getPartners().then(data => {
                    this.partners = data
                    this.isPartnersLoading = false
                })
            },
            saveSite() {
                this.$emit('saveSite', this.formData)
                this.closeAndResetModal()
            },

            openModal() {
                this.getPartnerList()
                this.modalIsOpen = true
            },

            closeAndResetModal() {
                this.modalIsOpen = false
                this.initForm()
            },
            initForm() {
                this.formData = {
                    urls: '',
                    lang: 'fr_fr',
                    group_id: 1,
                    site_type: 'bronze',
                    redaction: 'nextlevel'
                }
            }
        }
    }
</script>
