<template>
    <section>
        <b-loading v-model="isLoading" :is-full-page="false" />

        <div class="d-flex mb-2 justify-content-between">
            <div class="d-flex">
                <b-button type="is-warning" class="mb-2 mb-md-0 ml-md-2 d-flex" @click="() => (selectSiteModal = true)">
                    <i class="far fa-thumbs-up mr-2" />Sites dispo.
                </b-button>
                <b-button type="is-warning" class="mb-2 mb-md-0 ml-md-2 d-flex" @click="() => (addKWModal = true)">
                    <i class="fas fa-plus-square mr-2" />Ajouter des MC
                </b-button>
                <b-button type="is-warning" class="mb-2 mb-md-0 ml-md-2 d-flex" @click="() => (settingsModal = true)">
                    <i class="fas fa-cogs mr-2" />Réglages
                </b-button>
            </div>
            <b-taglist class="justify-self-right" attached>
                <b-tag type="is-light"> Crédits Whitepage restants</b-tag>
                <b-tag type="is-warning">
                    {{ whitepageCreditsRemaining }}
                </b-tag>
            </b-taglist>
        </div>

        <CampaignPreOrdersTable
            v-model="activeTab"
            :pre-orders-list="preorders"
            :sites="sites"
            :groups="groups"
            :campaign-pre-orders-sites="campaignPreOrdersSites"
            @loadAsyncData="loadAsyncData"
            @updatePreOrder="updatePreOrder"
            @bulkSetPartnerAvailability="bulkSetPartnerAvailability"
            @launchPreOrders="launchPreOrders"
            @deletePreOrders="deletePreOrders"
            @duplicatePreOrders="duplicatePreOrders"
            @setWhitePagePreOrders="setWhitePagePreOrders"
            @setAIPreOrders="setAIPreOrders"
            @setRedactorPreOrders="setRedactorPreOrders"
        />
        <nl-modal
            title="Sélectionner un site"
            :open-modal="selectSiteModal"
            size="xl"
            @hidden="selectSiteModal = false"
        >
            <AvailableSitesByUser v-if="activeTab === 4" :groups="groups" />
        </nl-modal>
        <CampaignPreOrdersKWModal
            :active-modal="addKWModal"
            :settings="settings"
            @fetchData="fetchData"
            @closeModal="addKWModal = false"
        />
        <nl-modal
            title="Réglages des précommandes"
            :open-modal="settingsModal"
            size="m"
            @hidden="settingsModal = false"
        >
            <CampaignsSettingsModal
                :settings="settings"
                @closeModal="settingsModal = false"
                @updateSettings="updateSettings"
            />
        </nl-modal>
    </section>
</template>

<script>
    export default {
        name: 'CampaignFollowPreOrdersTab',
        props: {
            activeTab: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                isLoading: false,
                preorders: {},
                groups: [],
                sites: [],
                campaignId: null,
                campaignPreOrdersSites: {},
                whitepageCreditsRemaining: 0,
                selectSiteModal: false,
                addKWModal: false,
                settingsModal: false,
                settings: {
                    id: 0,
                    articles_count: 0,
                    brief: '',
                    words_count: 0,
                    lang: 'fr'
                }
            }
        },

        watch: {
            activeTab(newValue, oldValue) {
                if (newValue === 4 && oldValue !== 4) {
                    this.fetchData()
                }
            }
        },

        created() {
            this.fetchSettings()
        },

        methods: {
            fetchData() {
                this.isLoading = true

                this.axios
                    .get(`/campaigns/preorders/${this.$route.query.id}`)
                    .then(response => {
                        this.preorders = response.data.preorders
                        this.sites = response.data.sites
                        this.groups = response.data.groups
                        this.campaignPreOrdersSites = response.data.campaign_preorders_sites
                        this.whitepageCreditsRemaining = response.data.whitepage_remaining_credits
                        this.formatPreOrders()
                        this.fetchSettings()
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.isLoading = false))
            },

            fetchSettings() {
                this.isLoading = true

                this.axios
                    .get(`/partner-preorders/settings/${this.$route.query.id}`)
                    .then(response => {
                        if (response.data.settings) {
                            this.settings = response.data.settings
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => (this.isLoading = false))
            },

            updateSettings(settings) {
                this.isLoading = true
                let params = {
                    campaign_id: this.$route.query.id,
                    lang: settings.settings.lang,
                    brief_count: settings.settings.brief_count,
                    articles_count: settings.settings.articles_count,
                    brief: settings.settings.brief
                }
                if (settings.settings.id !== 0) {
                    params.id = settings.settings.id
                }
                this.axios
                    .post(`/partner-preorders/settings`, params)
                    .then(response => {
                        this.settings = response.data.settings
                        this.snackNotif('Les paramètres on été mis à jour !', 'is-success')
                    })
                    .catch(error => {
                        console.log(error)
                        this.snackNotif("Une erreur s'est produite !", 'is-danger')
                    })
                    .finally(() => {
                        settings.callback()
                        this.isLoading = false
                    })
            },

            launchPreOrders(preOrders) {
                const cannotBeLaunch = []

                if (this.confirmModal) {
                    this.confirmModal = false
                }

                preOrders.forEach(preOrder => {
                    if (!this.canBeLaunched(preOrder)) {
                        cannotBeLaunch.push(preOrder)
                    }
                })

                if (cannotBeLaunch.length) {
                    this.$buefy.snackbar.open({
                        message: `Vous ne pouvez pas lancer la précommande. Il est nécessaire d'enregistrer un site, d'indiquer par quel moyen passer la précommande, et de remplir les champs "H1" et "Meta Title".`,
                        position: 'is-bottom',
                        type: 'is-danger',
                        duration: 4500
                    })

                    return
                }

                this.isLoading = true

                this.axios
                    .post(`/campaigns/preorders/launch/${this.$route.query.id}`, {
                        _method: 'PATCH',
                        ids: preOrders.map(preOrder => preOrder.id)
                    })
                    .then(() => {
                        preOrders.forEach(preOrder => {
                            preOrder.launched_at = 1
                            preOrder.status = preOrder.type === 'textbulker' ? 'ai_in_progress' : 'in_progress'
                        })

                        this.snackNotif('Précommande(s) lancée(s).', 'is-success')
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        this.isLoading = false
                    })
            },

            bulkSetPartnerAvailability(params) {
                this.isLoading = true

                this.axios
                    .post(`/campaigns/preorders/partners/${this.$route.query.id}`, {
                        ids: params.ids,
                        partner_availability: params.partner_availability,
                        _method: 'PATCH'
                    })
                    .then(() => {
                        this.preorders.data = this.preorders.data.map(preorder => {
                            if (params.ids.includes(preorder.id)) {
                                preorder.partner_availability = params.partner_availability
                            }

                            return preorder
                        })

                        this.snackNotif('Précommande(s) mise(s) à jour.', 'is-success')
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.isLoading = false))
            },

            updatePreOrder(preOrder) {
                this.isLoading = true

                this.axios
                    .post(`/campaigns/preorders/${this.$route.query.id}/${preOrder.id}`, {
                        site_id: preOrder.site_id,
                        group_id: preOrder.group_id,
                        partner_availability: preOrder.partner_availability,
                        tag_title: preOrder.tag_title,
                        meta_title: preOrder.meta_title,
                        type: preOrder.type,
                        _method: 'PATCH'
                    })
                    .then(() => {
                        this.formatPreOrders()

                        this.snackNotif('Précommande(s) mise(s) à jour.', 'is-success')
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.isLoading = false))
            },

            deletePreOrders(preOrders) {
                this.isLoading = true

                this.axios
                    .post(`/campaigns/preorders`, {
                        _method: 'DELETE',
                        ids: preOrders.map(row => row.id)
                    })
                    .then(() => {
                        this.preorders.data = this.preorders.data.filter(preOrder => !preOrders.includes(preOrder))

                        this.formatPreOrders()

                        this.snackNotif('Précommande(s) supprimée(s).', 'is-success')
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.isLoading = false))
            },

            setWhitePagePreOrders(preOrders) {
                this.isLoading = true

                this.axios
                    .post(`/campaigns/preorders/whitepage`, {
                        _method: 'PATCH',
                        ids: preOrders.map(row => row.id)
                    })
                    .then(response => {
                        this.preorders.data = this.preorders.data.map(preorder => {
                            var ids = response.data.preorders.map(row => row.id)
                            if (ids.includes(preorder.id)) {
                                preorder = response.data.preorders[ids.indexOf(preorder.id)]
                            }

                            return preorder
                        })
                        this.formatPreOrders()

                        this.snackNotif('WhitePage ajoutée(s).', 'is-success')
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.isLoading = false))
            },

            setAIPreOrders(preOrders) {
                this.isLoading = true

                this.axios
                    .post(`/campaigns/preorders/ai`, {
                        _method: 'PATCH',
                        ids: preOrders.map(row => row.id)
                    })
                    .then(response => {
                        this.preorders.data = this.preorders.data.map(preorder => {
                            var ids = response.data.preorders.map(row => row.id)
                            if (ids.includes(preorder.id)) {
                                preorder = response.data.preorders[ids.indexOf(preorder.id)]
                            }

                            return preorder
                        })
                        this.formatPreOrders()

                        this.snackNotif('TextBulker ajouté(s).', 'is-success')
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.isLoading = false))
            },

            setRedactorPreOrders(preOrders) {
                this.isLoading = true

                this.axios
                    .post(`/campaigns/preorders/redactor`, {
                        _method: 'PATCH',
                        ids: preOrders.map(row => row.id)
                    })
                    .then(response => {
                        this.preorders.data = this.preorders.data.map(preorder => {
                            var ids = response.data.preorders.map(row => row.id)
                            if (ids.includes(preorder.id)) {
                                preorder = response.data.preorders[ids.indexOf(preorder.id)]
                            }

                            return preorder
                        })
                        this.formatPreOrders()

                        this.snackNotif('Redactor ajoutée(s).', 'is-success')
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.isLoading = false))
            },

            duplicatePreOrders(preOrders) {
                this.isLoading = true

                this.axios
                    .post(`/campaigns/preorders/duplicate`, {
                        _method: 'PATCH',
                        ids: preOrders.map(row => row.id)
                    })
                    .then(response => {
                        this.preorders.data.unshift(...response.data.preorders)

                        this.formatPreOrders()

                        this.snackNotif('Précommande(s) dupliquée(s).', 'is-success')
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.isLoading = false))
            },

            canBeLaunched(preOrder) {
                if (!preOrder.type) {
                    return false
                }

                if (!preOrder.meta_title) {
                    return false
                }

                if (!preOrder.tag_title) {
                    return false
                }

                if (!preOrder.site_id) {
                    return false
                }

                if (preOrder.launched_at === 1) {
                    return false
                }

                return true
            },

            formatPreOrders() {
                this.preorders.data = this.preorders.data.map(preOrder => {
                    preOrder.launched_at = preOrder.launched_at ? 1 : 0
                    preOrder.partner_availability = preOrder.partner_availability ? true : false

                    return preOrder
                })
            },

            loadAsyncData(data) {
                var field = data.sortField
                var route

                if (data.sortOrder === 'desc') {
                    field = '-' + data.sortField
                }

                var params = {
                    page: data.page,
                    include: data.includes,
                    sort: field
                }

                if (data.filters && data.filters.length > 0) {
                    data.filters.forEach(element => {
                        params[`filter[${element.key}]`] = element.value
                    })
                }

                route = data.path

                let perPage = data.per_page ? data.per_page : 20
                perPage = route.includes('status') ? `&per_page=${perPage}` : `?per_page=${perPage}`

                this.axios
                    .get(route + perPage, {
                        params
                    })
                    .then(response => {
                        this.preorders = response.data.preorders
                    })
                    .catch(error => {
                        this.snackNotif(error.response.data.message, 'is-danger')
                        console.error(error)
                    })
                    .finally(() => {
                        data.callback()
                    })
            }
        }
    }
</script>