<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="close">
            <b-loading v-model="isLoading" :is-full-page="false"/>
            <template v-if="site">
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">
                        Changer le propriétaire d'un site
                    </h4>
                </header>
                <div class="card-content">
                    <div class="content">

                        <h1>{{ site.ndd }}</h1>
                        <b>Propriétaire actuel :</b> {{ site.partenaire.firstname }}
                        {{ site.partenaire.lastname }}
                        <small>({{ site.partenaire.email }})</small>

                        <form class="my-4">
                            <label for="newOwner">Id du nouveau propriétaire :</label>
                            <input type="text" v-model="newOwnerId" id="newOwner" class="input"></input>
                        </form>

                        <div v-if="newOwner">
                            <b>Nouveau propriétaire :</b> {{ newOwner.firstname }} {{ newOwner.lastname }}
                            <small>({{ newOwner.email }})</small>

                            <div>
                                <button class="button is-danger mt-3" @click.prevent="save">
                                    Confirmer le changement de propriétaire
                                </button>
                            </div>
                        </div>

                        <div class="buttons justify-content-end">
                            <button class="button is-warning" @click.prevent="close">
                                {{ $t('global.close') }}
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: 'OwnerChangeModal',
    data() {
        return {
            modalIsOpen: false,
            isLoading: false,
            newOwnerId: null,
            timeout: null,
            index: null,
        }
    },
    watch: {
        newOwnerId: function (newValue) {
            if (this.timeout) {
                clearTimeout(this.timeout)
            }

            this.timeout = setTimeout(this.newOwnerIdLookup, 600)
        }
    },
    computed: {
        site: {
            get() {
                return this.$store.state.sites.ownership.site
            },
            set(value) {
                let ownership = this.$store.state.sites.ownership
                ownership.site = value

                this.$store.commit('sites/setOwnership', ownership)
            }
        },
        newOwner: {
            get() {
                return this.$store.state.sites.ownership.newOwner
            },
            set(value) {
                let ownership = this.$store.state.sites.ownership
                ownership.newOwner = value

                this.$store.commit('sites/setOwnership', ownership)
            }
        }
    },
    methods: {
        ...mapActions('users', {
            getUser: 'get',
        }),
        ...mapActions('sites', ['changeOwnership']),
        close() {
            this.modalIsOpen = false
        },

        openModal(index) {
            this.index = index
            this.modalIsOpen = true

            if (this.newOwner) {
                this.newOwnerId = this.newOwner.id
            }
        },
        newOwnerIdLookup() {
            this.getUser(this.newOwnerId).then((data) => {
                this.newOwner = data
            })
        },
        save() {
            this.isLoading = true

            this.changeOwnership().then((data) => {
                this.$store.dispatch('toast', {
                    type: 'success',
                    title: 'Le changement de propriétaire est effectué'
                })
                data.site.email = this.newOwner.email
                this.$emit('refreshSite', data.site, this.index)
            }).catch((e) => {
                this.$store.dispatch('toastError', e)
            }).finally(() => {
                this.isLoading = false
            })
        }
    },
}
</script>
