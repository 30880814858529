<template>
    <div>
        <b-tooltip :label="editedNote" position="is-right" :active="Boolean(editedNote)" multilined>
            <b-icon
                class="cursor-pointer"
                :type="editedNote ? 'is-info' : 'is-dark'"
                :icon="editedNote ? 'info' : 'pen'"
                @click.native="edit"
            />
        </b-tooltip>
        <nl-modal :openModal="openModal" :title="$t('tableau-keywords.modifynote')" @hide="openModal = false">
            <div class="form-group" style="text-align: left">
                <label>{{ $t('global.note') }} :</label>
                <input v-model="editedNote" class="form-control" name="note" required type="text" />
                <span class="help-block"></span>
            </div>
            <template #footer>
                <button v-if="!isLoading" class="btn btn-secondary" @click="save()">{{ $t('global.update') }}</button>
                <button v-else class="btn btn-secondary" disabled>
                    <i class="fas fa-cog fa-spin"></i> {{ $t('global.loading') }}...
                </button>
            </template>
        </nl-modal>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        props: {
            favorite: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            openModal: false,
            editedNote: null,
            title: null,
            btnText: null,
            isLoading: false
        }),
        created() {
            this.editedNote = this.favorite.note
        },
        methods: {
            ...mapActions('favorites', ['updateFavorite']),
            edit() {
                this.openModal = true
            },
            save() {
                this.isLoading = true
                this.updateFavorite({
                    ...this.favorite,
                    note: this.editedNote
                })
                    .then(() => {
                        this.openModal = false
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
        }
    }
</script>
