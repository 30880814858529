export default {
    setSites(state, data) {
        state.sites = data
    },
    setSite(state, data) {
        state.site = data
    },
    setSelectorInput(state, data) {
        state.selectorInput = data
    }
}
