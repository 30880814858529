<template>
    <b-dropdown aria-role="list" position="is-bottom-left">
        <template #trigger="{ active }">
            <b-button :icon-right="active ? 'caret-up' : 'caret-down'">
                <flag :iso="'en' === locale ? 'us' : 'fr'" />
            </b-button>
        </template>

        <b-dropdown-item aria-role="listitem" @click="$emit('change-locale', 'fr')">
            <flag iso="fr" />
            {{ $t('language.French') }}
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="$emit('change-locale', 'en')">
            <flag iso="us" />
            {{ $t('language.English') }}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
    export default {
        name: 'ChooseLanguage',
        components: {},
        props: {
            locale: {
                type: String,
                default: 'fr'
            },
            btnClass: {
                type: String,
                default: 'btn-secondary'
            }
        },
        data: function () {
            return {}
        },
        created() {},
        mounted() {},
        methods: {}
    }
</script>
