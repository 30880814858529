<template>
    <div class="mb-3">
        <b-field :label="label">
            <b-select v-model="form" :multiple="multiple" expanded>
                <slot />
            </b-select>
        </b-field>
        <b-button
            type="is-primary"
            @click="
                $emit('update', {
                    key: configKey,
                    data: form,
                    type: 'select',
                    multiple: multiple
                })
            "
        >
            Modifier
        </b-button>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'SelectConfig',
        props: ['configKey', 'label', 'multiple'],
        computed: {
            ...mapState('config', {
                config: state => state.config
            })
        },
        data() {
            return {
                form: []
            }
        },
        created() {
            if (this.config[this.configKey][0].value.includes(',') && this.multiple == true) {
                this.form = this.config[this.configKey][0].value.split(',')
            } else if (!this.config[this.configKey][0].value.includes(',') && this.multiple == true) {
                this.form.push(this.config[this.configKey][0].value)
            } else {
                this.form = this.config[this.configKey][0].value
            }
        }
    }
</script>

<style scoped></style>
