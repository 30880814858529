export default {
    setContents(state, data) {
        state.contents = data
    },
    setOffsite(state, data) {
        state.offsite.data = data
    },
    setOffsiteLoading(state, boolean) {
        state.offsite.loading = boolean
    },
    setContentsPerProject(state, data) {
        state.contentsPerProject = data
    },
    setProjects(state, data) {
        state.projects = data
    }
}
