export default {
    setConfigEmail(state, data) {
        state.configEmail = data
    },
    setAdmins(state, data) {
        state.admins = data
    },
    setConfig(state, data) {
        state.config = data
    }
}
