<template>
    <form v-if="target">
        <div class="block mt-2">
            <b-radio v-model="credits.type" name="type" native-value="add" @input="amount = null"> Ajout</b-radio>
            <b-radio v-model="credits.type" name="type" native-value="remove" @input="amount = null"> Retrait</b-radio>
        </div>
        <b-field label="Montant">
            <b-input
                v-model="amount"
                type="number"
                step="0.01"
                icon="euro-sign"
                expanded
                placeholder="Montant en Euro"
            />
            <p class="control">
                <b-button
                    v-if="credits.type === 'add'"
                    class="button is-primary"
                    @click.prevent="amount = Number.parseFloat(me.credit_amount)"
                >
                    / {{ me.credit_amount | toCurrency }}
                </b-button>
                <b-button
                    v-else
                    class="button is-primary"
                    @click.prevent="amount = Number.parseFloat(target.credit_amount)"
                >
                    /
                    {{ target.user ? target.user.credit_amount : target.credit_amount | toCurrency }}
                </b-button>
            </p>
        </b-field>
        <b-field label="Libellé">
            <b-input v-model="credits.label" type="text" icon="tag" placeholder="Facultatif" />
        </b-field>
        <b-field
            :label="
                'Crédit actuel ' + (credits.type === 'remove' ? me.role : target.user ? target.user.role : target.role)
            "
        >
            <b-input v-if="credits.type === 'remove'" type="text" :value="me.credit_amount + '€'" readonly disabled />
            <b-input
                v-else
                type="text"
                :value="target.user ? target.user.credit_amount : target.credit_amount + '€'"
                readonly
                disabled
            />
        </b-field>
    </form>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'CreditForm',
        data() {
            return {}
        },
        computed: {
            ...mapState('consultants', ['target', 'credits']),
            ...mapState('auth', {
                me: state => state.user
            }),
            amount: {
                get() {
                    return this.$store.state.consultants.credits.amount
                },
                set(value) {
                    this.$store.commit('consultants/setCreditsAmount', value)
                }
            }
        },
        methods: {}
    }
</script>

<style scoped></style>
