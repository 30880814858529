<template>
    <fragment>
        <router-link class="nav_link" to="/partner">
            <i class="fas fa-tachometer-alt nav_icon" />
            <span class="nav_name">{{ $t('layout.dashboard') }}</span>
        </router-link>

        <router-link class="nav_link" to="/partner/sites">
            <i class="fas fa-sitemap nav_icon" />
            <span class="nav_name">{{ $t('global.sites') }}</span>
        </router-link>

        <router-link class="nav_link" to="/partner/orders" style="display: flex; gap: 0.5rem; align-items: center">
            <i class="fas fa-shopping-cart nav_icon" />
            <span class="nav_name">{{ $t('global.orders') }} </span>
            <span v-if="pending !== null && pending > 0" class="notifications__count">
                {{ pending }}
            </span>
        </router-link>

<!--        <SidebarDropdown title="RD" to="/rd/buy" icon="fas fa-link">
            <router-link to="/rd/buy" class="sidebar__sublink">
                <span class="nav_name">Acheter des RD</span>
            </router-link>

            <router-link to="/rd/orders" class="sidebar__sublink">
                <span class="nav_name">RD Commandés</span>
            </router-link>
        </SidebarDropdown>-->

        <router-link class="nav_link" to="/partner/needs">
            <i class="fas fa-pen nav_icon" />
            <span class="nav_name">{{ $t('layout.customerneeds') }}</span>
        </router-link>

        <router-link class="nav_link" to="/partner/alerts">
            <i class="fas fa-clock nav_icon" />
            <span class="nav_name">{{ $t('layout.alertscustommerneeds') }}</span>
        </router-link>

<!--        <router-link class="nav_link" to="/partner/sites-sellings">-->
<!--            <i class="fas fa-rocket nav_icon" />-->
<!--            <span class="nav_name">{{ $t('layout.selling-websites') }}</span>-->
<!--        </router-link>-->

        <a
            class="is-warning nav_link bg-warning text-black py-3"
            href="https://www.nextlevel.link/tips"
            target="_blank"
        >
            <i class="fas fa-lightbulb mr-2 nav_icon text-dark" />
            <span class="nav_name text-dark">Tips</span>
        </a>
    </fragment>
</template>

<style scoped>
    .notifications__count {
        top: 0;
        right: 0;
        font-weight: bold;
        background-color: #ffc107 !important;
        color: black;
        border-radius: 26px;
        font-size: 0.75rem;
        height: 20px;
        min-width: 20px;
        max-width: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'PartnerMenu',
        created() {
            this.getPendingOrdersCount()
        },
        methods: {
            ...mapActions('orders', ['getPendingOrdersCount'])
        },
        computed: {
            ...mapState('auth', {
                user: state => state.user
            }),
            ...mapState('orders', {
                pending: state => state.pendingOrdersCount
            })
        }
    }
</script>
