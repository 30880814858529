<template>
    <modal v-model="activeModal" :modal-title="$t('global.addkeyword')" @cancel="closeModal" @save="addKeyword">
        <nl-alert ref="alert" />
        <form>
            <b-field label="Mot-clé">
                <b-input v-model="modalData.keyword" placeholder="Mot-clé" required />
            </b-field>
            <b-field label="Volume" :type="modalData.volume && modalData.volume < 10 ? 'is-danger' : ''">
                <b-numberinput
                    v-model="modalData.volume"
                    placeholder="Volume"
                    :controls="false"
                    custom-class="has-text-left"
                />
                <template v-if="modalData.volume && modalData.volume < 10" #message>
                    Le volume doit être supérieure ou égal à 10
                </template>
            </b-field>
            <b-field label="Langue">
                <nl-select v-model="modalData.lang" :items="langs" />
            </b-field>
            <b-field>
                <b-checkbox v-model="modalData.is_hot"> Priorité</b-checkbox>
            </b-field>
        </form>
    </modal>
</template>

<script>
    import NlAlert from '../../UI/NlAlert.vue'
    import ApiService from '../../../services/api.service'
    import Modal from '../../../components/UI/Modal/Modal'

    export default {
        components: {
            NlAlert,
            Modal
        },
        props: {
            activeModal: {
                type: Boolean,
                required: true
            }
        },
        data: () => ({
            keywordsList: null,
            error: null,
            countries: [
                { id: 'fr', text: 'France' },
                { id: 'ch', text: 'Suisse' }
            ],
            langs: [
                { id: 'fr_fr', text: 'Français' },
                { id: 'en_en', text: 'Anglais' }
            ],
            modalData: {
                keyword: null,
                volume: null,
                lang: 'fr_fr',
                is_hot: false
            }
        }),
        methods: {
            addKeyword() {
                if (this.modalData.keyword !== null && this.modalData.lang !== null && this.modalData.is_hot !== null) {
                    ApiService.post('/admin/keywords', this.modalData)
                        .then(response => {
                            this.$emit('saved')
                            let toastParams = {
                                message: response.data.message,
                                type: 'is-success'
                            }
                            this.$buefy.toast.open(toastParams)
                            this.closeModal()
                        })
                        .catch(({ response }) => {
                            if (response.status === 422) {
                                this.$refs.alert.showValidationError(response.data)
                            } else {
                                this.$refs.alert.displayMsg('danger', response)
                            }
                        })
                }
            },
            resetData() {
                this.modalData = {
                    keyword: null,
                    volume: null,
                    lang: 'fr_fr',
                    is_hot: false
                }
            },
            closeModal() {
                this.resetData()
                this.$emit('closeModal')
            }
        }
    }
</script>

<style></style>
