<template>
    <div>
        <h5 class="card-title mb-3">{{ $t('redactor.select-reason') }} :</h5>
        <form class="p-3">
            <div v-for="reason in refusalReasons" :key="reason.id" class="form-check mb-3">
                <input
                    :id="reason.id"
                    v-model="decline.type"
                    class="form-check-input"
                    type="radio"
                    name="refusalReasons"
                    :value="reason.id"
                />

                <label class="form-check-label" :for="reason.id">
                    {{ reason.text }}
                </label>
            </div>
        </form>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        data() {
            return {
                refusalReasons: [
                    { id: 'dont-want', text: this.$t('redactor.dont-want') },
                    { id: 'dont-know', text: this.$t('redactor.dont-know') },
                    {
                        id: 'error',
                        text: 'Il y a un problème avec la commande (brief, pages, liens, ...)'
                    },
                    { id: 'other', text: this.$t('redactor.other') }
                ]
            }
        },

        computed: {
            ...mapState('redactors', ['decline'])
        }
    }
</script>
