<template>
    <div v-if="userAttestations" class="user-attestations">
        <b-table
            v-if="!openModal && !openModalUrssaf && !openModalRefuse"
            :sticky-header="true"
            :data="userAttestations"
            :striped="true"
        >
            <b-table-column field="type" label="Type">
                <div slot="default" slot-scope="{ row }">
                    {{ row.type }}
                </div>
            </b-table-column>
            <b-table-column field="created_at" label="Date d'upload">
                <div slot="default" slot-scope="{ row }">
                    {{ row.created_at | date }}
                </div>
            </b-table-column>
            <b-table-column field="status" label="Status">
                <div slot="default" slot-scope="{ row }">
                    <span
                        class="badge"
                        :class="{
                            'badge-darkorange': row.status == 'new',
                            'badge-success': row.status === 'validate',
                            'badge-danger': row.status === 'refused'
                        }"
                    >
                        {{ getAttestationStatus(row.status) }}
                    </span>
                </div>
            </b-table-column>
            <b-table-column field="expiration" label="Expiration">
                <div v-if="row.expiration" slot="default" slot-scope="{ row }">
                    {{ row.expiration | date }}
                </div>
            </b-table-column>
            <b-table-column field="download" label="Download">
                <div slot="default" slot-scope="{ row }">
                    <button class="btn btn-primary" @click="download(row)">Download</button>
                </div>
            </b-table-column>
            <b-table-column field="actions" label="Actions">
                <div slot="default" slot-scope="{ row }">
                    <button v-if="row.status == 'new'" class="btn btn-success" @click="startValidation(row)">
                        {{ $t('partnerAdmin.attestation.actions.validate') }}
                    </button>
                    <button
                        v-if="row.status == 'new' && row.type == 'urssaf'"
                        class="btn btn-success"
                        @click="startValidationUrssaf(row)"
                    >
                        URSSAF
                    </button>
                    <button v-if="row.status == 'new'" class="btn btn-warning" @click="startRefuse(row)">
                        {{ $t('partnerAdmin.attestation.actions.refuse') }}
                    </button>
                    <button v-if="row.status != 'validate'" class="btn btn-danger" @click="deleteItem(row)">
                        {{ $t('partnerAdmin.attestation.actions.delete') }}
                    </button>
                </div>
            </b-table-column>
        </b-table>
        <div v-if="openModal" class="mini-modal">
            <v-form @submit="markAsValidate">
                <div class="row">
                    <div class="col">
                        <v-textfield
                            v-model="expiration"
                            :label="$t('partnerAdmin.attestation.validatePopup.label')"
                            name="expiration"
                            type="date"
                            rules="required"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col text-right">
                        <button class="btn btn-success">
                            {{ $t('partnerAdmin.attestation.actions.validate') }}
                        </button>
                        <button class="btn btn-primary" @click.prevent="closeMiniModal">
                            {{ $t('partnerAdmin.attestation.actions.cancel') }}
                        </button>
                    </div>
                </div>
            </v-form>
        </div>
        <div v-if="openModalUrssaf" class="mini-modal">
            <v-form @submit="validateUrssaf">
                <nl-alert ref="ursaffAlert" />
                <div class="row">
                    <div class="col">
                        <v-textfield
                            v-model="code"
                            :label="$t('partnerAdmin.attestation.urssafPopup.label')"
                            name="code"
                            rules="required"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col text-right">
                        <button class="btn btn-success">
                            {{ $t('partnerAdmin.attestation.actions.validate') }}
                        </button>
                        <button class="btn btn-primary" @click.prevent="closeMiniModal">
                            {{ $t('partnerAdmin.attestation.actions.cancel') }}
                        </button>
                    </div>
                </div>
            </v-form>
        </div>
        <div v-if="openModalRefuse" title="Refuser l'attesatation" class="mini-modal">
            <v-form @submit="refuseAttestation">
                <div class="row">
                    <div class="col">
                        <nl-select
                            v-model="reason"
                            :name="$t('partnerAdmin.attestation.refusePopup.label')"
                            :items="reasons"
                            :label="$t('partnerAdmin.attestation.refusePopup.label')"
                        />
                        <v-textfield
                            v-if="reason === 'other'"
                            v-model="comment"
                            :name="$t('partnerAdmin.attestation.refusePopup.label')"
                            :label="$t('partnerAdmin.attestation.refusePopup.comment')"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col text-right">
                        <button class="btn btn-success">
                            {{ $t('partnerAdmin.attestation.actions.validate') }}
                        </button>
                        <button class="btn btn-primary" @click.prevent="closeMiniModal">
                            {{ $t('partnerAdmin.attestation.actions.cancel') }}
                        </button>
                    </div>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import { mapActions } from 'vuex'
    import Download from '@/utils/download'
    import NlAlert from '../../UI/NlAlert.vue'

    export default {
        components: { NlAlert },
        filters: {
            date(value) {
                return moment(value).format('DD-MM-YYYY')
            }
        },
        props: {
            userAttestations: {
                type: Array
            },
            user: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            openModal: false,
            openModalUrssaf: false,
            openModalRefuse: false,
            toValidate: null,
            expiration: null,
            code: null,
            reason: null,
            comment: null
        }),
        computed: {
            reasons() {
                return [
                    {
                        id: 'olderThan6Month',
                        text: this.$t('partnerAdmin.attestation.refuseReasons.olderThan6Month')
                    },
                    {
                        id: 'impossibleToValidate',
                        text: this.$t('partnerAdmin.attestation.refuseReasons.impossibleToValidate')
                    },
                    {
                        id: 'cantRead',
                        text: this.$t('partnerAdmin.attestation.refuseReasons.cantRead')
                    },
                    {
                        id: 'urssafMandatory',
                        text: this.$t('partnerAdmin.attestation.refuseReasons.urssafMandatory')
                    },
                    {
                        id: 'other',
                        text: this.$t('partnerAdmin.attestation.refuseReasons.other')
                    }
                ]
            },
            attestationStatus() {
                return {
                    new: this.$t('partnerAdmin.attestation.status.new'),
                    validate: this.$t('partnerAdmin.attestation.status.validate'),
                    refused: this.$t('partnerAdmin.attestation.status.refused'),
                    expired: this.$t('partnerAdmin.attestation.status.expired')
                }
            }
        },
        methods: {
            ...mapActions('user_attestations', {
                listAttestations: 'list',
                updateAttestation: 'update',
                downloadAttestation: 'download',
                deleteAttestation: 'delete',
                validateUrssafAttestation: 'validateUrssaf'
            }),
            download(attestation) {
                this.downloadAttestation(attestation.id).then(response => {
                    Download.download(response, this.user.company)
                })
            },
            startValidation(attestation) {
                this.toValidate = attestation
                this.openModal = true
            },
            startValidationUrssaf(attestation) {
                this.toValidate = attestation
                this.openModalUrssaf = true
            },
            startRefuse(attestation) {
                this.toValidate = attestation
                this.openModalRefuse = true
            },
            closeMiniModal() {
                this.toValidate = null
                this.code = null
                this.reason = null
                this.comment = null
                this.openModal = false
                this.openModalUrssaf = false
                this.openModalRefuse = false
            },
            markAsValidate() {
                this.updateAttestation({
                    id: this.toValidate.id,
                    status: 'validate',
                    expiration: this.expiration
                }).then(data => {
                    this.closeMiniModal()
                    this.$emit('refresh')
                })
            },
            deleteItem(item) {
                this.deleteAttestation(item).then(data => {
                    this.$emit('refresh')
                })
            },
            validateUrssaf(item) {
                this.validateUrssafAttestation({
                    id: this.toValidate.id,
                    code: this.code
                })
                    .then(data => {
                        this.closeMiniModal()
                        this.$emit('refresh')
                    })
                    .catch(({ data }) => {
                        this.$refs.ursaffAlert.displayMsg('danger', [data.message])
                    })
            },
            refuseAttestation(item) {
                this.updateAttestation({
                    id: this.toValidate.id,
                    status: 'refused',
                    reason: this.reason,
                    comment: this.comment
                }).then(data => {
                    this.closeMiniModal()
                    this.$emit('refresh')
                })
            },
            getAttestationStatus(status) {
                return this.attestationStatus[status]
            }
        }
    }
</script>

<style lang="css" scoped>
    .user-attestations {
        position: relative;
    }

    .user-attestations .mini-modal {
        background-color: white;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
    }
</style>
