<template>
    <b-button type="is-danger" title="Delete Record" @click.native="triggerPopup">
        <b-icon icon="trash" />
    </b-button>
</template>

<script>
    export default {
        name: 'DeleteButton',
        components: {},
        props: {
            classes: {
                type: String,
                required: false,
                default: 'btn btn-sm btn-icon btn-outline-danger rounded-circle mr-1',
                description: 'Customize the btn'
            },
            messageBeforeIcon: {
                type: String,
                required: false,
                default: '',
                description: 'Show a label before the icon'
            },
            messageAfterIcon: {
                type: String,
                required: false,
                default: '',
                description: 'Show a label after the icon'
            }
        },
        data: function () {
            return {
                swalPopup: null
            }
        },
        created() {
            this.swalPopup = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-primary mr-2'
                },
                buttonsStyling: false
            })
        },
        mounted() {},
        methods: {
            triggerPopup: function () {
                var that = this

                this.swalPopup
                    .fire({
                        title: this.$t('global.deleteConfirmation'),
                        text: this.$t('global.deleteConfirmationDesc'),
                        showLoaderOnConfirm: true,
                        showCancelButton: true,
                        confirmButtonText: this.$t('global.deleteYes'),
                        cancelButtonText: this.$t('global.cancel'),
                        reverseButtons: true
                    })
                    .then(function (result) {
                        if (result.value) {
                            that.$emit('delete')
                        }
                    })
            },
            showConfirmation: function () {
                this.$swal.fire('Supprimé !', '', 'success')
            }
        }
    }
</script>
