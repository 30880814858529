<template>
    <div>
        <AssignAdminModal
            ref="assignModal"
            :unselectable-users="this.users.map(e => e.id)"
            @admin:selected="addAdmin"
        />

        <div id="user-configs">
            <ConfigMailHeader :number="users.length" :label="label" :loader="loader" />

            <div class="columns is-flex-direction-column">
                <div v-show="showTrash" class="column is-full trash" @drop="dropHandler" @dragover="dragoverHandler">
                    <i class="fas fa-trash-alt mr-4" /> Supprimer
                </div>

                <div
                    v-for="(user, index) in users"
                    :key="`${configKey}-${user.id}`"
                    class="column is-full"
                    draggable="true"
                    @dragstart="startDrag(index, $event)"
                    @dragend="dragEndHandler"
                >
                    <div class="card">
                        <div class="card-content">
                            <div class="media">
                                <div class="media-left">
                                    <button class="button is-rounded btn-lg">
                                        <i class="fas fa-user" style="font-size: 1.3em" />
                                    </button>
                                </div>
                                <div class="media-content">
                                    <p class="title is-5">{{ user.firstname }} {{ user.lastname }}</p>
                                    <p class="subtitle is-6">
                                        {{ user.email }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="showTrash" class="column is-full trash" @drop="dropHandler" @dragover="dragoverHandler">
                <i class="fas fa-trash-alt mr-4" /> Supprimer
            </div>

            <div class="column add is-one-fifth" @click.prevent="prepareAdd">
                <i class="fas fa-plus" />
            </div>
        </div>
    </div>
</template>

<script>
    import ConfigMailHeader from '../../Config/ConfigMailHeader'
    import ConfigMailUserCard from '../../Config/ConfigMailUserCard'
    import { mapActions, mapState } from 'vuex'
    import AssignAdminModal from '../../Config/AssignAdminsModal'

    export default {
        name: 'UserConfig',
        components: { AssignAdminModal, ConfigMailUserCard, ConfigMailHeader },
        props: ['configKey', 'label', 'type'],
        data() {
            return {
                showTrash: false,
                loader: false,
                form: '',
                users: []
            }
        },
        computed: {
            ...mapState('config', {
                config: state => state.config,
                admins: state => state.admins
            })
        },
        created() {
            this.form = this.config[this.configKey][0].value
            this.users = this.admins.filter(user => {
                return this.config[this.configKey][0].value.split(',').indexOf(user.id.toString()) !== -1
            })
        },
        methods: {
            ...mapActions('config', ['saveEmailConfig']),
            addAdmin(admin) {
                this.$refs.assignModal.hide()
                this.users.push(admin)
                let form = this.users.map(user => user.id).join(',')

                this.$emit('update', { key: this.configKey, data: form, type: 'input' })
            },
            prepareAdd() {
                this.loader = true
                this.$refs.assignModal.show()
            },
            startDrag(index, event) {
                event.dataTransfer.setData('index', index)
                this.showTrash = true
            },
            dropHandler(event) {
                var index = event.dataTransfer.getData('index')
                this.users.splice(index, 1)
                let form = this.users.map(user => user.id).join(',')

                this.$emit('update', { key: this.configKey, data: form, type: 'input' })
            },
            dragoverHandler(event) {
                event.preventDefault()
                event.dataTransfer.dropEffect = 'move'
            },
            dragEndHandler() {
                this.showTrash = false
            }
        }
    }
</script>
