<template>
    <div class="card">
        <customer-selector :readonly="readonly" :campaign="campaign" @loading="value => (isLoading = value)" />
        <b-loading v-model="isLoading" :is-full-page="false" />
        <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between p-2">
                <span class="text-muted mr-1"> name </span>
                <span class="ml-auto text-truncate">
                    {{ campaign.customer.firstname }} {{ campaign.customer.lastname }}
                </span>
                <LoginAs
                    :user-id="this.campaign.customer.id"
                    :manager-id="this.campaign.id"
                    :redirect-to="'/admin/campaigns/details?id=' + this.campaign.id"
                    class="ml-1 btn btn-link p-0"
                />
            </li>
            <li class="list-group-item d-flex justify-content-between p-2">
                <span class="text-muted mr-1">email</span>
                <span class="ml-auto text-truncate">
                    <a :href="'mailto:' + campaign.customer.email">
                        {{ campaign.customer.email }}
                    </a>
                </span>
                <nl-clipboard-button :copy="campaign.customer.email" />
            </li>
            <li class="list-group-item d-flex justify-content-between p-2">
                <span class="text-muted mr-1">telephone</span>{{ campaign.customer.telephone }}
            </li>
            <li v-if="campaign.customer.skype" class="list-group-item d-flex justify-content-between p-2">
                <span class="text-muted mr-1">skype</span>{{ campaign.customer.skype }}
            </li>
            <li class="list-group-item d-flex justify-content-between p-2">
                <span class="text-muted mr-1">Crédit</span>{{ campaign.customer.credit_amount }} € HT
            </li>
        </ul>
        <div class="card-body">
            <textarea
                class="form-control"
                :value="this.campaign.information.user_additional_information"
                @change="e => update('user_additional_information', e.target.value)"
            />
        </div>
    </div>
</template>

<script>
    import CustomerSelector from './CustomerSelector'
    import NlClipboardButton from '../UI/NlClipboardButton'
    import Textarea from '../Form/Textarea'
    import { mapActions } from 'vuex'

    export default {
        name: 'Customer',
        components: { Textarea, NlClipboardButton, CustomerSelector },
        props: {
            campaign: {
                type: Object,
                required: true
            },
            readonly: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                showModal: false,
                customerId: null,
                customerAdditionalInformation: null,
                isLoading: false
            }
        },
        methods: {
            ...mapActions('campaigns', ['updateCampaign']),
            update(key, value) {
                this.isLoading = true
                this.updateCampaign({
                    campaignId: this.campaign.id,
                    [key]: value
                }).then(() => (this.isLoading = false))
            },
            setCustomerId: function (id) {
                this.customerId = id
                this.showModal = false
            }
        }
    }
</script>

<style scoped></style>
