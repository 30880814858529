export default {
    setFilterActive(state, isActive) {
        state.filterActive = isActive
    },
    setDefaultSelection(state, hasDefaultSelection) {
        state.defaultSelection = hasDefaultSelection
    },
    setProjects(state, projects) {
        state.projects = projects
    },
    setDefaultProject(state, value) {
        state.defaultProject = value
    }
}
