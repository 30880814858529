<template>
    <div>
        <b-table :data="projects" default-sort-direction="desc" :striped="true" per-page="10" :paginated="true">
            <b-table-column
                field=""
                :label="$t('projets.columns.defaultProject')"
                centered
                width="10%"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <div
                        v-if="defaultUserProject && defaultUserProject.id === props.row.id"
                        class="badge badge-light px-2 py-2 bg-secondary"
                    >
                        {{ $t('projets.columns.defaultProject') }}
                    </div>
                    <div v-else>
                        <div v-show="props.row.status === 1">
                            <button
                                v-show="props.row.id === showSetProjectDefaultId"
                                class="btn btn-sm btn-primary"
                                @click="setProjectDefault(props.row)"
                            >
                                {{ $t('projets.columns.setDefault') }}
                            </button>
                        </div>
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="project_lang" searchable label="Langue" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <flag :iso="'en' === props.row.project_lang ? 'us' : 'fr'" />
                </div>

                <div slot="searchable" slot-scope="props">
                    <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                        <option :value="props.filters[status]">
                            {{ $t('precommande.all') }}
                        </option>
                        <option value="fr">Français</option>
                        <option value="en">English</option>
                    </select>
                </div>
            </b-table-column>
            <b-table-column field="name" searchable label="Nom" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    {{ props.row.name }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Nom..."
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="web" searchable label="Site web" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    {{ props.row.website }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Site web..."
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="filter" label="Liens filtrés" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    {{ props.row.brief }}
                </div>
            </b-table-column>
            <b-table-column field="status" searchable label="Statut" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <div
                        v-if="!defaultUserProject || defaultUserProject.id !== props.row.id"
                        class="custom-control custom-switch"
                    >
                        <input
                            :id="'switch-' + props.row.id"
                            v-model="props.row.status"
                            type="checkbox"
                            class="custom-control-input"
                            @change="updateEntity(props.row)"
                        />

                        <label class="custom-control-label" :for="'switch-' + props.row.id">
                            <span v-if="props.row.status === 1">{{ $t('projets.columns.statusActive') }}</span>
                            <span v-else>{{ $t('projets.columns.statusInactive') }}</span>
                        </label>
                    </div>
                    <div v-else>
                        <span v-if="props.row.status === 1">{{ $t('projets.columns.statusActive') }}</span>
                        <span v-else>{{ $t('projets.columns.statusInactive') }}</span>
                    </div>
                </div>

                <div slot="searchable" slot-scope="props" cell-class="align-middle">
                    <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                        <option :value="props.filters[status]">
                            {{ $t('precommande.all') }}
                        </option>
                        <option value="true">
                            {{ $t('projets.columns.statusActive') }}
                        </option>
                        <option value="false">
                            {{ $t('projets.columns.statusInactive') }}
                        </option>
                    </select>
                </div>
            </b-table-column>
            <b-table-column field="budget" searchable label="Budget" cell-class="align-middle">
                <div slot="default" slot-scope="props">{{ props.row.budget ? 0 : props.row.budget }}€</div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Budget..."
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="email" searchable label="Destinataires email" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    {{ props.row.destinataires_mails }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Destinataires email..."
                        />
                    </div>
                </div>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    export default {
        name: 'UserProjectTab',
        props: {
            tabData: {}
        },
        data() {
            return {
                projects: [],
                defaultUserProject: this.$store.state.project,
                showSetProjectDefaultId: null,
                status: this.$t('precommande.all')
            }
        },
        watch: {
            tabData: {
                handler(newValue) {
                    this.projects = newValue.content.projects
                },
                deep: true
            }
        },
        methods: {
            findItem(entity) {
                return this.projectsList.findIndex(project => project.id === entity.id)
            },
            editEntity(entity) {
                this.$emit('edit', entity)
            },
            deactivateSelected() {
                this.deactivateLoading = true

                this.checkedRows.forEach(element => {
                    element.status = 0
                })

                this.$emit('deactivateSelected', {
                    projects: this.checkedRows,
                    callbackEmit: () => {
                        this.deactivateLoading = false
                    }
                })
            },
            deleteSelected() {
                this.deleteLoading = true

                this.$emit('deleteSelected', {
                    projects: this.checkedRows,
                    callbackEmit: () => {
                        this.checkedRows.forEach(element => {
                            let index = this.findItem(element)

                            this.projectsList.splice(index, 1)
                        })
                    }
                })
            },
            delEntity(entity) {
                let index = this.findItem(entity)

                this.$emit('delete', {
                    project: this.projectsList[index],
                    callbackEmit: () => {
                        this.projectsList.splice(index, 1)
                        this.$refs.delBtn.showConfirmation()
                    }
                })
            },
            updateEntity(data) {
                let project = data

                this.axios.put('/crud/projects/' + project.id, project).then(response => {
                    data.callbackEmit(response.data.project)
                })
            },
            updateProjectDefault(data) {
                let currentUser = this.$store.state.auth.user
                let project = data.project

                currentUser.project_default_id = project.id

                this.axios.put('/crud/users/' + currentUser.id, currentUser).then(({ data }) => {
                    this.$store.commit('auth/setUser', data.user)
                })
            }
        }
    }
</script>
