<template>
    <div class="">
        <nl-table
            async
            :actions="actions"
            :columns.sync="columns"
            :get-data="loadAsyncData"
            :checked-rows.sync="checkedRows"
            :force-update="forceUpdate"
            :right-buttons="[
                {
                    label: 'Export',
                    icon: 'file-excel',
                    action: () => this.$emit('export')
                }
            ]"
        >
            <template #statut="{ props }">
                <TrackingOrderStatus :order="props.row" />
            </template>

            <template #statutSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" :placeholder="props.label" size="is-small">
                    <option :value="null">
                        {{ $t('global.all') }}
                    </option>
                    <option value="a_valider">
                        {{ $t('status.waitingforvalidation') }}
                    </option>
                    <option value="en_cours">
                        {{ $t('status.redaction') }}
                    </option>
                    <option value="termine">
                        {{ $t('status.published') }}
                    </option>
                    <option value="annule">
                        {{ $t('status.canceled') + '/' + $t('status.refused') }}
                    </option>
                </b-select>
            </template>

            <!--            <template #bought_kw="{props}">
                      <span v-if="props.row.bought_kw">{{ props.row.bought_kw }}</span>
                      <b-tooltip :label="$t('tracking_order.cannot-found-history')" v-else>
                          <span class="tag is-light is-danger">
                              <i class="fa fa-exclamation-triangle"></i>
                          </span>
                      </b-tooltip>
                  </template>-->

            <template #projectName="{ props }">
                <span v-if="editProject !== props.row.id" class="is-editable">
                    {{ props.row.project ? props.row.project.name : $t('global.none') }}
                </span>

                <!--        TODO: Faire le changement de projet -->
                <!--        <div v-if="editProject === props.row.id">-->
                <!--          <b-select-->
                <!--            v-model="props.row.project_id"-->
                <!--            @change="moveProject"-->
                <!--            @keydown.esc="editProject = null"-->
                <!--          >-->
                <!--            <option :value="project.id" v-for="project in projects" :key="project.id">-->
                <!--              {{ project.name }}-->
                <!--            </option>-->
                <!--          </b-select>-->
                <!--        </div>-->
            </template>

            <template #project.nameSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" :placeholder="props.label" size="is-small">
                    <option :value="null">
                        {{ $t('global.all') }}
                    </option>
                    <option v-for="project in projects" :key="project.id" :value="project.id">
                        {{ project.name }}
                    </option>
                </b-select>
            </template>

            <template #url="{ props }">
                <span v-if="props.row.statut === 'termine'">{{ props.row.url }}</span>
                <span v-else-if="props.row.statut === 'annule' || props.row.statut === 'refuse'">
                    {{ $t('status.canceled') }}
                </span>
                <span v-else>{{ $t('suivi.availablewhenpublished') }}</span>
            </template>

            <template #link_and_anchor="{ props }">
                <span>
                    {{ props.row.link_anchor }} <br />
                    <a :href="props.row.link_url" target="_blank">
                        {{ props.row.link_url }}
                    </a>
                </span>
                <hr v-if="props.row.link_second_url && props.row.link_second_anchor" />
                <span v-if="props.row.link_second_url && props.row.link_second_anchor">
                    {{ props.row.link_second_anchor }} :
                    <a :href="props.row.link_second_url" target="_blank">
                        {{ props.row.link_second_url }}
                    </a>
                </span>
            </template>

            <template #info_url="{ props }">
                <button
                    v-if="props.row.statut === 'termine'"
                    class="button is-light"
                    @click="openOrderDetailModal(props.row)"
                >
                    {{ $t('suivi.seedatas') }}
                </button>
                <span v-else style="min-width: 120px; display: block">-</span>
            </template>

            <template #brief="{ props }">
                <b-tooltip
                    v-if="props.row.statut !== 'refuse' && props.row.statut !== 'annule'"
                    :label="props.row.brief"
                    :triggers="['click']"
                    :auto-close="['outside', 'escape']"
                    multilined
                >
                    <b-button icon-right="file-alt" type="is-light" />
                </b-tooltip>
                <span v-else style="min-width: 120px; display: block">-</span>
            </template>

            <template #date_publication_prevue="{ props }">
                <p v-if="props.row.statut !== 'refuse' && props.row.statut !== 'annule'" class="mb-0">
                    {{
                        props.row.date_publication_prevue
                            ? moment(props.row.date_publication_prevue).format('DD/MM/YYYY')
                            : 'ASAP'
                    }}
                </p>
            </template>

            <template #created_at="{ props }">
                <span>{{ moment(props.row.created_at).format('DD/MM/YYYY') }}</span>
            </template>

            <template #finished_at="{ props }">
                <span v-if="props.row.finished_at">{{ moment(props.row.finished_at).format('DD/MM/YYYY') }}</span>
                <span v-else>-</span>
            </template>

            <template #price_paid="{ props }">
                <p class="mb-0">
                    {{ props.row.price_paid ? props.row.price_paid + '€' : '-' }}
                </p>
            </template>
        </nl-table>
        <OrderDetailModal ref="orderDetailModal" />
    </div>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable'
    import { mapActions, mapState } from 'vuex'
    import OrderDetailModal from '@/components/Content/OrderDetailModal'
    import TrackingOrderStatus from '@/components/Dashboard/TrackingOrder/TrackingOrderStatus.vue'

    export default {
        name: 'TrackingOrderTable',
        components: {
            OrderDetailModal,
            NlTable,
            TrackingOrderStatus
        },
        props: {
            type: {
                type: String,
                required: true
            },
            status: {
                type: String,
                required: false
            }
        },

        data() {
            return {
                orders: [],
                selectedProject: null,
                editProject: null,

                checkedRows: [],
                columns: [
                    {
                        label: this.$t('global.ref'),
                        field: 'id',
                        centered: true,
                        width: '1rem',
                        sortable: true,
                        searchable: true
                    },
                    {
                        label: this.$t('global.status'),
                        field: 'statut',
                        centered: true,
                        width: '9rem',
                        sortable: false,
                        hide: this.type !== 'all',
                        searchable: true,
                        custom: true,
                        customSearch: true
                    },
                    /*{
            label: this.$t('global.keyword'),
            field: 'bought_kw',
            centered: true,
            sortable: true,
            searchable: true,
            custom: true,
        },*/
                    {
                        label: this.$t('global.project'),
                        field: 'project.name',
                        centered: true,
                        sortable: false,
                        searchable: false,
                        width: '8rem',
                        custom: true,
                        customSearch: true
                    },
                    {
                        label: this.$t('global.page'),
                        field: 'url',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        custom: true
                    },
                    {
                        label: this.$t('links&anchors.link&anchor'),
                        field: 'link_and_anchor',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        width: '8rem',
                        custom: true
                    },
                    {
                        label: this.$t('suivi.url'),
                        field: 'info_url',
                        centered: true,
                        custom: true
                    },
                    {
                        label: this.$t('global.brief'),
                        field: 'brief',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        custom: true,
                        hide: this.type === 'refusedCanceled',
                        visible: false
                    },
                    {
                        label: this.$t('global.price'),
                        field: 'price_paid',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        width: '1rem',
                        custom: true
                    },
                    {
                        label: this.$t('suivi.dateorder'),
                        field: 'created_at',
                        centered: true,
                        sortable: true,
                        custom: true,
                        searchable: true,
                        width: '1rem'
                    },
                    {
                        label: this.$t('suivi.wantedpublication'),
                        field: 'date_publication_prevue',
                        centered: true,
                        custom: true,
                        sortable: true,
                        searchable: true,
                        width: '1rem',
                        type: 'date',
                        visible: false
                    },
                    {
                        label: this.$t('partenaire-commandes.publication_date'),
                        field: 'finished_at',
                        custom: true,
                        centered: true,
                        sortable: true,
                        searchable: true,
                        width: '1rem',
                        type: 'date'
                    }
                ],
                forceUpdate: false
            }
        },
        watch: {
            projectId() {
                this.forceUpdate = !this.forceUpdate
            }
        },
        computed: {
            ...mapState({
                projectId: state => state.project.id
            }),
            ...mapState('project_selector', ['projects']),
            actions() {
                return this.projects.map(project => {
                    return {
                        action: () => this.confirmMoveProject(project),
                        label: `${this.$t('global.moveproject')} ${project.name}`
                    }
                })
            }
        },

        methods: {
            ...mapActions('tracking_order', {
                getTrackingOrders: 'index',
                moveProject: 'move'
            }),

            confirmMoveProject: function (project) {
                this.$buefy.dialog.confirm({
                    title: this.$t('global.move'),
                    message: `${this.$t('suivi.move-orders')} :
          <strong>#${this.checkedRows.map(e => e.id).join(', #')}</strong>
          ${this.$t('suivi.in-project')} <strong>${project.name}</strong>.`,
                    type: 'is-warning',
                    size: 'is-medium',
                    onConfirm: () => {
                        this.moveProject({
                            project_id: project.id,
                            ids: this.checkedRows.map(e => e.id)
                        })
                            .then(() => {
                                this.snackNotif('Commande(s) déplacée(s) avec succès')
                            })
                            .catch(() => {
                                this.snackNotif('Error', 'is-danger')
                            })
                            .finally(() => {
                                this.editProject = null
                                this.forceUpdate = !this.forceUpdate
                            })
                    }
                })
            },

            loadAsyncData(params) {
                return this.getTrackingOrders({
                    projectId: this.projectId,
                    status: this.status,
                    params
                })
            },
            openOrderDetailModal(data) {
                this.$refs.orderDetailModal.showModal(data)
            }
        }
    }
</script>

<style scoped lang="scss">
    $box-padding: 0;
</style>