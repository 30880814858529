<template>
    <div>
        <AgencyMenu />

        <router-link class="nav_link" to="/client">
            <i class="fas fa-tachometer-alt nav_icon" />
            <span class="nav_name">{{ $t('layout.dashboard') }}</span>
        </router-link>

        <SearchMenu />

        <router-link class="nav_link" to="/favorites">
            <i class="far fa-star nav_icon" />
            <span class="nav_name">{{ $t('favorites.title') }}</span>
        </router-link>

        <router-link class="nav_link" to="/alerts">
            <i class="far fa-clock nav_icon" />
            <span class="nav_name">{{ $t('layout.alerts') }}</span>
        </router-link>

        <router-link class="nav_link" to="/projects">
            <i class="far fa-folder nav_icon" />
            <span class="nav_name">{{ $t('projets.title') }}</span>
        </router-link>

        <router-link class="nav_link" to="/client/preorder">
            <i class="fas fa-file-signature nav_icon" />
            <span class="nav_name">
                {{ $t('layout.preorders') }}
            </span>
        </router-link>

        <router-link class="nav_link" to="/campaigns">
            <i class="fas fa-life-ring nav_icon" />
            <span class="nav_name">
                {{ $t('mes-accompagnements.title') }}
            </span>
        </router-link>

<!--        <router-link class="nav_link" to="/partner/sites-sellings">-->
<!--            <i class="fas fa-rocket nav_icon" />-->
<!--            <span class="nav_name">{{ $t('layout.selling-websites') }}</span>-->
<!--        </router-link>-->

        <router-link
            v-if="!['partenaire', 'agence', 'manager', 'consultant', 'admin'].includes(user.role)"
            class="is-warning nav_link bg-warning text-black py-3"
            to="/become-partner"
            target="_blank"
        >
            <i class="fas fa-handshake nav_icon text-dark" />
            <span class="nav_name text-dark">
                {{ $t('devenir-partenaire.title') }}
            </span>
        </router-link>
    </div>
</template>
<script>
    import { mapState } from 'vuex'

    export default {
        name: 'CustomerMenu',
        data() {
            return {
                isChecked: false
            }
        },
        computed: {
            ...mapState('auth', {
                user: state => state.user
            }),
            isMenuOpen: {
                get() {
                    return this.$store.state.isMenuOpen
                }
            },
            isPartnerMenuOpen: {
                get() {
                    return this.$store.state.isPartnerMenuOpen
                },
                set(value) {
                    this.$store.commit('setIsPartnerMenuOpen', value)
                }
            }
        },
        methods: {
            handleChange() {
                this.isPartnerMenuOpen = !this.isPartnerMenuOpen
            },
            checkRole(role) {
                if (this.user.role === 'admin') {
                    return true
                }

                return this.user.role === role
            }
        }
    }
</script>
