const countryList = {
    fr: [
        { text: 'Afghanistan', id: 'AF' },
        { text: 'Afrique du Sud', id: 'ZA' },
        { text: 'Albanie', id: 'AL' },
        { text: 'Algérie', id: 'DZ' },
        { text: 'Allemagne', id: 'DE' },
        { text: 'Andorre', id: 'AD' },
        { text: 'Angola', id: 'AO' },
        { text: 'Antigua-et-Barbuda', id: 'AG' },
        { text: 'Arabie saoudite', id: 'SA' },
        { text: 'Argentine', id: 'AR' },
        { text: 'Arménie', id: 'AM' },
        { text: 'Australie', id: 'AU' },
        { text: 'Autriche', id: 'AT' },
        { text: 'Azerbaïdjan', id: 'AZ' },
        { text: 'Bahamas', id: 'BS' },
        { text: 'Bahreïn', id: 'BH' },
        { text: 'Bangladesh', id: 'BD' },
        { text: 'Barbade', id: 'BB' },
        { text: 'Biélorussie', id: 'BY' },
        { text: 'Belgique', id: 'BE' },
        { text: 'Belize', id: 'BZ' },
        { text: 'Bénin', id: 'BJ' },
        { text: 'Bhoutan', id: 'BT' },
        { text: 'Bolivie', id: 'BO' },
        { text: 'Bosnie-Herzégovine', id: 'BA' },
        { text: 'Botswana', id: 'BW' },
        { text: 'Brésil', id: 'BR' },
        { text: 'Brunei', id: 'BN' },
        { text: 'Bulgarie', id: 'BG' },
        { text: 'Burkina Faso', id: 'BF' },
        { text: 'Burundi', id: 'BI' },
        { text: 'Cambodge', id: 'KH' },
        { text: 'Cameroun', id: 'CM' },
        { text: 'Canada', id: 'CA' },
        { text: 'Cap-Vert', id: 'CV' },
        { text: 'République centrafricaine', id: 'CF' },
        { text: 'Chili', id: 'CL' },
        { text: 'Chine', id: 'CN' },
        { text: 'Chypre (pays)', id: 'CY' },
        { text: 'Colombie', id: 'CO' },
        { text: 'Comores (pays)', id: 'KM' },
        { text: 'République du Congo', id: 'CG' },
        { text: 'République démocratique du Congo', id: 'CD' },
        { text: 'Corée du Sud', id: 'KR' },
        { text: 'Corée du Nord', id: 'KP' },
        { text: 'Costa Rica', id: 'CR' },
        { text: "Côte d'Ivoire", id: 'CI' },
        { text: 'Croatie', id: 'HR' },
        { text: 'Cuba', id: 'CU' },
        { text: 'Danemark', id: 'DK' },
        { text: 'Djibouti', id: 'DJ' },
        { text: 'République dominicaine', id: 'DO' },
        { text: 'Dominique', id: 'DM' },
        { text: 'Égypte', id: 'EG' },
        { text: 'Salvador', id: 'SV' },
        { text: 'Émirats arabes unis', id: 'AE' },
        { text: 'Équateur (pays)', id: 'EC' },
        { text: 'Érythrée', id: 'ER' },
        { text: 'Espagne', id: 'ES' },
        { text: 'Estonie', id: 'EE' },
        { text: 'États-Unis', id: 'US' },
        { text: 'Éthiopie', id: 'ET' },
        { text: 'Fidji', id: 'FJ' },
        { text: 'Finlande', id: 'FI' },
        { text: 'France', id: 'FR' },
        { text: 'Gabon', id: 'GA' },
        { text: 'Gambie', id: 'GM' },
        { text: 'Géorgie (pays)', id: 'GE' },
        { text: 'Ghana', id: 'GH' },
        { text: 'Grèce', id: 'GR' },
        { text: 'Grenade (pays)', id: 'GD' },
        { text: 'Guadeloupe', id: 'GP' },
        { text: 'Guatemala', id: 'GT' },
        { text: 'Guinée', id: 'GN' },
        { text: 'Guinée-Bissau', id: 'GW' },
        { text: 'Guinée équatoriale', id: 'GQ' },
        { text: 'Guyana', id: 'GY' },
        { text: 'Guyane française', id: 'GF' },
        { text: 'Haïti', id: 'HT' },
        { text: 'Honduras', id: 'HN' },
        { text: 'Hong Kong', id: 'HK' },
        { text: 'Hongrie', id: 'HU' },
        { text: 'Inde', id: 'IN' },
        { text: 'Indonésie', id: 'ID' },
        { text: 'Iran', id: 'IR' },
        { text: 'Irak', id: 'IQ' },
        { text: 'Irlande (pays)', id: 'IE' },
        { text: 'Islande', id: 'IS' },
        { text: 'Israël', id: 'IL' },
        { text: 'Italie', id: 'IT' },
        { text: 'Jamaïque', id: 'JM' },
        { text: 'Japon', id: 'JP' },
        { text: 'Jordanie', id: 'JO' },
        { text: 'Kazakhstan', id: 'KZ' },
        { text: 'Kenya', id: 'KE' },
        { text: 'Kirghizistan', id: 'KG' },
        { text: 'Kiribati', id: 'KI' },
        { text: 'Koweït', id: 'KW' },
        { text: 'Laos', id: 'LA' },
        { text: 'Lesotho', id: 'LS' },
        { text: 'Lettonie', id: 'LV' },
        { text: 'Liban', id: 'LB' },
        { text: 'Liberia', id: 'LR' },
        { text: 'Libye', id: 'LY' },
        { text: 'Liechtenstein', id: 'LI' },
        { text: 'Lituanie', id: 'LT' },
        { text: 'Luxembourg (pays)', id: 'LU' },
        { text: 'Macédoine du Nord', id: 'MK' },
        { text: 'Madagascar', id: 'MG' },
        { text: 'Malaisie', id: 'MY' },
        { text: 'Malawi', id: 'MW' },
        { text: 'Maldives', id: 'MV' },
        { text: 'Mali', id: 'ML' },
        { text: 'Malte', id: 'MT' },
        { text: 'Maroc', id: 'MA' },
        { text: 'Martinique', id: 'MQ' },
        { text: 'Îles Marshall (pays)', id: 'MH' },
        { text: 'Maurice (pays)', id: 'MU' },
        { text: 'Mauritanie', id: 'MR' },
        { text: 'Mayotte', id: 'YT' },
        { text: 'Mexique', id: 'MX' },
        { text: 'États fédérés de Micronésie (pays)', id: 'FM' },
        { text: 'Moldavie', id: 'MD' },
        { text: 'Monaco', id: 'MC' },
        { text: 'Mongolie', id: 'MN' },
        { text: 'Monténégro', id: 'ME' },
        { text: 'Mozambique', id: 'MZ' },
        { text: 'Birmanie', id: 'MM' },
        { text: 'Namibie', id: 'NA' },
        { text: 'Nauru', id: 'NR' },
        { text: 'Népal', id: 'NP' },
        { text: 'Nicaragua', id: 'NI' },
        { text: 'Niger', id: 'NE' },
        { text: 'Nigeria', id: 'NG' },
        { text: 'Norvège', id: 'NO' },
        { text: 'Nouvelle-Calédonie', id: 'NC' },
        { text: 'Nouvelle-Zélande', id: 'NZ' },
        { text: 'Oman', id: 'OM' },
        { text: 'Ouganda', id: 'UG' },
        { text: 'Ouzbékistan', id: 'UZ' },
        { text: 'Pakistan', id: 'PK' },
        { text: 'Palaos', id: 'PW' },
        { text: 'Panama', id: 'PA' },
        { text: 'Papouasie-Nouvelle-Guinée', id: 'PG' },
        { text: 'Paraguay', id: 'PY' },
        { text: 'Pays-Bas', id: 'NL' },
        { text: 'Pérou', id: 'PE' },
        { text: 'Philippines', id: 'PH' },
        { text: 'Pologne', id: 'PL' },
        { text: 'Polynésie française', id: 'PF' },
        { text: 'Portugal', id: 'PT' },
        { text: 'Qatar', id: 'QA' },
        { text: 'Reunion (La)', id: 'RE' },
        { text: 'Roumanie', id: 'RO' },
        { text: 'Royaume-Uni', id: 'GB' },
        { text: 'Russie', id: 'RU' },
        { text: 'Rwanda', id: 'RW' },
        { text: 'Saint-Christophe-et-Niévès', id: 'KN' },
        { text: 'Saint-Barthélemy', id: 'BL' },
        { text: 'Saint-Marin', id: 'SM' },
        { text: 'Saint-Vincent-et-les-Grenadines', id: 'VC' },
        { text: 'Saint Pierre and Miquelon', id: 'PM' },
        { text: 'Saint-Martin', id: 'MF' },
        { text: 'Sainte-Lucie', id: 'LC' },
        { text: 'Salomon', id: 'SB' },
        { text: 'Samoa', id: 'WS' },
        { text: 'Sao Tomé-et-Principe', id: 'ST' },
        { text: 'Sénégal', id: 'SN' },
        { text: 'Serbie', id: 'RS' },
        { text: 'Seychelles', id: 'SC' },
        { text: 'Sierra Leone', id: 'SL' },
        { text: 'Singapour', id: 'SG' },
        { text: 'Slovaquie', id: 'SK' },
        { text: 'Slovénie', id: 'SI' },
        { text: 'Somalie', id: 'SO' },
        { text: 'Soudan', id: 'SD' },
        { text: 'Soudan du Sud', id: 'SS' },
        { text: 'Sri Lanka', id: 'LK' },
        { text: 'Suède', id: 'SE' },
        { text: 'Suisse', id: 'CH' },
        { text: 'Suritext', id: 'SR' },
        { text: 'Eswatini', id: 'SZ' },
        { text: 'Syrie', id: 'SY' },
        { text: 'Tadjikistan', id: 'TJ' },
        { text: 'Tanzanie', id: 'TZ' },
        { text: 'Terres australes et antarctiques françaises', id: 'TF' },
        { text: 'Tchad', id: 'TD' },
        { text: 'Tchéquie', id: 'CZ' },
        { text: 'Thaïlande', id: 'TH' },
        { text: 'Timor oriental', id: 'TL' },
        { text: 'Togo', id: 'TG' },
        { text: 'Tonga', id: 'TO' },
        { text: 'Trinité-et-Tobago', id: 'TT' },
        { text: 'Tunisie', id: 'TN' },
        { text: 'Turkménistan', id: 'TM' },
        { text: 'Turquie', id: 'TR' },
        { text: 'Tuvalu', id: 'TV' },
        { text: 'Ukraine', id: 'UA' },
        { text: 'Uruguay', id: 'UY' },
        { text: 'Vanuatu', id: 'VU' },
        { text: 'Venezuela', id: 'VE' },
        { text: 'Viêt Nam', id: 'VN' },
        { text: 'Wallis-et-Futuna', id: 'WF' },
        { text: 'Yémen', id: 'YE' },
        { text: 'Zambie', id: 'ZM' },
        { text: 'Zimbabwe', id: 'ZW' }
    ],
    en: [
        { text: 'Afghanistan', id: 'AF' },
        { text: 'Åland Islands', id: 'AX' },
        { text: 'Albania', id: 'AL' },
        { text: 'Algeria', id: 'DZ' },
        { text: 'American Samoa', id: 'AS' },
        { text: 'AndorrA', id: 'AD' },
        { text: 'Angola', id: 'AO' },
        { text: 'Anguilla', id: 'AI' },
        { text: 'Antarctica', id: 'AQ' },
        { text: 'Antigua and Barbuda', id: 'AG' },
        { text: 'Argentina', id: 'AR' },
        { text: 'Armenia', id: 'AM' },
        { text: 'Aruba', id: 'AW' },
        { text: 'Australia', id: 'AU' },
        { text: 'Austria', id: 'AT' },
        { text: 'Azerbaijan', id: 'AZ' },
        { text: 'Bahamas', id: 'BS' },
        { text: 'Bahrain', id: 'BH' },
        { text: 'Bangladesh', id: 'BD' },
        { text: 'Barbados', id: 'BB' },
        { text: 'Belarus', id: 'BY' },
        { text: 'Belgium', id: 'BE' },
        { text: 'Belize', id: 'BZ' },
        { text: 'Benin', id: 'BJ' },
        { text: 'Bermuda', id: 'BM' },
        { text: 'Bhutan', id: 'BT' },
        { text: 'Bolivia', id: 'BO' },
        { text: 'Bosnia and Herzegovina', id: 'BA' },
        { text: 'Botswana', id: 'BW' },
        { text: 'Bouvet Island', id: 'BV' },
        { text: 'Brazil', id: 'BR' },
        { text: 'British Indian Ocean Territory', id: 'IO' },
        { text: 'Brunei Darussalam', id: 'BN' },
        { text: 'Bulgaria', id: 'BG' },
        { text: 'Burkina Faso', id: 'BF' },
        { text: 'Burundi', id: 'BI' },
        { text: 'Cambodia', id: 'KH' },
        { text: 'Cameroon', id: 'CM' },
        { text: 'Canada', id: 'CA' },
        { text: 'Cape Verde', id: 'CV' },
        { text: 'Cayman Islands', id: 'KY' },
        { text: 'Central African Republic', id: 'CF' },
        { text: 'Chad', id: 'TD' },
        { text: 'Chile', id: 'CL' },
        { text: 'China', id: 'CN' },
        { text: 'Christmas Island', id: 'CX' },
        { text: 'Cocos (Keeling) Islands', id: 'CC' },
        { text: 'Colombia', id: 'CO' },
        { text: 'Comoros', id: 'KM' },
        { text: 'Congo', id: 'CG' },
        { text: 'Congo, The Democratic Republic of the', id: 'CD' },
        { text: 'Cook Islands', id: 'CK' },
        { text: 'Costa Rica', id: 'CR' },
        { text: "Cote D'Ivoire", id: 'CI' },
        { text: 'Croatia', id: 'HR' },
        { text: 'Cuba', id: 'CU' },
        { text: 'Cyprus', id: 'CY' },
        { text: 'Czech Republic', id: 'CZ' },
        { text: 'Denmark', id: 'DK' },
        { text: 'Djibouti', id: 'DJ' },
        { text: 'Dominica', id: 'DM' },
        { text: 'Dominican Republic', id: 'DO' },
        { text: 'Ecuador', id: 'EC' },
        { text: 'Egypt', id: 'EG' },
        { text: 'El Salvador', id: 'SV' },
        { text: 'Equatorial Guinea', id: 'GQ' },
        { text: 'Eritrea', id: 'ER' },
        { text: 'Estonia', id: 'EE' },
        { text: 'Ethiopia', id: 'ET' },
        { text: 'Falkland Islands (Malvinas)', id: 'FK' },
        { text: 'Faroe Islands', id: 'FO' },
        { text: 'Fiji', id: 'FJ' },
        { text: 'Finland', id: 'FI' },
        { text: 'France', id: 'FR' },
        { text: 'French Guiana', id: 'GF' },
        { text: 'French Polynesia', id: 'PF' },
        { text: 'French Southern Territories', id: 'TF' },
        { text: 'Gabon', id: 'GA' },
        { text: 'Gambia', id: 'GM' },
        { text: 'Georgia', id: 'GE' },
        { text: 'Germany', id: 'DE' },
        { text: 'Ghana', id: 'GH' },
        { text: 'Gibraltar', id: 'GI' },
        { text: 'Greece', id: 'GR' },
        { text: 'Greenland', id: 'GL' },
        { text: 'Grenada', id: 'GD' },
        { text: 'Guadeloupe', id: 'GP' },
        { text: 'Guam', id: 'GU' },
        { text: 'Guatemala', id: 'GT' },
        { text: 'Guernsey', id: 'GG' },
        { text: 'Guinea', id: 'GN' },
        { text: 'Guinea-Bissau', id: 'GW' },
        { text: 'Guyana', id: 'GY' },
        { text: 'Haiti', id: 'HT' },
        { text: 'Heard Island and Mcdonald Islands', id: 'HM' },
        { text: 'Holy See (Vatican City State)', id: 'VA' },
        { text: 'Honduras', id: 'HN' },
        { text: 'Hong Kong', id: 'HK' },
        { text: 'Hungary', id: 'HU' },
        { text: 'Iceland', id: 'IS' },
        { text: 'India', id: 'IN' },
        { text: 'Indonesia', id: 'ID' },
        { text: 'Iran, Islamic Republic Of', id: 'IR' },
        { text: 'Iraq', id: 'IQ' },
        { text: 'Ireland', id: 'IE' },
        { text: 'Isle of Man', id: 'IM' },
        { text: 'Israel', id: 'IL' },
        { text: 'Italy', id: 'IT' },
        { text: 'Jamaica', id: 'JM' },
        { text: 'Japan', id: 'JP' },
        { text: 'Jersey', id: 'JE' },
        { text: 'Jordan', id: 'JO' },
        { text: 'Kazakhstan', id: 'KZ' },
        { text: 'Kenya', id: 'KE' },
        { text: 'Kiribati', id: 'KI' },
        { text: "Korea, Democratic People'S Republic of", id: 'KP' },
        { text: 'Korea, Republic of', id: 'KR' },
        { text: 'Kuwait', id: 'KW' },
        { text: 'Kyrgyzstan', id: 'KG' },
        { text: "Lao People'S Democratic Republic", id: 'LA' },
        { text: 'Latvia', id: 'LV' },
        { text: 'Lebanon', id: 'LB' },
        { text: 'Lesotho', id: 'LS' },
        { text: 'Liberia', id: 'LR' },
        { text: 'Libyan Arab Jamahiriya', id: 'LY' },
        { text: 'Liechtenstein', id: 'LI' },
        { text: 'Lithuania', id: 'LT' },
        { text: 'Luxembourg', id: 'LU' },
        { text: 'Macao', id: 'MO' },
        { text: 'Macedonia, The Former Yugoslav Republic of', id: 'MK' },
        { text: 'Madagascar', id: 'MG' },
        { text: 'Malawi', id: 'MW' },
        { text: 'Malaysia', id: 'MY' },
        { text: 'Maldives', id: 'MV' },
        { text: 'Mali', id: 'ML' },
        { text: 'Malta', id: 'MT' },
        { text: 'Marshall Islands', id: 'MH' },
        { text: 'Martinique', id: 'MQ' },
        { text: 'Mauritania', id: 'MR' },
        { text: 'Mauritius', id: 'MU' },
        { text: 'Mayotte', id: 'YT' },
        { text: 'Mexico', id: 'MX' },
        { text: 'Micronesia, Federated States of', id: 'FM' },
        { text: 'Moldova, Republic of', id: 'MD' },
        { text: 'Monaco', id: 'MC' },
        { text: 'Mongolia', id: 'MN' },
        { text: 'Montserrat', id: 'MS' },
        { text: 'Morocco', id: 'MA' },
        { text: 'Mozambique', id: 'MZ' },
        { text: 'Myanmar', id: 'MM' },
        { text: 'Namibia', id: 'NA' },
        { text: 'Nauru', id: 'NR' },
        { text: 'Nepal', id: 'NP' },
        { text: 'Netherlands', id: 'NL' },
        { text: 'Netherlands Antilles', id: 'AN' },
        { text: 'New Caledonia', id: 'NC' },
        { text: 'New Zealand', id: 'NZ' },
        { text: 'Nicaragua', id: 'NI' },
        { text: 'Niger', id: 'NE' },
        { text: 'Nigeria', id: 'NG' },
        { text: 'Niue', id: 'NU' },
        { text: 'Norfolk Island', id: 'NF' },
        { text: 'Northern Mariana Islands', id: 'MP' },
        { text: 'Norway', id: 'NO' },
        { text: 'Oman', id: 'OM' },
        { text: 'Pakistan', id: 'PK' },
        { text: 'Palau', id: 'PW' },
        { text: 'Palestinian Territory, Occupied', id: 'PS' },
        { text: 'Panama', id: 'PA' },
        { text: 'Papua New Guinea', id: 'PG' },
        { text: 'Paraguay', id: 'PY' },
        { text: 'Peru', id: 'PE' },
        { text: 'Philippines', id: 'PH' },
        { text: 'Pitcairn', id: 'PN' },
        { text: 'Poland', id: 'PL' },
        { text: 'Portugal', id: 'PT' },
        { text: 'Puerto Rico', id: 'PR' },
        { text: 'Qatar', id: 'QA' },
        { text: 'Reunion', id: 'RE' },
        { text: 'Romania', id: 'RO' },
        { text: 'Russian Federation', id: 'RU' },
        { text: 'RWANDA', id: 'RW' },
        { text: 'Saint Helena', id: 'SH' },
        { text: 'Saint Kitts and Nevis', id: 'KN' },
        { text: 'Saint Lucia', id: 'LC' },
        { text: 'Saint Pierre and Miquelon', id: 'PM' },
        { text: 'Saint Vincent and the Grenadines', id: 'VC' },
        { text: 'Samoa', id: 'WS' },
        { text: 'San Marino', id: 'SM' },
        { text: 'Sao Tome and Principe', id: 'ST' },
        { text: 'Saudi Arabia', id: 'SA' },
        { text: 'Senegal', id: 'SN' },
        { text: 'Serbia and Montenegro', id: 'CS' },
        { text: 'Seychelles', id: 'SC' },
        { text: 'Sierra Leone', id: 'SL' },
        { text: 'Singapore', id: 'SG' },
        { text: 'Slovakia', id: 'SK' },
        { text: 'Slovenia', id: 'SI' },
        { text: 'Solomon Islands', id: 'SB' },
        { text: 'Somalia', id: 'SO' },
        { text: 'South Africa', id: 'ZA' },
        { text: 'South Georgia and the South Sandwich Islands', id: 'GS' },
        { text: 'Spain', id: 'ES' },
        { text: 'Sri Lanka', id: 'LK' },
        { text: 'Sudan', id: 'SD' },
        { text: 'Suriname', id: 'SR' },
        { text: 'Svalbard and Jan Mayen', id: 'SJ' },
        { text: 'Swaziland', id: 'SZ' },
        { text: 'Sweden', id: 'SE' },
        { text: 'Switzerland', id: 'CH' },
        { text: 'Syrian Arab Republic', id: 'SY' },
        { text: 'Taiwan, Province of China', id: 'TW' },
        { text: 'Tajikistan', id: 'TJ' },
        { text: 'Tanzania, United Republic of', id: 'TZ' },
        { text: 'Thailand', id: 'TH' },
        { text: 'Timor-Leste', id: 'TL' },
        { text: 'Togo', id: 'TG' },
        { text: 'Tokelau', id: 'TK' },
        { text: 'Tonga', id: 'TO' },
        { text: 'Trinidad and Tobago', id: 'TT' },
        { text: 'Tunisia', id: 'TN' },
        { text: 'Turkey', id: 'TR' },
        { text: 'Turkmenistan', id: 'TM' },
        { text: 'Turks and Caicos Islands', id: 'TC' },
        { text: 'Tuvalu', id: 'TV' },
        { text: 'Uganda', id: 'UG' },
        { text: 'Ukraine', id: 'UA' },
        { text: 'United Arab Emirates', id: 'AE' },
        { text: 'United Kingdom', id: 'GB' },
        { text: 'United States', id: 'US' },
        { text: 'United States Minor Outlying Islands', id: 'UM' },
        { text: 'Uruguay', id: 'UY' },
        { text: 'Uzbekistan', id: 'UZ' },
        { text: 'Vanuatu', id: 'VU' },
        { text: 'Venezuela', id: 'VE' },
        { text: 'Viet Nam', id: 'VN' },
        { text: 'Virgin Islands, British', id: 'VG' },
        { text: 'Virgin Islands, U.S.', id: 'VI' },
        { text: 'Wallis and Futuna', id: 'WF' },
        { text: 'Western Sahara', id: 'EH' },
        { text: 'Yemen', id: 'YE' },
        { text: 'Zambia', id: 'ZM' },
        { text: 'Zimbabwe', id: 'ZW' }
    ]
}

export default countryList
