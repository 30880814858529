<template>
    <span>
        <a
            v-if="consultant.user.actif === 0"
            href="#"
            class="rounded button is-light rounded-lg text-decoration-none mr-1 is-danger"
            @click.prevent="desactivate()"
        >
            <b-icon icon="ban" />
        </a>
        <a
            v-else
            href="#"
            class="rounded button is-light rounded-lg text-decoration-none mr-1 is-success"
            @click.prevent="activate()"
        >
            <b-icon icon="check" />
        </a>
        <b-modal v-model="modal">
            <template #default="props">
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">
                        {{ type == 'desactivate' ? 'Désactivation' : 'Activation' }}
                    </h4>
                </header>
                <div class="box">
                    <b-loading v-if="loading" v-model="loading" :is-full-page="false" />
                    <div v-else>
                        <div class="modal-body">
                            {{
                                type == 'desactivate'
                                    ? 'Confirmez-vous la désactivation du consultant ?'
                                    : "Confirmez-vous l'activation du consultant ?"
                            }}
                        </div>
                        <div class="modal-footer">
                            <b-button type="button" class="btn btn-danger" @click="props.close">Annuler</b-button>
                            <b-button type="button" class="btn btn-primary" @click="updateConsultant">Oui</b-button>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
    </span>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        props: {
            consultant: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            modal: false,
            type: null,
            loading: false
        }),
        methods: {
            ...mapActions('consultants', {
                enabledConsultant: 'enabled',
                disabledConsultant: 'disabled'
            }),
            desactivate() {
                this.type = 'desactivate'
                this.modal = true
            },
            activate() {
                this.type = 'activate'
                this.modal = true
            },
            closeModal() {
                this.modal = false
            },
            updateConsultant() {
                this.loading = true
                if (this.type == 'desactivate') {
                    this.disabledConsultant(this.consultant.id)
                        .then(() => {
                            this.$store.dispatch('toast', {
                                title: 'Consultant désactivé',
                                type: 'success'
                            })
                        })
                        .catch(error => {
                            this.$store.dispatch('toast', {
                                title: 'Une erreur est survenue lors de la mise à jour',
                                type: 'error'
                            })
                        })
                        .finally(() => {
                            this.loading = false
                            this.modal = false
                            this.$emit('reload')
                        })
                } else {
                    this.enabledConsultant(this.consultant.id)
                        .then(() => {
                            this.$store.dispatch('toast', {
                                title: 'Consultant activé',
                                type: 'success'
                            })
                        })
                        .catch(error => {
                            this.$store.dispatch('toast', {
                                title: 'Une erreur est survenue lors de la mise à jour',
                                type: 'error'
                            })
                        })
                        .finally(() => {
                            this.loading = false
                            this.modal = false
                            this.$emit('reload')
                        })
                }
            }
        }
    }
</script>

<style></style>
