<template>
    <div>
        <b-modal v-model="modalIsOpen" :on-cancel="closeAndResetModal" :width="960">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">Trouver une image sur Pixabay</h4>
                </header>
                <div class="card-content">
                    <div>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Recherche :</span>
                            </div>
                            <input v-model="querySearch" class="form-control" type="text" />

                            <div class="input-group-append">
                                <button class="btn btn-outline-primary" type="button" @click.prevent="doSearchImages">
                                    Rechercher des images
                                </button>
                            </div>
                        </div>

                        <div v-if="loadingImages">
                            <div class="d-flex justify-content-center align-items-center preloading">
                                <div class="loader-xl" />
                            </div>
                        </div>

                        <div v-else class="mt-3">
                            <ul class="gallery">
                                <li v-for="image in images" :key="image.id" class="gallery-card">
                                    <img :src="image.webformatURL" alt="" />
                                    <button
                                        class="btn btn-warning card-btn"
                                        @click.prevent="$emit('image:selected', image)"
                                    >
                                        <i class="fas fa-check" />
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'RedactorPixabayModal',
        props: [],
        data() {
            return {
                modalIsOpen: false,
                querySearch: '',
                images: [],
                loadingImages: false
            }
        },

        methods: {
            ...mapActions('redactors', ['searchImages']),
            openModal(type, data) {
                this.modalIsOpen = true
            },

            closeAndResetModal() {
                this.modalIsOpen = false
                this.querySearch = ''
                this.images = []
            },

            doSearchImages() {
                this.loadingImages = true
                this.searchImages(this.querySearch)
                    .then(response => {
                        this.images = response.images.hits
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loadingImages = false
                    })
            }
        }
    }
</script>

<style scoped>
    .gallery {
        list-style: none;
        padding: 0;
        margin: 0;

        display: flex;
        flex-wrap: wrap;
    }

    .gallery li {
        flex: 1 1 15rem;
        min-height: 30vh;
        max-height: 50vh;
    }

    .gallery-card img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .gallery-card {
        position: relative;
    }

    .gallery-card .card-btn {
        position: absolute;
        top: 95%;
        left: 95%;
        transform: translate(-95%, -95%);
        -ms-transform: translate(-95%, -95%);
    }
</style>
