<template>
    <span>
        <Toaster ref="toaster" class="d-inline" />
        <button v-clipboard:copy="copy" v-clipboard:success="onCopy" class="ml-1 btn btn-link p-0">
            <i class="far fa-clipboard" />
        </button>
    </span>
</template>

<script>
    import Toaster from './Toaster'

    export default {
        components: { Toaster },
        props: {
            copy: {
                required: true
            }
        },
        methods: {
            onCopy: function (e) {
                this.$refs.toaster.fire('success', e.text + ' copied !') // Confirm Message
            }
        }
    }
</script>

<style scoped></style>
