<template>
    <div v-if="!loading">
        <div v-if="jobProcessing">Export utilisateurs en cours</div>
        <div v-else>
            <button v-if="lastExport" class="btn btn-primary" @click.prevent="downloadExcel">
                Télécharger le dernier export {{ lastExport }}
            </button>
            <button class="btn btn-primary" @click.prevent="orderNewExport">Lancer un nouvel export</button>
        </div>
    </div>
</template>

<script>
    import Download from '../../../utils/download'
    import { mapActions } from 'vuex'

    export default {
        data: () => ({
            loading: true,
            jobProcessing: false,
            lastExport: null
        }),
        created() {
            //this.loadExportInfo()
        },
        methods: {
            ...mapActions('users', {
                exportUsers: 'export',
                getLastExportInfo: 'getLastExportInfo',
                downloadExport: 'downloadExport'
            }),
            loadExportInfo() {
                this.getLastExportInfo()
                    .then(({ data }) => {
                        this.jobProcessing = data.job_processing
                        this.lastExport = data.last_export
                        if (data.job_processing == true) {
                            setTimeout(() => {
                                this.loadExportInfo()
                            }, 3000)
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            downloadExcel() {
                this.downloadExport().then(response => {
                    Download.download(response, 'liste-user.xlsx')
                })
            },
            orderNewExport() {
                this.exportUsers().then(({ data }) => {
                    this.jobProcessing = data.job_processing
                    this.lastExport = data.last_export
                    if (data.job_processing == true) {
                        setTimeout(() => {
                            this.loadExportInfo()
                        }, 3000)
                    }
                })
            }
        }
    }
</script>

<style></style>
