import moment from 'moment'

export default () => ({
    isLoading: false,
    loaded: false,
    list: [],
    total: 0,

    selectedManager: null,
    target: null,
    source: null,

    agency: null,

    agencies: null,
    agenciesConfig: {
        include: '',
        sort: 'id',
        sortDirection: '',
        page: 1,
        filters: {},
        per_page: 100
    },

    managers: null,
    managersConfig: {
        include: '',
        sort: 'id',
        sortDirection: '',
        page: 1,
        filters: {},
        per_page: 100
    },

    consultants: null,
    consultantsConfig: {
        include: '',
        sort: 'id',
        sortDirection: '',
        page: 1,
        filters: {},
        per_page: 100
    },

    searchConfig: {
        include: '',
        sort: 'id',
        sortDirection: '',
        page: 1,
        filters: {},
        per_page: 100
    },

    clients: null,
    clientsConfig: {
        include: '',
        sort: 'id',
        sortDirection: '',
        page: 1,
        filters: {},
        per_page: 50
    },

    manager: null,
    consultant: null,
    newEmployee: {
        type: 'consultant',
        email: null,
        lastname: null,
        firstname: null,
        password: null,
        budget_initial: null,
        manager_id: null
    },
    newClient: {
        email: null,
        lastname: null,
        firstname: null,
        password: null,
        budget_initial: null,
        responsible_id: null,
        responsible_type: 5
    },
    newUser: {
        user: null,
        agency_id: null,
        responsible_id: null,
        responsible_type: 4,
        type: 'manager'
    },

    agencyClient: null,
    agencyClientContents: null,

    month: moment().startOf('month').format('YYYY-MM-DD'),

    date_from: null,
    date_to: null,

    credits: {
        type: 'add',
        label: '',
        amount: null
    },

    action: 'create',
    scope: null
})
