<template>
    <nav class="navbar sticky-top navbar-light bg-danger text-center text-white d-block mb-0">
        {{ $t('layout.clientaccount') }}.
        <a href="#" class="text-white" @click.prevent="doLogout">
            {{ $t('layout.clickhereaccount') }}
        </a>
        <!--        - -->
        <!--        <router-link :to="`/logout-accompagnement.php?pg=accompagnement`"-->
        <!--                     class="text-white">{{ $t('layout.clickhereconseils') }}-->
        <!--        </router-link>-->
        <!--        - -->
        <!--        <router-link :to="`/logout-accompagnement.php?pg=consultant`"-->
        <!--                     class="text-white">{{ $t('layout.clickhereaccompagnement') }}-->
        <!--        </router-link>-->
    </nav>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'LogoutAs',
        props: [],
        methods: {
            ...mapActions('auth', ['logoutAs']),
            doLogout() {
                this.logoutAs().then(() => {
                    let url = this.$store.state.auth.url
                    if (!url) {
                        url = this.$store.state.homepage
                    }
                    this.$router.push(url)
                })
            }
        }
    }
</script>

<style scoped>
    .navbar {
        z-index: 99;
    }
</style>
