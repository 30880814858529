export default {
    setLoading(state) {
        state.isLoading = true
        state.loaded = false
    },
    setList(state, data) {
        state.list = data.notifications.data
        state.total = data.notifications.meta.total
        state.isLoading = false
        state.loaded = true
    }
}
