<template>
    <footer id="footer" class="px-2 py-1">
        Copyright &copy; NextLevel.Link
        <a href="https://www.nextlevel.link/mentions-legales" target="_blank">{{ $t('global.legalnotice') }}</a>
        /
        <a href="https://www.nextlevel.link/contact" target="_blank">{{ $t('layout.contact') }}</a>
        / <a href="#" @click.prevent="toggleGTCModal()">{{ $t('layout.cgv') }}</a> /
        <a href="https://www.nextlevel.link/faq-client-nextlevel" target="_blank">{{ $t('layout.faqcustommers') }}</a>
        /
        <a href="https://www.nextlevel.link/faq-partenaire-nextlevel" target="_blank">{{ $t('layout.faqpartners') }}</a>
    </footer>
</template>

<script>
    import { mapMutations } from 'vuex'

    export default {
        name: 'AppFooter',
        data() {
            return {}
        },
        methods: {
            ...mapMutations(['toggleGTCModal']),
            openMentions: function () {
                this.$refs.mentionsModal.show()
            },
            closeMentions: function () {
                this.$refs.mentionsModal.hide()
            }
        }
    }
</script>
