import ApiService from '@/services/api.service'

export default {
    list(context, { status, project, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(
                `/preorders${status ? `?status=${status}` : ''} ${project ? `&project=${project}` : ''}`,
                params
            )
                .then(({ data }) => {
                    context.commit('setPreorders', data)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
