<template>
    <div>
        <SiteLogTable :site-logs="siteNotes" @reload="$emit('reloadData', tabData.index)" />
        <ContentLogTable :content-logs="contentNotes" @reload="$emit('reloadData', tabData.index)" />
        <PartnerLogTable :partner-logs="partnerNotes" @reload="$emit('reloadData', tabData.index)" />
    </div>
</template>

<script>
    import SiteLogTable from '../Notes/SiteLogTable'
    import ContentLogTable from '../Notes/ContentLogTable'
    import PartnerLogTable from '../Notes/PartnerLogTable'

    export default {
        name: 'PartnerDetailsNote',
        components: { PartnerLogTable, SiteLogTable, ContentLogTable },
        props: {
            tabData: {}
        },
        data() {
            return {
                siteNotes: [],
                contentNotes: [],
                partnerNotes: [],
                user: {}
            }
        },
        watch: {
            tabData: {
                handler(newValue) {
                    this.siteNotes = newValue.content.sitesLogs
                    this.contentNotes = newValue.content.contentLogs
                    this.partnerNotes = newValue.content.partnerLogs
                    this.user = newValue.user
                },
                deep: true
            }
        }
    }
</script>
