<template>
    <UserCampaignTable :accompagnements="accompagnements" @loadAsyncData="loadAsyncData" />
</template>

<script>
    import UserCampaignTable from './UserCampaignTable'

    export default {
        name: 'UserCampaignTab',
        components: { UserCampaignTable },
        props: {
            tabData: {}
        },
        data() {
            return {
                accompagnements: []
            }
        },
        watch: {
            tabData: {
                handler(newValue) {
                    this.accompagnements = newValue.content.accompagnements
                },
                deep: true
            }
        },
        methods: {
            loadAsyncData(data) {
                var field = data.sortField

                if (data.sortOrder === 'asc') {
                    field = '-' + data.sortField
                }

                var params = {
                    page: data.page,
                    include: data.includes,
                    sort: field
                }

                if (data.filters && data.filters.length > 0) {
                    data.filters.forEach(element => {
                        params[`filter[${element.key}]`] = element.value
                    })
                }

                this.axios
                    .get(`/admin/user-details/campaigns/${this.$route.query.id}`, {
                        params
                    })
                    .then(response => {
                        this.accompagnements = response.data.accompagnements
                    })
                    .catch(error => {
                        console.error(error)
                    })
                    .finally(() => {
                        data.callback()
                    })
            }
        }
    }
</script>
