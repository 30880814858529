<template>
    <div class="w-100 h-100">
        <div class="d-flex align-items-center justify-content-center">
            <div class="d-flex flex-column align-items-center">
                <span>Date de début</span>
                <input v-model="date.start" type="date" class="form-control" />
            </div>
            <div class="ml-3 d-flex flex-column align-items-center">
                Date de début
                <input v-model="date.end" type="date" class="form-control" />
            </div>
            <button type="button" class="btn btn-outline-primary align-self-end ml-2" @click="getData()">
                Actualiser
            </button>
        </div>
        <p class="text-center h5 mt-4">
            <span class="font-weight-bold">URL : </span><span class="h5">{{ infos.accompagnement_url }}</span
            ><br />
            <span class="font-weight-bold">MC : </span><span class="h5">{{ infos.mot_cle }}</span>
        </p>

        <div v-if="preloading">
            <div class="d-flex justify-content-center align-items-center preloading">
                <div class="loader-xl" />
            </div>
        </div>
        <line-chart v-if="preloading === false" ref="chart" :data="linechart.data" :options="linechart.options" />
        <Toaster ref="toaster" />
    </div>
</template>

<script>
    var image = new Image()
    image.scr = 'http://placehold.it/350x150'

    import moment from 'moment'

    export default {
        name: 'RankCharts',

        props: {
            infos: {
                type: Object,
                required: true
            }
        },

        data: function () {
            return {
                preloading: false,
                date: {
                    start: '',
                    end: ''
                },
                linechart: {
                    data: {
                        labels: [],
                        datasets: [
                            {
                                label: 'Position',
                                data: [],
                                tension: 0,
                                stepped: true,
                                type: 'line',
                                backgroundColor: 'transparent',
                                borderColor: 'rgba(1, 116, 188, 0.50)',
                                pointBackgroundColor: 'rgba(1, 116, 188, 0.50)'
                            }
                        ]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            yAxes: [
                                {
                                    suggestedMin: 1,
                                    suggestedMax: 100,
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }
                            ],
                            yAxes: [
                                {
                                    suggestedMin: 1,
                                    suggestedMax: 100,
                                    ticks: {
                                        reverse: true,
                                        beginAtZero: true,
                                        suggestedMin: 1,
                                        suggestedMax: 20
                                    }
                                }
                            ]
                        },
                        watermark: {
                            image: image,

                            x: 0,
                            y: 0,

                            width: 100,
                            height: 50,
                            alignToChartArea: false,

                            opacity: 1,

                            alignX: 'middle',
                            alignY: 'middle',

                            position: 'back'
                        }
                    }
                }
            }
        },

        created() {
            this.date = {
                start: moment().subtract(1, 'month').format('YYYY-MM-DD'),
                end: moment().format('YYYY-MM-DD')
            }
        },
        methods: {
            getData() {
                if (this.date.start === '' && this.date.end === '') {
                    this.$refs.toaster.fire('error', "Merci d'entrer une date de début et de fin")
                    return
                }
                this.preloading = true
                this.axios
                    .get(
                        `/admin/monitorank/get-monitorank-positions?id=${this.infos.accompagnement_id}&dateFrom=${this.date.start}&dateTo=${this.date.end}&url=${this.infos.accompagnement_url}&keyword=${this.infos.mot_cle}`
                    )
                    .then(response => {
                        this.linechart.data.labels = []
                        this.linechart.data.datasets[0].data = []
                        Object.keys(response.data.content.rank).forEach(key => {
                            this.linechart.data.labels.push(key)
                            this.linechart.data.datasets[0].data.push(
                                response.data.content.rank[key] === 0 ? 101 : Number(response.data.content.rank[key])
                            )
                        })
                        this.preloading = false
                    })
                    .catch(error => {
                        this.$refs.toaster.fire('error', error.response.data.message)
                        this.preloading = false
                    })
                    .finally(() => {
                        if (this.linechart.data.datasets[0].data.length > 0) {
                            this.$refs.chart.render()
                        } else {
                            this.$refs.toaster.fire('error', 'Pas de données pour cette url')
                        }
                    })
            }
        }
    }
</script>
