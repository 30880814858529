<template>
    <div>
        <h5 className="card-title mb-3">
            {{ $t('redactor.anchor-edit') }}
        </h5>
        <form>
            <div className="form-group">
                <label htmlFor="old-anchor">
                    {{ $t('redactor.anchor-old') }}
                </label>
                <input
                    id="old-anchor"
                    v-model="writing.primary_anchor"
                    :placeholder="$t('redactor.anchor-old')"
                    class="form-control"
                    readonly
                    type="text"
                />
            </div>
            <div className="form-group">
                <label htmlFor="new-anchor">
                    {{ $t('redactor.anchor-new') }}
                </label>
                <input
                    id="new-anchor"
                    v-model="newAnchor"
                    :placeholder="$t('redactor.anchor-new')"
                    class="form-control"
                    type="text"
                />
                <small v-if="errorAnchor" className="text-danger">
                    {{ errorAnchor }}
                </small>
            </div>
            <div v-if="writing.secondary_anchor" className="">
                <div className="form-group">
                    <label htmlFor="old-anchor-two"> {{ $t('redactor.anchor-old') }} 2 </label>
                    <input
                        id="old-anchor-two"
                        v-model="writing.secondary_anchor"
                        :placeholder="$t('redactor.anchor-old')"
                        class="form-control"
                        readonly
                        type="text"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="new-anchor-two"> {{ $t('redactor.anchor-new') }} 2 </label>
                    <input
                        id="new-anchor-two"
                        v-model="newAnchorTwo"
                        :placeholder="$t('redactor.anchor-new')"
                        class="form-control"
                        type="text"
                    />
                    <small v-if="errorAnchorTwo" className="text-danger">
                        {{ errorAnchorTwo }}
                    </small>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'ChangeAnchorPanel',
        data() {
            return {
                errorAnchor: '',
                newAnchor: '',
                newAnchorTwo: '',
                errorAnchorTwo: '',
                anchorUpdated: false
            }
        },
        computed: {
            ...mapState('redactors', {
                writing: state => state.writing
            })
        },
        methods: {
            ...mapActions('redactors', {
                updateWriting: 'updateWriting'
            }),
            storeNewAnchor() {
                var data = {}

                if (this.newAnchor !== this.primary_anchor) {
                    if (this.newAnchor.length === 0) {
                        this.errorAnchor = 'Nouvelle ancre invalide'
                        return
                    } else {
                        this.errorAnchor = ''
                        data['primary_anchor'] = this.newAnchor
                    }
                }

                if (this.writing.secondary_link) {
                    if (this.newAnchorTwo !== this.secondary_anchor) {
                        if (this.newAnchorTwo.length === 0) {
                            this.errorAnchorTwo = 'Nouvelle ancre 2 invalide'
                            return
                        } else {
                            this.errorAnchorTwo = ''
                            data['secondary_anchor'] = this.newAnchorTwo
                        }
                    }
                }

                this.updateWriting(data)
                    .then(() => {
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: this.$t('redactor.anchor.changed')
                        })

                        this.$emit('anchor:changed')
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.errorAnchor = ''
                        this.errorAnchorTwo = ''
                    })
            }
        },
        mounted() {
            this.newAnchor = this.writing.primary_anchor
            this.newAnchorTwo = this.writing.secondary_anchor
        }
    }
</script>

<style scoped></style>
