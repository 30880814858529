<template>
    <div>
        <div class="d-flex flex-column flex-md-row justify-content-end">
            <form class="d-flex mb-2">
                <button id="rechargement" type="button" class="btn btn-primary mr-2" @click="reloadData">
                    <i class="fas fa-sync-alt"/>
                </button>
                <div class="columnsChoice">
                    <b-dropdown aria-role="list" :close-on-click="false" position="is-bottom-left">
                        <template #trigger="{ active }">
                            <b-button
                                :label="$t('global.columns')"
                                type="is-info"
                                class="mr-2"
                                :icon-right="active ? 'caret-up' : 'caret-down'"
                            />
                        </template>

                        <b-dropdown-item v-for="(col, index) in columns" :key="index" aria-role="listitem">
                            <b-field>
                                <b-checkbox v-model="col.visible">
                                    {{ col.name }}
                                </b-checkbox>
                            </b-field>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </form>
        </div>
        <b-table
            :data="orders.data"
            :mobile-cards="true"
            :paginated="true"
            :current-page.sync="orders.current_page"
            :striped="true"
            :debounce-search="200"
            class="table-striped"
            :loading="isLoading"
            :default-sort="[config.sort, config.sortDirection]"
            :per-page="config.per_page"
            :total="orders.total"
            backend-sorting
            backend-pagination
            backend-filtering
            @page-change="onPageChange"
            @sort="onSort"
            @filters-change="onFilterChange"
        >
            <b-table-column
                field="id"
                sortable
                :searchable="isSearchable"
                label="ID"
                cell-class="align-middle"
                :visible="columns.id.visible"
            >
                <div slot="default" slot-scope="props">
                    {{ props.row.id }}
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="number"
                        class="form-control form-control-sm"
                        placeholder="#"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="created_at"
                sortable
                :searchable="isSearchable"
                :label="$t('factures.date')"
                cell-class="align-middle"
                :visible="columns.created_at.visible"
            >
                <div slot="default" slot-scope="props">
                    {{ moment(props.row.created_at).format('L') }}
                    à {{ moment(props.row.created_at).format('LT') }}
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="date"
                        class="form-control form-control-sm"
                        :placeholder="$t('factures.date')"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="emailPayant"
                sortable
                :searchable="isSearchable"
                label="Email"
                cell-class="align-middle"
                :visible="columns.emailPayant.visible"
            >
                <div slot="default" slot-scope="props">
                    {{ props.row.emailPayant }}
                    <LoginAs :user-id="props.row.user_id"/>
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        step="0.01"
                        class="form-control form-control-sm"
                        placeholder="Email"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="accompagnement.url"
                sortable
                label="Accompagnement"
                cell-class="align-middle text-center"
                :visible="columns.accompagnement.visible"
            >
                <div v-if="props.row.accompagnement != null" slot="default" slot-scope="props">
                    {{ props.row.accompagnement.url }} -
                    {{ getCampaignPeriod(props.row.infos) }}
                </div>
                <div v-else slot="default">
                    <span class="tag is-danger">
                        <i class="fas fa-times"/>
                    </span>
                </div>
            </b-table-column>

            <b-table-column
                field="price_ht"
                sortable
                :searchable="isSearchable"
                :label="$t('factures.amounHT')"
                cell-class="align-middle"
                :visible="columns.price_ht.visible"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ props.row.price_ht }}€</span>
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm"
                        :placeholder="$t('factures.amountTTC')"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="price_ttc"
                sortable
                :searchable="isSearchable"
                :label="$t('factures.amountTTC')"
                cell-class="align-middle"
                :visible="columns.price_ttc.visible"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ props.row.price_ttc }}€</span>
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm"
                        :placeholder="$t('factures.amountTTC')"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="credits"
                sortable
                label="Crédits offerts"
                cell-class="align-middle text-center"
                :visible="columns.credits.visible"
            >
                <div slot="default" slot-scope="props">
                    <div v-if="props.row.coupon">
                        {{ props.row.price_ht * props.row.coupon.reduction / 100 }}€
                    </div>

                    <div v-if="props.row.credits">
                        {{ props.row.credits }}€
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="coupon"
                sortable
                label="Coupon"
                cell-class="align-middle text-center"
                :visible="columns.coupon.visible"
            >
                <div slot="default" slot-scope="props">
                    <b-tooltip
                        v-if="props.row.coupon"
                        :label="props.row.coupon.coupon"
                        position="is-right"
                    >
                        <span class="tag is-success">
                            <i class="fas fa-check"/>
                        </span>
                    </b-tooltip>
                </div>
            </b-table-column>

            <b-table-column
                field="type_order"
                sortable
                :searchable="isSearchable"
                :label="$t('factures.orderType')"
                cell-class="align-middle"
                :visible="columns.type_order.visible"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.type_order === 'card'" class="tag is-white">
                        <i class="far fa-credit-card"/>&nbsp;Carte&nbsp;
                    </span>

                    <span v-if="props.row.type_order === 'paypal'" class="tag is-info">
                        <i class="fab fa-paypal"/>&nbsp;PayPal&nbsp;
                    </span>

                    <span v-if="props.row.type_order === 'wire'" class="tag is-black">
                        <i class="fab fa-paypal"/>&nbsp;Virement&nbsp;
                    </span>

                    <span v-if="props.row.type_order === 'gains'" class="tag has-background-link-dark text-white">
                        <i class="fas fa-coins"/>&nbsp;Gains&nbsp;
                    </span>
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                        <option :value="props.filters['type_order']" selected disabled hidden>Type</option>
                        <option value="">-</option>
                        <option value="card">Carte</option>
                        <option value="paypal">Paypal</option>
                        <option value="wire">Virement</option>
                        <option value="gains">Gains</option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column
                field="payment_status"
                label="Statut"
                cell-class="align-middle"
                :searchable="isSearchable"
                :visible="columns.payment_status.visible"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.payment_status === 'pending'" class="tag is-info">En attente</span>
                    <span
                        v-else-if="
                            props.row.payment_status === 'in_progress_credits' ||
                            props.row.payment_status === 'in_progress_invoice'
                        "
                        class="tag is-warning"
                    >En cours</span
                    >
                    <span v-else-if="props.row.payment_status === 'complete'" class="tag is-success">Terminé</span>
                    <span v-else-if="props.row.payment_status === 'canceled'" class="tag is-dark">Annulé</span>
                    <span v-else-if="props.row.payment_status === 'refunded'" class="tag is-danger">Remboursé</span>
                    <span v-else class="tag is-dark">Inconnu</span>
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                        <option :value="props.filters['type_order']" selected disabled hidden>Statut</option>
                        <option value="">-</option>
                        <option value="pending">En attente</option>
                        <option value="in_progress_invoice,in_progress_credits">En cours</option>
                        <option value="complete">Terminé</option>
                        <option value="refunded">Remboursé</option>
                        <option value="canceled">Annulé</option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column
                field="invoiceIdFacturation"
                label="PDF"
                cell-class="align-middle"
                :visible="columns.invoiceIdFacturation.visible"
            >
                <div slot="default" slot-scope="props">
                    <div v-if="props.row.quotationIdFacturation">
                        <span class="tag is-warning">
                            <a class="downloadPDF" @click="downloadPdf(props.row.id, 'quote')">
                                D{{ props.row.facturation_quote_ref ?? props.row.quotationIdFacturation }}<i class="fas fa-file-download"/>
                            </a>
                        </span>
                    </div>

                    <div v-if="props.row.invoiceIdFacturation && props.row.typeFacturationPro === 'invoice'">
                        <span class="tag is-success">
                            <a class="downloadPDF" @click="downloadPdf(props.row.id, 'invoice')">
                                F{{ props.row.facturation_invoice_ref ?? props.row.invoiceIdFacturation }}<i class="fas fa-file-download"/>
                            </a>
                        </span>
                    </div>

                    <div v-if="props.row.refundInvoiceIdFacturation">
                        <span class="tag is-danger">
                            <a class="downloadPDF is-white" @click="downloadPdf(props.row.id, 'refund')">
                                A{{ props.row.facturation_refund_ref ?? props.row.refundInvoiceIdFacturation }}<i class="fas fa-file-download"/>
                            </a>
                        </span>
                    </div>
                </div>
            </b-table-column>

            <b-table-column label="Actions" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <div
                        v-if="!['complete', 'canceled', 'refunded'].includes(props.row.payment_status)"
                        class="dropdown"
                    >
                        <button
                            id="chooseLocale"
                            class="btn btn-primary btn-sm dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                        >
                            Actions
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <template
                                v-if="
                                    props.row.payment_status == 'pending' &&
                                    !props.row.quotationIdFacturation &&
                                    !props.row.invoiceIdFacturation
                                "
                            >
                                <a href="#" class="dropdown-item" @click.prevent="generateQuote(props.row.id)"
                                ><i class="fas fa-file-alt mr-1"/>Créer un devis</a
                                >
                                <a href="#" class="dropdown-item" @click.prevent="generateInvoice(props.row.id)"
                                ><i class="fas fa-file-alt mr-1"/>Créer une facture</a
                                >
                            </template>
                            <template v-if="props.row.payment_status == 'pending'">
                                <a href="#" class="dropdown-item" @click.prevent="addCredits(props.row.id)"
                                ><i class="fas fa-plus mr-1"/>Ajouter les crédits</a
                                >
                            </template>
                            <template v-if="props.row.quotationIdFacturation && !props.row.invoiceIdFacturation">
                                <a href="#" class="dropdown-item" @click.prevent="generateInvoice(props.row.id)"
                                ><i class="fas fa-file-alt mr-1"/>Générer la facture</a
                                >
                                <a href="#" class="dropdown-item" @click.prevent="deleteOrder(props.row.id)"
                                ><i class="far fa-trash-alt mr-1"/>Supprimer</a
                                >
                            </template>
                            <template
                                v-if="
                                    props.row.invoiceIdFacturation &&
                                    (props.row.payment_status == 'pending' ||
                                        props.row.payment_status == 'in_progress_credits' ||
                                        props.row.payment_status == 'in_progress_invoice')
                                "
                            >
                                <a href="#" class="dropdown-item" @click.prevent="markAsPaid(props.row.id)"
                                ><i class="fas fa-check mr-1"/>Marquer comme payée</a
                                >
                            </template>
                        </div>
                    </div>
                </div>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
import Download from '../../../utils/download'
import {mapActions, mapState} from 'vuex'

export default {
    name: 'BillingList',
    components: {},
    props: {
        active: {
            type: Boolean,
            default: false
        },
        filter: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        columns: {
            id: {
                visible: true,
                name: 'Id'
            },
            created_at: {
                visible: true,
                name: 'Date achat'
            },
            emailPayant: {
                visible: true,
                name: 'Email'
            },
            accompagnement: {
                visible: true,
                name: 'Accompagnement'
            },
            price_ht: {
                visible: true,
                name: 'Prix HT'
            },
            price_ttc: {
                visible: true,
                name: 'Prix TTC'
            },
            type_order: {
                visible: true,
                name: 'Type'
            },
            payment_status: {
                visible: true,
                name: 'Status'
            },
            quotationIdFacturation: {
                visible: true,
                name: 'Devis'
            },
            invoiceIdFacturation: {
                visible: true,
                name: 'Facture'
            },
            coupon: {
                visible: true,
                name: 'Coupon'
            },
            credits: {
                visible: true,
                name: 'Crédits offerts'
            },
        },

        //buefy data
        isSearchable: true,
        isLoading: false
    }),
    watch: {
        active(active) {
            if (active == true) {
                this.reloadData()
            }
        }
    },
    computed: {
        ...mapState('billing', {
            orders: state => state.orders,
            config: state => state.ordersConfig,
        })
    },
    methods: {
        ...mapActions('billing', {
            getBilling: 'list',
            advanceCredit: 'advanceCredit',
            generateInvoiceAction: 'generateInvoice',
            generateQuoteAction: 'generateQuote',
            markAsPaidAction: 'markAsPaid',
            delete: 'delete',
            downloadPdfAction: 'downloadPdf'
        }),
        reloadData() {
            this.loadAsyncData()
        },
        /** Table filtering, sorting and pagination methods */
        loadAsyncData() {
            this.isLoading = true
            this.getBilling()
                .finally(() => {
                    this.isLoading = false
                })
        },
        onSort(field, order) {
            this.config.sortField = field
            this.config.sortDirection = order

            this.loadAsyncData()
        },

        onFilterChange(filter) {
            this.config.filters = filter
            this.loadAsyncData()
        },

        onPageChange(page) {
            this.config.page = page
            this.loadAsyncData()
        },

        getCampaignPeriod(infos) {
            return new Date(JSON.parse(infos).period).toLocaleDateString('fr-fr', {
                month: 'long',
                year: 'numeric'
            })
        },

        addCredits(orderId) {
            this.isLoading = true
            this.advanceCredit(orderId)
                .then(() => {
                    this.reloadData()
                })
                .catch(({response}) => {
                    this.isLoading = false
                })
        },
        generateInvoice(orderId) {
            this.isLoading = true
            this.generateInvoiceAction(orderId)
                .then(() => {
                    this.reloadData()
                })
                .catch(({response}) => {
                    this.isLoading = false
                })
        },

        generateQuote(orderId) {
            this.isLoading = true
            this.generateQuoteAction(orderId)
                .then(() => {
                    this.reloadData()
                })
                .catch(({response}) => {
                    this.isLoading = false
                })
        },
        markAsPaid(orderId) {
            this.isLoading = true
            this.markAsPaidAction(orderId)
                .then(() => {
                    this.reloadData()
                })
                .catch(({response}) => {
                    this.isLoading = false
                })
        },
        deleteOrder(orderId) {
            this.isLoading = true
            this.delete(orderId)
                .then(() => {
                    this.reloadData()
                })
                .catch(({response}) => {
                    this.isLoading = false
                })
        },
        downloadPdf(orderId, type) {
            this.downloadPdfAction({orderId, type}).then(response => {
                Download.download(response, orderId + '-' + type + '.pdf')
            })
        }
    }
}
</script>

<style scoped>
.downloadPDF {
    white-space: nowrap;
    font-size: 11px;
    color: white;
}

.downloadPDF i {
    margin-left: 3px;
}
</style>
