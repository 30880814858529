<template>
    <div>
        <b-tooltip label="Modifier les crédits">
            <a
                href="#"
                class="rounded button is-light rounded-lg text-decoration-none mr-1"
                @click.prevent="handleInput(target)"
            >
                <b-icon icon="coins" />
            </a>
        </b-tooltip>

        <CreditModal ref="creditModal" @hide="$emit('reload')" />
    </div>
</template>

<script>
    import CreditModal from '../Modal/CreditModal'

    export default {
        name: 'ModifyCredits',
        components: { CreditModal },
        model: {
            prop: 'target',
            event: 'click'
        },
        props: {
            target: {
                type: Object,
                required: true
            }
        },
        data: () => ({}),
        methods: {
            handleInput(value) {
                this.$store.commit('consultants/setTarget', value)
                this.$refs.creditModal.show()
            }
        }
    }
</script>
