<template>
    <div>
        <!--        <export-button-->
        <!--          v-if="data.data && data.data.length > 0"-->
        <!--          fileName="export_urls.xlsx"-->
        <!--          :sheets="[-->
        <!--            {-->
        <!--              name: 'Feuille 1',-->
        <!--              data: data.data-->
        <!--                ? data.data.map((e) => {-->
        <!--                    return {-->
        <!--                      URL: e.url,-->
        <!--                      [`${$t('partenaire-mot-cles.gain')}`]: e.price,-->
        <!--                      [`${$t('partenaire-mot-cles.minigain')}`]: `${-->
        <!--                        e.min_gain-->
        <!--                      }€  ${-->
        <!--                        e.min_gain_default-->
        <!--                          ? ''-->
        <!--                          : `(${$t('partenaire-mot-cles.global-site')})`-->
        <!--                      }`,-->
        <!--                      [`${$t('global.keywords')}`]: e.keywords_count,-->
        <!--                      [`${$t('partenaire-mot-cles.visibility')}`]:-->
        <!--                        !e.hasExternalLinks &&-->
        <!--                        e.price >= e.min_gain &&-->
        <!--                        !e.classError &&-->
        <!--                        !e.scrapError-->
        <!--                          ? $t('global.yes')-->
        <!--                          : $t('global.no'),-->
        <!--                      [`${$t('partenaire-mot-cles.error.externalLinks')}`]:-->
        <!--                        e.hasExternalLinks-->
        <!--                          ? e.externalLinks-->
        <!--                          : $t('global.none'),-->
        <!--                      [`${$t('partenaire-mot-cles.error.scrap')}`]: e.scrapError-->
        <!--                        ? e.curlStatus-->
        <!--                        : $t('favorites.none'),-->
        <!--                      [`${$t('partenaire-mot-cles.saleablePage')}`]:-->
        <!--                        e.classError ? e.class : $t('global.no'),-->
        <!--                      [`${$t('partenaire-mot-cles.isScrapped')}`]: e.isScrapped-->
        <!--                        ? $t('global.yes')-->
        <!--                        : $t('global.no'),-->
        <!--                    };-->
        <!--                  })-->
        <!--                : [],-->
        <!--            },-->
        <!--          ]"-->
        <!--          className="ml-2"-->
        <!--        />-->
        <!--      </div>-->
        <!--    </div>-->

        <b-taglist v-if="priceSum" attached>
            <b-tag type="is-warning" size="is-medium">
                {{ $t('partenaire-mot-cles.potentialGain') }}
            </b-tag>
            <b-tag type="is-dark" size="is-medium"> {{ priceSum }}€</b-tag>
        </b-taglist>

        <b-skeleton v-else animated :width="160" :height="30" />

        <nl-table
            :actions="actions"
            :checked-rows.sync="checkedRows"
            :columns.sync="columns"
            :force-update="forceUpdate"
            :get-data="loadAsyncData"
            checkable
        >
            <template
                #keywords="{
                    props: {
                        row: { keywords, keywords_count }
                    }
                }"
            >
                <b-button
                    v-if="keywords_count"
                    type="is-light"
                    icon-left="eye"
                    :label="String(keywords_count)"
                    @click="$emit('displayKeywords', keywords)"
                />
                <div v-else>-</div>
            </template>
            <template
                #floor_price="{
                    props: {
                        row: { floor_price }
                    }
                }"
            >
                {{
                    floor_price
                        ? `${floor_price}€`
                        : `${site?.prix_minimum_filtre || 0}€ (${$t('partenaire-mot-cles.global-site')})`
                }}
            </template>
            <template
                #saleable="{
                    props: {
                        row: { saleable }
                    }
                }"
            >
                <b-icon v-if="saleable" type="is-success" icon="check" />
                <b-icon v-else type="is-danger" icon="times" />
            </template>
            <template
                #actions="{
                    props: {
                        row: { url }
                    }
                }"
            >
                <ScrapButton
                    :item="{
                        id: site.id,
                        url: url
                    }"
                    :call-back="() => $emit('reset')"
                />
            </template>
        </nl-table>

        <SiteKeywordsConfirmModal ref="confirmModal" :checked-rows="checkedRows" @uncheckRows="checkedRows = []" />
    </div>
</template>

<script>
    import ScrapButton from '@/components/Sites/ScrapButton'
    // import ExportButton from "../Tools/ExportButton.vue";
    import NlTable from '@/components/UI/Table/NlTable.vue'
    import { mapActions } from 'vuex'
    import SiteKeywordsConfirmModal from '@/components/PartnerSites/SiteKeywordsConfirmModal.vue'

    export default {
        name: 'SiteKeywordsTable',
        components: {
            SiteKeywordsConfirmModal,
            NlTable,
            ScrapButton
            // ExportButton,
        },
        props: {
            forceUpdate: { required: true, type: Boolean },
            site: { required: true, type: Object },
            saleable: { required: false, type: Boolean, default: null }
        },

        data() {
            return {
                checkedRows: [],
                bulkActionType: 'prix-plancher',

                priceSum: null,

                actions: [
                    {
                        label: this.$t('partenaire-mot-cles.minigaindefine'),
                        action: () => {
                            this.openConfirmModal('prix-plancher')
                        }
                    },
                    {
                        label: this.$t('partenaire-mot-cles.blacklist'),
                        action: () => {
                            this.openConfirmModal('blacklist')
                        }
                    },
                    {
                        label: this.$t('partenaire-mot-cles.reinitminigainurl'),
                        action: () => {
                            this.openConfirmModal('reinitialiser')
                        }
                    },
                    {
                        label: this.$t('partenaire-mot-cles.global-min-gain-define'),
                        action: () => {
                            this.openConfirmModal('global-reset')
                        },
                        allowEmpty: true
                    }
                ],

                buttons: [
                    {
                        label: this.$t('partenaire-mot-cles.global-min-gain-define'),
                        action: () => {
                            this.openConfirmModal('global-reset')
                        }
                    }
                ],

                columns: [
                    {
                        label: 'URL',
                        field: 'url',
                        centered: false
                    },
                    {
                        label: this.$t('partenaire-mot-cles.gain'),
                        field: 'price',
                        suffix: '€'
                    },
                    {
                        label: this.$t('partenaire-mot-cles.minigain'),
                        field: 'floor_price',
                        custom: true
                    },
                    {
                        label: this.$t('partenaire-mot-cles.visibility'),
                        field: 'saleable',
                        custom: true
                    },
                    {
                        label: this.$t('global.keywords'),
                        field: 'keywords',
                        custom: true
                    },
                    {
                        label: 'Logs',
                        field: 'actions',
                        custom: true
                    }
                ]
            }
        },

        methods: {
            ...mapActions('sites', ['getUrlsBySite', 'getUrlsPriceSumBySite']),
            loadAsyncData(params) {
                return this.getUrlsBySite({
                    id: this.$route.params.id,
                    params: {
                        ...params,
                        filter: {
                            ...params.filter,
                            ...(this.saleable !== null ? { is_saleable: this.saleable } : {})
                        },
                        include: ['keywords', 'keywords_count']
                    }
                })
            },

            openConfirmModal(action) {
                this.$refs.confirmModal.openModal(action)
            }
        },
        async created() {
            this.priceSum =
                (await this.getUrlsPriceSumBySite({
                    id: this.$route.params.id,
                    params: {
                        filter: {
                            ...(this.saleable !== null ? { is_saleable: this.saleable } : {})
                        }
                    }
                })) || 0
        }
    }
</script>
