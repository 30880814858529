<template>
    <div>
        <b-field grouped group-multiline>
            <div class="control">
                <b-taglist attached>
                    <b-tag type="is-warning" size="is-medium"> Capacité de traitement</b-tag>
                    <b-tag type="is-dark" size="is-medium">
                        {{ parseInt(kpiCounts.maxWordsPerDay).toLocaleString() }}
                    </b-tag>
                </b-taglist>
            </div>
            <div class="control">
                <b-taglist attached>
                    <b-tag type="is-warning" size="is-medium"> Nombre de jours</b-tag>
                    <b-tag type="is-dark" size="is-medium">
                        {{ dailyWordsCount }}
                    </b-tag>
                </b-taglist>
            </div>
            <div class="control">
                <b-taglist attached>
                    <b-tag type="is-warning" size="is-medium"> Kill duplicate</b-tag>
                    <b-tag type="is-dark" size="is-medium">
                        {{ credit }}
                    </b-tag>
                </b-taglist>
            </div>
        </b-field>
        <b-table :data="statsData.data" :columns="statsData.columns" />
    </div>
</template>

<script>
    export default {
        name: 'WritingKpis',
        props: {
            kpiCounts: {
                type: Object,
                required: true
            },
            credit: {
                type: Number,
                required: true
            }
        },
        computed: {
            dailyWordsCount() {
                return (
                    Math.round(
                        (parseInt(this.kpiCounts.words_remaining.asap) / parseInt(this.kpiCounts.maxWordsPerDay)) * 100
                    ) / 100
                ).toFixed(1)
            },
            statsData() {
                let data = [
                    {
                        type: 'Commandes',
                        count: this.kpiCounts.entity_type.content + ' (' + this.kpiCounts.contentStats.nonAsap + ')',
                        words:
                            this.kpiCounts.contentStats.words + ' (' + this.kpiCounts.contentStats.nonAsapWords + ')',
                        effectiveTreatmentCapacity: this.kpiCounts.contentStats.effectiveTreatmentCapacity,
                        days: parseFloat(this.kpiCounts.contentStats.workingDaysCount).toFixed(2)
                    },
                    {
                        type: 'Précommandes',
                        count: this.kpiCounts.entity_type.preorder,
                        words: this.kpiCounts.preorderStats.words,
                        effectiveTreatmentCapacity: this.kpiCounts.preorderStats.effectiveTreatmentCapacity,
                        days: parseFloat(this.kpiCounts.preorderStats.workingDaysCount).toFixed(2)
                    },
                    {
                        type: 'Spots',
                        count: this.kpiCounts.entity_type.spot,
                        words: this.kpiCounts.spotStats.words,
                        effectiveTreatmentCapacity: this.kpiCounts.spotStats.effectiveTreatmentCapacity,
                        days: parseFloat(this.kpiCounts.spotStats.workingDaysCount).toFixed(2)
                    },
                    {
                        type: 'Total',
                        count:
                            this.kpiCounts.entity_type.content +
                            this.kpiCounts.entity_type.preorder +
                            this.kpiCounts.entity_type.spot +
                            ' (' +
                            this.kpiCounts.contentStats.nonAsap +
                            ')',
                        words:
                            this.kpiCounts.spotStats.words +
                            this.kpiCounts.preorderStats.words +
                            this.kpiCounts.contentStats.words +
                            ' (' +
                            this.kpiCounts.contentStats.nonAsapWords +
                            ')',
                        effectiveTreatmentCapacity:
                            this.kpiCounts.spotStats.effectiveTreatmentCapacity +
                            this.kpiCounts.preorderStats.effectiveTreatmentCapacity +
                            this.kpiCounts.contentStats.effectiveTreatmentCapacity,
                        days: parseFloat(
                            this.kpiCounts.spotStats.workingDaysCount +
                                this.kpiCounts.preorderStats.workingDaysCount +
                                this.kpiCounts.contentStats.workingDaysCount
                        ).toFixed(2)
                    }
                ]

                let columns = [
                    {
                        field: 'type',
                        label: 'Type'
                    },
                    {
                        field: 'count',
                        label: 'NB de rédactions (dont non ASAP)',
                        centered: true
                    },
                    {
                        field: 'words',
                        label: 'NB de mots (dont non ASAP)',
                        centered: true
                    },
                    {
                        field: 'effectiveTreatmentCapacity',
                        label: 'Capacité de traitement effective',
                        centered: true
                    },
                    {
                        field: 'days',
                        label: 'Nombre de jours',
                        centered: true
                    }
                ]

                return {
                    data,
                    columns
                }
            }
        }
    }
</script>

<style scoped></style>
