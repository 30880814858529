<template>
    <div v-if="status != 'Arrêtée'">
        <b-tooltip position="is-right">
            <template v-slot:content>
                <div class="text-left">
                    <i class="fas fa-check text-success pr-2" />{{ $t('precommande.status.todo') }}<br />
                    <i class="fas fa-check pr-2" :class="[isInWriting ? 'text-success' : 'text-dark']" />{{
                        $t('precommande.status.inprogress')
                    }}<br />
                    <i class="fas fa-check pr-2" :class="[isPendingPositionning ? 'text-success' : 'text-dark']" />{{
                        $t('precommande.status.awaiting')
                    }}<br />
                    <i class="fas fa-check pr-2" :class="[isDone ? 'text-success' : 'text-dark']" />{{
                        $t('precommande.status.done')
                    }}
                </div>
            </template>

            <span :class="statusClass">{{ status }}</span>
        </b-tooltip>
    </div>
    <div v-else>
        <span :class="statusClass">{{ status }}</span>
    </div>
</template>

<script>
    export default {
        name: 'Status',
        props: ['status'],
        data: function () {
            return {
                isTodo: true,
                isInWriting: false,
                isPendingPositionning: false,
                isDone: false,
                isStopped: false,
                statusClass: []
            }
        },
        watch: {
            status: function (newValue) {
                this.getCurrentStep()
            }
        },
        created() {
            this.getCurrentStep()
        },
        mounted() {},
        methods: {
            init: function () {
                this.isTodo = true
                this.isInWriting = false
                this.isPendingPositionning = false
                this.isDone = false
                this.isStopped = false

                this.statusClass = []
                this.statusClass.push('badge')
            },
            getCurrentStep: function () {
                this.init()

                switch (this.status) {
                    case 'A faire':
                        this.statusClass.push('badge-danger')
                        break
                    case 'En rédaction':
                        this.isInWriting = true
                        this.statusClass.push('badge-warning')
                        break
                    case 'Attente positionnement':
                        ;(this.isInWriting = true), (this.isPendingPositionning = true)
                        this.statusClass.push('badge-warning')
                        break
                    case 'Achetée':
                    case 'Fait':
                        this.isInWriting = true
                        this.isPendingPositionning = true
                        this.isDone = true
                        this.statusClass.push('badge-success')
                        break
                    case 'Arrêtée':
                        this.statusClass.push('badge-dark')
                        break
                    default:
                        this.statusClass.push('badge-warning')
                }
            }
        }
    }
</script>
