<template>
    <nl-modal ref="modal" title="Créditer un utilisateur" :add-legacy-events="false" position="right" size="lg">
        <template v-slot:header>
            <div v-if="target" class="bg-antracite text-white w-100 text-center p-4">
                <h1>
                    <span v-if="credits.type === 'add'"> Créditer </span>
                    <span v-else> Débiter </span>
                    {{ target.firstname }} {{ target.lastname }}
                </h1>
            </div>
        </template>

        <div v-if="target" class="text-left">
            <CreditForm />
            <div class="text-center">
                <button
                    class="btn-action btn-third mt-2 mb-2 mb-xl-4"
                    :disabled="loading || !credits.amount || credits.amount <= 0"
                    @click.prevent="save"
                >
                    <i v-if="loading" class="fas fa-circle-notch fa-spin mr-1" />
                    <span v-if="credits.type === 'add'"> Créditer </span>
                    <span v-else> Débiter </span>

                    {{ target.firstname }} {{ target.lastname }}
                </button>
            </div>
        </div>

        <template v-slot:footer>
            <div class="d-flex w-100 justify-content-start">
                <b-button type="button" class="btn btn-danger" @click="$refs.modal.hide()"> Annuler</b-button>
            </div>
        </template>
    </nl-modal>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import CreditForm from '../Form/CreditForm'
    import AgencyModal from './AgencyModal'

    export default {
        name: 'CreditModal',
        components: {
            CreditForm
        },
        mixins: [AgencyModal],
        data: () => ({
            loading: false
        }),
        computed: {
            ...mapState('consultants', ['target', 'credits']),
            isCredit() {
                return this.credits.type === 'add'
            }
        },
        methods: {
            ...mapActions('consultants', ['creditUser', 'debitUser']),
            save() {
                this.loading = true
                let endpoint = this.isCredit ? this.creditUser() : this.debitUser()
                endpoint
                    .then(() => {
                        if (this.isCredit) {
                            this.$store.commit('auth/subCredits', this.credits.amount)
                        } else {
                            this.$store.commit('auth/addCredits', this.credits.amount)
                        }

                        const credits = {
                            type: 'add',
                            amount: null,
                            label: null
                        }

                        this.$store.commit('consultants/setCredits', credits)

                        this.hide()
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
        mounted() {}
    }
</script>
