<template>
    <div v-if="!preloading">
        <label>{{ label }}</label>
        <autocomplete
            :search="search"
            :get-result-value="getResultValue"
            :debounce-time="500"
            :default-value="defaultValue"
            @submit="handleSubmit"
        />
    </div>
</template>

<script>
    import Autocomplete from '@trevoreyre/autocomplete-vue'
    import { mapActions } from 'vuex'

    export default {
        components: {
            Autocomplete
        },
        props: {
            label: {
                type: String,
                required: true
            },
            value: {
                type: Number
            }
        },
        data: () => ({
            preloading: true,
            defaultValue: null
        }),
        computed: {
            userId: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        created() {
            if (this.userId) {
                this.getUser(this.userId)
                    .then(user => {
                        this.defaultValue = user.id
                    })
                    .finally(() => {
                        this.preloading = false
                    })
            } else {
                this.preloading = false
            }
        },
        methods: {
            ...mapActions('users', {
                searchUser: 'search',
                getUser: 'get'
            }),
            search(q) {
                if (q.length < 1) {
                    return []
                }
                return this.searchUser(q)
            },
            getResultValue(result) {
                return result.email
            },
            handleSubmit(result) {
                this.userId = result.id
            }
        }
    }
</script>

<style scoped>
    .autocomplete {
        width: 100%;
    }

    .autocomplete-input {
        width: 100%;
        padding: 5px 10px;
    }

    .autocomplete-result-list {
        z-index: 1;
        background-color: white;
    }

    .autocomplete-result {
        padding: 5px 10px;
    }
</style>
