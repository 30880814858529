<template>
    <div>
        <div v-show="editing && !readonly">
            <select
                id="frm_followup_status"
                ref="select"
                :value="thematicPeriod ? thematicPeriod.status : ''"
                class="form-control"
                @change="onChange($event)"
                @keydown.esc="editing = false"
                @blur="editing = false"
            >
                <option value="">-</option>
                <option v-for="(status, i) in statutesAvailable" :key="i" :value="status">
                    {{ $t('accompagnement.action.status.' + status) }}
                </option>
            </select>
        </div>
        <div v-show="!editing">
            <a class="d-flex w-100 h-100 justify-content-center align-items-center" @dblclick="edit">
                <b-tooltip v-if="thematicPeriod && thematicPeriod.status" position="is-top">
                    <template v-slot:content>
                        {{
                            thematicPeriod && thematicPeriod.created_at
                                ? 'Creation : ' + $d(Date.parse(thematicPeriod.created_at), 'short')
                                : ''
                        }}
                        <br />
                        {{
                            thematicPeriod && thematicPeriod.updated_at
                                ? 'Mise à jour : ' + $d(Date.parse(thematicPeriod.updated_at), 'short')
                                : ''
                        }}
                    </template>
                    <span
                        class="badge"
                        :class="{
                            'badge-warning': thematicPeriod.status === 'todo',
                            'badge-darkorange': thematicPeriod.status === 'in_progress',
                            'badge-danger': thematicPeriod.status === 'awaiting',
                            'badge-success': thematicPeriod.status === 'done'
                        }"
                    >
                        {{ $t('accompagnement.action.status.' + thematicPeriod.status) }}
                    </span>
                </b-tooltip>
                <span v-else class="text-muted" style=""> - </span>
            </a>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        props: {
            thematicPeriod: {
                required: true
            },
            statutesAvailable: {
                required: true,
                type: Array,
                default: () => {
                    return []
                }
            },
            readonly: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                editing: false
            }
        },
        computed: {
            ...mapState('campaigns', {
                campaignId: state => state.campaign.id
            })
        },
        methods: {
            ...mapActions('campaigns', ['deleteCampaignActions']),
            edit() {
                this.editing = true
                this.$refs.select.focus()
            },
            onChange(e) {
                if (e.target.value === '') {
                    this.delete()
                } else if (e.target.value && !this.thematicPeriod) {
                    this.create(e.target.value)
                } else {
                    this.update(e.target.value)
                }
                this.editing = false
            },
            update(value) {
                this.$emit('update', value)
            },
            create(value) {
                this.$emit('create', value)
            },
            delete() {
                this.deleteCampaignActions({
                    campaignId: this.campaignId,
                    actionId: this.thematicPeriod.id
                })
            }
        }
    }
</script>

<style scoped></style>
