<template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form :autocomplete="autocomplete" @submit.prevent="handleSubmit(onSubmit)">
            <slot />
        </form>
    </ValidationObserver>
</template>

<script>
    import { ValidationObserver } from 'vee-validate'

    export default {
        components: {
            ValidationObserver
        },
        props: {
            autocomplete: {
                type: String,
                default: () => 'on'
            }
        },
        methods: {
            onSubmit() {
                this.$emit('submit')
            },
            validate() {
                return this.$refs.form.validate()
            },
            reset() {
                return this.$refs.form.reset()
            }
        }
    }
</script>

<style></style>
