<template>
    <nl-modal ref="modal" :title="`Ajouter un/des article(s)`" :add-legacy-events="false" position="center" size="md">
        <div class="d-flex justify-content-between" style="gap: 1rem">
            <div class="w-50">
                <b-field label="H1" class="min-width-input">
                    <b-input v-model="tagTitles" rows="15" type="textarea" placeholder="Titre" />
                </b-field>
                <p><b>Nombre de titres :</b> {{ tagTitles ? tagTitles.split('\n').length : 0 }}</p>
            </div>
            <div class="w-50">
                <b-field label="Meta Title">
                    <b-input v-model="metaTitles" rows="15" type="textarea" placeholder="Meta Title" />
                </b-field>
                <p><b>Nombre de titres :</b> {{ metaTitles ? metaTitles.split('\n').length : 0 }}</p>
            </div>
        </div>
        <div v-if="tagTitles">
            Vous allez générer {{ tagTitlesLength }} article{{ tagTitlesLength > 1 ? 's' : '' }} sur
            {{ nbSpots }} spot{{ nbSpots > 1 ? 's' : '' }} pour un coût de
            <b-taglist attached style="display: inline">
                <b-tag type="is-warning" size="is-medium">{{ cost }}</b-tag>
                <b-tag type="is-dark" size="is-medium">crédit{{ cost > 1 ? 's' : '' }}</b-tag>
            </b-taglist>
        </div>
        <template #footer>
            <b-button type="is-primary" @click="hide">Annuler</b-button>
            <b-button type="is-warning" :disabled="!(tagTitles.length > 0)" @click="onSave"> Ajouter</b-button>
        </template>
    </nl-modal>
</template>

<script>
    export default {
        name: 'AddContentItemModal',
        props: {
            spotId: {
                type: Number,
                required: false,
                default: null
            },
            spotIds: {
                type: Array,
                required: false,
                default: () => []
            }
        },
        data() {
            return {
                showModal: true,
                tagTitles: '',
                metaTitles: ''
            }
        },
        computed: {
            computedTitles() {
                return {
                    tag_title: this.tagTitles.split('\n'),
                    meta_title: this.metaTitles.split('\n')
                }
            },
            nbSpots() {
                return this.spotId ? 1 : this.spotIds.length
            },
            cost() {
                return this.tagTitlesLength * this.nbSpots
            },
            tagTitlesLength() {
                return this.tagTitles.split('\n').filter(line => line.trim() !== '').length
            }
        },
        methods: {
            show() {
                this.tagTitles = ''
                this.metaTitles = ''
                this.$refs.modal.show()
            },
            hide() {
                this.$refs.modal.hide()
            },
            onSave() {
                if (this.tagTitles.length > 0) {
                    this.$emit('addContentItem', {
                        spot_id: this.spotId,
                        ...this.computedTitles
                    })
                    this.hide()
                }
            }
        }
    }
</script>