<template>
    <div class="">
        <div class="d-flex flex-column flex-md-row justify-content-between">
            <div class="d-flex flex-column flex-md-row">
                <div class="px-2 d-flex align-items-center">
                    <input v-model="perPage" type="number" class="form-control w-25" @keypress.enter="loadAsyncData" />
                    <p class="control ml-2 mt-2 mb-2">
                        {{ $t('global.per-page') }}
                    </p>
                </div>
            </div>

            <form class="d-flex flex-column flex-md-row">
                <b-field>
                    <b-select v-model="selectedProject" :placeholder="$t('global.actions')">
                        <option v-for="project in projects" :key="project.id" :value="project">
                            {{ `${$t('global.mooveproject')} ${project.name}` }}
                        </option>
                    </b-select>
                </b-field>
                <b-button
                    :disabled="!checkedRows.length || selectedProject === null"
                    class="mb-2 mb-md-0 ml-md-2"
                    type="is-warning"
                    @click="openConfirmModal(selectedProject)"
                >
                    {{ $t('global.validate') }}
                </b-button>
            </form>
        </div>

        <b-table
            v-if="orders?.data"
            :data="orders.data"
            :mobile-cards="true"
            :debounce-search="400"
            default-sort-direction="desc"
            :striped="true"
            :loading="loading"
            :paginated="true"
            :default-sort="[sortField, sortOrder]"
            :per-page="orders.per_page"
            :total="orders.total"
            backend-sorting
            backend-pagination
            backend-filtering
            :checked-rows.sync="checkedRows"
            :checkable="true"
            checkbox-position="right"
            @page-change="onPageChange"
            @sort="onSort"
            @filters-change="onFilterChange"
        >
            <b-table-column
                centered
                field="id"
                width="1em"
                sortable
                :searchable="isSearchable"
                :label="$t('global.ref')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">#{{ props.row.id }}</div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.ref')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="statut"
                width="9em"
                sortable
                :searchable="isSearchable"
                :label="$t('global.status')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <TrackingOrderStatus :order="props.row" />
                </div>

                <div slot="searchable" slot-scope="props">
                    <select v-model="props.filters[props.column.field]" class="form-control form-control-sm p-1">
                        <option :value="props.filters['status']" selected disabled hidden>
                            {{ $t('global.status') }}
                        </option>
                        <option :value="null">
                            {{ $t('global.all') }}
                        </option>
                        <option value="a_valider">
                            {{ $t('status.waitingforvalidation') }}
                        </option>
                        <option value="en_cours">
                            {{ $t('status.redaction') }}
                        </option>
                        <option value="termine">
                            {{ $t('status.published') }}
                        </option>
                        <option value="annule">
                            {{ $t('status.canceled') + '/' + $t('status.refused') }}
                        </option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="bought_kw"
                sortable
                :searchable="isSearchable"
                :label="$t('global.keyword')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.bought_kw">{{ props.row.bought_kw }}</span>
                    <b-tooltip v-else :label="$t('suivi.cannot-found-history')">
                        <span class="tag is-light is-danger">
                            <i class="fa fa-exclamation-triangle" />
                        </span>
                    </b-tooltip>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.keyword')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="project"
                sortable
                width="8em"
                :searchable="isSearchable"
                :label="$t('global.project')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="editProject !== props.row.id" class="is-editable" @click="editProject = props.row.id">
                        {{ props.row.project ? props.row.project.name : $t('global.none') }}
                    </span>

                    <div v-if="editProject === props.row.id" class="form-group form-group-sm">
                        <select
                            v-model="props.row.project_id"
                            class="form-control"
                            @change="
                                $emit('moveProject', {
                                    project_id: props.row.project_id,
                                    ids: [props.row.id]
                                })
                                editProject = null
                            "
                            @keydown.esc="editProject = null"
                        >
                            <option v-for="project in projects" :key="project.id" :value="project.id">
                                {{ project.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option :value="props.filters['project']" selected disabled hidden>
                                {{ $t('global.project') }}
                            </option>
                            <option v-for="project in projects" :key="project.id" :value="project.id">
                                {{ project.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="option_mots"
                sortable
                :searchable="isSearchable"
                :label="$t('suivi.suppword')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ props.row.option_mots > 0 ? props.row.option_mots : 0 }}</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('suivi.suppword')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="url"
                sortable
                :searchable="isSearchable"
                :label="$t('links&anchors.link')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.statut === 'termine'">{{ props.row.url }}</span>
                    <span v-else-if="props.row.statut === 'annule' || props.row.statut === 'refuse'">{{
                        $t('status.canceled')
                    }}</span>
                    <span v-else>{{ $t('suivi.availablewhenpublished') }}</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('links&anchors.link')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="link_url"
                sortable
                :searchable="isSearchable"
                :label="$t('links&anchors.wantedlinks')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <p class="mb-0">
                        <span>{{ props.row.link_url }}</span>
                    </p>
                    <p v-if="props.row.link_second_url" class="mb-0">
                        <span>{{ props.row.link_second_url }}</span>
                    </p>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('links&anchors.wantedlinks')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="link_anchor"
                sortable
                :searchable="isSearchable"
                :label="$t('links&anchors.wantedanchors')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <p class="mb-0">
                        {{ props.row.link_anchor }}
                    </p>
                    <p v-if="props.row.link_second_anchor" class="mb-0">
                        {{ props.row.link_second_anchor }}
                    </p>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('links&anchors.wantedanchors')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column centered field="info_url" :label="$t('suivi.url')" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <button
                        v-if="props.row.statut === 'termine'"
                        class="button is-light"
                        @click="openDataUrlModal(props.row)"
                    >
                        {{ $t('suivi.seedatas') }}
                    </button>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="brief"
                sortable
                :searchable="isSearchable"
                :label="$t('global.brief')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <b-tooltip
                        v-if="props.row.statut !== 'refuse' && props.row.statut !== 'annule'"
                        :label="props.row.brief"
                        :triggers="['click']"
                        :auto-close="['outside', 'escape']"
                        multilined
                    >
                        <b-button icon-right="file-alt" type="is-light" />
                    </b-tooltip>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.brief')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="price_paid"
                sortable
                :searchable="isSearchable"
                :label="$t('global.price')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ props.row.price_paid }}€</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.brief')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="created_at"
                width="1em"
                sortable
                :searchable="isSearchable"
                :label="$t('suivi.dateorder')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    {{ moment(props.row.created_at).format('DD/MM/YYYY') }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('suivi.dateorder')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="date_publication_prevue"
                sortable
                :searchable="isSearchable"
                :label="$t('suivi.wantedpublication')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <p v-if="props.row.statut !== 'refuse' && props.row.statut !== 'annule'" class="mb-0">
                        {{
                            props.row.date_publication_prevue
                                ? moment(props.row.date_publication_prevue).format('DD/MM/YYYY')
                                : 'ASAP'
                        }}
                    </p>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('suivi.wantedpublication')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="contenu_date_envoi"
                sortable
                :searchable="isSearchable"
                :label="$t('suivi.datepost')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <p v-if="props.row.statut !== 'refuse' && props.row.statut !== 'annule'" class="mb-0">
                        {{
                            props.row.contenu_date_envoi
                                ? moment(props.row.contenu_date_envoi).format('DD/MM/YYYY')
                                : '-'
                        }}
                    </p>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('suivi.datepost')"
                        />
                    </div>
                </div>
            </b-table-column>
        </b-table>

        <TrackingOrderModal
            ref="confirmModal"
            :checked-rows="checkedRows"
            @uncheckRows="
                checkedRows = []
                selectedProject = null
            "
        />
    </div>
</template>

<script>
    export default {
        name: 'TrackingOrderTableWithoutTab',
        props: ['tabInfo', 'projects'],
        data() {
            return {
                orders: [],
                isSearchable: true,
                bulkActionType: null,
                checkedRows: [],
                loading: false,
                perPage: 20,
                selectedProject: null,
                editProject: null,

                sortField: 'id',
                sortOrder: 'desc',
                filters: '',
                page: 1,

                projectSearchId: '-1',

                isMobileWidth: window.innerWidth < 768
            }
        },
        watch: {
            tabInfo: {
                handler(newValue) {
                    this.orders = newValue.content

                    if (this.loading) {
                        this.loading = false
                    }
                },
                deep: true
            }
        },

        created() {
            this.orders = this.tabInfo.content
        },

        methods: {
            openConfirmModal() {
                this.$refs.confirmModal.openModal(this.selectedProject)
            },

            openDataUrlModal(order) {
                this.$refs.confirmModal.openModal(order)
            },

            selectProject(project) {
                this.selectedProject = { id: project.id, name: project.name }
            },

            /** Table filtering, sorting and pagination methods */
            loadAsyncData() {
                this.loading = true

                this.$emit('loadAsyncData', {
                    page: this.page,
                    sortField: this.sortField,
                    sortOrder: this.sortOrder,
                    filters: this.filters,
                    includes: '',
                    path: this.orders.path,
                    index: this.indexType,
                    per_page: this.perPage,

                    callback() {
                        this.loading = false
                    }
                })
            },

            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order

                this.loadAsyncData()
            },

            onFilterChange(filter) {
                var filters = []
                Object.keys(filter).forEach(element => {
                    var currentFilter = {
                        key: element,
                        value: filter[element]
                    }
                    filters.push(currentFilter)
                })
                this.filters = filters
                this.loadAsyncData()
            },

            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            }
        }
    }
</script>

<style>
    .-mt-1 {
        margin-top: -0.5em;
    }

    .media-content p.text {
        margin-bottom: 0;
    }

    .th-wrap {
        justify-content: center;
    }
</style>
