<template>
    <div>
        <b-tooltip label="Modifier">
            <a
                href="#"
                class="rounded button is-warning is-light rounded-lg text-decoration-none mr-1"
                @click.prevent="handleInput"
            >
                <b-icon icon="pen" />
            </a>
        </b-tooltip>

        <CreateConsultantModal ref="modal2" @reload="$emit('reload')" />
    </div>
</template>

<script>
    import CreateConsultantModal from '../Modal/CreateConsultantModal'

    export default {
        name: 'EditEmployeeButton',
        components: {
            CreateConsultantModal
        },
        model: {
            prop: 'target',
            event: 'click'
        },
        props: {
            target: {
                type: Object,
                required: true
            },
            type: {
                type: String,
                required: true,
                description: "Accepted values are 'consultant' or 'manager'"
            }
        },
        data: () => ({}),
        methods: {
            handleInput() {
                let data = {
                    type: this.type,
                    id: this.target.id,
                    email: this.target.email,
                    lastname: this.target.lastname,
                    firstname: this.target.firstname,
                    budget_initial: this.target.credit_amount,
                    password: null
                }

                if (this.type === 'consultant') {
                    data.manager_id = this.target.manager_id
                } else {
                    data.consultants = this.target.consultants
                }

                this.$store.commit('consultants/setNewEmployee', data)
                this.$store.commit('consultants/setAction', 'update')

                this.$refs.modal2.show()
            }
        }
    }
</script>
