<template>
    <div>
        <div v-if="loaded">
            <b-table
                :data="consultants.data"
                :default-sort="[config.sort, config.sortDirection]"
                :loading="isLoading"
                :mobile-cards="true"
                :per-page="config.per_page"
                :total="consultants.total"
                backend-filtering
                backend-pagination
                backend-sorting
                paginated
                :current-page.sync="consultants.current_page"
                detailed
                detail-key="id"
                :show-detail-icon="false"
                :opened-detailed="defaultOpenedDetails"
                @sort="onSort"
                @page-change="onPageChange"
                @filters-change="onFilterChange"
            >
                <b-table-column field="role" sortable label="Role" cell-class="align-middle">
                    <template v-slot="props">
                        <span v-if="props.row.role === 'manager'">
                            <b-tooltip label="Manager" class="is-primary">
                                <i class="fa fa-users"/>
                            </b-tooltip>
                        </span>
                        <span v-if="props.row.role === 'consultant'">
                            <b-tooltip label="Consultant" class="text-secondary">
                                <i class="fa fa-user"/>
                            </b-tooltip>
                        </span>
                    </template>
                </b-table-column>
                <b-table-column field="lastname" sortable label="Nom" cell-class="align-middle">
                    <template v-slot="props">
                        <router-link :to="`/agency/consultants?id=${props.row.id}`">
                            {{ props.row.firstname }} {{ props.row.lastname }}
                        </router-link>
                        <b-tooltip
                            :label="'Se connecter au compte de ' + props.row.firstname + ' ' + props.row.lastname"
                            position="is-top"
                        >
                            <LoginAs :user-id="props.row.user_id" class="ml-auto"/>
                        </b-tooltip>
                    </template>
                </b-table-column>
                <b-table-column field="email" sortable label="Email" cell-class="align-middle">
                    <template v-slot="props">
                        {{ props.row.email }}
                    </template>
                </b-table-column>
                <b-table-column
                    field="credit_amount"
                    sortable
                    label="Crédits restant"
                    cell-class="align-middle"
                    numeric
                >
                    <template v-slot="props">
                        {{ props.row.credit_amount | toCurrency }}
                    </template>
                </b-table-column>
                <b-table-column field="clients" sortable label="Clients" cell-class="align-middle" numeric>
                    <template v-slot="props">
                        {{ props.row.clients_count || 0 }}
                    </template>
                </b-table-column>
                <b-table-column field="actions" sortable label="Actions" cell-class="text-right align-middle" numeric>
                    <template v-slot="props">
                        <div class="d-flex justify-content-end text-left">
                            <ModifyCredits :target="props.row" @reload="fetchData"/>

                            <b-tooltip v-if="isAgency && managersExists" label="Affecter à un manager">
                                <b-button
                                    type="is-warning is-light"
                                    class="mr-1"
                                    @click.prevent="defaultOpenedDetails = [props.row.id]"
                                >
                                    <b-icon icon="random"/>
                                </b-button>
                            </b-tooltip>

                            <EditEmployeeButton :target="props.row" type="consultant" @reload="doReload"/>
                            <RemoveEmployeeButton :target="props.row" @reload="doReload"
                                                  v-if=" ['agence', 'admin'].includes(me.role) "/>
                        </div>
                    </template>
                </b-table-column>

                <template #detail="props">
                    <div class="d-flex">
                        <div class="p-0">
                            <b-field label="Attacher le consultant au manager...">
                                <SearchManager
                                    store
                                    @select="option => (props.row.manager_id = option ? option.id : null)"
                                />
                            </b-field>
                        </div>
                        <div class="p-0 flex-grow-1"/>
                        <div class="p-0 flex-shrink-1 d-flex flex-column justify-content-end">
                            <button
                                class="btn btn-primary d-block my-1"
                                :disabled="updating"
                                @click.prevent="defaultOpenedDetails = []"
                            >
                                Annuler
                            </button>
                            <button
                                class="btn btn-secondary d-block my-1"
                                :disabled="updating"
                                @click.prevent="reaffectConsultant(props.index, props.row)"
                            >
                                Sauvegarder
                            </button>
                        </div>
                    </div>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import ModifyCredits from '@/components/Agency/Button/ModifyCredits.vue'
import {mapActions, mapState} from 'vuex'
import LoginAs from '@/components/Admin/LoginAs.vue'
import EditEmployeeButton from '@/components/Agency/Button/EditEmployeeButton.vue'
import SearchManager from '@/components/Agency/Button/Search/SearchManager.vue'
import RemoveEmployeeButton from "@/components/Agency/Button/RemoveAgencyUserButton.vue";

export default {
    components: {
        RemoveEmployeeButton,
        SearchManager,
        EditEmployeeButton,
        LoginAs,
        ModifyCredits
    },
    data: () => ({
        rows_per_page: 15,
        filters: null,
        sort: null,
        sortOrder: null,
        page: null,
        checkedRows: [],
        isLoading: true,
        loaded: false,
        defaultOpenedDetails: [1],
        updating: false
    }),
    created() {
    },
    computed: {
        ...mapState('consultants', {
            consultants: 'consultants',
            managers: 'managers',
            config: 'consultantsConfig'
        }),
        isAgency() {
            return this.$store.state.auth.user.role === 'admin' || this.$store.state.auth.user.role === 'agence'
        },
        managersExists() {
            return this.$store.state.consultants.managers && this.$store.state.consultants.managers.data.length > 0
        },
        me() {
            return this.$store.state.auth.user
        }
    },
    methods: {
        ...mapActions('auth', {getUser: 'me'}),
        ...mapActions('consultants', {
            createConsultant: 'create',
            updateConsultant: 'update',
            enabledConsultant: 'enabled',
            disablesConsultant: 'disabled',
            addCreditsToConsultant: 'addCredits',
            removeCreditsToConsultant: 'removeCredits',
            bulkAddCreditsToConsultants: 'bulkAddCredits',
            getConsultants: 'getConsultants',
            affectConsultant: 'affectConsultant'
        }),
        viewConsultant(consultant) {
            this.$router.push(`/consultant-details.php?id=${consultant.id}`)
        },
        fetchData() {
            this.isLoading = true
            this.getConsultants()
                .catch(e => {
                    this.$store.dispatch('toastError', e)
                })
                .finally(() => {
                    this.isLoading = false
                    this.loaded = true

                    this.$emit('ready')
                })
        },
        onSort(field, order) {
            this.config.sort = field
            this.config.sortDirection = order

            this.fetchData()
        },
        onFilterChange(filter) {
            var filters = {}
            Object.keys(filter).forEach(element => {
                filters[element] = filter[element]
            })
            this.config.filters = filters

            this.fetchData()
        },
        onPageChange(page) {
            this.config.page = page

            this.fetchData()
        },
        reaffectConsultant(index, consultant) {
            this.updating = true
            const payload = {
                manager_id: consultant.manager_id,
                id: consultant.id
            }
            this.affectConsultant(payload)
                .then(() => {
                    this.defaultOpenedDetails = []
                    this.consultants.data.splice(index, 1)

                    let managerIndex = this.managers.data.findIndex(manager => manager.id === consultant.manager_id)
                    if (managerIndex >= 0) {
                        let manager = this.managers.data[managerIndex]
                        manager.consultants.push(consultant)

                        this.managers.data.splice(managerIndex, 1, manager)
                    }
                })
                .catch(e => {
                    this.$store.dispatch('toastError', e)
                })
                .finally(() => {
                    this.updating = false
                })
        },
        doReload() {
            this.$emit('reload')
        }
    },
    mounted() {
        this.fetchData()
    }
}
</script>

<style>
::v-deep(.is-manager-agency) {
    background-color: #ebeced;
}
</style>
