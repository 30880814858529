<template>
    <fragment>
        <li class="nav-item dropdown">
            <a
                href="#"
                class="nav-link dropdown-toggle p-0"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <span v-if="unread_notifications.length > 0" class="notifications__count">
                    {{ unread_notifications.length }}
                </span>
                <i class="far fa-bell" style="font-size: 1.6rem; color: #2b383f" />
            </a>
            <div class="dropdown-menu dropdown-menu-right">
                <router-link
                    v-for="(notification, index) in unread_notifications"
                    :key="`notification_${index}`"
                    :to="notification.destination || '/notifications'"
                    class="dropdown-item"
                >
                    {{ notification.titre }}
                </router-link>
                <div v-if="unread_notifications.length > 0" class="dropdown-divider" />
                <a class="dropdown-item" @click.prevent="readAllNotifications()">
                    {{ $t('layout.markallasread') }}
                </a>
                <router-link to="/notifications" class="dropdown-item">
                    {{ $t('layout.seeallnotifications') }}
                </router-link>
            </div>
        </li>
    </fragment>
</template>

<style scoped>
    .nav-item {
        position: relative;
    }

    .dropdown-toggle::after {
        content: none;
    }

    .notifications__count {
        top: 0;
        right: 0;
        position: absolute;
        font-weight: bold;
        background-color: red;
        color: white;
        border-radius: 26px;
        font-size: 0.75rem;
        height: 20px;
        min-width: 20px;
        max-width: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        name: 'NotificationsMenu',

        data: () => ({
            loading: false
        }),

        created() {
            this.getNotifications()
        },
        computed: {
            ...mapGetters('notifications', {
                notifications: 'list',
                notificationLoading: 'isLoading',
                notificationLoaded: 'loaded',
                notificationTotal: 'total'
            }),
            unread_notifications: function () {
                return this.notifications.filter(notification => notification.lu === 0)
            }
        },
        methods: {
            ...mapActions('notifications', {
                getNotifications: 'list',
                deleteNotifications: 'delete',
                readNotification: 'setRead',
                readAllNotifications: 'setReadAll'
            })
        }
    }
</script>
