<template>
    <div class="">
        <div class="mb-2" v-if="tabInfo.alert">
            <b-notification
                type="is-warning"
                aria-close-label="Close notification"
                role="alert"
                :message="tabInfo.alert"
            >
            </b-notification>
        </div>

        <nl-table
            :actions="actions"
            :checked-rows.sync="checkedRows"
            :columns.sync="columns"
            :force-update="forceUpdate"
            :get-data="loadAsyncData"
            :left-buttons="leftButtons"
            :searchable="false"
            centered
            default-sort="keywords_count"
            default-sort-direction="desc"
            sortable
        >
            <template #actif="{ props: { row } }">
                <PartnerSiteStatus :site="row" />
            </template>

            <template
                #health="{
                    props: {
                        row: { health }
                    }
                }"
            >
                <b-tag :type="health >= 200 && health < 300 ? 'is-success' : 'is-danger'">
                    {{ health || '-' }}
                </b-tag>
            </template>
            <template
                #healthSearch="{
                    props: {
                        filters,
                        column: { field }
                    }
                }"
            >
                <b-select size="is-small" v-model="filters[field + '_range']">
                    <option :value="null">Tous</option>
                    <option value="100">1XX</option>
                    <option value="200">2XX</option>
                    <option value="300">3XX</option>
                    <option value="400">4XX</option>
                    <option value="500">5XX</option>
                </b-select>
            </template>
            <template #group.localized_translations.nameSearch="{ props }">
                <NlThematicFilter v-model="props.filters.group_id" size="is-small" />
            </template>
            <template
                #concerned_url="{
                    props: {
                        row: { id, actif, scrape_whitelist, scrape_blacklist, scrape_sitemap }
                    }
                }"
            >
                <span v-if="actif !== 0 && actif !== -3">
                    <a
                        :href="`/admin/sites/restrictions?id=${id}`"
                        target="_blank"
                        v-if="scrape_whitelist || scrape_blacklist || scrape_sitemap"
                    >
                        <span class="mr-1" v-if="scrape_sitemap">Sitemap</span>
                        <span class="mr-1" v-if="scrape_whitelist">Whitelist</span>
                        <span v-if="scrape_blacklist">Blacklist</span>
                    </a>
                    <span v-else>{{ $t('partenaire-site.all-site') }}</span>
                </span>
            </template>

            <template #site_type="{ props: { row } }">
                <b-tag v-if="row.site_type === 'or'" class="gold">Or</b-tag>
                <b-tag v-if="row.site_type === 'argent'" class="silver">Argent</b-tag>
                <b-tag v-if="row.site_type === 'bronze'" class="bronze">Bronze</b-tag>
                <b-tag v-if="row.site_type === 'vipor'" class="gold">VIP Or</b-tag>
                <b-tag v-if="row.site_type === 'vipargent'" class="silver">VIP Argent</b-tag>
                <b-tag v-if="row.site_type === 'vipbronze'" class="bronze">VIP Bronze</b-tag>
                <b-tag v-if="row.site_type === 'media'" class="is-info">Media</b-tag>
            </template>

            <template
                #site_typeSearch="{
                    props: {
                        filters,
                        column: { field }
                    }
                }"
            >
                <b-select v-model="filters[field]" size="is-small">
                    <option :value="null">Tous</option>
                    <option value="or">Or</option>
                    <option value="argent">Argent</option>
                    <option value="bronze">Bronze</option>
                    <option value="vipor">VIP Or</option>
                    <option value="vipargent">VIP Argent</option>
                    <option value="vipbronze">VIP Bronze</option>
                    <option value="media">Media</option>
                </b-select>
            </template>

            <template #redactionSearch="{ props }">
                <b-select
                    size="is-small"
                    v-model="props.filters[props.column.field]"
                    @change="editDataChange(props.row)"
                    @keydown.esc="closeFlyEdit"
                >
                    <option :value="null">{{ $t('global.all') }}</option>
                    <option value="nextlevel">Nextlevel</option>
                    <option value="proprietaire">
                        {{ $t('partenaire-site.partner') }}
                    </option>
                </b-select>
            </template>
            <template #contenu_redige_max="{ props }">
                <span>
                    {{ props.row.contenu_redige_max }}
                </span>
            </template>
            <template #keywords_count="{ props }">
                <router-link
                    :to="{ name: 'PartnerKeywords', params: { id: props.row.id } }"
                    class="button is-light text-decoration-none"
                >
                    <span class="mr-1">{{ props.row.keywords_count }}</span>
                    <b-icon icon="eye" size="is-small"></b-icon>
                </router-link>
            </template>
            <template #urls_count="{ props }">
                <router-link
                    :to="{ name: 'PartnerKeywords', params: { id: props.row.id } }"
                    class="button is-light text-decoration-none"
                >
                    <span class="mr-1">{{ props.row.urls_count }}</span>
                    <b-icon icon="eye" size="is-small"></b-icon>
                </router-link>
            </template>
            <template #accept_auto_orders_at="{ props }">
                <b-tooltip :label="$t('partenaire-site.order-auto')" v-if="props.row.accept_auto_orders_at !== null">
                    <b-icon icon="check-circle" size="is-small" type="is-success"></b-icon>
                </b-tooltip>
                <b-tooltip :label="$t('partenaire-site.order-auto')" v-else>
                    <b-icon icon="ban" size="is-small" type="is-danger"></b-icon>
                </b-tooltip>
                /
                <b-tooltip :label="$t('partenaire-site.preorder-auto')" v-if="props.row.accept_preorders_auto === 1">
                    <b-icon icon="check-circle" size="is-small" type="is-success"></b-icon>
                </b-tooltip>
                <b-tooltip :label="$t('partenaire-site.preorder-auto')" v-else>
                    <b-icon icon="ban" size="is-small" type="is-danger"></b-icon>
                </b-tooltip>
            </template>
            <template #orders_count="{ props }">
                <router-link
                    :to="`/partner/sites/orders?id=${props.row.id}`"
                    class="button is-light text-decoration-none"
                    v-if="props.row.actif !== -3 && props.row.orders_count > 0"
                >
                    <span class="mr-1">{{ props.row.orders_count }}</span>
                    <b-icon icon="eye" size="is-small"></b-icon>
                </router-link>
                <span v-else>0</span>
            </template>
            <template #preorders_count="{ props }">
                <router-link
                    :to="`/partner/sites/preorders?id=${props.row.id}`"
                    class="button is-light text-decoration-none"
                    v-if="props.row.actif !== -3 && props.row.preorders_count > 0"
                >
                    <span class="mr-1">{{ props.row.preorders_count }}</span>
                    <b-icon icon="eye" size="is-small"></b-icon>
                </router-link>
                <span v-else>0</span>
            </template>
            <template #orders_total="{ props }">
                <span v-if="props.row.orders_total"> {{ props.row.orders_total.toFixed(2) }}€ </span>
                <span v-else>0€</span>
            </template>
            <template #wp_data="{ props }">
                <NlWordpress :site-data="props.row" site-type="site" />
            </template>
            <template #majestic_data.rdSearch="{ props }">
                <div class="d-flex justify-content-center" style="gap: 0.25rem">
                    <b-numberinput
                        :controls="false"
                        size="is-small"
                        placeholder="min."
                        v-model="props.filters['min_rd_count']"
                        style="min-width: 50px"
                    />
                    <b-numberinput
                        :controls="false"
                        size="is-small"
                        placeholder="max."
                        v-model="props.filters['max_rd_count']"
                        style="min-width: 50px"
                    />
                </div>
            </template>

            <template #notes_customer="{ props }">
                <PartnerSiteNoteModal v-if="props.row.notes_customer" :note="props.row.notes_customer" />
            </template>

            <template #actions="{ props }">
                <a
                    href="#"
                    class="rounded button is-light rounded-lg text-decoration-none"
                    @click.prevent="openSiteModal(props.row)"
                    v-if="props.row.actif === -1 || props.row.actif === 1"
                >
                    <b-icon icon="cog"></b-icon>
                </a>
            </template>
        </nl-table>
        <PartnerSiteConfirmModal :checked-rows="checkedRows" ref="confirmModal" @uncheckRows="checkedRows = []" />
    </div>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable.vue'
    import PartnerSiteStatus from '@/components/PartnerSites/PartnerSiteStatus.vue'
    import { mapActions } from 'vuex'
    import NlThematicFilter from '@/components/Form/NlThematicFilter.vue'
    import PartnerSiteConfirmModal from '@/components/PartnerSites/PartnerSiteConfirmModal.vue'
    import NlWordpress from '@/components/UI/Wordpress/NlWordpress.vue'
    import ColumnEditable from '@/components/Admin/Sites/ColumnEditable.vue'
    import PartnerSiteNoteModal from '@/components/PartnerSites/PartnerSiteNoteModal.vue'

    export default {
        name: 'PartnerSiteTable',
        components: {
            PartnerSiteNoteModal,
            ColumnEditable,
            NlWordpress,
            PartnerSiteConfirmModal,
            NlThematicFilter,
            PartnerSiteStatus,
            NlTable
        },
        props: {
            tabInfo: {
                type: Object
            },
            groups: {
                type: Array
            },
            status: {
                type: Number
            },
            forceUpdate: {
                type: Boolean
            },
            leftButtons: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                sites: [],
                bulkActionType: null,
                checkedRows: [],
                filters: '',
                page: 1,

                editRedaction: null,
                editCategory: null,

                columns: [
                    {
                        label: this.$t('global.status'),
                        field: 'actif',
                        custom: true
                    },
                    {
                        label: this.$t('global.health'),
                        field: 'health',
                        custom: true,
                        customSearch: true,
                        searchable: true
                    },
                    {
                        label: this.$t('global.site'),
                        field: 'url',
                        centered: false,
                        filter: new RegExp(/(http)s?:\/\/(www\.)?|\/$/gi),
                        searchable: true
                    },
                    {
                        label: this.$t('global.category'),
                        field: 'group.localized_translations.name',
                        searchField: 'group_id',
                        aze: 'aze',
                        customSearch: true,
                        searchable: true,
                        width: 300
                    },
                    {
                        label: 'Type de site',
                        field: 'site_type',
                        customSearch: true,
                        custom: true,
                        searchable: true,
                        width: 300
                    },
                    {
                        label: this.$t('partenaire-site.url'),
                        field: 'concerned_url',
                        custom: true
                    },
                    {
                        label: this.$t('partenaire-site.redaction'),
                        field: 'redaction',
                        hide: ['deleted', 'waiting_improvement'].includes(this.tabInfo.type),
                        customSearch: true,
                        searchable: true
                    },
                    {
                        label: `Max ${this.$t('global.words')}`,
                        field: 'contenu_redige_max',
                        custom: true
                    },
                    {
                        label: this.$t('partenaire-site.keywords'),
                        field: 'keywords_count',
                        custom: true,
                        sortable: true,
                        searchable: true
                    },
                    {
                        label: this.$t('partenaire-site.urls'),
                        field: 'urls_count',
                        custom: true,
                        sortable: true,
                        searchable: true
                    },
                    {
                        label: this.$t('partenaire-site.manage-auto'),
                        tooltip: this.$t('partenaire-site.order-preorder-auto'),
                        field: 'accept_auto_orders_at',
                        custom: true
                    },
                    {
                        label: this.$t('global.orders'),
                        field: 'orders_count',
                        hide: this.tabInfo.type === 'waiting_improvement',
                        custom: true
                    },
                    {
                        label: this.$t('global.preorders'),
                        field: 'preorders_count',
                        hide: this.tabInfo.type === 'waiting_improvement',
                        custom: true
                    },
                    {
                        label: this.$t('global.earnings'),
                        field: 'orders_total',
                        hide: this.tabInfo.type === 'waiting_improvement',
                        custom: true
                    },
                    {
                        label: this.$t('global.reason'),
                        field: 'notes_customer',
                        hide: !['deleted', 'waiting_improvement'].includes(this.tabInfo.type),
                        custom: true
                    },
                    {
                        centered: false,
                        label: 'Plugin',
                        field: 'wp_data',
                        custom: true,
                        customSearch: true
                    },
                    {
                        label: 'RD',
                        field: 'majestic_data.rd',
                        customSearch: true,
                        searchable: true
                    },
                    {
                        label: this.$t('global.created-at'),
                        field: 'created_at',
                        visible: false,
                        hide: !['all', 'activated'].includes(this.tabInfo.type)
                    },
                    {
                        label: this.$t('global.actions'),
                        field: 'actions',
                        custom: true
                    }
                ],
                actions: [
                    {
                        label: this.$t('global.desactivate'),
                        action: () => {
                            this.openConfirmModal('desactivate')
                        }
                    },
                    {
                        label: this.$t('global.delete'),
                        action: () => {
                            this.openConfirmModal('delete')
                        }
                    },
                    {
                        label: this.$t('global.reactivate'),
                        action: () => {
                            this.openConfirmModal('reactivate')
                        }
                    },
                    {
                        label: this.$t('partenaire-site.redaction'),
                        action: () => {
                            this.openConfirmModal('redaction')
                        }
                    },
                    {
                        label: this.$t('global.words'),
                        action: () => {
                            this.openConfirmModal('contenu_redige_max')
                        }
                    },
                    {
                        label: this.$t('partenaire-site.accept-auto'),
                        action: () => {
                            this.openConfirmModal('accept_auto_orders')
                        }
                    },
                    {
                        label: this.$t('partenaire-site.accept-auto-preorder'),
                        action: () => {
                            this.openConfirmModal('accept_auto_preorders')
                        }
                    }
                ]
            }
        },

        methods: {
            ...mapActions('partner_sites', {
                getSites: 'getSites'
            }),
            findGroup(groupId) {
                return this.groups.find(group => group.id === groupId)?.name
            },

            openSiteModal(site) {
                site.clear_whitelist = false
                site.clear_blacklist = false

                this.$emit('openModal', site)
            },

            openConfirmModal(bulkActionType) {
                if (bulkActionType === 'accept_auto_orders') {
                    this.automaticOrders()
                } else if (bulkActionType === 'accept_auto_preorders') {
                    this.automaticPreorders()
                } else {
                    this.$refs.confirmModal.openModal(bulkActionType)
                }
            },

            closeFlyEdit() {
                this.editRedaction = null
                this.editCategory = null
                this.editWordsMax = null
            },

            editDataChange(site) {
                this.editRedaction = null
                this.editCategory = null
                this.editWordsMax = null

                site.scrape_blacklist = null
                site.scrape_whitelist = null
                site.validation = 'simple'
                site.blacklisted_groups = JSON.stringify(site.blacklisted_groups)
                this.$emit('editSite', site)
            },

            handleEditCategory(id) {
                this.editCategory = id
            },

            handleEditWordsMax(id) {
                this.editWordsMax = id
            },

            handleEditRedaction(id) {
                this.editRedaction = id
            },

            rescrap(site) {
                if (site.isScrapping) {
                    return
                }

                this.$emit('rescrap', site)
            },

            automaticOrders() {
                this.$emit('setAcceptAutoBulkSites', {
                    ids: this.checkedRows.map(site => site.id)
                })
            },

            automaticPreorders() {
                this.$emit('setAcceptAutoPreorderBulkSites', {
                    ids: this.checkedRows.map(site => site.id)
                })
            },

            loadAsyncData(params) {
                return this.getSites({
                    ...params,
                    filter: {
                        ...params.filter,
                        ...(this.status !== null ? { actif: this.status } : {})
                    }
                })
            }
        }
    }
</script>

<style>
    .-mt-1 {
        margin-top: -0.5em;
    }

    .media-content p.text {
        margin-bottom: 0;
    }

    .is-editable {
        padding: 0.5em;
        border-radius: 5px;
        cursor: pointer;
    }

    .is-editable:hover {
        background: #eee;
    }

    .bronze {
        background-color: #85530f !important;
        color: white !important;
    }

    .gold {
        background-color: #cc9900 !important;
        color: white !important;
    }

    .silver {
        background-color: #d2dee6 !important;
    }
</style>