import i18n from '@/i18n/i18n'
import moment from 'moment'

i18n.locale = 'fr'

export default () => ({
    /*
    |--------------------------------------------------------------------------
    | Admins
    |--------------------------------------------------------------------------
    */
    redactors: null,
    redactorsConfig: {
        include: '',
        sort: 'id',
        sortDirection: '',
        page: 1,
        filters: '',
        per_page: 100
    },

    wordsRemainingToWrite: null,
    maxWordsPerDay: null,

    writings: null,
    savedWritingsConfig: null,
    writingsConfig: {
        include: '',
        sort: '',
        sortDirection: '',
        page: 1,
        filters: '',
        per_page: 100
    },
    /*
    |--------------------------------------------------------------------------
    | Redactors
    |--------------------------------------------------------------------------
    */
    date_from: moment().startOf('month').format('YYYY-MM-DD'),
    date_to: moment().endOf('month').format('YYYY-MM-DD'),

    decline: {
        type: 'dont-want',
        text: ''
    },

    declinePublication: '',

    writing: {
        content: ''
    },
    authors: null,
    categories: null,

    logs: null,
    creditLog: {
        price: null,
        words: null,
        content_id: null
    },

    preorders: null,

    redactor: null,
    redactorWritring: {
        total: null,
        words: null,
        amount: null,
        writingsPerDay: null
    },

    currentTab: 'processing',

    columns: {
        words_count: { active: false, name: i18n.t('redactor.nbWords') },
        lang: { active: false, name: i18n.t('language.title') }
    }
})
