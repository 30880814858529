export default () => ({
    withdrawGains: {
        amount: 0,
        addressPaypal: '',
        iban: '',
        bic: '',
        bankName: '',
        saveDefaultPayment: true,
        invoiceChoice: 'generation',
        firstname: '',
        lastname: '',
        company: '',
        siret: '',
        noSiret: false,
        address: '',
        postalCode: '',
        city: '',
        tvaNumber: '',
        noTva: false,
        invoiceNumber: '',
        cgv: false,
        engagement: false,
        revenu: false
    },

    user: null,
    gain: null,
    withdrawal_this_month: null,
    failed_order: null,

    bonus: 0
})
