<template>
    <div>
        <b-button icon-left="ban" label="Gestion Black & White List" type="is-info" @click="openModal" />
        <b-modal v-model="open" :width="960" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">Gestion Black & White List</h4>
                </header>
                <div class="card-content">
                    <div class="content">
                        <form>
                            <b-field :label="$t('group-site.modal.url_sell')">
                                <b-switch v-model="advancedMode" passive-type="is-danger" type="is-success">
                                    <template v-if="!advancedMode">Mode simple : tout le site</template>
                                    <template v-else>Mode avancé</template>
                                </b-switch>
                            </b-field>

                            <template v-if="advancedMode">
                                <b-notification closable has-icon type="is-info is-light">
                                    {{ $t('partenaire-site.avancedmodedescription') }}
                                </b-notification>

                                <b-field :label="$t('partenaire-site.whitelist')">
                                    <b-input v-model="formData.scrape_sitemap" />
                                </b-field>

                                <b-field :label="$t('partenaire-site.whitelist-textfile')">
                                    <div class="d-flex flex-wrap" style="gap: 0.75rem">
                                        <b-upload v-model="fileWhitelist" drag-drop accept=".txt">
                                            <section
                                                class="p-1 d-flex has-text-centered align-items-center"
                                                style="gap: 0.75rem"
                                            >
                                                <b-icon icon="upload" type="is-warning" />
                                                <span>
                                                    {{ $t('partenaire-site.selecttxt') }}
                                                </span>
                                            </section>
                                        </b-upload>
                                        <b-tag
                                            v-if="fileWhitelist?.name"
                                            size="is-medium"
                                            close-type="is-danger"
                                            attached
                                            closable
                                            @close="fileWhitelist = null"
                                        >
                                            <b-icon icon="file" class="mr-1" />
                                            {{ fileWhitelist.name }}
                                        </b-tag>
                                    </div>
                                    <div v-if="formData.scrape_whitelist_count" class="mt-1">
                                        <b-field>
                                            <b-checkbox v-model="formData.clear_whitelist" type="is-danger">
                                                <!-- todo count url -->
                                                {{ formData.scrape_whitelist_count }}
                                                {{ $t('partenaire-site.whitelisturl') }}
                                            </b-checkbox>
                                        </b-field>
                                    </div>
                                </b-field>

                                <b-field :label="$t('partenaire-site.blacklist-textfile')">
                                    <div class="d-flex flex-wrap" style="gap: 0.75rem">
                                        <b-upload v-model="fileBlacklist" drag-drop accept=".txt">
                                            <section
                                                class="p-1 d-flex has-text-centered align-items-center"
                                                style="gap: 0.75rem"
                                            >
                                                <b-icon icon="upload" type="is-warning" />
                                                <span>
                                                    {{ $t('partenaire-site.selecttxt') }}
                                                </span>
                                            </section>
                                        </b-upload>
                                        <b-tag
                                            v-if="fileBlacklist?.name"
                                            size="is-medium"
                                            close-type="is-danger"
                                            attached
                                            closable
                                            @close="fileBlacklist = null"
                                        >
                                            <b-icon icon="file" class="mr-1" />
                                            {{ fileBlacklist.name }}
                                        </b-tag>
                                    </div>
                                    <div v-if="formData.scrape_blacklist_count" class="mt-1">
                                        <b-field>
                                            <b-checkbox v-model="formData.clear_blacklist" type="is-danger">
                                                <!-- todo count url -->
                                                {{ formData.scrape_blacklist_count }}
                                                {{ $t('partenaire-site.whitelisturl') }}
                                            </b-checkbox>
                                        </b-field>
                                    </div>
                                </b-field>

                                <b-field label="Blacklist admin via fichier texte">
                                    <div class="d-flex flex-wrap" style="gap: 0.75rem">
                                        <b-upload v-model="fileBlacklistAdmin" drag-drop accept=".txt">
                                            <section
                                                class="p-1 d-flex has-text-centered align-items-center"
                                                style="gap: 0.75rem"
                                            >
                                                <b-icon icon="upload" type="is-warning" />
                                                <span>
                                                    {{ $t('partenaire-site.selecttxt') }}
                                                </span>
                                            </section>
                                        </b-upload>
                                        <b-tag
                                            v-if="fileBlacklistAdmin?.name"
                                            size="is-medium"
                                            close-type="is-danger"
                                            attached
                                            closable
                                            @close="fileBlacklistAdmin = null"
                                        >
                                            <b-icon icon="file" class="mr-1" />
                                            {{ fileBlacklistAdmin.name }}
                                        </b-tag>
                                    </div>
                                    <div v-if="formData.admin_scrape_blacklist_count" class="mt-1">
                                        <b-field>
                                            <b-checkbox v-model="formData.clear_blacklist_admin" type="is-danger">
                                                <!-- todo count url -->
                                                {{ formData.admin_scrape_blacklist_count }}
                                                URLs sont définies en blacklist admin. Cliquez ici pour les supprimer
                                            </b-checkbox>
                                        </b-field>
                                    </div>
                                </b-field>
                            </template>

                            <div class="buttons justify-content-end">
                                <button class="button is-light" @click.prevent="closeAndResetModal">
                                    {{ $t('global.cancel') }}
                                </button>
                                <button class="button is-warning" @click.prevent="editSite">
                                    {{ $t('global.modify') }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import { cleanObject } from '@/helpers/objectHelpers'

    export default {
        name: 'BlackWhiteListModal',
        props: {
            site: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                advancedMode: false,
                fileWhitelist: null,
                fileBlacklist: null,
                fileBlacklistAdmin: null,
                errorUrl: '',

                open: false,
                isLoading: false,
                formData: {}
            }
        },
        methods: {
            editSite() {
                this.formData.scrape_whitelist = this.fileWhitelist?.name ? this.fileWhitelist : null
                this.formData.scrape_blacklist = this.fileBlacklist?.name ? this.fileBlacklist : null
                this.formData.scrape_blacklist_admin = this.fileBlacklistAdmin?.name ? this.fileBlacklistAdmin : null

                this.fileWhitelist = []
                this.fileBlacklist = []
                this.fileBlacklistAdmin = []

                let data = cleanObject({
                    scrape_sitemap: this.formData.scrape_sitemap,
                    scrape_whitelist: this.formData.scrape_whitelist,
                    scrape_blacklist: this.formData.scrape_blacklist,
                    scrape_blacklist_admin: this.formData.scrape_blacklist_admin,
                    clear_whitelist: !!this.formData.clear_whitelist,
                    clear_blacklist: !!this.formData.clear_blacklist,
                    clear_blacklist_admin: !!this.formData.clear_blacklist_admin
                })

                this.$emit('editBlackWhiteList', data)
                this.closeAndResetModal()
            },

            counts() {
                this.formData.scrape_whitelist_count =
                    this.formData.scrape_whitelist != null ? JSON.parse(this.formData.scrape_whitelist).length : null
                this.formData.scrape_blacklist_count =
                    this.formData.scrape_blacklist != null ? JSON.parse(this.formData.scrape_blacklist).length : null
                this.formData.admin_scrape_blacklist_count =
                    this.formData.admin_scrape_blacklist != null
                        ? JSON.parse(this.formData.admin_scrape_blacklist).length
                        : null
            },

            openModal() {
                this.open = true
                this.formData = this.site
                this.advancedMode = Boolean(
                    this.site.scrape_sitemap ||
                        this.site.scrape_whitelist ||
                        this.site.scrape_blacklist ||
                        this.site.admin_scrape_blacklist
                )
                this.counts()
            },

            deleteDropFile(type) {
                this.formData[type] = []
            },

            closeAndResetModal() {
                this.open = false
                this.advancedMode = false

                this.$emit('clean-form')

                this.formData = {
                    validation: 'simple',
                    scrape_sitemap: '',
                    scrape_whitelist: [],
                    scrape_blacklist: [],
                    scrape_blacklist_admin: [],
                    clear_whitelist: false,
                    clear_blacklist: false,
                    clear_blacklist_admin: false
                }
            }
        }
    }
</script>

<style>
    label[for^='scrape_'] {
        color: #363636;
        display: block;
        font-size: 1rem;
        font-weight: 700;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
