<script>
    import { Line } from 'vue-chartjs'

    var chartjsPluginWatermark = require('chartjs-plugin-watermark')

    export default {
        extends: Line,
        props: ['data', 'options'],
        watch: {},
        created() {
            this.addPlugin(chartjsPluginWatermark)
        },
        mounted() {},
        methods: {
            render: function () {
                this.renderChart(this.data, this.options)
            }
        }
    }
</script>
