<template>
    <fragment>
        <b-dropdown
            scrollable
            aria-role="list"
            max-height="250"
            position="is-bottom-left"
            :close-on-click="false"
            :expanded="$screen.width < 768"
        >
            <template #trigger="{ active }">
                <b-button
                    type="is-info"
                    icon-left="columns"
                    :expanded="$screen.width < 768"
                    :icon-right="active ? 'caret-up' : 'caret-down'"
                    :label="$t('global.columns')"
                />
            </template>

            <b-dropdown-item v-for="(column, index) in filteredColumns" :key="`column_${index}`" aria-role="listitem">
                <b-field>
                    <b-checkbox v-model="column.visible" checked>
                        {{ column.label }}
                    </b-checkbox>
                </b-field>
            </b-dropdown-item>
        </b-dropdown>
    </fragment>
</template>
<script>
    export default {
        name: 'TableColumnSelect',
        props: {
            value: Array,
            autoSetVisible: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        computed: {
            filteredColumns() {
                return this.value.filter(e => !e.required && !e.hide)
            }
        },
        created() {
            if (this.autoSetVisible) {
                this.$emit(
                    'input',
                    this.value.map(column => {
                        return {
                            visible: true,
                            ...column
                        }
                    })
                )
            }
        }
    }
</script>
<style></style>
