import ApiService from '../../../services/api.service'

export default {
    list(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/users', params)
                .then(({ data }) => {
                    resolve(data)
                    context.commit('setList', data.users)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    search(context, query) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/users/search?q=' + query)
                .then(({ data }) => {
                    resolve(data.users)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    get(context, userId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/users/' + userId)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    create(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.post('/admin/users', model)
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    update(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.put('/admin/users/' + model.id, model)
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    delete(context, userId) {
        return new Promise((resolve, reject) => {
            ApiService.delete('/admin/users/' + userId)
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    addFilleul(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/admin/users/' + params.id + '/filleul', params)
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    checkAddFilleul(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/admin/users/' + params.id + '/check-filleul', params)
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    getCreditsLog(context, userId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/users/' + userId + '/credit')
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    updateCredits(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put('/admin/users/' + params.id + '/credit', params)
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    getEarningLog(context, userId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/users/' + userId + '/earning')
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    updateEarnings(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put('/admin/users/' + params.id + '/earning', params)
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    getLastExportInfo() {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/users/export-info')
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    export(context) {
        return new Promise((resolve, reject) => {})
    },

    downloadExport(context) {
        return new Promise((resolve, reject) => {
            ApiService.get(
                '/admin/users/download-export',
                {},
                {
                    responseType: 'arraybuffer',
                    headers: { Accept: 'application/octet-stream' }
                }
            )
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    updatePartnerStatus(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put('/admin/users/' + params.id + '/partner-status', params)
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    createRedactor(context, userId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/crud/users/' + userId + '/setRedactor')
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    getCustomers(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/user/autocomplete`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
