<template>
    <b-tooltip label="Promouvoir en manager">
        <b-button type="is-danger is-light" class="mr-1" @click.prevent="$emit('click')">
            <span v-if="loading" class="icon">
                <i class="fas fa-circle-notch fa-spin" />
            </span>
            <b-icon v-if="!loading" icon="arrow-up" />
        </b-button>
    </b-tooltip>
</template>

<script>
    export default {
        name: 'PromoteButton',
        props: {
            consultant: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            loading: false
        })
    }
</script>
