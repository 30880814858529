<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">
                        {{ $t('suivi.urldate') }}
                    </h4>
                </header>
                <div class="card-content">
                    <div class="content">
                        <section class="row">
                            <div class="col-12">
                                <b-loading v-model="dataLoading" :is-full-page="true" />

                                <div v-if="orderData && order">
                                    <b-notification
                                        v-if="!orderData.keywords_count"
                                        type="is-danger is-light"
                                        :closable="false"
                                        role="alert"
                                    >
                                        <i class="fa fa-exclamation-triangle mr-2" />
                                        {{ $t('suivi.cannot-found-history') }}
                                    </b-notification>

                                    <div>
                                        <h4>URL :</h4>
                                        <p>
                                            <strong>{{ $t('suivi.numberofkeywords') }} : </strong>
                                            {{ orderData.keywords_count }}
                                        </p>
                                        <!--<p v-if="orderData.keyword_info"><strong>{{ $t('tableau-keywords.urlindice') }} : </strong> {{ orderData.keyword_info.indice_url }}</p>-->
                                        <hr />
                                    </div>

                                    <div>
                                        <h4>Site :</h4>
                                        <p>
                                            <strong>{{ $t('global.category') }} : </strong>
                                            {{ orderData.site_type }}
                                        </p>
                                        <p>
                                            <strong>{{ $t('global.theme') }} : </strong>
                                            {{ orderData.group_name }}
                                        </p>
                                        <hr />
                                    </div>

                                    <div v-if="orderData.keyword_info">
                                        <h4>{{ $t('global.keyword') }} :</h4>
                                        <p>
                                            <strong>Position : </strong>
                                            {{ orderData.keyword_info.position }}
                                        </p>
                                        <p>
                                            <strong>Volume : </strong>
                                            {{ orderData.keyword_info.volume }}
                                        </p>
                                        <hr />
                                    </div>

                                    <div>
                                        <h4>{{ $t('suivi.keywordspositionned') }} :</h4>
                                        <b-table :data="keywords_list" :striped="true" :paginated="true" :per-page="5">
                                            <b-table-column field="keyword" :label="$t('global.keyword')" sortable>
                                                <div
                                                    slot="default"
                                                    slot-scope="props"
                                                    :class="[
                                                        props.row.keyword == order.bought_kw ? 'font-weight-bold' : ''
                                                    ]"
                                                >
                                                    {{ props.row.keyword }}
                                                </div>
                                            </b-table-column>
                                            <b-table-column
                                                centered
                                                field="volume"
                                                :label="$t('partenaire-mot-cles.volume')"
                                                sortable
                                            >
                                                <div
                                                    slot="default"
                                                    slot-scope="props"
                                                    :class="[
                                                        props.row.keyword == order.bought_kw ? 'font-weight-bold' : ''
                                                    ]"
                                                >
                                                    {{ props.row.volume }}
                                                </div>
                                            </b-table-column>
                                            <b-table-column
                                                centered
                                                field="position"
                                                :label="$t('partenaire-mot-cles.position')"
                                                sortable
                                            >
                                                <div
                                                    slot="default"
                                                    slot-scope="props"
                                                    :class="[
                                                        props.row.keyword == order.bought_kw ? 'font-weight-bold' : ''
                                                    ]"
                                                >
                                                    {{ props.row.position }}
                                                </div>
                                            </b-table-column>
                                        </b-table>
                                    </div>
                                </div>

                                <div v-else>
                                    <p class="text-muted p-4 rounded border text-center">...</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'ContentDetailModal',
        props: ['checkedRows'],
        data() {
            return {
                modalIsOpen: false,
                dataLoading: false,
                order: null,
                orderData: null
            }
        },

        computed: {
            keywords_list() {
                const buyedKeyword = this.orderData.keywords_list.find(k => k.keyword == this.order.bought_kw)
                const otherKeywords = [...this.orderData.keywords_list.filter(k => k.keyword != this.order.keyword)]
                return buyedKeyword ? [buyedKeyword, ...otherKeywords] : [...otherKeywords]
            }
        },

        methods: {
            validate() {
                this.$parent.$emit('moveProject', {
                    project_id: this.moveData.project.id,
                    ids: this.listIds
                })

                this.closeAndResetModal()
            },

            openModal(order) {
                this.modalIsOpen = true
                this.dataLoading = true

                this.order = order
                this.getDataUrl(order.id)
            },

            getDataUrl(orderId) {
                this.axios
                    .get(`/tracking-order/${orderId}/data`)
                    .then(({ data }) => {
                        this.dataLoading = false
                        this.orderData = data.data
                    })
                    .catch(error => {
                        this.snackNotif('Error', 'is-danger')
                        console.error(error)
                    })
            },

            closeAndResetModal() {
                this.modalIsOpen = false

                this.orderData = null
                this.order = null
            }
        }
    }
</script>
