import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const dateTimeFormats = {
    en: {
        month: {
            year: 'numeric',
            month: 'long'
        },
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        },
        medium: {
            month: 'numeric',
            day: 'numeric',
            weekday: 'long',
            hour: 'numeric',
            minute: 'numeric'
        },
        medium_date: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric'
        },
        long: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
            hour: 'numeric',
            minute: 'numeric'
        }
    },
    fr: {
        month: {
            month: 'long',
            year: 'numeric'
        },
        short: {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        },
        medium: {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        },
        medium_date: {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        },
        long: {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        }
    }
}

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })

    return messages
}

export default new VueI18n({
    locale: document.getElementsByTagName('body')[0].dataset.lang || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages(),
    dateTimeFormats
})
