<template>
    <div>
        <div>
            <div v-for="(file, index) in files" :key="index" class="fileUpload">
                <file-loading v-model="files[index]" @delete="removeFile(index)" />
            </div>
            <div v-if="!files.length" :style="style" class="fileInput">
                <input
                    ref="fileInput"
                    type="file"
                    @change="onFileChanged"
                    @onUploadError="$emit('onUploadError', response)"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import UploadMixin from './UploadMixin'
    import FileLoading from './FileLoading'

    export default {
        components: {
            FileLoading
        },
        mixins: [UploadMixin]
    }
</script>

<style lang="scss" scoped>
    .fileUpload {
        width: 300px;
        border: 1px solid #868686;
        background-color: white;
        padding: 5px 10px;

        .fileInput {
            cursor: pointer;
        }
    }
</style>
