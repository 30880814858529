<template>
    <div class="card w-100">
        <div class="card-header align-items-center">Planning des actions à venir</div>
        <div class="card-body p-0 onsite-table-container">
            <b-loading v-model="isLoading" :is-full-page="false" />
            <table class="table table-hover mb-0 w-100" style="max-width: 100%">
                <thead>
                    <tr>
                        <th class="position-sticky" style="width: 375px">Actions</th>
                        <th v-for="(date, i) in periods" :key="i">
                            {{ $d(Date.parse(date), 'month') }}
                        </th>
                    </tr>
                </thead>
                <tbody v-for="(group, i) in actions.data" :key="i">
                    <tr class="position-sticky">
                        <th :colspan="periods.length + 1" class="bg-light" style="border-right: none">
                            <span class="position-sticky">
                                {{ $t('accompagnement.action.groups.' + group.name) }}
                            </span>
                        </th>
                    </tr>
                    <tr v-for="thematic in group.thematics" :key="thematic.name">
                        <td>
                            {{ $t('accompagnement.action.thematics.' + group.name + '.' + thematic.name) }}
                        </td>
                        <td v-for="(date, i) in periods" :key="i">
                            <onsite-actions-status
                                :thematic-period="thematic.periods[date]"
                                :readonly="readonly"
                                :statutes-available="actions.meta"
                                @update="
                                    update(
                                        thematic.periods[date] ? thematic.periods[date].id : null,
                                        group.name + '.' + thematic.name,
                                        date,
                                        $event
                                    )
                                "
                                @create="create(group.name + '.' + thematic.name, date, $event)"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import OnsiteActionsStatus from './OnsiteActionsStatus'
    import { mapActions, mapState } from 'vuex'
    import moment from 'moment'

    export default {
        components: { OnsiteActionsStatus },
        props: {
            readonly: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState('campaigns', {
                duration: state => state.campaign.information.duration,
                startDate: state => state.campaign.information.start_at,
                status: state => state.campaign.meta.status,
                campaignId: state => state.campaign.id,
                actions: state => state.actions.data,
                isLoading: state => state.actions.loading
            }),
            periods() {
                let date = moment(this.startDate).startOf('month')

                if (date.isValid()) {
                    let periods = []

                    Array.from(Array(this.duration).keys()).forEach(() => {
                        periods.push(date.format('YYYY-MM-DD'))

                        date.add(1, 'month')
                    })

                    return periods
                }

                return []
            }
        },
        methods: {
            ...mapActions('campaigns', ['getCampaignActions', 'updateCampaignActions', 'createCampaignActions']),
            getActions: function () {
                this.getCampaignActions(this.campaignId)
            },
            update(id, thematic, period, status) {
                this.updateCampaignActions({
                    actionId: id,
                    campaignId: this.campaignId,
                    params: {
                        thematic,
                        period,
                        status
                    }
                })
            },
            create(thematic, period, status) {
                this.createCampaignActions({
                    campaignId: this.campaignId,
                    params: {
                        thematic,
                        period,
                        status
                    }
                })
            }
        },
        mounted() {
            this.getActions()
        }
    }
</script>

<style scoped>
    .onsite-table-container {
        width: 100%;
        overflow: auto;
    }

    .onsite-table-container table {
        border-collapse: separate;
    }

    .onsite-table-container tr > th:first-child,
    .onsite-table-container tr > th:first-child > span,
    .onsite-table-container tr > td:first-child {
        position: sticky;
    }

    .onsite-table-container tr > th:first-child > span {
        left: 0.75rem;
    }

    .onsite-table-container tr > th:first-child,
    .onsite-table-container tr > td:first-child {
        background: #fff;
        left: 0;
        border-right: #dee2e6 2px solid;
        z-index: 100;
        box-sizing: content-box;
    }
</style>
