export default {
    locale(state) {
        return state.locale
    },
    credit(state) {
        return state.credit
    },
    security(state) {
        return state.security
    },
    user(state) {
        return state.user
    }
}
