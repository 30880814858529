<template>
    <div class="card">
        <div class="card-header">Audit On Page</div>
        <ul v-if="readonly" class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between">
                <span class="text-muted mr-1">name</span>
                <span class="ml-auto text-truncate">
                    {{ campaign.information.analyse_onpage ? 'Fait' : 'A faire' }}
                </span>
            </li>
            <li v-if="consultant" class="list-group-item d-flex justify-content-between">
                <span class="text-muted mr-1">Consultant</span>
                <span class="ml-auto text-truncate"> {{ consultant.firstname }} {{ consultant.lastname }} </span>
            </li>
        </ul>
        <div v-else class="card-body">
            <div class="form-group">
                <label for="frm_onpage_status">statut</label>
                <select
                    id="frm_onpage_status"
                    class="form-control"
                    :v-model="campaign.information.analyse_onpage"
                    @change="setStatus($event)"
                >
                    <option :value="0">A faire</option>
                    <option :value="1">Fait</option>
                </select>
            </div>

            <consultant
                v-if="campaign.meta.available_consultants.onPage.length > 0"
                label="Qui ?"
                type="onPage"
                :available="campaign.meta.available_consultants.onPage"
                :consultant="campaign.consultants.onPage"
                class="mb-2"
            />
        </div>
    </div>
</template>

<script>
    import Consultant from './Consultant'
    import { mapActions, mapState } from 'vuex'

    export default {
        components: { Consultant },
        props: {
            readonly: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                consultant: null
            }
        },
        computed: {
            ...mapState('campaigns', ['campaign'])
        },
        methods: {
            ...mapActions('campaigns', ['updateCampaign']),
            setStatus: function (e) {
                this.updateCampaign({
                    campaignId: this.campaign.id,
                    analyse_onpage: e.target.value
                })
            },
            updateConsultant: function (data) {
                if (!data || data.length <= 0) {
                    data = null
                }

                this.$emit('update', {
                    onPage: Number.parseInt(data)
                })
            }
        }
    }
</script>

<style scoped></style>
