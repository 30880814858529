<template>
    <div>
        <b-loading v-model="isLoading" />
        <div class="d-flex flex-column flex-md-row justify-content-between">
            <div class="d-flex flex-column flex-md-row">
                <div class="px-2 d-flex align-items-center">
                    <button type="button" class="btn btn-primary mr-1" id="rechargement" @click="loadAsyncData">
                        <i class="fas fa-sync-alt"></i>
                    </button>
                    <input
                        type="number"
                        class="form-control w-25"
                        v-model="config.per_page"
                        style="text-align: right"
                        @keypress.enter="loadAsyncData"
                    />
                    <p class="control ml-2 mt-2 mb-2">{{ $t('global.per-page') }}</p>
                </div>
            </div>

            <form class="d-flex flex-column flex-md-row align-items-center">
                <b-dropdown aria-role="list" :close-on-click="false" position="is-bottom-left">
                    <template #trigger="{ active }">
                        <b-button
                            :label="$t('global.columns')"
                            type="is-info"
                            class="mr-2"
                            :icon-right="active ? 'caret-up' : 'caret-down'"
                        />
                    </template>

                    <b-dropdown-item aria-role="listitem" v-for="(col, index) in columns" :key="index">
                        <b-field>
                            <b-checkbox v-model="col.active">{{ col.name }}</b-checkbox>
                        </b-field>
                    </b-dropdown-item>
                </b-dropdown>
                <b-select v-model="selectAction" :placeholder="$t('global.actions')">
                    <option :value="1" :disabled="checkedRows.length != 1">Modifier</option>
                    <option :value="2" :disabled="checkedRows.length != 1">Ajouter une note</option>
                    <option :value="3" :disabled="checkedRows.length == 0">Valider</option>
                    <option :value="4" :disabled="checkedRows.length == 0">Annuler</option>
                    <option :value="5" :disabled="checkedRows.length != 1">Télécharger le contenu</option>
                    <option :value="6" :disabled="checkedRows.length == 0">Mail d'erreur</option>
                    <option :value="7" :disabled="checkedRows.length == 0">Relancer la vérification</option>
                </b-select>
                <b-button class="mb-2 mb-md-0 ml-md-2" type="is-warning" @click="onValidateAction">
                    {{ $t('global.validate') }}
                </b-button>
                <b-button class="mb-2 mb-md-0 ml-md-2" @click.prevent="switchExpired" type="is-warning">
                    {{ switchExpiredOrders }}
                </b-button>
            </form>
        </div>
        <b-table
            class="table-striped"
            :sticky-header="false"
            :data="orders.data"
            :mobile-cards="true"
            :debounce-search="700"
            paginated
            backend-pagination
            :total="orders.total"
            :per-page="orders.per_page"
            @page-change="onPageChange"
            backend-filtering
            @filters-change="onFilterChange"
            backend-sorting
            @sort="onSort"
            :striped="true"
            :checked-rows.sync="checkedRows"
            checkable
            checkbox-position="left"
        >
            <b-table-column sortable searchable field="id" label="Ref">
                <template v-slot="props">
                    #{{ props.row.id }}
                    <b-tooltip
                        position="is-bottom"
                        multilined
                        v-if="props.row.content_logs.notes_by_status.urgent.length > 0"
                    >
                        <i class="fas fa-exclamation-triangle text-danger"></i>
                        <template v-slot:content>
                            <p
                                class="p-0 m-0"
                                v-for="note in props.row.content_logs.notes_by_status.urgent"
                                :key="note.id"
                            >
                                {{ moment(note.created_at).format('DD/MM/YYYY') }} :
                                {{ note.notes }}
                            </p>
                        </template>
                    </b-tooltip>
                    <b-tooltip
                        position="is-bottom"
                        multilined
                        v-if="props.row.content_logs.notes_by_status.pending.length > 0"
                    >
                        <i class="fas fa-clock text-info"></i>
                        <template v-slot:content>
                            <p
                                class="p-0 m-0"
                                v-for="note in props.row.content_logs.notes_by_status.pending"
                                :key="note.id"
                            >
                                {{ moment(note.created_at).format('DD/MM/YYYY') }} :
                                {{ note.notes }}
                            </p>
                        </template>
                    </b-tooltip>
                    <b-tooltip
                        position="is-bottom"
                        multilined
                        v-if="props.row.content_logs.notes_by_status.following.length > 0"
                    >
                        <i class="fas fa-recycle text-success"></i>
                        <template v-slot:content>
                            <p
                                class="p-0 m-0"
                                v-for="note in props.row.content_logs.notes_by_status.following"
                                :key="note.id"
                            >
                                {{ moment(note.created_at).format('DD/MM/YYYY') }} :
                                {{ note.notes }}
                            </p>
                        </template>
                    </b-tooltip>
                    <b-tooltip position="is-bottom" multilined v-if="props.row.note_erreur">
                        <i class="fas fa-envelope text-danger"></i>
                        <template v-slot:content>
                            <p class="p-0 m-0">
                                {{ props.row.note_erreur }}
                            </p>
                        </template>
                    </b-tooltip>
                </template>
                <div slot="searchable">
                    <div class="w-100 mx-auto">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="#"
                            v-model="config.filters.id"
                            @change="onFilterChange"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="content.created_at" searchable sortable label="Date">
                <template v-slot="props">
                    {{ moment(props.row.creation_date).format('DD/MM/YYYY') }}
                </template>
                <div slot="searchable">
                    <div class="w-100 mx-auto">
                        <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="config.filters.creation_date"
                            @change="onFilterChange"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="verification_date_premier_echec"
                label="Date d'échec"
                :visible="activeTab === 'error'"
            >
                <template v-slot="props">
                    <span v-if="props.row[props.column.field] === null"> - </span>
                    <span v-else>
                        {{ moment(props.row[props.column.field]).format('DD/MM/YYYY') }}
                    </span>
                </template>
            </b-table-column>
            <b-table-column field="statut" label="Statut" :visible="activeTab === 'all'">
                <div slot-scope="props" slot="default">
                    <span v-if="props.row[props.column.field] === 'termine'">
                        <span
                            v-if="
                                (props.row.verification_code_reponse_http != 200 &&
                                    props.row.verification_ne_plus_verifier_code_reponse == 0) ||
                                (props.row.verification_autres_liens_externes != null &&
                                    props.row.verification_ne_plus_verifier_autres_liens_externes == 0) ||
                                (props.row.verification_ne_plus_verifier_liens == 0 &&
                                    (props.row.link_status === 'erreur' || props.row.link_second_status === 'erreur'))
                            "
                            class="tag is-warning"
                        >
                            Terminé avec erreur
                        </span>
                        <span v-else class="tag is-success"> Terminé </span>
                    </span>
                    <span class="tag is-info" v-if="props.row[props.column.field] === 'en_cours'"> A traiter </span>
                    <span class="tag is-warning" v-if="props.row[props.column.field] === 'a_valider'">
                        En attente de validation
                    </span>
                    <span class="tag is-danger" v-if="props.row[props.column.field] === 'refuse'"> Refusé </span>
                    <span class="tag is-danger" v-if="props.row[props.column.field] === 'annule'"> Annulé </span>
                    <span class="tag is-danger" v-if="props.row[props.column.field] === 'termine' && false">
                        Erreurs
                    </span>
                </div>
            </b-table-column>
            <b-table-column sortable searchable field="content.buyer_email" label="Client">
                <template v-slot="props">
                    <a :href="`/admin/users/details?id=` + props.row.buyer_id" target="_blank">{{
                        props.row.client
                    }}</a>
                </template>
                <div slot="searchable">
                    <div class="w-100 mx-auto">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="contact@nextlevel.link"
                            v-model="config.filters.client"
                            @change="onFilterChange"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column searchable sortable field="bought_kw" label="Mot-clé" :visible="columns.keywords.active">
                <template v-slot="props">
                    <button @click="openKeywordsModal(props.row.keywords)" class="button is-light">
                        <i class="far fa-eye"></i>
                    </button>
                </template>
                <div slot="searchable">
                    <div class="w-100 mx-auto">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Mot-clé"
                            v-model="config.filters.bought_kw"
                            @change="onFilterChange"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column searchable field="lang" label="Langue" :visible="columns.lang.active">
                <template v-slot="props">
                    <span v-if="props.row[props.column.field] === 'fr_fr'">
                        <img :src="`${appUrl}/assets/img/lang/fr_fr.png`" class="w-50" alt="french flag" />
                    </span>
                    <span v-if="props.row[props.column.field] === 'com_en'">
                        <img :src="`${appUrl}/assets/img/lang/com_en.png`" class="w-50" alt="uk flag" />
                    </span>
                </template>
                <div slot="searchable" slot-scope="props">
                    <select
                        placeholder="Tous"
                        class="form-control form-control-sm"
                        v-model="config.filters.lang"
                        @change="onFilterChange"
                    >
                        <option :value="props.filters[props.column.field]">Tous</option>
                        <option value="fr_fr">FR</option>
                        <option value="com_en">EN</option>
                    </select>
                </div>
            </b-table-column>
            <b-table-column searchable field="partner" label="Partenaire">
                <template v-slot="props">
                    <span v-if="props.row.site.partner">
                        <a :href="`/admin/users/details?id=` + props.row.site.partenaire_id" target="_blank">
                            {{ props.row.site.partner.email }}
                        </a>
                        <LoginAs :user-id="props.row.site.partner.id" class="mx-1"></LoginAs>
                    </span>
                    <span v-else> NextLevel </span>
                </template>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto d-flex">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Partenaire"
                            v-model="config.filters.partner"
                            @change="onFilterChange"
                        />
                        <select
                            class="form-control form-control-sm ml-2"
                            placeholder="Partenaire"
                            v-model="config.filters.partner_type"
                            @change="onFilterChange"
                        >
                            <option :value="props.filters[props.column.field]">Tous</option>
                            <option value="partner">Partenaire</option>
                            <option value="nl">NextLevel</option>
                        </select>
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="url" searchable sortable label="Page">
                <template v-slot="props">
                    <a target="_blank" rel="noreferrer" :href="props.row[props.column.field]">{{
                        props.row[props.column.field]
                    }}</a>
                </template>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="https://nextlevel.link"
                            v-model="config.filters.url"
                            @change="onFilterChange"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="errors" label="Erreurs" :visible="activeTab === 'error'" searchable>
                <template v-slot="props">
                    <span class="text-danger">
                        {{ concatenateErrors(props.row) }}
                    </span>
                </template>
                <div slot="searchable" slot-scope="props">
                    <select
                        class="form-control form-control-sm"
                        v-model="config.filters.error_type"
                        @change="onFilterChange"
                    >
                        <option :value="props.filters[props.column.field]">Tous</option>
                        <option value="link_error">Lien</option>
                        <option value="http_error">Code HTTP</option>
                    </select>
                </div>
            </b-table-column>
            <b-table-column field="site.actif" label="Statut du site" :visible="activeTab === 'error'">
                <template v-slot="props">
                    <span class="tag is-success" v-if="props.row.site.actif == 1"> Actif </span>
                    <span class="tag is-danger" v-if="props.row.site.actif == 0"> Supprimé </span>
                    <span class="tag is-info" v-if="props.row.site.actif == -1"> À valider </span>
                    <span class="tag is-warning" v-if="props.row.site.actif == -2"> Désactivé admin </span>
                    <span class="tag is-warning" v-if="props.row.site.actif == -4"> Désactivé client </span>
                    <span class="tag is-info" v-if="props.row.site.actif == -5"> À améliorer </span>
                </template>
            </b-table-column>
            <b-table-column searchable sortable field="nb_words" label="Contenu" :visible="columns.content.active">
                <template v-slot="props"> {{ 200 + parseInt(props.row.nb_words) }} mots</template>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <select
                            class="form-control form-control-sm"
                            placeholder="200"
                            v-model="config.filters.nb_words"
                            @change="onFilterChange"
                        >
                            <option :value="props.filters[props.column.field]">Toutes</option>
                            <option value="200">200</option>
                            <option value="300">300</option>
                            <option value="400">400</option>
                            <option value="500">500</option>
                            <option value="600">600</option>
                            <option value="700">700</option>
                        </select>
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                searchable
                sortable
                :visible="activeTab != 'a_valider' && columns.send_at.active"
                field="contenu_date_envoi"
                label="Envoyé le"
            >
                <template v-slot="props">
                    <span v-if="props.row.contenu_date_envoi">
                        {{ moment(props.row.contenu_date_envoi).format('DD/MM/YYYY') }}
                    </span>
                    <span v-else>-</span>
                </template>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <select
                            class="form-control form-control-sm"
                            placeholder="200"
                            v-model="config.filters.is_sent"
                            @change="onFilterChange"
                        >
                            <option :value="props.filters[props.column.field]">Toutes</option>
                            <option value="1">Oui</option>
                            <option value="0">Non</option>
                        </select>
                    </div>
                </div>
            </b-table-column>
            <b-table-column searchable field="link_anchor" label="Lien & ancres">
                <template v-slot="props">
                    <span
                        >{{ props.row.link_anchor }} :
                        <a :href="props.row.link_url" target="_blank">{{ props.row.link_url }}</a></span
                    >
                    <hr v-if="props.row.link_second_url && props.row.link_second_anchor" />
                    <span v-if="props.row.link_second_url && props.row.link_second_anchor"
                        >{{ props.row.link_second_anchor }} :
                        <a :href="props.row.link_second_url" target="_blank">{{ props.row.link_second_url }}</a></span
                    >
                </template>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Lien ou ancre"
                            v-model="config.filters.link_anchor"
                            @change="onFilterChange"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="site.wp_data" label="Plugin" :visible="columns.plugin.active">
                <template v-slot="props">
                    <NlWordpress :site-data="props.row.site" site-type="site" />
                </template>
            </b-table-column>
            <b-table-column sortable searchable field="price_paid" label="Prix" :visible="columns.price.active">
                <template v-slot="props"> {{ props.row[props.column.field] }}€</template>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="200€"
                            v-model="config.filters.price_paid"
                            @change="onFilterChange"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                sortable
                field="content.contenu_date_envoi"
                label="Publication"
                :visible="columns.publication.active"
            >
                <template v-slot="props">
                    <span v-if="props.row.publication_date == null"> ASAP </span>
                    <span v-else>
                        {{ moment(props.row.publication_date).format('DD/MM/YYYY') }}
                    </span>
                </template>
            </b-table-column>
            <b-table-column searchable field="redaction" label="Redaction">
                <template v-slot="props">
                    <span class="tag is-success" v-if="props.row[props.column.field] === 'proprietaire'">
                        Propriétaire
                    </span>
                    <span class="tag is-warning" v-if="props.row[props.column.field] === 'nextlevel'"> NextLevel </span>
                </template>
                <div slot="searchable" slot-scope="props">
                    <select
                        class="form-control form-control-sm"
                        v-model="config.filters.redaction"
                        @change="onFilterChange"
                    >
                        <option :value="props.filters[props.column.field]">Tous</option>
                        <option value="nextlevel">Nextlevel</option>
                        <option value="proprietaire">Propriétaire</option>
                    </select>
                </div>
            </b-table-column>
            <b-table-column field="motif_refus_partenaire" label="Raison" :visible="activeTab === 'refuse'">
                <template v-slot="props">
                    <span v-if="props.row[props.column.field] === null"> - </span>
                    <span v-else>
                        {{ props.row[props.column.field] }}
                    </span>
                </template>
            </b-table-column>
        </b-table>

        <ContentModal ref="contentModal" @refresh="loadAsyncData" />
        <NoteModal
            ref="noteModal"
            modalType="content"
            :type-array="['Support', 'Developpement', 'Commercial', 'Communication', 'Comptabilité', 'Autre']"
            @refresh="loadAsyncData"
        />
        <KeywordsModal ref="keywordsModal" @refresh="loadAsyncData" />
        <ErrorMailModal ref="errorMailModal" @refresh="loadAsyncData" />
        <CheckOrdersModal ref="checkOrdersModal" @refresh="loadAsyncData" />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import CheckOrdersModal from './CheckOrdersModal.vue'
    import NlWordpress from '@/components/UI/Wordpress/NlWordpress.vue'
    import KeywordsModal from '@/components/Admin/KeywordsModal.vue'

    export default {
        name: 'OrdersTable',
        components: { KeywordsModal, NlWordpress, CheckOrdersModal },
        props: ['activeTab'],
        data() {
            return {
                checkedRows: [],
                appUrl: process.env.APP_URL,
                selectAction: null,
                columns: {
                    lang: { active: false, name: 'Langue' },
                    keywords: { active: false, name: 'MC' },
                    send_at: { active: false, name: 'Envoyé le' },
                    content: { active: false, name: 'Contenu' },
                    plugin: { active: false, name: 'Plugin' },
                    price: { active: false, name: 'Prix' },
                    publication: { active: false, name: 'Publication' }
                }
            }
        },
        created() {
            this.$store.state.orders.shouldComputeOrdersNotes = true
        },
        computed: {
            ...mapState('orders', {
                orders: state => state.orders,
                config: state => state.ordersConfig
            }),
            isLoading: {
                get() {
                    return this.$store.state.orders.isLoading
                },
                set(value) {
                    this.$store.commit('orders/setIsLoading', value)
                }
            },
            checkedIds() {
                return this.checkedRows.map(row => row.id)
            },
            expired: {
                get() {
                    return this.$store.state.orders.expired
                },
                set(value) {
                    this.$store.commit('orders/setExpired', value)
                }
            },
            shouldComputeOrdersNotes: {
                get() {
                    return this.$store.state.orders.shouldComputeOrdersNotes
                },
                set(value) {
                    this.$store.commit('orders/setShouldComputeOrdersNotes', value)
                }
            },
            switchExpiredOrders() {
                if (this.expired == false) {
                    return 'Afficher les commandes expirées'
                } else {
                    return 'Masquer les commandes expirées'
                }
            }
        },

        methods: {
            ...mapActions('orders', ['getOrders', 'validateContent', 'cancelContent']),
            loadAsyncData() {
                this.isLoading = true
                this.checkedRows = []
                this.selectAction = null
                this.shouldComputeOrdersNotes = true
                this.getOrders()
                    .then(() => {})
                    .catch(error => {
                        console.log(error)
                        this.$store.dispatch('toastError', error)
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            onSort(field, order) {
                this.config.sort = field
                this.config.sortDirection = order

                this.loadAsyncData()
            },
            switchExpired() {
                this.expired = !this.expired
                this.loadAsyncData()
            },
            onFilterChange(filter) {
                var filters = {
                    status: this.activeTab
                }
                Object.keys(filter).forEach(element => {
                    filters[element] = filter[element]
                })
                this.config.filters = { ...filters, ...this.config.filters }

                this.loadAsyncData()
            },
            onPageChange(page) {
                this.config.page = page
                this.loadAsyncData()
            },
            onValidateAction() {
                switch (this.selectAction) {
                    case 1:
                        this.$refs.contentModal.openModal(this.checkedRows[0])
                        break
                    case 2:
                        this.$refs.noteModal.openModal(this.checkedRows[0].id)
                        break
                    case 3:
                        this.validateContent(this.checkedIds)
                            .then(() => {
                                this.loadAsyncData()
                            })
                            .catch(error => {
                                this.$store.dispatch('toastError', error)
                            })
                        break
                    case 4:
                        this.cancelContent(this.checkedIds)
                            .then(() => {
                                this.loadAsyncData()
                            })
                            .catch(error => {
                                this.$store.dispatch('toastError', error)
                            })
                        break
                    case 5:
                        this.downloadContent(this.checkedIds[0])
                        break
                    case 6:
                        this.openMailModal()
                        break
                    case 7:
                        this.openCheckOrderModal()
                        break
                }
            },
            getWordpressVersion(meta) {
                const metaObject = JSON.parse(meta)
                return metaObject.wp_version ?? ''
            },

            getWordpressNlVersion(meta) {
                const metaObject = JSON.parse(meta)
                return metaObject.plugin_version ?? ''
            },
            concatenateErrors(row) {
                var errors = ''
                if (row.verification_autres_liens_externes) {
                    errors += 'Liens externes: ' + row.verification_autres_liens_externes + '\n'
                }
                if (row.link_status === 'erreur') {
                    errors += 'Lien 1: ' + row.link_status_message + '\n'
                }
                if (row.link_second_status === 'erreur') {
                    errors += 'Lien 2: ' + row.link_second_status_message + '\n'
                }
                if (row.verification_code_reponse_http != '200') {
                    errors += 'Code HTTP: ' + row.verification_code_reponse_http + '\n'
                }
                return errors
            },
            openKeywordsModal(row) {
                this.$refs.keywordsModal.openModal(row)
            },
            openMailModal() {
                this.$refs.errorMailModal.openModal(this.checkedIds)
            },
            openCheckOrderModal() {
                this.$refs.checkOrdersModal.openModal(this.checkedIds)
            },
            downloadContent(ref) {
                let brief = this.orders.data.find(order => order.id === id).html_jour_de_la_commande
                let blob = new Blob([brief], { type: 'text/html;charset=utf-8' })
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'content_' + ref + '.html')
                document.body.appendChild(link)
                link.click()
            }
        }
    }
</script>
