<template>
    <b-modal v-model="active">
        <template>
            <header class="px-4 py-3 has-background-warning shadow-sm">
                <h4 class="h4 p-0 mb-0">Acheter un site</h4>
            </header>
            <div class="modal-body">
                <h3 class="text-center">Voulez-vous vraiment acheter ce site pour {{ website.price }}€ HT ?</h3>
                <p v-if="user.internet_bs_email == null" class="text-center text-danger">
                    Pour acheter un site, merci d'entrer votre adresse mail internet.bs sur votre
                    <a class="text-danger" href="/profile"><u>profil</u></a>
                </p>
            </div>
            <div class="modal-footer">
                <b-button type="button" class="btn btn-primary" :loading="isLoading" @click.prevent="closeModal">
                    Annuler
                </b-button>
                <b-button
                    v-if="user"
                    :loading="isLoading"
                    type="button"
                    :disabled="user.credit_amount < website.price || user.internet_bs_email === null"
                    class="btn btn-secondary"
                    @click="buy(1)"
                >
                    Acheter avec les crédits
                </b-button>
                <b-button
                    v-if="user"
                    :loading="isLoading"
                    type="button"
                    class="btn btn-secondary"
                    :disabled="user.gains < website.price || user.internet_bs_email === null"
                    @click="buy(0)"
                >
                    Acheter avec les gains
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'BuySiteModal',
        data() {
            return {
                active: false,
                website: {
                    price: 0
                },
                isLoading: false
            }
        },
        created() {
            this.get()
        },
        computed: {
            ...mapState('auth', ['user'])
        },
        methods: {
            ...mapActions('sites', ['buySite']),
            ...mapActions('auth', ['me']),
            openModal(content) {
                this.website = content
                this.active = true
            },
            closeModal() {
                this.active = false
            },
            buy(use_credits) {
                this.isLoading = true
                this.buySite({
                    id: this.website.id,
                    use_credits: use_credits
                })
                    .then(() => {
                        this.isLoading = false
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title:
                                this.user.role === 'prospect'
                                    ? 'Le site a bien été acheté. Merci de vous reconnecter pour accéder à vos sites'
                                    : 'Le site a bien été acheté.'
                        })
                        this.closeModal()
                        this.$router.push('/partner/sites')
                    })
                    .catch(e => {
                        this.isLoading = false
                        this.$store.dispatch('toast', {
                            type: 'error',
                            title: 'Une erreur est survenue',
                            message: e.response.message
                        })
                    })
            }
        }
    }
</script>

<style></style>
