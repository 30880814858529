import ApiService from '../../../services/api.service'

export default {
    upload(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/upload', params.model, params.config)
                .then(data => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
