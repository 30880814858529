<template>
    <div>
        <div class="row">
            <div class="col-md-12 col-lg-6 row">
                <h4 class="h4">Général</h4>
                <div class="col-md-12">
                    <b-switch
                        v-model="user.display_search_results"
                        :true-value="true"
                        :false-value="false"
                        type="is-success"
                        @input="editUser"
                    >
                        Afficher exceptionnellement les résultats
                    </b-switch>
                </div>
                <div class="col-md-12">
                    <b-switch
                        v-model="user.displaySerp"
                        true-value="1"
                        false-value="0"
                        type="is-success"
                        @input="editUser"
                    >
                        Afficher l'URL dans la recherche (Modal et Favoris)
                    </b-switch>
                </div>
            </div>
            <div v-if="user.role === 'agence'" class="col-md-12 col-lg-6 row">
                <h4 class="h4">Agence</h4>
                <div class="col-md-12">
                    <b-switch
                        v-model="user.display_serp_clients"
                        :true-value="true"
                        :false-value="false"
                        type="is-success"
                        @input="editUser"
                    >
                        Afficher l'URL dans la recherche (Modal et Favoris) pour les clients de l'agence
                    </b-switch>
                </div>
                <div class="col-md-12">
                    <b-switch
                        v-model="user.display_site_kpi"
                        :true-value="true"
                        :false-value="false"
                        type="is-success"
                        @input="editUser"
                    >
                        Afficher les KPI des sites (CF, TF, RD, DR) dans la recherche pour les clients de l'agence
                    </b-switch>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PartnerDetailsSearch',
        props: {
            user: {}
        },
        methods: {
            editUser() {
                this.axios
                    .put('/admin/partner-details/' + this.user.id, this.user)
                    .then(() => {})
                    .catch(() => {})
            }
        }
    }
</script>
