import { ROLES } from '@/config/roles'

const metaGroup = {
    roles: [ROLES.ADMINISTRATIVE]
}

export default [
    {
        path: '/admin/withdrawal-requests',
        alias: '/demande-retrait',
        name: 'WithdrawalWinnings',
        component: () => import('../views/Admin/WithdrawalWinnings/WithdrawalWinnings.vue'),
        meta: metaGroup
    },
    {
        path: '/admin/partners',
        name: 'Partners',
        component: () => import('../views/Admin/Partners.vue'),
        meta: metaGroup
    },
    {
        path: '/admin/attestations',
        name: 'User Attestation',
        component: () => import('../views/Admin/UserAttestation.vue'),
        meta: metaGroup
    },
    {
        path: '/admin/users',
        name: 'Users',
        component: () => import('../views/Admin/Users.vue'),
        meta: {
            isSeo: true,
            roles: [ROLES.CONSULTANT, ROLES.MANAGER, ROLES.AGENCY]
        }
    },
    {
        path: '/admin/wires',
        name: 'Wires',
        components: {
            default: () => import('../views/Admin/Wires.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/sites/urls',
        name: 'AdminUrls',
        components: {
            default: () => import('../views/Admin/URLs/AdminUrls.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/sites/:id/urls',
        name: 'SiteUrls',
        components: {
            default: () => import('../views/Admin/Sites/SiteUrls.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/partners/details',
        name: 'PartenaireDetails',
        component: () => import('../views/Admin/Partner/PartnerDetails.vue'),
        meta: metaGroup
    },
    {
        path: '/admin/users/details',
        name: 'UserDetails',
        component: () => import('../views/Admin/User/UserDetails.vue'),
        meta: metaGroup
    }
]
