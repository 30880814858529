<script>
    export default {
        data: () => ({
            focus: false
        }),
        computed: {
            stateClass() {
                const c = []
                if (this.focus) {
                    c.push('input-focus')
                }
                if (this.value !== null && this.value !== '') {
                    c.push('input-filled')
                }
                return c
            }
        }
    }
</script>
