const metaGroup = { isPublic: true }

export default [
    {
        path: '/login',
        alias: '/login.php',
        name: 'LoginFr',
        component: () => import('../views/Public/Login.vue'),
        meta: metaGroup
    },
    {
        path: '/reset',
        name: 'OubliMotDePasse',
        component: () => import('../views/Public/ForgottenPassword.vue'),
        meta: metaGroup
    },
    {
        path: '/reset/password',
        name: 'OubliMotDePasseEtape2',
        component: () => import('../views/Public/ForgottenPasswordStep2.vue'),
        meta: metaGroup
    },
    {
        path: '/register',
        alias: '/register.php',
        name: 'Register',
        component: () => import('../views/Public/Register.vue'),
        meta: metaGroup
    }
]
