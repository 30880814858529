//Laravel ECHO Client
import Echo from 'laravel-echo'
import Vue from 'vue'
import NlStorage from "@/services/nl.storage";

window.io = require('socket.io-client')

let echoInstance = new Echo({
    broadcaster: 'socket.io',
    host: process.env.VUE_APP_LARAVEL_DOMAIN + ':' + (process.env.VUE_APP_ECHO_PORT ?? '6001'),
    auth: {
        headers: {
            Authorization: 'Bearer ' + NlStorage.getItem('token')
        }
    }
})

Vue.prototype.$echo = echoInstance
