<template>
    <b-modal
        :active="tacBool || GTCModalOpened"
        :can-cancel="Boolean(user.accept_cgv)"
        aria-role="alertdialog"
        @close="toggleGTCModal(false)"
    >
        <template>
            <div class="card-content text-dark">
                <div class="modal-header">
                    <h5 class="modal-title">
                        CGV Nextlevel - ({{ moment(tac.created_at).format('L') }} - Version {{ tac.version }})
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="cgv" v-html="tac.tac" />
                </div>
                <div v-if="!user.accept_cgv" class="modal-footer">
                    <b-button type="is-dark" @click="userAcceptTacs"> Accepter</b-button>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex'

    export default {
        name: 'TacsModal',
        props: {
            user: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                tacBool: false,
                tac: []
            }
        },
        computed: {
            ...mapState(['GTCModalOpened'])
        },
        methods: {
            ...mapActions('auth', { updateUser: 'update' }),
            ...mapActions('config', {
                getLastTacsByLang: 'getLastTacsByLang',
                acceptTacs: 'acceptTacs'
            }),
            ...mapMutations('auth', { setUser: 'setUser' }),
            ...mapMutations(['toggleGTCModal']),
            getTacsByUserLang() {
                this.getLastTacsByLang().then(tacs => {
                    this.tac = tacs
                    this.user.accept_cgv === 0 ? (this.tacBool = true) : (this.tacBool = false)
                })
            },
            userAcceptTacs() {
                this.user.accept_cgv = 1
                this.user.tac_id = this.tac.id
                this.setUser(this.user)
                this.acceptTacs({ tac_id: this.tac.id }).then(() => {
                    this.tacBool = false
                })
            }
        },
        created() {
            this.getTacsByUserLang()
        }
    }
</script>
