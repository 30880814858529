export default {
    favorites(state) {
        return state.favorites
    },
    notes(state) {
        return state.notes
    },
    note: state => (siteId, projectId) => {
        const note = state.notes
            ? state.notes.find(note => note.site_id === siteId && note.projectId === projectId)
            : null
        return note ? note.note : null
    }
}
