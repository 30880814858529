<template>
    <div :class="`card bg-${color} w-100 mb-3`">
        <div class="card-content py-1 px-3">
            <div class="card-body">
                <div class="media d-flex justify-content-between">
                    <div class="align-self-center">
                        <i :class="`fa-2x ${icon} text-white`" />
                    </div>
                    <div class="media-body text-white text-right">
                        <slot name="counter" />
                        <slot name="text" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StatCard',
        props: {
            icon: {
                type: String,
                default: 'fas fa-check'
            },
            color: {
                type: String,
                default: 'warning'
            }
        }
    }
</script>

<style></style>
