import ApiService from '../../../services/api.service'

export default {
    async list(context) {
        const params = await context.dispatch('prepareParams', context.state.ordersConfig, {
            root: true
        })

        return new Promise((resolve, reject) => {
            ApiService.get('/admin/billing', params)
                .then(({data}) => {
                    context.commit('setOrders', data.orders)
                    resolve(data)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },
    create(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.post('/admin/billing', model)
                .then(({data}) => {
                    resolve(data)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },
    update(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.put('/admin/billing/' + model.id, model)
                .then(({data}) => {
                    resolve(data)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },
    delete(context, orderId) {
        return new Promise((resolve, reject) => {
            ApiService.delete('/admin/billing/' + orderId)
                .then(({data}) => {
                    resolve(data)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },
    advanceCredit(ctx, orderId) {
        return new Promise((resolve, reject) => {
            ApiService.put('/admin/billing/' + orderId + '/advance-credit')
                .then(({data}) => {
                    resolve(data)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },
    generateInvoice(ctx, orderId) {
        return new Promise((resolve, reject) => {
            ApiService.put('/admin/billing/' + orderId + '/generate-invoice')
                .then(({data}) => {
                    resolve(data)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },
    generateQuote(ctx, orderId) {
        return new Promise((resolve, reject) => {
            ApiService.put('/admin/billing/' + orderId + '/generate-quote')
                .then(({data}) => {
                    resolve(data)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },
    markAsPaid(ctx, orderId) {
        return new Promise((resolve, reject) => {
            ApiService.put('/admin/billing/' + orderId + '/mark-as-paid')
                .then(({data}) => {
                    resolve(data)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },
    downloadPdf(ctx, params) {
        const {orderId, type} = params
        return new Promise((resolve, reject) => {
            ApiService.get(
                '/admin/billing/' + orderId + '/download/' + type,
                {},
                {
                    responseType: 'arraybuffer',
                    headers: {Accept: 'application/octet-stream'}
                }
            )
                .then(data => {
                    resolve(data)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },

    searchByUser(ctx, userId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/facturation-pro/search-by-user', {userId})
                .then(data => {
                    resolve(data)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },

    searchByQuote(ctx, quoteId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/facturation-pro/search-by-quote', {quoteId})
                .then(data => {
                    resolve(data)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },

    searchByInvoice(ctx, invoiceId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/facturation-pro/search-by-invoice', {invoiceId})
                .then(data => {
                    resolve(data)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },

    import(ctx, fpData) {
        return new Promise((resolve, reject) => {
            ApiService.post('/admin/facturation-pro/import', fpData)
                .then(data => {
                    resolve(data)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },

    download(ctx, fpData) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/orders/download', fpData)
                .then(data => {
                    resolve(data)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },

    async adminDownload(context) {
        let config = {...context.state.ordersConfig}

        if (context.state.startDate && context.state.endDate) {
            config.filters['is_between'] = new Date(context.state.startDate).toISOString() + ',' + new Date(context.state.endDate).toISOString()
        }

        const params = await context.dispatch('prepareParams', config, {
            root: true
        })

        return new Promise((resolve, reject) => {
            ApiService.get('/admin/billing/download', params, {responseType: 'blob'})
                .then(response => {
                    resolve(response)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },

    async adminDownloadCredits(context, dateEnd) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/billing/credits/download', {'date_end':dateEnd}, {responseType: 'blob'})
                .then(response => {
                    resolve(response)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },

    async adminDownloadGains(context, dateEnd) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/billing/gains/download', {'date_end':dateEnd}, {responseType: 'blob'})
                .then(response => {
                    resolve(response)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },

    withdraw(context) {
        return new Promise((resolve, reject) => {
            ApiService.post('/partner-gain/withdrawal', context.state.withdrawal)
                .then(data => {
                    resolve(data)
                })
                .catch(({response}) => {
                    reject(response)
                })
        })
    },

    exportXML(context) {
        return new Promise((resolve, reject) => {
            ApiService.get(
                '/withdrawals/invoices/xml',
                {},
                {
                    responseType: 'arraybuffer',
                    headers: { Accept: 'application/octet-stream' }
                }
            )
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    downloadUserInvoice(context, logId) {
        return new Promise((resolve, reject) => {
            ApiService.get(
                `/partner-gain/my-invoices/${logId}`,
                {},
                {
                    responseType: 'arraybuffer',
                    headers: { Accept: 'application/octet-stream' }
                }
            )
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
}
