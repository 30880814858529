<template>
    <nl-modal ref="modal" title="Choisir une période" :add-legacy-events="false" position="right" size="lg">
        <template v-slot:header>
            <div class="bg-antracite text-white w-100 text-center p-4">
                <h1>
                    <span>Choisir une période</span>
                </h1>
            </div>
        </template>
        <div class="text-left">
            <b-field label="Date de début">
                <b-datepicker v-model="start_date" placeholder="Cliquer pour sélectionner" />
            </b-field>
            <b-field label="Date de fin">
                <b-datepicker v-model="end_date" placeholder="Cliquer pour sélectionner" />
            </b-field>
            <div class="d-flex justify-content-center">
                <button class="btn-action btn-third mt-2 mb-2 mb-xl-4" @click.prevent="onSave">
                    <span>Choisir</span>
                </button>
            </div>
        </div>
        <template v-slot:footer>
            <div class="d-flex w-100 justify-content-start">
                <b-button type="button" class="btn btn-danger" @click="$refs.modal.hide()"> Annuler</b-button>
            </div>
        </template>
    </nl-modal>
</template>

<script>
    import AgencyModal from '@/components/Agency/Modal/AgencyModal.vue'

    export default {
        name: 'CustomDateModal',
        mixins: [AgencyModal],
        data() {
            return {
                start_date: null,
                end_date: null
            }
        },
        methods: {
            show() {
                this.$refs.modal.show()
            },
            hide() {
                this.$refs.modal.hide()
            },
            onSave() {
                this.$emit('changeDate', 'custom', this.start_date, this.end_date)
                this.hide()
            }
        }
    }
</script>
