<template>
    <div class="row px-xl-5 mb-xl-5">
        <div class="p-2 col-md-12">
            <div class="card">
                <div class="card-header row px-4 py-3 text-light" style="">
                    <div class="col-md-8">
                        <h3 class="mb-0">
                            {{ $t('profil.profil.modify-password') }}
                        </h3>
                    </div>
                </div>

                <div class="card-body px-4 py-0">
                    <v-form @submit="submitUpdatePassword">
                        <div class="row">
                            <div class="col col-md-12 col-lg-4">
                                <v-textfield
                                    v-model="newPassword"
                                    class-label="form-control-label-profil"
                                    name="password"
                                    type="password"
                                    :label="$t('profil.new-password')"
                                    rules="required"
                                />
                            </div>
                        </div>
                        <div class="row mb-0">
                            <div class="col col-md-12 col-lg-4">
                                <v-textfield
                                    v-model="confirmPassword"
                                    class-label="form-control-label-profil"
                                    type="password"
                                    :label="$t('profil.confirm-new-password')"
                                    rules="required"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-auto mr-auto">&nbsp;</div>
                            <div class="col-auto">
                                <div class="row mb-0">
                                    <button class="btn btn-secondary mr-2" :disabled="loading">
                                        {{ $t('profil.profil.modify-password') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <b-loading v-model="loading" :is-full-page="false" />
                    </v-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AbstractProfile from './AbstractProfile.vue'

    export default {
        name: 'AccountPasswordChange',
        mixins: [AbstractProfile],
        data: function () {
            return {}
        },
        created() {},
        methods: {
            submitUpdatePassword: function () {
                this.loading = true

                this.$emit('submitUpdatePassword', {
                    password: this.newPassword,
                    password_confirmation: this.confirmPassword,
                    callback: () => {
                        this.loading = false
                    }
                })
            }
        }
    }
</script>
