<template>
    <div>
        <i v-if="value === true" class="fas fa-check-circle text-success"></i>
        <i v-else class="fas fa-times-circle text-danger"></i>
    </div>
</template>

<script>
    export default {
        name: 'TrueFalseBadge',
        props: {
            value: {
                type: Boolean,
                required: true
            }
        }
    }
</script>

<style scoped></style>
