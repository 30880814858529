export default {
    setOrders(state, data) {
        state.orders = data
    },
    setCounts(state, data) {
        state.counts = data
    },
    setActiveTab(state, data) {
        state.activeTab = data
    },
    setIsLoading(state, data) {
        state.isLoading = data
    },
    setExpired(state, data) {
        state.expired = data
    },
    setShouldComputeOrdersNotes(state, data) {
        state.shouldComputeOrdersNotes = data
    },
    setPendingOrdersCount(state, data) {
        state.pendingOrdersCount = data
    }
}
