<template>
    <NlModal
        :open-modal="showModal"
        :title="type === 'global' ? $t('tableau-keywords.blacklist') : 'Blackliste Site'"
        @hide="$emit('close')"
    >
        <p>
            {{
                type === 'global'
                    ? $t('tableau-keywords.blacklistdescription')
                    : "Ce site n'apparaîtra plus dans les résultats de recherche pour ce projet, êtes-vous sûr ?"
            }}
        </p>

        <template v-slot:footer>
            <button type="button" class="btn btn-primary" data-dismiss="modal">
                {{ $t('global.cancel') }}
            </button>
            <button type="submit" class="btn btn-danger" @click="$emit('confirm')">
                {{ $t('global.validate') }}
            </button>
        </template>
    </NlModal>
</template>

<script>
    import NlModal from '../UI/NlModal'

    export default {
        components: { NlModal },
        props: {
            showModal: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: 'global'
            }
        }
    }
</script>

<style scoped></style>
