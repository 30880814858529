export default {
    methods: {
        formatCentsNumber(number) {
            return (number / 100).toFixed(2).toString().replace(/\./g, ',')
        },

        snackNotif(message, type = 'is-success', duration = 5000) {
            this.$buefy.snackbar.open({
                message: message,
                type: type,
                position: 'is-bottom',
                duration: duration
            })
        }
    }
}
