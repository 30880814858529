<template>
    <span>
        <b-tooltip v-if="status == 1" :label="$t('status.activated')" position="is-right">
            <span class="tag is-success">
                <i
                    v-if="currentSite.scheduled_at"
                    class="fas fa-clock"
                    data-toggle="tooltip"
                    data-html="true"
                    :title="scheduleTime"
                />
                <i v-else class="fas fa-check-circle" data-toggle="tooltip" data-html="true" />
            </span>
        </b-tooltip>

        <b-tooltip v-else-if="status == 0" :label="$t('status.deleted')" position="is-right">
            <span class="tag is-danger">
                <i
                    v-if="currentSite.notes_customer"
                    class="fas fa-info"
                    data-toggle="tooltip"
                    data-html="true"
                    :title="currentSite.notes_customer"
                />
                <i v-else class="fas fa-trash-alt" data-toggle="tooltip" data-html="true" />
            </span>
        </b-tooltip>

        <b-tooltip v-else-if="status == -1" :label="$t('status.attentevalidation')" position="is-right">
            <span class="tag is-info">
                <i class="fas fa-pause-circle" data-toggle="tooltip" data-html="true" />
            </span>
        </b-tooltip>

        <b-tooltip v-else-if="status == -2 || status == -4" :label="$t('status.deactivated')" position="is-right">
            <span class="tag is-warning">
                <i
                    v-if="status == -4 && !currentSite.desactivation_site_to"
                    class="fas fa-handshake"
                    data-toggle="tooltip"
                    data-html="true"
                    :title="$t('partenaire-site.deactivatedtemp')"
                />
                <i
                    v-else-if="status == -4 && site.desactivation_site_to"
                    class="fas fa-clock"
                    data-toggle="tooltip"
                    data-html="true"
                    :title="desactivateTime"
                />
                <i
                    v-else
                    class="fas fa-info"
                    data-toggle="tooltip"
                    data-html="true"
                    :title="$t('partenaire-site.deactivateddNextLevel')"
                />
            </span>
        </b-tooltip>

        <b-tooltip v-else-if="status == -3" :label="$t('status.refused')" position="is-right">
            <span class="tag is-danger">
                <i
                    v-if="currentSite.notes_customer"
                    class="fas fa-info"
                    data-toggle="tooltip"
                    data-html="true"
                    :title="currentSite.notes_customer"
                />
                <i v-else class="fas fa-ban" data-toggle="tooltip" data-html="true" />
            </span>
        </b-tooltip>

        <b-tooltip v-else-if="status == -5" :label="$t('status.waitingforimprove')" position="is-right">
            <span class="tag is-info">
                <i class="fas fa-briefcase" data-toggle="tooltip" data-html="true" />
            </span>
        </b-tooltip>

        <span v-else class="tag is-dark">Error</span>
    </span>
</template>

<script>
    export default {
        name: 'PartnerSiteStatus',
        props: ['site'],

        data() {
            return {
                status: null,
                currentSite: null,
                scheduleTime: null,
                desactivateTime: null
            }
        },

        watch: {
            site: {
                immediate: true,
                deep: true,
                handler(newValue) {
                    this.currentSite = newValue
                    this.status = newValue.actif
                }
            }
        },

        created() {
            this.currentSite = this.site
            this.status = this.currentSite.actif

            if (this.status == 1 && this.currentSite.scheduled_at) {
                this.scheduleTime = `${this.$t('partenaire-site.deactivationfrom')} ${this.moment(
                    this.currentSite.scheduled_at
                ).format('DD/MM/YY')} ${this.$t('partenaire-site.to')} ${this.moment(
                    this.currentSite.desactivation_site_to
                ).format('DD/MM/YY')} ${this.$t('global.included')}`
            }

            if (this.status == -4 && this.currentSite.desactivation_site_to) {
                this.desactivateTime = `${this.$t('partenaire-site.deactivateduntil')} ${this.moment(
                    this.currentSite.desactivation_site_to
                ).format('DD/MM/YY')} ${this.$t('global.included')}`
            }
        }
    }
</script>
