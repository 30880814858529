import ApiService from '../../../services/api.service'

export default {
    async exportAgencyOrders(context, data) {
        let params = await context.dispatch('prepareParams', context.state.contentsConfig, { root: true })

        return new Promise((resolve, reject) => {
            ApiService.get(`/agencies/${data.agency_id}/clients/${data.client_id}/export.${data.ext}`, params, {
                responseType: 'blob'
            })
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    updateCampaignContent(context, { accompagnement_id, content_id, period }) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/admin/campaign/${accompagnement_id}/content`, {
                content_id,
                period
            })
                .then(async data => {
                    await context.dispatch('getContentsByCampaign', accompagnement_id)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    detachCampaignContent(context, { accompagnement_id, content_id }) {
        return new Promise((resolve, reject) => {
            context.commit('setOffsiteLoading', true)
            ApiService.get(`/admin/campaign/${accompagnement_id}/content/${content_id}/detach`)
                .then(async data => {
                    await context.dispatch('getContentsByCampaign', accompagnement_id)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    attachCampaignContent(context, { accompagnement_id, ids }) {
        return new Promise((resolve, reject) => {
            context.commit('setOffsiteLoading', true)
            ApiService.post(`/admin/campaign/${accompagnement_id}/contents/attach`, {
                ids
            })
                .then(async data => {
                    await context.dispatch('getContentsByCampaign', accompagnement_id)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    getContentsByCampaign(context, campaignId) {
        return new Promise((resolve, reject) => {
            context.commit('setOffsiteLoading', true)
            ApiService.get(`/campaign/${campaignId}/periods`)
                .then(({ data }) => {
                    context.commit('setOffsite', data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
                .finally(() => {
                    context.commit('setOffsiteLoading', false)
                })
        })
    },

    getContentsByPeriod(context, { campaignId, period, page = 1 }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/campaign/${campaignId}/contents`, {
                page: page,
                period: period
            })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    }
}
