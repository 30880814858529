export default () => ({
    user: null,
    locale: null,
    credit: null,
    security: {
        hideResults: false,
        isFavorite: false,
        seeKeyword: false,
        isAdmin: false,
        isProspect: false
    },
    orders: {data:[]},
    ordersConfig: {
        include: '',
        sort: 'id',
        sortDirection: 'desc',
        page: 1,
        filters: {},
        per_page: 50
    },

    withdrawal: {
        banque: null,
        iban: null,
        bic: null,
        saveDefaultPayment: null,
        user: null,
        user_invoice_number: null
    },

    startDate: null,
    endDate: null,
})
