<template>
    <form>
        <b-field label="A quel agence l'utilisateur doit-il être rattaché ?">
            <b-autocomplete
                v-model="agencySearchQuery"
                :data="agencies"
                placeholder="Recherche par prénom ou nom"
                :loading="isFetchingAgencies"
                :custom-formatter="option => `${option.firstname} ${option.lastname}`"
                :clearable="true"
                @select="option => setAgency(option)"
                @input="searchAgencies"
            >
                <template slot-scope="props">
                    {{ props.option.firstname }} {{ props.option.lastname }}
                    <br />
                    <div class="font-italic">
                        <small><i class="fa fa-at" /> {{ props.option.email }}</small>
                    </div>
                </template>
            </b-autocomplete>
        </b-field>

        <div v-show="user.agency_id">
            <b-field label="L'utilisateur est un">
                <b-radio v-model="user.type" name="type" native-value="manager"> Manager</b-radio>
                <b-radio v-model="user.type" name="type" native-value="consultant"> Consultant</b-radio>
                <b-radio v-model="user.type" name="type" native-value="client"> Client</b-radio>
            </b-field>

            <div v-if="user.type === 'client'">
                <b-field label="Rattacher le client à">
                    <b-radio
                        v-model="user.responsible_type"
                        name="responsible_type"
                        :native-value="5"
                        @input="resetResponsible"
                    >
                        Un consultant
                    </b-radio>
                    <b-radio
                        v-model="user.responsible_type"
                        name="responsible_type"
                        :native-value="4"
                        @input="resetResponsible"
                    >
                        Un manager
                    </b-radio>
                </b-field>

                <b-field
                    v-if="user.responsible_type === 5"
                    label="Attacher le client au consultant..."
                    message="En l'absence de consultant, le client sera rattaché à l'agence directement"
                >
                    <b-autocomplete
                        v-model="name"
                        :data="consultants"
                        placeholder="Recherche par prénom ou nom"
                        :loading="isFetching"
                        :custom-formatter="option => `${option.user.firstname} ${option.user.lastname}`"
                        :clearable="true"
                        @select="option => (user.responsible_id = option ? option.id : null)"
                        @input="searchConsultants"
                    >
                        <template slot-scope="props">
                            {{ props.option.user.firstname }} {{ props.option.user.lastname }}
                            <br />
                            <div class="font-italic">
                                <small><i class="fa fa-at" /> {{ props.option.user.email }}</small>
                            </div>
                        </template>
                    </b-autocomplete>
                </b-field>

                <b-field
                    v-else
                    label="Attacher le client au manager..."
                    message="En l'absence de manager, l'utilisateur sera rattaché à l'agence directement"
                >
                    <SearchManager @select="option => (user.responsible_id = option ? option.id : null)" />
                </b-field>
            </div>

            <div v-if="user.type === 'consultant'">
                <b-field
                    label="Rattacher le consultant à un manager"
                    message="En l'absence de manager, l'utilisateur sera rattaché à l'agence directement"
                >
                    <SearchManager @select="option => (user.manager_id = option ? option.id : null)" />
                </b-field>
            </div>
        </div>
    </form>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'CreateClientForm',
        data() {
            return {
                agencySearchQuery: '',
                name: '',
                selected: null,

                consultants: null,
                managers: null,
                agencies: null,

                isFetching: false,
                isFetchingAgencies: false,
                timeout: null
            }
        },
        computed: {
            ...mapState('consultants', {
                user: 'newUser',
                config: 'searchConfig'
            })
        },
        methods: {
            ...mapActions('consultants', ['getManagers', 'getConsultants', 'getAgencies']),
            searchAgencies() {
                this.isFetchingAgencies = true

                if (this.timeout) {
                    clearTimeout(this.timeout)
                }

                this.timeout = setTimeout(this.doSearchAgencies, 500)
            },
            doSearchAgencies() {
                this.config.filters['computed_name'] = this.agencySearchQuery
                this.getAgencies({ config: this.config, commit: false })
                    .then(data => {
                        this.agencies = data.agencies.data
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.isFetchingAgencies = false
                    })
            },
            searchConsultants() {
                this.isFetching = true

                if (this.timeout) {
                    clearTimeout(this.timeout)
                }

                this.timeout = setTimeout(this.doSearchConsultant, 500)
            },
            doSearchConsultant() {
                this.config.filters['computed_name'] = this.name
                this.getConsultants({ config: this.config, commit: false })
                    .then(data => {
                        this.consultants = data.consultants.data
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            },
            searchManagers() {
                this.isFetching = true

                if (this.timeout) {
                    clearTimeout(this.timeout)
                }

                this.timeout = setTimeout(this.doSearchManagers, 500)
            },
            doSearchManagers() {
                this.config.filters['computed_name'] = this.name
                this.getManagers({ config: this.config, commit: false })
                    .then(data => {
                        this.managers = data.managers.data
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            },
            setAgency(agency) {
                this.$store.commit('consultants/setAgency', { id: agency.id })
                this.user.agency_id = agency.id
            }
        }
    }
</script>
