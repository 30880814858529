<template>
    <div class="d-flex flex-column flex-md-row align-items-baseline">
        <b-loading v-model="isLoading" :is-full-page="true" />

        <article class="card p-3 mb-3 mb-md-0">
            <div class="d-flex flex-column mb-2 text-center">
                <p class="mb-1">
                    {{ currentAccompagnement.url }}
                </p>

                <status
                    :status="currentAccompagnement.statut"
                    :available="statusList"
                    @change="status => this.editAccompagnement(status)"
                />
            </div>
            <p class="bg-light rounded p-2 text-center">
                <span>{{ currentAccompagnement.user.firstname }} {{ currentAccompagnement.user.lastname }}</span>
                <span class="text-muted">({{ currentAccompagnement.user.email }})</span>
            </p>
        </article>

        <div class="w-100 pl-md-5">
            <table class="w-100 ml-auto">
                <thead>
                    <tr>
                        <th>Facturation</th>
                        <th>Mois</th>
                        <th>Budget Offsite</th>
                        <th>Budget Onsite</th>
                        <th>Budget Total</th>
                        <th>Paiement</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in currentAccompagnement.repartitions" :key="item.id">
                        <td>
                            <span class="font-weight-bold mr-auto d-md-none">Facturation</span>
                            <b-switch
                                v-model="item.billable"
                                type="is-success"
                                :true-value="1"
                                :false-value="0"
                                @input="editBudgetRepartition(item)"
                            />
                        </td>
                        <td>
                            <span class="font-weight-bold mr-auto d-md-none">Mois</span>
                            {{ moment(item.periode).format('MM/YYYY') }}
                        </td>
                        <td>
                            <span class="font-weight-bold mr-auto d-md-none">Budget Offsite</span>

                            <span
                                v-if="!editBudgetOffSite || editBudgetOffSite != item.id"
                                class="is-editable"
                                @click="editBudgetOffSite = item.id"
                            >
                                <span> {{ item.budget }}€ </span>
                            </span>

                            <div v-if="editBudgetOffSite == item.id" class="form-group form-group-sm">
                                <input
                                    v-model="item.budget"
                                    type="text"
                                    class="form-control form-control-sm"
                                    @keydown.enter="editBudgetRepartition(item)"
                                    @keydown.esc="closeFlyEdit"
                                />
                                <small class="text-muted"
                                    ><kbd>Entrée</kbd> pour valider / <kbd>Échap</kbd> pour quitter</small
                                >
                            </div>
                        </td>
                        <td>
                            <span class="font-weight-bold mr-auto d-md-none">Budget Onsite</span>

                            <span
                                v-if="!editBudgetOnSite || editBudgetOnSite != item.id"
                                class="is-editable"
                                @click="editBudgetOnSite = item.id"
                            >
                                <span> {{ item.budget_onsite }}€ </span>
                            </span>

                            <div v-if="editBudgetOnSite == item.id" class="form-group form-group-sm">
                                <input
                                    v-model="item.budget_onsite"
                                    type="text"
                                    class="form-control form-control-sm"
                                    @keydown.enter="editBudgetRepartition(item)"
                                    @keydown.esc="closeFlyEdit"
                                />
                                <small class="text-muted"
                                    ><kbd>Entrée</kbd> pour valider / <kbd>Échap</kbd> pour quitter</small
                                >
                            </div>
                        </td>
                        <td>
                            <span class="font-weight-bold mr-auto d-md-none">Budget Total</span>
                            {{ parseInt(item.budget_onsite) + parseInt(item.budget) }}€
                        </td>
                        <td>
                            <span
                                v-if="item.payment_request_status == 0 || item.payment_request_status == null"
                                class="badge badge-info"
                                >A demander</span
                            >
                            <span v-else class="badge badge-success">Déjà effectué</span>
                        </td>
                        <td>
                            <b-button
                                v-if="moment(item.periode).diff(moment(), 'months', true) > 0"
                                size="is-small"
                                icon-left="hand-holding-usd"
                                title="Demande de crédit"
                                :disabled="!item.billable"
                                @click="generateRequest(item)"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import Status from '../../Campaigns/Status'

    export default {
        name: 'AdminAccompagnementDetailedRow',
        components: { Status },
        props: {
            accompagnement: {
                type: Object,
                required: true
            },
            statusList: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                currentAccompagnement: null,

                isLoading: false,

                editBudgetOnSite: null,
                editBudgetOffSite: null
            }
        },

        watch: {
            accompagnement: {
                immediate: true,
                deep: true,
                handler(newValue) {
                    this.currentAccompagnement = newValue
                }
            }
        },

        created() {
            this.currentAccompagnement = this.accompagnement
        },

        methods: {
            generateRequest: function (budget) {
                this.isLoading = true
                this.axios
                    .post('/admin/accompagnements/quotation/' + this.currentAccompagnement.id, {
                        date: budget.periode
                    })
                    .then(response => {
                        this.$store.dispatch('toast', {
                            title: "Création de l'ordre de paiement",
                            type: 'success'
                        })
                    })
                    .catch(error => {
                        this.$store.dispatch('toast', {
                            title: "Une erreur est survenue pendant la création de l'ordre de paiement",
                            type: 'error'
                        })
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },

            editBudgetRepartition(repartition) {
                this.isLoading = true

                this.axios
                    .post(`/admin/accompagnements/${repartition.accompagnement_id}/${repartition.id}`, {
                        budget: repartition.budget,
                        budget_onsite: repartition.budget_onsite,
                        billable: repartition.billable,
                        _method: 'PATCH'
                    })
                    .then(() => {
                        this.$store.dispatch('toast', {
                            title: 'Accompagnement mis à jour',
                            type: 'success'
                        })
                    })
                    .catch(error => {
                        console.error(error)
                        this.$store.dispatch('toast', {
                            title: 'Une erreur est survenue lors de la mise à jour',
                            type: 'error'
                        })
                    })
                    .finally(() => {
                        this.isLoading = false
                    })

                this.closeFlyEdit()
            },

            closeFlyEdit() {
                this.editBudgetOnSite = null
                this.editBudgetOffSite = null
            },

            editAccompagnement(status) {
                this.currentAccompagnement.statut = status
                this.$emit('editAccompagnement', {
                    ...this.currentAccompagnement,
                    ...{ statut: status }
                })
            }
        }
    }
</script>
