import ApiService from '../../../services/api.service'

export default {
    list(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/spots', params)
                .then(({ data: { spots } }) => {
                    resolve(spots)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    getContentItems(context, { spot, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/spots/${spot}/content-items`, params)
                .then(({ data: { contentItems } }) => {
                    resolve(contentItems)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    getContentItemsBySpot(context, { params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/spots/content-items/generated`, params)
                .then(({ data: { spots } }) => {
                    resolve(spots)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    getContentItemsCount(context, status) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/spots/content-items/${status}/count`)
                .then(({ data: { count } }) => {
                    resolve(count)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    getAvailableCategories(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get('/spots/categories', params)
                .then(({ data: { categories } }) => {
                    resolve(categories)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    createContentItem(context, { spot_id, params }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/spots/${spot_id}/content-items`, params)
                .then(({ data: { spots } }) => {
                    resolve(spots)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    createBulkContentItem(context, { params }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/spots/content-items`, params)
                .then(({ data: { spots } }) => {
                    resolve(spots)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    listOrders(context, { status, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(
                `/admin/content_spots?include=buyer,spot${status ? `&filter[status]=${status}` : ''}`,
                params
            )
                .then(({ data: { content_spots } }) => {
                    resolve(content_spots)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    countByStatus(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/content_spots/count', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    countAvailableSpots(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get('/spots/count', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    create(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/admin/spots/bulk', params)
                .then(({ data: { spots } }) => {
                    resolve(spots)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    update(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/admin/spots/${params.id}`, params)
                .then(({ data: { spots } }) => {
                    resolve(spots)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    delete(context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/admin/spots/${id}`)
                .then(({ data: { spots } }) => {
                    resolve(spots)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    buy(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/rd/buy`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    listMyOrders(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get('/content_spots?include=spot', params)
                .then(({ data: { content_spots } }) => {
                    resolve(content_spots)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    listOrdersBySpot(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/content_spots?filter[spot_id]=${context.state.spotId}&include=buyer`, params)
                .then(({ data: { content_spots } }) => {
                    resolve(content_spots)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    addTitle(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/admin/content_spots/${params.id}`, params)
                .then(({ data: { spots } }) => {
                    resolve(spots)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    sendToRedactor(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/admin/content_spots/bulk`, params)
                .then(({ data: { spots } }) => {
                    resolve(spots)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    getRemainingCredits(context) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/content_spots/credits')
                .then(({ data: { credits } }) => {
                    resolve(credits)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    getWordpressCategories(context, { spotId }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/spots/${spotId}/wordpress-categories`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    spotPublishContentItems(context, { spot, data }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/spots/${spot}/content-items/publish`, data)
                .then(response => {
                    resolve(response.data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    }
}
