<template>
    <b-modal v-model="active">
        <template>
            <header class="px-4 py-3 has-background-warning shadow-sm">
                <h4 class="h4 p-0 mb-0">Acheter des RD pour {{ url }}</h4>
            </header>
            <div class="modal-body">
                <h3 class="text-center">Voulez-vous vraiment acheter ces {{ spots }} RD pour {{ totalPrice }}€ ?</h3>
            </div>
            <div class="modal-footer">
                <b-button type="button" class="btn btn-primary" :loading="isLoading" @click.prevent="closeModal">
                    Annuler
                </b-button>
                <b-button
                    v-if="user"
                    :loading="isLoading"
                    type="button"
                    :disabled="user.credit_amount < totalPrice"
                    class="btn btn-secondary"
                    @click="buy('credits')"
                >
                    Acheter avec les crédits
                </b-button>
                <b-button
                    v-if="user"
                    :loading="isLoading"
                    type="button"
                    class="btn btn-secondary"
                    :disabled="user.gains < totalPrice"
                    @click="buy('benefits')"
                >
                    Acheter avec les gains
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex'

    export default {
        name: 'BuyRDModal',
        props: {
            price: {
                type: Number,
                required: true
            },
            spots: {
                type: Number,
                required: true
            },
            url: {
                type: String,
                required: true
            },
            specificAnchors: {
                type: Array,
                required: true
            },
            category: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                active: false,
                isLoading: false
            }
        },
        created() {
            this.get()
        },
        computed: {
            ...mapState('auth', ['user']),
            totalPrice() {
                return this.price * this.spots
            }
        },
        methods: {
            ...mapActions('spots', { buySpots: 'buy' }),
            ...mapActions('auth', ['me']),
            ...mapMutations('auth', {
                setBenefits: 'setBenefits',
                setCredits: 'setCredits'
            }),
            openModal() {
                this.active = true
            },
            closeModal() {
                this.active = false
            },
            buy(type) {
                this.isLoading = true
                let params = {
                    url: this.url,
                    rd_number: this.spots,
                    use_credits: type === 'credits',
                    specific_anchor: this.specificAnchors,
                    category: this.category.id
                }
                this.buySpots(params)
                    .then(data => {
                        this.setBenefits(data.benefits)
                        this.setCredits(data.credits)
                        this.isLoading = false
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'Les RD ont bien été achetés'
                        })
                        this.closeModal()
                        this.$router.push('/rd/orders')
                    })
                    .catch(error => {
                        this.isLoading = false
                        this.$store.dispatch('toast', {
                            type: 'error',
                            title: 'Une erreur est survenue',
                            message: error.data.message
                        })
                    })
            }
        }
    }
</script>

<style></style>