export default () => ({
    contents: null,
    offsite: {
        data: {
            periods: [],
            available: []
        },
        loading: true
    },
    contentsPerProject: null,
    contentsConfig: {
        include: '',
        sort: 'created_at',
        sortDirection: 'desc',
        page: 1,
        filters: {},
        per_page: 10
    },

    projects: null
})
