<template>
    <div>
        <h2><i class="fas fa-chart-line mr-2" />Mes stats ({{ computePeriod }})</h2>
        <div v-if="sites.length > 0 && stats !== null" class="d-lg-flex w-100" style="gap: 1rem">
            <div class="col-lg-3 col-sm-12 pt-3">
                <StatCard :color="'warning'" :icon="'fas fa-check'">
                    <template v-slot:counter>
                        <div class="d-flex justify-content-end align-items-center">
                            <h3 class="m-0">
                                {{ stats.orders_count }}
                            </h3>
                            &nbsp;
                            <b-tooltip position="is-right">
                                <p
                                    class="m-0 p-1 text-sm box ml-2 text-white text-nowrap"
                                    :class="
                                        computeVariation(stats.orders_count, stats.variation_orders_count) > 0
                                            ? 'bg-success'
                                            : 'bg-danger'
                                    "
                                    style="width: fit-content; margin-left: auto"
                                >
                                    {{
                                        computeVariation(stats.orders_count, stats.variation_orders_count) > 0
                                            ? '+'
                                            : ''
                                    }}
                                    {{ computeVariationPercentage(stats.orders_count, stats.variation_orders_count) }}
                                    %
                                </p>
                                <template v-slot:content>
                                    <b>Evolution du nombre de commandes sur la période précédente</b>
                                    (Ex : Mois en cours VS Mois dernier)
                                </template>
                            </b-tooltip>
                        </div>
                    </template>
                    <template v-slot:text>
                        <span>Nombre de commandes</span>
                    </template>
                </StatCard>
                <StatCard :color="'info'" :icon="'fas fa-euro-sign'">
                    <template v-slot:counter>
                        <div class="d-flex justify-content-end align-items-center">
                            <h3 class="m-0">
                                {{ stats.sum | toCurrency }}
                            </h3>
                            &nbsp;
                            <b-tooltip position="is-right">
                                <p
                                    class="m-0 p-1 text-sm box ml-2 text-white text-nowrap"
                                    :class="
                                        computeVariation(stats.sum, stats.variation_sum) > 0
                                            ? 'bg-success'
                                            : 'bg-danger'
                                    "
                                    style="width: fit-content; margin-left: auto"
                                >
                                    {{ computeVariation(stats.sum, stats.variation_sum) > 0 ? '+' : '' }}
                                    {{ computeVariationPercentage(stats.sum, stats.variation_sum) }}
                                    %
                                </p>
                                <template v-slot:content>
                                    <b>Evolution du chiffre d'affaires sur la période précédente</b>
                                    (Ex : Mois en cours VS Mois dernier)
                                </template>
                            </b-tooltip>
                        </div>
                    </template>
                    <template v-slot:text>
                        <span>CA Généré</span>
                    </template>
                </StatCard>
                <StatCard :color="'success'" :icon="'fas fa-ruler'">
                    <template v-slot:counter>
                        <div class="d-flex justify-content-end align-items-center">
                            <h3 class="m-0">
                                {{ (stats.sum / (stats.orders_count === 0 ? 1 : stats.orders_count)) | toCurrency }}
                            </h3>
                            &nbsp;<b-tooltip position="is-right">
                                <p
                                    class="m-0 p-1 text-sm box ml-2 text-white text-nowrap"
                                    :class="
                                        computeMeanPriceVariation(
                                            stats.sum,
                                            stats.orders_count,
                                            stats.variation_sum,
                                            stats.variation_orders_count
                                        ) > 0
                                            ? 'bg-success'
                                            : 'bg-danger'
                                    "
                                    style="width: fit-content; margin-left: auto"
                                >
                                    {{
                                        computeMeanPriceVariation(
                                            stats.sum,
                                            stats.orders_count,
                                            stats.variation_sum,
                                            stats.variation_orders_count
                                        ) > 0
                                            ? '+'
                                            : ''
                                    }}
                                    {{
                                        computeMeanPriceVariation(
                                            stats.sum,
                                            stats.orders_count,
                                            stats.variation_sum,
                                            stats.variation_orders_count
                                        )
                                    }}
                                    €
                                </p>
                                <template v-slot:content>
                                    <b>Evolution du gain moyen par commande sur la période précédente</b>
                                    (Ex : Mois en cours VS Mois dernier)
                                </template>
                            </b-tooltip>
                        </div>
                    </template>
                    <template v-slot:text>
                        <span>Gain moyen</span>
                    </template>
                </StatCard>
            </div>
            <div class="vl d-xs-none" />
            <div class="col-lg-8 col-sm-12">
                <BarChart class="w-100" :options="chartOptions" :data="ordersData" />
            </div>
        </div>
        <div v-else>
            <nl-loader :show="true" />
        </div>
    </div>
</template>

<script>
    import StatCard from '@/components/Admin/Partner/StatCard'
    import moment from 'moment'

    export default {
        name: 'StatsDashboard',
        components: { StatCard },
        props: {
            sites: {
                type: Array,
                required: true
            },
            stats: {
                type: Object,
                required: false
            },
            start_date: {
                type: String,
                required: true
            },
            end_date: {
                type: String,
                required: true
            },
            period: {
                type: String,
                default: 'last7Days'
            }
        },
        computed: {
            computePeriod() {
                switch (this.period) {
                    case 'last7days':
                        return '7 derniers jours'
                    case 'currentMonth':
                        return 'Mois en cours - ' + moment().format('MMMM')
                    case 'lastMonth':
                        return 'Mois dernier - ' + moment().subtract(1, 'months').format('MMMM')
                    case 'currentYear':
                        return 'Année en cours - ' + moment().format('YYYY')
                    case 'custom':
                        return (
                            'Personnalisé - ' +
                            moment(this.start_date).format('L') +
                            ' - ' +
                            moment(this.end_date).format('L')
                        )
                    default:
                        return '7 derniers jours'
                }
            },
            ordersData() {
                // sort stats.orders by date
                const orders = this.stats.orders.sort((a, b) => {
                    a = a.date.split('/')
                    b = b.date.split('/')
                    return a[2] - b[2] || a[1] - b[1] || a[0] - b[0]
                })

                const variation = this.stats.last_period.sort((a, b) => {
                    a = a.date.split('/')
                    b = b.date.split('/')
                    return a[2] - b[2] || a[1] - b[1] || a[0] - b[0]
                })

                let labels = []

                switch (this.period) {
                    case 'last7days':
                        labels = orders.map(order => {
                            return moment(order.date, 'DD/MM/YYYY').format('dddd')
                        })
                        break
                    case 'currentMonth':
                        labels = orders.map(order => {
                            return moment(order.date, 'DD/MM/YYYY').format('dddd DD')
                        })
                        break
                    case 'lastMonth':
                        labels = orders.map(order => {
                            return moment(order.date, 'DD/MM/YYYY').format('DD')
                        })
                        break
                    case 'currentYear':
                        labels = orders.map(order => {
                            return moment(order.date, 'MM/YYYY').format('MMMM')
                        })
                        break
                    case 'custom':
                        labels = orders.map(order => {
                            return order.date.length > 7
                                ? moment(order.date, 'DD/MM/YYYY').format('D MMMM YYYY')
                                : moment(order.date, 'MM/YYYY').format('MMMM YYYY')
                        })
                        break
                    default:
                        labels = orders.map(order => {
                            return order.date
                        })
                        break
                }

                return {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Nombre de commandes',
                            backgroundColor: '#fabc15',
                            data: orders.map(order => order.count),
                            yAxisID: 'y-axis-1',
                            order: 3
                        },
                        {
                            label: 'Nombre de commandes période -1',
                            backgroundColor: '#f54e54',
                            data: variation.map(order => order.count),
                            yAxisID: 'y-axis-1',
                            order: 4
                        },
                        {
                            label: "Chiffre d'affaire",
                            data: orders.map(order => order.price_paid.toFixed(2)),
                            type: 'line',
                            yAxisID: 'y-axis-2',
                            borderWidth: 2,
                            fill: false,
                            borderColor: '#17a2b8',
                            order: 1
                        },
                        {
                            label: "Chiffre d'affaire période -1",
                            data: variation.map(order => order.price_paid.toFixed(2)),
                            type: 'line',
                            yAxisID: 'y-axis-2',
                            borderWidth: 2,
                            fill: false,
                            borderColor: '#28a745',
                            order: 2
                        }
                    ]
                }
            },
            chartOptions() {
                return {
                    responsive: true,
                    maintainAspectRatio: false,
                    tooltips: {
                        // add a tooltip with the date of the order in the title and the count in the body
                        callbacks: {
                            title: (tooltipItem, data) => {
                                return data.datasets[tooltipItem[0].datasetIndex].label
                            },
                            label: (tooltipItem, data) => {
                                return (
                                    data.datasets[tooltipItem.datasetIndex].label +
                                    ' : ' +
                                    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                                )
                            }
                        }
                    },
                    scales: {
                        yAxes: [
                            {
                                id: 'y-axis-1',
                                type: 'linear',
                                position: 'left',
                                ticks: {
                                    beginAtZero: true,
                                    suggestedMax: Math.max(...this.stats.orders.map(order => order.count)) * 1.1,
                                    stepSize: 1
                                }
                            },
                            {
                                id: 'y-axis-2',
                                type: 'linear',
                                position: 'right',
                                gridLines: {
                                    drawOnChartArea: false
                                },
                                ticks: {
                                    beginAtZero: true,
                                    callback: function (value, index, values) {
                                        return value + ' €'
                                    },
                                    stepSize: 50,
                                    suggestedMax: Math.max(...this.stats.orders.map(order => order.price_paid)) * 1.1
                                }
                            }
                        ]
                    }
                }
            }
        },
        methods: {
            computeVariation(value, variation) {
                return value - variation
            },
            computeVariationPercentage(value, variation) {
                return (((value - variation) / (value === 0 ? 1 : value)) * 100).toFixed(0)
            },
            computeMeanPriceVariation(sum, orders_count, variation_sum, variation_orders_count) {
                return (
                    sum / (orders_count === 0 ? 1 : orders_count) -
                    variation_sum / (variation_orders_count === 0 ? 1 : variation_orders_count)
                ).toFixed(0)
            }
        }
    }
</script>

<style scoped>
    .vl {
        border-left: 1px solid rgba(0, 0, 0, 0.2);
        height: auto;
        margin: 0 10px;
    }
</style>
