<template>
    <nl-modal ref="modal" title="Choisir une période" :add-legacy-events="false" position="center" size="md">
        <div v-if="content !== null" class="d-flex justify-content-center flex-column">
            <h4>Nouvelle période</h4>
            <div class="p-2">
                <b-select v-model="period">
                    <option v-for="(period, index) in periods" :key="index" :value="period.period">
                        {{ moment(period.period, 'YYYY-MM-dd').format('MMMM YYYY') }}
                    </option>
                </b-select>
            </div>
        </div>
        <template v-slot:footer>
            <div class="d-flex w-100 justify-content-end" style="gap: 1rem">
                <b-button type="button" class="btn btn-danger" @click="hide"> Annuler</b-button>
                <b-button type="button" class="btn btn-primary" @click="onSave"> Sauvegarder</b-button>
            </div>
        </template>
    </nl-modal>
</template>

<script>
    import Status from '@/components/Content/Status.vue'
    import moment from 'moment'

    export default {
        name: 'PeriodModal',
        components: { Status },
        props: {
            periods: {
                type: Array,
                required: false
            }
        },
        data() {
            return {
                content: null,
                period: null
            }
        },
        computed: {
            moment() {
                return moment
            }
        },
        methods: {
            show(content) {
                this.content = content
                this.period = content.period
                this.$refs.modal.show()
            },
            hide() {
                this.$refs.modal.hide()
            },
            onSave() {
                this.$emit('changePeriod', this.content.id, this.period)
                this.hide()
                this.content = null
            }
        }
    }
</script>

<style scoped></style>
