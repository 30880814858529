<script>
    import { Doughnut } from 'vue-chartjs'

    export default {
        extends: Doughnut,
        props: ['data', 'options'],
        watch: {
            data: function () {
                this.init()
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init: function () {
                this.renderChart(this.data, this.options)
            }
        }
    }
</script>

<style></style>
