<template>
    <div>
        <hr />
        <div
            class="d-flex justify-content-between align-items-center cursor-pointer"
            @click="
                () => {
                    visibleTab = !visibleTab
                }
            "
        >
            <h2><i class="fas fa-chart-pie mr-2" />Statistiques des sites</h2>
            <i v-if="!visibleTab" class="fas fa-caret-down fa-lg" />
            <i v-if="visibleTab" class="fas fa-caret-up fa-lg" />
        </div>
        <collapse-transition :duration="500" mode="out-in" origin="center">
            <div v-if="visibleTab">
                <div v-if="stats !== null">
                    <div class="d-flex flex-column flex-lg-row">
                        <div class="col-lg-6 d-flex flex-column align-items-center">
                            <h3>Santé des sites</h3>
                            <PieChart :data="healthChartData" :options="healthChartOptions" class="w-50" />
                        </div>
                        <div class="vl" />
                        <div class="col-lg-6 d-flex flex-column align-items-center mt-lg-0 mt-3">
                            <h3>Nombre de RD</h3>
                            <PieChart :data="rdChartData" :options="rdChartOptions" class="w-50" />
                        </div>
                    </div>
                </div>
                <div v-else>
                    <nl-loader :show="true" />
                </div>
            </div>
        </collapse-transition>
    </div>
</template>

<script>
    import PieChart from '@/components/Chart/PieChart.vue'

    export default {
        name: 'SitesStatistics',
        components: { PieChart },
        props: {
            stats: {
                type: Object
            }
        },
        data() {
            return {
                visibleTab: false
            }
        },
        computed: {
            //generate PieChart data
            healthChartData() {
                if (this.stats !== null) {
                    return {
                        labels: ['0', '2XX', '3XX', '4XX', '5XX'],
                        datasets: [
                            {
                                data: [
                                    this.stats.health['0-199'],
                                    this.stats.health['200-299'],
                                    this.stats.health['300-399'],
                                    this.stats.health['400-499'],
                                    this.stats.health['500-599']
                                ],
                                backgroundColor: ['#17a2b8', '#28a745', '#ffc107', '#fa9531', '#dc3545']
                            }
                        ]
                    }
                }
            },
            healthChartOptions() {
                return {
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: {
                        display: true
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                let dataset = data.datasets[tooltipItem.datasetIndex]
                                let total = dataset.data.reduce(function (
                                    previousValue,
                                    currentValue,
                                    currentIndex,
                                    array
                                ) {
                                    return previousValue + currentValue
                                })
                                let currentValue = dataset.data[tooltipItem.index]
                                let precentage = Math.floor((currentValue / total) * 100 + 0.5)
                                return precentage + '% (' + currentValue + ')'
                            },
                            title: function (tooltipItem, data) {
                                return data.labels[tooltipItem[0].index]
                            }
                        }
                    }
                }
            },
            rdChartData() {
                if (this.stats !== null) {
                    return {
                        labels: ['<25', '25-49', '50-100', '>100'],
                        datasets: [
                            {
                                data: [
                                    this.stats.rds['0-25'],
                                    this.stats.rds['25-59'],
                                    this.stats.rds['60-100'],
                                    this.stats.rds['100']
                                ],
                                backgroundColor: ['#dc3545', '#ffc107', '#28a745', '#17a2b8']
                            }
                        ]
                    }
                }
            },
            rdChartOptions() {
                return {
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: {
                        display: true
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                let dataset = data.datasets[tooltipItem.datasetIndex]
                                let total = dataset.data.reduce(function (
                                    previousValue,
                                    currentValue,
                                    currentIndex,
                                    array
                                ) {
                                    return previousValue + currentValue
                                })
                                let currentValue = dataset.data[tooltipItem.index]
                                let precentage = Math.floor((currentValue / total) * 100 + 0.5)
                                return precentage + '% (' + currentValue + ')'
                            },
                            title: function (tooltipItem, data) {
                                return data.labels[tooltipItem[0].index]
                            }
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .vl {
        border-left: 3px solid hsl(0deg, 0%, 86%);
        height: auto;
        margin: 0 10px;
    }
</style>
