import { ROLES } from '@/config/roles'

const metaGroup = { roles: [ROLES.REDACTOR] }

export default [
    {
        path: '/redactor',
        name: 'Redactor',
        component: () => import('../views/Redactor/Redactor.vue'),
        meta: metaGroup
    },
    {
        path: '/redactor/details',
        name: 'RedactorDetails',
        component: () => import('../views/Redactor/RedactorDetails.vue'),
        meta: metaGroup
    },
    {
        path: '/redactor/writing',
        name: 'Writing',
        component: () => import('../views/Redactor/Writing.vue'),
        meta: metaGroup
    }
]
