<template>
    <div>
        <b-button size="is-small" type="is-light" @click="open = true">
            <b-icon icon="eye" class="mr-2" />
            {{ keywordsCount }}
        </b-button>
        <b-modal v-model="open">
            <template>
                <header class="modal-card-head has-background-warning">
                    <p class="modal-card-title m-0">Mots-clés</p>
                </header>
                <section class="modal-card-body">
                    <NlTable
                        async
                        sortable
                        :columns.sync="columns"
                        :get-data="loadAsyncData"
                        :default-sort="'position'"
                    >
                        <template #date_serp="{ props: { row } }">
                            {{ moment(row.date_serp).format('DD/MM/YYYY') }}
                        </template>
                    </NlTable>
                </section>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable.vue'
    import { mapActions } from 'vuex'

    export default {
        name: 'KeywordModal',
        components: { NlTable },
        props: {
            urlId: {
                type: Number,
                required: true
            },
            keywordsCount: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                open: false,
                columns: [
                    {
                        centered: false,
                        custom: false,
                        field: 'keyword',
                        label: 'Mot-clé',
                        searchable: false,
                        sortable: false
                    },
                    {
                        centered: false,
                        custom: false,
                        field: 'position',
                        label: 'Position',
                        searchable: false,
                        sortable: true
                    },
                    {
                        centered: false,
                        custom: false,
                        field: 'volume',
                        label: 'Volume',
                        searchable: false,
                        sortable: false
                    },
                    {
                        centered: false,
                        custom: true,
                        field: 'date_serp',
                        label: 'Date de serp',
                        searchable: false,
                        sortable: false
                    }
                ]
            }
        },
        methods: {
            ...mapActions('urls', ['getKeywordsByUrl']),

            loadAsyncData(params) {
                return this.getKeywordsByUrl({
                    url_id: this.urlId,
                    params
                })
            }
        }
    }
</script>
