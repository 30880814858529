<template>
    <nl-modal :open-modal="openModal" title="Historique des gains" size="xl" @hidden="close">
        <b-table
            :sticky-header="true"
            :data="earningLog"
            :mobile-cards="true"
            paginated
            :striped="true"
            :loading="loading"
            :per-page="10"
            default-sort="created_at"
            default-sort-direction="desc"
        >
            <b-table-column field="created_at" sortable label="Date">
                <template v-slot="props">
                    {{ props.row[props.column.field] }}
                </template>
            </b-table-column>
            <b-table-column field="log" sortable label="Log">
                <template v-slot="props">
                    <span v-html="props.row[props.column.field]" />
                </template>
            </b-table-column>
            <b-table-column field="montant" sortable label="Montant">
                <template v-slot="props">
                    {{ props.row[props.column.field] | currency }}
                </template>
            </b-table-column>
        </b-table>
    </nl-modal>
</template>

<script>
    import { mapActions } from 'vuex'
    import { EventManager } from '@/components/EventManager'

    export default {
        components: {
            EventManager
        },
        data: () => ({
            openModal: false,
            user: null,
            earningLog: [],
            loading: true
        }),
        created() {
            EventManager.$on('onShowEarningLog', this.init)
        },
        methods: {
            ...mapActions('users', { getEarningLog: 'getEarningLog' }),
            init(data) {
                this.user = Object.assign({}, data)
                this.getEarningLog(this.user.id).then(({ earnings }) => {
                    this.earningLog = earnings
                    this.loading = false
                })
                this.openModal = true
            },
            close() {
                this.user = Object.assign({})
                this.gainsLog = []
                this.openModal = false
            },
            save() {
                this.updateUser(this.user).then(data => {
                    this.$emit('saved', data)
                })
            }
        }
    }
</script>
