<template>
    <ValidationProvider v-slot="{ errors }" tag="div" :name="name" :rules="rules">
        <div class="w-auto">
            <slot name="field" :fieldValue="value" :onInput="onInput" :fieldName="definitiveName" :fieldId="id" />
        </div>
        <div class="text-red-600 font-thin text-xs h-4">
            {{ errors[0] }}
        </div>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate'
    import FieldMixin from './mixins/FieldMixin.vue'

    export default {
        components: {
            ValidationProvider
        },
        mixins: [FieldMixin]
    }
</script>

<style></style>
