<template>
    <b-modal v-model="activeModal">
        <template>
            <header class="px-4 py-3 has-background-warning shadow-sm">
                <h4 class="h4 p-0 mb-0">Mots-clés</h4>
            </header>
            <b-table
                class="table-striped"
                :sticky-header="false"
                :data="data"
                :mobile-cards="true"
                :debounce-search="700"
            >
                <b-table-column field="keywords" label="Mots-clés">
                    <template v-slot="props">
                        {{ props.row.keyword }}
                    </template>
                </b-table-column>
            </b-table>
        </template>
    </b-modal>
</template>

<script>
    export default {
        name: 'KeywordsModal',
        data() {
            return {
                activeModal: false,
                data: null
            }
        },
        computed: {
            splitKeywords() {
                if (this.data) {
                    return this.data.split(',')
                } else {
                    return null
                }
            }
        },
        methods: {
            openModal(data) {
                this.data = data
                this.activeModal = true
            },
            clodeModal() {
                this.data = null
                this.activeModal = false
                this.$emit('refresh')
            }
        }
    }
</script>

<style></style>
