<template>
    <b-modal v-model="activeModal">
        <template>
            <header class="px-4 py-3 has-background-warning shadow-sm">
                <h4 class="h4 p-0 mb-0">Ajouter une note</h4>
            </header>
            <form action="post">
                <div class="modal-body">
                    <b-field label="Type">
                        <b-select v-model="form.type" expanded>
                            <option v-for="type in typeArray" :key="type" :value="type">
                                {{ type }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field label="Note">
                        <b-input v-model="form.note" type="textarea" />
                    </b-field>
                    <b-field label="Statut">
                        <b-select v-model="form.status" expanded>
                            <option value="urgent">Urgent</option>
                            <option value="en cours">En cours</option>
                            <option value="suivi">Suivi</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="modal-footer">
                    <b-button type="button" class="btn btn-danger" @click="clodeModal"> Annuler</b-button>
                    <b-button type="button" class="btn btn-primary" @click="send"> Sauvegarder</b-button>
                </div>
            </form>
        </template>
    </b-modal>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'NoteModal',
        props: {
            typeArray: {
                default: null
            },
            modalType: {
                default: 'site'
            }
        },
        data() {
            return {
                activeModal: false,
                form: {}
            }
        },
        methods: {
            ...mapActions('orders', ['addNote', 'addNoteSite', 'addNoteFree']),
            send() {
                if (this.modalType == 'site') {
                    this.addNoteSite(this.form).then(() => {
                        this.$emit('send')
                        this.clodeModal()
                    })
                } else if (this.modalType == 'free') {
                    this.addNoteFree(this.form).then(() => {
                        this.$emit('send')
                        this.clodeModal()
                    })
                } else {
                    this.addNote(this.form).then(() => {
                        this.$emit('send')
                        this.clodeModal()
                    })
                }
                this.$emit('refresh')
            },
            openModal(id = null) {
                id == null ? (this.form.id = this.$route.query.id) : (this.form.id = id)
                this.activeModal = true
            },
            clodeModal() {
                this.form = {}
                this.activeModal = false
            }
        }
    }
</script>
