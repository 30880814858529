<template>
    <div>
        <b-tooltip
            :label="String(0 === credits ? $t('tableau-keywords.credit') : $t('tableau-keywords.credittoorder'))"
            :active="enabled"
        >
            <b-button
                v-if="enabled"
                icon-left="exclamation-triangle"
                type="is-danger"
                :label="`${price}€`"
                @click="$router.push({ name: 'Paiement' })"
            />
            <b-button
                v-else
                icon-left="cart-plus"
                icon-pack="fas"
                type="is-warning"
                :label="`${price}€`"
                @click="show = true"
            />
        </b-tooltip>
        <b-modal v-model="show" :can-cancel="true" @close="close" @after-enter="getData" :width="1200">
            <template>
                <header class="modal-card-head has-background-warning">
                    <p class="modal-card-title m-0">
                        {{ $t('tableau-keywords.orderlink') }}
                    </p>
                    <button type="button" class="delete" @click="close" />
                </header>
                <section class="modal-card-body" style="height: 600px">
                    <b-loading v-model="isLoading" :is-full-page="false" />
                    <div v-if="contentModal" class="mess-orange">
                        <div class="alert" v-html="contentModal" />
                    </div>
                    <b-message
                        v-if="!isLoading && restrictionsMessages.length > 0 && activeStep === 0"
                        type="is-warning"
                    >
                        <h5 class="alert-heading">
                            {{ $t('achat-lien.restrictions') }}
                        </h5>
                        <ul>
                            <li v-for="(restriction, index) in restrictionsMessages" class="is-small" :key="index">
                                - {{ restriction }}
                            </li>
                        </ul>
                    </b-message>
                    <b-message v-if="!isLoading && currentUrl" type="is-info">
                        {{ currentUrl }}
                    </b-message>

                    <ValidationObserver ref="globalForm" slim>
                        <b-steps
                            v-model="activeStep"
                            size="is-small"
                            :animated="true"
                            :rounded="true"
                            :has-navigation="false"
                            label-position="bottom"
                            mobile-mode="minimalist"
                        >
                            <b-step-item step="1" label="Url / Ancre">
                                <ValidationObserver ref="formUrl" slim>
                                    <form>
                                        <b-field v-if="suggestedCampaigns.length > 0" label="Accompagnements">
                                            <b-select v-model="formData.campaignId" placeholder="---" expanded>
                                                <option value="">-----</option>
                                                <option
                                                    v-for="accompagnement in suggestedCampaigns"
                                                    :key="accompagnement.id"
                                                    :value="accompagnement.id"
                                                >
                                                    #{{ accompagnement.id }} {{ accompagnement.url }} ({{
                                                        accompagnement.debut_accompagnement
                                                            ? $d(
                                                                  Date.parse(accompagnement.debut_accompagnement),
                                                                  'short'
                                                              )
                                                            : ''
                                                    }}
                                                    ->
                                                    {{
                                                        accompagnement.fin_accompagnement
                                                            ? $d(Date.parse(accompagnement.fin_accompagnement), 'short')
                                                            : ''
                                                    }})
                                                </option>
                                            </b-select>
                                        </b-field>
                                        <div class="d-flex w-100 justify-content-between">
                                            <PurchaseModalLink
                                                :label="'URL du lien à placer'"
                                                v-model="formData.linkUrl"
                                                :suggestions="suggestions.linkSuggestions"
                                                name="linkUrl"
                                                class="w-50 pr-2"
                                            />
                                            <PurchaseModalAnchor
                                                v-model="formData.linkAnchor"
                                                :keywords="otherKeywords"
                                                :suggestions="suggestions.anchorSuggestions"
                                                name="linkAnchor"
                                                class="w-50 pl-2"
                                            />
                                        </div>
                                        <ValidationProvider
                                            v-if="alreadyBought"
                                            name="confirmAlreadyBought"
                                            :rules="{ required: { allowFalse: false } }"
                                            slim
                                        >
                                            <template #default="{ valid }">
                                                <b-field
                                                    :type="{ 'is-danger': !valid }"
                                                    :message="
                                                        !valid
                                                            ? 'Veuillez confirmez que vous acceptez vouloir placer une autre fois un lien vers ce domaine sur ce site.'
                                                            : null
                                                    "
                                                >
                                                    <b-checkbox v-model="confirmAlreadyBought" required>
                                                        {{ $t('partenaire-site.confirm') }}
                                                        <sup class="text-danger">*</sup>
                                                    </b-checkbox>
                                                </b-field>
                                            </template>
                                        </ValidationProvider>
                                        <hr />
                                        <ValidationProvider name="allowSecondAnchor" slim>
                                            <b-field>
                                                <b-switch
                                                    v-model="formData.allowSecondAnchor"
                                                    type="is-success"
                                                    :true-value="true"
                                                    :false-value="false"
                                                    :disabled="isPriceDisabled"
                                                >
                                                    {{ $t('tableau-keywords.addlink') }}
                                                    (+<span>{{ priceSupp }}</span
                                                    >€ HT)
                                                </b-switch>
                                            </b-field>
                                        </ValidationProvider>
                                        <template v-if="formData.allowSecondAnchor">
                                            <div class="d-flex justify-content-between">
                                                <PurchaseModalLink
                                                    :label="'URL du lien secondaire à placer'"
                                                    v-model="formData.linkUrl2"
                                                    :suggestions="suggestions.linkSuggestions2"
                                                    name="linkUrl2"
                                                    class="w-50 pr-2"
                                                />
                                                <PurchaseModalAnchor
                                                    v-model="formData.linkAnchor2"
                                                    :keywords="otherKeywords"
                                                    :suggestions="suggestions.anchorSuggestions"
                                                    name="linkAnchor2"
                                                    class="w-50 pl-2"
                                                />
                                            </div>
                                        </template>
                                    </form>
                                </ValidationObserver>
                            </b-step-item>
                            <b-step-item step="2" label="Options">
                                <ValidationProvider name="brief" rules="required" slim>
                                    <b-field label="Brief">
                                        <b-input v-model="formData.brief" type="textarea" style="height: 100px" />
                                    </b-field>
                                </ValidationProvider>
                                <b-message v-if="informationModal" type="is-info">
                                    <label
                                        ><b>{{ $t('global.importantinformations') }}</b></label
                                    ><br />
                                    <span>{{ informationModal }}</span>
                                </b-message>
                                <div class="d-flex justify-content-between">
                                    <div class="form-group w-50">
                                        <b-field label="Ajout de mots au texte existant">
                                            <b-select v-model="formData.nbWords" @input="changeNbWord" expanded>
                                                <option :value="0">
                                                    200 {{ $t('global.words') }} : {{ $t('global.included') }}
                                                </option>
                                                <option :value="100" :disabled="site.contenu_redige_max < 300">
                                                    300 {{ $t('global.words') }} (+9€
                                                    {{ $t('tableau-keywords.withoutVAT') }})
                                                </option>
                                                <option :value="200" :disabled="site.contenu_redige_max < 400">
                                                    400 {{ $t('global.words') }} (+17€
                                                    {{ $t('tableau-keywords.withoutVAT') }})
                                                </option>
                                                <option :value="300" :disabled="site.contenu_redige_max < 500">
                                                    500 {{ $t('global.words') }} (+25€
                                                    {{ $t('tableau-keywords.withoutVAT') }})
                                                </option>
                                                <option :value="400" :disabled="site.contenu_redige_max < 600">
                                                    600 {{ $t('global.words') }} (+34€
                                                    {{ $t('tableau-keywords.withoutVAT') }})
                                                </option>
                                                <option :value="500" :disabled="site.contenu_redige_max < 700">
                                                    700 {{ $t('global.words') }} (+40€
                                                    {{ $t('tableau-keywords.withoutVAT') }})
                                                </option>
                                            </b-select>
                                        </b-field>
                                    </div>
                                    <div class="vr"></div>
                                    <div class="w-50">
                                        <div class="mb-2">
                                            <b-field label="Date de publication">
                                                <b-radio
                                                    v-model="formData.allowPublication"
                                                    name="name"
                                                    type="is-warning"
                                                    class="mt-3"
                                                    native-value="immediat"
                                                >
                                                    Dès que possible
                                                </b-radio>
                                                <b-radio
                                                    v-model="formData.allowPublication"
                                                    name="name"
                                                    type="is-warning"
                                                    class="mt-3 ml-3"
                                                    native-value="planifie"
                                                    >Planifiée
                                                </b-radio>
                                            </b-field>
                                            <b-datepicker
                                                v-model="formData.publicationDate"
                                                :min-date="getDatePlanifiedPublication"
                                                nearby-selectable-month-days
                                                expanded
                                                position="is-top-left"
                                                icon="calendar"
                                                :locale="'fr'"
                                                v-if="formData.allowPublication === 'planifie'"
                                                style="width: fit-content"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr v-if="security.isAdmin" />
                                <div v-if="security.isAdmin">
                                    <b-switch
                                        v-model="formData.isPrio"
                                        type="is-success"
                                    >
                                        Commande prioritaire
                                    </b-switch>
                                </div>
                            </b-step-item>
                        </b-steps>
                    </ValidationObserver>
                </section>
                <footer class="modal-card-foot h-auto justify-content-end">
                    <slot name="footer">
                        <template>
                            <b-button v-if="activeStep === 0" label="Annuler" type="is-danger" @click="close" />
                            <b-button v-else label="Précédent" @click="previousStep" />
                        </template>
                        <template>
                            <b-button v-if="activeStep === totalSteps" type="is-success" @click="handleSubmit">
                                {{ $t('tableau-keywords.buy', { price: totalPrice }) }}
                            </b-button>
                            <b-button v-else @click="nextStep">
                                {{ $t('tableau-keywords.nextStep', { price: totalPrice }) }}
                            </b-button>
                        </template>
                    </slot>
                </footer>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import axios from 'axios'
    import { mapActions, mapMutations } from 'vuex'
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import PurchaseModalLink from '@/components/Search/PurchaseModal/PurchaseModalLink.vue'
    import PurchaseModalAnchor from '@/components/Search/PurchaseModal/PurchaseModalAnchor.vue'

    export default {
        name: 'PurchaseModal',
        components: {
            PurchaseModalAnchor,
            PurchaseModalLink,
            ValidationProvider,
            ValidationObserver
        },
        props: {
            contentModal: {
                type: String,
                required: true
            },
            credits: {
                type: Number,
                required: true
            },
            enabled: {
                type: Boolean,
                default: false
            },
            informationModal: {
                type: String,
                required: true
            },
            keyword: {
                type: String,
                required: true
            },
            price: {
                type: Number,
                default: null
            },
            priceSupp: {
                type: Number,
                default: null
            },
            security: {
                type: Object,
                required: true
            },
            siteId: {
                type: Number,
                default: null
            },
            statId: {
                type: Number,
                default: null
            },
            url: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                show: false,
                isLoading: true,
                loaded: false,
                activeStep: 0,
                totalSteps: 1,
                suggestedCampaigns: [],
                restrictionsMessages: [],
                restrictions: {
                    secondLinkAuthorized: true,
                    scheduledIsAuthorized: true,
                    maxWords: 0
                },
                suggestions: {
                    linkSuggestions: [],
                    linkSuggestions2: [],
                    anchorSuggestions: []
                },
                otherKeywords: [],
                site: {},
                formData: {
                    campaignId: null,
                    linkUrl: '',
                    linkAnchor: '',
                    linkAnchorFetching: false,
                    allowSecondAnchor: false,
                    linkUrl2: '',
                    linkAnchor2: '',
                    linkAnchorFetching2: false,
                    brief: '',
                    nbWords: 0,
                    nbWordsPrice: 0,
                    allowPublication: 'immediat',
                    publicationDate: new Date(),
                    isPrio: false
                },

                confirmAlreadyBought: false,
                currentUrl: null
            }
        },
        computed: {
            totalPrice() {
                let total = this.price + this.formData.nbWordsPrice
                if (this.formData.allowSecondAnchor) {
                    total += this.priceSupp
                }

                return Number(total)
            },
            isPriceDisabled: function () {
                return !this.restrictions.secondLinkAuthorized
            },
            alreadyBought() {
                try {
                    const currentUrl = new URL(this.formData.linkUrl).hostname

                    return this.site.contents.some(({ link_url }) => link_url.includes(currentUrl))
                } catch {
                    return false
                }
            },
            getDatePlanifiedPublication() {
                let date = new Date()
                date.setDate(date.getDate() + 9)
                return date
            }
        },
        watch: {
            'formData.campaignId': function (value) {
                if (value) {
                    this.formData.linkUrl = ''
                    this.formData.secondLink = ''
                    this.loadLinksSuggestion()
                }
            },
            show: function(value) {
                if(value === false) {
                    return;
                }


            }
        },
        methods: {
            ...mapActions('urls', ['getBuyModalInfo', 'buyUrl']),
            ...mapActions('project_selector', {
                getProjects: 'projects'
            }),
            ...mapMutations('auth', {
                setBenefits: 'setBenefits',
                setCredits: 'setCredits'
            }),
            getData() {
                if (!this.loaded) {
                    const informations = this.getInformation()
                    const campaigns = this.getCampaigns()

                    Promise.all([informations, campaigns])
                        .then(() => {
                            this.isLoading = false
                            this.loaded = true
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }
            },
            getInformation() {
                return this.getBuyModalInfo({
                    url_id: this.url.id,
                    params: {
                        projectId: this.$store.state.project.id
                    }
                })
                    .then(data => {
                        this.site = data.site
                        this.currentUrl = data.url

                        this.setRestrictions()

                        this.formData.brief = data?.info?.brief
                        this.formData.linkUrl = data?.info?.link_url
                        this.formData.linkAnchor = data?.info?.link_anchor

                        this.otherKeywords = data.keywords
                    })
                    .catch(error => {
                        this.$buefy.toast.open({
                            message: this.$t('buylink.unavailablelink'),
                            type: 'is-danger'
                        })
                        this.$emit('error')
                        this.close()
                        console.error(error)
                    })
            },
            getCampaigns() {
                return axios
                    .get('/campaigns/project/' + this.$store.state.project.id, {
                        status: 'for_buy_modal',
                        'filter[project_id]': this.$store.state.project.id
                    })
                    .then(({ data }) => {
                        this.suggestedCampaigns = data.accompagnements
                        if (this.suggestedCampaigns.length === 1) {
                            this.accompagnement = this.suggestedCampaigns[0].id
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            loadLinksSuggestion: function () {
                this.isLoading = true
                if (!this.formData.campaignId) {
                    return []
                }

                axios
                    .get(`/campaigns/url/${this.formData.campaignId}/suggestions`)
                    .then(({ data }) => {
                        const linkSuggestions = data.suggestions
                            .filter(link => link.to_work_on)
                            .map(link => {
                                return link.url
                            })
                        const anchorSuggestions = data.suggestions
                            .filter(link => link.to_work_on)
                            .map(link => {
                                return link.mot_cle
                            })
                        const secondLinkSuggestions = data.contents.map(link => {
                            return link.url
                        })

                        this.suggestions.linkSuggestions = [...new Set(linkSuggestions)]
                        this.suggestions.anchorSuggestions = [...new Set(anchorSuggestions)]
                        this.suggestions.linkSuggestions2 = [...new Set(secondLinkSuggestions)]

                        if (this.suggestions.linkSuggestions.length === 1) {
                            this.formData.linkUrl = this.suggestions.linkSuggestions[0]
                        }
                        if (this.suggestions.anchorSuggestions.length === 1) {
                            this.formData.linkAnchor = this.suggestions.anchorSuggestions[0]
                        }
                        if (this.suggestions.linkSuggestions2.length === 1) {
                            this.formData.secondLink = this.suggestions.linkSuggestions2[0]
                        }
                    })
                    .finally(() => (this.isLoading = false))
            },
            getAnchorTypeTranslation(anchor) {
                switch (anchor) {
                    case 'toutes':
                        return this.$t('partenaire-site.alltheanchors')
                    case 'url':
                        return this.$t('partenaire-site.url-choice')
                    case 'generique':
                        return this.$t('partenaire-site.generics')
                    case 'url_ou_generique':
                        return this.$t('partenaire-site.urlsorgeneric')
                    case 'url_ou_generique_ou_non_opti':
                        return this.$t('partenaire-site.urlsGenericKeywords')
                    case 'keywords':
                        return this.$t('partenaire-site.keywords-choice')
                    case 'toutes_no_url':
                        return this.$t('partenaire-site.all-no-url')
                    case 'url_ou_generique_ou_keywords':
                        return this.$t('partenaire-site.urlsGenericKeywords')

                    default:
                        return 'Error'
                }
            },
            setRestrictions() {
                if (this.site.lien_secondaire_autorise !== 1) {
                    this.restrictionsMessages.push(this.$t('achat-lien.onelink'))
                }
                if (this.site.contenu_redige_max !== 700) {
                    this.restrictionsMessages.push(this.site.contenu_redige_max + ' ' + this.$t('achat-lien.max'))
                }
                if (this.site.planification_autorisee === 0) {
                    this.restrictionsMessages.push(this.$t('achat-lien.plannedpublication'))
                }
                if (this.site.refus_concurrent_mfa === 'oui') {
                    this.restrictionsMessages.push(this.$t('achat-lien.noMFA'))
                }
                if (this.site.ancres_acceptees !== 'toutes') {
                    this.restrictionsMessages.push(
                        `${this.$t('projets.site-anchors-type')} : ${this.getAnchorTypeTranslation(
                            this.site.ancres_acceptees
                        )}`
                    )
                }
                if (this.site.blacklisted_groups.length) {
                    this.restrictionsMessages.push(
                        `${this.$t('projets.site-groups-blacklisted')} : ${this.site.blacklisted_groups
                            .map(group =>
                                group.localized_translations ? group.localized_translations.name : 'undefined'
                            )
                            .join(', ')}`
                    )
                }

                this.restrictions.secondLinkAuthorized = 1 === this.site.lien_secondaire_autorise
                this.restrictions.scheduledIsAuthorized = 1 === this.site.planification_autorisee
                this.restrictions.maxKeywords = this.site.contenu_redige_max
            },
            useOtherAnchor(secondary = false) {
                if (!secondary === false) {
                    this.formData.linkAnchor = this.otherKeywords[Math.floor(Math.random() * this.otherKeywords.length)]
                } else {
                    this.formData.linkAnchor2 =
                        this.otherKeywords[Math.floor(Math.random() * this.otherKeywords.length)]
                }
            },
            changeNbWord() {
                switch (this.formData.nbWords) {
                    case 0:
                        this.formData.nbWordsPrice = 0
                        break
                    case 100:
                        this.formData.nbWordsPrice = 9
                        break
                    case 200:
                        this.formData.nbWordsPrice = 17
                        break
                    case 300:
                        this.formData.nbWordsPrice = 25
                        break
                    case 400:
                        this.formData.nbWordsPrice = 34
                        break
                    case 500:
                        this.formData.nbWordsPrice = 40
                        break
                }
            },
            async handleSubmit() {
                const isValid = await this.$refs.globalForm.validate()

                if (isValid) {
                    this.isLoading = true

                    this.buyUrl({
                        url_id: this.url.id,
                        params: {
                            brief: this.formData.brief,
                            bought_keyword: this.keyword,
                            link_url: this.formData.linkUrl,
                            link_anchor: this.formData.linkAnchor,
                            link_second_price: this.formData.allowSecondAnchor,
                            link_second_url: this.formData.linkUrl2,
                            link_second_anchor: this.formData.linkAnchor2,
                            link_price: this.price,
                            link_id: this.url.id,
                            project_id: this.$store.state.project.id,
                            add_word: this.formData.nbWords,
                            radio_date_publication_prevue: this.formData.allowPublication,
                            date_publication_prevue: this.formData.publicationDate,
                            accompagnement: this.formData.campaignId,
                            isPrio: this.formData.isPrio
                        }
                    })
                        .then(data => {
                            this.setBenefits(data.benefits)
                            this.setCredits(data.credits)

                            let message = ''
                            if (data.messages && Array.isArray(data.messages)) {
                                data.messages.forEach(msg => {
                                    message += ' ' + this.$t(msg)
                                })
                            }

                            // Confirm Message
                            this.$buefy.toast.open({
                                message: message + ' ' + data.price + ' €',
                                type: 'is-success'
                            })

                            this.getProjects()
                            this.$emit('bought')
                        })
                        .catch(error => {
                            console.error(error)
                            this.handleError()
                        })
                        .finally(() => {
                            this.close()
                        })
                }
            },
            nextStep() {
                this.$refs.formUrl?.validate().then(valid => {
                    this.activeStep += this.activeStep === this.totalSteps || !valid ? 0 : 1
                })
            },
            previousStep() {
                this.activeStep -= this.activeStep === 0 ? 0 : 1
            },

            handleError(message = this.$t('error.message.default')) {
                this.$buefy.toast.open({
                    message,
                    type: 'is-danger'
                })
                this.$emit('error')
            },
            close() {
                this.isLoading = false
                this.show = false
            }
        }
    }
</script>

<style>
    .vr {
        border: 1px solid red;
        height: max-content;
        margin: 0 20px;
    }
</style>