<template>
    <b-tooltip :label="label" :position="position">
        <a href="#" :class="classes" @click.prevent="$emit('click')">
            <b-icon :icon="icon" />
            <slot />
        </a>
    </b-tooltip>
</template>

<script>
    import ButtonMixin from './ButtonMixin'

    export default {
        name: 'ViewButton',
        mixins: [ButtonMixin]
    }
</script>
