<template>
    <nl-modal ref="assignModal" title="Assigner un rédacteur" size="xl" class="assign-modal">
        <div class="container-fluid container assign-redactor">
            <h5>Choisir un rédacteur dans la liste</h5>

            <b-table :data="admins" :loading="loading" :mobile-cards="true" :striped="true">
                <b-table-column cell-class="align-middle" field="id" label="Id">
                    <div slot="default" slot-scope="props">
                        {{ props.row.id }}
                    </div>
                </b-table-column>

                <b-table-column cell-class="align-middle" field="firstname" label="Prénom">
                    <div slot="default" slot-scope="props">
                        {{ props.row.firstname }}
                    </div>
                </b-table-column>

                <b-table-column cell-class="align-middle" field="lastname" label="Nom">
                    <div slot="default" slot-scope="props">
                        {{ props.row.lastname }}
                    </div>
                </b-table-column>

                <b-table-column cell-class="align-middle" field="email" label="Email">
                    <div slot="default" slot-scope="props">
                        {{ props.row.email }}
                    </div>
                </b-table-column>

                <b-table-column v-slot="props" cell-class="align-middle" centered label="Action">
                    <button
                        class="btn btn-secondary"
                        :disabled="unselectableUsers.includes(props.row.id)"
                        @click="doAssign(props.row)"
                    >
                        Selectionner
                    </button>
                </b-table-column>
            </b-table>
        </div>
    </nl-modal>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'AssignAdminModal',
        props: {
            unselectableUsers: {
                required: false,
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                assignModal: false,
                redactor: null,
                loading: false
            }
        },

        computed: {
            ...mapState('config', {
                admins: state => state.admins
            })
        },

        methods: {
            show() {
                this.$refs.assignModal.show()
            },
            hide() {
                this.$refs.assignModal.hide()
                this.redactor = null
            },
            doAssign(admin) {
                this.$emit('admin:selected', admin)
            }
        }
    }
</script>

<style scoped></style>
