<template>
    <div class="nav-item" v-if="role === 'admin'">
        <router-link v-if="!isSidebar" to="/admin/keywords" class="balance__item">
            <div v-if="!loading" class="balance__subitem">
                <span class="text-nowrap">{{ $t('partenaire-site.keywords') }}</span>
                <span>{{ keywordsCount | toThousands }}</span>
            </div>
            <b-skeleton v-else animated width="150" height="30" />
        </router-link>
    </div>
</template>

<style scoped>
    .balance__item {
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        text-decoration: none;
    }

    .balance__subitem {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .balance__subitem > span {
        margin: 0;
        padding: 0;
        width: min-content;
        height: min-content;
        text-align: end;
        color: #2b383f;
    }

    .balance__subitem > span:first-child {
        font-size: 0.7rem;
        margin-bottom: -0.4rem;
    }

    .balance__subitem > span:last-child {
        font-size: 1.1rem;
        font-weight: bold;
    }
</style>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'KeywordMenu',
        data() {
            return {
                keywordsCount: 0,
                loading: true
            }
        },
        props: {
            isSidebar: {
                type: Boolean,
                required: false
            }
        },
        async created() {
            this.keywordsCount = await this.$store.dispatch('keyword/getKeywordsCount')
            this.loading = false
        },
        computed: {
            ...mapState('auth', {
                role: state => state.user?.role
            })
        }
    }
</script>
