<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">
                        {{ $t('global.verification') }}
                    </h4>
                </header>
                <div class="card-content">
                    <div v-if="order" class="content d-flex flex-column">
                        <b-loading v-model="isLoading" :is-full-page="false" />

                        <p v-if="isLoading" class="text-center mx-auto my-4">{{ $t('status.running') }}...</p>

                        <div v-else class="mx-auto my-4">
                            <p>
                                <i
                                    class="fas mr-1"
                                    :class="responseCode.isValid ? 'fa-check text-success' : 'fa-times text-danger'"
                                />
                                <strong>{{ $t('partenaire-commandes.response-code') }}</strong>
                            </p>
                            <p>
                                <i
                                    class="fas mr-1"
                                    :class="externalLinks.isValid ? 'fa-check text-success' : 'fa-times text-danger'"
                                />
                                <strong>{{ $t('partenaire-commandes.external-links') }}</strong>
                            </p>
                            <p>
                                <i
                                    class="fas mr-1"
                                    :class="firstLink.isValid ? 'fa-check text-success' : 'fa-times text-danger'"
                                />
                                <strong>{{ $t('partenaire-commandes.first-link') }}</strong>
                            </p>
                            <p v-if="order.link_second_url">
                                <i
                                    class="fas mr-1"
                                    :class="secondLink.isValid ? 'fa-check text-success' : 'fa-times text-danger'"
                                />
                                <strong>{{ $t('partenaire-commandes.second-link') }}</strong>
                            </p>
                        </div>

                        <div class="buttons justify-content-end">
                            <button class="button is-light" @click.prevent="closeAndResetModal">
                                {{ $t('global.close') }}
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'PartnerOrdersCheckModal',
        data() {
            return {
                modalIsOpen: false,
                order: null,
                isLoading: false,

                responseCode: {
                    isValid: false
                },
                externalLinks: {
                    isValid: false
                },
                firstLink: {
                    isValid: false,
                    text: ''
                },
                secondLink: {
                    isValid: false,
                    text: ''
                }
            }
        },

        methods: {
            resultCheck(result) {
                this.responseCode.isValid = result.responseCode !== 200 ? false : true
                this.externalLinks.isValid = result.externalLinks ? false : true
                this.firstLink.isValid = result.firstLink ? false : true
                this.firstLink.text = result.firstLink ? result.firstLink : null

                this.order.verification_autres_liens_externes = result.externalLinks
                this.order.verification_code_reponse_http = result.responseCode
                this.order.link_status_message = result.firstLink

                if (this.order.link_second_url) {
                    this.secondLink.isValid = result.secondLink ? false : true
                    this.secondLink.text = result.secondLink ? result.secondLink : ''

                    this.order.link_second_status_message = result.secondLink
                }

                this.isLoading = false
            },

            openModal(order) {
                this.modalIsOpen = true
                this.order = order
                this.isLoading = true

                this.$parent.$emit('relaunchCheck', {
                    data: order,
                    callback: this.resultCheck
                })
            },

            closeAndResetModal() {
                this.modalIsOpen = false
                this.order = null
                this.isLoading = false

                this.responseCode = {
                    isValid: false
                }
                this.externalLinks = {
                    isValid: false
                }
                this.firstLink = {
                    isValid: false,
                    text: ''
                }
                this.secondLink = {
                    isValid: false,
                    text: ''
                }
            }
        }
    }
</script>

<style>
    label[for^='scrape_'] {
        color: #363636;
        display: block;
        font-size: 1rem;
        font-weight: 700;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
