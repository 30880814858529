<template>
    <b-field label="Projet" class="w-100">
        <b-autocomplete
            v-model="projectName"
            expanded
            field="name"
            placeholder="Projet"
            :data="filteredProjects"
            :open-on-focus="true"
            :loading="isLoading"
            :disabled="disabled"
            @click.native="projectName = ''"
            @focus="projectName = ''"
            @select="changeProject"
        >
            <template slot-scope="props">
                {{ props.option.name }}
            </template>
        </b-autocomplete>
    </b-field>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'ProjectSelector',
        props: {
            userId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                projectName: '',
                selectedProject: null,
                isLoading: false
            }
        },
        computed: {
            ...mapState('projects', {
                projects: state => state.projects
            }),
            disabled() {
                return !this.userId
            },
            filteredProjects() {
                return this.projects
                    .filter(project => {
                        return project.name.toString().toLowerCase().indexOf(this.projectName.toLowerCase()) >= 0
                    })
                    .sort((a, b) => a.name.localeCompare(b.name))
            }
        },
        methods: {
            ...mapActions('projects', {
                getProjects: 'getByUser'
            }),
            changeProject(project) {
                this.$emit('changeProject', project ? project.id : null)
            }
        },
        created() {
            if (this.projects.length === 0) {
                this.getProjects(this.userId).then(() => {
                    this.isLoading = false
                })
            }
        }
    }
</script>

<style scoped>
    /deep/ .dropdown-menu,
    .dropdown-content {
        padding: 0 !important;
        border: none;
    }
</style>
