<template>
    <div class="d-flex" style="gap: 0.5rem">
        <b-select v-model="selected" :placeholder="$t('global.actions')">
            <option v-for="action in actions" :key="action.label" :value="action">
                {{ action.label.substring(0, 50) }}
            </option>
        </b-select>
        <b-button
            type="is-warning"
            :disabled="(!selected || disabled) && !selected?.allowEmpty"
            :label="$t('global.validate')"
            @click="trigger"
        />
    </div>
</template>

<script>
    export default {
        name: 'TableActions',
        props: {
            actions: {
                type: Array,
                required: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                selected: null
            }
        },
        methods: {
            trigger() {
                if (this.selected) this.selected.action()
            }
        }
    }
</script>
