<template>
    <nl-modal :title="$t('suivi.urldate')" :open-modal="openModal" @hidden="openModal = false">
        <template v-if="!loading && order !== null && orderData !== null">
            <div>
                <h5 class='tag is-medium is-warning'>URL</h5>
                <p>
                    <strong>Page : </strong
                    ><a target="_blank" :href="order.url">{{ order.url }}</a>
                </p>
                <p>
                    <strong>{{ $t('partenaire-mot-cles.keyword') }} : </strong>
                    {{ order.bought_kw }}
                </p>
            </div>
            <hr />
            <div>
                <h5 class='tag is-medium is-warning'>Site</h5>
                <p>
                    <strong>{{ $t('global.category') }} : </strong>
                    {{ orderData.site_type }}
                </p>
                <p>
                    <strong>{{ $t('global.theme') }} : </strong>
                    {{ orderData.group_name }}
                </p>
                <hr />
            </div>
            <div v-if='keywordsList.length > 0'>
                <h5 class='tag is-medium is-warning'>{{ $t('suivi.keywordspositionned') + ' (' + (keywordsList.length) + ')' }}</h5>
                <b-table
                    :data="keywordsList"
                    :striped="true"
                    :paginated="true"
                    pagination-rounded
                    :per-page="5"
                >
                    <b-table-column field="keyword" :label="$t('global.keyword')" sortable>
                        <div
                            slot="default"
                            slot-scope="props"
                            :class="[props.row.keyword == order.bought_kw ? 'font-weight-bold' : '']"
                        >
                            {{ props.row.keyword }}
                        </div>
                    </b-table-column>
                    <b-table-column centered field="volume" :label="$t('partenaire-mot-cles.volume')" sortable>
                        <div
                            slot="default"
                            slot-scope="props"
                            :class="[props.row.keyword == order.bought_kw ? 'font-weight-bold' : '']"
                        >
                            {{ props.row.volume ?? '-' }}
                        </div>
                    </b-table-column>
                    <b-table-column centered field="position" :label="$t('partenaire-mot-cles.position')" sortable>
                        <div
                            slot="default"
                            slot-scope="props"
                            :class="[props.row.keyword == order.bought_kw ? 'font-weight-bold' : '']"
                        >
                            {{ props.row.position ?? '-' }}
                        </div>
                    </b-table-column>
                </b-table>
            </div>
            <b-message v-else type="is-warning">
                {{ $t('partenaire-mot-cles.no-more-keywords') }}
            </b-message>
        </template>
        <template v-else>
            <div>
                <p>Chargement...</p>
            </div>
        </template>
    </nl-modal>
</template>

<script>
    export default {
        name: 'OrderDetailModal',
        props: {
            fromSites: {
                type: Boolean,
                default: false,
                required: false
            }
        },
        data() {
            return {
                openModal: false,
                order: null,
                orderData: {},
                loading: true
            }
        },
        computed:{
          keywordsList(){
              if(this.orderData.keywords_list.length === 0){
                  return this.order.keywords.split(',').map(keyword => {
                      if(keyword !== ""){
                          return {
                              keyword: keyword,
                              volume: null,
                              position: null
                          }
                      }
                  })
              } else {
                    return this.orderData.keywords_list
              }
            }
        },
        methods: {
            showModal(data) {
                this.openModal = true
                this.order = data
                this.getDataUrl(data.id)
            },
            close() {
                this.openModal = false
                this.order = null
            },
            getDataUrl(orderId) {
                this.axios
                    .get(`/tracking-order/${orderId}/data`)
                    .then(({ data }) => {
                        this.loading = false
                        this.orderData = data.data
                        if (this.fromSites) {
                            this.computeData()
                        }
                    })
                    .catch(error => {
                        this.snackNotif('Error', 'is-danger')
                        console.error(error)
                    })
            },
            computeData() {
                this.loading = false
            }
        }
    }
</script>
