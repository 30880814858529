<template>
    <b-table
        :data="sites.sites.data"
        :mobile-cards="true"
        :paginated="true"
        :debounce-search="700"
        :current-page.sync="config.page"
        :loading="isLoading"
        :default-sort="[config.sort, config.sortDirection]"
        :total="sites.sites.total"
        :per-page="config.per_page"
        :striped="true"
        backend-sorting
        backend-pagination
        backend-filtering
        @page-change="onPageChange"
        @sort="onSort"
        @filters-change="onFilterChange"
    >
        <b-table-column field="id" label="ID" sortable>
            <template v-slot="props">
                #{{ props.row.id }}
                <span class="tag is-success" v-if="isNew(props.row.created_at)"> New </span>
            </template>
        </b-table-column>
        <b-table-column field="ndd" label="NDD" searchable sortable>
            <template v-slot="props">
                {{ props.row.ndd }}
            </template>
            <div slot="searchable" slot-scope="props" class="searchableCol">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="ndd"
                    v-model="props.filters[props.column.field]"
                />
            </div>
        </b-table-column>
        <b-table-column field="group_name" label="Thème" searchable>
            <div slot-scope="props" slot="default">
                {{ findGroupName(props.row.group_id) }}
            </div>
            <div slot="searchable" slot-scope="props">
                <select class="form-control form-control-sm" v-model="props.filters['group_id']">
                    <option :value="props.filters[props.column.field]">Tous</option>
                    <option :value="g.value" v-for="g in groups">{{ g.text }}</option>
                </select>
            </div>
        </b-table-column>
        <b-table-column field="partenaire_id" label="Propriétaire" cell-class="align-middle" searchable centered>
            <div slot-scope="props" slot="default" class="text-center">
                <span class="tag is-success p-2 text-center" v-if="props.row.partenaire_id !== null"> Partenaire </span>
                <span class="tag is-warning text-dark p-2 text-center" v-else> NextLevel </span>
            </div>
            <div slot="searchable" slot-scope="props">
                <select class="form-control form-control-sm" v-model="props.filters['is_partner']">
                    <option :value="props.filters[props.column.field]">Tous</option>
                    <option value="0">NL</option>
                    <option value="1">Partenaire</option>
                </select>
            </div>
        </b-table-column>
        <b-table-column field="nb_mots_cles_seobserver" label="MC SEOObserver" searchable sortable centered>
            <div slot-scope="props" slot="default">
                {{ props.row.nb_mots_cles_seobserver }}
            </div>
            <div slot="searchable" slot-scope="props" class="searchableCol" style="display: flex">
                <input
                    type="number"
                    step="0.01"
                    class="form-control form-control-sm w-50"
                    placeholder="Min."
                    style="width: 50% !important; margin-right: 5px"
                    v-model="props.filters['min_nb_mots_cles_seobserver']"
                />
                <input
                    type="number"
                    step="0.01"
                    class="form-control form-control-sm w-50"
                    placeholder="Max."
                    style="width: 50% !important"
                    v-model="props.filters['max_nb_mots_cles_seobserver']"
                />
            </div>
        </b-table-column>
        <b-table-column field="keywords_count" label="MC NL" searchable sortable centered>
            <div slot-scope="props" slot="default">
                {{ props.row.keywords_count }}
            </div>
            <div slot="searchable" slot-scope="props" class="searchableCol" style="display: flex">
                <input
                    type="number"
                    step="0.01"
                    class="form-control form-control-sm w-50"
                    placeholder="Min."
                    style="width: 50% !important; margin-right: 5px"
                    v-model="props.filters['min_keywords_count']"
                />
                <input
                    type="number"
                    step="0.01"
                    class="form-control form-control-sm w-50"
                    placeholder="Max."
                    style="width: 50% !important"
                    v-model="props.filters['max_keywords_count']"
                />
            </div>
        </b-table-column>
        <b-table-column field="orders_count" label="NB de commandes" searchable sortable>
            <div slot-scope="props" slot="default">
                {{ props.row.orders_count }}
            </div>
            <div slot="searchable" slot-scope="props" class="searchableCol" style="display: flex">
                <input
                    type="number"
                    step="0.01"
                    class="form-control form-control-sm w-50"
                    placeholder="Min."
                    style="width: 50% !important; margin-right: 5px"
                    v-model="props.filters['min_orders_count']"
                />
                <input
                    type="number"
                    step="0.01"
                    class="form-control form-control-sm w-50"
                    placeholder="Max."
                    style="width: 50% !important"
                    v-model="props.filters['max_orders_count']"
                />
            </div>
        </b-table-column>
        <b-table-column field="wp_data" label="Plugin" searchable>
            <div slot-scope="props" slot="default">
                <span v-if="props.row.wp_data" class="badge badge-success">
                    WP {{ getWordpressVersion(props.row.wp_data) }} | NL
                    {{ getWordpressNlVersion(props.row.wp_data) }}
                </span>
                <span v-else>-</span>
            </div>
            <div slot="searchable" slot-scope="props">
                <select class="form-control form-control-sm" v-model="props.filters['wp_plugin']">
                    <option :value="props.filters[props.column.field]">Tous</option>
                    <option value="0">Sans plugin</option>
                    <option value="1">Avec plugin</option>
                </select>
            </div>
        </b-table-column>
    </b-table>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'AvailableSitesTable',
        props: ['sites', 'projectId', 'groups', 'isLoading'],
        computed: {
            ...mapState('sites', {
                config: state => state.domainPostingConfig
            })
        },
        methods: {
            ...mapActions('sites', ['getDomainPosting']),
            loadAsyncData() {
                this.$emit('loadAsyncData')
            },
            onSort(field, order) {
                this.config.sort = field
                this.config.sortDirection = order

                this.loadAsyncData()
            },
            onFilterChange(filter) {
                var filters = {}
                Object.keys(filter).forEach(element => {
                    filters[element] = filter[element]
                })
                this.config.filters = filters

                this.loadAsyncData()
            },
            onPageChange(page) {
                this.config.page = page
                this.loadAsyncData()
            },
            findGroupName(group_id) {
                var group = this.groups.find(group => group.value == group_id)
                return group ? group.text : '-'
            },
            isNew(created_at) {
                var date = new Date(created_at)
                var now = new Date()
                var diff = now.getTime() - date.getTime()
                var days = Math.floor(diff / (1000 * 60 * 60 * 24))
                return days < 30
            },
            getWordpressVersion(meta) {
                const metaObject = JSON.parse(meta)
                return metaObject.wp_version ?? ''
            },

            getWordpressNlVersion(meta) {
                const metaObject = JSON.parse(meta)
                return metaObject.plugin_version ?? ''
            }
        }
    }
</script>
