<template>
    <b-modal
        :active="display"
        :can-cancel="true"
        aria-role="alertdialog"
        @close="display = false"
    >
        <template>
            <div class="card-content text-dark">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Export des crédits
                    </h5>
                </div>
                <div class="is-flex is-flex-direction-column modal-body" style="min-height: 500px">
                    <div class="w-100 is-flex is-flex-direction-row is-justify-content-space-around flex-grow-1">
                        État des crédits au :
                        <b-field label="Date fin">
                            <b-datepicker
                                placeholder="Click to select..."
                                v-model="endDate"
                            >
                            </b-datepicker>
                        </b-field>
                    </div>

                    <div class="w-100 d-flex justify-content-center" @click.prevent="doDownload">
                        <button class="btn btn-secondary">
                            <i v-show="loading" class="fas fa-circle-notch fa-spin mr-1"/>
                            Télécharger
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<script>
import {mapActions} from 'vuex'
import Download from '@/utils/download'

export default {
    name: 'OrdersExport',
    components: {},
    data: function () {
        return {
            loading: false,
            display: false,
        }
    },
    computed: {
        startDate: {
            get() {
                return this.$store.state.billing.startDate
            },
            set(value) {
                this.$store.commit('billing/setStartDate', value)
            }
        },
        endDate: {
            get() {
                return this.$store.state.billing.endDate
            },
            set(value) {
                this.$store.commit('billing/setEndDate', value)
            }
        },

    },
    methods: {
        ...mapActions('billing', ['adminDownloadCredits']),

        open() {
            this.display = true
        },

        doDownload() {
            this.loading = true
            this.adminDownloadCredits(this.endDate)
                .then(response => {
                Download.download(
                    response,
                    `credits_${this.moment(this.endDate).format('YYYY-MM-DD')}`
                )
            }).catch((e) => {
                this.$store.dispatch('toastError', e)
            }).finally(() => {
                this.loading = false
            })

        },
    },
    mounted() {
        this.startDate = this.moment().startOf('month').toDate()
        this.endDate = this.moment().endOf('month').toDate()
    }
}
</script>
