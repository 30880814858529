<template>
    <field v-model="input" class="relative mb-4 flex flex-col" :rules="finalRule" :name="name">
        <template #field="{ fieldName }">
            <nl-fieldset :legend="label" class="mb-0">
                <radio-group v-model="input" :name="fieldName" :items="items" />
            </nl-fieldset>
        </template>
    </field>
</template>

<script>
    import Field from './Field'
    import RadioGroup from './RadioGroup.vue'

    export default {
        components: {
            Field,
            RadioGroup
        },
        props: {
            label: {
                type: String,
                default: '&nbsp;'
            },
            items: {
                type: Array,
                required: true
            },
            type: {
                type: String,
                default: 'text'
            },
            value: {
                type: [String, Number],
                default: ''
            },
            rules: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: ''
            }
        },
        computed: {
            finalRule() {
                return [this.rules].join('|')
            },
            input: {
                get() {
                    return this.value
                },
                set(value) {
                    return this.$emit('input', value)
                }
            }
        }
    }
</script>
