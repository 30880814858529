<template>
    <v-form @submit="save">
        <div class="row">
            <div class="col col-6">
                <v-textfield
                    v-model="profile.email_commande_autre"
                    class-label="form-control-label-profil"
                    :label="$t('profil.orders.add-receiver')"
                />
            </div>
        </div>
        <div class="row">
            <div class="col col-6">
                <v-checkbox v-model="profile.recevoir_email_commande">
                    {{ $t('profil.orders.send-me-a-copy') }}
                </v-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col col-4">
                <button class="btn btn-primary">
                    {{ $t('global.save') }}
                </button>
            </div>
        </div>
    </v-form>
</template>

<script>
    import AbstractProfile from './AbstractProfile.vue'

    export default {
        name: 'AccountOrder',
        mixins: [AbstractProfile],
        data: function () {
            return {}
        },
        created() {},
        methods: {
            save: function () {
                this.$emit('save')
            }
        }
    }
</script>
