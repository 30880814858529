export default {
    setCampaign(state, data) {
        state.campaign = data
    },

    setMails(state, data) {
        state.campaign.mails = data
    },

    setLaunchingMail(state, data) {
        state.campaign.mails.launching = data
    },

    setMonthlyReportMails(state, data) {
        state.campaign.mails.monthlyReports = data
    },

    setKeywords(state, data) {
        state.keywords = data
    },

    setKeywordsWorkedOn(state, data) {
        state.keywordsWorkedOn = data
    },
    setKeywordsNotWorkedOn(state, data) {
        state.keywordsNotWorkedOn = data
    },

    updateKeywords(state, { ids, data }) {
        state.keywords = state.keywords.map(e => {
            if (ids.includes(e.id)) {
                return {
                    ...e,
                    ...data
                }
            } else {
                return e
            }
        })
    },

    removeKeywords(state, ids) {
        state.keywords = state.keywords.filter(e => !ids.includes(e.id))
    },

    setCampaignStatus(state, status) {
        state.campaign.information.status = status
    },

    setCampaignInformation(state, { url, budget_mensuel, duree, debut_accompagnement, fin_accompagnement }) {
        state.campaign.information = {
            ...state.campaign.information,
            url,
            budget_mensuel,
            duration: duree,
            start_at: debut_accompagnement,
            end_at: fin_accompagnement
        }
    },

    setBudgets(state, budgets) {
        state.budgets = budgets
    },

    setActionsData(state, value) {
        state.actions.data = value
    },

    setActionsLoading(state, value) {
        state.actions.loading = value
    },

    setFollowUpsData(state, value) {
        state.followUps.data = value
    },

    setFollowUpsLoading(state, value) {
        state.followUps.loading = value
    }
}
