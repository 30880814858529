export default () => ({
    preorders: null,
    preordersConfig: {
        include: '',
        sort: 'id',
        sortDirection: 'desc',
        page: 1,
        filters: {},
        per_page: 25
    }
})
