import ApiService from '../../../services/api.service'

export default {
    projects(context) {
        return new Promise((resolve, reject) => {
            ApiService.get('/projects')
                .then(data => {
                    context.commit('setProjects', data.data)
                    resolve(data.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getDefaultProject(context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/user/defaultProject`)
                .then(({ data }) => {
                    context.commit('setProject', data.project, { root: true })
                    context.commit('setDefaultProject', data.project)
                    resolve(data.project)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
