var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.priceSum)?_c('b-taglist',{attrs:{"attached":""}},[_c('b-tag',{attrs:{"type":"is-warning","size":"is-medium"}},[_vm._v(" "+_vm._s(_vm.$t('partenaire-mot-cles.potentialGain'))+" ")]),_c('b-tag',{attrs:{"type":"is-dark","size":"is-medium"}},[_vm._v(" "+_vm._s(_vm.priceSum)+"€")])],1):_c('b-skeleton',{attrs:{"animated":"","width":160,"height":30}}),_c('nl-table',{attrs:{"actions":_vm.actions,"checked-rows":_vm.checkedRows,"columns":_vm.columns,"force-update":_vm.forceUpdate,"get-data":_vm.loadAsyncData,"checkable":""},on:{"update:checkedRows":function($event){_vm.checkedRows=$event},"update:checked-rows":function($event){_vm.checkedRows=$event},"update:columns":function($event){_vm.columns=$event}},scopedSlots:_vm._u([{key:"keywords",fn:function({
                props: {
                    row: { keywords, keywords_count }
                }
            }){return [(keywords_count)?_c('b-button',{attrs:{"type":"is-light","icon-left":"eye","label":String(keywords_count)},on:{"click":function($event){return _vm.$emit('displayKeywords', keywords)}}}):_c('div',[_vm._v("-")])]}},{key:"floor_price",fn:function({
                props: {
                    row: { floor_price }
                }
            }){return [_vm._v(" "+_vm._s(floor_price ? `${floor_price}€` : `${_vm.site?.prix_minimum_filtre || 0}€ (${_vm.$t('partenaire-mot-cles.global-site')})`)+" ")]}},{key:"saleable",fn:function({
                props: {
                    row: { saleable }
                }
            }){return [(saleable)?_c('b-icon',{attrs:{"type":"is-success","icon":"check"}}):_c('b-icon',{attrs:{"type":"is-danger","icon":"times"}})]}},{key:"actions",fn:function({
                props: {
                    row: { url }
                }
            }){return [_c('ScrapButton',{attrs:{"item":{
                    id: _vm.site.id,
                    url: url
                },"call-back":() => _vm.$emit('reset')}})]}}])}),_c('SiteKeywordsConfirmModal',{ref:"confirmModal",attrs:{"checked-rows":_vm.checkedRows},on:{"uncheckRows":function($event){_vm.checkedRows = []}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }