<template>
    <div>
        <div v-if="contents.length > 0" class="alert alert-warning" role="alert">
            <h4 class="alert-heading">Commande(s) à contrôler !</h4>
            <p>
                <span>{{ contents.length }} </span>
                Commande(s) sont disponible sur la période de l'accompagnement mais ne sont pas associée(s) à celui-ci.
                <a href="#" class="alert-link" @click.prevent="showModal = true">Cliquer pour corriger</a>
            </p>
        </div>
        <portal to="bootstrap">
            <nl-modal :open-modal="showModal" size="xl" title="Commande(s) à contrôler" @hide="hideModal">
                <div class="overflow-x-auto">
                    <nl-table
                        :async="false"
                        :checked-rows.sync="checkedRows"
                        :columns.sync="columns"
                        :data="contents"
                        :checkable="true"
                    >
                        <template #created_at="{ props }">
                            {{ props.row.created_at ? $d(Date.parse(props.row.created_at), 'short') : '' }}
                        </template>
                        <template #partenaire_id="{ props }">
                            <span v-if="!props.row.partenaire_id"> NextLevel </span>
                            <span v-else>
                                {{ $t('partenaire-site.partner') }}
                            </span>
                        </template>
                        <template #status="{ props }">
                            <Status :content="props.row" />
                        </template>
                        <template #url="{ props }">
                            <div v-if="!readonly || props.row.statut === 'termine'" class="d-flex align-items-center">
                                <b-tooltip position="is-top">
                                    <template v-slot:content>
                                        {{ props.row.url }}
                                    </template>
                                    <span class="text-truncate d-inline-block" style="max-width: 200px">
                                        {{ props.row.url }}
                                    </span>
                                </b-tooltip>
                            </div>
                            <div v-else>
                                {{ $t('suivi.availablewhenpublished') }}
                            </div>
                        </template>
                        <template #link_anchor="{ props }">
                            {{ props.row.link_anchor }}
                            <hr v-if="props.row.link_second_anchor" />
                            <span v-if="props.row.link_second_anchor">
                                {{ props.row.link_second_anchor }}
                            </span>
                        </template>
                        <template #link_url="{ props }">
                            <div class="d-flex align-items-center">
                                <b-tooltip position="is-top">
                                    <template v-slot:content>
                                        {{ props.row.link_url }}
                                    </template>
                                    {{ props.row.link_url }}
                                </b-tooltip>
                            </div>
                            <hr v-if="props.row.link_second_anchor" />
                            <div v-if="props.row.link_second_anchor" class="d-flex align-items-center">
                                <b-tooltip position="is-top">
                                    <template v-slot:content>
                                        {{ props.row.link_second_url }}
                                    </template>
                                    {{ props.row.link_second_url }}
                                </b-tooltip>
                                <nl-clipboard-button :copy="props.row.link_second_url" />
                            </div>
                        </template>
                        <template #price_paid="{ props }">
                            {{ props.row.price_paid | toCurrency }}
                        </template>
                    </nl-table>
                </div>

                <template v-slot:footer>
                    <button type="button" class="btn btn-primary" data-dismiss="modal">Annuler</button>
                    <button
                        type="submit"
                        class="btn btn-secondary"
                        :disabled="checkedRows.length === 0"
                        @click="submitSelection"
                    >
                        Associer à l'accompagnement
                    </button>
                </template>
                <template v-slot:footer>
                    <button type="button" class="btn btn-primary" data-dismiss="modal">Annuler</button>
                    <button
                        type="submit"
                        class="btn btn-secondary"
                        :disabled="checkedRows.length === 0"
                        @click="submitSelection"
                    >
                        Associer à l'accompagnement
                    </button>
                </template>
            </nl-modal>
        </portal>
    </div>
</template>
<script>
    import Status from '@/components/Content/Status.vue'
    import { mapState } from 'vuex'

    export default {
        components: { Status },
        props: {
            contents: {
                type: Array,
                default: () => []
            },
            readonly: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                showModal: false,
                checkedRows: [],
                columns: [
                    {
                        label: 'ID',
                        field: 'id',
                        centered: true,
                        sortable: false,
                        searchable: false
                    },
                    {
                        label: "Date d'achat",
                        field: 'created_at',
                        centered: true,
                        sortable: false,
                        searchable: false,
                        custom: true
                    },
                    {
                        label: 'Propiétaire',
                        field: 'partenaire_id',
                        centered: true,
                        sortable: false,
                        searchable: false,
                        custom: true
                    },
                    {
                        label: 'Statut',
                        field: 'status',
                        centered: true,
                        sortable: false,
                        searchable: false,
                        custom: true
                    },
                    {
                        label: 'URL achetée',
                        field: 'url',
                        centered: true,
                        sortable: false,
                        searchable: false,
                        custom: true
                    },
                    {
                        label: 'Ancre',
                        field: 'link_anchor',
                        sortable: false,
                        searchable: false,
                        custom: true
                    },
                    {
                        label: 'Lien',
                        field: 'link_url',
                        sortable: false,
                        centered: true,
                        searchable: false,
                        custom: true
                    },
                    {
                        label: 'Prix',
                        field: 'price_paid',
                        sortable: false,
                        centered: true,
                        searchable: false,
                        custom: true
                    }
                ]
            }
        },
        computed: {
            ...mapState('campaigns', ['campaign']),
            ...mapState('contents', {
                periods: state => state.offsite
            })
        },
        methods: {
            hideModal() {
                this.checkedRows = []
                this.showModal = false
            },
            submitSelection() {
                this.$emit(
                    'submit',
                    this.checkedRows.map(content => content.id)
                )
                this.hideModal()
            }
        }
    }
</script>

<style scoped></style>
