<template>
    <div class="d-flex flex-column" style="gap: 0.5rem">
        <div class="actions-container">
            <div style="margin-left: auto">
                <PerPageSelector
                    v-model="config.per_page"
                    :loading="loading"
                    class="mx-2"
                    @perPageChange="loadAsyncData"
                />
            </div>
            <TableColumnSelect v-model="columns" />
            <div class="d-flex actions" style="gap: 0.5rem">
                <b-select v-model="selectAction" style="flex: 1 1 auto" expanded :placeholder="$t('global.actions')">
                    <option value="edit" :disabled="checkedRows.length !== 1">
                        {{ $t('global.modify') }}
                    </option>
                    <option value="setDefault" :disabled="checkedRows.length !== 1">
                        {{ $t('projets.columns.setDefault') }}
                    </option>
                    <option value="deactivate" :disabled="checkedRows.length === 0">
                        {{ $t('global.desactivate') }}
                    </option>
                    <option value="delete" :disabled="checkedRows.length === 0">
                        {{ $t('global.delete') }}
                    </option>
                    <option value="activate" :disabled="checkedRows.length === 0">
                        {{ $t('global.activate') }}
                    </option>
                </b-select>
                <b-button type="is-warning" :label="$t('global.validate')" @click="onValidateAction" />
            </div>
        </div>

        <b-table
            :data="projects"
            :total="projects.length"
            :mobile-cards="hasMobileCards"
            :checked-rows.sync="checkedRows"
            backend-filtering
            backend-sorting
            backend-pagination
            paginated
            :per-page="config.per_page"
            :current-page.sync="config.page"
            :default-sort="config.sort"
            :default-sort-direction="config.sortDirection"
            checkable
            :header-checkable="$screen.width > 768"
            :debounce-search="400"
            :checkbox-position="checkboxPosition"
            pagination-rounded
            :loading="loading"
            @page-change="onPageChange"
            @filters-change="onFilterChange"
            @sort="onSort"
        >
            <b-table-column
                field=""
                :label="columns[0].label"
                :visible="columns[0].visible"
                centered
                width="10%"
                cell-class="align-middle"
            >
                <template v-slot="props">
                    <div>
                        <div v-if="defaultProjectId === props.row.id" class="badge badge-light px-2 py-2 bg-secondary">
                            {{ $t('projets.columns.defaultProject') }}
                        </div>
                    </div>
                </template>
            </b-table-column>

            <b-table-column
                field="project_lang"
                sortable
                searchable
                :label="columns[1].label"
                :visible="columns[1].visible"
                class="searchableCol"
                centered
                cell-class="align-middle"
            >
                <template v-slot="props">
                    <flag :iso="'en' === props.row.project_lang ? 'us' : 'fr'" />
                </template>

                <template #searchable="props">
                    <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                        <option :value="props.filters.status">
                            {{ $t('precommande.all') }}
                        </option>
                        <option value="fr">Français</option>
                        <option value="en">English</option>
                    </select>
                </template>
            </b-table-column>

            <b-table-column
                field="name"
                sortable
                searchable
                :label="columns[2].label"
                :visible="columns[2].visible"
                class="searchableCol"
                cell-class="align-middle"
            >
                <template v-slot="props">
                    {{ props.row.name }}
                </template>
                <template #searchable="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        class="form-control form-control-sm"
                        :placeholder="$t('projets.columns.name')"
                    />
                </template>
            </b-table-column>

            <b-table-column
                field="website"
                sortable
                searchable
                :label="columns[3].label"
                :visible="columns[3].visible"
                cell-class="align-middle"
            >
                <template v-slot="props">
                    {{ props.row.website }}
                </template>
                <template #searchable="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        class="form-control form-control-sm"
                        :placeholder="$t('projets.columns.website')"
                    />
                </template>
            </b-table-column>

            <b-table-column
                field="blacklist"
                :label="columns[4].label"
                :visible="columns[4].visible"
                centered
                cell-class="align-middle"
            >
                <template v-slot="{ row: { blacklist_count } }">
                    <span style="white-space: nowrap">
                        <span v-if="blacklist_count">
                            {{ `${blacklist_count} URL(s)` }}
                        </span>
                        <span v-else> - </span>
                    </span>
                </template>
            </b-table-column>

            <b-table-column
                field="budget"
                :label="columns[5].label"
                :visible="columns[5].visible"
                sortable
                searchable
                cell-class="text-right align-middle"
            >
                <template v-slot="props">
                    <div>
                        <span v-if="!props.row.budget">0</span>
                        <span v-else>{{ props.row.budget }}</span>
                        €
                    </div>
                </template>
                <template #searchable="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        class="form-control form-control-sm"
                        :placeholder="$t('projets.columns.budget')"
                    />
                </template>
            </b-table-column>

            <b-table-column
                field="destinataires_mails"
                sortable
                searchable
                :label="columns[6].label"
                :visible="columns[6].visible"
                cell-class="text-right align-middle"
            >
                <template v-slot="props">
                    {{ props.row.destinataires_mails }}
                </template>
                <template #searchable="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        class="form-control form-control-sm"
                        :placeholder="$t('projets.columns.emailRecipients')"
                    />
                </template>
            </b-table-column>

            <b-table-column field="actions" label="Voir">
                <template v-slot="props">
                    <div v-if="props.row.name.startsWith('RD-')">
                        <router-link
                            :to="{ name: 'MyOrders', query: { project_id: props.row.id } }"
                            class="button is-light text-decoration-none"
                        >
                            <b-icon icon="eye" size="is-small"></b-icon>
                        </router-link>
                    </div>
                </template>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import TableColumnSelect from '@/components/UI/Table/TableColumnSelect.vue'
    import PerPageSelector from '@/components/UI/Table/PerPageSelector.vue'
    import { Flag } from 'vue-flag-icon/components'

    export default {
        name: 'TabProject',
        components: { PerPageSelector, TableColumnSelect, Flag },
        props: {
            projects: {
                type: Array,
                required: true
            },
            loading: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data: function () {
            return {
                //buefy data
                checkedRows: [],
                checkboxPosition: 'left',
                hasMobileCards: true,
                isPaginated: true,
                isSearchable: true,

                selectAction: null,

                //other
                isMobileWidth: window.innerWidth < 768,

                columns: [
                    {
                        label: this.$t('projets.columns.defaultProject')
                    },
                    {
                        label: this.$t('precommande.columns.locale')
                    },
                    {
                        label: this.$t('projets.columns.name')
                    },
                    {
                        label: this.$t('projets.columns.website')
                    },
                    {
                        label: this.$t('projets.columns.filteredLinks')
                    },
                    {
                        label: this.$t('projets.columns.budget')
                    },
                    {
                        label: this.$t('projets.columns.emailRecipients')
                    },
                    {
                        label: this.$t('projets.columns.actions')
                    }
                ]
            }
        },
        computed: {
            ...mapState('auth', {
                defaultProjectId: state => state.user.project_default_id
            }),
            ...mapState('projects', {
                config: 'projectsConfig'
            })
        },
        methods: {
            ...mapActions('auth', ['me']),
            loadAsyncData() {
                this.$emit('loadAsyncData')
            },
            onFilterChange(filters) {
                this.config.filters = filters
                this.loadAsyncData()
            },
            onPageChange(page) {
                this.config.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.config.field = field
                this.config.sortDirection = order

                this.loadAsyncData()
            },
            onValidateAction() {
                this.$emit('onValidateAction', this.selectAction, this.checkedRows)
                this.checkedRows = []
            }
        }
    }
</script>

<style lang="scss" scoped>
    .actions-container {
        padding: 0.5rem 0;
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
    }

    @media screen and (max-width: 768px) {
        .actions-container {
            flex-wrap: wrap;
        }

        .actions-container {
            display: flex;

            > ::v-deep * {
                flex: 1 1 125px;
            }

            > .actions {
                flex: 1 1 auto;
            }
        }
    }
</style>