<template>
    <field v-model="input" class="nl-textarea" :class="stateClass" :rules="rules" :name="name">
        <template #field="{ fieldValue, onInput, fieldName, fieldId }">
            <label :for="fieldId">{{ label }}</label>
            <textarea
                :id="fieldId"
                :ref="fieldName"
                :value="fieldValue"
                name=""
                :type="type"
                class="w-full rounded-lg border py-1 px-4 focus:outline-none"
                :rows="rows"
                @input="event => onInput(event.target.value)"
                @change="event => $emit('change', event)"
                @focus="focus = !focus"
                @blur="focus = !focus"
            />
        </template>
    </field>
</template>

<script>
    import Field from './Field'
    import FieldState from './mixins/FieldState.vue'

    export default {
        components: {
            Field
        },
        mixins: [FieldState],
        props: {
            rows: {
                type: Number,
                default: () => 10
            },
            label: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'text',
                validator(value) {
                    return ['url', 'text', 'password', 'tel', 'search', 'number', 'email'].includes(value)
                }
            },
            value: {
                type: String,
                default: ''
            },
            rules: {
                type: [Object, String],
                default: ''
            },
            name: {
                type: String,
                default: ''
            }
        },
        computed: {
            input: {
                get() {
                    return this.value
                },
                set(value) {
                    return this.$emit('input', value)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .nl-textarea {
        label {
        }

        textarea {
            width: 100%;
        }

        &.input-focus,
        &.input-filled {
            label {
            }
        }
    }
</style>
