import ApiService from '../../../services/api.service'
import { getCache, setCache } from '@/helpers/localStorageHelper'

export default {
    getEmailConfig({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/config/emails`)
                .then(({ data }) => {
                    let map = {}
                    data.configs.forEach(arr => {
                        if (map[arr['name']]) {
                            map[arr['name']].push(arr)
                        } else {
                            map[arr['name']] = [arr]
                        }
                    })

                    commit('setConfigEmail', map)
                    commit('setAdmins', data.users)

                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    saveEmailConfig(context, data) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/config/emails`, data)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getConfigs({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/config/configs`)
                .then(({ data }) => {
                    let map = {}
                    data.configs.forEach(arr => {
                        if (map[arr['name']]) {
                            map[arr['name']].push(arr)
                        } else {
                            map[arr['name']] = [arr]
                        }
                    })

                    map.selects = data.selects

                    commit('setConfig', map)
                    commit('setAdmins', data.users)

                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    updateConfigs(context, data) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/config/configs`, data)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getConfigByName(context, { name }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/config/name/${name}`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getLastTacs() {
        return new Promise((resolve, reject) => {
            ApiService.get('/config/tacs')
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    createTacs(context, data) {
        return new Promise((resolve, reject) => {
            ApiService.post('/config/tacs', data)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getModalInfo() {
        return (
            getCache('modal') ??
            new Promise((resolve, reject) => {
                ApiService.get('/config/modal')
                    .then(({ data }) => {
                        setCache('modal', data)
                        resolve(data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        )
    },

    getLastTacsByLang() {
        return (
            getCache('tacs') ??
            new Promise((resolve, reject) => {
                ApiService.get('/config/tacs-lang')
                    .then(({ data }) => {
                        setCache('tacs', data)
                        resolve(data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        )
    },

    acceptTacs(context, data) {
        return new Promise((resolve, reject) => {
            ApiService.put('/config/tacs-accept', data)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
