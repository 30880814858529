<template>
    <form>
        <b-field label="Email client ou fictif (client@agence.fr)" class="pb-0 mb-0">
            <b-input v-model="client.email" type="email" icon="at" maxlength="255" class="pb-0 mb-0" />
        </b-field>

        <b-field label="Prénom client ou nom de l'entreprise">
            <b-input v-model="client.firstname" type="text" icon="user" />
        </b-field>

        <b-field label="Nom client ou nom de l'agence">
            <b-input v-model="client.lastname" type="text" icon="user" />
        </b-field>

        <b-field label="Mot de passe">
            <b-input v-model="client.password" type="password" icon="lock" password-reveal expanded />
            <p class="control">
                <b-button class="button is-primary" @click.prevent="createPassword"> Générer</b-button>
            </p>
        </b-field>

        <b-field v-if="user.role !== 'consultant'" label="Rattacher le client à">
            <b-radio v-model="client.responsible_type" name="name" :native-value="5" @input="resetResponsible">
                Un consultant
            </b-radio>
            <b-radio v-model="client.responsible_type" name="name" :native-value="4" @input="resetResponsible">
                {{ user.role === 'manager' ? 'Moi' : 'Un manager' }}
            </b-radio>
            <b-radio
                v-if="user.role === 'agence'"
                v-model="client.responsible_type"
                name="name"
                :native-value="null"
                @input="resetResponsible"
            >
                Moi
            </b-radio>
        </b-field>

        <b-field
            v-if="client.responsible_type === 5 && user.role !== 'consultant'"
            label="Attacher le client au consultant..."
        >
            <b-autocomplete
                v-model="name"
                :data="consultants"
                placeholder="Recherche par prénom ou nom"
                :loading="isFetching"
                :custom-formatter="option => `${option.firstname} ${option.lastname}`"
                open-on-focus
                :clearable="true"
                @select="option => (client.responsible_id = option ? option.id : null)"
                @input="searchConsultants"
            >
                <template slot-scope="props">
                    {{ props.option.firstname }} {{ props.option.lastname }}
                    <br />
                    <div class="font-italic">
                        <small><i class="fa fa-at" /> {{ props.option.email }}</small>
                    </div>
                </template>
            </b-autocomplete>
        </b-field>

        <b-field
            v-if="client.responsible_type === 4 && user.role !== 'consultant' && user.role !== 'manager'"
            label="Attacher le client au manager..."
        >
            <b-autocomplete
                v-model="name"
                :data="managers"
                placeholder="Recherche par prénom ou nom"
                :loading="isFetching"
                :custom-formatter="option => `${option.firstname} ${option.lastname}`"
                open-on-focus
                :clearable="true"
                @select="option => (client.responsible_id = option ? option.id : null)"
                @input="searchManagers"
            >
                <template slot-scope="props">
                    {{ props.option.firstname }} {{ props.option.lastname }}
                    <br />
                    <div class="font-italic">
                        <small><i class="fa fa-at" /> {{ props.option.email }}</small>
                    </div>
                </template>
            </b-autocomplete>
        </b-field>

        <b-field label="Budget initial" class="mb-3">
            <b-input
                v-model="client.budget_initial"
                type="number"
                step="0.01"
                icon="euro-sign"
                expanded
                placeholder="HT"
            />
            <p class="control">
                <b-button
                    class="button is-primary"
                    @click.prevent="client.budget_initial = Number.parseFloat(user.credit_amount)"
                >
                    / {{ user.credit_amount | toCurrency }}
                </b-button>
            </p>
        </b-field>
    </form>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'CreateClientForm',
        data() {
            return {
                name: '',
                selected: null,
                consultants: null,
                managers: null,
                isFetching: false,
                timeout: null
            }
        },
        computed: {
            ...mapState('consultants', {
                client: 'newClient',
                config: 'searchConfig'
            }),
            ...mapState('auth', ['user'])
        },
        methods: {
            ...mapActions('consultants', ['getManagers', 'getConsultants']),
            createPassword() {
                const length = 12
                const wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ~!@-#$+abcdefghijklmnopqrstuvwxyz~!@-#$+'

                this.client.password = Array.from(crypto.getRandomValues(new Uint32Array(length)))
                    .map(x => wishlist[x % wishlist.length])
                    .join('')
            },
            reset() {
                this.name = ''
                this.selected = null
                this.consultants = null
                this.managers = null
                this.isFetching = false
                this.timeout = null
            },
            searchConsultants() {
                this.isFetching = true

                if (this.timeout) {
                    clearTimeout(this.timeout)
                }

                this.timeout = setTimeout(this.doSearchConsultant, 500)
            },
            doSearchConsultant() {
                this.config.filters['computed_name'] = this.name
                this.getConsultants({ config: this.config, commit: false })
                    .then(data => {
                        this.consultants = data.consultants.data
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            },
            searchManagers() {
                this.isFetching = true

                if (this.timeout) {
                    clearTimeout(this.timeout)
                }

                this.timeout = setTimeout(this.doSearchManagers, 500)
            },
            doSearchManagers() {
                this.config.filters['computed_name'] = this.name
                this.getManagers({ config: this.config, commit: false })
                    .then(data => {
                        this.managers = data.managers.data
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            },
            resetResponsible() {
                this.client.manager_id = null
                this.client.client_id = null
                this.client.responsible_id = null

                this.name = ''

                if (this.client.responsible_type === 4 && this.user.role === 'manager') {
                    this.name = this.user.email
                    this.config.filters['computed_name'] = this.name
                    this.getManagers({ config: this.config, commit: false })
                        .then(data => {
                            this.managers = data.managers.data
                            this.client.responsible_id = this.managers[0].id
                        })
                        .catch(e => {
                            this.$store.dispatch('toastError', e)
                        })
                }
            }
        }
    }
</script>
