<template>
    <div class="w-100 h-100">
        <line-chart ref="chart" :data="linechart.data" :options="linechart.options" />
    </div>
</template>

<script>
    export default {
        name: 'RedactorWordsCountFollowUp',
        components: {},
        props: {
            redactor: {
                type: Object,
                required: true
            },
            orders: {
                type: Array,
                required: true
            },
            dateFrom: {
                type: String,
                required: true
            },
            dateTo: {
                type: String,
                required: true
            }
        },
        data: function () {
            return {
                linechart: {
                    data: {
                        labels: [],
                        datasets: []
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            position: 'top'
                        },
                        title: {
                            display: true,
                            text: 'Évolution du nombre de mots rédigés'
                        },
                        animation: {
                            duration: 0
                        },
                        hover: {
                            animationDuration: 0
                        },
                        responsiveAnimationDuration: 0,
                        plugins: {
                            title: {
                                display: true,
                                text: 'Chart with Tick Configuration'
                            }
                        },
                        scales: {
                            xAxes: [],
                            yAxes: [
                                {
                                    display: true,
                                    position: 'right',
                                    ticks: {
                                        beginAtZero: true,
                                        callback: function (val, index) {
                                            return Math.trunc(val) === val ? val : ''
                                        }
                                    },
                                    gridLines: {
                                        display: false // only want the grid lines for one axis to show up
                                    }
                                },
                                {
                                    id: 'word-count',
                                    display: true,
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }
                            ]
                        }
                    }
                }
            }
        },

        watch: {
            orders: function () {
                this.init()
            }
        },
        created() {
            if (this.orders && this.orders.length > 0) {
                this.init()
            }
        },
        mounted() {},
        methods: {
            init: function () {
                this.getData().then(() => {
                    this.$refs.chart.render()
                })
            },
            getData: function () {
                return new Promise((resolve, reject) => {
                    var labels = []
                    var dataset = {
                        label: 'Nombre de mots rédigés',
                        data: [],
                        borderWidth: 2,
                        fill: false,
                        borderColor: 'rgb(92, 178, 112)',
                        tension: 0.1,
                        type: 'line',
                        yAxisID: 'word-count'
                    }

                    var datasetSeuil = {
                        label: 'Seuil max',
                        data: [],
                        borderWidth: 2,
                        fill: true,
                        backgroundColor: 'rgba(249, 249, 249, 0)',
                        borderColor: 'rgb(101, 53, 134)',
                        tension: 0.1,
                        type: 'line',
                        yAxisID: 'word-count',
                        pointRadius: 0,
                        borderDash: [10, 15],
                        title: false,
                        tooltips: {
                            enabled: false
                        },
                        legend: {
                            display: false
                        }
                    }

                    var datasetBarChart = {
                        label: 'Nombre de commandes',
                        data: [],
                        borderWidth: 1,
                        borderColor: 'rgb(249, 188, 19)',
                        backgroundColor: 'rgb(249, 188, 19)',
                        type: 'bar'
                    }

                    var momentDateFrom = this.moment(this.dateFrom)
                    var momentDateTo = this.moment(this.dateTo)

                    var numberOfMonth = momentDateTo.diff(momentDateFrom, 'months', true)
                    var start = momentDateFrom.format('YYYY-MM')

                    if (numberOfMonth > 2) {
                        for (var i = 0; i <= numberOfMonth; i++) {
                            var currentMonth = this.moment(this.dateFrom).add(i, 'M')
                            var monthName = currentMonth.clone().format('MMMM')

                            labels.push(monthName)

                            var wordsCount = 0
                            var ordersCount = 0
                            var order = this.orders.find(order => order.date === currentMonth.format('YYYY-MM'))

                            if (order) {
                                wordsCount = parseInt(order.total_written)
                                ordersCount = parseInt(order.total)
                            }

                            dataset.data.push(wordsCount)
                            datasetBarChart.data.push(ordersCount)
                            datasetSeuil.data.push(this.redactor.word_count)
                        }

                        if (
                            this.linechart.options.scales.xAxes[0] &&
                            this.linechart.options.scales.xAxes[0].hasOwnProperty('ticks')
                        ) {
                            this.linechart.options.scales.xAxes[0] = {}
                        }
                    } else {
                        for (var i = 0; i <= numberOfMonth; i++) {
                            var numberOfDaysInMonth = Array.from(
                                Array(this.moment(start, 'YYYY-MM').daysInMonth()).keys()
                            )

                            for (var j = 0; j < numberOfDaysInMonth.length; j++) {
                                var day = 1 + numberOfDaysInMonth[j]
                                if (day < 10) {
                                    day = '0' + day
                                }

                                var currentDate = this.moment(`${start}-${day}`)

                                labels.push(currentDate.format('DD MMM'))

                                var wordsCount = 0
                                var ordersCount = 0
                                var order = this.orders.find(order => order.date === currentDate.format('YYYY-MM-DD'))

                                if (order) {
                                    wordsCount = parseInt(order.total_written)
                                    ordersCount = parseInt(order.total)
                                }

                                dataset.data.push(wordsCount)
                                datasetBarChart.data.push(ordersCount)
                                datasetSeuil.data.push(this.redactor.word_count)
                            }

                            start = this.moment(this.dateFrom)
                                .add(1 + i, 'M')
                                .format('YYYY-MM')
                        }

                        this.linechart.options.scales.xAxes[0] = {
                            ticks: {
                                callback: function (val, index) {
                                    return index % 2 === 0 ? val : ''
                                }
                            }
                        }
                    }

                    this.linechart.data.datasets = [dataset, datasetBarChart, datasetSeuil]
                    this.linechart.data.labels = labels
                    resolve()
                })
            }
        }
    }
</script>
