var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"modal",staticClass:"modal fade",attrs:{"tabindex":_vm.tabIndex,"aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog",class:{
            'modal-xxl': _vm.size === 'xxl',
            'modal-xl': _vm.size === 'xl',
            'modal-lg': _vm.size === 'lg',
            'modal-sm': _vm.size === 'sm',
            'modal-right': _vm.position === 'right'
        }},[_c('div',{staticClass:"modal-content",class:[_vm.position === 'right' ? 'content-right' : '']},[(_vm.$slots.header)?_c('div',{staticClass:"modal-header"},[_vm._t("header")],2):_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._m(0)]),_c('div',{staticClass:"modal-body",class:{ 'p-0': _vm.noPaddingBody }},[_vm._t("default")],2),(_vm.$slots.footer)?_c('div',{staticClass:"modal-footer"},[_vm._t("footer")],2):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])
}]

export { render, staticRenderFns }