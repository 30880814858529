<template>
    <nl-modal ref="modal" title="Ajouter un site spot" :add-legacy-events="false" position="center" size="md">
        <div class="d-flex justify-content-center flex-column">
            <b-field label="URLs (une par ligne)">
                <b-input v-model="newSite.urls" custom-class="input-bulk" type="textarea" rows="25" />
            </b-field>
            <b-field label="Thématique">
                <b-select v-model="newSite.group_id" expanded>
                    <option v-for="group in groups" :key="group.id" :value="group.id">
                        {{ group.name }}
                    </option>
                </b-select>
            </b-field>
        </div>
        <template #footer>
            <b-button type="is-primary" @click="hide"> Annuler</b-button>
            <b-button
                type="is-warning"
                :disabled="!(newSite.urls.length > 0 && newSite.group_id !== null)"
                @click="onSave"
            >
                Ajouter
            </b-button>
        </template>
    </nl-modal>
</template>

<script>
    export default {
        name: 'AddSpotSiteModal',
        props: ['groups'],
        data() {
            return {
                showModal: true,
                modalType: 'create',
                newSite: {
                    urls: '',
                    wp_token: '',
                    group_id: 1
                }
            }
        },
        methods: {
            show() {
                this.newSite = {
                    urls: '',
                    group_id: null
                }
                this.$refs.modal.show()
            },
            hide() {
                this.$refs.modal.hide()
            },
            onSave() {
                if (this.newSite.urls.length > 0 && this.newSite.group_id !== null) {
                    let urlsArray = this.newSite.urls.split('\n')
                    this.$emit('addSites', this.newSite.group_id, urlsArray)
                    this.hide()
                }
            }
        }
    }
</script>

<style scoped>
    .input-bulk {
        min-height: 200px;
    }
</style>
