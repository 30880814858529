<template>
    <span>
        <span v-if="status == 'termine' && !isTerminateWithErrors(order)" class="tag is-success">
            <i class="fas fa-check-circle mr-1" data-toggle="tooltip" data-html="true" />
            {{ $t('status.finished') }}
        </span>

        <span v-else-if="status == 'termine' && isTerminateWithErrors(order)" class="tag is-danger">
            <i class="fas fa-exclamation-circle mr-1" data-toggle="tooltip" data-html="true" />
            {{ $t('partenaire-commandes.terminate-error') }}
        </span>

        <span v-else-if="status == 'refuse'" class="tag is-danger">
            <i class="fas fa-ban mr-1" data-toggle="tooltip" data-html="true" />
            {{ $t('status.refused') }}
        </span>

        <span v-else-if="status == 'en_cours'" class="tag is-info">
            <i class="fas fa-pencil-alt mr-1" data-toggle="tooltip" data-html="true" />
            {{ $t('global.todo') }}
        </span>

        <span v-else-if="status == 'a_valider'" class="tag is-warning">
            <i class="fas fa-clipboard-list mr-1" data-toggle="tooltip" data-html="true" />
            {{ $t('global.tovalidate') }}
        </span>

        <span v-else-if="status == 'annule'" class="tag is-danger">
            <i class="fas fa-ban mr-1" data-toggle="tooltip" data-html="true" />
            {{ $t('status.canceled') }}
        </span>

        <span v-else class="tag is-dark">Error</span>
    </span>
</template>

<script>
    export default {
        name: 'PartnerOrdersStatus',
        props: ['order'],

        data() {
            return {
                status: null,
                currentOrder: null
            }
        },

        watch: {
            order: {
                immediate: true,
                deep: true,
                handler(newValue) {
                    this.currentOrder = newValue
                    this.status = newValue.statut
                }
            }
        },

        created() {
            this.currentOrder = this.order
            this.status = this.currentOrder.statut
        },

        methods: {
            isTerminateWithErrors(order) {
                return (
                    order.statut === 'termine' &&
                    order.verification_code_reponse_http != null &&
                    ((order.verification_code_reponse_http != '200' &&
                        order.verification_ne_plus_verifier_code_reponse !== '0') ||
                        (order.verification_autres_liens_externes != null &&
                            order.verification_ne_plus_verifier_autres_liens_externes == '0') ||
                        (order.verification_ne_plus_verifier_liens == 'ok' &&
                            (order.link_status == 'erreur' || order.link_second_status == 'erreur')))
                )
            }
        }
    }
</script>
