import axios from 'axios'

import router from '../router'
import NlStorage from "@/services/nl.storage";

/*
|--------------------------------------------------------------------------
| Axios Base config
|--------------------------------------------------------------------------
|
*/
let backendUrl = process.env.VUE_APP_LARAVEL_URL
axios.defaults.baseURL = backendUrl + '/api'

/*
|--------------------------------------------------------------------------
| Axios Request Interceptor
|--------------------------------------------------------------------------
|
*/
axios.interceptors.request.use(
    config => {
        let token = NlStorage.getItem('token')

        if (token) config.headers['Authorization'] = `Bearer ${token}`

        return config
    },

    error => {
        return Promise.reject(error)
    }
)

/*
|--------------------------------------------------------------------------
| Axios Response Interceptor
|--------------------------------------------------------------------------
|
*/
axios.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        if (error?.response?.status === 401) {
            NlStorage.removeItem('token')
            router.push('/login')
        }
        return Promise.reject(error)
    }
)

export default axios
