<template>
    <nl-modal ref="modal" :title="`Ajouter une catégorie`" :add-legacy-events="false" position="center" size="md">
        <div>
            <b-field label="Catégorie">
                <b-select v-model="category" placeholder="Sélectionner une catégorie" expanded>
                    <option v-for="category in categories" :key="category.id" :value="category.id">
                        {{ category.name }}
                    </option>
                </b-select>
            </b-field>
        </div>
        <template #footer>
            <b-button type="is-primary" @click="hide">Annuler</b-button>
            <b-button type="is-warning" :disabled="!category" @click="onSave"> Publier </b-button>
        </template>
    </nl-modal>
</template>

<script>
    export default {
        name: 'SpotsPublishModal',
        props: {
            contentItemsIds: {
                type: Array,
                required: true
            },
            categories: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                showModal: true,
                category: null
            }
        },
        methods: {
            show() {
                this.category = null
                this.$refs.modal.show()
            },
            hide() {
                this.$refs.modal.hide()
            },
            onSave() {
                if (this.category) {
                    this.$emit('publish', {
                        content_items_ids: this.contentItemsIds,
                        category: this.category
                    })
                    this.hide()
                }
            }
        }
    }
</script>