<template>
    <div>
        <p class="small text-muted font-weight-bold">Nombre de virements : {{ wiresList.total }}</p>

        <b-table
            :data="wiresList.data"
            :mobile-cards="true"
            :paginated="true"
            :current-page.sync="page"
            default-sort-direction="desc"
            :striped="true"
            :debounce-search="200"
            class="table-striped"
            :loading="isLoading"
            :default-sort="[sortField, sortOrder]"
            :per-page="wiresList.per_page"
            :total="wiresList.total"
            backend-sorting
            backend-pagination
            backend-filtering
            @page-change="onPageChange"
            @sort="onSort"
            @filters-change="onFilterChange"
        >
            <b-table-column
                field="invoice_quotation_id"
                sortable
                :searchable="isSearchable"
                label="ID Facture"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    {{
                        props.row.invoiceIdFacturation
                            ? props.row.invoiceIdFacturation + ' (Facture)'
                            : props.row.quotationIdFacturation
                            ? props.row.quotationIdFacturation + ' (Devis)'
                            : ''
                    }}
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="number"
                        class="form-control form-control-sm"
                        placeholder="#"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="created_at"
                sortable
                :searchable="isSearchable"
                :label="$t('factures.date')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    {{ moment(props.row.created_at).format('L') }}
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="date"
                        class="form-control form-control-sm"
                        :placeholder="$t('factures.date')"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="emailPayant"
                sortable
                :searchable="isSearchable"
                label="Email"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    {{ props.row.emailPayant }}
                    <LoginAs :user-id="props.row.id" />
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        step="0.01"
                        class="form-control form-control-sm"
                        placeholder="Email"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="accompagnement.url"
                sortable
                :searchable="isSearchable"
                label="Accompagnement"
                cell-class="align-middle"
            >
                <div v-if="props.row.accompagnement != null" slot="default" slot-scope="props">
                    {{ props.row.accompagnement.url }} -
                    {{ getCampaignPeriod(props.row.infos) }}
                </div>
                <div v-else slot="default">Pas d'accompagnement</div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        step="0.01"
                        class="form-control form-control-sm"
                        placeholder="Accompagnement"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="price_ht"
                sortable
                :searchable="isSearchable"
                :label="$t('factures.amounHT')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span class="tag">{{ props.row.price_ht }}€</span>
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm"
                        :placeholder="$t('factures.date')"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="payment_status"
                sortable
                :searchable="isSearchable"
                label="Status"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.payment_status === 'pending'" class="tag is-info">En attente</span>
                    <span
                        v-else-if="
                            props.row.payment_status === 'in_progress_credits' ||
                            props.row.payment_status === 'in_progress_invoice'
                        "
                        class="tag is-warning"
                        >En cours</span
                    >
                    <span v-else-if="props.row.payment_status === 'complete'" class="tag is-success">Terminé</span>
                    <span v-else class="tag is-dark">Inconnu</span>
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        step="0.01"
                        class="form-control form-control-sm"
                        placeholder="Status"
                    />
                </div>
            </b-table-column>

            <b-table-column field="invoiceIdFacturation" label="Devis/Facture" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <span>
                        <a :href="`${appUrl}/invoiceadmin.php?num=${props.row.id}`">
                            <img :src="`${appUrl}/assets/img/pdf.png`" />
                        </a>
                    </span>
                </div>
            </b-table-column>

            <b-table-column label="Actions">
                <div slot="default" slot-scope="props">
                    <div
                        v-if="
                            props.row.payment_status === 'pending' ||
                            props.row.payment_status === 'in_progress_credits' ||
                            props.row.payment_status === 'in_progress_invoice'
                        "
                        class="dropdown"
                    >
                        <button
                            id="chooseLocale"
                            class="btn btn-primary btn-sm dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                        >
                            Actions
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a
                                v-if="
                                    props.row.payment_status !== 'in_progress_credits' &&
                                    props.row.payment_status !== 'in_progress_invoice'
                                "
                                href="#"
                                class="dropdown-item"
                                @click.prevent="validateWire(props.row.id)"
                                ><i class="fas fa-check mr-1" />Valider</a
                            >
                            <a
                                v-if="props.row.payment_status !== 'in_progress_invoice'"
                                href="#"
                                class="dropdown-item"
                                @click.prevent="generateInvoice(props.row.id)"
                                ><i class="fas fa-file-alt mr-1" />Générer la facture</a
                            >
                            <a
                                v-if="props.row.payment_status !== 'in_progress_credits'"
                                href="#"
                                class="dropdown-item"
                                @click.prevent="addCredits(props.row.id)"
                                ><i class="fas fa-plus mr-1" />Ajouter les crédits</a
                            >
                            <a
                                v-if="props.row.payment_status !== 'in_progress_invoice'"
                                href="#"
                                class="dropdown-item"
                                @click.prevent="deleteWire(props.row.id)"
                                ><i class="far fa-trash-alt mr-1" />Supprimer</a
                            >
                        </div>
                    </div>
                </div>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    export default {
        name: 'TabWires',
        props: ['wires'],

        data: function () {
            return {
                wiresList: {},

                //buefy data
                isSearchable: true,
                sortField: 'id',
                sortOrder: 'desc',
                filters: '',
                page: 1,
                isLoading: false,

                // data info
                appUrl: process.env.APP_URL,

                //other
                isMobileWidth: window.innerWidth < 768
            }
        },
        watch: {
            wires: {
                handler(newValue) {
                    this.wiresList = newValue
                },
                deep: true
            }
        },

        created() {
            this.wiresList = this.wires && this.wires.total && this.wires.total > 0 ? this.wires : {}
        },

        methods: {
            validateWire(wireId) {
                this.isLoading = true
                var self = this

                this.$emit('validateWire', {
                    wire_id: wireId,

                    callback: () => {
                        self.isLoading = false
                    }
                })
            },

            addCredits(wireId) {
                this.isLoading = true
                var self = this

                this.$emit('addCredits', {
                    wire_id: wireId,

                    callback: () => {
                        self.isLoading = false
                    }
                })
            },

            generateInvoice(wireId) {
                this.isLoading = true
                var self = this

                this.$emit('generateInvoice', {
                    wire_id: wireId,

                    callback: () => {
                        self.isLoading = false
                    }
                })
            },

            deleteWire(wireId) {
                this.isLoading = true
                var self = this

                this.$emit('deleteWire', {
                    wire_id: wireId,

                    callback: () => {
                        self.isLoading = false
                    }
                })
            },

            /** Table filtering, sorting and pagination methods */
            loadAsyncData() {
                this.isLoading = true
                var self = this

                this.$emit('loadAsyncData', {
                    page: this.page,
                    sortField: this.sortField,
                    sortOrder: this.sortOrder,
                    filters: this.filters,
                    includes: '',
                    path: this.wiresList.path,
                    index: this.indexType,
                    per_page: this.perPage,

                    callback() {
                        self.isLoading = false
                    }
                })
            },
            getCampaignPeriod(infos) {
                return new Date(JSON.parse(infos).period).toLocaleDateString('fr-fr', {
                    month: 'long',
                    year: 'numeric'
                })
            },
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order

                this.loadAsyncData()
            },

            onFilterChange(filter) {
                var filters = []
                Object.keys(filter).forEach(element => {
                    var currentFilter = {
                        key: element,
                        value: filter[element]
                    }
                    filters.push(currentFilter)
                })
                this.filters = filters
                this.loadAsyncData()
            },

            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            }
        }
    }
</script>
