<template>
    <tr>
        <td v-if="withCheckbox">
            <input type="checkbox" value="1" @change="emitSelectionStatus($event)" />
        </td>
        <td>
            {{ achat.id }}
        </td>
        <td>
            {{ achat.created_at ? $d(Date.parse(achat.created_at), 'short') : '' }}
        </td>
        <td>
            <span v-if="!achat.partenaire_id"> NextLevel </span>
            <span v-else>
                {{ $t('partenaire-site.partner') }}
            </span>
        </td>
        <td>
            <status :content="achat" />
        </td>
        <td>
            <span v-if="achat.finished_at">{{ moment(achat.finished_at).format('L') }}</span>
        </td>
        <td>
            <div v-if="security.isAdmin || achat.statut === 'termine'">
                <b-tooltip position="is-top">
                    <template v-slot:content>
                        {{ achat.url }}
                    </template>
                    <span class="text-truncate d-inline-block" style="max-width: 230px">
                        {{ achat.url }}
                    </span>
                </b-tooltip>
                <nl-clipboard-button :copy="achat.url" />
            </div>
            <div v-else>
                {{ $t('suivi.availablewhenpublished') }}
            </div>
        </td>
        <td v-if="withUrlInformation">
            <button
                v-if="achat.statut === 'termine'"
                class="btn btn-secondary show-url-infos"
                @click.prevent="$emit('click')"
            >
                {{ $t('suivi.seedatas') }}
            </button>
        </td>
        <td>
            {{ achat.link_anchor }}
            <div v-if="achat.link_second_anchor">
                <hr />
                {{ achat.link_second_anchor }}
            </div>
        </td>
        <td>
            <span>
                <b-tooltip position="is-top">
                    <template v-slot:content>
                        {{ achat.link_url }}
                    </template>
                    {{ formatUrl(achat.link_url) }}
                    <nl-clipboard-button :copy="achat.link_url" />
                </b-tooltip>
            </span>
            <div v-if="achat.link_second_anchor">
                <hr />
                <span>
                    <b-tooltip position="is-top">
                        <template v-slot:content>
                            {{ achat.link_second_url }}
                            <nl-clipboard-button :copy="achat.link_second_url" />
                        </template>
                        {{ formatUrl(achat.link_second_url) }}
                    </b-tooltip>
                </span>
            </div>
        </td>
        <td>
            <span class="text-nowrap">{{ achat.price_paid }} €</span>
        </td>
        <td v-if="withEditButton">
            <a
                href="#"
                class="btn btn-sm btn-icon btn-outline-primary rounded-circle mr-1"
                title="Edit"
                @click.prevent="$emit('edit')"
            >
                <i class="fas fa-pen" />
            </a>
        </td>
    </tr>
</template>

<script>
    import Status from './Status'
    import NlClipboardButton from '../UI/NlClipboardButton'
    import { mapGetters } from 'vuex'

    export default {
        components: { NlClipboardButton, Status },
        props: {
            achat: {
                required: true
            },
            withEditButton: {
                type: Boolean,
                default: false
            },
            withUrlInformation: {
                type: Boolean,
                default: false
            },
            withCheckbox: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapGetters('auth', ['security'])
        },
        methods: {
            emitSelectionStatus: function (e) {
                this.$emit(e.target.checked ? 'select' : 'unselect')
            },
            formatUrl: function (urlToFormat) {
                if (!urlToFormat) {
                    return ''
                }

                let url = new URL(urlToFormat)
                return url.pathname
            }
        }
    }
</script>

<style scoped></style>
