<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 v-if="redactionData.active" class="h4 p-0 mb-0">
                        {{ $t('partenaire-commandes.redactionNL') }}
                    </h4>
                    <h4 v-if="acceptRedactionData.active" class="h4 p-0 mb-0">
                        {{ $t('partenaire-commandes.acceptredactionNL') }}
                    </h4>
                    <h4 v-else-if="acceptData.active" class="h4 p-0 mb-0">
                        {{ $t('partenaire-commandes.acceptorders') }}
                    </h4>
                    <h4 v-else-if="refuseData.active" class="h4 p-0 mb-0">
                        {{ $t('partenaire-commandes.Irefuseorders') }}
                    </h4>
                    <h4 v-else-if="publishData.active" class="h4 p-0 mb-0">
                        {{ $t('partenaire-commandes.published-orders') }}
                    </h4>
                    <h4 v-else class="h4 p-0 mb-0">
                        {{ $t('global.modify') }}
                    </h4>
                </header>
                <div class="card-content">
                    <div class="content">
                        <form>
                            <section class="row mb-3">
                                <div v-if="redactionData.active || acceptRedactionData.active" class="col-12">
                                    <div class="form-group">
                                        <label class="label" for="brief">{{ $t('global.brief') }}</label>
                                        <b-field>
                                            <b-input
                                                v-model="redactionData.brief"
                                                type="textarea"
                                                minlength="5"
                                                :validation-message="$t('validation.string.too-short')"
                                                :placeholder="$t('global.brief')"
                                            />
                                        </b-field>
                                    </div>
                                </div>

                                <div v-if="acceptData.active" class="col-12">
                                    <p class="mt-4 text-center">
                                        {{ $t('partenaire-commandes.confirm-accept') }} :
                                        <strong>#{{ this.listIds.join(', #') }}</strong
                                        >.
                                    </p>
                                </div>

                                <div v-if="publishData.active" class="col-12">
                                    <p class="mt-4 text-center">
                                        {{ $t('partenaire-commandes.confirm-publish') }} :
                                        <strong>#{{ this.listIds.join(', #') }}</strong
                                        >.
                                    </p>
                                </div>

                                <div v-if="refuseData.active" class="col-12 pb-4">
                                    <p class="mt-4 text-center">
                                        {{ $t('partenaire-commandes.confirm-refuse') }} :
                                        <strong>#{{ this.listIds.join(', #') }}</strong
                                        >.
                                    </p>

                                    <div class="form-group">
                                        <label class="label" for="motif_refus">{{
                                            $t('partenaire-commandes.cancel.reason')
                                        }}</label>
                                        <select
                                            id="motif_refus"
                                            v-model="refuseData.choice"
                                            name="motif_refus"
                                            class="form-control"
                                        >
                                            <option value="prix">
                                                {{ $t('partenaire-commandes.cancel.price') }}
                                            </option>
                                            <option value="concurrent">
                                                {{ $t('partenaire-commandes.cancel.concurrent') }}
                                            </option>
                                            <option value="hs">
                                                {{ $t('partenaire-commandes.cancel.hs') }}
                                            </option>
                                            <option value="erreur">
                                                {{ $t('partenaire-commandes.cancel.error') }}
                                            </option>
                                            <option value="autre">
                                                {{ $t('global.other') }}
                                            </option>
                                        </select>
                                    </div>

                                    <div v-if="refuseData.choice === 'prix'" id="motif_prix_container">
                                        <div class="form-group">
                                            <label class="label" for="prix_url">{{
                                                $t('partenaire-commandes.minigain')
                                            }}</label>
                                            <div class="input-group">
                                                <input
                                                    id="prix_url"
                                                    v-model="refuseData.min_price_url"
                                                    type="number"
                                                    class="form-control"
                                                    name="prix_url"
                                                    required
                                                />
                                                <div class="input-group-append">
                                                    <span class="input-group-text">€</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="label" for="prix_minimum_filtre">
                                                {{ $t('partenaire-commandes.minigainsite') }}
                                                <b-tooltip :label="$t('partenaire-commandes.gainwarning')">
                                                    <i class="ml-1 fas fa-info-circle" />
                                                </b-tooltip>
                                            </label>
                                            <div class="input-group">
                                                <input
                                                    id="prix_minimum_filtre"
                                                    v-model="refuseData.min_price_site"
                                                    type="number"
                                                    class="form-control valid"
                                                    name="prix_minimum_filtre"
                                                />
                                                <div class="input-group-append">
                                                    <span class="input-group-text">€</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-if="refuseData.choice === 'autre'"
                                        id="motif_autre_container"
                                        class="form-group"
                                    >
                                        <label class="label" for="motif_refus_autre">{{
                                            $t('partenaire-commandes.cancel.details')
                                        }}</label>
                                        <textarea
                                            id="motif_refus_autre"
                                            v-model="refuseData.motif_refus_autre"
                                            name="motif_refus_autre"
                                            class="form-control"
                                            rows="4"
                                            :placeholder="$t('partenaire-commandes.cancel.details')"
                                            :required="refuseData.choice === 'autre'"
                                        />
                                    </div>
                                </div>
                            </section>

                            <div class="buttons justify-content-end">
                                <button class="button is-light" @click.prevent="closeAndResetModal">
                                    {{ $t('global.cancel') }}
                                </button>
                                <button class="button is-warning" @click.prevent="validate">
                                    {{
                                        refuseData.active
                                            ? $t('partenaire-commandes.refuseorder')
                                            : $t('global.validate')
                                    }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'PartnerOrdersConfirmModal',
        props: ['checkedRows'],
        data() {
            return {
                modalIsOpen: false,
                currentType: null,
                listIds: [],

                redactionData: {
                    active: false,
                    brief: ''
                },
                acceptRedactionData: {
                    active: false
                },
                acceptData: {
                    active: false
                },
                publishData: {
                    active: false
                },
                refuseData: {
                    active: false,
                    motif_refus_autre: '',
                    min_price_url: null,
                    min_price_site: null,
                    choice: 'prix'
                }
            }
        },

        watch: {
            checkedRows() {
                this.listIds = this.checkedRows.map(order => order.id)
            }
        },

        created() {
            this.listIds = this.checkedRows.map(order => order.id)
        },

        methods: {
            validate() {
                switch (this.currentType) {
                    case 'redactionNL':
                        if (this.redactionData.brief.length < 3) {
                            return
                        }

                        this.$parent.$emit('redactionBulkOrders', {
                            ids: this.listIds,
                            brief: this.redactionData.brief
                        })
                        break
                    case 'acceptorder':
                        this.$parent.$emit('acceptBulkOrders', {
                            ids: this.listIds
                        })
                        break
                    case 'acceptRedactionNL':
                        if (this.redactionData.brief.length < 3) {
                            return
                        }
                        this.$parent.$emit('acceptRedactionBulkOrders', {
                            ids: this.listIds,
                            brief: this.redactionData.brief
                        })
                        break
                    case 'published':
                        this.$parent.$emit('publishBulkOrders', {
                            ids: this.listIds
                        })
                        break
                    case 'refuseorder':
                        this.$parent.$emit('refuseBulkOrders', {
                            ids: this.listIds,
                            choice: this.refuseData.choice,
                            motif_refus_autre: this.refuseData.motif_refus_autre,
                            min_price_url: this.refuseData.min_price_url,
                            min_price_site: this.refuseData.min_price_site
                        })
                        break

                    default:
                        break
                }

                this.$emit('uncheckRows')
                this.closeAndResetModal()
            },

            openModal(type) {
                this.modalIsOpen = true
                this.currentType = type

                switch (type) {
                    case 'redactionNL':
                        this.redactionData.active = true
                        break
                    case 'acceptRedactionNL':
                        this.acceptRedactionData.active = true
                        break
                    case 'acceptorder':
                        this.acceptData.active = true
                        break
                    case 'refuseorder':
                        this.refuseData.active = true
                        break
                    case 'published':
                        this.publishData.active = true
                        break

                    default:
                        break
                }
            },

            closeAndResetModal() {
                this.modalIsOpen = false
                this.currentType = null
                this.$emit('uncheckRows')

                this.redactionData = {
                    active: false,
                    brief: ''
                }
                this.acceptData = {
                    active: false
                }
                this.acceptRedactionData = {
                    active: false
                }
                this.publishData = {
                    active: false
                }
                this.refuseData = {
                    active: false,
                    motif_refus_autre: '',
                    min_price_url: null,
                    min_price_site: null,
                    choice: 'prix'
                }
            }
        }
    }
</script>

<style>
    label[for^='scrape_'] {
        color: #363636;
        display: block;
        font-size: 1rem;
        font-weight: 700;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
