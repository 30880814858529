<template>
    <div>
        <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <h2 class="text-muted mt-1">
                    {{ withdrawal.translated_date }}
                </h2>
                <b-field grouped group-multiline class="ml-2">
                    <div class="control">
                        <b-taglist attached>
                            <b-tag type="is-warning" size="is-small"> Montant HT</b-tag>
                            <b-tag type="is-dark" size="is-small">
                                {{ withdrawal.total_earnings.toFixed(2) | toCurrency }}
                            </b-tag>
                        </b-taglist>
                    </div>
                    <div class="control">
                        <b-taglist attached>
                            <b-tag type="is-warning" size="is-small"> NB de demandes</b-tag>
                            <b-tag type="is-dark" size="is-small">
                                {{ withdrawal.total_earnings_count }}
                            </b-tag>
                        </b-taglist>
                    </div>
                </b-field>
            </div>

            <div v-if="withdrawal.total_earnings_count > 0" class="buttons mt-4 mt-md-0">
                <button class="button is-small" @click.prevent="generateMultiplePdf(withdrawal.date)">
                    Générer les factures
                </button>
                <button
                    v-show="displayAllButton"
                    class="button is-light is-small"
                    @click.prevent="hideText === 'Voir' ? showValidatedWithdrawals() : hideTab()"
                >
                    <i class="fas fa-caret-right mr-1" />
                    {{ hideText }} les demandes validées
                    <nl-loader :show="loading" :customize-loader="'ml-2'" :width="'loader-sm'" />
                </button>
            </div>
        </div>

        <div v-if="withdrawal.total_earnings_count > 0" class="my-4">
            <div :class="withdrawal.earnings.length < 1 ? 'is-hidden' : ''">
                <TabsWithdrawalWinnings
                    :data="withdrawal.earnings"
                    :show-validated="showValidated"
                    :date="withdrawal.date"
                    @hideTab="hideTab"
                    @deleteWithdrawal="deleteWithdrawal"
                />
            </div>
        </div>

        <div v-else class="my-4">
            <p class="text-center font-weight-bold">Aucune activité enregistrée en {{ withdrawal.translated_date }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CardWithdrawalWinnings',
        props: ['data', 'withdraw_date'],
        data() {
            return {
                withdrawal: [],
                hideText: 'Voir',
                loading: false
            }
        },

        computed: {
            displayAllButton() {
                let sum = 0
                this.withdrawal.earnings.forEach(w => {
                    if (w.status === 'valider' && w.envoi_reglement === 1) {
                    } else {
                        sum += parseFloat(w.montant_ht)
                    }
                })

                return sum < this.withdrawal.total_earnings || this.hideText === 'Cacher'
            },
            showValidated() {
                return this.hideText === 'Cacher'
            }
        },

        created() {
            this.withdrawal = this.data

            let withdrawDate = this.withdrawal.date.split(' ')
            this.withdrawal.translated_date =
                this.$t(`date.months.${withdrawDate[0].toLowerCase()}`) + ` ${withdrawDate[1]}`
        },
        methods: {
            generateMultiplePdf(date) {
                this.axios.post('/withdrawals/generate-pdfs', {
                    date: date
                })
            },

            hideTab() {
                this.loading = true
                this.hideText = this.hideText === 'Voir' ? 'Cacher' : 'Voir'
                this.withdrawal.earnings = this.withdrawal.earnings.filter(w => w.status !== 'valider')
                this.loading = false
            },
            showValidatedWithdrawals() {
                this.loading = true
                this.hideText = this.hideText === 'Voir' ? 'Cacher' : 'Voir'
                this.axios
                    .get(`/withdrawals/month/${this.withdraw_date}`)
                    .then(response => {
                        this.withdrawal.earnings = response.data.withdrawals
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        this.loading = false
                    })
            },

            deleteWithdrawal(withdrawal) {
                this.axios
                    .delete(`/withdrawals/${withdrawal.id}`)
                    .then(() => {
                        this.withdrawal.earnings = this.withdrawal.earnings.filter(w => w.id !== withdrawal.id)
                    })
                    .catch(error => console.error(error))
            }
        }
    }
</script>
