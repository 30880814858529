<template>
    <div>
        <NlTable
            async
            sortable
            :kpis="kpis"
            :columns.sync="columns"
            :get-data="loadAsyncData"
            :force-update="forceUpdate"
            :actions="actions"
            checkable
            :checked-rows.sync="checkedRows"
        >
            <template #keywords="{ props: { row } }">
                <PartnerKeywordModal :url-id="row.id" :keywords-count="row.keywords_count" />
            </template>

            <template #saleableSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small" expanded>
                    <option :value="null">Tous</option>
                    <option :value="true">Oui</option>
                    <option :value="false">Non</option>
                </b-select>
            </template>

            <template #saleable="{ props: { row } }">
                <b-tag v-if="row.saleable" type="is-success" size="is-small"> Oui</b-tag>
                <b-tag v-else type="is-danger" size="is-small"> Non</b-tag>
            </template>

            <template #response_codeSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small" expanded>
                    <option :value="null">Tous</option>
                    <option value="0">0</option>
                    <option value="100">1XX</option>
                    <option value="200">2XX</option>
                    <option value="300">3XX</option>
                    <option value="400">4XX</option>
                    <option value="500">5XX</option>
                </b-select>
            </template>

            <template #response_code="{ props: { row } }">
                <template v-if="row.scrapped_at">
                    <b-tag v-if="row.response_code === 200" type="is-success" size="is-small">
                        {{ row.response_code }}
                    </b-tag>
                    <b-tag v-else type="is-danger" size="is-small">
                        {{ row.response_code }}
                    </b-tag>
                </template>
                <template v-else> -</template>
            </template>

            <template #external_linksSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small" expanded>
                    <option :value="null">Tous</option>
                    <option :value="true">Oui</option>
                    <option :value="false">Non</option>
                </b-select>
            </template>

            <template #external_links="{ props: { row } }">
                <template v-if="row.scrapped_at">
                    <div v-if="row.external_links_count === 0">
                        <b-tag v-if="row.response_code == 200" type="is-success" size="is-small">
                            <b-icon icon="check" />
                        </b-tag>
                        <span v-else> - </span>
                    </div>
                    <div v-else>
                        <PartnerExternalLinksModal :external-links-count="row.external_links_count" :url-id="row.id" />
                    </div>
                </template>
                <template v-else> -</template>
            </template>

            <template #forbidden_classesSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small" expanded>
                    <option :value="null">Tous</option>
                    <option :value="true">Oui</option>
                    <option :value="false">Non</option>
                </b-select>
            </template>

            <template #forbidden_classes="{ props: { row } }">
                <template v-if="row.scrapped_at">
                    <b-tag
                        v-if="row.response_code == 200 && row.forbidden_classes.length === 0"
                        type="is-success"
                        size="is-small"
                    >
                        <b-icon icon="check" />
                    </b-tag>
                    <span v-else-if="row.response_code != 200 && row.forbidden_classes.length === 0"> - </span>
                    <b-tag v-else type="is-danger" size="is-small">
                        <b-icon icon="times" />
                    </b-tag>
                </template>
                <template v-else> -</template>
            </template>

            <template #floor_priceSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small" expanded>
                    <option :value="null">Tous</option>
                    <option :value="true">Oui</option>
                    <option :value="false">Non</option>
                </b-select>
            </template>

            <template #floor_price="{ props: { row } }">
                <b-tag :type="isCorrectPrice(row) ? 'is-danger' : 'is-success'" size="is-small">
                    <b-icon :icon="isCorrectPrice(row) ? 'times' : 'check'" />
                    {{ finalPrice(row) | currency }}
                    <span v-if="row.floor_price !== 0 && row.floor_price != null">(Url)</span>
                    <span v-else-if="row.site.prix_minimum_filtre !== 0 && row.site.prix_minimum_filtre != null"
                        >(Global)</span
                    >
                </b-tag>
            </template>

            <template #price="{ props: { row } }">
                <span v-if="row.price !== null">
                    {{ benefit(row) | currency }}
                </span>
                <span v-else> - </span>
            </template>

            <template #scrapped_at="{ props: { row } }">
                <span v-if="row.scrapped_at !== null">
                    <b-tag type="is-success" size="is-small">
                        {{ moment(row.scrapped_at).format('L') }}
                    </b-tag>
                </span>
                <span v-else>
                    <b-tag type="is-danger" size="is-small">
                        <b-icon icon="times" />
                    </b-tag>
                </span>
            </template>

            <template #scrapped_atSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small" expanded>
                    <option :value="null">Tous</option>
                    <option :value="true">Oui</option>
                    <option :value="false">Non</option>
                </b-select>
            </template>

            <template #actions="{ props: { row } }">
                <b-button class="ml-2" type="is-warning" size="is-small" @click="scrapAnUrl(row.id)">
                    <b-icon icon="sync" />
                </b-button>
            </template>
        </NlTable>

        <SiteKeywordsConfirmModal
            ref="confirmModal"
            :checked-rows="checkedRows"
            @uncheckRows="checkedRows = []"
            @bulkBlacklist="bulkUpdateBlacklistUrls"
            @bulkMinimumGain="bulkUpdateFloorPrice"
            @bulkReinitMinimumGain="bulkResetFloorPrice"
            @reset="resetData"
            @resetMinimumGainGlobal="bulkResetFloorPriceSite"
            @displayKeywords="displayKeywords"
        />
    </div>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable.vue'
    import { mapActions } from 'vuex'
    import PartnerKeywordModal from '@/components/PartnerSites/Urls/PartnerKeywordModal.vue'
    import PartnerExternalLinksModal from '@/components/PartnerSites/Urls/PartnerExternalLinksModal.vue'
    import SiteKeywordsConfirmModal from '@/components/PartnerSites/SiteKeywordsConfirmModal.vue'

    export default {
        name: 'PartnerUrlTable',
        components: {
            SiteKeywordsConfirmModal,
            PartnerExternalLinksModal,
            PartnerKeywordModal,
            NlTable
        },
        props: {
            tableUpdate: {
                type: Boolean,
                default: false
            },
            status: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                totalEarn: 0,
                forceUpdate: this.forceUpdate,
                checkedRows: [],
                bulkActionType: 'prix-plancher',
                actions: [
                    {
                        label: this.$t('partenaire-mot-cles.minigaindefine'),
                        action: () => {
                            this.openConfirmModal('prix-plancher')
                        }
                    },
                    {
                        label: this.$t('partenaire-mot-cles.blacklist'),
                        action: () => {
                            this.openConfirmModal('blacklist')
                        }
                    },
                    {
                        label: this.$t('partenaire-mot-cles.reinitminigainurl'),
                        action: () => {
                            this.openConfirmModal('reinitialiser')
                        }
                    },
                    {
                        label: this.$t('partenaire-mot-cles.global-min-gain-define'),
                        action: () => {
                            this.openConfirmModal('global-reset')
                        },
                        allowEmpty: true
                    }
                ],
                buttons: [
                    {
                        label: this.$t('partenaire-mot-cles.global-min-gain-define'),
                        action: () => {
                            this.openConfirmModal('global-reset')
                        }
                    }
                ],
                columns: [
                    {
                        width: 500,
                        centered: false,
                        custom: false,
                        field: 'url',
                        label: 'Url',
                        searchable: true,
                        sortable: false
                    },
                    {
                        centered: false,
                        custom: true,
                        field: 'keywords',
                        label: 'Mots-clés',
                        searchable: false,
                        sortable: true
                    },
                    {
                        centered: false,
                        custom: true,
                        field: 'price',
                        label: 'Gains',
                        searchable: false,
                        sortable: false
                    },
                    {
                        centered: true,
                        custom: true,
                        customSearch: true,
                        field: 'saleable',
                        label: 'Vendable',
                        hide: this.status === 'saleable' || this.status === 'not_saleable',
                        searchable: true,
                        sortable: false
                    },
                    {
                        centered: true,
                        custom: true,
                        customSearch: true,
                        field: 'response_code',
                        label: 'Code réponse',
                        searchable: true,
                        sortable: false
                    },
                    {
                        centered: true,
                        custom: true,
                        customSearch: true,
                        field: 'forbidden_classes',
                        label: 'Classes interdites',
                        searchable: true,
                        sortable: false
                    },
                    {
                        centered: false,
                        custom: true,
                        customSearch: true,
                        field: 'external_links',
                        label: 'Liens externes',
                        searchable: true,
                        sortable: false
                    },
                    {
                        centered: false,
                        custom: true,
                        customSearch: true,
                        field: 'floor_price',
                        label: 'Gains plancher',
                        searchable: true,
                        sortable: false
                    },
                    {
                        centered: true,
                        custom: true,
                        customSearch: true,
                        field: 'scrapped_at',
                        label: 'Date de scrap',
                        searchable: true,
                        sortable: true
                    },
                    {
                        centered: false,
                        custom: true,
                        field: 'actions',
                        label: 'Actions',
                        searchable: false,
                        sortable: false
                    }
                ]
            }
        },
        computed: {
            kpis() {
                return [
                    {
                        label: 'Gains potentiels',
                        value: this.totalEarn + '€'
                    }
                ]
            }
        },
        watch: {
            tableUpdate: function () {
                this.forceUpdate = !this.forceUpdate
            }
        },
        created() {
            this.getUrlsPriceSumByPartnerSite({
                id: this.$route.params.id
            }).then(response => {
                this.totalEarn = response
            })
        },
        methods: {
            ...mapActions('urls', [
                'getUrls',
                'getUrlsByPartnerSite',
                'doScrapAnPartnerUrl',
                'getSeobserver',
                'doScrap',
                'getUrlsPriceSumByPartnerSite'
            ]),

            finalPrice(row) {
                const finalPrice =
                    row.site.prix_minimum_filtre > row.floor_price ? row.site.prix_minimum_filtre : row.floor_price
                return finalPrice === 0 ? null : finalPrice
            },

            benefit(row) {
                if (row.site.prix_minimum_base > row.price) {
                    return (row.site.prix_minimum_base * row.site.commission) / 100
                }

                return row.price
            },

            isCorrectPrice(row) {
                return row.floor_price > this.benefit(row) || row.site.prix_minimum_filtre > this.benefit(row)
            },

            launchSeobserver() {
                this.getSeobserver({ siteId: this.$route.params.id })
                this.forceUpdate = !this.forceUpdate
            },

            launchScrap() {
                this.doScrap({ siteId: this.$route.params.id })
                this.forceUpdate = !this.forceUpdate
            },

            scrapAnUrl(urlId) {
                this.doScrapAnPartnerUrl({
                    site_id: this.$route.params.id,
                    url_id: urlId
                }).then(() => {
                    this.forceUpdate = !this.forceUpdate
                })
            },

            openConfirmModal(action) {
                this.$refs.confirmModal.openModal(action)
            },

            loadAsyncData(params) {
                return this.getUrlsByPartnerSite({
                    site_id: this.$route.params.id,
                    status: this.status,
                    params
                })
            },

            bulkResetFloorPrice(params) {
                this.axios
                    .patch(`/partner/sites/${this.$route.params.id}/reset_floor_price`, {
                        ids: JSON.stringify(params.ids),
                        urls: JSON.stringify(params.urls)
                    })
                    .then(() => {
                        this.snackNotif(this.$t('partenaire-mot-cles.alert-reinit-min-gain'))
                        this.$emit('updateCounts')
                    })
                    .catch(error => {
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => this.resetData())
            },

            bulkUpdateBlacklistUrls(params) {
                this.axios
                    .patch(`/partner/sites/${this.$route.params.id}/blacklist_urls`, {
                        ids: JSON.stringify(params.ids),
                        urls: JSON.stringify(params.urls)
                    })
                    .then(() => {
                        this.snackNotif(this.$t('partenaire-mot-cles.alert-blacklist-urls'))
                        this.$emit('updateCounts')
                    })
                    .catch(error => {
                        this.snackNotif(error.response.data.message, 'is-danger')
                        this.$emit('updateCounts')
                    })
                    .finally(() => this.resetData())
            },

            bulkUpdateFloorPrice({ ids, floor_price }) {
                this.axios
                    .put(`/urls`, {
                        ids,
                        data: { floor_price }
                    })
                    .then(() => {
                        this.snackNotif(this.$t('partenaire-mot-cles.alert-min-gain'))
                        this.$emit('updateCounts')
                    })
                    .catch(error => {
                        this.snackNotif(error.response.data.message, 'is-danger')
                        this.$emit('updateCounts')
                    })
                    .finally(() => this.resetData())
            },

            bulkResetFloorPriceSite() {
                this.axios
                    .patch(`/partner/sites/${this.$route.params.id}/reset_floor_price_site`)
                    .then(() => {
                        this.snackNotif(this.$t('partenaire-mot-cles.alert-reinit-min-gain-global'))
                        this.$emit('updateCounts')
                    })
                    .catch(error => {
                        this.snackNotif(error.response.data.message, 'is-danger')
                        this.$emit('updateCounts')
                    })
                    .finally(() => this.resetData())
            },

            displayKeywords(keywords) {
                this.modalKeywords = keywords
                this.showModal = true
            },

            async resetData() {
                this.forceUpdate = !this.forceUpdate
                await this.getCounts()
            }
        }
    }
</script>
