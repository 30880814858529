<template>
    <div>
        <b-tooltip label="Supprimer">
            <b-button
                class="rounded button is-danger is-light rounded-lg text-decoration-none mr-1"
                :loading="isLoading"
                @click.prevent="confirm"
            >
                <b-icon icon="times"/>
            </b-button>
        </b-tooltip>
    </div>
</template>

<script>

import {mapActions} from "vuex";

export default {
    name: 'RemoveEmployeeButton',
    components: {},
    props: {
        target: {
            type: Object,
            required: true
        },
    },
    data: () => ({
        isLoading: false,
    }),
    methods: {
        ...mapActions('consultants', ['removeUserFromAgency']),
        confirm() {
            this.$buefy.dialog.confirm({
                message: 'Voulez-vous retirer l\'utilisateur de l\'agence ?',
                onConfirm: () => this.doDelete()
            })
        },
        doDelete() {
            this.isLoading = true
            this.removeUserFromAgency(this.target.user_id).then(() => {
                this.$emit('reload')
            }).catch((e) => {
                this.$store.dispatch('toastError', e)
            }).finally(() => {
                this.isLoading = false
            })
        }
    }
}
</script>
