<template>
    <nl-modal ref="modal" title="Acheter des RD" :add-legacy-events="false" position="center" size="md">
        <div class="d-flex justify-content-center flex-column">
            <h5>Nombre de RD souhaité</h5>
            <div class="d-flex justify-content-center">
                <b-button class="mx-2" :class="spots == 5 ? 'is-warning' : 'is-primary'" @click="setSpots(5)">
                    5
                </b-button>
                <b-button class="mx-2" :class="spots == 10 ? 'is-warning' : 'is-primary'" @click="setSpots(10)">
                    10
                </b-button>
                <b-button class="mx-2" :class="spots == 20 ? 'is-warning' : 'is-primary'" @click="setSpots(20)">
                    20
                </b-button>
                <b-button
                    class="mx-2"
                    :class="customSpots ? 'is-warning' : 'is-primary'"
                    @click="
                        () => {
                            customSpots = !customSpots
                        }
                    "
                >
                    Custom
                </b-button>
                <b-input v-if="customSpots" v-model="spots" class="mx-2" type="number" min="20" max="1000" />
            </div>
            <p v-if="rdTooLow" class="text-center is-danger">Merci de saisir un nombre de RD supérieur à 20</p>
        </div>
        <template #footer>
            <b-button type="is-primary" @click="hide"> Annuler</b-button>
        </template>
    </nl-modal>
</template>

<script>
    export default {
        name: 'BuySpotsModal',
        data() {
            return {
                showModal: true,
                site: null,
                customSpots: false,
                spots: 5
            }
        },
        computed: {
            rdTooLow() {
                return this.spots < 20 && this.customSpots
            }
        },
        methods: {
            show(row) {
                this.site = row
                this.$refs.modal.show()
            },
            hide() {
                this.$refs.modal.hide()
            },
            setSpots(spots) {
                this.customSpots = false
                this.spots = spots
            }
        }
    }
</script>

<style scoped></style>
