<template>
    <b-button
        class="mb-2 text-decoration-none"
        tag="router-link"
        icon-pack="fas"
        :to="to"
        :type="type"
        :size="size"
        :icon-left="iconLeft"
    >
        <span v-if="label !== null">{{ label }}</span>
    </b-button>
</template>

<script>
    export default {
        name: 'ButtonRouterTo',
        props: {
            to: {
                type: String,
                required: true
            },
            label: {
                type: String,
                default: null
            },
            type: {
                type: String,
                default: 'is-light'
            },
            size: {
                type: String,
                default: null
            },
            iconLeft: {
                type: String,
                default: null
            }
        }
    }
</script>
