<template>
    <nl-modal ref="modal" title="Modifier les titres" :add-legacy-events="false" position="center" size="md">
        <p><b>Nombre de commandes :</b> {{ ids.length }}</p>
        <div class="d-flex justify-content-between" style="gap: 1rem">
            <div class="w-50">
                <b-field label="H1" class="min-width-input">
                    <b-input v-model="tagTitles" rows="15" type="textarea" placeholder="Titre" />
                </b-field>
                <p><b>Nombre de titres :</b> {{ tagTitles.split('\n').length }}</p>
            </div>
            <div class="w-50">
                <b-field label="Meta Title">
                    <b-input v-model="metaTitles" rows="15" type="textarea" placeholder="Meta Title" />
                </b-field>
                <p><b>Nombre de titres :</b> {{ metaTitles.split('\n').length }}</p>
            </div>
        </div>
        <template #footer>
            <b-button type="is-primary" @click="hide"> Annuler</b-button>
            <b-button type="is-warning" @click="onSave"> Sauvegarder</b-button>
        </template>
    </nl-modal>
</template>

<script>
    export default {
        name: 'BulkTitleModal',
        data() {
            return {
                ids: [],
                tagTitles: '',
                metaTitles: ''
            }
        },
        computed: {
            computedIds() {
                let tag_titles = this.tagTitles.split('\n')
                let meta_titles = this.metaTitles.split('\n')
                return this.ids.map((id, index) => {
                    return {
                        id: id.id,
                        tag_title: tag_titles[index],
                        meta_title: meta_titles[index]
                    }
                })
            }
        },
        methods: {
            show(ids) {
                this.ids = ids
                this.$refs.modal.show()
            },
            hide() {
                this.$refs.modal.hide()
                this.ids = []
                this.tagTitles = ''
                this.metaTitles = ''
            },
            onSave() {
                let data = this.computedIds
                this.$emit('saveTitles', data)
                this.hide()
            }
        }
    }
</script>

<style scoped>
    .min-width-input {
        min-height: 150px;
    }
</style>
