<template>
    <modal
        v-model="active"
        modal-title="Ajouter une alerte"
        @cancel="$emit('update:active', false)"
        @save="handleSubmit"
    >
        <template #default>
            <form>
                <b-field :label="$t('global.keyword')">
                    <b-input v-model="value.keyword" :placeholder="$t('global.keyword')" expanded required />
                </b-field>
                <b-field :label="$t('language.title')">
                    <b-select v-model="value.lang" :placeholder="$t('language.title')" expanded required>
                        <option v-for="(langue, index) in languages" :key="index" :value="langue.id">
                            {{ langue.text }}
                        </option>
                    </b-select>
                </b-field>
                <b-field :label="$t('global.recipient')">
                    <b-input v-model="value.recipient" :placeholder="$t('global.recipient')" expanded required />
                </b-field>
                <b-field :label="$t('alertes.searchquantity')">
                    <b-select v-model="value.volume" :placeholder="$t('alertes.searchquantity')" expanded required>
                        <option
                            v-for="(volume, index) in volumes"
                            :key="index"
                            :value="volume.id"
                            :selected="volume.selected"
                        >
                            {{ volume.text }}
                        </option>
                    </b-select>
                </b-field>
                <b-field label="Top 10">
                    <b-select v-model="value.top10" placeholder="Top 10" expanded required>
                        <option v-for="(top, index) in top10" :key="index" :value="top.id">
                            {{ top.text }}
                        </option>
                    </b-select>
                </b-field>
                <b-field label="Top 100">
                    <b-select v-model="value.top100" placeholder="Top 100" expanded required>
                        <option v-for="(top, index) in top100" :key="index" :value="top.id">
                            {{ top.text }}
                        </option>
                    </b-select>
                </b-field>
                <b-field :label="$t('global.maxprice')">
                    <b-select v-model="value.price" :placeholder="$t('global.maxprice')" expanded required>
                        <option v-for="(price, index) in pricing" :key="index" :value="price.id">
                            {{ price.text }}
                        </option>
                    </b-select>
                </b-field>
            </form>
        </template>
    </modal>
</template>

<script>
    import Modal from '@/components/UI/Modal/Modal.vue'
    import { mapActions } from 'vuex'

    export default {
        components: {
            Modal
        },
        props: {
            active: {
                default: false,
                type: Boolean,
                required: false
            },
            callBack: {
                type: Function,
                required: false,
                default: null
            },
            value: {
                type: Object
            }
        },
        data() {
            return {
                languages: [
                    { text: 'Français', id: 'fr_fr' },
                    { text: 'Anglais', id: 'com_en' }
                ],
                volumes: [
                    { text: 'Tous les volumes', id: 0 },
                    { text: 'Minimum 50', id: 50 },
                    { text: 'Minimum 100', id: 100 },
                    { text: 'Minimum 500', id: 500 },
                    { text: 'Minimum 1000', id: 1000 },
                    { text: 'Minimum 3000', id: 3000 },
                    { text: 'Minimum 5000', id: 5000 },
                    { text: 'Minimum 10000', id: 10000 }
                ],
                top10: [
                    { text: 'Tous les tops 10', id: 0 },
                    { text: 'Minimum 10', id: 10 },
                    { text: 'Minimum 30', id: 30 },
                    { text: 'Minimum 50', id: 50 }
                ],
                top100: [
                    { text: 'Tous les tops 100', id: 0 },
                    { text: 'Minimum 10', id: 10 },
                    { text: 'Minimum 30', id: 30 },
                    { text: 'Minimum 50', id: 50 }
                ],
                pricing: [
                    { text: 'Tous les prix', id: 0 },
                    { text: '40 €', id: 40 },
                    { text: '80 €', id: 80 },
                    { text: '120 €', id: 120 },
                    { text: '150 €', id: 150 },
                    { text: '180 €', id: 180 },
                    { text: '200 €', id: 200 },
                    { text: '250 €', id: 250 },
                    { text: '300 €', id: 300 },
                    { text: '350 €', id: 350 },
                    { text: '400 €', id: 400 },
                    { text: '450 €', id: 450 },
                    { text: '500 €', id: 500 },
                    { text: '550 €', id: 550 }
                ]
            }
        },
        methods: {
            ...mapActions('alert', {
                createAlert: 'create'
            }),
            handleSubmit() {
                if (!this.callBack) {
                    this.createAlert({
                        ...this.value,
                        destinataire: this.value.recipient,
                        pricing: this.value.price
                    }).then(() => {
                        this.showModal = false
                        this.modalData = {}
                    })
                } else {
                    this.callBack()
                }
            }
        }
    }
</script>
