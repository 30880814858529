import { render, staticRenderFns } from "./TrueFalseBadge.vue?vue&type=template&id=3715bcf8&scoped=true&"
import script from "./TrueFalseBadge.vue?vue&type=script&lang=js&"
export * from "./TrueFalseBadge.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3715bcf8",
  null
  
)

export default component.exports