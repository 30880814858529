<template>
    <div>
        <label v-for="item in items" :key="item.value" class="customRadio">
            <span>{{ item.text }}</span>
            <input
                v-model="input"
                type="radio"
                :name="name"
                :value="item.value"
                :checked="input == item.value ? true : false"
            />
            <span class="checkmark" />
        </label>
    </div>
</template>

<script>
    export default {
        props: {
            items: {
                type: Array,
                required: true
            },
            value: {
                type: [String, Number],
                default: ''
            },
            name: {
                type: String,
                required: true
            }
        },
        computed: {
            input: {
                get() {
                    return this.value
                },
                set(value) {
                    return this.$emit('input', value)
                }
            }
        }
    }
</script>

<style lang="scss">
    .customRadio {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin-top: 20px;

        & input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        & span {
            position: relative;
            left: 10px;
            font-size: 15px;
        }

        & .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 36px;
            width: 34px;
            background-color: #fff;
            border-radius: 10px;
            border: 1px solid #3c3c3b;
            margin-right: 10px;
        }

        &:hover input ~ .checkmark {
            border-color: #9de3d4;
        }

        & input:checked ~ .checkmark {
            background-color: #77cfbe;
            border-color: #77cfbe;
        }

        & .checkmark:after {
            content: '';
            position: absolute;
            display: none;
        }

        & input:checked ~ .checkmark:after {
            display: block;
        }

        & .checkmark:after {
            left: 13px;
            top: 8px;
            width: 8px;
            height: 13px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
</style>
