<template>
    <div class="columns">
        <div class="column is-narrow">
            <button class="button is-rounded">
                <span v-if="loader">
                    <i class="fas fa-circle-notch fa-spin" />
                </span>
                <span v-else>
                    {{ number }}
                </span>
            </button>
        </div>
        <div class="column is-align-self-center">
            <h4 class="subtitle">
                {{ label }}
            </h4>
        </div>
    </div>
</template>
<style scoped>
    .subtitle {
        color: #f9bc14;

        margin-bottom: 0;
        font-size: 0.85rem;
        font-family: Poppins, sans-serif;
    }

    .column .columns {
        margin-left: 0.5em;
    }
</style>
<script>
    export default {
        name: 'ConfigMailHeader',
        props: {
            label: {
                required: true,
                type: String
            },
            number: {
                required: true,
                type: Number
            },
            loader: {
                required: false,
                type: Boolean
            }
        }
    }
</script>
