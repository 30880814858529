<template>
    <nl-modal ref="assignModal" title="Assigner un rédacteur" size="xl" class="assign-modal">
        <div class="container-fluid container assign-redactor">
            <h5>Choisir un rédacteur dans la liste</h5>

            <b-table
                :data="redactors.data"
                :default-sort="[config.sort, config.sortDirection]"
                :loading="loading"
                :mobile-cards="true"
                :per-page="redactors.per_page"
                :striped="true"
                :total="redactors.total"
                backend-filtering
                backend-pagination
                backend-sorting
                paginated
                @sort="onSort"
                @page-change="onPageChange"
                @filters-change="onFilterChange"
            >
                <b-table-column cell-class="align-middle" field="id" label="Id">
                    <div slot="default" slot-scope="props">
                        <p><span class="font-weight-bold">Id : </span>{{ props.row.id }}</p>
                        <p><span class="font-weight-bold">User id : </span>{{ props.row.user_id }}</p>
                    </div>
                </b-table-column>

                <b-table-column :label="$t('redactor.redactors')" cell-class="align-middle" field="user">
                    <div slot="default" slot-scope="props">
                        <p>
                            <a :href="`/redactor/details?id=${props.row.user.id}`"
                                >{{ props.row.user.firstname }} {{ props.row.user.lastname }}</a
                            >
                            <LoginAs :user-id="props.row.user.id" class="ml-auto" />
                        </p>
                        <p>
                            <span class="text-muted">{{ props.row.user.email }}</span>
                        </p>
                    </div>
                </b-table-column>

                <b-table-column
                    v-slot="props"
                    cell-class="align-middle"
                    centered
                    field="prices"
                    label="Langue rédaction"
                >
                    <span v-if="props.row.prices && props.row.prices.length">
                        <div v-for="price in props.row.prices" :key="price.id">
                            <span class="mr-4">
                                <flag
                                    :iso="
                                        price.lang.startsWith('com') || price.lang.startsWith('en')
                                            ? 'us'
                                            : price.lang.substr(0, 2)
                                    "
                                />
                            </span>
                            {{ price.price | toCents }}€ / 100 mots
                        </div>
                    </span>
                </b-table-column>

                <b-table-column v-slot="props" cell-class="align-middle" centered label="Action">
                    <button class="btn btn-secondary" @click="doAssignRedactor(props.row)">Selectionner</button>
                </b-table-column>
            </b-table>
        </div>
    </nl-modal>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'AssignRedactorModal',

        data() {
            return {
                assignRedactorModal: false,
                redactor: null,
                loading: false
            }
        },

        computed: {
            ...mapState('redactors', {
                redactors: state => state.redactors,
                config: state => state.redactorsConfig
            })
        },

        methods: {
            ...mapActions('redactors', ['assignRedactor', 'getRedactorDashboard']),
            show() {
                this.$refs.assignModal.show()
            },
            hide() {
                this.$refs.assignModal.hide()
                this.redactor = null
            },
            doAssignRedactor(redactor) {
                this.$emit('redactor:selected', redactor)
            },
            onSort(field, order) {
                this.config.sort = field
                this.config.sortDirection = order

                this.loadAsyncData()
            },
            onFilterChange(filter) {
                var filters = {}
                Object.keys(filter).forEach(element => {
                    filters[element] = filter[element]
                })
                this.config.filters = filters

                this.loadAsyncData()
            },
            onPageChange(page) {
                this.config.page = page

                this.loadAsyncData()
            },

            loadAsyncData() {
                this.loading = true
                this.getRedactorDashboard().then(() => {
                    this.loading = false
                })
            }
        }
    }
</script>

<style scoped></style>
