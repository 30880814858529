<template>
    <div class="box">
        <nl-table async :columns="columns" :get-data="getInvoices">
            <template #invoiceIdFacturation="{ props }">
                <div v-if="props.row.quotationIdFacturation">
                    <a class="btn btn-warning" @click="getPDF(props.row.id, 'quote')">
                        <i class="fas fa-download" /> {{ $t('factures.quotation') }}
                    </a>
                </div>

                <div v-if="props.row.invoiceIdFacturation && props.row.typeFacturationPro === 'invoice'">
                    <a class="btn btn-success" @click="getPDF(props.row.id, 'invoice')">
                        <i class="fas fa-download" /> {{ $t('factures.invoice') }}
                    </a>
                </div>

                <div v-if="props.row.refundInvoiceIdFacturation">
                    <a class="btn btn-danger" @click="getPDF(props.row.id, 'invoice')">
                        <i class="fas fa-download" /> Avoir
                    </a>
                </div>
            </template>
        </nl-table>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import PerPageSelector from '@/components/UI/Table/PerPageSelector'
    import NlTable from '@/components/UI/Table/NlTable'
    import Download from '@/utils/download'

    export default {
        name: 'TabInvoices',
        components: {
            PerPageSelector,
            NlTable
        },
        data: function () {
            return {
                columns: [
                    {
                        field: 'id',
                        label: 'ID',
                        searchable: true,
                        sortable: true,
                        type: 'number'
                    },
                    {
                        field: 'created_at',
                        label: this.$t('factures.date'),
                        type: 'date',
                        searchable: true,
                        sortable: true,
                        fieldOption: {
                            dateFormat: 'medium'
                        }
                    },
                    {
                        field: 'price_ht',
                        label: this.$t('factures.amounHT'),
                        searchable: true,
                        sortable: true
                    },
                    {
                        field: 'invoiceIdFacturation',
                        label: this.$t('factures.invoice'),
                        custom: true
                    }
                ],

                preloading: true,
                api_url: process.env.VUE_APP_LARAVEL_URL
            }
        },
        methods: {
            ...mapActions('invoices', {
                getInvoices: 'list'
            }),
            getPDF(id, type) {
                this.axios
                    .get('/orders/' + id + '/download/' + type, {
                        responseType: 'arraybuffer',
                        headers: { Accept: 'application/octet-stream' }
                    })
                    .then(response => {
                        Download.download(response, id + '-' + type + '.pdf')
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        }
    }
</script>
