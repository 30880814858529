<template>
    <div class="">
        <div v-if="tabInfo.alert && tabInfo.alert.length" class="mb-2">
            <b-notification
                type="is-warning"
                aria-close-label="Close notification"
                role="alert"
                :message="tabInfo.alert"
            />
        </div>

        <div class="d-flex justify-content-end mt-1" :class="tabInfo.type === 'deleted' ? 'mt-3' : ''">
            <div class="d-flex">
                <b-select
                    v-model="perPage"
                    icon="align-center"
                    :disabled="loading"
                    :loading="loading"
                    @input="loadAsyncData"
                >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                </b-select>

                <TableColumnSelect v-model="columns" />

                <form
                    v-if="tabInfo.type === 'all' || tabInfo.type === 'toBeAccepted' || tabInfo.type === 'toBeTreated'"
                    class="ml-2 d-flex"
                >
                    <b-field>
                        <b-select v-model="bulkActionType" :placeholder="$t('global.actions')">
                            <option
                                v-if="tabInfo.type === 'all' || tabInfo.type === 'toBeAccepted'"
                                value="acceptorder"
                            >
                                {{ $t('partenaire-commandes.acceptorder') }}
                            </option>
                            <option
                                v-if="tabInfo.type === 'all' || tabInfo.type === 'toBeAccepted'"
                                value="refuseorder"
                            >
                                {{ $t('partenaire-commandes.Irefuseorder') }}
                            </option>
                            <option v-if="tabInfo.type === 'all' || tabInfo.type === 'toBeTreated'" value="redactionNL">
                                {{ $t('partenaire-commandes.redactionNL') }}
                            </option>
                            <option v-if="tabInfo.type === 'all' || tabInfo.type === 'toBeTreated'" value="published">
                                {{ $t('partenaire-commandes.published') }}
                            </option>
                            <option
                                v-if="tabInfo.type === 'all' || tabInfo.type === 'toBeAccepted'"
                                value="acceptRedactionNL"
                            >
                                {{ $t('partenaire-commandes.acceptredactionNL') }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-button
                        :disabled="!checkedRows.length || bulkActionType === null"
                        class="mb-2 mb-md-0 ml-md-2"
                        type="is-warning"
                        @click="openConfirmModal"
                    >
                        {{ $t('global.modify') }}
                    </b-button>
                </form>
                <button class="button is-warning ml-2" @click="emitOpenModal()">
                    <i class="fas fa-file-excel mr-2" />Export
                </button>
            </div>
        </div>

        <b-table
            :data="orders.data"
            :mobile-cards="true"
            :debounce-search="400"
            default-sort-direction="desc"
            :striped="true"
            :loading="loading"
            :paginated="true"
            class="my-table"
            :default-sort="[sortField, sortOrder]"
            :per-page="orders.per_page"
            :total="orders.total"
            backend-sorting
            backend-pagination
            backend-filtering
            pagination-rounded
            :checked-rows.sync="checkedRows"
            :checkable="tabInfo.type === 'all' || tabInfo.type === 'toBeAccepted' || tabInfo.type === 'toBeTreated'"
            :is-row-checkable="row => row.statut === 'a_valider' || (row.statut === 'en_cours' && !row.site.wp_data)"
            checkbox-position="right"
            @page-change="onPageChange"
            @sort="onSort"
            @filters-change="onFilterChange"
        >
            <b-table-column
                centered
                field="id"
                width="1em"
                sortable
                :searchable="isSearchable"
                :label="columns[0].label"
                :visible="columns[0].visible"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">#{{ props.row.id }}</div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.ref')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="created_at"
                width="1em"
                sortable
                :searchable="isSearchable"
                :label="columns[1].label"
                :visible="columns[1].visible"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    {{ moment(props.row.created_at).format('DD/MM/YYYY') }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="date"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.time.date')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                v-if="tabInfo.type === 'all'"
                centered
                field="statut"
                width="1em"
                sortable
                :searchable="isSearchable"
                :label="columns[2].label"
                :visible="columns[2].visible"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <PartnerOrdersStatus :order="props.row" />
                    <span v-if="props.row.sold_nl == 1">Contenu NL</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option :value="undefined">
                                {{ $t('precommande.all') }}
                            </option>
                            <option value="en_cours">
                                {{ $t('status.running') }}
                            </option>
                            <option value="a_valider">
                                {{ $t('status.attentevalidation') }}
                            </option>
                            <option value="refuse">
                                {{ $t('status.refused-s') }}
                            </option>
                            <option value="termine">
                                {{ $t('status.finished') }}
                            </option>
                            <option value="annule">
                                {{ $t('status.canceled') }}
                            </option>
                            <option value="expire">
                                {{ $t('status.expired') }}
                            </option>
                        </select>
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="url"
                sortable
                :searchable="isSearchable"
                :label="columns[3].label"
                :visible="columns[3].visible"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ props.row.url }}</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.page')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="link_url"
                sortable
                :searchable="isSearchable"
                :label="columns[4].label"
                :visible="columns[4].visible"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <p class="mb-0 max-width-25">
                        <span>{{ props.row.link_url }}</span>
                    </p>
                    <span v-if="props.row.link_second_url" class="max-width-25">
                        <hr />
                        <span>{{ props.row.link_second_url }}</span>
                    </span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('links&anchors.wantedlinks')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="link_anchor"
                sortable
                :searchable="isSearchable"
                :label="columns[5].label"
                :visible="columns[5].visible"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <p class="mb-0 max-width-25">
                        {{ props.row.link_anchor }}
                    </p>
                    <span v-if="props.row.link_second_anchor" class="max-width-25">
                        <hr />
                        {{ props.row.link_second_anchor }}
                    </span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('links&anchors.wantedanchors')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                v-if="tabInfo.type === 'toBeTreated'"
                centered
                field="link_status"
                width="1em"
                sortable
                :searchable="isSearchable"
                :label="$t('global.status')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <PartnerOrdersStatusToBeTreated :order="props.row" />
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.status')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                v-if="tabInfo.type !== 'refusedCanceled'"
                centered
                field="redaction"
                :label="columns[6].label"
                :visible="columns[6].visible"
                cell-class="align-middle"
                width="10%"
            >
                <div slot="default" slot-scope="props">
                    <p v-if="props.row.statut !== 'refuse' && props.row.statut !== 'annule'" class="mb-0">
                        <span v-if="props.row.redaction !== 'nextlevel'"
                            >{{ 200 + parseInt(props.row.option_mots) }} {{ $t('partenaire-commandes.wordsyou') }}</span
                        >

                        <span v-else>
                            <span v-if="props.row.contenu && !props.row.has_content_in_review">
                                <a
                                    class="button is-small is-light"
                                    :href="'/partner/orders/details?id=' + props.row.id"
                                    >{{ $t('partenaire-commandes.seecontent') }}</a
                                >
                            </span>

                            <span v-else>
                                {{ 200 + parseInt(props.row.option_mots) }}
                                {{
                                    props.row.statut === 'a_valider'
                                        ? $t('partenaire-commandes.wordnextlevel')
                                        : $t('partenaire-commandes.wordsnextlevelongoing')
                                }}
                            </span>
                        </span>
                    </p>
                </div>
            </b-table-column>

            <b-table-column
                v-if="tabInfo.type !== 'refusedCanceled'"
                centered
                field="date_publication_prevue"
                sortable
                :searchable="isSearchable"
                :label="columns[7].label"
                :visible="columns[7].visible"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <p v-if="props.row.statut !== 'refuse' && props.row.statut !== 'annule'" class="mb-0">
                        {{
                            props.row.date_publication_prevue
                                ? moment(props.row.date_publication_prevue).format('DD/MM/YYYY')
                                : 'ASAP'
                        }}
                    </p>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('partenaire-commandes.wantedpublication')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="contenu_date_envoi"
                sortable
                :searchable="isSearchable"
                :label="columns[8].label"
                :visible="columns[8].visible"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <p class="mb-0">
                        {{
                            props.row.contenu_date_envoi
                                ? moment(props.row.contenu_date_envoi).format('DD/MM/YYYY')
                                : '-'
                        }}
                    </p>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="date"
                            class="form-control form-control-sm"
                            :placeholder="$t('partenaire-commandes.sending-date')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                v-if="tabInfo.type === 'terminateWithErrors'"
                centered
                field="link_status_message"
                sortable
                :searchable="isSearchable"
                :label="columns[9].label"
                :visible="columns[9].visible"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <b-tooltip :triggers="['click']" :auto-close="['outside', 'escape']" multilined>
                        <template v-slot:content>
                            <p v-if="props.row.link_status_message" class="mb-0">
                                {{
                                    props.row.link_status_message == 'anchor_not_found' ||
                                    props.row.link_status_message == 'link_not_found'
                                        ? $t('crawler.exceptions.' + props.row.link_status_message)
                                        : props.row.link_status_message
                                }}
                            </p>
                            <p v-if="props.row.link_second_status_message" class="mb-0">
                                {{
                                    props.row.link_second_status_message == 'anchor_not_found' ||
                                    props.row.link_second_status_message == 'link_not_found'
                                        ? $t('crawler.exceptions.' + props.row.link_second_status_message)
                                        : props.row.link_second_status_message
                                }}
                            </p>
                            <p v-if="props.row.verification_autres_liens_externes" class="mb-0">
                                {{ $t('partenaire-commandes.extlinks') }} -
                                {{ props.row.verification_autres_liens_externes }}
                            </p>
                            <p
                                v-if="
                                    props.row.verification_code_reponse_http &&
                                    props.row.verification_code_reponse_http != 200
                                "
                                class="mb-0"
                            >
                                {{ $t('partenaire-commandes.response-code') }} :
                                {{ props.row.verification_code_reponse_http }}
                            </p>
                        </template>
                        <b-button :label="$t('global.show')" type="is-danger" size="is-small" />
                    </b-tooltip>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.errors')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                v-if="tabInfo.type !== 'refusedCanceled'"
                centered
                field="price_paid"
                sortable
                :searchable="isSearchable"
                :label="columns[10].label"
                :visible="columns[10].visible"
                cell-class="align-middle"
            >
                <template slot="header">
                    <b-tooltip :label="$t('partenaire-commandes.gains')" dashed>
                        {{ $t('global.earnings') }}
                    </b-tooltip>
                </template>
                <div slot="default" slot-scope="props">{{ props.row.earning.toFixed(2) }}€</div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.earnings')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                v-if="tabInfo.type !== 'refusedCanceled'"
                :label="columns[11].label"
                :visible="columns[11].visible"
                cell-class="align-middle"
                centered
                width="10em"
            >
                <div slot="default" slot-scope="props">
                    <div class="buttons justify-content-center">
                        <a
                            v-if="props.row.statut !== 'annule' && props.row.statut !== 'refuse'"
                            :href="'/partner/orders/details?id=' + props.row.id"
                            class="rounded button px-2 is-light rounded-lg text-decoration-none"
                            :title="$t('partenaire-commandes.seedetails')"
                        >
                            <i class="fas fa-eye" />
                        </a>
                        <button
                            v-if="
                                props.row.statut === 'termine' &&
                                isTerminateWithErrors(props.row) &&
                                tabInfo.type === 'all'
                            "
                            class="rounded button px-2 is-light is-danger rounded-lg text-decoration-none"
                            :title="$t('partenaire-commandes.see-error')"
                            @click="$emit('goToTab', 4)"
                        >
                            <i class="fas fa-exclamation-triangle" />
                        </button>
                        <button
                            v-if="
                                props.row.statut === 'termine' &&
                                isTerminateWithErrors(props.row) &&
                                tabInfo.type === 'terminateWithErrors'
                            "
                            class="rounded button px-2 is-light is-danger rounded-lg text-decoration-none"
                            :title="$t('partenaire-commandes.relancecheck')"
                            @click="relaunchCheck(props.row)"
                        >
                            <i class="fas fa-undo-alt" />
                        </button>
                        <button
                            v-if="props.row.statut === 'a_valider'"
                            class="rounded button px-2 is-light is-success rounded-lg text-decoration-none"
                            :title="$t('partenaire-commandes.acceptorder')"
                            @click="
                                bulkActionType = 'acceptorder'
                                checkedRows = [props.row]
                                openConfirmModal()
                            "
                        >
                            <i class="far fa-check-circle" />
                        </button>
                        <button
                            v-if="props.row.statut === 'a_valider'"
                            class="rounded button px-2 is-light is-danger rounded-lg text-decoration-none"
                            :title="$t('partenaire-commandes.Irefuseorder')"
                            @click="
                                bulkActionType = 'refuseorder'
                                checkedRows = [props.row]
                                openConfirmModal()
                            "
                        >
                            <i class="far fa-times-circle" />
                        </button>
                        <button
                            v-if="
                                props.row.statut === 'en_cours' &&
                                (props.row.redaction === 'proprietaire' ||
                                    (props.row.redaction === 'nextlevel' && props.row.contenu_date_envoi))
                            "
                            class="rounded button px-2 is-success rounded-lg text-decoration-none"
                            :title="$t('partenaire-commandes.published')"
                            @click="
                                bulkActionType = 'published'
                                checkedRows = [props.row]
                                openConfirmModal()
                            "
                        >
                            <i class="fas fa-clipboard-check" />
                        </button>
                        <button
                            v-if="props.row.statut === 'en_cours' && props.row.redaction === 'proprietaire'"
                            class="rounded button px-2 is-info rounded-lg text-decoration-none"
                            :title="$t('partenaire-commandes.redactionNL')"
                            @click="
                                bulkActionType = 'redactionNL'
                                checkedRows = [props.row]
                                openConfirmModal()
                            "
                        >
                            <i class="fas fa-pencil-alt" />
                        </button>
                        <button
                            v-if="props.row.statut === 'a_valider' && props.row.redaction === 'proprietaire'"
                            class="rounded button px-2 is-info is-light rounded-lg text-decoration-none"
                            :title="$t('partenaire-commandes.acceptredactionNL')"
                            @click="
                                bulkActionType = 'acceptRedactionNL'
                                checkedRows = [props.row]
                                openConfirmModal()
                            "
                        >
                            <i class="fas fa-pencil-alt" />
                        </button>
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                v-if="tabInfo.type === 'refusedCanceled'"
                centered
                field="motif_refus_partenaire"
                sortable
                :searchable="isSearchable"
                :label="$t('global.reason')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <b-tooltip
                        v-if="props.row.motif_refus_partenaire"
                        :triggers="['click']"
                        :auto-close="['outside', 'escape']"
                    >
                        <template v-slot:content>
                            <span v-if="props.row.motif_refus_partenaire !== 'autre'">{{
                                props.row.motif_refus_partenaire
                            }}</span>
                            <span v-else>{{ props.row.motif_refus_partenaire_detail }}</span>
                        </template>
                        <b-button :label="$t('global.show')" type="is-dark" size="is-small" />
                    </b-tooltip>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.reason')"
                        />
                    </div>
                </div>
            </b-table-column>
        </b-table>

        <PartnerOrdersConfirmModal
            ref="confirmModal"
            :checked-rows="checkedRows"
            :current-type="bulkActionType"
            @uncheckRows="
                checkedRows = []
                bulkActionType = null
            "
        />

        <PartnerOrdersCheckModal v-if="tabInfo.type === 'terminateWithErrors'" ref="relaunchCheckModal" />
    </div>
</template>

<script>
    import TableColumnSelect from '@/components/UI/Table/TableColumnSelect'

    export default {
        name: 'PartnerOrdersTable',
        components: { TableColumnSelect },
        props: ['tabInfo', 'groups'],

        data() {
            return {
                orders: [],
                isSearchable: true,
                bulkActionType: null,
                checkedRows: [],
                loading: false,
                perPage: 25,

                sortField: 'created_at',
                sortOrder: 'desc',
                filters: '',
                page: 1,

                isMobileWidth: window.innerWidth < 768,

                columns: [
                    {
                        label: this.$t('global.ref')
                    },
                    {
                        label: this.$t('global.time.date')
                    },
                    {
                        label: this.$t('global.status')
                    },
                    {
                        label: this.$t('global.page')
                    },
                    {
                        label: this.$t('links&anchors.wantedlinks')
                    },
                    {
                        label: this.$t('links&anchors.wantedanchors')
                    },
                    {
                        label: this.$t('global.content')
                    },
                    {
                        label: this.$t('partenaire-commandes.wantedpublication')
                    },
                    {
                        label: this.$t('partenaire-commandes.sending-date')
                    },
                    {
                        label: this.$t('global.errors')
                    },
                    {
                        label: this.$t('global.earnings')
                    },
                    {
                        label: this.$t('global.details')
                    }
                ]
            }
        },
        watch: {
            tabInfo: {
                handler(newValue) {
                    this.orders = newValue.content

                    if (this.loading) {
                        this.loading = false
                    }
                },
                deep: true
            }
        },

        created() {
            this.orders = this.tabInfo.content
        },

        methods: {
            openConfirmModal() {
                this.$refs.confirmModal.openModal(this.bulkActionType)
            },

            relaunchCheck(order) {
                this.$refs.relaunchCheckModal.openModal(order)
            },

            isTerminateWithErrors(order) {
                if (
                    order.statut === 'termine' &&
                    order.link_status == 'ok' &&
                    (!order.link_second_anchor || order.link_second_status == 'ok') &&
                    !order.verification_autres_liens_externes &&
                    (order.verification_code_reponse_http == null || order.verification_code_reponse_http == 200)
                ) {
                    return false
                }

                var twoYearsAgo = this.moment().subtract(2, 'years').add(7, 'days')
                var isExpired = order.date_publication_prevue
                    ? twoYearsAgo.isAfter(this.moment(order.date_publication_prevue))
                    : twoYearsAgo.isAfter(this.moment(order.created_at))

                if (isExpired) {
                    return false
                }

                return true
            },

            /** Table filtering, sorting and pagination methods */
            loadAsyncData() {
                this.loading = true

                if (this.orders.path === undefined) {
                    this.orders.path = '/partner-orders'
                }

                this.$emit('loadAsyncData', {
                    page: this.page,
                    sortField: this.sortField,
                    sortOrder: this.sortOrder,
                    filters: this.filters,
                    includes: '',
                    path: this.orders.path,
                    index: this.indexType,
                    per_page: this.perPage,

                    callback() {
                        this.loading = false
                    }
                })
            },

            emitOpenModal() {
                this.$emit('openExportModal')
            },

            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order

                this.loadAsyncData()
            },

            onFilterChange(filter) {
                var filters = []
                Object.keys(filter).forEach(element => {
                    var currentFilter = {
                        key: element,
                        value: filter[element]
                    }
                    filters.push(currentFilter)
                })
                this.filters = filters
                this.loadAsyncData()
            },

            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            }
        }
    }
</script>

<style scoped>
    .-mt-1 {
        margin-top: -0.5em;
    }

    .media-content p.text {
        margin-bottom: 0;
    }

    .th-wrap {
        justify-content: center;
    }

    .max-width-25 {
        max-width: 25em;
        word-break: break-word;
    }
</style>
