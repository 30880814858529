<template>
    <ValidationObserver v-if="campaign.information" v-slot="{ invalid }">
        <form class="" @submit.prevent="submit">
            <nl-modal title="edit" :open-modal="show" @hide="$emit('discard')">
                <ValidationProvider v-slot="{ errors }" tag="div" name="Url" rules="required|url" class="form-group">
                    <label for="frm_properties_url">url</label>
                    <input
                        id="frm_properties_url"
                        v-model="campaign.information.url"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length > 0 }"
                    />
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </ValidationProvider>

                <ValidationProvider
                    v-if="!campaign.meta.status.includes(campaign.information.status)"
                    v-slot="{ errors }"
                    tag="div"
                    rules="required|numeric"
                    class="form-group"
                >
                    <label for="frm_properties_budget">budget</label>
                    <div class="input-group mb-3">
                        <input
                            id="frm_properties_budget"
                            v-model.number="campaign.information.budget_mensuel"
                            type="number"
                            class="form-control"
                            :class="{ 'is-invalid': errors.length > 0 }"
                        />
                        <div class="input-group-append">
                            <span class="input-group-text">€</span>
                        </div>
                    </div>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </ValidationProvider>

                <ValidationProvider
                    v-if="!campaign.meta.status.includes(campaign.information.status)"
                    v-slot="{ errors }"
                    tag="div"
                    class="form-group"
                >
                    <label for="frm_properties_start_at">Date de Début</label>
                    <flat-pickr
                        id="frm_properties_start_at"
                        v-model="campaign.information.start_at"
                        :config="datePickrConfig"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length > 0 }"
                    />
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </ValidationProvider>

                <ValidationProvider
                    v-if="!campaign.meta.status.includes(campaign.information.status)"
                    v-slot="{ errors }"
                    tag="div"
                    rules="required"
                    class="form-group"
                >
                    <label for="frm_properties_duration">Durée</label>
                    <select
                        id="frm_properties_duration"
                        v-model="campaign.information.duration"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length > 0 }"
                    >
                        <option :value="null" selected>-</option>
                        <option v-for="(duration, index) in campaign.meta.durations" :key="index" :value="duration">
                            {{ duration === 12 ? '1 an' : duration === 24 ? '2 ans' : `${duration} mois` }}
                        </option>
                    </select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </ValidationProvider>

                <template v-slot:footer>
                    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-secondary" :disabled="invalid">Save</button>
                </template>
            </nl-modal>
        </form>
    </ValidationObserver>
</template>

<script>
    import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
    import { numeric, required } from 'vee-validate/dist/rules'
    import validationUrl from '../../utils/validationUrl'
    import NlModal from '../UI/NlModal'
    import flatPickr from 'vue-flatpickr-component'
    import { French } from 'flatpickr/dist/l10n/fr.js'
    import 'flatpickr/dist/flatpickr.css'

    import { mapActions } from 'vuex'

    extend('required', required)
    extend('numeric', numeric)
    extend('url', validationUrl)

    export default {
        name: 'PropertiesForm',
        components: {
            ValidationObserver,
            ValidationProvider,
            NlModal,
            flatPickr
        },
        props: {
            show: {
                type: Boolean,
                required: true
            },
            campaign: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                datePickrConfig: {
                    locale: French,
                    altFormat: 'd/m/Y',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    disableMobile: 'true'
                }
            }
        },
        methods: {
            ...mapActions('campaigns', ['updateCampaign']),
            submit() {
                this.updateCampaign({
                    campaignId: this.campaign.id,
                    url: this.campaign.information.url,
                    budget_mensuel: this.campaign.information.budget_mensuel,
                    debut_accompagnement: this.campaign.information.start_at,
                    duree: this.campaign.information.duration
                })
                    .then(() => {
                        this.$emit('discard')
                    })
                    .catch(e => {
                        console.error(e)
                    })
            }
        }
    }
</script>

<style scoped></style>
