<template>
    <div>
        <add-user @saved="reload" />
        <NlTable :columns.sync="columns" :get-data="getUsers" :left-buttons="buttons" :kpis="kpis">
            <template #role="{ props: { row } }">
                <span>
                    {{ row.role }}
                </span>
            </template>

            <template #roleSearch="{ props }">
                <select class="form-control form-control-sm" v-model="props.filters[props.column.field]">
                    <option :value="props.filters['role']" selected disabled hidden>Rôle</option>
                    <option value="">Tous</option>
                    <option value="admin">Admin</option>
                    <option value="partenaire">Partenaire</option>
                    <option value="prospect">Prospect</option>
                    <option value="consultant">Consultant</option>
                    <option value="agence">Agence</option>
                    <option value="manager">Manager</option>
                    <option value="administratif">Administratif</option>
                    <option value="redacteur">Rédacteur</option>
                </select>
            </template>

            <template #date_inscription="{ props: { row } }">
                <span>
                    {{ moment(row.date_inscription).format('DD/MM/YYYY') }}
                </span>
            </template>

            <template #credit_amount="{ props: { row } }">
                <span>
                    {{ row.credit_amount | toCurrency }}
                </span>
            </template>

            <template #gains="{ props: { row } }">
                <span>
                    {{ row.gains | toCurrency }}
                </span>
            </template>

            <template #date_inscriptionSearch="{ props }">
                <input
                    type="date"
                    class="form-control form-control-sm"
                    placeholder="JJ/MM/AAAA"
                    v-model="props.filters[props.column.field]"
                />
            </template>

            <template #hasAnActiveAccompagnementSearch="{ props }">
                <select class="form-control form-control-sm" v-model="props.filters[props.column.field]">
                    <option :value="props.filters['rolhasAnActiveAccompagnemente']" selected disabled hidden>
                        Accompagnement
                    </option>
                    <option value="">-</option>
                    <option value="1">Oui</option>
                    <option value="0">Non</option>
                </select>
            </template>

            <template #user="{ props: { row } }">
                <router-link :to="'/admin/users/details?id=' + row.id">
                    {{ row.user }}
                </router-link>
                <LoginAs :user-id="row.id" />
            </template>
            <template #hasAnActiveAccompagnement="{ props: { row } }">
                <TrueFalseBadge :value="!!row.hasAnActiveAccompagnement" />
            </template>
        </NlTable>
    </div>
</template>

<script>
    import TrueFalseBadge from '@/components/UI/TrueFalseBadge.vue'
    import NlTable from '@/components/UI/Table/NlTable.vue'
    import { mapActions } from 'vuex'
    import { EventManager } from '@/components/EventManager'
    import AddUser from '@/components/Admin/User/AddUser.vue'

    export default {
        name: 'AdminUsersTable',
        components: { AddUser, NlTable, TrueFalseBadge },
        data() {
            return {
                columns: [
                    {
                        custom: false,
                        field: 'id',
                        searchable: true,
                        sortable: true,
                        label: 'ID',
                        visible: true
                    },
                    {
                        custom: true,
                        field: 'role',
                        searchable: true,
                        sortable: true,
                        customSearch: true,
                        label: 'Role'
                    },
                    {
                        custom: false,
                        field: 'firstname',
                        center: false,
                        searchable: true,
                        sortable: true,
                        label: 'Prénom'
                    },
                    {
                        custom: false,
                        field: 'lastname',
                        searchable: true,
                        sortable: true,
                        label: 'Nom'
                    },
                    {
                        custom: true,
                        field: 'user',
                        searchable: true,
                        sortable: true,
                        label: 'Email'
                    },
                    {
                        custom: true,
                        field: 'credit_amount',
                        searchable: false,
                        sortable: true,
                        label: 'Crédits'
                    },
                    {
                        custom: true,
                        field: 'gains',
                        searchable: false,
                        sortable: true,
                        label: 'Gains'
                    },
                    {
                        custom: true,
                        field: 'hasAnActiveAccompagnement',
                        searchable: true,
                        sortable: false,
                        customSearch: true,
                        label: 'Accompagnement'
                    },
                    {
                        custom: true,
                        field: 'date_inscription',
                        customSearch: true,
                        searchable: true,
                        sortable: true,
                        label: 'Inscription'
                    },
                    {
                        custom: false,
                        field: 'telephone',
                        searchable: false,
                        sortable: false,
                        visible: false,
                        label: 'Téléphone'
                    },
                    {
                        custom: false,
                        field: 'skype',
                        searchable: false,
                        sortable: false,
                        visible: false,
                        label: 'Skype'
                    }
                ],
                buttons: [
                    {
                        label: 'Créer un utilisateur',
                        icon: 'plus-circle',
                        type: 'is-warning',
                        action: this.addUser
                    }
                ],
                kpis: [
                    {
                        label: 'Crédits',
                        value: 0
                    },
                    {
                        label: 'Gains',
                        value: 0
                    }
                ],
                forceUpdate: 1
            }
        },
        methods: {
            ...mapActions('users', {
                list: 'list'
            }),
            addUser() {
                EventManager.$emit('onAddUser')
            },
            reload(data) {
                this.fireToaster(data)
                this.forceUpdate = !this.forceUpdate
            },
            getUsers(params) {
                return new Promise(resolve => {
                    this.list(params).then(response => {
                        console.log(response)
                        this.kpis[0].value = this.$options.filters.toCurrency(response.kpis.credits)
                        this.kpis[1].value = this.$options.filters.toCurrency(response.kpis.earnings)
                        resolve(response.users)
                    })
                })
            }
        }
    }
</script>

<style scoped>
    ::v-deep .tags:last-child {
        margin-bottom: 0;
    }

    ::v-deep .tags:not(:last-child) {
        margin-bottom: 0;
    }
</style>
