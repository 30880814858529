<template>
    <div>
        <div style="position: relative; display: flex; align-items: center; width: max-content; padding: 10px 0">
            <b-taglist attached class="m-0">
                <b-tag type="is-warning" size="is-medium" class="m-0"> Keywords count</b-tag>
                <b-tag type="is-dark" size="is-medium" class="m-0">
                    {{ keywordsCount | toThousands }}
                </b-tag>
            </b-taglist>
            <b-button
                size="is-small"
                type="is-warning"
                icon-right="sync-alt"
                class="ml-1"
                @click="loadStat('keywords-count')"
            />
            <b-loading v-model="isLoading['keywords-count']" size="is-small" :is-full-page="false" />
        </div>
        <div class="columns">
            <div class="column">
                <div class="box">
                    <h3>
                        <span>Volumes</span>
                        <b-button
                            size="is-small"
                            type="is-warning"
                            icon-right="sync-alt"
                            class="ml-1"
                            @click="loadStat('keywords-per-volume')"
                        />
                    </h3>
                    <b-table :data="volumes" :loading="isLoading['keywords-per-volume']" :narrowed="true">
                        <b-table-column field="volume" label="Volume">
                            <template v-slot="{ row }">
                                {{ row.from }}
                                {{ isNaN(row.to) ? ' ' + row.to : ' - ' + row.to }}
                            </template>
                        </b-table-column>

                        <b-table-column field="count" label="Count">
                            <template v-slot="{ row }">
                                {{ row.count | toThousands }}
                            </template>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
            <div class="column">
                <div class="box">
                    <h3>
                        <span>Tags</span>
                        <b-button
                            size="is-small"
                            type="is-warning"
                            icon-right="sync-alt"
                            class="ml-1"
                            @click="loadAggregate('keywords-per-tag')"
                        />
                    </h3>
                    <b-table :data="tags" :loading="isLoading['keywords-per-tag']" :narrowed="true">
                        <b-table-column label="Tag">
                            <template v-slot="{ row }">
                                {{ tagRef[row.stored_keyword_tag_id] }}
                            </template>
                        </b-table-column>

                        <b-table-column field="count" label="Count">
                            <template v-slot="{ row }">
                                {{ row.total | toThousands }}
                            </template>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
            <div class="column">
                <div class="box">
                    <h3>
                        <span>Priorités</span>
                        <b-button
                            size="is-small"
                            type="is-warning"
                            icon-right="sync-alt"
                            class="ml-1"
                            @click="loadAggregate('priority')"
                        />
                    </h3>
                    <b-table :data="priorities" :loading="isLoading['priority']" :narrowed="true">
                        <b-table-column label="Priorité">
                            <template v-slot="{ row }">
                                {{ priorityRef[row.is_hot] }}
                            </template>
                        </b-table-column>

                        <b-table-column field="count" label="Count">
                            <template v-slot="{ row }">
                                {{ row.total | toThousands }}
                            </template>
                        </b-table-column>
                    </b-table>
                </div>
                <div class="box">
                    <h3>
                        <span>Statuts</span>
                        <b-button
                            size="is-small"
                            type="is-warning"
                            icon-right="sync-alt"
                            class="ml-1"
                            @click="loadAggregate('statuses')"
                        />
                    </h3>
                    <b-table :data="statuses" :loading="isLoading['statuses']" :narrowed="true">
                        <b-table-column label="Statut">
                            <template v-slot="{ row }">
                                {{ statusRef[row.stored_keyword_status_id] }}
                            </template>
                        </b-table-column>

                        <b-table-column field="count" label="Count">
                            <template v-slot="{ row }">
                                {{ row.total | toThousands }}
                            </template>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
            <!--            <div class="col-6">-->
            <!--                <div class="box">-->
            <!--                    <h2>Keywords per scrap date range</h2>-->
            <!--                    <b-table-->
            <!--                        :data="scrapDates"-->
            <!--                        :loading="isLoading['keywords-per-scrap-date']"-->
            <!--                    >-->
            <!--                        <b-table-column field="date" label="Date">-->
            <!--                            <template v-slot="{row}">-->
            <!--                                {{row.range}}-->
            <!--                            </template>-->
            <!--                        </b-table-column>-->

            <!--                        <b-table-column field="count" label="Count">-->
            <!--                            <template v-slot="{row}">-->
            <!--                                {{ row.count | toThousands }}-->
            <!--                            </template>-->
            <!--                        </b-table-column>-->
            <!--                    </b-table>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        data: () => ({
            tagRef: {
                1: 'Anglais',
                2: 'Caractères interdits',
                3: 'URL',
                4: 'Mal encodé',
                5: 'Marque',
                6: 'Court 2',
                7: 'Court 3',
                8: 'Mono mot',
                9: 'Question',
                10: 'Affiliation',
                11: 'Caractères spéciaux',
                12: 'Date 1900',
                13: 'Date début 2000',
                14: 'Date courante',
                15: 'Date fin 2000',
                16: 'Numérique',
                17: '4 mots',
                18: '5 mots',
                19: '6 mots',
                20: '7 mots',
                21: '8 mots',
                22: '9 à 11 mots',
                23: '12 à 16 mots',
                24: 'Plus de 16 mots'
            },
            priorityRef: {
                0: 'Non prioritaire',
                1: 'Prioritaire'
            },
            statusRef: {
                1: 'Actif',
                4: 'Nouveau'
            },
            volumes: [],
            scrapDates: [],
            tags: [],
            priorities: [],
            statuses: [],
            keywordsCount: 0,
            isLoading: {
                'keywords-per-volume': false,
                'keywords-count': false,
                'keywords-per-scrap-date': false,
                'keywords-per-tag': false,
                priority: false,
                statuses: false
            }
        }),
        mounted() {
            this.init()
        },
        methods: {
            ...mapActions('keyword', {
                getKeywordStats: 'getStats',
                getAggregate: 'getAggregate'
            }),
            init() {
                this.loadStat('keywords-per-volume')
                this.loadStat('keywords-count')
                // this.loadStat('keywords-per-scrap-date')
                this.loadAggregate('keywords-per-tag')
                this.loadAggregate('priority')
                this.loadAggregate('statuses')
            },
            loadStat(endpoint) {
                this.isLoading[endpoint] = true
                this.getKeywordStats(endpoint)
                    .then(data => {
                        if (data.volumes) this.volumes = data.volumes
                        if (data.count) this.keywordsCount = data.count
                        // if(data.scrapDates) this.scrapDates = data.scrapDates
                        // if(data.tags) this.tags = data.tags
                    })
                    .finally(() => (this.isLoading[endpoint] = false))
            },
            loadAggregate(type) {
                this.isLoading[type] = true

                this.getAggregate(type)
                    .then(data => {
                        if (type === 'keywords-per-tag') this.tags = data
                        if (type === 'priority') this.priorities = data
                        if (type === 'statuses') this.statuses = data
                    })
                    .finally(() => (this.isLoading[type] = false))
            }
        }
    }
</script>

<style scoped>
    .columns > .column > .box > h3 {
        display: flex;
        justify-content: space-between;
    }
</style>
