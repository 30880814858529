<template>
    <div class="p-2">
        <div class="d-flex align-items-center justify-content-end">
            <input
                v-model="config.per_page"
                class="pl-75 form-control"
                style="width: 10%"
                type="number"
                @keypress.enter="loadAsyncData"
            />
            <p class="control ml-2 mt-2 mb-2">
                {{ $t('global.per-page') }}
            </p>
        </div>

        <b-table
            :data="redactors.data"
            :default-sort="[config.sort, config.sortDirection]"
            :loading="loading"
            :mobile-cards="true"
            :per-page="redactors.per_page"
            :sticky-header="true"
            :striped="true"
            :total="redactors.total"
            backend-filtering
            backend-pagination
            backend-sorting
            paginated
            @sort="onSort"
            @page-change="onPageChange"
            @filters-change="onFilterChange"
        >
            <b-table-column cell-class="align-middle" field="id" label="Id" header-class="tabRedactorIdColumn">
                <div slot="default" slot-scope="props">
                    <span class="font-weight-bold">Id : </span>{{ props.row.id }}<br />
                    <span class="font-weight-bold">User id : </span>{{ props.row.user_id }}
                </div>
            </b-table-column>

            <b-table-column :label="$t('redactor.redactors')" cell-class="align-middle" field="user">
                <div slot="default" slot-scope="props">
                    <p>
                        <router-link :to="`/redactor/details?id=${props.row.user.id}`">
                            {{ props.row.user.firstname }}
                            {{ props.row.user.lastname }}
                        </router-link>
                        <LoginAs :user-id="props.row.user.id" class="ml-auto" />
                    </p>
                    <p>
                        <span class="text-muted">{{ props.row.user.email }}</span>
                    </p>
                </div>
            </b-table-column>

            <b-table-column v-slot="props" cell-class="align-middle" label="Type de rédaction" width="0">
                <div v-if="props.row.orders" class="tag is-warning mb-2">Commande</div>
                <div v-if="props.row.preorders" class="tag is-info">Précommande</div>
            </b-table-column>

            <b-table-column cell-class="align-middle" centered field="in_progress" label="En cours" width="0">
                <div slot="default" slot-scope="props" class="text-center">
                    <div v-if="props.row.entity_id">
                        <span v-if="props.row.entity_type === 1">Content</span>
                        <span v-else>Preorder</span>
                        <span class="tag"> #{{ props.row.entity_id }} </span>

                        <button
                            v-if="props.row.content_id"
                            class="button tag is-small ml-2 is-light"
                            title="Retirer de la commande"
                            @click.prevent="unassignOrderModal(props.row, props.index)"
                        >
                            <b-icon icon="times" size="is-small" />
                        </button>
                    </div>

                    <span v-else>-</span>
                </div>
            </b-table-column>

            <b-table-column
                v-slot="props"
                cell-class="align-middle"
                centered
                field="prices"
                label="Langue rédaction"
                header-class="tabRedactorRedactionColumn"
            >
                <span v-if="props.row.prices && props.row.prices.length">
                    <div v-for="price in props.row.prices" :key="price.id">
                        <span class="mr-4">
                            <flag
                                :iso="
                                    price.lang.startsWith('com') || price.lang.startsWith('en')
                                        ? 'us'
                                        : price.lang.substr(0, 2)
                                "
                            />
                        </span>
                        {{ price.price | toCents }}€ / 100 mots
                    </div>
                </span>
            </b-table-column>

            <b-table-column
                :label="$t('redactor.nbWordsWriter')"
                cell-class="align-middle "
                centered
                field="word_count"
                sortable
            >
                <div slot="default" slot-scope="props">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="d-flex align-items-center">
                                <div class="col">
                                    <span v-if="props.row.word_count">{{ props.row.word_count }}</span>
                                    <span v-else>0</span>
                                </div>
                                <div class="col">
                                    <vue-easy-pie-chart
                                        :percent="getPercent(props.row.written_words_today, props.row.word_count)"
                                        :size="75"
                                        bar-color="#F9BC14"
                                        font-size=".95rem"
                                        scale-color="#a0a0a0"
                                        track-color="#c0c0c0"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            Taux de redaction hebdo :
                            <div class="progress">
                                <b-progress
                                    v-if="props.row.word_count > 0"
                                    :value="
                                        parseInt((props.row.written_words_weekly / (7 * props.row.word_count)) * 100)
                                    "
                                    format="percent"
                                    show-value
                                    type="is-warning"
                                />
                                <b-progress v-else :value="0" format="percent" show-value />
                            </div>
                        </div>
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                v-slot="props"
                cell-class="align-middle"
                field="review_percent"
                label="Relecture"
                numeric
                width="1"
            >
                <span
                    :class="
                        props.row.review_percent < 50 ? '' : props.row.review_percent > 99 ? 'is-danger' : 'is-warning'
                    "
                    class="font-weight-bold tag is-large"
                >
                    {{ props.row.review_percent }}%
                </span>
            </b-table-column>

            <b-table-column
                v-slot="props"
                :label="$t('redactor.refusals')"
                cell-class="align-middle text-right"
                field="refusals"
                numeric
            >
                <div v-if="props.row.blacklisted_subjects && props.row.blacklisted_subjects.length > 0">
                    <span v-for="refusal in props.row.blacklisted_subjects" :key="refusal.id" class="m-1 tag">
                        {{ refusal.localized_translations.name }}
                    </span>
                </div>
                <div v-else>
                    {{ $t('redactor.no-info') }}
                </div>
            </b-table-column>
        </b-table>

        <RedactorConfirmModal ref="confirmModal" />
    </div>
</template>

<style>
    .tabRedactorIdColumn {
        min-width: 140px;
    }

    .tabRedactorRedactionColumn {
        min-width: 200px;
    }
</style>

<script>
    import VueEasyPieChart from 'vue-easy-pie-chart'
    import 'vue-easy-pie-chart/dist/vue-easy-pie-chart.css'

    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'TabRedactors',
        components: {
            VueEasyPieChart
        },
        props: ['groups'],
        data: function () {
            return {
                isSearchable: false,
                search_field: '',
                loading: false,

                langs: [
                    {
                        code: 'fr_fr',
                        name: 'Français'
                    },
                    {
                        code: 'com_en',
                        name: 'English'
                    }
                ]
            }
        },
        computed: {
            ...mapState('redactors', {
                redactors: state => state.redactors,
                config: state => state.redactorsConfig
            })
        },
        methods: {
            ...mapActions('redactors', ['getRedactorDashboard', 'unassignWriting']),
            unassignOrderModal(redactor, index) {
                this.$buefy.dialog.confirm({
                    title: `Êtes-vous sûr ?`,
                    message: `Souhaitez-vous vraiment retirer la commande à ${redactor.user.firstname} ${redactor.user.lastname} ?`,
                    confirmText: 'Confirmer',
                    cancelText: 'Annuler',
                    type: 'is-danger',
                    onConfirm: () => this.doUnassignOrder(redactor, index)
                })
            },

            doUnassignOrder(redactor, index) {
                this.loading = true
                this.unassignWriting({ id: redactor.writing_id })
                    .then(() => {
                        var redactor = this.redactors.data[index]
                        redactor.content_id = null
                        redactor.writing_id = null

                        this.redactors.data.splice(index, 1, redactor)
                    })
                    .catch(e => {
                        this.$store.dispatch('toast', {
                            type: 'error',
                            title: 'Une erreur est survenue',
                            message: e.response.message
                        })
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },

            save: function (redactor) {
                this.savingSelected = true
                this.$emit('save', redactor)
            },
            loadAsyncData() {
                this.loading = true
                this.getRedactorDashboard().then(() => {
                    this.loading = false
                })
            },
            onSort(field, order) {
                this.config.sort = field
                this.config.sortDirection = order

                this.loadAsyncData()
            },
            onFilterChange(filter) {
                var filters = {}
                Object.keys(filter).forEach(element => {
                    filters[element] = filter[element]
                })
                this.config.filters = filters

                this.loadAsyncData()
            },
            onPageChange(page) {
                this.config.page = page

                this.loadAsyncData()
            },

            getPercent(words_written_today, word_count) {
                let result = parseInt(((words_written_today * 100) / word_count).toFixed(2))

                return result <= 0 || isNaN(result) ? 0 : result
            },

            openConfirmModal(order) {
                this.$refs.confirmModal.openModal('unassigned-redactors', order)
            }
        },
        mounted() {}
    }
</script>
