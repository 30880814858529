export default () => ({
    reports: null,
    reportsConfig: {
        include: '',
        sort: 'orders_count',
        sortDirection: 'desc',
        page: 1,
        filters: {
            is_partner: ''
        },
        per_page: 20
    },

    date_from: null,
    date_to: null
})
