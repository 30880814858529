<template>
    <div>
        <b-tag v-if="newValue == 'urgent' && !editable" type="is-danger mx-2" rounded @click="editable = !editable">
            {{ newValue.charAt(0).toUpperCase() + newValue.slice(1) }}
        </b-tag>
        <b-tag
            v-else-if="newValue == 'en cours' && !editable"
            type="is-warning mx-2"
            rounded
            @click="editable = !editable"
        >
            {{ newValue.charAt(0).toUpperCase() + newValue.slice(1) }}
        </b-tag>
        <b-tag
            v-else-if="newValue == 'suivi' && !editable"
            type="is-success mx-2"
            rounded
            @click="editable = !editable"
        >
            {{ newValue.charAt(0).toUpperCase() + newValue.slice(1) }}
        </b-tag>
        <div v-else-if="newValue != null" class="select">
            <select v-model="newValue" @change="inputBlur($event.target)">
                <option value="urgent">Urgent</option>
                <option value="en cours">En cours</option>
                <option value="suivi">Suivi</option>
            </select>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TagEditable',
        props: {
            initValue: null,
            noteId: 0
        },
        data() {
            return {
                editable: false,
                newValue: ''
            }
        },
        watch: {
            initValue: function (newVal, oldVal) {
                this.newValue = newVal
            }
        },
        created() {
            this.newValue = this.initValue
        },
        methods: {
            inputBlur() {
                this.editable = !this.editable
                this.$emit('input-blur', { id: this.noteId, value: this.newValue })
            }
        }
    }
</script>
