<template>
    <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
        <template>
            <header class="px-4 py-3 has-background-warning shadow-sm">
                <h4 class="h4 p-0 mb-0">Export</h4>
            </header>
            <div class="card-content">
                <div class="content d-flex flex-column">
                    <b-loading v-model="isLoading" :is-full-page="false" />

                    <form v-if="!isExported" class="mx-auto my-4">
                        <p>
                            {{ $t('partenaire-commandes.choose-format') }}
                        </p>

                        <div class="form-group">
                            <select id="format" v-model="formatFile" class="form-control" name="format">
                                <option value="csv">CSV</option>
                                <option value="xlsx">XLSX</option>
                            </select>
                        </div>

                        <div class="p-2 has-background-link-light mb-3">
                            <div class="form-check form-check-inline">
                                <input
                                    id="currentMonth"
                                    v-model="period"
                                    class="form-check-input"
                                    type="radio"
                                    name="period"
                                    :value="0"
                                />
                                <label class="form-check-label" for="currentMonth">Mois en cours</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    id="lastMonth"
                                    v-model="period"
                                    class="form-check-input"
                                    type="radio"
                                    name="period"
                                    :value="1"
                                />
                                <label class="form-check-label" for="lastMonth">Mois précédent</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    id="currentYear"
                                    v-model="period"
                                    class="form-check-input"
                                    type="radio"
                                    name="period"
                                    :value="2"
                                />
                                <label class="form-check-label" for="currentYear">Année en cours</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    id="lastYear"
                                    v-model="period"
                                    class="form-check-input"
                                    type="radio"
                                    name="period"
                                    :value="3"
                                />
                                <label class="form-check-label" for="lastYear">Année précédente</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    id="custom"
                                    v-model="period"
                                    class="form-check-input"
                                    type="radio"
                                    name="period"
                                    :value="4"
                                />
                                <label class="form-check-label" for="custom">Custom</label>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="form-group col-12 col-md-6">
                                <label for="date_from">{{ $t('redactor.from') }}</label>
                                <input
                                    id="date_from"
                                    v-model="config.filters['date_from']"
                                    type="date"
                                    class="form-control"
                                    required
                                    :disabled="period !== 4"
                                />
                            </div>
                            <div class="form-group col-12 col-md-6">
                                <label for="date_to">{{ $t('redactor.to') }}</label>
                                <input
                                    id="date_to"
                                    v-model="config.filters['date_to']"
                                    type="date"
                                    class="form-control"
                                    required
                                    :disabled="period !== 4"
                                />
                            </div>
                            <div class="col-12">
                                <small v-if="errorMessage" class="text-danger">{{ errorMessage }}</small>
                            </div>
                        </div>

                        <button class="button is-warning" @click.prevent="doExport">
                            {{ $t('partenaire-commandes.get-data') }}
                        </button>
                    </form>

                    <div v-else-if="!isLoading && isExported" class="mx-auto my-4">
                        <button class="button is-warning" @click="download">
                            {{ $t('global.download') }}
                        </button>
                    </div>

                    <div class="buttons justify-content-end">
                        <button class="button is-light" @click.prevent="closeAndResetModal">
                            {{ $t('global.close') }}
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'ExportOrdersModal',
        data() {
            return {
                modalIsOpen: false,
                isLoading: false,

                isExported: false,
                file: null,
                formatFile: 'csv',

                errorMessage: null,

                period: 0
            }
        },
        watch: {
            period: function (value) {
                switch (value) {
                    case 0:
                        this.config.filters['date_from'] = this.moment().startOf('month').format('YYYY-MM-DD')
                        this.config.filters['date_to'] = this.moment().format('YYYY-MM-DD')
                        break

                    case 1:
                        this.config.filters['date_from'] = this.moment()
                            .subtract(1, 'month')
                            .startOf('month')
                            .format('YYYY-MM-DD')
                        this.config.filters['date_to'] = this.moment()
                            .subtract(1, 'month')
                            .endOf('month')
                            .format('YYYY-MM-DD')
                        break

                    case 2:
                        this.config.filters['date_from'] = this.moment().startOf('year').format('YYYY-MM-DD')
                        this.config.filters['date_to'] = this.moment().format('YYYY-MM-DD')
                        break

                    case 3:
                        this.config.filters['date_from'] = this.moment()
                            .subtract(1, 'year')
                            .startOf('year')
                            .format('YYYY-MM-DD')
                        this.config.filters['date_to'] = this.moment()
                            .subtract(1, 'year')
                            .endOf('year')
                            .format('YYYY-MM-DD')
                        break
                }
            }
        },

        created() {
            this.config.filters['date_from'] = this.moment().startOf('month').format('YYYY-MM-DD')
            this.config.filters['date_to'] = this.moment().format('YYYY-MM-DD')
        },

        computed: {
            ...mapState('contents', {
                config: 'contentsConfig'
            })
        },

        methods: {
            ...mapActions('contents', ['exportAgencyOrders']),

            doExport() {
                let payload = {
                    agency_id: this.$store.state.consultants.agency.id,
                    client_id: this.$store.state.consultants.agencyClient.id,
                    ext: this.formatFile
                }

                this.exportAgencyOrders(payload)
                    .then(response => {
                        const url = URL.createObjectURL(
                            new Blob([response.data], {
                                type: 'application/octet-stream'
                            })
                        )
                        const link = document.createElement('a')
                        link.href = url
                        link.download = 'commandes.' + this.formatFile
                        document.body.appendChild(link)
                        link.click()
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {})
            },

            show() {
                this.modalIsOpen = true
            },

            closeAndResetModal() {
                this.modalIsOpen = false
                this.isLoading = false
                this.isExported = false
                this.file = null
            },

            s2ab(s) {
                var buf = new ArrayBuffer(s.length)
                var view = new Uint8Array(buf)
                for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
                return buf
            }
        }
    }
</script>

<style>
    label[for^='scrape_'] {
        color: #363636;
        display: block;
        font-size: 1rem;
        font-weight: 700;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
