import { ROLES } from '@/config/roles'

const metaGroup = { roles: [ROLES.AGENCY, ROLES.MANAGER, ROLES.CONSULTANT] }
export default [
    {
        path: '/agencies',
        alias: '/agencies',
        name: 'Agency',
        components: {
            default: () => import('../views/Agency/Agency.vue')
        },
        meta: metaGroup
    },
    {
        path: '/agency/managers',
        name: 'Managers',
        components: {
            default: () => import('../views/Agency/Managers.vue')
        },
        meta: metaGroup
    },
    {
        path: '/agency/consultants',
        name: 'Consultants',
        components: {
            default: () => import('../views/Agency/Consultants.vue')
        },
        meta: metaGroup
    },
    {
        path: '/agency/client',
        name: 'AgencyClientDetails',
        components: {
            default: () => import('../views/Agency/AgencyClientDetails.vue')
        },
        meta: metaGroup
    }
]
