<template>
    <li class="nav-item dropdown m-0">
        <a
            href="#"
            class="nav-link dropdown-toggle p-0"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
        >
            <i
                v-if="$store.state.auth.fromAdmin"
                class="far fas fa-user-check mr-1"
                style="font-size: 1.5rem; color: #2b383f"
            />
            <i v-else class="far fa-user mr-1" style="font-size: 1.5rem; color: #2b383f" />
            <span style="color: #2b383f; font-weight: bold; font-size: 1rem">{{ user.firstname }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
            <router-link to="/profile" class="dropdown-item">
                <b-icon icon="user" />
                {{ $t('layout.profil') }}
            </router-link>
            <router-link to="/invoices" class="dropdown-item">
                <b-icon icon="file-invoice" />
                {{ $t('factures.title') }}
            </router-link>

            <router-link
                v-if="
                    ['prospect', 'agence', 'partenaire', 'consultant', 'admin'].includes(user.role) &&
                    (isAdminMenuOpen || !isPartnerMenuOpen)
                "
                to="/credits"
                class="dropdown-item"
            >
                <b-icon icon="euro-sign" />
                {{ $t('mes-credits.title') }}
            </router-link>

            <router-link
                v-if="
                    ['prospect', 'agence', 'partenaire', 'consultant', 'admin'].includes(user.role) &&
                    (isAdminMenuOpen || isPartnerMenuOpen)
                "
                to="/partner/earnings"
                class="dropdown-item"
            >
                <b-icon icon="piggy-bank" />
                {{ $t('global.earnings') }}
            </router-link>

            <a href="#" class="dropdown-item" @click.prevent="logout">
                <b-icon icon="sign-out-alt" />
                {{ $t('global.disconnect') }}
            </a>
        </div>
    </li>
</template>

<style scoped>
    .dropdown-toggle::after {
        content: none;
    }
</style>

<script>
    import { mapActions, mapState } from 'vuex'
    import NlStorage from "@/services/nl.storage";

    export default {
        name: 'UserMenu',
        computed: {
            ...mapState('auth', {
                user: state => state.user,
                fromAdmin: state => state.fromAdmin
            }),
            ...mapState({
                isPartnerMenuOpen: 'isPartnerMenuOpen',
                isAdminMenuOpen: 'isAdminMenuOpen'
            })
        },

        methods: {
            ...mapActions('auth', ['checkRole']),
            logout() {
                NlStorage.clear()
                this.$router.go()
            }
        }
    }
</script>
