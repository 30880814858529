<template>
    <nl-modal
        ref="modal"
        title="Affecter un utilisateur à une agence"
        :add-legacy-events="false"
        position="right"
        size="lg"
    >
        <template v-slot:header>
            <div class="bg-antracite text-white w-100 text-center p-4">
                <h1>Affecter à une agence</h1>
            </div>
        </template>

        <div>
            <AffectUserToAgencyForm />
            <div class="text-center">
                <button class="btn-action btn-third mt-2 mb-2 mb-xl-4" :disabled="loading" @click.prevent="save">
                    <i v-if="loading" class="fas fa-circle-notch fa-spin mr-1" />
                    Affecter
                </button>
            </div>
        </div>

        <template v-slot:footer>
            <div class="d-flex w-100 justify-content-start">
                <b-button type="button" class="btn btn-danger" @click="$refs.modal.hide()"> Annuler</b-button>
            </div>
        </template>
    </nl-modal>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import AgencyModal from './AgencyModal'
    import CreateClientForm from '../Form/CreateClientForm'

    export default {
        name: 'AffectUserToAgencyModal',
        components: {
            CreateClientForm
        },
        mixins: [AgencyModal],
        data: () => ({
            loading: false
        }),
        computed: {
            ...mapState('consultants', {
                user: state => state.newUser
            })
        },
        methods: {
            ...mapActions('consultants', ['affectUserToAgency']),
            save() {
                this.loading = true

                let params = this.user

                if (this.user.user) {
                    params.user_id = this.user.user.id

                    delete params.user
                }

                this.affectUserToAgency(params)
                    .then(() => {
                        this.hide()
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
        mounted() {}
    }
</script>
