<template>
    <div>
        <div v-if="isLoading" style="height: 200px">
            <b-loading v-model="isLoading" :is-full-page="false" />
        </div>
        <div v-else>
            <div class="d-flex justify-content-end">
                <div v-if="budgets.length" class="d-flex">
                    <b-button outlined icon-left="fa fa-undo-alt" type="is-danger" @click="reset">
                        Réinitialiser
                    </b-button>
                    <budget-extend />
                </div>
            </div>
            <div class="card-body p-0 w-100">
                <b-table
                    v-if="budgets.length"
                    striped
                    :data="budgets"
                    :mobile-cards="true"
                    :loading="isLoadingTable"
                    :paginated="false"
                    :checked-rows.sync="checkedRows"
                    :checkable="true"
                    :custom-is-checked="(props, checkedRow) => props.id === checkedRow.id"
                    class="w-100 overflow-x-auto"
                >
                    <b-table-column v-slot="props" field="periode" label="Mois">
                        {{ $d(Date.parse(props.row.periode), 'month') }}
                    </b-table-column>
                    <b-table-column v-slot="props" label="Total" centered>
                        {{ props.row.budget + props.row.budget_onsite }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="budget" label="Budget Offsite" centered>
                        {{ props.row.budget }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="budget_onsite" label="Budget Onsite" centered>
                        {{ props.row.budget_onsite }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="billable" label="À payer" centered>
                        <b-switch
                            v-model="props.row.billable"
                            type="is-success"
                            :true-value="1"
                            :false-value="0"
                            @input="value => updateBillable(props.row.id, value)"
                        />
                    </b-table-column>
                    <b-table-column v-slot="props" field="payment_request_status" label="Statut" centered>
                        <span v-if="props.row.payment_request_status" class="badge badge-success"> Déjà effectué </span>
                        <span v-else class="badge badge-info"> A demander </span>
                    </b-table-column>
                    <b-table-column v-slot="props" field="budget_observation" label="Observation">
                        {{ props.row.observation }}
                    </b-table-column>
                    <b-table-column width="90">
                        <template v-slot="props">
                            <div class="d-flex" style="gap: 0.5rem">
                                <b-button
                                    type="is-warning is-light"
                                    icon-left="fas fa-hand-holding-usd"
                                    :disabled="true"
                                    @click="generateRequest(props.row)"
                                />
                                <mail-preview-modal
                                    v-if="props.row.mail"
                                    :html="props.row.mail.mail ? props.row.mail.mail.html : ''"
                                    :title="`Mail de rapport mensuel du ${moment(props.row.mail.sent_at).format(
                                        'DD/MM/YYYY'
                                    )}`"
                                >
                                    <b-button v-if="props.row.mail.mail != null" type="is-link is-success">
                                        <b-icon icon="fas fa-envelope" />
                                    </b-button>
                                    <b-button v-else type="is-link is-danger">
                                        <b-icon icon="fas fa-envelope" />
                                    </b-button>
                                </mail-preview-modal>
                                <campaign-monthly-report-mail
                                    v-else
                                    :campaign="campaign"
                                    :period="new Date(props.row.periode)"
                                />
                                <budget-form :budget="props.row" />
                            </div>
                        </template>
                        <template v-slot:header="{ column }">
                            <div class="d-flex justify-content-end">
                                <budget-form v-if="checkedRows.length" :ids="checkedRowsIds" />
                            </div>
                        </template>
                    </b-table-column>
                </b-table>
                <div v-else class="text-center p-4">
                    <h5 class="card-title">Répartition du budget</h5>
                    <p class="card-text">Une fois les dates d'accompagnement définies la répartition s'affichera</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BudgetForm from './BudgetForm'
    import BudgetExtend from './BudgetExtend'
    import 'vue-loading-overlay/dist/vue-loading.css'
    import NlModal from '../UI/NlModal'
    import { mapActions, mapState } from 'vuex'
    import MailPreviewModal from './MailPreviewModal'
    import CampaignMonthlyReportMail from './CampaignMonthlyReportMail'

    export default {
        components: {
            NlModal,
            BudgetExtend,
            BudgetForm,
            MailPreviewModal,
            CampaignMonthlyReportMail
        },
        props: {
            activeTab: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                budgetBeingEdited: null,
                showExtendBudget: false,
                showResetConfirmModal: false,
                isLoading: true,
                isLoadingTable: true,
                checkedRows: []
            }
        },
        watch: {
            activeTab(newValue, oldValue) {
                if (newValue === 0 && oldValue !== 0) {
                    this.isLoadingTable = true
                    this.getBudget(this.$route.query.id).finally(() => {
                        this.isLoading = false
                        this.isLoadingTable = false
                    })
                }
            }
        },
        computed: {
            ...mapState('campaigns', ['budgets', 'campaign']),
            checkedRowsIds() {
                return this.checkedRows.map(e => e.id)
            }
        },
        methods: {
            ...mapActions('campaigns', ['getBudget', 'claimCredit', 'resetBudgets', 'updateBudget']),
            // TODO: A revoir pcq c'est pourri sa mère
            generateRequest: function (budget) {
                this.isLoading = true
                this.claimCredit({
                    campaignId: this.$route.query.id,
                    period: budget.periode
                })
                    .then(() => {
                        budget.payment_request_status = 1
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            reset: function () {
                this.$buefy.dialog.confirm({
                    title: this.$t('campaign-manager.notification.budget.resetDialogTitle'),
                    message: this.$t('campaign-manager.notification.budget.resetDialogMessage'),
                    cancelText: 'Annuler',
                    confirmText: 'Réinitialiser',
                    type: 'is-danger',
                    hasIcon: true,
                    ariaRole: 'alertdialog',
                    ariaModal: true,
                    onConfirm: () => {
                        this.isLoading = true
                        this.resetBudgets(this.$route.query.id)
                            .then(() => {
                                this.$store.dispatch('toast', {
                                    message: this.$t('campaign-manager.notification.budget.resetValidation'),
                                    type: 'success',
                                    closeButton: true
                                })
                            })
                            .catch(() => {
                                this.$store.dispatch('toast', {
                                    message: this.$t('campaign-manager.notification.budget.resetError'),
                                    type: 'error',
                                    closeButton: true
                                })
                            })
                            .finally(() => {
                                this.isLoading = false
                            })
                    }
                })
            },
            updateBillable(id, value) {
                this.isLoading = true
                this.updateBudget({
                    id: this.$route.query.id,
                    data: {
                        billable: value
                    },
                    ids: [id]
                }).finally(() => {
                    this.isLoading = false
                })
            }
        }
    }
</script>
