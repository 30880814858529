import NlStorage from "@/services/nl.storage";

export function getCache(key) {
    const itemStr = NlStorage.getItem(key)

    if (itemStr) {
        const item = JSON.parse(itemStr)

        if (item?.expiry && new Date().getTime() < item.expiry && item.value) {
            return item.value
        }
    }

    return null
}

export function setCache(key, data, ttl = 86400000) {
    NlStorage.setItem(
        key,
        JSON.stringify({
            value: data,
            expiry: new Date().getTime() + ttl
        })
    )
}
