export default () => ({
    campaign: null,
    keywords: [],
    keywordsWorkedOn: {},
    keywordsNotWorkedOn: {},
    budgets: [],
    actions: {
        loading: true,
        data: []
    },
    followUps: {
        loading: true,
        data: {
            data: [],
            meta: {
                tasks: [],
                thematics: [],
                status: []
            }
        }
    }
})
