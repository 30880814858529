<template>
    <a role="button" class="projects-move" @click="movingProject"
        ><i class="far fa-folder-open" />
        {{ favorite.project ? favorite.project.name : 'non défini' }}
    </a>
</template>

<script>
    import { EventManager } from '../EventManager'

    export default {
        components: {
            EventManager
        },
        props: {
            favorite: {
                type: Object,
                required: true
            }
        },
        created() {},
        methods: {
            movingProject() {
                EventManager.$emit('movingProject', {
                    type: 'favorite',
                    object: {
                        stat_id: this.favorite.stat_id,
                        originalProject: this.favorite.project_id
                    }
                })
                EventManager.$on('movedProject', data => {
                    if (data.object.stat_id == this.favorite.stat_id) {
                        EventManager.$on('movedProject', null)
                        this.$emit('reload')
                    }
                })
            }
        }
    }
</script>

<style></style>
