<template>
    <tr :id="result.id" :class="lineClass">
        <td v-if="selectable" :class="displayCurrentUrl ? 'cat-0' : 'cat-1'">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" name="lines" :value="result.id" />
            </div>
        </td>
        <td v-if="displayCurrentUrl" class="cat-0 align-middle">
            <span class="text-bold">{{ $t('tableau-keywords.yoururl') }} :</span>
            <a :href="result.current_url" target="_blank" rel="noreferrer noopener">
                {{ result.current_url }}
            </a>
            <br />
            <span class="text-bold"> {{ $t('tableau-keywords.yourposition') }} : </span>
            {{ result.current_position }}
        </td>
        <td v-if="displayCurrentUrl" class="table-separation" />
        <td v-if="security.displaySearchResults || security.seeKeyword" class="cat-1 align-middle">
            <span
                v-if="security.isAdmin"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="blacklist url projet"
            >
                <i
                    class="text-normal fa-times-circle fa cursor-pointer"
                    @click="$emit('blacklist-keyword', result.id)"
                />
            </span>
            <i
                :class="['text-secondary fa-star favorite', hisFavorite ? 'fa' : 'far']"
                @click="favorite($event, result.id)"
            />
            <notes v-if="hisFavorite" :favorite="hisFavorite" />
            <a
                v-if="result.partenaire_id"
                role="button"
                data-toggle="tooltip"
                data-placement="top"
                :title="$t('tableau-keywords.partnersite')"
                ><i class="far fa-handshake"
            /></a>

            {{ result.keyword }}
            <div>
                <i class="far fa-folder-open" />
                {{ result.project_name }}
            </div>
            <!--<move-project v-if="hisFavorite" :favorite="hisFavorite" @reload="$emit('reload')"/>-->
            <span
                v-if="undefined !== result.keywords && 0 < result.keywords.length"
                class="fas fa-plus ml-1 mr-1 showOtherKw"
                style="cursor: pointer"
                @click="displaySubKeywords($event, result.id)"
            />
            <div v-if="security.isAdmin || (security.displayUrl && isFavorite)">
                <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="blacklist couple générale"
                >
                    <i class="text-normal fa-ban fa cursor-pointer" @click="$emit('blacklist-url', result.id)" />
                </span>
                <a :href="result.url" target="_blank" rel="noreferrer noopener">{{ result.url }}</a
                >- Rédac <span v-if="'nextlevel' === result.redaction">NL</span
                ><span v-else>{{ $t('partenaire-site.partner') }}</span>
            </div>
        </td>
        <td v-else class="cat-1 align-middle">
            <i
                :class="['text-secondary fa-star favorite', hisFavorite ? 'fa' : 'far']"
                @click="favorite($event, result.id)"
            />

            {{ result.keyword }}

            <span
                v-if="undefined !== result.keywords && 0 < result.keywords.length"
                class="fas fa-plus ml-1 mr-1 showOtherKw"
                @click="displaySubKeywords($event, result.id)"
            />
        </td>
        <td v-if="displayCurrentPosition && !displayCurrentUrl" class="cat-1 align-middle">
            <span>{{ result.current_position }}</span>
        </td>
        <td class="cat-1 align-middle">
            <span
                v-if="security.isProspect && 0 === credit && !security.seeKeyword && !security.displaySearchResults"
                style="display: block; background-color: #404e67; width: 50px; height: 20px"
            />
            <span v-else>Top {{ result.position }}</span>
        </td>
        <td class="cat-1 align-middle">
            {{ 11 === result.volume ? 'N/C' : result.volume }}
        </td>

        <!-- Url -->
        <td class="cat-2 align-middle">
            {{ result.nb_keyword }}
        </td>
        <td class="cat-2 align-middle">
            <span
                v-if="security.isProspect && 0 === credit && !security.seeKeyword && !security.displaySearchResults"
                style="display: block; background-color: #404e67; width: 50px; height: 20px"
            />
            <span v-else>{{ result.top10 }}</span>
        </td>
        <td class="cat-4 align-middle">
            {{ result.indice_url }}
        </td>

        <!-- Le site -->
        <td class="cat-3 align-middle">
            {{ result.site_type }}
        </td>
        <td v-if="security.displaySiteKpi" class="cat-3 align-middle">
            {{ result.domain_rank }}
        </td>
        <td v-if="security.displaySiteKpi" class="cat-3 align-middle">
            {{ result.tf_count }}
        </td>
        <td v-if="security.displaySiteKpi" class="cat-3 align-middle">
            {{ result.cf_count }}
        </td>
        <td v-if="security.displaySiteKpi" class="cat-3 align-middle">
            {{ result.rd_count }}
        </td>
        <td class="cat-3 align-middle">
            {{ result.groupe_name }}
        </td>

        <!-- Commander -->
        <td class="cat-5 align-middle">
            <a
                v-if="0 < result.price && result.price > credit"
                href="achat-credits.php"
                class="btn btn-danger"
                data-toggle="tooltip"
                data-placement="top"
                :title="0 === credit ? $t('tableau-keywords.credit') : $t('tableau-keywords.credittoorder')"
            >
                <i class="fa fa-exclamation-triangle" aria-hidden="true" />
                {{ result.price }}€
            </a>

            <b-button v-else type="is-warning" icon-pack="fas" icon-left="cart-plus" @click="buyLink(result)">
                {{ Math.round(result.price) }}€ HT
            </b-button>
        </td>
    </tr>
</template>

<script>
    import axios from 'axios'
    import { mapActions } from 'vuex'
    import Notes from '../Favorites/Notes'

    export default {
        name: 'LineResult',
        components: {
            Notes
        },
        props: {
            result: Object,
            favorites: Array,
            credit: Number,
            lineClass: String,
            security: Object,
            projectId: { required: true },
            projectName: { type: String, default: null },
            updateUrl: Function,
            selectable: { type: Boolean, default: false },
            displayCurrentPosition: { type: Boolean, default: false },
            displayCurrentUrl: { type: Boolean, default: false },
            isFavorite: { type: Boolean, default: false }
        },
        computed: {
            hisFavorite() {
                return this.favorites.find(f => f.stat_id === this.result.id)
            }
        },
        methods: {
            ...mapActions('favorites', { toggleFavorite: 'toggle' }),
            buyLink(result) {
                let statId = result.id
                let price = result.price
                let priceSupp = result.price * 0.15

                this.$emit('buy', statId, price, priceSupp, result.site_id)
            },
            projectMove(id) {
                $('#projects-manager').addClass('select').find('.modal-title').html(this.$t('projets.moovetoproject'))
                $('#projects-manager').modal()
            },
            favorite(e, id) {
                this.toggleFavorite({
                    id,
                    project_id: this.$store.state.project.id
                }).then(data => {
                    if (data.favorites === 'added') {
                        $(e.target).removeClass('far')
                        $(e.target).addClass('fa')
                    } else {
                        $(e.target).removeClass('fa')
                        $(e.target).addClass('far')
                    }
                    this.$emit('updateFavorites')
                })
                /*
            const formData =  new FormData();
            formData.append('id', id);
            formData.append('project_id', this.$store.state.project);

            axios.post('api/favorite.php', formData, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then(function (response) {
                if (response.data == 1) {
                    $(e.target).removeClass('far');
                    $(e.target).addClass('fa');
                } else {
                    $(e.target).removeClass('fa');
                    $(e.target).addClass('far');
                }
            });*/
            },
            favoriteNL(e, id) {
                const formData = new FormData()
                formData.append('id', id)

                axios
                    .post('api/favoriteNL.php', formData, {
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                    })
                    .then(function (response) {
                        if (response.data == 1) {
                            $(e.target).removeClass('far')
                            $(e.target).addClass('fa')
                        } else {
                            $(e.target).removeClass('fa')
                            $(e.target).addClass('far')
                        }
                    })
            },
            displaySubKeywords(e, id) {
                $(e.target).toggleClass('fa-plus')
                $(e.target).toggleClass('fa-minus')
                $('.sub-item-' + id).toggleClass('d-none')
            }
        },
        mounted() {},
        destroyed() {}
    }
</script>
