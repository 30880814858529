import ApiService from '../../../services/api.service'

export default {
    getAuthCampaigns(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/campaigns/list`, params)
                .then(({ data }) => {
                    resolve(data.accompagnements)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getCampaignPosition(context, params) {
        return new Promise((resolve, reject) => {
            const { accompagnementId, dateFrom, dateTo } = params
            ApiService.get(`/campaigns/${accompagnementId}/positions`, {
                dateFrom,
                dateTo
            })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getCampaignById(context, { campaignId }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/campaigns/${campaignId}`)
                .then(({ data }) => {
                    if (data?.mails?.launching) data.mails.launching.options = JSON.parse(data.mails.launching.options)
                    if (data?.mails?.monthlyReports)
                        data.mails.monthlyReports = data.mails.monthlyReports.map(({ options, ...rest }) => {
                            return { options: JSON.parse(options), ...rest }
                        })
                    context.commit('setCampaign', data)

                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getCampaignMails(context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/campaigns/${context.campaign.id}/mails`)
                .then(({ data }) => {
                    context.commit('setMails', data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    saveLaunchingMail(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/campaigns/${context.state.campaign.id}/mails/launch`, params)
                .then(({ data }) => {
                    context.commit('setLaunchingMail', data.mail)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    saveMonthlyReportMail(context, formData) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/campaigns/${context.state.campaign.id}/mails/monthly-report`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
                .then(({ data }) => {
                    context.commit('setMonthlyReportMails', data.mail)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getKeywordsByCampaign(context, { campaignId }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/campaigns/url/${campaignId}`)
                .then(({ data }) => {
                    context.commit('setKeywords', data)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getKeywordsWorkedOnByCampaign(context, { campaignId, page = 1 }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/campaigns/url/worked-on/${campaignId}`, { page: page })
                .then(({ data }) => {
                    context.commit('setKeywordsWorkedOn', data.keywords)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getKeywordsNotWorkedOnByCampaign(context, { campaignId, page = 1 }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/campaigns/url/not-worked-on/${campaignId}`, {
                page: page
            })
                .then(({ data }) => {
                    context.commit('setKeywordsNotWorkedOn', data)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    updateCampaign(
        context,
        {
            campaignId,
            user_id,
            project_id,
            url,
            budget_mensuel,
            notes,
            brief_client,
            statut,
            debut_accompagnement,
            duree,
            accompagnant_id,
            accompagnant2_id,
            accompagnant_onpage_id,
            type_facturation,
            moment_facturation,
            brief_consultant,
            analyse_onpage,
            user_additional_information
        }
    ) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/admin/accompagnements/${campaignId}`, {
                user_id,
                project_id,
                url,
                budget_mensuel,
                notes,
                brief_client,
                statut,
                debut_accompagnement,
                duree,
                accompagnant_id,
                accompagnant2_id,
                accompagnant_onpage_id,
                type_facturation,
                moment_facturation,
                brief_consultant,
                analyse_onpage,
                user_additional_information
            })
                .then(({ data }) => {
                    if (data.campaign || data.budgets) {
                        context.commit('setCampaign', data.campaign)
                        context.commit('setBudgets', data.budgets)
                    } else {
                        context.commit('setCampaign', data)
                    }
                    if (debut_accompagnement || duree) {
                        context.dispatch('contents/getContentsByCampaign', campaignId, {
                            root: true
                        })
                    }
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getMailPreview(context, { campaignId, type, data }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/campaigns/${campaignId}/mails/${type}/preview`, data)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    bulkUpdateCampaignKeywords(context, { ids, data }) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/admin/campaign/${context.state.campaign.id}/keywords`, { ids, data })
                .then(() => {
                    context.commit('updateKeywords', { ids, data })
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    bulkDeleteCampaignKeywords(context, { urls }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/admin/campaign/keywords/delete`, { urls })
                .then(() => {
                    context.commit(
                        'removeKeywords',
                        urls.map(e => e.id)
                    )
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    fetchGoogleResults(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/campaigns/google-search-results`, params)
                .then(({ data }) => {
                    resolve(
                        data.results.map(res => ({
                            mot_cle: res.keywords,
                            url: res.url ?? params.url,
                            position_initiale: res.rank,
                            rd_kw: res.rd_count
                        }))
                    )
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    createCampaignKeywords(context, { campaignId, params }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/campaigns/url/${campaignId}`, params)
                .then(({ data }) => {
                    context.commit('setKeywords', data)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    createMonitorankEntities(context, { campaignId }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/campaigns/${campaignId}/positions`)
                .then(({ data }) => {
                    context.commit('setKeywords', data)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getMonitorankEntities(context, { campaignId }) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/campaigns/${campaignId}/positions`)
                .then(({ data }) => {
                    context.commit('setKeywords', data)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    storeRD(context, { id, rd }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/campaigns/refdomains/${id}`, {
                rd
            })
                .then(({ data }) => {
                    context.commit('setKeywords', data)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getBudget(context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/campaign/${id}/budget`)
                .then(({ data }) => {
                    context.commit('setBudgets', data)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    updateBudget(context, { id, ids, data }) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/admin/campaign/${id}/budget`, { ids, data })
                .then(({ data }) => {
                    context.commit('setBudgets', data)
                    context.dispatch('contents/getContentsByCampaign', id, {
                        root: true
                    })
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    // TODO: A revoir pcq c'est pourri sa mère
    claimCredit(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/admin/accompagnements/credit`, params)
                .then(({ data }) => {
                    context.commit('setBudgets', data)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    extendCampaign(context, { id, duration }) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/admin/campaign/${id}/extend`, { duration })
                .then(({ data }) => {
                    context.commit('setBudgets', data.budgets)
                    context.commit('setCampaign', data.campaign)
                    context.dispatch('contents/getContentsByCampaign', id, {
                        root: true
                    })
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    resetBudgets(context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/campaign/${id}/budget/reset`)
                .then(({ data }) => {
                    context.commit('setBudgets', data)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    setPartnerAvailability(context, { id, partner_availability }) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/admin/campaign/url/${id}/availability`, {
                partner_availability: partner_availability
            })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getCampaignActions(context, id) {
        context.commit('setActionsLoading', true)
        return new Promise((resolve, reject) => {
            ApiService.get(`/campaign/${id}/actions`)
                .then(({ data }) => {
                    context.commit('setActionsData', data)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    context.commit('setActionsLoading', false)
                })
        })
    },

    updateCampaignActions(context, { actionId, campaignId, params }) {
        context.commit('setActionsLoading', true)
        return new Promise((resolve, reject) => {
            ApiService.put(`/admin/campaign/${campaignId}/actions/${actionId}`, params)
                .then(({ data }) => {
                    context.commit('setActionsData', data)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    context.commit('setActionsLoading', false)
                })
        })
    },

    createCampaignActions(context, { campaignId, params }) {
        context.commit('setActionsLoading', true)
        return new Promise((resolve, reject) => {
            ApiService.post(`/admin/campaign/${campaignId}/actions`, params)
                .then(({ data }) => {
                    context.commit('setActionsData', data)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    context.commit('setActionsLoading', false)
                })
        })
    },

    deleteCampaignActions(context, { campaignId, actionId }) {
        context.commit('setActionsLoading', true)
        return new Promise((resolve, reject) => {
            ApiService.delete(`/admin/campaign/${campaignId}/actions/${actionId}`)
                .then(({ data }) => {
                    context.commit('setActionsData', data)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    context.commit('setActionsLoading', false)
                })
        })
    },

    getCampaignFollowUps(context, id) {
        context.commit('setFollowUpsLoading', true)
        return new Promise((resolve, reject) => {
            ApiService.get(`/campaign/${id}/followUps`)
                .then(({ data }) => {
                    context.commit('setFollowUpsData', data)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    context.commit('setFollowUpsLoading', false)
                })
        })
    },

    updateCampaignFollowUps(context, { followUpId, campaignId, params }) {
        context.commit('setFollowUpsLoading', true)
        return new Promise((resolve, reject) => {
            ApiService.put(`/admin/campaign/${campaignId}/followUps/${followUpId}`, params)
                .then(({ data }) => {
                    context.commit('setFollowUpsData', data)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    context.commit('setFollowUpsLoading', false)
                })
        })
    },

    createCampaignFollowUps(context, { campaignId, params }) {
        context.commit('setFollowUpsLoading', true)
        return new Promise((resolve, reject) => {
            ApiService.post(`/admin/campaign/${campaignId}/followUps`, params)
                .then(({ data }) => {
                    context.commit('setFollowUpsData', data)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    context.commit('setFollowUpsLoading', false)
                })
        })
    },

    deleteCampaignFollowUps(context, { campaignId, followUpId }) {
        context.commit('setFollowUpsLoading', true)
        return new Promise((resolve, reject) => {
            ApiService.delete(`/admin/campaign/${campaignId}/followUps/${followUpId}`)
                .then(({ data }) => {
                    context.commit('setFollowUpsData', data)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    context.commit('setFollowUpsLoading', false)
                })
        })
    },
    editCampaignKeyword(context, data) {
        context.commit('setFollowUpsLoading', true)
        return new Promise((resolve, reject) => {
            ApiService.put(`/admin/campaign/keywords/${data.id}`, data.payload)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    context.commit('setFollowUpsLoading', false)
                })
        })
    },
}
