<template>
    <field v-model="input" class="text-field" :class="stateClass" :rules="rules" :name="name">
        <template #field="{ fieldValue, onInput, fieldName, fieldId }">
            <label v-if="label" :class="classLabel" :for="fieldId">{{ label }}</label>
            <div :class="{ prepend: $slots.prepend }">
                <div v-if="$slots.prepend" class="input-group-prepend">
                    <div class="input-group-text">
                        <slot name="prepend" />
                    </div>
                </div>
                <input
                    :id="fieldId"
                    :ref="fieldName"
                    :value="fieldValue"
                    :type="type"
                    :min="min"
                    :max="max"
                    :disabled="disabled"
                    class="w-full py-1 focus:outline-none border-b border-gray-400 form-control"
                    @input="event => onInput(event.target.value)"
                    @change="event => $emit('change', event)"
                    @focus="focus = !focus"
                    @blur="focus = !focus"
                />
                <div v-if="append" class="self-end text-xs ml-2">
                    {{ append }}
                </div>
            </div>
        </template>
    </field>
</template>

<script>
    import Field from './Field'
    import FieldState from './mixins/FieldState.vue'

    export default {
        components: {
            Field
        },
        mixins: [FieldState],
        props: {
            label: {
                type: String,
                default: ''
            },
            classLabel: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'text',
                validator(value) {
                    return ['url', 'text', 'password', 'tel', 'search', 'number', 'email', 'date'].includes(value)
                }
            },
            value: {
                type: [String, Number],
                default: ''
            },
            rules: {
                type: [Object, String],
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            min: {
                type: Number,
                default: 0
            },
            max: {
                type: Number,
                default: null
            },
            append: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: () => false
            }
        },
        computed: {
            input: {
                get() {
                    return this.value
                },
                set(value) {
                    return this.$emit('input', value)
                }
            }
        }
    }
</script>

<style lang="scss">
    .text-field {
        label {
        }

        input {
            width: 100%;
        }

        &.input-focus,
        &.input-filled {
            label {
            }
        }

        .prepend {
            display: flex;

            .input-group-text {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            input {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
</style>
