<template>
    <b-select
        id="projectSelectorMenu"
        v-model="projectSelected"
        icon="folder"
        icon-pack="fa"
        placeholder="Selectionner un projet"
        rounded
        @input="changeProject"
    >
        `
        <option :value="{ name: 'Tous', id: -1 }">
            {{ $t('global.all') }}
        </option>
        <option v-for="project in projects" :key="project.id" :value="project">
            {{ project.name.substring(0, 20) }}
            ({{ project.budget }}/{{ project.price_paid_this_month }}€)
        </option>
    </b-select>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex'

    export default {
        name: 'ProjectMenu',
        data() {
            return {
                projectSelected: null
            }
        },
        watch: {
            project: {
                handler: function (val) {
                    this.projectSelected = val
                }
            }
        },
        created() {
            this.getProjects()
            this.getProject().then(() => {
                this.projectSelected = this.project
            })
        },
        computed: {
            ...mapState({
                project: 'project'
            }),
            ...mapState('project_selector', {
                projects: 'projects'
            })
        },
        methods: {
            ...mapMutations({
                setProject: 'setProject'
            }),
            ...mapActions('project_selector', {
                getProjects: 'projects',
                getProject: 'getDefaultProject'
            }),
            changeProject() {
                this.setProject(this.projectSelected)
            }
        }
    }
</script>

<style scoped>
    #projectSelectorMenu {
        border: red solid 1px;
    }
</style>
