<template>
    <div class="">
        <div
            class="d-flex flex-column flex-md-row justify-content-between"
            :class="tabInfo.type === 'deleted' ? 'mt-3' : ''"
        >
            <div />
            <form class="d-flex mb-2">
                <button id="rechargement" type="button" class="btn btn-primary mr-2" @click="reloadData">
                    <i class="fas fa-sync-alt" />
                </button>
                <input
                    v-model="perPage"
                    type="number"
                    class="input mr-2"
                    placeholder="Nombre d'éléments par page"
                    @change="reloadData"
                />
                <div class="columnsChoice">
                    <b-dropdown aria-role="list" :close-on-click="false" position="is-bottom-left">
                        <template #trigger="{ active }">
                            <b-button
                                :label="$t('global.columns')"
                                type="is-info"
                                class="mr-2"
                                :icon-right="active ? 'caret-up' : 'caret-down'"
                            />
                        </template>

                        <b-dropdown-item v-for="(col, index) in columns" :key="index" aria-role="listitem">
                            <b-field>
                                <b-checkbox v-model="col.visible">
                                    {{ col.name }}
                                </b-checkbox>
                            </b-field>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <b-field class="m-0">
                    <b-select v-model="bulkActionType" :placeholder="$t('global.actions')">
                        <option value="activate">
                            {{ $t('global.activate') }}
                        </option>
                        <option value="reactivate">Réactiver</option>
                        <option value="waitingActivate">En attente d'amélioration</option>
                        <option value="desactivate">
                            {{ $t('global.desactivate') }}
                        </option>
                        <option value="delete">
                            {{ $t('global.delete') }}
                        </option>
                        <option value="redaction">Rédaction</option>
                        <option value="type">Type de site</option>
                        <option value="group">Groupe</option>
                    </b-select>
                </b-field>
                <b-button
                    :disabled="!checkedRows.length || bulkActionType === null"
                    class="ml-2"
                    type="is-warning"
                    @click="sendBulkAction"
                >
                    {{ $t('global.validate') }}
                </b-button>
            </form>
        </div>

        <b-table
            :data="sites.data"
            :mobile-cards="true"
            :debounce-search="400"
            default-sort-direction="desc"
            :striped="true"
            :loading="loading"
            :paginated="true"
            :per-page="sites.per_page"
            :total="sites.total"
            :default-sort="[sortField, sortOrder]"
            backend-sorting
            backend-pagination
            backend-filtering
            :checked-rows.sync="checkedRows"
            :checkable="true"
            checkbox-position="right"
            @page-change="onPageChange"
            @sort="onSort"
            @filters-change="onFilterChange"
        >
            <b-table-column field="id" sortable :searchable="isSearchable" label="#" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <span>{{ props.row.id }}</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="input is-small"
                            placeholder="#..."
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                v-if="tabInfo.type === 'all'"
                field="actif"
                sortable
                :searchable="isSearchable"
                :label="$t('global.status')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <b-tooltip
                        v-if="props.row.actif === -2 || props.row.actif === -4"
                        :label="
                            props.row.desactivation_site_to != null
                                ? moment(props.row.desactivation_site_to).format('L') +
                                  ' au ' +
                                  moment(props.row.scheduled_at).format('L')
                                : props.row.status_updated_at != null
                                ? moment(props.row.status_updated_at).format('L')
                                : 'Pas de date d\'enregistré'
                        "
                        position="is-right"
                    >
                        <GroupSiteStatus :site="props.row" />
                    </b-tooltip>
                    <GroupSiteStatus v-else :site="props.row" />
                    <b-tooltip
                        v-if="
                            (props.row.notes != '' && props.row.notes != null && props.row.actif === -2) ||
                            props.row.actif === -4
                        "
                        :label="props.row.notes"
                        position="is-right"
                    >
                        <i class="fas fa-comment-dots text-danger" />
                    </b-tooltip>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="select is-small">
                        <select v-model="props.filters[props.column.field]">
                            <option :value="props.filters[props.column.field]" disabled selected hidden>
                                {{ $t('group-site.table.status') }}
                            </option>
                            <option value="1">
                                {{ $t('group-site.status.activated') }}
                            </option>
                            <option value="0">
                                {{ $t('group-site.status.deleted') }}
                            </option>
                            <option value="-1">
                                {{ $t('group-site.status.validation') }}
                            </option>
                            <option value="-2">
                                {{ $t('group-site.status.desactivated') }}
                            </option>
                            <option value="-3">
                                {{ $t('group-site.status.refused') }}
                            </option>
                            <option value="-4">
                                {{ $t('group-site.status.desactivated_partner') }}
                            </option>
                            <option value="-5">
                                {{ $t('group-site.status.amelioration') }}
                            </option>
                        </select>
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                v-if="tabInfo.type == 'desactivated' || tabInfo.type == 'desactivated_nl'"
                field="status_updated_at"
                sortable
                :searchable="isSearchable"
                label="Date désactivation"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <div class="d-flex align-items-center">
                        {{
                        props.row.status_updated_at != null ? moment(props.row.status_updated_at).format('L') : 'Pas de
                        date
                        d\'enregistré'
                        }}
                        <b-tooltip
                            v-if="props.row.notes != '' && props.row.notes != null && props.row.actif === -2"
                            class="ml-2"
                            :label="props.row.notes"
                            position="is-right"
                        >
                            <i class="fas fa-comment-dots text-danger" />
                        </b-tooltip>
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="date"
                            class="input is-small"
                            placeholder="Date statut..."
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="lang" sortable :searchable="isSearchable" label="Langue" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <flag class="rounded shadow-sm" :iso="props.row.lang == 'fr_fr' ? 'fr' : 'us'" />
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="select is-small">
                        <select v-model="props.filters[props.column.field]">
                            <option :value="props.filters[props.column.field]">Langue</option>
                            <option value="fr_fr">
                                {{ $t('group-site.lang.fr') }}
                            </option>
                            <option value="com_en">
                                {{ $t('group-site.lang.en') }}
                            </option>
                        </select>
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="ndd"
                sortable
                :searchable="isSearchable"
                :visible="columns.ndd.visible"
                label="NDD"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <ColumnEditable
                        :type="'input'"
                        :init-value="props.row.ndd"
                        :site-id="props.row.id"
                        :field="props.column.field"
                        @input-blur="sendEditColumn($event)"
                    />
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="input is-small"
                            placeholder="NDD..."
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="url"
                sortable
                :searchable="isSearchable"
                :label="$t('group-site.table.url')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <ColumnEditable
                        :type="'input'"
                        :init-value="props.row.url"
                        :site-id="props.row.id"
                        :field="props.column.field"
                        @input-blur="sendEditColumn($event)"
                    />
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="input is-small"
                            :placeholder="$t('group-site.table.url') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="group_id"
                sortable
                :searchable="isSearchable"
                :visible="columns.group.visible"
                :label="$t('group-site.table.group')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <!--          <span>{{ findGroup(props.row.group_id) }}</span>-->
                    <ColumnEditable
                        :type="'select'"
                        :groups="groups"
                        :init-value="props.row.group_id"
                        :site-id="props.row.id"
                        :field="props.column.field"
                        @input-blur="sendEditColumn($event)"
                    >
                        <option v-for="group in groups" :key="group.id" :value="group.id">
                            <div v-if="group.parent_id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ group.name }}</div>

                            <div v-else>
                                {{ group.name }}
                            </div>
                        </option>
                    </ColumnEditable>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="input is-small"
                            :placeholder="$t('group-site.table.group') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                v-if="
                    tabInfo.type !== 'deleted' && tabInfo.type !== 'waiting_improvement' && tabInfo.type !== 'refused'
                "
                field="scrape_files"
                :label="$t('partenaire-site.url')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.actif != 0 && props.row.actif != -3">
                        <a
                            v-if="
                                props.row.scrape_whitelist ||
                                props.row.scrape_blacklist ||
                                props.row.scrape_sitemap ||
                                props.row.admin_scrape_blacklist
                            "
                            :href="`/admin/sites/keywords?id=${props.row.id}`"
                            target="_blank"
                        >
                            <span v-if="props.row.scrape_sitemap" class="mr-1">Sitemap</span>
                            <span v-if="props.row.scrape_whitelist" class="mr-1">Whitelist</span>
                            <span v-if="props.row.scrape_blacklist">Blacklist</span>
                            <span v-if="props.row.admin_scrape_blacklist">Blacklist Admin</span>
                        </a>
                        <span v-else>{{ $t('partenaire-site.all-site') }}</span>
                        <i v-if="props.row.prix_minimum_filtre > 0" class="fas fa-euro-sign text-warning" />
                        <i v-if="props.row.outrepasser_liens === 1" class="fas fa-lock-open" />
                    </span>
                </div>
            </b-table-column>
            <b-table-column
                field="site_type"
                sortable
                :searchable="isSearchable"
                :label="$t('group-site.table.type')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <!--          <span>{{ props.row.site_type }}</span>-->
                    <ColumnEditable
                        :type="'select'"
                        :init-value="props.row.site_type"
                        :site-id="props.row.id"
                        :field="props.column.field"
                        @input-blur="sendEditColumn($event)"
                    >
                        <option value="">Type de site</option>
                        <option value="or">Or</option>
                        <option value="argent">Argent</option>
                        <option value="bronze">Bronze</option>
                        <option value="vipor">VIP Or</option>
                        <option value="vipargent">VIP Argent</option>
                        <option value="vipbronze">VIP Bronze</option>
                        <option value="media">Media</option>
                    </ColumnEditable>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="select is-small">
                        <select v-model="props.filters[props.column.field]">
                            <option :value="props.filters[props.column.field]">Type de site</option>
                            <option value="or">Or</option>
                            <option value="argent">Argent</option>
                            <option value="bronze">Bronze</option>
                            <option value="vipor">VIP Or</option>
                            <option value="vipargent">VIP Argent</option>
                            <option value="vipbronze">VIP Bronze</option>
                            <option value="media">Media</option>
                        </select>
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                v-if="tabInfo.type !== 'waiting_activation'"
                field="wp"
                sortable
                :label="$t('group-site.table.WP')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <NlWordpress :site-data="props.row" site-type="site" />
                </div>
            </b-table-column>
            <b-table-column
                field="semrush_kw"
                sortable
                :searchable="isSearchable"
                :visible="columns.semrush_kw.visible"
                :label="$t('group-site.table.semrush_keywords')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ props.row.nb_mots_cles_semrush }}</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="input is-small"
                            :placeholder="$t('group-site.table.semrush_keywords') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="semrush_var"
                sortable
                :visible="columns.var_semrush.visible"
                :label="$t('group-site.table.semrush_var')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span />
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="input is-small"
                            placeholder="VAR SEMRush..."
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="nb_mots_cles_seobserver"
                sortable
                :searchable="isSearchable"
                :label="$t('group-site.table.seobserver_keywords')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ props.row.nb_mots_cles_seobserver }}</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="input is-small"
                            :placeholder="$t('group-site.table.seobserver_keywords') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="seobserver_var"
                sortable
                :visible="columns.var_seobserver.visible"
                :label="$t('group-site.table.seobserver_var')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span />
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="input is-small"
                            placeholder="VAR SEObserver..."
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="seobserver_update"
                sortable
                :visible="columns.update_seobserver.visible"
                :label="$t('group-site.table.seobserver_update')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span />
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="date"
                            class="input is-small"
                            placeholder="MAJ SEObserver..."
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="nb_keywords"
                sortable
                :searchable="isSearchable"
                :label="$t('group-site.table.nl_keywords')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <a
                        target="_blank"
                        :href="`/admin/sites/keywords?id=${props.row.id}`"
                        class="button is-light text-decoration-none"
                    >
                        <span class="mr-1">{{ props.row.nb_keywords }}</span>
                        <b-icon icon="eye" size="is-small" />
                    </a>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="input is-small"
                            :placeholder="$t('group-site.table.nl_keywords') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="nl_var"
                sortable
                :visible="columns.var_nextlevel.visible"
                :label="$t('group-site.table.nl_var')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span />
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="input is-small"
                            placeholder="VAR NL..."
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="nl_update"
                sortable
                :visible="columns.update_nextlevel.visible"
                :label="$t('group-site.table.nl_update')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ moment(props.row.updated_at).format('L') }}</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="date"
                            class="input is-small"
                            placeholder="MAJ NL..."
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                v-if="tabInfo.type !== 'waiting_activation' && tabInfo.type !== 'waiting_improvement'"
                field="orders_count"
                sortable
                :searchable="isSearchable"
                :label="$t('group-site.table.nb_orders')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <a
                        target="_blank"
                        :href="`/admin/sites/orders?id=${props.row.id}`"
                        class="button is-light text-decoration-none"
                    >
                        <span class="mr-1">{{ props.row.orders_count }}</span>
                        <b-icon icon="eye" size="is-small" />
                    </a>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="input is-small"
                            :placeholder="$t('group-site.table.nb_orders') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                v-if="tabInfo.type === 'all' || tabInfo.type === 'activated'"
                field="health"
                sortable
                :searchable="isSearchable"
                :label="$t('global.health')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.health == '200'" class="tag is-success">
                        <i class="fas fa-check-circle mr-1" />
                        200
                    </span>
                    <span v-else class="tag is-danger">
                        <i class="fas fa-info mr-1" />
                        400
                    </span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="input is-small"
                            placeholder="Santé..."
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="redaction"
                sortable
                :searchable="isSearchable"
                :label="$t('group-site.table.redaction')"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <!--          <span>{{ props.row.redaction == 'nextlevel' ? 'NL' : 'Partenaire' }}</span>-->
                    <ColumnEditable
                        :type="'select'"
                        :init-value="props.row.redaction"
                        :site-id="props.row.id"
                        :field="props.column.field"
                        @input-blur="sendEditColumn($event)"
                    >
                        <option value="nextlevel">NL</option>
                        <option value="proprietaire">Partenaire</option>
                    </ColumnEditable>
                </div>
                <div slot="searchable" slot-scope="props">
                    <b-select v-model="filters[props.field]" size="is-small">
                        <option :value="null">Tous</option>
                        <option value="nextlevel">NL</option>
                        <option value="proprietaire">Partenaire</option>
                    </b-select>
                </div>
            </b-table-column>
            <b-table-column
                field="created_at"
                sortable
                :searchable="isSearchable"
                label="Date d'ajout"
                :visible="columns.created_at.visible"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ moment(props.row.created_at).format('L') }}</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="date"
                            class="input is-small"
                            placeholder="Date d'ajout"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="actions" sortable label="Actions" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <div class="is-flex">
                        <a
                            href="#"
                            class="rounded button is-light rounded-lg text-decoration-none mr-1"
                            @click.prevent="openSiteModal(props.row)"
                        >
                            <b-icon icon="cog" />
                        </a>
                        <a
                            target="_blank"
                            :href="`/admin/sites/${props.row.id}/urls`"
                            class="rounded button is-light rounded-lg text-decoration-none mr-1"
                        >
                            <b-icon icon="link" />
                        </a>
                        <a
                            class="rounded button is-light rounded-lg text-decoration-none mr-1"
                            @click.prevent="openNoteModal(props.row.id)"
                        >
                            <b-icon pack="fas" icon="comment-alt" />
                        </a>
                    </div>
                </div>
            </b-table-column>
        </b-table>

        <NoteModal
            ref="noteModal"
            modal-type="site"
            :type-array="['Support', 'Developpement', 'Commercial', 'Communication', 'Comptabilité', 'Autre']"
        />

        <GroupSiteConfirmModal
            ref="confirmModal"
            :checked-rows="checkedRows"
            :one-row="oneRow"
            :groups="groups"
            @uncheckRows="checkedRows = []"
        />
    </div>
</template>

<script>
    import GroupSiteConfirmModal from './GroupSiteConfirmModal'
    import ColumnEditable from './ColumnEditable'
    import GroupSiteStatus from './GroupSiteStatus'
    import NlWordpress from '@/components/UI/Wordpress/NlWordpress.vue'

    export default {
        name: 'GroupSiteTableWithoutTab',
        components: {
            ColumnEditable,
            GroupSiteConfirmModal,
            GroupSiteStatus,
            NlWordpress
        },
        props: ['tabInfo', 'groups', 'users', 'partners', 'displayUser'],

        data() {
            return {
                sites: [],
                isSearchable: true,
                bulkActionType: null,
                checkedRows: [],
                oneRow: null,
                loading: false,

                fakeData: '',
                selected: null,

                columns: {
                    id: {
                        visible: false,
                        name: 'Id'
                    },
                    ndd: {
                        visible: false,
                        name: 'Ndd'
                    },
                    group: {
                        visible: false,
                        name: 'Groupe'
                    },
                    semrush_kw: {
                        visible: false,
                        name: 'Mots-clés SEMRush'
                    },
                    var_semrush: {
                        visible: false,
                        name: 'VAR Semrush'
                    },
                    var_seobserver: {
                        visible: false,
                        name: 'VAR SEObserver'
                    },
                    update_seobserver: {
                        visible: false,
                        name: 'MAJ SEObserver'
                    },
                    var_nextlevel: {
                        visible: false,
                        name: 'VAR Nextlevel'
                    },
                    update_nextlevel: {
                        visible: false,
                        name: 'MAJ Nextlevel'
                    },
                    validation: {
                        visible: false,
                        name: 'Validation'
                    },
                    created_at: {
                        visible: false,
                        name: "Date d'ajout"
                    }
                },

                sortField: 'nb_keywords',
                sortOrder: 'desc',
                filters: '',
                page: 1,
                perPage: 50,

                isMobileWidth: window.innerWidth < 768,
                editRedaction: null,
                editCategory: null,
                editWordsMax: null
            }
        },
        computed: {
            filteredPartnerEmail() {
                return this.users.filter(user => {
                    return user.email.toString().toLowerCase().indexOf(this.fakeData.toLowerCase()) >= 0
                })
            }
        },
        watch: {
            tabInfo: {
                handler(newValue) {
                    this.sites = newValue.content

                    if (this.loading) {
                        this.loading = false
                    }

                    if (this.tabInfo.type === 'waiting_activation') {
                        this.columns.group.visible = true
                    } else {
                        this.columns.group.visible = false
                    }
                },
                deep: true
            },
            fakeData: function (newValue) {
                if (newValue == '') {
                    this.fakeData = ''
                    let filter = this.filters.find(filter => filter.key == 'partenaire_id')
                    filter.value = null
                    this.loadAsyncData()
                } else {
                    this.fakeData = newValue
                }
            }
        },
        created() {
            this.sites = this.tabInfo.content
            this.perPage = this.sites.per_page
        },
        methods: {
            reloadData() {
                this.loadAsyncData()
            },

            findGroup(groupId) {
                let group = this.groups.find(group => group.id == groupId)
                return group.name
            },

            findUser(userId) {
                return this.users.find(user => user.id == userId)
            },

            columnSelection(column) {
                return this.columns.find(col => col.name == column).visible
            },

            openSiteModal(site) {
                site.clear_whitelist = false
                site.clear_blacklist = false

                this.$emit('openModal', site)
            },

            openNoteModal(siteId) {
                this.$refs.noteModal.openModal(siteId)
            },

            openModalDelete(site) {
                this.oneRow = site
                this.$refs.confirmModal.openModal('delete')
            },

            sendBulkAction() {
                let listIds = this.checkedRows.map(site => site.id)

                switch (this.bulkActionType) {
                    case 'desactivate':
                        this.$refs.confirmModal.openModal(this.bulkActionType)
                        break
                    case 'activate':
                        this.$emit('activateBulkSites', {
                            ids: listIds
                        })
                        this.checkedRows = []
                        break
                    case 'reactivate':
                        this.$emit('reactivateBulkSites', {
                            ids: listIds
                        })
                        this.checkedRows = []
                        break
                    case 'waitingActivate':
                        this.$refs.confirmModal.openModal(this.bulkActionType)
                        break
                    case 'delete':
                        this.$refs.confirmModal.openModal(this.bulkActionType)
                        break
                    case 'redaction':
                        this.$refs.confirmModal.openModal(this.bulkActionType)
                        break
                    case 'type':
                        this.$refs.confirmModal.openModal(this.bulkActionType)
                        break
                    case 'group':
                        this.$refs.confirmModal.openModal(this.bulkActionType)
                        break
                    default:
                        break
                }
            },

            closeFlyEdit() {
                this.editRedaction = null
                this.editCategory = null
                this.editWordsMax = null
            },

            editDataChange(site) {
                this.editRedaction = null
                this.editCategory = null
                this.editWordsMax = null

                site.scrape_blacklist = null
                site.scrape_whitelist = null
                site.validation = 'simple'
                site.blacklisted_groups = JSON.stringify(site.blacklisted_groups)
                this.$emit('editSite', site)
            },

            handleEditCategory(id) {
                this.editCategory = id
            },

            /** Table filtering, sorting and pagination methods */
            loadAsyncData() {
                this.loading = true

                this.$emit('loadAsyncData', {
                    page: this.page,
                    per_page: this.perPage,
                    sortField: this.sortField,
                    sortOrder: this.sortOrder,
                    filters: this.filters,
                    includes: '',
                    path: this.sites.path,
                    index: this.indexType,

                    callback() {
                        this.loading = false
                    }
                })
            },

            onSort(field, order) {
                if (field === 'group.translations.name') {
                    field = 'group_id'
                }

                this.sortField = field
                this.sortOrder = order

                this.loadAsyncData()
            },

            setValuePartner(value) {
                this.onFilterChange({ partenaire_id: value.id })
            },

            onFilterChange(filter) {
                var filters = []
                Object.keys(filter).forEach(element => {
                    var currentFilter = {
                        key: element,
                        value: filter[element]
                    }

                    filters.push(currentFilter)
                })
                this.filters = filters
                this.loadAsyncData()
            },

            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },

            sendEditColumn(data) {
                this.axios.post(`/admin/sites/edit-column`, {
                    id: data.id,
                    field: data.field,
                    value: data.value
                })
            }
        }
    }
</script>

<style>
    .-mt-1 {
        margin-top: -0.5em;
    }

    .media-content p.text {
        margin-bottom: 0;
    }

    .is-editable {
        padding: 0.5em;
        border-radius: 5px;
        cursor: pointer;
    }

    .is-editable:hover {
        background: #eee;
    }
</style>
