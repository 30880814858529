export default {
    setList(state, data) {
        state.list = data.groups
        state.total = data.groups.length
        state.isLoading = false
        state.loaded = true
    },
    setLoading(state) {
        state.isLoading = true
        state.loaded = false
    },
    setAdminList(state, data) {
        state.adminList = data
    },
    setGroups(state, data) {
        state.groups = data
    },
    setTranslatedGroups(state, data) {
        state.translatedGroups = data
    }
}
