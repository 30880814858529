<template>
    <div>
        <b-button
            icon-left="plus-circle"
            type="is-warning"
            class="partenaires-sites-ajout mr-2"
            @click="newAlert = true"
        >
            {{ $t('alertes.setalert') }}
        </b-button>
        <b-modal v-model="newAlert">
            <div v-if="loading">
                <div class="d-flex justify-content-center align-items-center preloading">
                    <div class="loader-xl" />
                </div>
            </div>
            <template v-else>
                <header class="modal-card-head has-background-warning">
                    <p class="modal-card-title m-0">
                        {{ model.libelle ? $t('alertes.updatealert') : $t('alertes.setalert') }}
                    </p>
                </header>
                <section class="modal-card-body">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <b-field :label="$t('alertes-partenaires.text')">
                                    <b-input
                                        v-model="model.libelle"
                                        :placeholder="$t('alertes-partenaires.text')"
                                        required
                                    />
                                </b-field>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <b-field :label="$t('alertes-partenaires.frequency')">
                                    <b-select
                                        v-model="model.frequence"
                                        :placeholder="$t('alertes-partenaires.frequency')"
                                        required
                                        expanded
                                    >
                                        <option
                                            v-for="(frequency, index) in frequences"
                                            :key="index"
                                            :value="frequency.id"
                                        >
                                            {{ frequency.text }}
                                        </option>
                                    </b-select>
                                </b-field>
                            </div>
                            <div class="col-md-6">
                                <b-field :label="$t('global.recipient')">
                                    <b-input
                                        v-model="model.destinataire"
                                        :placeholder="$t('global.recipient')"
                                        required
                                    />
                                </b-field>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <b-field label="Site">
                                    <b-select v-model="model.site" placeholder="Site" expanded @input="siteSelected">
                                        <option v-for="(site, index) in sites" :key="index" :value="site">
                                            {{ site.name }}
                                        </option>
                                    </b-select>
                                </b-field>
                            </div>
                            <div class="col-md-6">
                                <b-field :label="$t('commande.maincategory')">
                                    <b-input
                                        v-model="model.main_category"
                                        :placeholder="$t('commande.maincategory')"
                                        disabled=""
                                    />
                                </b-field>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <b-field :label="$t('alertes-partenaires.multipleselect')">
                                    <div class="d-flex flex-column w-100">
                                        <b-taglist class="mb-0">
                                            <b-tag v-for="(category, index) in selectedCategory" :key="index">
                                                {{ category.name }}
                                            </b-tag>
                                        </b-taglist>
                                        <b-dropdown
                                            v-model="selectedCategory"
                                            max-height="100"
                                            multiple
                                            scrollable
                                            expanded
                                            mobile-modal
                                        >
                                            <template #trigger>
                                                <b-button type="is-warning" icon-right="caret-down">
                                                    Catégories ({{ selectedCategory.length }})
                                                </b-button>
                                            </template>
                                            <b-dropdown-item v-for="(group, index) in list" :key="index" :value="group">
                                                {{ group.name }}
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </b-field>
                            </div>
                        </div>
                        <div v-if="model.site && model.site.group_id === 1" class="row mt-3">
                            <div class="col-md-4">
                                <b-button class="btn-secondary" @click="addParents">
                                    {{ $t('alertes-partenaires.addparent') }}
                                </b-button>
                            </div>
                            <div class="col-md-4">
                                <b-button class="btn-primary" @click="addChilds">
                                    {{ $t('alertes-partenaires.addchild') }}
                                </b-button>
                            </div>
                        </div>
                        <div id="option2" class="mt-3">
                            <p><strong>Options</strong></p>
                            <b-field>
                                <b-checkbox
                                    v-model="model.no_partner"
                                    :true-value="true"
                                    :false-value="false"
                                    type="is-warning"
                                >
                                    Recevoir les mots clés sans partenaires intéressés
                                </b-checkbox>
                            </b-field>
                            <b-field>
                                <b-checkbox
                                    v-model="model.two_month"
                                    :true-value="true"
                                    :false-value="false"
                                    type="is-warning"
                                >
                                    Recevoir les alertes des 2 mois précédents
                                </b-checkbox>
                            </b-field>
                        </div>
                    </form>
                </section>
                <footer class="modal-card-foot h-auto justify-content-end">
                    <b-button :label="$t('global.cancel')" type="is-primary" @click="closeAddAlert" />
                    <b-button :label="$t('global.save')" type="is-warning" @click="addAlert" />
                </footer>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        data: () => ({
            model: {
                main_category: '',
                category_id: [],
                no_partner: false,
                two_month: false,
                site: null
            },
            newAlert: false,
            frequences: [
                { text: 'Quotidienne', id: 1 },
                { text: 'Hebdomadaire', id: 7 },
                { text: 'Mensuelle', id: 30 }
            ],
            sites: [],
            selectedCategory: [],
            loading: false
        }),
        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('group', ['list']),
            langues() {
                return [
                    { text: 'Français', id: 0 },
                    { text: 'Anglais', id: 1 }
                ]
            }
        },
        props: {
            value: {
                type: Object,
                default: () => ({})
            }
        },
        watch: {
            value(v) {
                if (v && v.id) {
                    this.newAlert = true
                }
            },
            newAlert(value) {
                if (value) {
                    this.initialize()
                }
            }
        },
        mounted() {},
        methods: {
            ...mapActions('auth', { getUser: 'me' }),
            ...mapActions('alert_partner', {
                createAlert: 'create',
                updateAlert: 'update'
            }),
            ...mapActions('group', { listGroups: 'list' }),
            ...mapActions('sites', { getSites: 'getAllSitesFromUser' }),
            initialize() {
                this.loading = true
                const getUser = this.getUser()
                const getSites = this.getSites()
                const listGroup = this.listGroups()

                Promise.all([getUser, getSites, listGroup]).then(values => {
                    let user = values[0]
                    let sites = values[1]

                    this.model.lang = user.local === 'en' ? 'com_en' : 'fr_fr'

                    this.sites = sites.map(site => {
                        return {
                            id: site.id,
                            name: site.url,
                            group_id: site.group_id
                        }
                    })

                    this.model.destinataire = this.user.email

                    this.loading = false

                    if (this.value) {
                        this.loadAlert(this.value)
                    }
                })
            },
            addParents() {
                let parentsList = this.list
                    .filter(group => !group.parent_id)
                    .map(group => {
                        return group
                    })
                for (let idx = 0; idx < parentsList.length; idx++) {
                    const parent = parentsList[idx]
                    if (!this.selectedCategory.find(group => group.id === parent.id)) {
                        this.selectedCategory.push(parent)
                    }
                }
            },

            addChilds() {
                let childsList = this.list
                    .filter(group => group.parent_id)
                    .map(group => {
                        return group
                    })

                for (let idx = 0; idx < childsList.length; idx++) {
                    const child = childsList[idx]
                    if (!this.selectedCategory.find(group => group.id === child.id)) {
                        this.selectedCategory.push(child)
                    }
                }
            },

            siteSelected() {
                this.model.category_id = []
                this.selectedCategory = []
                this.model.main_category = ''
                if (this.model.site) {
                    for (let idx = 0; idx < this.list.length; idx++) {
                        const group = this.list[idx]
                        if (group.id === this.model.site.group_id) {
                            this.model.main_category = group.name
                            this.selectedCategory.push(group)
                        }
                        if (this.model.site.group_id !== 1 && group.parent_id === this.model.site.group_id) {
                            this.selectedCategory.push(group)
                        }
                    }
                }
            },

            addAlert() {
                this.model.site_id = this.model.site.id
                this.model.category_id = this.selectedCategory.map(cat => cat.id)
                if (this.model.destinataire != null && this.model.site.id != null && this.model.frequence != null) {
                    if (this.model.id) {
                        this.updateAlert(this.model)
                            .then(() => {
                                this.newAlert = false
                                this.model = {}
                                this.$emit('save')
                            })
                            .catch(response => {
                                if (response.status === 422) {
                                    this.$refs.alert.showValidationError(response.data)
                                } else {
                                    this.$refs.alert.displayMsg('danger', response)
                                }
                            })
                    } else {
                        this.createAlert(this.model)
                            .then(() => {
                                this.newAlert = false
                                this.model = {}
                                this.$emit('save')
                            })
                            .catch(response => {
                                if (response.status === 422) {
                                    this.$refs.alert.showValidationError(response.data)
                                } else {
                                    this.$refs.alert.displayMsg('danger', response)
                                }
                            })
                    }
                }
            },
            closeAddAlert() {
                this.model = {
                    main_category: '',
                    category_id: [],
                    no_partner: false,
                    two_month: false,
                    site: null
                }
                this.$emit('input', {})
                this.newAlert = false
            },
            loadAlert(alert) {
                this.model = Object.assign({}, alert)
                if (this.sites.length > 0) {
                    this.model.site = this.sites.find(site => {
                        return site.id === alert.site_id
                    })
                }

                if (this.model.category_id && !Array.isArray(this.model.category_id)) {
                    this.model.category_id = this.model.category_id.split(',')
                }
                this.selectedCategory = []
                this.model.main_category = alert.site.group.name

                for (let idx = 0; idx < this.model.category_id.length; idx++) {
                    const category = this.model.category_id[idx]
                    this.selectedCategory.push(
                        this.list.find(group => {
                            return group.id === category
                        })
                    )
                }
            }
        }
    }
</script>

<style></style>
