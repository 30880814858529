<template>
    <nl-modal :open-modal="openModal" title="Modifier les Crédits d'un Utilisateur" @hidden="close">
        <template v-if="user">
            <nl-alert ref="alert" />
            <v-form ref="updateCredits">
                <div class="form-group">
                    <text-field v-model="user.user" disabled label="Email" />
                </div>
                <div class="form-group">
                    <text-field
                        v-model="amount"
                        type="number"
                        :min="-99999"
                        name="Crédits"
                        label="Ajouter ou Soustraire des Euros en Crédit"
                        rules="required"
                    />
                </div>
                <div class="form-group">
                    <text-field v-model="log" type="text" name="Motif" label="Motif" rules="required" />
                </div>
                <div class="form-group">
                    <text-field v-model="user.credit_amount" disabled label="Credit Actuel" />
                </div>
            </v-form>
        </template>
        <template #footer>
            <button class="btn btn-primary" @click="save">
                {{ $t('global.modify') }}
            </button>
            <button class="btn btn-danger" @click="close">
                {{ $t('global.cancel') }}
            </button>
        </template>
    </nl-modal>
</template>

<script>
    import { mapActions } from 'vuex'
    import { EventManager } from '@/components/EventManager'

    export default {
        components: {
            EventManager
        },
        data: () => ({
            openModal: false,
            user: null,
            amount: null,
            log: null
        }),
        created() {
            EventManager.$on('onUpdateCredit', this.init)
        },
        methods: {
            ...mapActions('users', { updateCredits: 'updateCredits' }),
            init(data) {
                this.user = Object.assign({}, data)
                this.openModal = true
            },
            close() {
                this.user = Object.assign({})
                this.amount = null
                this.log = null
                this.$refs.updateCredits.reset()
                this.openModal = false
                this.$refs.alert.clean()
            },
            save() {
                this.$refs.alert.clean()
                this.$refs.updateCredits.validate().then(success => {
                    if (success) {
                        this.updateCredits({
                            id: this.user.id,
                            amount: this.amount,
                            log: this.log
                        })
                            .then(data => {
                                this.$emit('saved', data)
                                this.close()
                            })
                            .catch(response => {
                                if (response.status == 422) {
                                    this.$refs.alert.showValidationError(response.data)
                                } else {
                                    this.$refs.alert.displayMsg('danger', response)
                                }
                            })
                    }
                })
            }
        }
    }
</script>

<style></style>
