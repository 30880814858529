<template>
    <div class="card">
        <div class="card-header">Briefs</div>
        <div class="card-body">
            <div class="row h-100">
                <div v-for="(brief_type, key) in BRIEF_TYPES" :key="`brief_type${key}`" class="col-md-4 h-100">
                    <div class="h-100 d-flex flex-column">
                        <div class="d-flex justify-content-between align-items-center">
                            <div
                                class="d-flex align-items-center justify-content-between cursor-pointer mb-2"
                                @click="edit(brief_type.key)"
                            >
                                <h5 class="title is-5 mb-0">
                                    {{ brief_type.name }}
                                </h5>
                                <b-icon pack="fas" icon="pen" size="is-small" class="ml-2" />
                            </div>
                        </div>
                        <div
                            v-if="editing !== brief_type.key && briefs[brief_type.key]"
                            style="font-size: 0.9em; min-height: 400px; max-height: 400px; white-space: pre-line"
                            class="overflow-auto h-100"
                            @dblclick="edit(brief_type.key, `input_${brief_type.key}`)"
                        >
                            {{ briefs[brief_type.key] }}
                        </div>
                        <b-input
                            v-show="editing === brief_type.key || !briefs[brief_type.key]"
                            :ref="`input_${brief_type.key}`"
                            v-model="briefs[brief_type.key]"
                            type="textarea"
                            style="
                                font-size: 0.9rem !important;
                                flex: 1 1 auto !important;
                                min-height: 400px;
                                max-height: 400px;
                                white-space: pre-line;
                            "
                            custom-class="textarea"
                            @keydown.native.esc="editing = null"
                            @blur="update(brief_type)"
                            @focus="edit(brief_type.key, `input_${brief_type.key}`)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import Textarea from '../Form/Textarea'

    const BRIEF_TYPES = [
        {
            name: 'Client',
            key: 'client',
            dbName: 'brief_client'
        },
        {
            name: 'Onsite',
            key: 'onsite',
            dbName: 'notes'
        },
        {
            name: 'Offsite',
            key: 'offsite',
            dbName: 'brief_consultant'
        }
    ]
    export default {
        name: 'Briefs',
        components: { Textarea },
        computed: {
            ...mapState('campaigns', {
                briefs: state => state.campaign.briefs
            })
        },
        data() {
            return {
                BRIEF_TYPES: BRIEF_TYPES,
                editing: null
            }
        },
        methods: {
            ...mapActions('campaigns', ['updateCampaign']),
            update(type) {
                this.updateCampaign({
                    campaignId: this.$route.query.id,
                    [type.dbName]: this.briefs[type.key]
                })
                this.editing = null
            },
            edit(key) {
                this.editing = key
                this.$refs[`input_${key}`][0].focus()
            }
        }
    }
</script>

<style>
    .textarea {
        font-size: 0.9rem !important;
        flex: 1 1 auto !important;
        min-height: 100% !important;
    }
</style>
