<template>
    <fieldset>
        <legend>{{ legend }}</legend>
        <slot />
    </fieldset>
</template>
<script>
    export default {
        props: {
            legend: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    legend {
    }
</style>
