import Checkbox from '@/components/Form/Checkbox.vue'
import Fieldset from '@/components/Form/Fieldset.vue'
import Form from '@/components/Form/Form.vue'
import RadioList from '@/components/Form/RadioList.vue'
import Select from '@/components/Form/Select.vue'
import TextField from '@/components/Form/TextField.vue'
import Textarea from '@/components/Form/Textarea.vue'
import NlLoader from '@/components/UI/NlLoader.vue'
import NlModal from '@/components/UI/NlModal.vue'
import Tab from '@/components/UI/Tabs/Tab.vue'
import TabItem from '@/components/UI/Tabs/TabItem.vue'
import TabItems from '@/components/UI/Tabs/TabItems.vue'
import Tabs from '@/components/UI/Tabs/Tabs.vue'

const Components = {
    install(Vue) {
        Vue.component('v-textfield', TextField)
        Vue.component('v-checkbox', Checkbox)
        Vue.component('radio-list', RadioList)
        Vue.component('nl-select', Select)
        Vue.component('nl-modal', NlModal)
        Vue.component('v-textarea', Textarea)
        Vue.component('v-fieldset', Fieldset)
        Vue.component('v-form', Form)
        Vue.component('NlLoader', NlLoader)

        Vue.component('Tabs', Tabs)
        Vue.component('Tab', Tab)
        Vue.component('TabItems', TabItems)
        Vue.component('TabItem', TabItem)
    }
}

export default Components
