<template>
    <div>
        <b-loading v-model="isLoading" :is-full-page="true" />
        <add-keyword
            :active-modal="addKeywordModal"
            @closeModal="addKeywordModal = false"
            @saved="forceUpdate = !forceUpdate"
        />

        <add-source
            :active-modal="addSourceModal"
            :sources="sources"
            @closeModal="addSourceModal = false"
            @addSource="updateKeywords('stored_keyword_source_id', $event)"
        />

        <nl-table
            :columns.sync="columns"
            :checked-rows.sync="checkedRows"
            :left-buttons="buttons"
            :get-data="loadAsyncData"
            :actions="actions"
            :force-update="forceUpdate"
            :per-page="100"
            @filtersChanged="filtersChanged"
        >
            <template #kpis>
                <div class="d-flex align-items-center">
                    <div class="pt-3 pb-3" style="position: relative; width: max-content">
                        <b-taglist attached class="m-0">
                            <b-tag type="is-warning" size="is-medium" class="m-0">
                                {{ $t('global.keywords') }}
                            </b-tag>
                            <b-tag type="is-dark" size="is-medium" class="m-0">
                                {{ total | toThousands }}
                            </b-tag>
                        </b-taglist>
                        <b-loading v-model="countIsLoading" :is-full-page="false" />
                    </div>
                    <b-button
                        class="ml-1"
                        type="is-danger"
                        icon-left="trash"
                        size="is-small"
                        title="Supprimer tous les mots clés filtrés"
                        @click="confirmDelete(true)"
                    >
                        Supprimer
                    </b-button>
                </div>
            </template>

            <template #add-left-buttons>
                <b-field class="file is-dark">
                    <b-tooltip label='CSV UTF-8 (1ère ligne ignorée) | séparateur ; | encadrement " | échappement \'>
                        <b-upload v-model="file" class="file-label" @input="uploadCSV">
                            <span class="file-cta">
                                <b-icon class="file-icon" icon="upload" />
                                <span class="file-label">Importer des mots clés</span>
                            </span>
                            <span v-if="file" class="file-name">
                                {{ file.name }}
                            </span>
                        </b-upload>
                    </b-tooltip>
                    <b-loading v-model="csvIsLoading" :is-full-page="false" />
                </b-field>
            </template>
            <!-- Statut column-->
            <template #stored_keyword_status_idSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small">
                    <option :value="null">
                        {{ $t('global.all') }}
                    </option>
                    <!-- TODO parcourir this.statuses avec v-for pour l'affichage des options -->
                    <option value="4">
                        {{ $t('global.new') }}
                    </option>
                    <option value="1">
                        {{ $t('global.active') }}
                    </option>
                </b-select>
            </template>

            <template #stored_keyword_status_id="{ props }">
                <b-tag :type="statuses[props.row.stored_keyword_status_id].tagType">
                    {{ statuses[props.row.stored_keyword_status_id].label }}
                </b-tag>
            </template>

            <!-- Mot clé column-->
            <template #keyword="{ props }">
                <router-link :to="'/search/keyword?keyword=' + encodeURIComponent(props.row.keyword)">
                    {{ props.row.keyword }}
                </router-link>
            </template>

            <!-- Volume column-->
            <template #volumeSearch="{ props }">
                <div class="d-flex">
                    <b-numberinput
                        v-model="props.filters['min_volume_count']"
                        :controls="false"
                        size="is-small"
                        placeholder="min."
                    />
                    <b-numberinput
                        v-model="props.filters['max_volume_count']"
                        :controls="false"
                        size="is-small"
                        placeholder="max."
                    />
                </div>
            </template>

            <template #volume="{ props }">
                {{ props.row.volume }}
            </template>

            <!-- Source column-->
            <template #stored_keyword_source_idSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small">
                    <option :value="null">
                        {{ $t('global.all') }}
                    </option>
                    <option v-for="(value, key) in sources" :key="key" :value="key">
                        {{ value }}
                    </option>
                </b-select>
            </template>

            <template #stored_keyword_source_id="{ props }">
                {{ sources[props.row.stored_keyword_source_id] ? sources[props.row.stored_keyword_source_id] : '-' }}
            </template>

            <!-- Disponible column-->
            <template #is_availableSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small">
                    <option :value="null">
                        {{ $t('global.all') }}
                    </option>
                    <option value="1">Oui</option>
                    <option value="0">Non</option>
                </b-select>
            </template>

            <template #is_available="{ props }">
                <b-icon v-if="props.row.is_available" icon="check" type="is-success" />
                <template v-else> -</template>
            </template>

            <!-- Priorité column-->
            <template #is_hotSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small">
                    <option :value="null">
                        {{ $t('global.all') }}
                    </option>
                    <option value="1">Oui</option>
                    <option value="0">Non</option>
                </b-select>
            </template>

            <template #is_hot="{ props }">
                <b-icon v-if="props.row.is_hot" icon="fire" type="is-danger" />
                <template v-else> -</template>
            </template>

            <!-- Tag column-->
            <template #stored_keyword_tag_idSearch="{ props }">
                <b-dropdown v-model="props.filters[props.column.field]" multiple aria-role="list">
                    <template #trigger>
                        <b-button icon-right="caret-down" size="is-small">
                            Tags ({{
                                props.filters[props.column.field] ? props.filters[props.column.field].length : 0
                            }})
                        </b-button>
                    </template>
                    <b-dropdown-item v-for="(value, key) in tags" :key="key" :value="key" aria-role="listitem">
                        <span>{{ value }}</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>

            <template #stored_keyword_tag_id="{ props }">
                <b-tag v-if="props.row.stored_keyword_tag_id" type="is-danger is-light">
                    {{ tags[props.row.stored_keyword_tag_id] }}
                </b-tag>
                <span v-else>-</span>
            </template>

            <!-- Langue column-->
            <template #langSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small">
                    <option :value="null">
                        {{ $t('global.all') }}
                    </option>
                    <option value="fr_fr">Français</option>
                    <option value="en_en">English</option>
                </b-select>
            </template>

            <template #lang="{ props }">
                <flag :iso="'en' === props.row.lang ? 'us' : 'fr'" />
            </template>
        </nl-table>
        <Toaster ref="toaster" />
    </div>
</template>

<script>
    import ApiService from '../../../services/api.service'
    import NlTable from '@/components/UI/Table/NlTable'
    import AddKeyword from '@/components/Admin/Keyword/AddKeyword'
    import { mapActions } from 'vuex'

    export default {
        name: 'KeywordTable',
        components: {
            AddKeyword,
            NlTable
        },
        data() {
            return {
                total: 0,
                countIsLoading: false,
                isLoading: false,
                csvIsLoading: false,
                selectedProject: null,
                editProject: null,
                appUrl: process.env.LARAVEL_APP_URL,
                forceUpdate: false,
                checkedRows: [],
                addKeywordModal: false,
                addSourceModal: false,
                file: null,
                filters: {},
                buttons: [
                    {
                        label: this.$t('global.addkeywords'),
                        icon: 'plus-circle',
                        type: 'is-warning',
                        action: () => this.$router.push('/admin/keywords/bulk')
                    },
                    {
                        label: this.$t('commande.addkeyword'),
                        icon: 'plus-circle',
                        type: 'is-warning',
                        action: () => (this.addKeywordModal = true)
                    }
                ],
                statuses: {
                    1: { label: this.$t('global.active'), tagType: 'is-success' },
                    4: { label: this.$t('global.new'), tagType: 'is-info' }
                },
                tags: {
                    1: 'Anglais',
                    2: 'Caractères interdits',
                    3: 'URL',
                    4: 'Mal encodé',
                    5: 'Marque',
                    6: 'Court 2',
                    7: 'Court 3',
                    8: 'Mono mot',
                    9: 'Question',
                    10: 'Affiliation',
                    11: 'Caractères spéciaux',
                    12: 'Date 1900',
                    13: 'Date début 2000',
                    14: 'Date courante',
                    15: 'Date fin 2000',
                    16: 'Numérique',
                    17: '4 mots',
                    18: '5 mots',
                    19: '6 mots',
                    20: '7 mots',
                    21: '8 mots',
                    22: '9 à 11 mots',
                    23: '12 à 16 mots',
                    24: 'Plus de 16 mots'
                },
                sources: {
                    1: 'Ahrefs',
                    2: 'Semrush',
                    3: 'Seobserver',
                    4: 'GSC',
                    5: 'Nextlevel'
                },
                columns: [
                    {
                        label: this.$t('global.status'),
                        field: 'stored_keyword_status_id',
                        centered: true,
                        width: '9rem',
                        sortable: false,
                        searchable: true,
                        custom: true,
                        customSearch: true
                    },
                    {
                        label: this.$t('global.keyword'),
                        field: 'keyword',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        custom: true
                    },
                    {
                        label: this.$t('global.quantity'),
                        field: 'volume',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        custom: false,
                        customSearch: true
                    },
                    {
                        label: this.$t('global.source'),
                        field: 'stored_keyword_source_id',
                        centered: true,
                        sortable: false,
                        searchable: true,
                        custom: true,
                        customSearch: true
                    },
                    {
                        label: this.$t('global.available'),
                        field: 'is_available',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        custom: true,
                        customSearch: true
                    },
                    {
                        label: this.$t('global.priority'),
                        field: 'is_hot',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        custom: true,
                        customSearch: true
                    },
                    {
                        label: this.$t('global.tag'),
                        field: 'stored_keyword_tag_id',
                        centered: true,
                        sortable: false,
                        searchable: true,
                        custom: true,
                        customSearch: true
                    },
                    {
                        label: this.$t('global.locale'),
                        field: 'lang',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        custom: true,
                        customSearch: true
                    }
                ],
                actions: [
                    {
                        label: 'Supprimer',
                        action: () => {
                            this.confirmDelete()
                        }
                    },
                    {
                        label: 'Activer',
                        action: () => {
                            this.updateKeywords('stored_keyword_status_id', 1, this.checkedRows)
                        }
                    },
                    {
                        label: 'Priorité Haute',
                        action: () => {
                            this.updateKeywords('is_hot', 1, this.checkedRows)
                        }
                    },
                    {
                        label: 'Priorité Normale',
                        action: () => {
                            this.updateKeywords('is_hot', 0, this.checkedRows)
                        }
                    },
                    {
                        label: 'Sourcer',
                        action: () => (this.addSourceModal = true)
                    }
                ]
            }
        },
        methods: {
            ...mapActions('keyword', ['getKeywords', 'upload', 'count']),
            loadAsyncData(params) {
                // debugger
                let keywords = this.getKeywords(params)
                this.countIsLoading = true
                this.count(params)
                    .then(response => {
                        this.total = response
                    })
                    .finally(() => {
                        this.countIsLoading = false
                    })
                return keywords
            },
            getTagType(status) {
                return 'is-' + this.statuses[status].tagType
            },
            filtersChanged(filters) {
                // debugger
                this.filters = filters
            },
            uploadCSV() {
                let formData = new FormData()
                formData.append('file', this.file)
                this.csvIsLoading = true
                this.upload(formData)
                    .then(response => {
                        this.$buefy.snackbar.open({
                            message: response.message,
                            position: 'is-top',
                            indefinite: true
                        })
                    })
                    .catch(error => {
                        this.$buefy.snackbar.open({
                            message: error.data.message,
                            type: 'is-danger',
                            position: 'is-top',
                            indefinite: true
                        })
                    })
                    .finally(() => {
                        this.file = null
                        this.csvIsLoading = false
                        this.forceUpdate = !this.forceUpdate
                    })
            },
            updateKeywords(field, value, checkedRows = []) {
                let ids = this.checkedRows.map(row => row.id)
                ApiService.put('/admin/keywords/bulk', {
                    field: field,
                    value: value,
                    ids: ids
                })
                    .then(response => {
                        let toastParams = {
                            message: response.data.message,
                            type: 'is-success',
                            duration: 3000
                        }
                        this.$buefy.toast.open(toastParams)
                    })
                    .catch(error => {
                        let toastParams = {
                            message: error.message,
                            type: 'is-danger',
                            duration: 3000
                        }
                        this.$buefy.toast.open(toastParams)
                    })
                    .finally(() => {
                        this.forceUpdate = !this.forceUpdate
                        this.checkedRows = []
                    })
            },
            confirmDelete(filteredKeywords = false) {
                this.$buefy.dialog.confirm({
                    title: 'Supprimer les mots clés',
                    message: filteredKeywords
                        ? 'Êtes-vous sûr de vouloir supprimer tous les mots clés filtrés ?'
                        : 'Êtes-vous sûr de vouloir supprimer les mots clés sélectionnés ?',
                    confirmText: 'Supprimer',
                    cancelText: 'Annuler',
                    type: 'is-danger',
                    size: 'is-medium',
                    onConfirm: () => {
                        if (filteredKeywords) {
                            this.deleteFilteredKeywords()
                        } else {
                            this.deleteKeywords()
                        }
                    }
                })
            },
            deleteFilteredKeywords() {
                this.isLoading = true
                ApiService.post('/admin/keywords/delete-filtered', this.filters)
                    .then(response => {
                        let toastParams = {
                            message: response.data.message,
                            type: 'is-success',
                            duration: 3000
                        }
                        this.$buefy.toast.open(toastParams)
                    })
                    .catch(error => {
                        debugger
                        let toastParams = {
                            message: error.response.data.message,
                            type: 'is-danger',
                            duration: 3000
                        }
                        this.$buefy.toast.open(toastParams)
                    })
                    .finally(() => {
                        this.isLoading = false
                        this.forceUpdate = !this.forceUpdate
                        this.checkedRows = []
                    })
            },
            deleteKeywords() {
                let ids = this.checkedRows.map(row => row.id)
                ApiService.post('/admin/keywords/bulk-delete', {
                    ids: ids
                })
                    .then(response => {
                        let toastParams = {
                            message: response.data.message,
                            type: 'is-success',
                            duration: 3000
                        }
                        this.$buefy.toast.open(toastParams)
                    })
                    .catch(error => {
                        let toastParams = {
                            message: error.message,
                            type: 'is-danger',
                            duration: 3000
                        }
                        this.$buefy.toast.open(toastParams)
                    })
                    .finally(() => {
                        this.forceUpdate = !this.forceUpdate
                        this.checkedRows = []
                    })
            },
            updateTabs() {
                this.$emit('updateTabs')
            }
        },
        mounted() {
            // ApiService.get("/admin/keywords/tags")
            //     .then(({data}) => {
            //       this.tags = data
            //     })
            //     .catch(({response}) => {
            //
            //     })
            // ApiService.get("/admin/keywords/sources")
            //     .then(({data}) => {
            //       this.sources = data
            //     })
            //     .catch(({response}) => {
            //
            //     })
        }
    }
</script>
