<template>
    <ValidationProvider :name="name" :rules="rules" slim>
        <template #default="{ valid, touched }">
            <b-field
                grouped
                label="Ancre du lien"
                :type="{ 'is-danger': !valid && touched }"
                :message="!valid && touched ? 'Veuillez saisir une ancre' : ''"
            >
                <b-autocomplete
                    :value="value"
                    @input="handleInput"
                    :data="filteredSuggestions"
                    dropdown-position="top"
                    clearable
                    open-on-focus
                    expanded
                    required
                >
                    <template #empty> Pas de résultats</template>
                </b-autocomplete>

                <div class="d-flex" style="gap: 0.5rem">
                    <b-button
                        icon-left="random"
                        type="is-warning"
                        :title="$t('partenaire-site.get_random_anchor')"
                        @click="useOtherAnchor()"
                    />
                    <b-button
                        type="is-warning"
                        :disabled="loading"
                        :title="$t('partenaire-site.get_generic_anchor')"
                        @click="getRandomAnchor('linkAnchor')"
                        :icon-left="loading ? 'circle-notch fa-spin' : 'anchor'"
                    />
                </div>
            </b-field>
        </template>
    </ValidationProvider>
</template>

<script>
    import axios from 'axios'
    import { ValidationProvider } from 'vee-validate'

    export default {
        name: 'PurchaseModalAnchor',
        components: { ValidationProvider },
        props: {
            name: {
                type: String,
                required: true
            },
            keywords: {
                type: Array,
                required: true
            },
            value: {
                type: String,
                required: false
            },
            rules: {
                type: String,
                default: 'required'
            },
            suggestions: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                loading: false
            }
        },
        methods: {
            useOtherAnchor() {
                this.handleInput(this.keywords[Math.floor(Math.random() * this.keywords.length)])
            },
            getRandomAnchor() {
                this.loading = true

                axios
                    .get(`/config/randomAnchor`)
                    .then(({ data }) => {
                        if (data?.anchor) {
                            this.handleInput(data?.anchor)
                        } else {
                            this.snackNotif('Impossible de récupérer une ancre générique.', 'is-error')
                        }
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        this.loading = false
                    })
            },
            handleInput(value) {
                this.$emit('input', value)
            }
        },
        computed: {
            filteredSuggestions() {
                return this.suggestions.filter(option => {
                    return option
                        .toString()
                        .toLowerCase()
                        .includes(this.value?.toLowerCase() ?? '')
                })
            }
        }
    }
</script>