<template>
    <div>
        <div v-if="preloading">
            <button class="btn btn-secondary"><i class="fas fa-cog fa-spin"></i> {{ $t('global.loading') }}...</button>
        </div>

        <div v-else>
            <div class="d-flex" style="gap: 10px">
                <StatCard :color="'success'" :icon="'fas fa-check'">
                    <template v-slot:counter>
                        <h3>{{ counts.published }}</h3>
                    </template>
                    <template v-slot:text>
                        <span>Commandes publiées</span>
                    </template>
                </StatCard>
                <StatCard :color="'warning'" :icon="'fas fa-spinner'">
                    <template v-slot:counter>
                        <h3>{{ counts.inProgress }}</h3>
                    </template>
                    <template v-slot:text>
                        <span>Commandes en cours</span>
                    </template>
                </StatCard>
                <StatCard :color="'danger'" :icon="'fas fa-ban'">
                    <template v-slot:counter>
                        <h3>{{ counts.canceled }}</h3>
                    </template>
                    <template v-slot:text>
                        <span>Commandes annulées</span>
                    </template>
                </StatCard>
            </div>
        </div>

        <section>
            <TrackingOrderTableWithoutTab
                :tab-info="tab"
                :projects="projects"
                @loadAsyncData="loadAsyncData"
                @moveProject="moveProject"
                @getDataUrl="getDataUrl"
            />
        </section>
    </div>
</template>

<script>
    import { EventManager } from '@/components/EventManager.js'
    import TrackingOrderTableWithoutTab from '../../Dashboard/TrackingOrder/TrackingOrderTabWithoutTab'

    export default {
        name: 'TrackingOrder',

        components: { TrackingOrderTableWithoutTab },

        data: function () {
            return {
                tabs: [],
                tab: {},
                counts: null,
                projects: [],

                orders: [],
                ordersInProgress: [],
                ordersPublished: [],
                ordersCanceled: [],

                statusToBeAccepted: 'a_valider',
                statusToBeTreated: 'en_cours',
                statusTerminate: 'termine',
                statusCanceled: 'annule',
                statusRefusal: 'refuse',
                statusInProgress: 'inProgress',

                //UI
                activeTab: 0,
                preloading: false,
                isLoading: false
            }
        },
        watch: {
            project() {
                this.getData()
            }
        },

        computed: {
            project() {
                return this.$store.state.project
            }
        },

        created() {
            EventManager.$on('project:loaded', data => {
                this.projects = data.projects

                var project = this.$store.state.project_selector.emptyProject
                project.name = this.$t(project.name)

                this.projects.unshift(project)
            })

            this.getData()
            this.preloading = true
        },

        methods: {
            getData: function () {
                this.ordersCanceled = []
                this.ordersInProgress = []
                this.ordersPublished = []

                this.axios
                    .get(`/admin/user-details/track-orders/${this.$route.query.id}`)
                    .then(response => {
                        this.orders = response.data.orders
                        this.projects = response.data.projects
                        let counts = response.data.counts

                        let countInProgress = counts
                            .filter(
                                key => key.statut === this.statusToBeAccepted || key.statut === this.statusToBeTreated
                            )
                            .reduce((a, b) => a + parseInt(b.total), 0)
                        let countCanceled = counts
                            .filter(key => key.statut === this.statusCanceled || key.statut === this.statusRefusal)
                            .reduce((a, b) => a + parseInt(b.total), 0)
                        let countPublished = counts.find(key => key.statut === this.statusTerminate)

                        this.counts = {
                            inProgress: countInProgress,
                            canceled: countCanceled,
                            published: countPublished ? countPublished.total : 0
                        }

                        this.formatTab()
                        this.preloading = false
                        this.isLoading = false
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
            },

            getDataUrl(params) {
                this.axios
                    .get(`/tracking-order/${params.order_id}/data`)
                    .then(response => {
                        params.callback(response.data.data)
                    })
                    .catch(error => {
                        this.snackNotif('Error', 'is-danger')
                        console.error(error)
                        params.callback([])
                    })
            },

            moveProject(params) {
                this.isLoading = true

                this.axios
                    .post(`/tracking-order/move`, {
                        project_id: params.project_id,
                        ids: params.ids,
                        _method: 'PATCH'
                    })
                    .then(response => {
                        this.snackNotif('Commande(s) déplacée(s) avec succès')

                        this.passOnDataChange(params, response.data.project)
                    })
                    .catch(error => {
                        this.snackNotif('Error', 'is-danger')
                        console.error(error)
                    })
                    .finally(() => (this.isLoading = false))
            },

            passOnDataChange(params, newProject) {
                this.orders.data = this.orders.data.map(order => {
                    if (params.ids.includes(order.id)) {
                        order.project_id = params.project_id
                        order.project = newProject

                        if (order.statut === this.statusTerminate) {
                            this.counts.published = this.counts.published - 1
                        }

                        if (order.statut === this.statusRefusal || order.statut === this.statusCanceled) {
                            this.counts.canceled = this.counts.canceled - 1
                        }

                        if (order.statut === this.statusToBeAccepted || order.statut === this.statusToBeTreated) {
                            this.counts.inProgress = this.counts.inProgress - 1
                        }
                    }

                    return order
                })
                this.orders.data = this.orders.data.filter(order => !params.ids.includes(order.id))
                this.orders.total = this.orders.total - params.ids.length

                if (this.ordersInProgress.hasOwnProperty('data')) {
                    this.ordersInProgress.data = this.ordersInProgress.data.map(order => {
                        if (params.ids.includes(order.id)) {
                            order.project_id = params.project_id
                            order.project = newProject
                        }

                        return order
                    })
                    this.ordersInProgress.data = this.ordersInProgress.data.filter(
                        order => !params.ids.includes(order.id)
                    )
                }

                if (this.ordersPublished.hasOwnProperty('data')) {
                    this.ordersPublished.data = this.ordersPublished.data.map(order => {
                        if (params.ids.includes(order.id)) {
                            order.project_id = params.project_id
                            order.project = newProject
                        }

                        return order
                    })
                    this.ordersPublished.data = this.ordersPublished.data.filter(
                        order => !params.ids.includes(order.id)
                    )
                }

                if (this.ordersCanceled.hasOwnProperty('data')) {
                    this.ordersCanceled.data = this.ordersCanceled.data.map(order => {
                        if (params.ids.includes(order.id)) {
                            order.project_id = params.project_id
                            order.project = newProject
                        }

                        return order
                    })
                    this.ordersCanceled.data = this.ordersCanceled.data.filter(order => !params.ids.includes(order.id))
                }

                this.formatTab()
            },

            getTabData(index) {
                switch (index) {
                    case 1:
                        if (this.ordersInProgress.hasOwnProperty('data')) {
                            break
                        }
                        this.isLoading = true

                        this.axios
                            .get(`/tracking-order/${this.project.id}?status=${this.statusInProgress}`)
                            .then(response => {
                                this.ordersInProgress = response.data.orders
                                this.formatTab()
                            })
                            .finally(() => (this.isLoading = false))
                        break
                    case 2:
                        if (this.ordersPublished.hasOwnProperty('data')) {
                            break
                        }
                        this.isLoading = true

                        this.axios
                            .get(`/tracking-order/${this.project.id}?status=${this.statusTerminate}`)
                            .then(response => {
                                this.ordersPublished = response.data.orders
                                this.formatTab()
                            })
                            .finally(() => (this.isLoading = false))
                        break
                    case 3:
                        if (this.ordersCanceled.hasOwnProperty('data')) {
                            break
                        }
                        this.isLoading = true

                        this.axios
                            .get(`/tracking-order/${this.project.id}?status=${this.statusCanceled}`)
                            .then(response => {
                                this.ordersCanceled = response.data.orders
                                this.formatTab()
                            })
                            .finally(() => (this.isLoading = false))
                        break

                    default:
                        break
                }
            },

            loadAsyncData(data) {
                var field = this.getFilters(data)

                var projectId = this.project.id

                var params = {
                    page: data.page ?? 0,
                    include: data.includes ?? '',
                    sort: field
                }

                if (data.filters && data.filters.length > 0) {
                    data.filters.forEach(element => {
                        if (element.key === 'project') {
                            projectId = element.value
                        } else {
                            params[`filter[${element.key}]`] = element.value
                        }
                    })
                }

                var route = `/admin/user-details/track-orders/${this.$route.query.id}`

                if (this.activeTab !== 0) {
                    let activeTabStatus = this.tabs.find(tab => tab.index == this.activeTab).status
                    route += '?status=' + activeTabStatus
                }

                let perPage = data.per_page ? data.per_page : 20
                perPage = route.includes('status') ? `&per_page=${perPage}` : `?per_page=${perPage}`

                this.axios
                    .get(route + perPage, {
                        params
                    })
                    .then(response => {
                        this.orders = response.data.orders
                        this.formatTab()
                    })
                    .finally()
            },

            getFilters: function (data) {
                var field = null

                if (data.sortField) {
                    if (data.sortOrder && data.sortOrder === 'desc') {
                        field = '-' + data.sortField
                    } else {
                        field = data.sortField
                    }
                }

                return field
            },

            goToTab(index) {
                this.activeTab = index
                this.getTabData(index)
            },

            formatTab() {
                this.tab = {
                    type: 'all',
                    label: this.$t('global.all') + ` (${this.orders.total})`,
                    icon: 'sitemap',
                    content: this.orders,
                    content_key: 'sites',
                    disabled: false,
                    index: 0,
                    status: ''
                }
            }
        },

        mounted() {}
    }
</script>
