<template>
    <div>
        <router-link class="nav_link" to="/admin/withdrawal-requests">
            <i class="fas fa-upload nav_icon" />
            <span class="nav_name">{{ $t('layout.whidraw') }}</span>
        </router-link>

        <router-link class="nav_link" to="/admin/partners">
            <i class="fas fa-handshake nav_icon" />
            <span class="nav_name">{{ $t('layout.partner-admin') }}</span>
        </router-link>

        <router-link class="nav_link" to="/admin/invoices">
            <i class="fas fa-receipt nav_icon ml-1" />
            <span class="nav_name">{{ $t('layout.billing') }}</span>
        </router-link>
    </div>
</template>
<script>
    import { mapState } from 'vuex'

    export default {
        name: 'AdministrativeMenu',
        computed: {
            ...mapState('auth', {
                user: state => state.user
            })
        }
    }
</script>
