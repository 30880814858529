<template>
    <Projects :allow-all="allowAll" />
</template>

<script>
    import Projects from './Projects'
    import ModalProject from './ModalProject'

    export default {
        name: 'ProjectSelector',
        components: { Projects, ModalProject },
        props: {
            allowAll: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
