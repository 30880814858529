<template>
    <span class="mr-1">
        <i v-if="!monitorankStatus || monitorankStatus === 'monitorank_not'" class="fa fa-chart-line text-danger" />
        <i v-if="monitorankStatus && monitorankStatus === 'monitorank_ok'" class="fa fa-chart-line text-success" />
        <i v-if="monitorankStatus && monitorankStatus === 'monitorank_partial'" class="fa fa-chart-line text-warning" />
    </span>
</template>

<script>
    export default {
        name: 'AdminAccompagnementMonitorankFlag',
        props: ['monitorankStatus']
    }
</script>
