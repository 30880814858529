import ApiService from '../../../services/api.service'

export default {
    getSites(context) {
        return new Promise((resolve, reject) => {
            ApiService.get('/partner/dashboard/sites')
                .then(data => {
                    context.commit('setSites', data.data)
                    resolve(data.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
