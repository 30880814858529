<template>
    <div>
        <h5 class="card-title">{{ $t('redactor.recap-infos') }} :</h5>
        <div class="card-text">
            <ul>
                <li class="mb-2">
                    {{ $t('global.keyword') }} :
                    <span id="keyword">
                        {{ writing.related_order.keyword }}
                    </span>
                    <button class="btn p-0 btn-link text-muted" @click="copyToClipboard($event, 'keyword')">
                        <i class="far fa-copy ml-1"></i>
                    </button>
                </li>

                <li class="mb-2">
                    Title :
                    <span>{{ writing.related_order.meta_title }}</span>
                </li>

                <li class="mb-2">
                    H1 :
                    <span>{{ writing.related_order.tag_title }}</span>
                </li>

                <li class="mb-2">
                    {{ $t('redactor.link') }} :
                    <span>{{ writing.primary_link }}</span>
                    <button class="btn p-0 btn-link text-muted" @click="copyToClipboard($event, 'linkUrlOrder')">
                        <i class="far fa-copy ml-1"></i>
                    </button>
                    <input type="hidden" id="linkUrlOrder" :value="writing.primary_link" />
                </li>

                <li class="mb-2">
                    {{ $t('global.anchor') }} :
                    <span>{{ writing.primary_anchor }}</span>
                    <button
                        class="btn p-0 btn-link text-muted"
                        @click="copy(writing.primary_link, writing.primary_anchor)"
                    >
                        <i class="far fa-copy ml-1"></i>
                    </button>
                </li>

                <li class="mb-2">
                    <div class="form-group">
                        <label for="category-select">Catégories</label>
                        <b-select expanded id="category-select" v-model="writing.category">
                            <option :value="category.id" v-for="category in categories" :key="category.id">
                                {{ category.name }}
                            </option>
                        </b-select>
                    </div>
                </li>

                <li class="mb-2">
                    <div class="form-group">
                        <label for="authors-select">Auteurs</label>
                        <b-select expanded id="authors-select" v-model="writing.author">
                            <option :value="author.id" v-for="author in authors" :key="author.id">
                                {{ author.nickname }}
                            </option>
                        </b-select>
                    </div>
                </li>

                <li class="mb-2">
                    {{ $t('redactor.nbWords') }} :
                    <span class="text-muted">{{ writing.words_count }}</span>
                </li>

                <li class="mb-2">
                    {{ $t('global.brief') }} :
                    <span class="text-muted">{{ writing.brief }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'RedactorSpotBriefPanel',

        computed: {
            ...mapState('redactors', {
                writing: state => state.writing,
                categories: state => state.categories,
                authors: state => state.authors
            }),
        },

        data: function () {
            return {}
        },
        created() {
            this.autoChooseAuthor()
        },
        methods: {
            autoChooseAuthor() {
                if (this.writing.author === null) {
                    let authorChoosen = this.authors[Math.random() * this.authors.length | 0]
                    while (authorChoosen.nickname === "") {
                        authorChoosen = this.authors[Math.random() * this.authors.length | 0]
                    }
                    this.writing.author = authorChoosen.id
                }
            },
            copyToClipboard(e, id) {
                let testingCodeToCopy = document.getElementById(id)
                // testingCodeToCopy.setAttribute('type', 'text');
                // testingCodeToCopy.select();

                try {
                    navigator.clipboard.writeText(testingCodeToCopy.innerText)
                    //document.execCommand('copy');
                    e.currentTarget.querySelector('i').classList.add('text-success')

                    setTimeout(() => {
                        document.querySelectorAll('i').forEach(icon => icon.classList.remove('text-success'))
                    }, 3000)
                } catch (err) {
                    console.error('No copy ' + err)
                }

                testingCodeToCopy.setAttribute('type', 'hidden')
            },
            copy(href, text) {
                let container = document.createElement('div')
                let element = document.createElement('a')
                element.setAttribute('href', href)
                element.innerText = text
                container.appendChild(element)

                try {
                    navigator.clipboard.write([
                        // eslint-disable-next-line no-undef
                        new ClipboardItem({
                            'text/html': new Blob([container.innerHTML], { type: 'text/html' }),
                            'text/plain': new Blob([container.innerHTML], {
                                type: 'text/plain'
                            })
                        })
                    ])
                } catch {
                    navigator.clipboard.writeText(container.innerHTML)
                }
            }
        }
    }
</script>
