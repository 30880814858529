<template />

<script>
    export default {
        name: 'PartnerDetailsNumber',
        props: {
            user: {},
            counts: {}
        }
    }
</script>
