<template>
    <div>
        <div class="alert alert-secondary" role="alert">
            Pour débiter un rédacteur, entrez simplement un montant négatif
        </div>

        <form>
            <div class="row">
                <div class="col-md-12 col-xl-6">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span id="refContent" class="input-group-text">Réf #</span>
                        </div>
                        <input
                            v-model="creditLog.entity_id"
                            class="form-control"
                            placeholder="Réf commande (facultatif)"
                            type="number"
                        />
                    </div>
                </div>

                <div class="col-md-12 col-xl-6">
                    <div class="form-check">
                        <input
                            id="creditlog-type-preorders"
                            v-model="creditLog.entity_type"
                            class="form-check-input"
                            type="radio"
                            value="2"
                        />
                        <label class="form-check-label" for="creditlog-type-preorders">Précommandes</label>
                    </div>
                    <div class="form-check">
                        <input
                            id="creditlog-type-orders"
                            v-model="creditLog.entity_type"
                            class="form-check-input"
                            type="radio"
                            value="1"
                        />
                        <label class="form-check-label" for="creditlog-type-orders">Commandes</label>
                    </div>
                </div>

                <div class="col-md-12 col-xl-12">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span id="words" class="input-group-text"><i class="fas fa-sort-numeric-down" /></span>
                        </div>
                        <input
                            v-model="creditLog.words"
                            class="form-control"
                            placeholder="Nombre de mots à créditer"
                            type="number"
                            @input.prevent="calcPrice"
                        />
                    </div>
                </div>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span id="basic-addon1" class="input-group-text">€</span>
                </div>
                <input v-model="creditLog.price" class="form-control" placeholder="Le montant" type="number" />
            </div>

            <button class="btn btn-secondary" type="button" :disabled="loading" @click.prevent="doCreditRedactor">
                <span class="d-flex">
                    <NlLoader :show="loading" width="loader-md" customize-loader="" />
                    <span v-if="!loading" class="p-2">Enregistrer</span>
                    <span v-if="loading" class="p-2">Enregistrement...</span>
                </span>
            </button>
        </form>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'CreditRedactorVue',
        components: {},
        data() {
            return {
                loading: false
            }
        },
        computed: {
            ...mapState('redactors', {
                creditLog: state => state.creditLog,
                redactor: state => state.redactor
            })
        },
        methods: {
            ...mapActions('redactors', {
                creditRedactor: 'creditRedactor'
            }),
            doCreditRedactor() {
                this.loading = true
                this.creditRedactor()
                    .then(() => {
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'Le rédacteur a bien été crédité',
                            message: ''
                        })
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            calcPrice() {
                if (this.redactor.prices && this.redactor.prices[0]) {
                    this.creditLog.price = ((this.redactor.prices[0].price / 100) * this.creditLog.words) / 100
                }
            }
        }
    }
</script>

<style scoped></style>
