<template>
    <div>
        <div class="d-flex justify-content-end">
            <b-select
                v-model="perPage"
                icon="align-center"
                :disabled="tableLoading"
                :loading="tableLoading"
                @input="loadAsyncData"
            >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
            </b-select>
            <b-dropdown aria-role="list" class="ml-2" :close-on-click="false" position="is-bottom-left">
                <template #trigger="{ active }">
                    <b-button
                        :label="$t('global.columns')"
                        type="is-info"
                        class="mr-2"
                        :icon-right="active ? 'caret-up' : 'caret-down'"
                    />
                </template>

                <b-dropdown-item v-for="(col, index) in columns" :key="index" aria-role="listitem">
                    <b-field>
                        <b-checkbox v-model="col.visible">
                            {{ col.name }}
                        </b-checkbox>
                    </b-field>
                </b-dropdown-item>
            </b-dropdown>
            <button class="button is-warning" @click="showExportModal">
                <i class="fas fa-download mr-1" />
                Export
            </button>
            <a
                href="https://www.nextlevel.link/besoins-de-nos-clients.html"
                class="button is-warning ml-2"
                target="_blank"
            >
                <i class="fas fa-info-circle mr-2" />Mode d'emploi
            </a>
        </div>

        <b-table
            :data="preorders.data"
            :loading="tableLoading"
            :default-sort="[sortField, sortOrder]"
            :per-page="preorders.per_page"
            :total="preorders.total"
            backend-sorting
            backend-pagination
            backend-filtering
            :debounce-search="1000"
            paginated
            pagination-rounded
            :has-detailed-visible="row => row.proposals.length && row.proposals[0].url"
            custom-detail-row
            detailed
            @page-change="onPageChange"
            @sort="onSort"
            @filters-change="onFilterChange"
        >
            <b-table-column
                centered
                field="locale"
                sortable
                searchable
                :visible="columns['locale'].visible"
                :label="columns['locale'].name"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <flag :iso="'en' === props.row.locale ? 'us' : 'fr'" class="rounded" />
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option :value="undefined">Tous</option>
                            <option value="fr">FR</option>
                            <option value="en">EN</option>
                        </select>
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="keyword"
                sortable
                searchable
                :visible="columns['keyword'].visible"
                :label="columns['keyword'].name"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ props.row.keyword }}</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.keyword')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="secondaryKeywords"
                sortable
                searchable
                :visible="columns['secondarykeyword'].visible"
                :label="columns['secondarykeyword'].name"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ showSecondKeywords(props.row.secondaryKeywords) }}</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('commande.secondarykeyword')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="groupe_id"
                sortable
                searchable
                :visible="columns['theme'].visible"
                :label="columns['theme'].name"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span>
                        {{ findGroup(props.row.groupe_id) }}
                    </span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option :value="undefined">
                                {{ $t('global.all') }}
                            </option>
                            <option v-for="group in groups" :key="group.id" :value="group.id">
                                {{ group.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="created_at"
                sortable
                searchable
                :visible="columns['date'].visible"
                :label="columns['date'].name"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span>
                        {{ moment(props.row.created_at).format('DD/MM/YYYY') }}
                    </span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="date"
                        class="form-control form-control-sm"
                        :placeholder="$t('partenaire-precommandes.date')"
                    />
                </div>
            </b-table-column>

            <b-table-column
                centered
                sortable
                field="nbArticle"
                :visible="columns['article_number'].visible"
                :label="columns['article_number'].name"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span>
                        {{ props.row.nbArticle }}
                    </span>
                </div>
            </b-table-column>

            <b-table-column
                centered
                sortable
                field="interested_partners_count"
                :visible="columns['otherpartners'].visible"
                :label="columns['otherpartners'].name"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <span>
                        {{ props.row.interested_partners_count }}
                    </span>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="partner_proposals"
                searchable
                :visible="columns['url'].visible"
                :label="columns['url'].name"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <div v-if="props.row.proposals.length">
                        <button
                            v-if="props.row.proposals[0].url || props.row.proposals.length > 1"
                            class="button is-light is-small"
                            @click="props.toggleDetails(props.row)"
                        >
                            {{ $t('partenaire-precommandes.show-proposals') }}
                        </button>

                        <button
                            v-else
                            type="button"
                            class="button is-primary is-small"
                            @click="openModalInterestEdit(props.row)"
                        >
                            {{ $t('partenaire-precommandes.suggest') }}
                        </button>
                    </div>

                    <span v-else> - </span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option :value="undefined">
                                {{ $t('global.all') }}
                            </option>
                            <option value="doesnt_have">
                                {{ $t('partenaire-precommandes.nointerest') }}
                            </option>
                            <option value="has">
                                {{ $t('partenaire-precommandes.interest') }}
                            </option>
                        </select>
                    </div>
                </div>
            </b-table-column>

            <b-table-column centered field="actions" :label="$t('global.actions')" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <button
                        v-if="!props.row.proposals.length"
                        type="button"
                        class="button is-primary is-small"
                        @click="newInterest(props.row)"
                    >
                        {{ $t('partenaire-precommandes.interestedredaction') }}
                    </button>
                    <button
                        v-else
                        type="button"
                        class="button is-danger is-small"
                        @click="deleteAllInterest(props.row)"
                    >
                        {{ $t('partenaire-precommandes.notinterestedredaction') }}
                    </button>
                </div>
            </b-table-column>

            <template slot="detail" slot-scope="props">
                <tr>
                    <td colspan="100%" class="p-4 detailed-row">
                        <div
                            v-if="props.row.proposals.length"
                            class="d-flex flex-column flex-md-row align-items-center justify-content-between"
                        >
                            <div>
                                <div
                                    v-for="(propal, index) in props.row.proposals"
                                    :key="index"
                                    class="mb-2 d-flex justify-content-between align-items-center"
                                    style="min-width: 30em"
                                >
                                    <span v-if="editUrl !== propal.id" class="url mr-1 mr-md-5">{{ propal.url }}</span>
                                    <input
                                        v-else-if="editUrl === propal.id"
                                        v-model="propal.url"
                                        type="text"
                                        class="form-control form-control-sm"
                                        @change="updateInterest(props.row, propal)"
                                        @keydown.esc="cleanUp"
                                    />
                                    <span>
                                        <button
                                            v-if="editUrl !== propal.id"
                                            type="button"
                                            class="button is-light rounded-pill is-small"
                                            :title="$t('global.modify')"
                                            @click="editUrl = propal.id"
                                        >
                                            <i class="fas fa-pen" />
                                        </button>
                                        <button
                                            type="button"
                                            class="button is-light is-danger rounded-pill is-small ml-2"
                                            :title="$t('global.delete')"
                                            @click="deleteInterest(props.row, propal)"
                                        >
                                            <i class="fas fa-trash-alt" />
                                        </button>
                                    </span>
                                </div>
                            </div>

                            <button
                                type="button"
                                class="button is-light is-warning"
                                :title="$t('partenaire-precommandes.suggestother')"
                                @click="openModalInterestNew(props.row)"
                            >
                                <i class="fas fa-plus-circle mr-2" />
                                {{ $t('partenaire-precommandes.suggestother') }}
                            </button>
                        </div>
                    </td>
                </tr>
            </template>
        </b-table>

        <nl-modal :open-modal="showModal" :title="$t('partenaire-precommandes.suggest')" @hidden="closeModal">
            <div class="form-group">
                <label class="label">URL</label>
                <b-input v-model="currentProposal.url" placeholder="URL" type="url" required />
            </div>

            <div class="form-group">
                <label class="label">Site</label>
                <v-select
                    v-model="currentProposal.site_id"
                    append-to-body
                    class="d-block w-100 h-100"
                    :options="users_sites.map(({ id, url }) => ({ id, url }))"
                    label="url"
                    :reduce="url => url.id"
                />
            </div>

            <div class="d-flex justify-content-end">
                <button class="button is-light mr-md-2" @click="closeModal">Annuler</button>
                <button
                    v-if="currentProposal.id"
                    class="button is-warning"
                    @click="updateInterest(currentPreOrder, currentProposal)"
                >
                    Valider
                </button>
                <button v-else class="button is-warning" @click="newInterest(currentPreOrder, currentProposal.url)">
                    Valider
                </button>
            </div>
        </nl-modal>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        props: {
            preordersList: {
                type: Object,
                required: true
            },
            groups: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                preorders: {},
                editUrl: null,
                currentPreOrder: {},
                currentProposal: {},
                showModal: false,

                perPage: 25,
                sortField: 'id',
                sortOrder: 'desc',
                filters: '',
                page: 1,
                tableLoading: false,
                users_sites: null,

                columns: {
                    locale: {
                        name: this.$t('precommande.columns.locale'),
                        visible: true
                    },
                    keyword: {
                        name: this.$t('precommande.columns.keyword'),
                        visible: true
                    },
                    secondarykeyword: {
                        name: this.$t('precommande.columns.secondarykeyword'),
                        visible: true
                    },
                    theme: {
                        name: this.$t('thematique.title'),
                        visible: true
                    },
                    date: {
                        name: this.$t('partenaire-precommandes.date'),
                        visible: true
                    },
                    article_number: {
                        name: this.$t('partenaire-precommandes.article-number'),
                        visible: true
                    },
                    otherpartners: {
                        name: this.$t('partenaire-precommandes.otherpartners'),
                        visible: true
                    },
                    url: {
                        name: this.$t('partenaire-precommandes.url'),
                        visible: true
                    }
                }
            }
        },

        watch: {
            preordersList(newValue) {
                this.preorders = newValue
            }
        },
        created() {
            this.getAllSitesFromUser().then(response => {
                this.users_sites = response
            })
        },
        methods: {
            ...mapActions('sites', ['getAllSitesFromUser']),
            newInterest(preOrder, url = null) {
                if (url && !this.isValidUrl(url)) {
                    this.snackNotif(this.$t('global.error-url'), 'is-danger')

                    return
                }

                this.$emit('newInterest', {
                    preorder: preOrder,
                    url: url,
                    callback: interest => {
                        preOrder.proposals.push(interest)
                    }
                })
                this.cleanUp()
            },

            updateInterest(preOrder, interest) {
                if (!this.isValidUrl(interest.url)) {
                    this.snackNotif(this.$t('global.error-url'), 'is-danger')

                    return
                }

                this.$emit('updateInterest', {
                    interest: interest,
                    site_id: interest.site_id,
                    callback: interestUpdated => {
                        preOrder.proposals = preOrder.proposals.map(interest => {
                            if (interestUpdated.id === interest.id) {
                                interest = interestUpdated
                            }

                            return interest
                        })
                    }
                })

                this.cleanUp()
            },

            deleteInterest(preOrder, interest) {
                this.$emit('deleteInterest', {
                    interest: interest,
                    callback: () => {
                        preOrder.proposals = preOrder.proposals.filter(oldInterest => oldInterest.id !== interest.id)
                        this.checkEmptyRows()
                    }
                })
                this.cleanUp()
            },

            deleteAllInterest(preOrder) {
                this.$emit('deleteAllInterest', {
                    preorder: preOrder,
                    callback: () => {
                        preOrder.proposals = []
                        this.checkEmptyRows()
                    }
                })
                this.cleanUp()
            },

            openModalInterestEdit(preOrder) {
                this.showModal = true
                this.currentPreOrder = preOrder
                this.currentProposal = JSON.parse(JSON.stringify(preOrder.proposals[0]))
            },

            openModalInterestNew(preOrder) {
                this.showModal = true
                this.currentPreOrder = preOrder
                this.currentProposal = {}
            },

            cleanUp() {
                this.editUrl = null
                this.showModal = false
                this.currentPreOrder = {}
                this.currentProposal = {}
            },

            closeModal() {
                this.cleanUp()
            },
            showExportModal() {
                this.$emit('showExportModal')
            },

            checkEmptyRows() {
                setTimeout(() => {
                    document.querySelectorAll('.detailed-row').forEach(div => {
                        if (div.innerHTML === '<!---->') {
                            div.remove()
                        }
                    })
                }, 1000)
            },

            // temp
            snackNotif(message, type = 'is-success', duration = 5000) {
                this.$buefy.snackbar.open({
                    message: message,
                    type: type,
                    position: 'is-bottom',
                    duration: duration
                })
            },

            findGroup(groupId) {
                if (!groupId) {
                    return '-'
                }

                let group = this.groups.find(group => group.id == groupId)

                return group.name
            },

            isValidUrl(url) {
                var pattern =
                    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
                return pattern.test(url)
            },

            showSecondKeywords(secondKeywords) {
                if (!secondKeywords) {
                    return null
                }

                return secondKeywords.map(secondKeyword => secondKeyword.name).join(', ')
            },

            /** Table filtering, sorting and pagination methods */
            loadAsyncData() {
                this.tableLoading = true
                var self = this

                this.$emit('loadAsyncData', {
                    page: this.page,
                    sortField: this.sortField,
                    sortOrder: this.sortOrder,
                    filters: this.filters,
                    includes: 'user',
                    path: this.preorders.path,
                    index: this.indexType,
                    per_page: this.perPage,

                    callback() {
                        self.tableLoading = false
                    }
                })
            },

            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order

                this.loadAsyncData()
            },

            onFilterChange(filter) {
                var filters = []
                Object.keys(filter).forEach(element => {
                    var currentFilter = {
                        key: element,
                        value: filter[element]
                    }
                    filters.push(currentFilter)
                })
                this.filters = filters
                this.loadAsyncData()
            },

            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            }
        }
    }
</script>
