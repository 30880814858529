<template>
    <div>
        <b-button
            :icon-left="isLoading ? 'circle-notch fa-spin' : 'pen'"
            @click="open = true"
            type="is-info"
            :disabled="isLoading"
        >
            Domaines sortants autorisés ({{ allowedOutgoingDomains.length }})
        </b-button>
        <b-modal v-model="open" size="is-small">
            <template>
                <header class="modal-card-head has-background-warning">
                    <p class="modal-card-title m-0">Domaine(s) sortant(s) autorisé(s)</p>
                </header>
                <section class="modal-card-body">
                    <b-field label="Domaines sortants autorisés (séparés par une virgule ou une nouvelle ligne)">
                        <b-input v-model="allowedOutgoingDomain" type="textarea" style="height: 200px" />
                    </b-field>
                </section>
                <footer class="justify-content-end modal-card-foot h-auto">
                    <b-button @click="save" type="is-success" label="Modifier" />
                    <b-button @click="open = false" type="is-danger" label="Annuler" />
                </footer>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        props: {
            site: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                allowedOutgoingDomain: this.site.domaines_sortants_autorises,
                open: false,
                isLoading: false
            }
        },
        computed: {
            allowedOutgoingDomains() {
                return this.site?.domaines_sortants_autorises ? this.site.domaines_sortants_autorises.split('\n') : []
            }
        },
        methods: {
            ...mapActions('sites', ['adminUpdateSite']),

            save() {
                this.open = false
                this.isLoading = true

                this.adminUpdateSite({
                    id: this.site.id,
                    domaines_sortants_autorises: this.allowedOutgoingDomain
                })
                    .then(site => {
                        this.$emit('saved')
                        this.allowedOutgoingDomain = site.domaines_sortants_autorises
                        this.$buefy.toast.open({
                            message: 'Site mis à jour',
                            type: 'is-success'
                        })
                    })
                    .finally(() => (this.isLoading = false))
            }
        }
    }
</script>
