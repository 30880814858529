import ApiService from '@/services/api.service'

function prepareParams(config) {
    let field = config.sort

    if (config.sortDirection === 'desc') {
        field = '-' + field
    }

    let params = {
        page: config.page,
        include: config.include,
        sort: field,
        per_page: config.per_page
    }

    if (config.filters) {
        for (const [key, value] of Object.entries(config.filters)) {
            params[`filter[${key}]`] = value
        }
    }

    return params
}

export default {
    getSite(context, siteId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/sites/' + siteId)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getSites(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/sites?include=majestic_data`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    getPartners(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/sites/partners`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    getSitesCount(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/sites/count', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    getUrlsBySite(context, { id, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/site/${id}/urls`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getUrlsCountBySite(context, { id, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/site/${id}/urls/count`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getUrlsPriceSumBySite(context, { id, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/site/${id}/urls/price/sum`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    adminUpdateSite(context, site) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/admin/sites/${site.id}`, site)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getExternalLinks(context, siteId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/sites/' + siteId + '/external-links')
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getDomainPosting(context, projectId) {
        return new Promise((resolve, reject) => {
            let params = prepareParams(context.state.domainPostingConfig)
            ApiService.get('/admin/sites/user/domain-posting?project=' + projectId, params)
                .then(response => {
                    resolve(response.data)
                    context.commit('setDomainPosting', response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getUrlBySite(context, params) {
        return new Promise((resolve, reject) => {
            const { sort, page, rowsPerPage, filter } = params ? params : [null, null, null, null]
            ApiService.get('/admin/sites/' + params.siteId + '/get-urls', {
                sort,
                page,
                rowsPerPage,
                filter
            })
                .then(({ data }) => {
                    context.commit('setTotal', data)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    searchSite(context, query) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/sites/search?q=' + query)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    resetSiteUrl(context, siteId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/sites/' + siteId + '/reset-site-url')
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    buySite(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/sites/sale/' + params.id + '/buy', {
                use_credits: params.use_credits
            })
                .then(({ data }) => {
                    context.commit('auth/setCredits', data.credits, { root: true })
                    context.commit('auth/setBenefits', data.benefits, { root: true })
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    checkScrapStatus(context, siteId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/sites/' + siteId + '/check-scrap-status')
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    scrapUrl(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/sites/' + params.siteId + '/scrap-url', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    launchSeobserver(context, siteId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/sites/' + siteId + '/launch-seobserver')
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    checkSeobserverStatus(context, siteId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/sites/' + siteId + '/check-seobserver-status')
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getKeywords(context, { siteId }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/sites/${siteId}/get-keywords`)
                .then(({ data }) => {
                    context.commit('setKeywords', data)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getRestrictions(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/sites/${params.siteId}/getRestrictions`)
                .then(({ data }) => {
                    context.commit('setRestrictions', data)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getLastExportInfo() {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/sites/export-info')
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    export() {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/sites/export', {})
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    downloadExport() {
        return new Promise((resolve, reject) => {
            ApiService.get(
                '/admin/site/download-export',
                {},
                {
                    responseType: 'arraybuffer',
                    headers: { Accept: 'application/octet-stream' }
                }
            )
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    getAllSites(context) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/sites')
                .then(res => {
                    context.commit('setSites', res.data.sites)
                    console.log(res)
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getAllSitesFromUser() {
        return new Promise((resolve, reject) => {
            ApiService.get('/user/sites/all')
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getSitesOnSales(context) {
        return new Promise((resolve, reject) => {
            let params = prepareParams(context.state.sellingWebsitesConfig)
            ApiService.get('/sites/sale', params)
                .then(response => {
                    resolve(response.data)
                    context.commit('setSitesOnSales', response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    listSitesOnSale(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put('/sites/sale', {
                sites: params.ids,
                price: params.price,
                saleable: params.saleable,
                gnews: params.gnews
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getSitesAvailableByUser(context, campaignId) {
        return new Promise((resolve, reject) => {
            let params = prepareParams(context.state.sitesAvailablesConfig)
            ApiService.get('/campaigns/preorders/sites-available/' + campaignId, params)
                .then(response => {
                    resolve(response.data)
                    context.commit('setSitesAvailables', response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateOvercomingLinks(context, { site_id, params }) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/admin/sites/${site_id}/overcoming-links`, params)
                .then(response => {
                    resolve(response.data)
                    context.commit('setSitesAvailables', response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getPriceUpdatePending(context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/sites/${id}/price/update/pending`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getScrapPending(context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/sites/${id}/scrap/pending`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getSerpPending(context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/sites/${id}/serp/pending`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    serp(context, { siteId }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/sites/${siteId}/serp`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    scrap(context, { siteId }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/sites/${siteId}/scrap`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    reindex(context, { siteId }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/sites/${siteId}/reindex`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    deletePlugin(context, siteId) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/admin/sites/${siteId}/plugin`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    changeOwnership(context) {
        return new Promise((resolve, reject) => {
            const ownership = context.state.ownership

            ApiService.post(`/admin/sites/${ownership.site.id}/owner`, {
                'partenaire_id': ownership.newOwner.id
            })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
