<template>
    <div v-if="display">
        <GChart :type="type" :data="data" :options="options" :settings="settings" />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { GChart } from 'vue-google-charts/legacy'

    export default {
        components: {
            GChart
        },
        data: function () {
            return {
                display: false,

                type: 'OrgChart',
                data: null,
                options: {
                    width: 800,
                    allowHtml: true
                },
                settings: {
                    packages: ['orgchart']
                }
            }
        },
        created() {},
        computed: {
            ...mapState('consultants', {
                agency: 'agency',
                clients: 'clients',
                managers: 'managers',
                consultants: 'consultants'
            })
        },
        mounted() {},
        methods: {
            draw() {
                this.data = [
                    [
                        {
                            v: '' + this.agency.id,
                            f: `<strong>${this.agency.firstname} ${this.agency.lastname}</strong><br/>${this.agency.email}`
                        },
                        'root',
                        'Agence'
                    ]
                ]

                this.managers.data.forEach(manager => {
                    this.data.push([
                        {
                            v: '' + manager.user_id,
                            f: `<strong>${manager.firstname} ${manager.lastname}</strong><br/>${manager.email}`
                        },
                        '' + this.agency.id,
                        'Managers'
                    ])

                    manager.consultants.forEach(consultant => {
                        this.data.push([
                            {
                                v: '' + consultant.user_id,
                                f: `<strong>${consultant.firstname} ${consultant.lastname}</strong><br/>${consultant.email}`
                            },
                            '' + manager.user_id,
                            'Managers'
                        ])
                    })
                })

                this.display = true
            }
        }
    }
</script>
