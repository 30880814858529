<template>
    <div>
        <b-taglist attached>
            <b-tag type="is-light"> Nombre total</b-tag>
            <b-tag type="is-warning">
                {{ reports.total }}
            </b-tag>
        </b-taglist>
        <p class="font-weight-bold">Sélection de la période</p>
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <b-datepicker
                    v-model="dateFrom"
                    :locale="'fr-FR'"
                    icon="calendar"
                    trap-focus
                    :icon-right="dateFrom ? 'times' : ''"
                    icon-right-clickable
                    @icon-right-click="dateFrom = null"
                />
                <i class="fas fa-arrow-right mx-2" />
                <b-datepicker
                    v-model="dateTo"
                    :locale="'fr-FR'"
                    icon="calendar"
                    trap-focus
                    :icon-right="dateTo ? 'times' : ''"
                    icon-right-clickable
                    @icon-right-click="dateTo = null"
                />
                <button id="rechargement" type="button" class="is-warning button ml-2" @click="loadAsyncData">
                    Valider
                </button>
            </div>
            <div class="d-flex">
                <button
                    id="rechargement"
                    type="button"
                    class="is-warning button mr-2"
                    :disabled="checkedRows.length == 0"
                    @click="openSalesModal"
                >
                    Mettre en vente
                </button>
                <b-button
                    v-if="!exportReady"
                    type="button"
                    class="button is-warning"
                    :loading="isLoading"
                    @click="exportReports"
                >
                    <i class="far fa-file-excel mr-2" />Préparer l'export
                </b-button>
                <export-button
                    v-if="exportReady"
                    file-name="export_urls.xlsx"
                    :sheets="[
                        {
                            name: 'Feuille 1',
                            data: reports.data
                                ? reports.data.map(e => {
                                      return {
                                          ID: e.id,
                                          NDD: e.ndd,
                                          Proprio: e.proprio ? e.proprio : 'NL',
                                          MC: e.keywords,
                                          NB_Ventes: e.orders_count,
                                          CA: e.ca,
                                          Reverse: e.donated ? e.donated : '-'
                                      }
                                  })
                                : []
                        }
                    ]"
                    class-name="ml-2"
                />
            </div>
        </div>
        <b-table
            :data="reports.data"
            :mobile-cards="true"
            :paginated="true"
            :current-page.sync="config.page"
            :striped="true"
            :debounce-search="200"
            class="table-striped"
            :loading="isLoading"
            :default-sort="[config.sort, config.sortDirection]"
            :per-page="reports.per_page"
            :total="reports.total"
            backend-sorting
            backend-pagination
            backend-filtering
            :checked-rows.sync="checkedRows"
            checkable
            checkbox-position="left"
            @page-change="onPageChange"
            @sort="onSort"
            @filters-change="onFilterChange"
        >
            <b-table-column field="id" label="ID" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    {{ props.row.id }}
                </div>
            </b-table-column>
            <b-table-column field="ndd" sortable :searchable="isSearchable" label="NDD" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    {{ props.row.ndd }}
                    <a target="_blank" :href="`https://www.google.com/search?q=site%3A${props.row.ndd}`"
                        ><i class="fab fa-google"
                    /></a>
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="ndd"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="group_id"
                sortable
                :searchable="isSearchable"
                label="Thème"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props" class="text-center">
                    <span>{{ getGroupName(props.row.group_id) }}</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <select v-model="props.filters['sites.group_id']" class="form-control form-control-sm">
                        <option :value="props.filters['sites.group_id']" disabled selected hidden>Tous</option>
                        <option v-for="group in groups" :key="group.id" :value="group.id">
                            {{ group.name }}
                        </option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column
                field="proprio"
                sortable
                :searchable="isSearchable"
                label="Propriétaire"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props" class="text-center">
                    <span v-if="props.row.proprio !== null" class="tag is-success p-2 text-center">Partenaire</span>
                    <span v-else class="tag is-warning text-dark p-2 text-center">NextLevel</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <select v-model="props.filters['is_partner']" class="form-control form-control-sm">
                        <option :value="props.filters['is_partner']" disabled selected hidden>Tous</option>
                        <option :value="0">NextLevel</option>
                        <option :value="1">Partenaire</option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column field="saleable" searchable sortable label="En vente" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.saleable" class="tag is-success">En vente</span>
                    <span v-else class="text-center">-</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <select v-model="props.filters['saleable']" class="form-control form-control-sm">
                        <option :value="props.filters['saleable']" disabled selected hidden>Tous</option>
                        <option :value="1">Oui</option>
                        <option :value="0">Non</option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column
                field="keywords"
                sortable
                :searchable="isSearchable"
                label="Mots-clés NL"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <a target="_blank" :href="`/admin/sites/keywords?id=${props.row.id}`">{{ props.row.keywords }}</a>
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol" style="display: flex">
                    <input
                        v-model="props.filters['min_keywords']"
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm"
                        placeholder="Min."
                        style="width: 50% !important; margin-right: 5px"
                    />
                    <input
                        v-model="props.filters['max_keywords']"
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm"
                        placeholder="Max."
                        style="width: 50% !important"
                    />
                </div>
            </b-table-column>

            <b-table-column
                field="orders_count"
                sortable
                :searchable="isSearchable"
                label="Nombre de ventes"
                cell-class="align-middle"
            >
                <div slot="default" slot-scope="props">
                    <a target="_blank" :href="`/admin/sites/orders?id=${props.row.id}`">{{ props.row.orders_count }}</a>
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol" style="display: flex">
                    <input
                        v-model="props.filters['min_orders_count']"
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm"
                        placeholder="Min."
                        style="width: 50% !important; margin-right: 5px"
                    />
                    <input
                        v-model="props.filters['max_orders_count']"
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm"
                        placeholder="Max."
                        style="width: 50% !important"
                    />
                </div>
            </b-table-column>

            <b-table-column field="ca" sortable :searchable="isSearchable" label="CA" cell-class="align-middle">
                <div slot="default" slot-scope="props">{{ roundAmount(props.row.ca) }}€</div>
                <div slot="searchable" slot-scope="props" class="searchableCol" style="display: flex">
                    <input
                        v-model="props.filters['min_ca']"
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm"
                        placeholder="Min."
                        style="width: 50% !important; margin-right: 5px"
                    />
                    <input
                        v-model="props.filters['max_ca']"
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm"
                        placeholder="Max."
                        style="width: 50% !important"
                    />
                </div>
            </b-table-column>

            <b-table-column field="donated" sortable label="€ reversés (partenaires)" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.proprio !== null">{{ roundAmount(props.row.donated) }}€</span>
                    <span v-else>-</span>
                </div>
            </b-table-column>

            <b-table-column field="action" sortable label="Action" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <a
                        :href="'/admin/sites/preorders?id=' + props.row.id"
                        title="Voir"
                        target="_blank"
                        class="rounded button px-2 is-light rounded-lg text-decoration-none"
                    >
                        <i class="fas fa-eye" />
                    </a>
                </div>
            </b-table-column>
        </b-table>
        <SalesModal ref="salesModal" />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'TabRapportCommande',
        data: function () {
            return {
                isSearchable: true,
                isLoading: false,
                exportReady: false,
                checkboxPosition: 'left',
                checkedRows: [],
                list: [],
                // data info
                appUrl: process.env.APP_URL,

                //other
                isMobileWidth: window.innerWidth < 768
            }
        },

        computed: {
            ...mapState('orders_reports', {
                reports: state => state.reports,
                config: state => state.reportsConfig
            }),
            ...mapState('group', {
                groups: state => state.translatedGroups
            }),

            dateFrom: {
                get() {
                    return this.$store.state.orders_reports.date_from
                },
                set(value) {
                    this.$store.commit('orders_reports/setDateFrom', value)
                }
            },
            dateTo: {
                get() {
                    return this.$store.state.orders_reports.date_to
                },
                set(value) {
                    this.$store.commit('orders_reports/setDateTo', value)
                }
            }
        },

        created() {},
        methods: {
            ...mapActions('orders_reports', ['getReports']),
            ...mapActions('group', ['getAll']),
            /** Table filtering, sorting and pagination methods */
            loadAsyncData() {
                this.isLoading = true
                this.getReports()
                    .then(() => {})
                    .catch(error => {
                        console.log(error)
                        this.$store.dispatch('toastError', error)
                    })
                    .finally(() => {
                        this.isLoading = false
                        this.exportReady = false
                    })
            },
            openSalesModal() {
                this.$refs.salesModal.openModal(this.checkedRows)
            },

            onSort(field, order) {
                this.config.sort = field
                this.config.sortDirection = order

                this.loadAsyncData()
            },
            onFilterChange(filter) {
                var filters = {}
                Object.keys(filter).forEach(element => {
                    filters[element] = filter[element]
                })
                this.config.filters = filters

                this.loadAsyncData()
            },
            onPageChange(page) {
                this.config.page = page
                this.loadAsyncData()
            },

            roundAmount(amount) {
                return Math.round(amount * 100) / 100
            },

            getGroupName(id) {
                var group = this.groups.find(group => group.id == id)
                return group ? group.name : '-'
            },

            exportReports() {
                this.config.per_page = this.reports.total
                this.isLoading = true
                this.getReports()
                    .then(() => {
                        this.exportReady = true
                    })
                    .catch(error => {
                        console.log(error)
                        this.$store.dispatch('toastError', error)
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
        }
    }
</script>
