export default () => ({
    orders: null,
    ordersConfig: {
        include: '',
        sort: 'content.id',
        sortDirection: 'desc',
        page: 1,
        filters: {},
        per_page: 100
    },
    expired: false,
    activeTab: 0,
    isLoading: false,
    counts: null,
    shouldComputeOrdersNotes: false,
    pendingOrdersCount: null
})
