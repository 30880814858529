<template>
    <div id="form-filter">
        <div class="d-flex align-items-center p-2">
            <h5 class="text-uppercase font-weight-bold mb-0">
                {{ $t('global.filters') }}
            </h5>
            <div class="ml-2">
                <button
                    v-show="hasFilters() || hasAdvencedFilters()"
                    id="resetFilter"
                    class="btn btn-primary"
                    @click="resetFilter"
                >
                    {{ $t('global.reinitialize') }}
                </button>
            </div>
            <div class="ml-auto">
                <button
                    class="btn btn-primary"
                    style="background-color: #17a2b8; border-color: #17a2b8"
                    @click="extended = !extended"
                >
                    <i class="fas" :class="{ 'fa-minus': extended, 'fa-plus': !extended }" />
                    <span> {{ $t('next-level.filtersmore') }}</span>
                </button>
            </div>
        </div>

        <div v-if="security.isAdmin" class="form-group row align-items-center m-0">
            <div class="col-sm-12 text-right">
                <div class="form-check">
                    <input
                        id="trie_adulte"
                        v-model="form.adult"
                        class="form-check-input"
                        type="checkbox"
                        value="1"
                        name="trie_adulte"
                        @change="updateFilters()"
                    />
                    <label class="form-check-label" for="trie_adulte">
                        {{ $t('favorites.noadultfilter') }}
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group row align-items-center m-0">
            <div class="col-sm-6 col-md-4 col-lg-3 mt-2">
                <label :class="form.price ? 'selected' : ''">{{ $t('global.maxprice') }} :</label>
                <div class="input-group mb-2">
                    <input
                        v-model="form.price"
                        type="number"
                        name="trie_prix"
                        class="form-control"
                        @change="updateFilters()"
                    />
                    <div class="input-group-append">
                        <div class="input-group-text">€</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3 mt-2">
                <label :class="form.url ? 'selected' : ''">{{ $t('links&anchors.nolinksto') }} :</label>
                <input
                    v-model="form.url"
                    type="text"
                    name="trie_url"
                    class="form-control"
                    :placeholder="$t('searchform.urlordomain')"
                    @change="updateFilters()"
                />
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3 mt-2">
                <label :class="form.source ? 'selected' : ''">Source :</label>
                <select v-model="form.source" name="trie_source" class="form-control" @change="updateFilters()">
                    <option value="">
                        {{ $t('favorites.allsites') }}
                    </option>
                    <option value="nextlevel" :selected="'nextlevel' === form.source ? 'selected' : ''">
                        NextLevel
                    </option>
                    <option value="partenaire" :selected="'partenaire' === form.source ? 'selected' : ''">
                        {{ $t('favorites.partners') }}
                    </option>
                </select>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3 mt-2">
                <div v-if="security.isAdmin">
                    <label :class="form.partner && 0 < form.partner.length ? 'selected' : ''"
                        >{{ $t('favorites.partners') }} :</label
                    >
                    <Select2
                        v-model="form.partner"
                        :settings="{
                            language: language,
                            multiple: true,
                            ajax: ajax,
                            minimumInputLength: 3
                        }"
                        @change="updateFilters()"
                    />
                </div>
                <div v-else class="form-check">
                    <input
                        id="trie_adulte"
                        v-model="form.adult"
                        class="form-check-input"
                        type="checkbox"
                        value="1"
                        name="trie_adulte"
                        @change="updateFilters()"
                    />
                    <label class="form-check-label" for="trie_adulte">
                        {{ $t('favorites.noadultfilter') }}
                    </label>
                </div>
            </div>
        </div>

        <div v-if="extended">
            <div class="form-group row align-items-center m-0">
                <div v-if="displayThematic" class="col-sm-6 col-md-4 col-lg-3 mt-2">
                    <label :class="0 < form.thematic.length ? 'selected' : ''">{{ $t('global.theme') }} :</label>
                    <Select2
                        v-model="form.thematic"
                        :options="filterThematic"
                        :settings="{ language: language, multiple: true }"
                        @change="updateFilters()"
                    />
                </div>
                <div v-if="displayThematic" class="col-sm-6 col-md-4 col-lg-3 mt-2">
                    <label :class="0 < form.thematicExcluded.length ? 'selected' : ''"
                        >{{ $t('favorites.excludedtheme') }} :</label
                    >
                    <Select2
                        v-model="form.thematicExcluded"
                        :options="filterThematic"
                        :settings="{ language: language, multiple: true }"
                        @change="updateFilters()"
                    />
                </div>

                <div class="col-sm-6 col-md-4 col-lg-3 mt-2">
                    <label :class="form.top ? 'selected' : ''">Top :</label>
                    <select v-model="form.top" name="trie_top" class="form-control" @change="updateFilters()">
                        <option value="">
                            {{ $t('global.all') }}
                        </option>
                        <option v-for="(top, i) in trie_top" :key="`top_${i}`" :value="top">Top {{ top }}</option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3 mt-2">
                    <label :class="form.volume ? 'selected' : ''">{{ $t('global.quantity') }} :</label>
                    <select v-model="form.volume" name="trie_volume" class="form-control" @change="updateFilters()">
                        <option value="">
                            {{ $t('global.all') }}
                        </option>
                        <option v-for="(volume, i) in trie_volume" :key="`volume_${i}`" :value="volume">
                            Min. {{ volume }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3 mt-2">
                    <label :class="form.keywordsCount ? 'selected' : ''">Nb KW :</label>
                    <select
                        v-model="form.keywordsCount"
                        name="trie_nb_kw"
                        class="form-control"
                        @change="updateFilters()"
                    >
                        <option value="">
                            {{ $t('global.all') }}
                        </option>
                        <option v-for="(nbKw, i) in trie_nb_kw" :key="`nbKw_${i}`" :value="nbKw">
                            Min. {{ nbKw }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3 mt-2">
                    <label :class="form.topTenCount ? 'selected' : ''">Nb top 10 :</label>
                    <select
                        v-model="form.topTenCount"
                        name="trie_nb_top10"
                        class="form-control"
                        @change="updateFilters()"
                    >
                        <option value="">
                            {{ $t('global.all') }}
                        </option>
                        <option v-for="(nbTop, i) in trie_nb_top10" :key="`nbTop_${i}`" :value="nbTop">
                            Min. {{ nbTop }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3 mt-2">
                    <label :class="form.index ? 'selected' : ''">{{ $t('tableau-keywords.urlindice') }} :</label>
                    <select v-model="form.index" name="trie_indice_url" class="form-control" @change="updateFilters()">
                        <option value="">
                            {{ $t('global.all') }}
                        </option>
                        <option v-for="(indiceUrl, i) in trie_indice_url" :key="`indiceUrl_${i}`" :value="indiceUrl">
                            Min. {{ indiceUrl }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3 mt-2">
                    <label :class="form.category ? 'selected' : ''">{{ $t('tableau-keywords.categorysite') }} :</label>
                    <select
                        v-model="form.category"
                        name="trie_categorie"
                        class="form-control"
                        @change="updateFilters()"
                    >
                        <option value="">
                            {{ $t('global.all') }}
                        </option>
                        <option v-for="(categorie, key) in trie_categorie" :key="`trie_categorie_${key}`" :value="key">
                            {{ categorie }}
                        </option>
                    </select>
                </div>
                <div v-if="security.isAdmin" class="col-sm-6 col-md-4 col-lg-3 mt-2">
                    <label :class="form.siteAge ? 'selected' : ''">{{ $t('tableau-keywords.siteAge') }} :</label>
                    <select v-model="form.siteAge" name="trie_categorie" class="form-control" @change="updateFilters()">
                        <option value="">
                            {{ $t('global.all') }}
                        </option>
                        <option v-for="(site_age, key) in trie_site_age" :key="`trie_site_age_${key}`" :value="key">
                            {{ site_age }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Search from '../../services/search/search'
    import Select2 from 'v-select2-component'

    export default {
        name: 'SearchFilter',
        components: {
            Select2
        },
        props: {
            filterThematic: Array,
            displayThematic: { type: Boolean, default: true },
            stopLoad: Boolean,
            form: Object,
            security: Object
        },
        data: function () {
            return {
                extended: false,
                trie_thematique: [],
                trie_top: [],
                trie_volume: [],
                trie_indice_url: [],
                trie_categorie: [],
                trie_nb_kw: [],
                trie_nb_top10: [],
                trie_site_age: [],
                ajax: {},
                language: {}
            }
        },
        watch: {
            form: function () {
                this.toggleExtended()
            }
        },
        created() {
            const search = new Search()
            search.initFilter(response => {
                this.trie_site_age = response.data.trie_site_age
                this.trie_categorie = response.data.trie_categorie
                this.trie_top = response.data.trie_top_number
                this.trie_volume = response.data.trie_volume_number
                this.trie_nb_kw = response.data.trie_nb_kw_number
                this.trie_nb_top10 = response.data.trie_nb_top10_number
                this.trie_indice_url = response.data.trie_indice_url_number
            })

            this.ajax = {
                transport: function (params, success, failure) {
                    search.searchPartner({ search: params.data.term }, success, failure)
                },
                dataType: 'json',
                processResults: response => {
                    return {
                        results: response.data
                    }
                }
            }

            const that = this
            this.language = {
                errorLoading: function () {
                    return that.$t('select.errorLoading')
                },
                inputTooShort: function (args) {
                    const remainingChars = args.minimum - args.input.length

                    return (
                        that.$t('select.inputTooShort') +
                        ' ' +
                        remainingChars +
                        ' ' +
                        that.$t('select.letter') +
                        (remainingChars > 1 && 'fr' == that.$store.state.locale ? 's' : '')
                    )
                },
                noResults: function () {
                    return that.$t('select.noResults')
                },
                searching: function () {
                    return that.$t('select.searching')
                }
            }
        },
        mounted() {
            this.toggleExtended()
        },

        methods: {
            updateFilters() {
                this.$emit('refresh')
            },
            resetFilter() {
                this.$emit('reset-filters')
            },
            hasFilters() {
                return this.form.price != '' || this.form.url != '' || this.form.source != '' || this.form.adult
            },
            hasAdvencedFilters() {
                return (
                    this.form.thematic.length > 0 ||
                    this.form.thematicExcluded.length > 0 ||
                    this.form.partner.length > 0 ||
                    this.form.top != '' ||
                    this.form.volume != '' ||
                    this.form.index != '' ||
                    this.form.category != '' ||
                    this.form.keywordsCount != '' ||
                    this.form.topTenCount != ''
                )
            },
            toggleExtended() {
                if (this.hasAdvencedFilters()) {
                    this.extended = true
                }
            }
        }
    }
</script>
