import { ROLES } from '@/config/roles'

const metaGroup = {
    roles: [ROLES.ADMIN]
}

export default [
    {
        path: '/admin/orders',
        name: 'SuiviAdmin',
        components: {
            default: () => import('../views/Admin/SuiviAdmin.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/orders/report',
        name: 'RapportCommandeAdmin',
        components: {
            default: () => import('../views/Admin/RapportCommande.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/orders/listing',
        name: 'URLsAdminListing',
        components: {
            default: () => import('../views/Admin/URLs/AdminListing.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/sites/availability',
        name: 'DomainPosting',
        components: {
            default: () => import('../views/Domain/DomainPosting.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/redactor',
        alias: '/redactor-admin',
        name: 'RedactorAdmin',
        component: () => import('../views/Redactor/RedactorAdminDashboard.vue'),
        meta: metaGroup
    },
    {
        path: '/admin/pre-orders/statistics',
        name: 'CampaignPreOrders',
        component: () => import('../views/Admin/PreorderStatistic.vue'),
        meta: metaGroup
    },
    {
        path: '/admin/sites',
        name: 'GroupsSites',
        components: {
            default: () => import('../views/Admin/Sites/GroupsSites.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/groups',
        name: 'Catégories',
        components: {
            default: () => import('../views/Admin/Categories.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/keywords/new',
        alias: '/addkeyword',
        name: 'AddKeywords',
        components: {
            default: () => import('../views/Admin/AddKeywords.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/keywords',
        name: 'Keywords',
        component: () => import('../views/Admin/Keyword/List.vue'),
        meta: metaGroup
    },
    {
        path: '/admin/keywords/bulk',
        name: 'Add Keyword',
        component: () => import('../views/Admin/Keyword/KeywordAdd.vue'),
        meta: metaGroup
    },
    {
        path: '/admin/invoices',
        name: 'Billing',
        components: {
            default: () => import('../views/Admin/Billing.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/coupons',
        name: 'CODE PROMO',
        components: {
            default: () => import('../views/Admin/Coupon/List.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/campaigns',
        name: 'AdminAccompagnement',
        component: () => import('../views/Admin/AdminAccompagnement.vue'),
        meta: metaGroup
    },
    {
        path: '/admin/last-researches',
        name: 'Dernières Recherches',
        components: {
            default: () => import('../views/Admin/SearchLog.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/config',
        alias: '/config',
        name: 'Config',
        components: {
            default: () => import('../views/Config/Config.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/config/mails',
        name: 'ConfigMail',
        components: {
            default: () => import('../views/Config/ConfigMail.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/sites/orders',
        alias: '/commande-domaine',
        name: 'AdminOrdersBySite',
        component: () => import('../views/Admin/Sites/Orders.vue'),
        meta: metaGroup
    },
    {
        path: '/admin/campaigns/new',
        name: 'AccompagnementNew',
        component: () => import('../views/Admin/AccompagnementNew.vue'),
        meta: metaGroup
    },
    {
        path: '/consultant/campaigns',
        name: 'Accompagnement Consultant',
        component: () => import('../views/Admin/AdminAccompagnement.vue'),
        props: {
            advisor: true
        },
        meta: metaGroup
    },
    {
        path: '/admin/sites/preorders',
        name: 'Site Précommande',
        components: {
            default: () => import('../views/Admin/Sites/Preorder.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/campaigns/details',
        name: 'AccompagnementApp',
        component: () => import('../views/Campaign/CampaignManager.vue'),
        props: {
            isAdmin: true,
            backLink: '/admin/campaigns'
        },
        meta: metaGroup
    },
    {
        path: '/admin/sites/keywords',
        name: 'GroupSiteKeywords',
        components: {
            default: () => import('../views/Admin/Sites/GroupSiteKeywords.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/sites/restrictions',
        name: 'GroupSiteRestrictions',
        components: {
            default: () => import('../views/Admin/Sites/GroupSiteRestrictions.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/spots/sites',
        name: 'SpotsSites',
        components: {
            default: () => import('../views/Spots/SpotsSites.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/spots/orders',
        name: 'SpotsOrdersList',
        components: {
            default: () => import('../views/Spots/SpotsOrders.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/spots/orders/:id',
        name: 'SpotsOrders',
        components: {
            default: () => import('../views/Spots/AdminSpotsOrders.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/spots/content-items',
        name: 'ContentItems',
        components: {
            default: () => import('../views/Spots/ContentItems.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/spots/:id/content_items/',
        name: 'SpotsContentItems',
        components: {
            default: () => import('../views/Spots/SpotsContentItems.vue')
        },
        meta: metaGroup
    },
    {
        path: '/admin/rank',
        alias: '/rank.php',
        name: 'Rank',
        components: {
            default: () => import('../views/Admin/Rank.vue')
        },
        meta: metaGroup
    }
]
