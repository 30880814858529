<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">
                        {{ editMode ? $t('partenaire-site.advancedoptions') : $t('global.add') }}
                    </h4>
                </header>
                <div class="card-content">
                    <div class="content">
                        <form enctype="multipart/form-data">
                            <div v-if="!editMode" class="">
                                <label class="label" for="url">{{ $t('partenaire-site.mainurl') }}</label>
                                <b-input
                                    v-model="formData.url"
                                    :loading="verifyUrlLoading"
                                    :disabled="editMode"
                                    placeholder="https://website.com"
                                    @input="initTimer"
                                />
                                <small v-if="errorUrl" class="text-danger">{{ errorUrl }}</small>
                            </div>

                            <section v-if="!editMode" class="row align-items-center">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="label" for="type_cms">{{ $t('partenaire-site.cmstype') }}</label>
                                        <select
                                            id="type_cms"
                                            v-model="formData.type_cms"
                                            name="type_cms"
                                            class="form-control"
                                            autocomplete="off"
                                        >
                                            <option value="wordpress">Wordpress</option>
                                            <option value="autres">
                                                {{ $t('partenaire-site.othercms') }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6 ">
                                    <b-field class="pt-3">
                                        <b-checkbox v-model="formData.display_urls">Afficher mes URLs</b-checkbox>
                                    </b-field>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="label" for="group_id">{{ $t('global.theme') }}</label>
                                        <select
                                            id="group_id"
                                            v-model="formData.group_id"
                                            name="group_id"
                                            class="form-control"
                                            autocomplete="off"
                                        >
                                            <option v-for="group in groups" :key="group.id" :value="group.id">
                                                <div v-if="group.parent_id">
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ group.name }}
                                                </div>

                                                <div v-else>
                                                    {{ group.name }}
                                                </div>
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="label" for="redaction">{{ $t('global.redaction') }}</label>
                                        <select
                                            id="redaction"
                                            v-model="formData.redaction"
                                            name="redaction"
                                            class="form-control"
                                            autocomplete="off"
                                        >
                                            <option value="nextlevel">
                                                {{ $t('partenaire-site.redactionnextlevel') }}
                                            </option>
                                            <option value="proprietaire">
                                                {{ $t('partenaire-site.redactionmyself') }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="label" for="validation">{{
                                                $t('partenaire-site.urlforsale')
                                            }}</label>
                                        <select
                                            id="validation"
                                            v-model="formData.validation"
                                            name="validation"
                                            class="form-control"
                                            autocomplete="off"
                                            @change="toggleAdvancedMode"
                                        >
                                            <option value="simple">
                                                {{ $t('partenaire-site.simplemode') }}
                                            </option>
                                            <option value="avance">
                                                {{ $t('partenaire-site.avancedmode') }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </section>

                            <section v-if="editMode" class="row align-items-center">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="label" for="validation">{{
                                                $t('partenaire-site.urlforsale')
                                            }}</label>

                                        <b-switch v-model="advancedMode" passive-type="is-danger" type="is-success">
                                            <template v-if="!advancedMode">Mode simple : tout le site</template>
                                            <template v-else>Mode avancé</template>
                                        </b-switch>
                                    </div>
                                </div>
                            </section>

                            <transition name="fade">
                                <section
                                    v-show="advancedMode"
                                    class="p-3 bg-light rounded mb-2"
                                >
                                    <p>{{ $t('partenaire-site.avancedmodedescription') }}.</p>

                                    <div class="form-group">
                                        <label class="label" for="scrape_sitemap">{{
                                                $t('partenaire-site.whitelist')
                                            }}</label>
                                        <input
                                            id="scrape_sitemap"
                                            v-model="formData.scrape_sitemap"
                                            type="text"
                                            class="form-control"
                                            name="scrape_sitemap"
                                        />
                                    </div>

                                    <div class="form-group">
                                        <label for="scrape_whitelist">{{
                                                $t('partenaire-site.whitelist-textfile')
                                            }}</label>
                                        <div class="d-flex flex-column flex-md-row align-items-center">
                                            <b-field>
                                                <b-upload v-model="fileWhitelist" drag-drop>
                                                    <section class="p-1">
                                                        <div class="d-flex has-text-centered">
                                                            <p class="mb-0 mr-2 text-warning">
                                                                <b-icon icon="upload"/>
                                                            </p>
                                                            <p class="mb-0">
                                                                {{ $t('partenaire-site.selecttxt') }}
                                                            </p>
                                                        </div>
                                                    </section>
                                                </b-upload>
                                            </b-field>
                                            <div v-if="fileWhitelist.name" class="tags ml-md-3">
                                                <span class="tag is-primary">
                                                    {{ fileWhitelist.name }}
                                                    <button
                                                        class="delete is-small"
                                                        type="button"
                                                        @click="deleteDropFile('scrape_whitelist')"
                                                    />
                                                </span>
                                            </div>
                                            <div v-if="formData.scrape_whitelist" class="mt-1 ml-3">
                                                <b-field>
                                                    <b-checkbox v-model="formData.clear_whitelist" type="is-danger">
                                                        <!-- todo count url -->
                                                        Supprimer la whitelist
                                                    </b-checkbox>
                                                </b-field>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="scrape_blacklist">{{
                                                $t('partenaire-site.blacklist-textfile')
                                            }}</label>
                                        <div class="d-flex flex-column flex-md-row align-items-center">
                                            <b-field>
                                                <b-upload v-model="fileBlacklist" drag-drop>
                                                    <section class="p-1">
                                                        <div class="d-flex has-text-centered">
                                                            <p class="mb-0 mr-2 text-warning">
                                                                <b-icon icon="upload"/>
                                                            </p>
                                                            <p class="mb-0">
                                                                {{ $t('partenaire-site.selecttxt') }}
                                                            </p>
                                                        </div>
                                                    </section>
                                                </b-upload>
                                            </b-field>
                                            <div v-if="fileBlacklist.name" class="tags ml-md-3">
                                                <span class="tag is-primary">
                                                    {{ fileBlacklist.name }}
                                                    <button
                                                        class="delete is-small"
                                                        type="button"
                                                        @click="deleteDropFile('scrape_blacklist')"
                                                    />
                                                </span>
                                            </div>
                                            <div v-if="editMode && formData.scrape_blacklist" class="mt-1 ml-3">
                                                <b-field>
                                                    <b-checkbox v-model="formData.clear_blacklist" type="is-danger">
                                                        <!-- todo count url -->
                                                        Supprimer la blacklist
                                                    </b-checkbox>
                                                </b-field>
                                            </div>

                                        </div>
                                    </div>
                                </section>
                            </transition>

                            <section>
                                <div>
                                    <section class="row mb-3">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="label" for="refus_concurrent_mfa">{{
                                                        $t('partenaire-site.MFAconcurrent')
                                                    }}</label>
                                                <select
                                                    id="refus_concurrent_mfa"
                                                    v-model="formData.refus_concurrent_mfa"
                                                    name="refus_concurrent_mfa"
                                                    class="form-control"
                                                    autocomplete="off"
                                                >
                                                    <option value="non">
                                                        {{ $t('partenaire-site.no-recommandation') }}
                                                    </option>
                                                    <option value="oui">
                                                        {{ $t('global.yes') }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="label" for="lien_secondaire_autorise">
                                                    {{ $t('partenaire-site.secondarylink') }}
                                                    <i
                                                        class="fas fa-info-circle"
                                                        data-toggle="tooltip"
                                                        data-html="true"
                                                        :title="$t('partenaire-site.secondarylink-info')"
                                                    />
                                                </label>
                                                <select
                                                    id="lien_secondaire_autorise"
                                                    v-model="formData.lien_secondaire_autorise"
                                                    name="lien_secondaire_autorise"
                                                    class="form-control"
                                                    autocomplete="off"
                                                >
                                                    <option value="1">
                                                        {{ $t('partenaire-site.yes-recommandation') }}
                                                    </option>
                                                    <option value="0">
                                                        {{ $t('global.no') }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div v-if="!editMode" class="col-12">
                                            <div class="form-group">
                                                <label class="label" for="contenu_redige_max">
                                                    {{ $t('partenaire-site.maxcontent') }}
                                                    <i
                                                        class="fas fa-info-circle"
                                                        data-toggle="tooltip"
                                                        data-html="true"
                                                        :title="$t('partenaire-site.addcontent')"
                                                    />
                                                </label>
                                                <select
                                                    id="contenu_redige_max"
                                                    v-model="formData.contenu_redige_max"
                                                    name="contenu_redige_max"
                                                    class="form-control"
                                                    autocomplete="off"
                                                >
                                                    <option value="200">200 {{ $t('global.words') }}</option>
                                                    <option value="300">300 {{ $t('global.words') }}</option>
                                                    <option value="400">400 {{ $t('global.words') }}</option>
                                                    <option value="500">500 {{ $t('global.words') }}</option>
                                                    <option value="600">600 {{ $t('global.words') }}</option>
                                                    <option value="700" selected>
                                                        700 {{ $t('partenaire-site.wordsrecommandation') }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="label" for="ancres_acceptees">{{
                                                        $t('partenaire-site.anchors')
                                                    }}</label>
                                                <select
                                                    id="ancres_acceptees"
                                                    v-model="formData.ancres_acceptees"
                                                    name="ancres_acceptees"
                                                    class="form-control"
                                                    autocomplete="off"
                                                >
                                                    <option value="toutes">
                                                        {{ $t('partenaire-site.alltheanchors') }}
                                                    </option>
                                                    <option value="toutes_no_url">
                                                        {{ $t('partenaire-site.all-no-url') }}
                                                    </option>
                                                    <option value="url">
                                                        {{ $t('partenaire-site.url-choice') }}
                                                    </option>
                                                    <option value="generique">
                                                        {{ $t('partenaire-site.generics') }}
                                                    </option>
                                                    <option value="url_ou_generique">
                                                        {{ $t('partenaire-site.urlsorgeneric') }}
                                                    </option>
                                                    <option value="url_ou_generique_ou_keywords">
                                                        {{ $t('partenaire-site.urlsGenericKeywords') }}
                                                    </option>
                                                    <option value="keywords">
                                                        {{ $t('partenaire-site.keywords-choice') }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="label" for="planification_autorisee">{{
                                                        $t('partenaire-site.allowplanning')
                                                    }}</label>
                                                <select
                                                    id="planification_autorisee"
                                                    v-model="formData.planification_autorisee"
                                                    name="planification_autorisee"
                                                    class="form-control"
                                                    autocomplete="off"
                                                >
                                                    <option value="1">
                                                        {{ $t('partenaire-site.yes-recommandation') }}
                                                    </option>
                                                    <option value="0">
                                                        {{ $t('global.no') }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="label" for="prix_minimum_filtre">
                                                    {{ $t('partenaire-site.minigain') }}
                                                    <i
                                                        class="fas fa-info-circle"
                                                        data-toggle="tooltip"
                                                        data-html="true"
                                                        :title="$t('partenaire-site.minigainexplanation')"
                                                    />
                                                </label>
                                                <div class="input-group">
                                                    <input
                                                        id="prix_minimum_filtre"
                                                        v-model="formData.prix_minimum_filtre"
                                                        type="text"
                                                        class="form-control"
                                                        name="prix_minimum_filtre"
                                                        min="0"
                                                    />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">€</span>
                                                    </div>
                                                </div>

                                                <div v-if="editMode" class="mt-2">
                                                    <b-field>
                                                        <b-checkbox
                                                            v-model="formData.clear_gain_plancher"
                                                            type="is-danger"
                                                        >
                                                            {{ $t('partenaire-mot-cles.reinitminigainsite') }}
                                                        </b-checkbox>
                                                    </b-field>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="editMode" class="col-12">
                                            <b-field>
                                                <b-checkbox v-model="formData.display_urls">Afficher mes URLs
                                                </b-checkbox>
                                            </b-field>
                                        </div>

                                        <div v-if="editMode" class="col-12 mt-3">
                                            <b-field>
                                                <b-checkbox
                                                    v-model="formData.accept_auto_orders_at"
                                                    :false-value="null"
                                                    type="is-warning"
                                                >
                                                    <!-- todo count url -->
                                                    <span class="label">
                                                        {{ $t('partenaire-site.accept-auto') }}
                                                        <i
                                                            class="fas fa-info-circle"
                                                            data-toggle="tooltip"
                                                            data-html="true"
                                                            :title="$t('partenaire-site.accept-auto-info')"
                                                        />
                                                    </span>
                                                </b-checkbox>
                                            </b-field>
                                        </div>


                                        <div v-if="editMode" class="col-12">
                                            <b-field v-if="canCheckPreordersAuto">
                                                <b-checkbox
                                                    v-model="formData.accept_preorders_auto"
                                                    :false-value="0"
                                                    :true-value="1"
                                                    type="is-warning"
                                                >
                                                    <!-- todo count url -->
                                                    <span class="label">
                                                        {{ $t('partenaire-site.accept-auto-preorder') }}
                                                        {{ `(-${config}€ ${$t('partenaire-site.after-sale')})` }}
                                                        <i
                                                            class="fas fa-info-circle"
                                                            data-toggle="tooltip"
                                                            data-html="true"
                                                            :title="$t('partenaire-site.accept-auto-info-preorder')"
                                                        />
                                                    </span>
                                                </b-checkbox>
                                            </b-field>
                                            <small v-if="!canCheckPreordersAuto" class="text-danger"
                                            >Merci d'activer l'acceptation automatique des commandes sur votre site
                                                et d'activer le plugin WP pour accepter automatiquement les
                                                précommandes</small
                                            >
                                        </div>

                                        <div class="col-12 mt-3">
                                            <div class="form-group">
                                                <label class="label">
                                                    {{ $t('partenaire-site.blacklist-groups-label') }}
                                                    <i
                                                        class="fas fa-info-circle"
                                                        data-toggle="tooltip"
                                                        data-html="true"
                                                        :title="$t('partenaire-site.blacklist-groups-info')"
                                                    />
                                                </label>
                                                <b-taginput
                                                    v-model="formData.blacklisted_groups"
                                                    :data="filteredGroups"
                                                    autocomplete
                                                    :allow-new="false"
                                                    :open-on-focus="true"
                                                    field="name"
                                                    type="is-dark"
                                                    :placeholder="$t('partenaire-site.blacklist-groups-add')"
                                                    @typing="getGroups"
                                                />
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </section>

                            <b-notification type="is-warning is-light" :closable="false" role="alert">
                                <p class="h5 font-weight-bold">
                                    {{ $t('global.importantinformations') }}
                                </p>
                                <ul>
                                    <li>{{ $t('partenaire-site.addlink') }}</li>
                                    <li>{{ $t('partenaire-site.engagement') }}</li>
                                    <li>{{ $t('partenaire-site.externallinks') }}</li>
                                    <li>{{ $t('partenaire-site.humanvalidation') }}</li>
                                    <li>{{ $t('partenaire-site.site-owner') }}</li>
                                </ul>
                            </b-notification>

                            <div class="buttons justify-content-end">
                                <button class="button is-light" @click.prevent="closeAndResetModal">
                                    {{ $t('global.cancel') }}
                                </button>
                                <button
                                    v-if="!editMode"
                                    class="button is-warning"
                                    :disabled="!urlIsValid"
                                    @click.prevent="saveSite"
                                >
                                    {{ $t('global.add') }}
                                </button>
                                <button v-else class="button is-warning" @click.prevent="editSite">
                                    {{ $t('global.modify') }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
    name: 'PartnerSiteModal',
    props: ['data', 'groups'],
    data() {
        return {
            modalIsOpen: false,
            editMode: false,
            advancedMode: false,

            groupsCanBeBlacklisted: [],
            filteredGroups: [],

            scrape_whitelist_count: 0,
            scrape_blacklist_count: 0,

            formData: {
                lang: 'fr_fr',
                blacklisted_groups: []
            },
            fileWhitelist: [],
            fileBlacklist: [],
            errorUrl: '',
            verifyUrlLoading: false,
            urlIsValid: false,
            refDomainIsValid: false,
            config: 0,

            timer: null
        }
    },
    computed: {
        canCheckPreordersAuto() {
            if (this.formData.wp_data && this.formData.wp_data !== null) {
                return this.formData.wp_data.is_active && this.formData.accept_auto_orders_at !== null
                    ? true
                    : false
            } else {
                return false
            }
        }
    },

    watch: {
        advancedMode() {
            this.formData.validation = this.advancedMode ? 'avance' : 'simple'
            console.log(this.formData.validation)
        },
        groups() {
            this.groupsCanBeBlacklisted = this.groups.filter(group => group.id !== 1)
        }
    },

    created() {
        this.formData = this.data
        this.getConfig()
    },

    methods: {
        ...mapActions('config', {
            getConfigByName: 'getConfigByName'
        }),
        getConfig() {
            this.getConfigByName({name: 'commission_precommande_auto'}).then(response => {
                this.config = response
            })
        },

        saveSite() {
            this.formData.scrape_whitelist = this.fileWhitelist.name ? this.fileWhitelist : null
            this.formData.scrape_blacklist = this.fileBlacklist.name ? this.fileBlacklist : null
            this.formData.blacklisted_groups =
                this.formData.blacklisted_groups && this.formData.blacklisted_groups.length
                    ? JSON.stringify(this.formData.blacklisted_groups)
                    : null

            this.$emit('saveSite', this.formData)
            this.closeAndResetModal()
        },

        editSite() {
            this.formData.scrape_whitelist = this.fileWhitelist.name ? this.fileWhitelist : null
            this.formData.scrape_blacklist = this.fileBlacklist.name ? this.fileBlacklist : null

            this.fileWhitelist = []
            this.fileBlacklist = []

            let data = {
                url: this.formData.url,
                lang: this.formData.lang,
                type_cms: this.formData.type_cms,
                group_id: this.formData.group_id,
                redaction: this.formData.redaction,
                validation: this.formData.validation,
                scrape_sitemap: this.formData.scrape_sitemap,
                scrape_whitelist: this.formData.scrape_whitelist,
                scrape_blacklist: this.formData.scrape_blacklist,
                refus_concurrent_mfa: this.formData.refus_concurrent_mfa,
                lien_secondaire_autorise: this.formData.lien_secondaire_autorise,
                contenu_redige_max: this.formData.contenu_redige_max,
                ancres_acceptees: this.formData.ancres_acceptees,
                planification_autorisee: this.formData.planification_autorisee,
                prix_minimum_filtre: this.formData.prix_minimum_filtre,
                clear_whitelist: this.formData.clear_whitelist,
                clear_blacklist: this.formData.clear_blacklist,
                clear_gain_plancher: this.formData.clear_gain_plancher,
                accept_auto_orders_at: this.formData.accept_auto_orders_at,
                accept_preorders_auto: this.formData.accept_preorders_auto,
                blacklisted_groups:
                    this.formData.blacklisted_groups && this.formData.blacklisted_groups.length > 0
                        ? JSON.stringify(this.formData.blacklisted_groups)
                        : null,
                id: this.formData.id,
                nb_keywords: this.formData.nb_keywords,
                display_urls: this.formData.display_urls ? 1 : 0,
            }

            this.$emit('editSite', data)
            this.closeAndResetModal()
        },

        initTimer() {
            if (this.timer) {
                clearTimeout(this.timer)
            }

            this.timer = setTimeout(this.validateForm, 500)
        },

        validateForm() {
            this.errorUrl = ''
            this.urlIsValid = false
            this.refDomainIsValid = false

            if (this.formData.url.length < 1 || this.formData.url.length < 10 || this.editMode) {
                this.errorUrl = "L'url est invalide"
                return
            }

            if (!this.isValidUrl(this.formData.url)) {
                this.errorUrl = "L'url est invalide"
                return
            }

            this.verifyUrlLoading = true
            let rdPromise = this.checkRd()
            let urlPromise = this.checkUrl()

            Promise.all([rdPromise, urlPromise]).finally(() => {
                this.verifyUrlLoading = false

                if (this.refDomainIsValid === false) {
                    this.urlIsValid = false
                    this.errorUrl += ` Votre site ne contient pas suffisamment de domaines référents.`
                }
            })
        },

        checkRd() {
            return this.axios
                .post('/partner-sites/ref-domains', {
                    url: this.formData.url
                })
                .then(({data}) => {
                    const {minimumReached} = data

                    if (minimumReached == true) {
                        this.refDomainIsValid = true
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },

        checkUrl() {
            return this.axios
                .post('/partner-sites/check-url', {
                    url: this.formData.url,
                    lang: this.formData.lang
                })
                .then(response => {
                    this.urlIsValid = response.data.result
                })
                .catch(error => {
                    console.log(error)
                    this.errorUrl = error.response.data.message
                })
        },

        openModal(site) {
            this.modalIsOpen = true
            this.editMode = false

            if (site) {
                this.editMode = true

                this.formData = site
                this.formData.validation =
                    site.scrape_sitemap || site.scrape_whitelist || site.scrape_blacklist ? 'avance' : 'simple'
                this.formData.clear_gain_plancher = false
                this.formData.accept_auto_orders_at = site.accept_auto_orders_at ? true : null
                if (site.blacklisted_groups) {
                    this.formData.blacklisted_groups = this.groups.filter(group => {
                        return site.blacklisted_groups.find(blacklisted => blacklisted.id === group.id)
                    })
                }

                this.advancedMode = this.formData.validation === 'avance'
            }
        },

        deleteDropFile(type) {
            this.formData[type] = []
        },

        getGroups(searchTerm) {
            this.filteredGroups = this.groupsCanBeBlacklisted.filter(group => {
                return group.name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()
                    .includes(
                        searchTerm
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLowerCase()
                    )
            })
        },

        toggleAdvancedMode(e) {
            if (e.target.value === 'avance') {
                this.advancedMode = true
                return
            }

            this.advancedMode = false
        },

        isValidUrl(url) {
            var pattern =
                /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
            return pattern.test(url)
        },

        closeAndResetModal() {
            this.modalIsOpen = false
            this.advancedMode = false

            this.$emit('clean-form')

            this.formData = {
                url: '',
                lang: 'fr_fr',
                type_cms: 'wordpress',
                group_id: 1,
                redaction: 'nextlevel',
                validation: 'simple',
                scrape_sitemap: '',
                scrape_whitelist: [],
                scrape_blacklist: [],
                refus_concurrent_mfa: 'non',
                lien_secondaire_autorise: 1,
                contenu_redige_max: 700,
                ancres_acceptees: 'toutes',
                planification_autorisee: 1,
                prix_minimum_filtre: 0,
                clear_whitelist: false,
                clear_blacklist: false,
                clear_gain_plancher: false,
                accept_auto_orders_at: null,
                blacklisted_groups: [],
                display_urls: true
            }
        }
    }
}
</script>

<style>
label[for^='scrape_'] {
    color: #363636;
    display: block;
    font-size: 1rem;
    font-weight: 700;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
