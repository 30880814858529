<template>
    <div class="col-md-12 mb-3">
        <div class="card border-0 mb-3 overflow-x-hidden">
            <div class="card-header border-0 shadow-sm px-3 justify-content-between">
                <span>{{ $t('mes-accompagnements.clientbrief') }}</span>
                <span class="font-weight-bold">
                    <span v-if="writing.entity_type === 1">Commande </span>
                    <span v-else-if="writing.entity_type === 2">Précommande </span>
                    <span v-else>Spot </span>
                    #{{ writing.related_order.id }}
                </span>
            </div>
            <div class="p-3 brief-client">
                <slot />
            </div>
            <div class="card-footer border-0 shadow-sm text-muted">
                <div class="col d-flex px-2 justify-content-between">
                    <div>
                        <slot name="previous" />
                    </div>
                    <div>
                        <slot name="next" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'WritingSlidePanel',
        props: ['btnClassPrevious', 'btnClassNext', 'previousLabel', 'nextLabel'],
        data() {
            return {}
        },
        computed: {
            ...mapState('redactors', {
                writing: state => state.writing
            })
        }
    }
</script>

<style scoped>
    .overflow-x-hidden {
        overflow-x: hidden;
    }
</style>
