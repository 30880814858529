<template>
    <div>
        <div v-if="showPreview && preview">
            <iframe :srcdoc="preview" frameborder="0" class="w-100" style="min-height: 100vh" />
            <div class="d-flex justify-content-center py-5">
                <b-button type="is-warning" @click="showPreview = false"> Fermer la prévisualisation</b-button>
            </div>
        </div>
        <div v-else-if="keywords">
            <form @submit.prevent="">
                <div class="mb-2">
                    <h3>Email de lancement</h3>
                </div>

                <div class="row mb-2">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="label">Destinataire</label>
                            <input v-model="launchingMail.recipient_email" class="form-control" type="email" required />
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="label">Émetteur</label>
                            <input v-model="launchingMail.sender_email" class="form-control" type="email" required />
                        </div>
                    </div>
                </div>

                <div class="mb-2">
                    <div class="form-group">
                        <label class="label">Destinataires en copie (optionnel)</label>
                        <input v-model="launchingMail.cc" class="form-control" type="email" multiple />
                        <small class="text-muted">Séparer les destinataires par une virgule</small>
                    </div>
                </div>

                <div class="mb-2">
                    <div class="form-group">
                        <label class="label">Destinataires en copie cachée (optionnel)</label>
                        <input v-model="launchingMail.bcc" class="form-control" type="email" multiple />
                        <small class="text-muted">Séparer les destinataires par une virgule</small>
                    </div>
                </div>

                <div class="mb-2">
                    <div class="form-group">
                        <label class="label">Introduction du mail</label>
                        <ckeditor v-model="mailParts.firstPart" :config="editorConfig" :editor="editor" />
                    </div>
                </div>

                <div class="mb-2">
                    <div class="form-group">
                        <label class="label">Informations supplémentaires sur les crédits</label>
                        <ckeditor v-model="launchingMail.information_credits" :config="editorConfig" :editor="editor" />
                        <small class="text-muted">Possibilité d'écrire en HTML</small>
                    </div>
                </div>

                <div class="mb-2">
                    <div class="form-group">
                        <label class="label">Crédits offert</label>
                        <input v-model="launchingMail.offered_credits" class="form-control" type="number" />
                        <small class="text-muted">0 pour aucun crédits offert</small>
                    </div>
                </div>

                <div class="mb-2">
                    <div class="form-group">
                        <label class="label">Votre brief</label>
                        <ckeditor v-model="launchingMail.notes" :config="editorConfig" :editor="editor" />
                        <small class="text-muted">Possibilité d'écrire en HTML</small>
                    </div>
                </div>

                <div class="mb-2">
                    <div class="form-group">
                        <label class="label">Seconde partie du mail</label>
                        <ckeditor v-model="mailParts.secondPart" :config="editorConfig" :editor="editor" />
                    </div>
                </div>

                <div class="mb-2">
                    <h4 class="h5">Mots clés à travailler</h4>
                    <p class="small text-muted">Seuls les mots clés séléctionnés seront affichés dans l'email.</p>

                    <b-table :data="keywords.data" checkable :checked-rows.sync="checkedRows">
                        <b-table-column centered field="keywords" label="Requêtes" cell-class="align-middle">
                            <div slot="default" slot-scope="props">
                                <span>{{ props.row.mot_cle }}</span>
                            </div>
                        </b-table-column>

                        <b-table-column centered field="url" label="URL à pousser" cell-class="align-middle">
                            <div slot="default" slot-scope="props">
                                <span>{{ props.row.url }}</span>
                            </div>
                        </b-table-column>

                        <b-table-column centered field="position" label="Position initiale" cell-class="align-middle">
                            <div slot="default" slot-scope="props">
                                <span>{{ props.row.position_initiale }}</span>
                            </div>
                        </b-table-column>
                    </b-table>
                </div>

                <div class="mb-2">
                    <div class="form-group">
                        <label class="label">Troisième du mail</label>
                        <ckeditor v-model="mailParts.thirdPart" :config="editorConfig" :editor="editor" />
                    </div>
                </div>

                <div class="mb-2">
                    <div class="form-group">
                        <label class="label">Fin du mail</label>
                        <ckeditor v-model="mailParts.fourthPart" :config="editorConfig" :editor="editor" />
                    </div>
                </div>

                <div v-if="false" class="mb-4 text-center px-2 py-4 border-dashed">
                    <button class="button is-small is-light" disabled>
                        <i class="fas fa-search mr-1" />
                        Voir l'aperçu
                    </button>
                </div>

                <div class="mb-2 d-flex flex-column flex-md-row justify-content-end">
                    <button class="button is-light" @click="$emit('closeModal')">Annuler</button>
                    <button
                        class="button is-dark my-2 my-md-0 mx-md-2"
                        type="submit"
                        :disabled="!checkedRows.length"
                        @click="onSubmit(false)"
                    >
                        Enregistrer
                    </button>
                    <button
                        class="button is-info my-2 my-md-0 mx-md-2"
                        type="submit"
                        :disabled="!checkedRows.length"
                        @click="getPreview()"
                    >
                        Prévisualiser
                    </button>
                    <button
                        class="button is-warning"
                        type="submit"
                        :disabled="!checkedRows.length"
                        @click="onSubmit(true)"
                    >
                        Enregistrer et envoyer
                    </button>
                </div>
                <Toaster ref="toaster" />
            </form>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
    import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
    import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
    import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
    import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
    import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
    import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
    import BlockquotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote'
    import IndentPlugin from '@ckeditor/ckeditor5-indent/src/indent'
    import ListPlugin from '@ckeditor/ckeditor5-list/src/list'

    import Image from '@ckeditor/ckeditor5-image/src/image'
    import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
    import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
    import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
    import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize'
    import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage'

    export default {
        props: {
            campaign: {
                type: Object,
                required: true
            }
        },
        data: function () {
            return {
                // Mail data
                launchingMail: {
                    recipient_email: null,
                    sender_email: null,
                    cc: null,
                    bcc: null,
                    information_credits: null,
                    offered_credits: null,
                    notes: null
                },

                // Mail custom text
                mailParts: {
                    firstPart: null,
                    secondPart: null,
                    thirdPart: null,
                    fourthPart: null
                },

                // Keywords checked rows
                checkedRows: [],

                loading: false,

                // Mail preview data
                showPreview: false,
                preview: null,

                //CKEditor
                editor: ClassicEditor,
                ckContent: null, //represent DOM element - see mounted hook
                editorConfig: {
                    copyFormatting_allowedContexts: true,
                    htmlEncodeOutput: false,
                    fullPage: true,
                    basicEntities: false,
                    editorDisabled: false,
                    plugins: [
                        EssentialsPlugin,
                        BoldPlugin,
                        ItalicPlugin,
                        LinkPlugin,
                        ParagraphPlugin,
                        HeadingPlugin,
                        BlockquotePlugin,
                        IndentPlugin,
                        ListPlugin,
                        Image,
                        ImageCaption,
                        ImageToolbar,
                        ImageStyle,
                        ImageResize,
                        LinkImage
                    ],
                    toolbar: {
                        items: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            '|',
                            'link',
                            '|',
                            'outdent',
                            'indent',
                            '|',
                            'bulletedList',
                            'numberedList',
                            '|',
                            'undo',
                            'redo'
                        ]
                    },
                    heading: {
                        options: [
                            {
                                model: 'paragraph',
                                title: 'Paragraphe',
                                class: 'ck-heading_paragraph'
                            },
                            {
                                model: 'heading1',
                                view: 'h1',
                                title: 'Titre 1',
                                class: 'h1'
                            },
                            {
                                model: 'heading2',
                                view: 'h2',
                                title: 'Titre 2',
                                class: 'h2'
                            },
                            {
                                model: 'heading3',
                                view: 'h3',
                                title: 'Titre 3',
                                class: 'h3'
                            }
                        ]
                    }
                }
            }
        },
        computed: {
            ...mapState('campaigns', {
                keywords: state => state.keywordsWorkedOn
            }),
            keywordsIds() {
                return this.checkedRows.map(e => e.id)
            }
        },
        methods: {
            ...mapActions('campaigns', ['saveLaunchingMail', 'getMailPreview']),
            onSubmit(mustBeSent = false) {
                this.$emit('loading', true)
                this.saveLaunchingMail({
                    must_be_sent: mustBeSent,
                    ...this.launchingMail,
                    keywords: this.keywordsIds,
                    firstPart: this.mailParts.firstpart,
                    secondPart: this.mailParts.secondPart,
                    thirdPart: this.mailParts.thirdPart,
                    fourthPart: this.mailParts.fourthPart
                })
                    .catch(e => console.error(e))
                    .finally(() => {
                        this.$emit('closeModal')
                        this.$emit('loading', false)
                    })
            },

            getMailParts() {
                this.loading = true
                this.axios
                    .get('/config/launching')
                    .then(response => {
                        this.mailParts.firstPart = response.data.firstPart
                        this.mailParts.secondPart = response.data.secondPart
                        this.mailParts.thirdPart = response.data.thirdPart
                        this.mailParts.fourthPart = response.data.fourthPart
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            getPreview() {
                this.getMailPreview({
                    campaignId: this.campaign.id,
                    type: 'launch',
                    data: {
                        ...this.launchingMail,
                        keywords: this.keywordsIds,
                        firstPart: this.mailParts.firstPart,
                        secondPart: this.mailParts.secondPart,
                        thirdPart: this.mailParts.thirdPart,
                        fourthPart: this.mailParts.fourthPart
                    }
                }).then(res => {
                    this.preview = res
                    this.showPreview = true
                })
            }
        },
        created() {
            if (this.campaign.mails.launching) {
                this.launchingMail = this.campaign.mails.launching.options
            } else {
                this.launchingMail.recipient_email = this.campaign.customer.email
                this.launchingMail.sender_email = this.campaign.consultants.offSite.email
                this.launchingMail.bcc = this.campaign.consultants.offSite.email
            }
            this.getMailParts()
        }
    }
</script>
