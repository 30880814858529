<template>
    <div class="row px-xl-5 mb-xl-5">
        <div class="p-2 col-md-12">
            <div class="card">
                <div class="card-header row px-4 py-3 text-light" style="">
                    <div class="col-md-8">
                        <h3 class="mb-0">
                            <slot name="title">
                                {{ $t('profil.address.submenu') }}
                            </slot>
                        </h3>
                    </div>
                </div>

                <div class="card-body px-4 py-0">
                    <v-form @submit="save">
                        <div class="row">
                            <div class="col-md-12">
                                <v-textfield
                                    v-model="profile.company"
                                    class-label="form-control-label-profil"
                                    :name="$t('user.company')"
                                    :label="$t('user.company') + (checkRole('partenaire') ? '*' : '')"
                                    :rules="partnerRules"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-4">
                                <v-textfield
                                    v-model="profile.address"
                                    class-label="form-control-label-profil"
                                    :name="$t('user.address')"
                                    :label="$t('user.address') + '*'"
                                    :rules="partnerRules"
                                />
                            </div>
                            <div class="col-md-12 col-lg-4">
                                <v-textfield
                                    v-model="profile.cp"
                                    class-label="form-control-label-profil"
                                    :name="$t('user.postal-code')"
                                    :label="$t('user.postal-code') + '*'"
                                    :rules="partnerRules"
                                />
                            </div>
                            <div class="col-md-12 col-lg-4">
                                <v-textfield
                                    v-model="profile.ville"
                                    class-label="form-control-label-profil"
                                    :name="$t('user.city')"
                                    :label="$t('user.city') + '*'"
                                    :rules="partnerRules"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-4">
                                <nl-select
                                    v-model="profile.country"
                                    class-label="form-control-label-profil"
                                    :name="$t('user.country')"
                                    :items="countries"
                                    :label="$t('user.country') + (checkRole('partenaire') ? '*' : '')"
                                    :rules="partnerRules"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-4">
                                <v-textfield
                                    v-model="profile.tva_number"
                                    class-label="form-control-label-profil"
                                    :name="$t('user.TVAnumber')"
                                    :label="$t('user.TVAnumber')"
                                />
                            </div>
                            <div v-if="isFrench" class="col-md-12 col-lg-4">
                                <v-textfield
                                    v-model="profile.siret"
                                    class-label="form-control-label-profil"
                                    :name="$t('user.siret')"
                                    :label="$t('user.siret') + (checkRole('partenaire') ? '*' : '')"
                                    :rules="partnerRules"
                                />
                            </div>
                        </div>
                        <div v-show="profile.country" class="row">
                            <div v-if="needNewAttestation" class="col-md-6 col-lg-4">
                                <div v-if="profile.sum_withdraw < 5000">
                                    <nl-select
                                        v-model="attestationType"
                                        class-label="form-control-label-profil"
                                        :name="$t('user.attestation.type')"
                                        :items="attestationsTypes"
                                        :label="$t('user.attestation.type')"
                                        @change="uploadAttestation"
                                    />

                                    <b-tag
                                        v-if="
                                            profile.attestations &&
                                            profile.attestations.length > 0 &&
                                            needNewAttestation
                                        "
                                        type="is-danger mt-2"
                                        rounded
                                    >
                                        Attestation
                                        {{
                                            profile.attestations[profile.attestations.length - 1].status === 'expired'
                                                ? 'expiré'
                                                : 'refusé'
                                        }}
                                    </b-tag>
                                </div>
                                <div v-else>
                                    <label class="form-control-label-profil"
                                        >{{ $t('user.attestation.type') }}
                                        <b-tag
                                            v-if="
                                                profile.attestations &&
                                                profile.attestations.length > 0 &&
                                                needNewAttestation
                                            "
                                            type="is-danger"
                                            rounded
                                            >{{
                                                profile.attestations[profile.attestations.length - 1].status ===
                                                'expired'
                                                    ? 'Expiré'
                                                    : 'Refusé'
                                            }}
                                        </b-tag>
                                    </label>
                                    <div>{{ $t('user.attestation.urssaf_required') }}</div>
                                </div>
                            </div>
                            <div v-if="attestationType || !needNewAttestation" class="col-md-6 col-lg-4">
                                <label class="form-control-label-profil">Attestation</label>
                                <template v-if="needNewAttestation">
                                    <file-upload
                                        v-if="!savingAttestation"
                                        @uploaded="uploadAttestation"
                                        @onUploadError="uploadError"
                                    />
                                    <p class="warning-attestaion">
                                        {{ $t('user.attestation.warning') }}
                                    </p>
                                </template>
                                <div v-else>
                                    <b-tag
                                        :type="{
                                            'is-success': attestationStatus === 'Valide',
                                            'is-warning': attestationStatus === 'En cours de validation'
                                        }"
                                        rounded
                                    >
                                        {{ attestationStatus }}
                                    </b-tag>
                                    <b-tag
                                        v-if="attestationStatus === 'Valide'"
                                        type="mx-2"
                                        :class="{
                                            'is-danger': moment().isSameOrAfter(
                                                profile.attestations[profile.attestations.length - 1].expiration
                                            ),
                                            'is-primary': moment().isBefore(
                                                profile.attestations[profile.attestations.length - 1].expiration
                                            ),
                                            'is-warning': moment().isBefore(
                                                profile.attestations[profile.attestations.length - 1].expiration,
                                                'month'
                                            )
                                        }"
                                        rounded
                                    >
                                        Expiration:
                                        {{
                                            moment(
                                                profile.attestations[profile.attestations.length - 1].expiration
                                            ).format('DD/MM/YYYY')
                                        }}
                                    </b-tag>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-auto mr-auto">&nbsp;</div>
                            <div class="col-auto">
                                <div class="row mb-0">
                                    <button class="btn btn-secondary mr-2" :disabled="loading">
                                        {{ $t('global.save') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <b-loading v-model="loading" :is-full-page="false" />
                    </v-form>
                </div>
            </div>
        </div>
        <Toaster ref="toaster" />
    </div>
</template>

<script>
    import AbstractProfile from './AbstractProfile.vue'
    import FileUpload from '@/components/UI/Upload/FileUpload.vue'
    import { mapActions } from 'vuex'
    import Toaster from '@/components/UI/Toaster.vue'

    export default {
        name: 'AccountAddress',
        components: {
            Toaster,
            FileUpload
        },
        mixins: [AbstractProfile],
        data: () => ({
            attestationType: null
        }),
        computed: {
            attestationsTypes() {
                if (this.profile.sum_withdraw >= 5000 && this.isFrench) {
                    return [{ text: this.$t('user.attestation.urssaf'), id: 'urssaf' }]
                } else if (this.isFrench) {
                    return [
                        { text: this.$t('user.attestation.urssaf'), id: 'urssaf' },
                        { text: this.$t('user.attestation.kbis'), id: 'kbis' },
                        { text: this.$t('user.attestation.insee'), id: 'insee' }
                    ]
                } else {
                    return [{ text: this.$t('user.attestation.others'), id: 'other' }]
                }
            },
            needNewAttestation() {
                if (this.profile.attestations && this.profile.attestations.length) {
                    const last = this.profile.attestations[this.profile.attestations.length - 1]
                    return last.status === 'expired' || last.status === 'refused'
                }
                return true
            },
            attestationStatus() {
                if (this.profile.attestations && this.profile.attestations.length) {
                    const last = this.profile.attestations[this.profile.attestations.length - 1]
                    if (last.status === 'new') {
                        return 'En cours de validation'
                    }
                    if (last.status === 'validate') {
                        return 'Valide'
                    }
                    if (last.status === 'expired') {
                        return 'Expiré'
                    }
                }
                return null
            },
            isAttestationNeeded() {
                return this.profile.country === 'FR'
            }
        },
        watch: {
            profile() {
                if (this.profile.sum_withdraw >= 5000) {
                    this.attestationType = 'urssaf'
                }
            }
        },
        created() {},
        methods: {
            ...mapActions('auth', {
                updateUser: 'update'
            }),
            save: function () {
                this.loading = true

                this.updateUser(this.profile)
                    .then(data => {
                        this.fireToast('success', data.message, '')
                    })
                    .catch(response => {
                        let msg = []
                        if (response.data.errors) {
                            for (const [, value] of Object.entries(response.data.errors)) {
                                value.forEach(txt => msg.push(txt))
                            }
                        }

                        this.fireToast('error', msg, '')
                    })
                    .finally(() => {
                        this.loading = false
                        this.$emit('saved')
                    })
            },
            uploadAttestation(file) {
                if (file[0] && file[0].file && this.attestationType) {
                    this.savingAttestation = true
                    this.saveAttestation({
                        url: file[0].file.url,
                        type: this.attestationType
                    })
                        .then(data => {
                            this.fireToast('success', data.message, '')
                        })
                        .catch(error => {
                            if (error.data && error.data.message) {
                                this.fireToast('error', error.data.message, '')
                            } else {
                                let msg = []
                                if (error.data.errors) {
                                    for (const [, value] of Object.entries(error.data.errors)) {
                                        value.forEach(txt => msg.push(txt))
                                    }
                                }
                                this.fireToast('error', msg, '')
                            }
                        })
                }
            },
            uploadError: function (error) {
                console.log(error)
                let msg = []
                if (error.data.errors) {
                    for (const [, value] of Object.entries(error.data.errors)) {
                        value.forEach(txt => msg.push(txt))
                    }
                }
                this.fireToast('error', msg.join('<br>'), '')
            },

            fireToast: function (type, message, message1) {
                this.$refs.toaster.fire(type, message, message1)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .warning-attestaion {
        font-size: 14px;
        color: orange;
    }
</style>