<template>
    <div class="row px-xl-5 mb-xl-3">
        <div class="p-2 col-md-12">
            <div class="card">
                <div class="card-header row px-4 py-3 text-light" style="">
                    <div class="col-md-8">
                        <h3 class="mb-0">
                            {{ $t('profil.delete.submenu') }}
                        </h3>
                    </div>
                </div>

                <div class="card-body px-4 py-0">
                    <div class="row">
                        <div class="col">
                            <v-form @submit="deleteAccount">
                                <h2 class="text-danger">
                                    {{ $t('profil.delete.delete-account') }}
                                </h2>
                                <v-checkbox v-model="confirmDelete" rules="required">
                                    {{ $t('profil.delete.confirm') }}
                                </v-checkbox>
                                <div class="row mb-0 ml-1">
                                    <button class="btn btn-primary" :disabled="loading">
                                        {{ $t('profil.delete.delete-account') }}
                                    </button>
                                </div>
                                <b-loading v-model="loading" :is-full-page="false" />
                            </v-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AbstractProfile from './AbstractProfile.vue'

    export default {
        name: 'AccountDelete',
        mixins: [AbstractProfile],
        data: function () {
            return {}
        },
        created() {},
        methods: {
            deleteAccount: function () {
                this.$emit('deleteAccount')
            }
        }
    }
</script>
