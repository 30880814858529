<template>
    <fragment>
        <b-select
            icon="align-center"
            :disabled="loading"
            :expanded="$screen.width < 768"
            :value="value"
            @input="handleInput"
        >
            <option :value="25">25</option>
            <option :value="50">50</option>
            <option :value="100">100</option>
            <option :value="250">250</option>
            <option :value="500">500</option>
        </b-select>
    </fragment>
</template>

<script>
    export default {
        name: 'PerPageSelector',
        props: {
            value: {
                type: Number,
                default: 25
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            handleInput(value) {
                this.$emit('input', value)
                this.$emit('perPageChange')
            }
        }
    }
</script>
