<template>
    <div>
        <b-loading v-model="isLoading" :is-full-page="false" />
        <div>
            <div class="d-flex justify-content-end">
                <div class="d-flex">
                    <export-button
                        v-if="followups.data.length > 0"
                        type=""
                        file-name="suivi-export.xlsx"
                        :sheets="[
                            {
                                name: 'Feuille 1',
                                data: followups.data.map(followup => {
                                    return {
                                        Duration: moment.duration(followup.duration * 1000).humanize(),
                                        Période: $d(Date.parse(followup.period), 'month'),
                                        Thématique: followup.thematic,
                                        Tache: followup.task,
                                        Statut: followup.status,
                                        Commentaire: followup.comment
                                    }
                                })
                            }
                        ]"
                    />
                    <b-button class="ml-3" :disabled="isLoading" @click="editFollowup()">
                        <i class="fas fa-plus" />
                    </b-button>
                </div>
            </div>
            <div class="mb-5">
                <b-table :data="followups.data" :loading="isLoadingTable" striped>
                    <b-table-column v-slot="props" field="period" label="Période">
                        {{ $d(Date.parse(props.row.period), 'month') }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="thematic" label="Thématique">
                        {{ $t(`accompagnement.followup.thematic.${props.row.thematic}`) }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="period" label="Tâche">
                        {{ props.row.task }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="duration" label="Durée">
                        {{
                            moment.utc(moment.duration(props.row.duration, 'minutes').asMilliseconds()).format('HH:mm')
                        }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="duration" label="Date">
                        {{ $d(Date.parse(props.row.period), 'short') }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="status" label="Statut">
                        <followup-status
                            :readonly="readonly"
                            :status="props.row.status"
                            :available="followups.meta.status"
                            @update="updateStatus({ ...props.row, status: $event })"
                        />
                    </b-table-column>
                    <b-table-column v-slot="props" field="comment" label="Commentaire">
                        {{ props.row.comment }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="period" cell-class="d-flex justify-content-end text-right">
                        <b-button type="is-light" @click="editFollowup(props.row)">
                            <b-icon icon="pen" />
                        </b-button>
                        <b-button class="text-danger ml-2" type="is-light" @click="deleteFollowup(props.row.id)">
                            <b-icon icon="trash" type="is-danger" />
                        </b-button>
                    </b-table-column>
                </b-table>
            </div>
            <portal to="bootstrap">
                <followup-form
                    v-if="!readonly"
                    :content="followupBeingEdited"
                    :status-available="followups.meta.status"
                    :tasks-available="followups.meta.tasks"
                    :thematics-available="followups.meta.thematics"
                    :show="followupBeingEdited !== null"
                    @save="updateFollowup($event)"
                    @delete="deleteFollowup($event)"
                    @discard="followupBeingEdited = null"
                />
            </portal>
            <onsite-actions />
        </div>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay'
    import FollowupForm from './FollowupForm'
    import FollowupStatus from './FollowupStatus'
    import { mapActions, mapState } from 'vuex'
    import moment from 'moment'
    import ExportButton from '../Tools/ExportButton'
    import OnsiteActions from './OnsiteActions.vue'

    export default {
        components: {
            OnsiteActions,
            ExportButton,
            FollowupStatus,
            FollowupForm,
            Loading
        },
        setup() {
            return {
                moment
            }
        },
        props: {
            readonly: {
                type: Boolean,
                default: false
            },
            activeTab: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                followupBeingEdited: null,
                isLoading: true,
                isLoadingTable: true
            }
        },
        watch: {
            activeTab(newValue, oldValue) {
                if (newValue === 2 && oldValue !== 2) {
                    this.isLoadingTable = true
                    this.getCampaignFollowUps(this.$route.query.id).finally(() => {
                        this.isLoading = false
                        this.isLoadingTable = false
                    })
                }
            }
        },
        computed: {
            ...mapState('campaigns', {
                followups: state => state.followUps.data
            }),
            totalDuration() {
                return this.followups.data.reduce(function (total, followup) {
                    return total + followup.duration
                }, 0)
            }
        },
        methods: {
            ...mapActions('campaigns', [
                'getCampaignFollowUps',
                'updateCampaignFollowUps',
                'createCampaignFollowUps',
                'deleteCampaignFollowUps'
            ]),
            editFollowup: function (followup) {
                this.followupBeingEdited = followup
            },
            updateStatus(followup, status) {
                this.updateCampaignFollowUps({
                    campaignId: this.$route.query.id,
                    followUpId: followup.id,
                    params: {
                        ...followup,
                        status: status
                    }
                })
            },
            updateFollowup: function (followupUpdated) {
                if (followupUpdated.id) {
                    this.updateCampaignFollowUps({
                        campaignId: this.$route.query.id,
                        followUpId: followupUpdated.id,
                        params: {
                            ...followupUpdated
                        }
                    })
                } else {
                    this.createCampaignFollowUps({
                        campaignId: this.$route.query.id,
                        params: {
                            ...followupUpdated
                        }
                    })
                }
            },
            deleteFollowup: function (id) {
                this.deleteCampaignFollowUps({
                    campaignId: this.$route.query.id,
                    followUpId: id
                })
            }
        }
    }
</script>
