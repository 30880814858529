<template>
    <nl-modal :open-modal="openModal" title="Profil Commercial" size="xl" @hidden="close">
        <template v-if="user">
            <div class="row">
                <div class="col-6 form-group">
                    <span
                        class="badge"
                        :class="{
                            'badge-darkorange': user.partner_status == null,
                            'badge-danger': user.partner_status === 'invalidate',
                            'badge-success': user.partner_status === 'validate'
                        }"
                    >
                        {{ getPartnerStatus(user.partner_status) }}
                    </span>
                </div>
                <div class="col-6 text-right">
                    <button v-if="!editProfile" class="btn btn-sm btn-primary" @click="editProfile = !editProfile">
                        {{ $t('partnerAdmin.actions.modifyProfile') }}
                    </button>
                    <button v-if="editProfile" class="btn btn-sm btn-success" @click="save">
                        {{ $t('partnerAdmin.actions.saveProfile') }}
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-6 form-group">
                    <text-field v-model="user.firstname" :disabled="!editProfile" :label="$t('user.firstname')" />
                </div>
                <div class="col-6 form-group">
                    <text-field v-model="user.lastname" :disabled="!editProfile" :label="$t('user.lastname')" />
                </div>
            </div>
            <div class="row">
                <div class="col-6 form-group">
                    <text-field v-model="user.company" :disabled="!editProfile" :label="$t('user.company')" />
                </div>
                <div class="col-6 form-group">
                    <text-field v-model="user.email" :disabled="!editProfile" :label="$t('user.email')" />
                </div>
            </div>
            <div class="row">
                <div class="col-6 form-group">
                    <text-field v-model="user.telephone" :disabled="!editProfile" :label="$t('user.phone')" />
                </div>
            </div>
            <div class="row">
                <div class="col-6 form-group">
                    <text-field v-model="user.address" :disabled="!editProfile" :label="$t('user.address')" />
                </div>
                <div class="col-6 form-group">
                    <text-field v-model="user.cp" :disabled="!editProfile" :label="$t('user.postal-code')" />
                </div>
            </div>
            <div class="row">
                <div class="col-6 form-group">
                    <text-field v-model="user.ville" :disabled="!editProfile" :label="$t('user.city')" />
                </div>
                <div class="col-6 form-group">
                    <nl-select
                        v-model="user.country"
                        class-label="form-control-label-profil"
                        :name="$t('user.country')"
                        :items="countries"
                        :label="$t('user.country')"
                        :disabled="!editProfile"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6 form-group">
                    <text-field v-model="user.tva_number" :disabled="!editProfile" :label="$t('user.TVAnumber')" />
                </div>
                <div class="col-6 form-group">
                    <text-field v-model="user.siret" :disabled="!editProfile" :label="$t('user.siret')" />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3>{{ $t('partnerAdmin.attestation.label') }}</h3>
                    <UserAttestations :user-attestations="user.attestations" :user="user" @refresh="refresh" />
                </div>
            </div>
        </template>
        <template #footer>
            <button class="btn btn-success" @click="updateStatus('validate')">
                {{ $t('partnerAdmin.actions.validate') }}
            </button>
            <button class="btn btn-danger" @click="updateStatus('invalidate')">
                {{ $t('partnerAdmin.actions.invalidate') }}
            </button>
            <div style="flex: 1 1 auto" />
            <button class="btn btn-primary" @click="close">
                {{ $t('global.close') }}
            </button>
        </template>
    </nl-modal>
</template>

<script>
    import { mapActions } from 'vuex'
    import { EventManager } from '@/components/EventManager'
    import UserAttestations from './UserAttestations.vue'
    import countryList from '@/utils/countries'

    export default {
        components: {
            EventManager,
            UserAttestations
        },
        data: () => ({
            editProfile: false,
            roles: [
                { text: 'admin', id: 'admin' },
                { text: 'prospect', id: 'prospect' },
                { text: 'partenaire', id: 'partenaire' },
                { text: 'consultant', id: 'consultant' },
                { text: 'agence', id: 'agence' },
                { text: 'administratif', id: 'administratif' }
            ],
            status: [
                { text: 'Actif', id: 1 },
                { text: 'Inactive', id: 0 }
            ],
            sponsorshipTypes: [
                { text: 'Crédit', id: 'credit' },
                { text: 'Gain', id: 'gain' }
            ],
            openModal: false,
            user: null
        }),
        computed: {
            partnerStatus() {
                return {
                    undefined: this.$t('partnerAdmin.status.undefined'),
                    validate: this.$t('partnerAdmin.status.validate'),
                    invalidate: this.$t('partnerAdmin.status.invalidate')
                }
            },

            countries() {
                return countryList[this.$store.state.locale]
            }
        },
        created() {
            EventManager.$on('onViewCommercialProfile', this.init)
        },
        methods: {
            ...mapActions('users', {
                getUser: 'get',
                updateUser: 'update',
                updatePartnerStatus: 'updatePartnerStatus'
            }),
            init(data) {
                this.user = Object.assign({}, data)
                this.openModal = true
            },
            close() {
                this.user = Object.assign({})
                this.openModal = false
            },
            refresh() {
                this.getUser(this.user.id).then(data => {
                    this.user = Object.assign({}, data)
                })
            },
            save() {
                this.updateUser(this.user).then(data => {
                    this.$emit('saved', data)
                    this.editProfile = false
                })
            },
            updateStatus(status) {
                this.updatePartnerStatus({
                    id: this.user.id,
                    status: status
                }).then(data => {
                    this.$emit('saved', data)
                    this.close()
                })
            },
            getPartnerStatus(status) {
                if (!status) {
                    status = 'undefined'
                }
                return this.partnerStatus[status]
            }
        }
    }
</script>

<style></style>
