var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-group-item"},[_c('span',{staticClass:"mr-1 d-inline-block",class:{
            'text-success': _vm.isValid,
            'text-warning': !_vm.isValid && !_vm.required,
            'text-danger': !_vm.isValid && _vm.required
        },staticStyle:{"width":"20px"}},[_c('i',{staticClass:"fas",class:{ 'fa-check': _vm.isValid, 'fa-exclamation': !_vm.isValid }})]),_vm._v(" "+_vm._s(_vm.name)+" : "),_c('strong',{class:{
            'text-warning': !_vm.isValid && !_vm.required,
            'text-danger': !_vm.isValid && _vm.required
        }},[(_vm.value)?_c('span',[_vm._v(_vm._s(_vm.value))]):_c('span',[_vm._v("non défini")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }