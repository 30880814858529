<template>
    <div class="d-flex flex-column" style="gap: 1rem">
        <slot name="filters" :search="search" :forceSubmit="forceSubmit">
            <nl-search-filters @search="search" :forceSubmit="forceSubmit" />
        </slot>
        <slot name="results">
            <SearchResult
                v-if="results?.length"
                :credit="credits"
                :display-current-position="displayCurrentPosition"
                :display-current-url="displayCurrentUrl"
                :loading="loading"
                :meta="meta"
                :modal-infos="modalInfos"
                :project-id="projectId"
                :results="results"
                :inner-hits-key="innerHitsKey"
                @search="forceSubmit = !forceSubmit"
                :is-favorite="isFavorite"
            />
        </slot>
        <slot v-if="!results?.length" name="errors" :props="{ filters, loading }">
            <div v-if="loading" class="box p-5" style="position: relative">
                <b-loading :active="loading" :is-full-page="false" />
            </div>
            <b-notification v-else-if="hasError" active :closable="false" type="is-danger is-light">
                {{ $t('error.message.default') }}
            </b-notification>
            <Card
                v-else-if="filters.keyword"
                v-show="!loading"
                :keyword="filters.keyword"
                :email="project.destinataires_mails"
                :lang="filters.lang === 'en' ? 'com_en' : 'fr_fr'"
            />
            <b-notification v-else active :closable="false" type="is-warning is-light">
                {{ $t('searchform.enter-keyword') }}
            </b-notification>
        </slot>
    </div>
</template>

<script>
    import NlSearchFilters from '@/components/Search/NlSearchFilters'
    import { mapActions, mapMutations, mapState } from 'vuex'
    import Card from '@/components/Search/Card.vue'
    import SearchResult from '@/components/Search/SearchResult.vue'
    import axios from '@/config/backend'

    export default {
        name: 'SearchLayout',
        props: {
            getData: {
                type: Function,
                required: true,
                default: () => {}
            },
            displayCurrentPosition: { type: Boolean, default: false },
            displayCurrentUrl: { type: Boolean, default: false },
            isFavorite: { type: Boolean, default: false },
            innerHitsKey: {
                type: String,
                default: 'keywords_hits'
            }
        },
        components: {
            SearchResult,
            Card,
            NlSearchFilters
        },
        data() {
            return {
                keyword: null,
                wordExcluded: ['le', 'la', 'l', 'c', 'pour', 'a', 'de', 'sans', 'une', 'un', 'ou', 'des', 'les', 'en'],
                isSearchExact: false,
                orderBy: 'DESC',
                mainThematic: null,
                modalInfos: {
                    content: null,
                    info: null
                },

                loading: false,
                forceSubmit: false,
                hasError: false
            }
        },
        computed: {
            ...mapState('search', {
                filters: 'filters',
                meta: 'meta',
                results: 'results'
            }),
            ...mapState('auth', {
                credits: state => state.user?.credit_amount
            }),
            projectId() {
                return this.$store.state.project ? this.$store.state.project.id : null
            },
            project() {
                return this.$store.state.project
            }
        },
        watch: {
            projectId: {
                immediate: true,
                handler() {
                    this.handleProjectChange()
                }
            }
        },
        methods: {
            ...mapMutations('search', {
                setResults: 'setResults'
            }),
            ...mapActions('search', {
                resetSearch: 'resetSearch'
            }),
            ...mapActions('favorites', { getFavorites: 'index' }),
            ...mapActions('config', ['getModalInfo']),
            search(params) {
                this.loading = true

                this.getData({
                    ...params,
                    ...this.meta,
                    projectId: this.projectId
                })
                    .then(() => {
                        this.loading = false
                        this.hasError = false
                    })
                    .catch(e => {
                        if (!axios.isCancel(e)) {
                            this.loading = false
                            this.hasError = true
                            this.$store.dispatch('toastError', e)
                        }
                    })
            },
            async handleProjectChange() {
                this.forceSubmit = !this.forceSubmit
                void this.getFavorites({
                    projectId: this.projectId
                })
            }
        },
        async created() {
            this.getModalInfo().then(({ modal_bloc_message, information }) => {
                this.modalInfos.content = modal_bloc_message
                this.modalInfos.info = information
            })

            this.setResults({ data: [] })
            this.forceSubmit = !this.forceSubmit
        },
        beforeDestroy() {
            this.resetSearch()
        }
    }
</script>