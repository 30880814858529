<template>
    <div class="d-flex align-items-center" v-if="!this.isAdminMenuOpen">
        <transition name="fade" mode="out-in">
            <div
                class="nav-item"
                v-if="
                    ['prospect', 'agence', 'partenaire', 'manager', 'consultant', 'admin'].includes(role) &&
                    credits <= 0 &&
                    displayBalance === 'credits'
                "
            >
                <router-link to="/buy-credits" class="buy_credits">
                    <i class="fas fa-cart-plus mr-2"></i>
                    {{ $t('mes-credits.buycredits') }}
                </router-link>
            </div>
            <div
                class="nav-item"
                v-else-if="
                    ['prospect', 'agence', 'partenaire', 'manager', 'consultant', 'admin'].includes(role) &&
                    credits > 0 &&
                    displayBalance === 'credits'
                "
            >
                <router-link v-if="!isSidebar" to="/credits" class="balance__item">
                    <div class="balance__subitem">
                        <span>{{ $t('profil.sponsorship.credit') }}</span>
                        <span v-if="credits">{{ credits.toFixed(2) }}</span>
                        <b-skeleton class="mt-3" v-else animated />
                    </div>
                    <i class="fas fa-euro-sign balance__icon"></i>
                </router-link>
                <router-link v-else to="/credits" class="text-white">
                    <div class="d-flex">
                        <span> {{ $t('profil.sponsorship.credit') }}&nbsp;:&nbsp; </span>
                        <span v-if="credits">{{ credits.toFixed(2) }} €</span>
                        <b-skeleton class="mt-3" v-else animated />
                    </div>
                </router-link>
            </div>
            <div
                class="nav-item"
                v-else-if="this.$parent.checkRole('partenaire') === true && displayBalance === 'benefits'"
            >
                <router-link v-if="!isSidebar" to="/partner/earnings" class="balance__item">
                    <div class="balance__subitem">
                        <span>{{ $t('layout.gains') }}</span>
                        <span v-if="benefits || typeof benefits === 'number'">{{ benefits.toFixed(2) }}</span>
                        <b-skeleton v-else animated />
                    </div>
                    <i class="fas fa-euro-sign balance__icon"></i>
                </router-link>
                <router-link v-else to="/credits" class="text-white">
                    <div class="d-flex">
                        <span> {{ $t('layout.gains') }} &nbsp;:&nbsp; </span>
                        <span v-if="benefits || typeof benefits === 'number'">{{ benefits.toFixed(2) }} €</span>
                        <b-skeleton class="mt-3" v-else animated />
                    </div>
                </router-link>
            </div>
        </transition>
    </div>
</template>

<style scoped>
    .buy_credits {
        background-color: #2b383f;
        text-transform: uppercase;
        color: #fabc15;
        width: fit-content;
        height: max-content;
        padding: 0.6rem 1.2rem;
        font-weight: bold;
        border-radius: 2rem;
    }

    .balance__item {
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        text-decoration: none;
    }

    .balance__subitem {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .balance__subitem > span {
        margin: 0;
        padding: 0;
        width: min-content;
        height: min-content;
        text-align: end;
        color: #2b383f;
    }

    .balance__subitem > span:first-child {
        font-size: 0.7rem;
        margin-bottom: -0.4rem;
    }

    .balance__subitem > span:last-child {
        font-size: 1.1rem;
        font-weight: bold;
    }

    .balance__icon {
        font-size: 1.6rem;
        margin-left: 0.3rem;
        color: #2b383f;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
        opacity: 0;
    }
</style>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'CreditGainMenu',
        props: {
            isSidebar: {
                type: Boolean,
                required: false
            }
        },
        computed: {
            ...mapState('auth', {
                credits: state => state.user?.credit_amount,
                benefits: state => Number.parseFloat(state.user?.gains)
            }),
            ...mapState('auth', {
                role: state => state.user?.role
            }),
            isPartnerMenuOpen() {
                return this.$store.state.isPartnerMenuOpen
            },
            isAdminMenuOpen() {
                return this.$store.state.isAdminMenuOpen
            },
            displayBalance() {
                if (this.isPartnerMenuOpen) {
                    return 'benefits'
                } else if (this.isAdminMenuOpen) {
                    return 'none'
                } else {
                    return 'credits'
                }
            }
        },
        mounted() {}
    }
</script>
