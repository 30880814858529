<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 v-if="moveData.active" class="h4 p-0 mb-0">
                        {{ $t('global.move') }}
                    </h4>
                    <h4 v-else class="h4 p-0 mb-0">
                        {{ $t('suivi.urldate') }}
                    </h4>
                </header>
                <div class="card-content">
                    <div class="content">
                        <section class="row">
                            <div v-if="orderData.active" class="col-12">
                                <b-loading v-model="dataLoading" :is-full-page="true" />

                                <div v-if="orderData.data">
                                    <b-notification
                                        v-if="!orderData.order.keywords"
                                        type="is-danger is-light"
                                        :closable="false"
                                        role="alert"
                                    >
                                        <i class="fa fa-exclamation-triangle mr-2" />
                                        {{ $t('suivi.cannot-found-history') }}
                                    </b-notification>

                                    <div>
                                        <h4>URL :</h4>
                                        <p>
                                            <strong>{{ $t('suivi.numberofkeywords') }} : </strong>
                                            {{ keywords_list.length }}
                                        </p>
                                        <!--<p v-if="orderData.data.keyword_info"><strong>{{ $t('tableau-keywords.urlindice') }} : </strong> {{ orderData.data.keyword_info.indice_url }}</p>-->
                                        <hr />
                                    </div>

                                    <div>
                                        <h4>Site :</h4>
                                        <p>
                                            <strong>{{ $t('global.category') }} : </strong>
                                            {{ orderData.data.site_type }}
                                        </p>
                                        <p>
                                            <strong>{{ $t('global.theme') }} : </strong>
                                            {{ orderData.data.group_name }}
                                        </p>
                                        <hr />
                                    </div>

                                    <div v-if="orderData.data.keyword_info">
                                        <h4>{{ $t('global.keyword') }} :</h4>
                                        <p>
                                            <strong>Position : </strong>
                                            {{ orderData.data.keyword_info.position }}
                                        </p>
                                        <p>
                                            <strong>Volume : </strong>
                                            {{ orderData.data.keyword_info.volume }}
                                        </p>
                                        <hr />
                                    </div>

                                    <div>
                                        <h4>{{ $t('suivi.otherkeywordspositionned') }} :</h4>
                                        <ul>
                                            <li v-for="keyword in keywords_list.slice(0, 5)" :key="keyword" class="p-2">
                                                {{ keyword }}
                                            </li>
                                        </ul>
                                        <ul v-if="keywords_list.length > 5 && seeMoreKeywords">
                                            <li
                                                v-for="keyword in keywords_list.length.slice(5)"
                                                :key="keyword"
                                                class="p-2"
                                            >
                                                {{ keyword }}
                                            </li>
                                        </ul>
                                        <button
                                            v-if="keywords_list.length > 5"
                                            class="button is-light is-small"
                                            @click.prevent="seeMoreKeywords = !seeMoreKeywords"
                                        >
                                            {{ seeMoreKeywords ? $t('global.hide') : $t('suivi.seemorekeywords') }}
                                        </button>
                                        <hr />
                                    </div>
                                </div>

                                <div v-else>
                                    <p class="text-muted p-4 rounded border text-center">...</p>
                                </div>
                            </div>

                            <div v-if="moveData.active" class="col-12">
                                <p class="mt-4 text-center">
                                    {{ $t('suivi.move-orders') }} :
                                    <strong>#{{ this.listIds.join(', #') }}</strong>
                                    {{ $t('suivi.in-project') }}
                                    <strong>{{ moveData.project.name }}</strong
                                    >.
                                </p>
                            </div>

                            <div v-if="moveData.active" class="col-12 buttons justify-content-end">
                                <button class="button is-light" @click.prevent="closeAndResetModal">
                                    {{ $t('global.cancel') }}
                                </button>
                                <button class="button is-warning" @click.prevent="validate">
                                    {{ $t('global.validate') }}
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'PartnerOrdersConfirmModal',
        props: ['checkedRows'],
        data() {
            return {
                modalIsOpen: false,
                listIds: [],
                dataLoading: false,
                seeMoreKeywords: false,

                moveData: {
                    active: false,
                    project: {
                        id: null,
                        name: null
                    }
                },
                orderData: {
                    active: false,
                    order: null,
                    data: null
                }
            }
        },
        computed: {
            keywords_list() {
                return this.orderData.order.keywords && this.orderData.order.keywords.length > 0
                    ? this.orderData.order.keywords.split(',')
                    : []
            }
        },

        watch: {
            checkedRows() {
                this.listIds = this.checkedRows.map(order => order.id)
            }
        },

        created() {
            this.listIds = this.checkedRows.map(order => order.id)
        },

        methods: {
            validate() {
                this.$parent.$emit('moveProject', {
                    project_id: this.moveData.project.id,
                    ids: this.listIds
                })

                this.closeAndResetModal()
            },

            openModal(projectOrOrder) {
                this.modalIsOpen = true

                if (projectOrOrder.hasOwnProperty('name')) {
                    this.moveData = {
                        active: true,
                        project: {
                            id: projectOrOrder.id,
                            name: projectOrOrder.name
                        }
                    }

                    return
                }

                this.dataLoading = true

                this.orderData = {
                    active: true,
                    order: projectOrOrder,
                    data: null
                }

                this.$parent.$emit('getDataUrl', {
                    order_id: projectOrOrder.id,
                    callback: result => {
                        this.dataLoading = false
                        this.orderData.data = result
                    }
                })
            },

            closeAndResetModal() {
                this.modalIsOpen = false
                this.seeMoreKeywords = false
                this.$emit('uncheckRows')

                this.moveData = {
                    active: false,
                    project: {
                        id: null,
                        name: null
                    }
                }

                this.orderData = {
                    active: false,
                    order: null,
                    data: null
                }
            }
        }
    }
</script>

<style>
    label[for^='scrape_'] {
        color: #363636;
        display: block;
        font-size: 1rem;
        font-weight: 700;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
