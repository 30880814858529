import ValidationHelper from '@/utils/validationHelper'
import NlStorage from "@/services/nl.storage";

export default {
    setUser(state, data) {
        NlStorage.setItem('user', JSON.stringify(data))
        state.user = data
    },
    setTokenData(state, token) {
        NlStorage.setItem('token', token.access_token)
        state.tokenData = token
    },
    setToken(state, token) {
        NlStorage.setItem('token', token)
    },
    setConnectedAs(state, data) {
        state.connectedAs = data
        NlStorage.setItem('connectedAs', JSON.stringify(data))
    },
    setFromAdmin(state, data) {
        state.fromAdmin = data
    },
    addToken(state, data) {
        state.tokens.push({
            user: state.user,
            token: NlStorage.getItem('token'),
            url: data.url
        })

        NlStorage.setItem('tokens', JSON.stringify(state.tokens))
    },
    subToken(state) {
        let { user, token, url } = state.tokens.pop()

        this.commit('auth/setUser', user)
        this.commit('auth/setToken', token)
        this.commit('auth/setUrl', url)

        if (state.tokens.length <= 0) {
            this.commit('auth/setConnectedAs', false)
        }

        NlStorage.setItem('tokens', JSON.stringify(state.tokens))
    },
    setUrl(state, data) {
        state.url = data
    },

    setUserInfo(state, data) {
        if (ValidationHelper.isEmpty(state.locale)) {
            state.locale = data.locale
        }

        state.credit = data.credit
        state.security.hideResults = data.hideResults
        state.security.seeKeyword = data.seeKeyword
        state.security.isAdmin = data.isAdmin
        state.security.isProspect = data.isProspect
        state.security.displaySiteKpi = data.displaySiteKpi
        state.security.displaySearchResults = data.displaySearchResults
        state.security.displayUrl = data.displayUrl
    },

    setAttestation(state, data) {
        const user = state.user
        user.attestations = data.attestations

        state.user = user
    },

    setBenefits(state, value) {
        state.user.gains = value
        NlStorage.setItem(
            'user',
            JSON.stringify({
                ...JSON.parse(NlStorage.getItem('user')),
                gains: value
            })
        )
    },

    setCredits(state, value) {
        state.user.credit_amount = value
        NlStorage.setItem(
            'user',
            JSON.stringify({
                ...JSON.parse(NlStorage.getItem('user')),
                credit_amount: value
            })
        )
    },

    subCredits(state, value) {
        state.user.credit_amount = state.user.credit_amount - Number.parseFloat(value)
        NlStorage.setItem(
            'user',
            JSON.stringify({
                ...JSON.parse(NlStorage.getItem('user')),
                credit_amount: state.user.credit_amount
            })
        )
    },

    addCredits(state, value) {
        state.user.credit_amount = state.user.credit_amount + Number.parseFloat(value)
        NlStorage.setItem(
            'user',
            JSON.stringify({
                ...JSON.parse(NlStorage.getItem('user')),
                credit_amount: state.user.credit_amount
            })
        )
    }
}
