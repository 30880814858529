<template>
    <div>
        <b-button
            :icon-left="buttonIconLeft"
            :icon-rigth="buttonIconRight"
            :label="String(keywordsCount || keywords.length)"
            :outlined="buttonOutlined"
            :size="buttonSize"
            :type="buttonType"
            @click="open = true"
        />
        <b-modal v-model="open">
            <template>
                <header class="modal-card-head has-background-warning">
                    <p class="modal-card-title m-0">
                        {{ $t('global.keywords') }}
                    </p>
                </header>
                <section class="modal-card-body">
                    <NlTable
                        :async="!keywords.length"
                        :columns.sync="columns"
                        :data="keywords"
                        :get-data="!keywords.length ? undefined : loadAsyncData"
                        :searchable="false"
                        :sortable="false"
                        default-sort="position"
                        default-sort-direction="asc"
                    >
                        <template #position="{ props: { row } }">
                            Top {{ Math.ceil(row.position / 10) * 10 }}
                        </template>
                        <template #date_serp="{ props: { row } }">
                            {{ moment(row.date_serp).format('DD/MM/YYYY') }}
                        </template>
                    </NlTable>
                </section>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable.vue'
    import { mapActions } from 'vuex'

    export default {
        name: 'KeywordsModal',
        components: { NlTable },
        props: {
            urlId: {
                type: Number,
                required: false
            },
            keywordsCount: {
                type: Number,
                required: false
            },
            keywords: {
                type: Array,
                required: function (props) {
                    return !props.urlId || !props.keywordsCount
                }
            },
            buttonSize: {
                type: String,
                default: null
            },
            buttonIconLeft: {
                type: String,
                default: 'eye'
            },
            buttonIconRight: {
                type: String,
                default: null
            },
            buttonType: {
                type: String,
                default: 'is-light'
            },
            buttonOutlined: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                open: false,
                columns: [
                    {
                        centered: false,
                        field: 'keyword',
                        label: 'Mot-clé'
                    },
                    {
                        field: 'position',
                        label: 'Position',
                        sortable: true,
                        custom: true
                    },
                    {
                        field: 'volume',
                        label: 'Volume',
                        sortable: true,
                    },
                    {
                        custom: true,
                        field: 'date_serp',
                        label: 'Date de serp',
                        sortable: true,
                    }
                ]
            }
        },
        methods: {
            ...mapActions('urls', ['getKeywordsByUrl']),

            loadAsyncData(params) {
                return this.getKeywordsByUrl({
                    url_id: this.urlId,
                    params
                })
            }
        }
    }
</script>
