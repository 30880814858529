<template>
    <section class="box position-relative">
        <b-loading v-model="isLoading" :is-full-page="false" />

        <b-steps v-model="activeStep" animated rounded :has-navigation="false" mobile-mode="compact" type="is-warning">
            <b-step-item step="1" label="Récupération">
                <div class="my-3">
                    <h2 class="h3 text-warning mb-5 text-center">Récupération des mots clés</h2>

                    <section class="mx-auto d-flex flex-column justify-content-center">
                        <div class="form-group col-md-6 mx-auto">
                            <label class="label">Ajouter des mots clés en masse (optionnel)</label>
                            <textarea v-model="rawKeywords" class="form-control" cols="30" rows="5" />
                            <small class="text-muted">
                                Séparer les mots clés d'un saut de ligne ou d'une virgule
                            </small>
                        </div>

                        <div class="col-md-2 mx-auto">
                            <button class="button is-warning" @click="fetchSuggestions">
                                Récupérer toutes les suggestions
                            </button>
                        </div>
                    </section>
                </div>
            </b-step-item>

            <b-step-item step="2" label="Préparation">
                <div class="my-3">
                    <h2 class="h3 text-warning mb-5 text-center">Préparation de précommandes</h2>

                    <section v-if="suggestions.length || true == false">
                        <div class="d-md-flex">
                            <div class="w-md-30">
                                <b-table :data="suggestions" checkable :checked-rows.sync="checkedRows">
                                    <b-table-column field="keyword" label="Mot clé">
                                        <div slot="default" slot-scope="props">
                                            <span>{{ props.row.keyword }}</span>
                                        </div>
                                    </b-table-column>

                                    <b-table-column field="posts_count" label="Nb articles">
                                        <div slot="default" slot-scope="props">
                                            <input
                                                v-model="props.row.posts_count"
                                                type="number"
                                                class="form-control form-control-sm"
                                                min="0"
                                                max="1000"
                                            />
                                        </div>
                                    </b-table-column>
                                </b-table>
                            </div>

                            <div class="mx-md-5 w-md-30">
                                <div class="form-inline align-items-baseline">
                                    <label class="label mr-2">Mettre le nombre d'articles à</label>
                                    <input
                                        v-model="preorders.default_posts_count"
                                        type="number"
                                        class="form-control form-control-sm w-25"
                                        @change="setPostsCountToKeywords"
                                    />
                                </div>
                                <div class="form-group">
                                    <label class="label">Brief par défaut</label>
                                    <textarea
                                        v-model="preorders.brief"
                                        class="form-control"
                                        cols="30"
                                        rows="10"
                                        :placeholder="config"
                                    />
                                    <small class="text-muted">
                                        En indiquant <strong>###</strong> dans le brief, il sera remplacé par le mot clé
                                        correspondant.
                                    </small>
                                </div>
                            </div>

                            <div class="">
                                <div class="form-group">
                                    <label class="label">Nombre de mots par défaut</label>
                                    <input
                                        v-model="preorders.words_count"
                                        type="number"
                                        class="form-control"
                                        placeholder="Exemple: 300"
                                    />
                                </div>

                                <div class="form-group">
                                    <label>Langue par défaut</label>
                                    <b-field>
                                        <b-radio-button v-model="preorders.lang" native-value="fr" type="is-warning">
                                            <flag iso="fr" class="mr-2 rounded" />
                                            <span>Français</span>
                                        </b-radio-button>
                                        <b-radio-button v-model="preorders.lang" native-value="en" type="is-warning">
                                            <flag iso="us" class="mr-2 rounded" />
                                            <span>Anglais</span>
                                        </b-radio-button>
                                    </b-field>
                                </div>
                            </div>
                        </div>

                        <div class="">
                            <hr class="my-4" />

                            <button
                                class="button is-warning"
                                :disabled="!checkedRows.length || !preorders.brief || !preorders.words_count"
                                @click="savePreOrders"
                            >
                                Enregistrer les précommandes
                            </button>
                        </div>
                    </section>

                    <section v-else class="">
                        <p class="text-center">Aucune suggestion disponible...</p>
                    </section>
                </div>
            </b-step-item>

            <b-step-item step="3" label="Terminé">
                <h2 class="h3 text-warning mb-5 text-center">Précommandes enregistrées</h2>

                <div class="text-center h1 mb-4">
                    <i class="far fa-check-circle" />
                </div>

                <p class="text-center">
                    Les précommandes ont bien été enregistrées ! Direction l'onglet
                    <a href="#" class="font-weight-bold" @click.prevent="$emit('goToFollowPreOrdersTab')"
                        >Suivi de Précommandes</a
                    >
                    afin de terminer la configuration.
                </p>
            </b-step-item>
        </b-steps>
    </section>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'CampaignPreOrdersTab',
        data() {
            return {
                activeStep: 0,
                isLoading: false,
                rawKeywords: '',

                suggestions: [],
                fetchedSuggestions: [],
                checkedRows: [],

                preorders: {
                    lang: 'fr',
                    words_count: 500,
                    brief: 'Utiliser le titre et intégrer le mots clé ### dans le contenu',
                    default_posts_count: 1
                },
                config: ''
            }
        },

        created() {
            this.getConfigByName({ name: 'brief_preorders' })
                .then(config => (this.config = config))
                .catch(e => console.error(e))
        },

        methods: {
            ...mapActions('config', ['getConfigByName']),
            fetchSuggestions() {
                this.isLoading = true

                var keywordsArray = [
                    ...new Set(
                        this.rawKeywords
                            .trim()
                            .replace(/\n/g, ',')
                            .split(',')
                            .map(keyword => keyword.trim())
                    )
                ]

                keywordsArray.forEach(keyword => {
                    if (!keyword) {
                        return
                    }

                    this.suggestions.push({
                        keyword: keyword,
                        posts_count: 1
                    })
                })

                if (!this.fetchedSuggestions.length) {
                    this.axios
                        .get(`/campaigns/url/${this.$route.query.id}/suggestions`)
                        .then(response => {
                            let responseKeywords = response.data.suggestions

                            responseKeywords.forEach(keyword => {
                                this.fetchedSuggestions.push(keyword)
                            })

                            this.suggestions = [...this.suggestions, ...this.fetchedSuggestions]
                        })
                        .catch(error => console.error(error))
                        .finally(() => {
                            this.nextStep()
                        })
                } else {
                    this.nextStep()
                }
            },

            savePreOrders() {
                this.isLoading = true

                this.axios
                    .post(`/campaigns/${this.$route.query.id}/preorders`, {
                        words_count: this.preorders.words_count,
                        brief: this.preorders.brief,
                        lang: this.preorders.lang,
                        keywords: this.checkedRows
                    })
                    .then(response => {
                        console.log(response)
                        this.activeStep++
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        this.isLoading = false
                    })
            },

            setPostsCountToKeywords() {
                this.suggestions = this.suggestions.map(suggestion => {
                    suggestion.posts_count = this.preorders.default_posts_count

                    return suggestion
                })
            },

            nextStep() {
                // remove duplicate suggestion.keyword value
                this.suggestions = this.suggestions.filter(
                    (suggestion, index, suggestions) =>
                        suggestions.findIndex(s => s.keyword === suggestion.keyword) === index
                )
                this.isLoading = false
                this.activeStep++
            }
        }
    }
</script>

<style scoped>
    @media screen and (min-width: 768px) {
        .w-md-30 {
            width: 30%;
        }
    }
</style>
