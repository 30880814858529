export default {
    setLoading(state, data) {
        state.isLoading = data
        state.loaded = !data
    },
    setList(state, data) {
        state.list = data.consultants.data
        state.total = data.consultants.meta.total
        state.isLoading = false
        state.loaded = true
    },
    setAgencies(state, data) {
        state.agencies = data
    },
    setAgency(state, data) {
        state.agency = data
    },
    setManagers(state, data) {
        state.managers = data
    },
    setManager(state, data) {
        state.manager = data
    },
    setClients(state, data) {
        state.clients = data
    },
    setMonth(state, data) {
        state.month = data
    },
    setConsultants(state, data) {
        state.consultants = data
    },
    setConsultantsConfig(state, data) {
        state.consultantsConfig = data
    },
    setConsultant(state, data) {
        state.consultant = data
    },
    setAgencyClientContents(state, data) {
        state.agencyClientContents = data
    },
    setSelectedManager(state, data) {
        state.selectedManager = data
    },
    setNewEmployee(state, data) {
        state.newEmployee = data
    },
    setNewClient(state, data) {
        state.newClient = data
    },
    setNewUser(state, data) {
        state.newUser = data
    },
    setTarget(state, data) {
        state.target = data
    },
    setCredits(state, data) {
        state.credits = data
    },
    setCreditsAmount(state, data) {
        state.credits.amount = data
    },
    setAction(state, data) {
        state.action = data
    },
    setAgencyClient(state, data) {
        state.agencyClient = data
    },
    setScope(state, data) {
        state.scope = data
    },
    incrementManager(state) {
        state.agency.managers_count++
        state.agency.consultants_count--
    },
    decrementManager(state) {
        state.agency.managers_count--
        state.agency.consultants_count++
    }
}
