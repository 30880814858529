<template>
    <div v-if="messages && messages.length > 0" class="alert" :class="alertClass">
        <ul>
            <li v-for="(message, index) in messages" :key="'msg-' + index">
                {{ message }}
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        data: () => ({
            messages: null,
            type: 'success'
        }),
        computed: {
            alertClass() {
                return ['alert-' + this.type]
            }
        },
        methods: {
            showValidationError(validationError) {
                this.messages = []
                this.messages.push(validationError.message)
                Object.keys(validationError.errors).forEach(element => {
                    let str = element + ' : '
                    str += validationError.errors[element]
                    this.messages.push(str)
                })
                this.type = 'danger'
            },
            displayMsg(type, msg) {
                this.type = type
                this.messages = msg
            },
            clean() {
                this.messages = null
            }
        }
    }
</script>

<style></style>
