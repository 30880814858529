<template>
    <div class="box">
        <div class="row">
            <div v-if="loading" class="col" style="height: 200px">
                <b-loading v-model="loading" :is-full-page="false" />
            </div>
            <div v-else class="col">
                <div v-if="!readonly" class="d-flex justify-content-end">
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        :disabled="isLoading || allKeywordCreateOnMc || allKeywordCreateOnMcNotWorkedOn"
                        @click="syncWithMonitorank()"
                    >
                        <i class="fas fa-upload" />
                        Creation MR
                    </button>
                    <button
                        type="button"
                        class="ml-3 btn btn-outline-primary"
                        :disabled="isLoading"
                        @click="getPositionFromMonitorank()"
                    >
                        <i class="fas fa-download" />
                        Relever MR
                    </button>
                    <button
                        class="ml-3 btn btn-outline-primary"
                        :disabled="urlBeingEdited !== null || isLoading"
                        @click="createUrl()"
                    >
                        <i class="fas fa-plus" />
                    </button>
                </div>

                <b-tabs v-model="activeTab" @input="changeTab">
                    <b-tab-item v-for="tab in tabs" :key="tab.key" :label="tab.label" :icon="tab.icon">
                        <url-table
                            :keywords="keywords"
                            :is-loading="isLoading"
                            :readonly="readonly"
                            :total="keywords.total"
                            :per_page="keywords.per_page"
                            :page="keywords.page"
                            @pageChange="onPageChange"
                            @changeStatus="changeStatus"
                            @changeAvailability="changeAvailability"
                            @updateKeyword="updateKeyword"
                            @deleteKeyword="deleteKeyword"
                            @openPositionModal="openPositionModal"
                        />
                    </b-tab-item>
                </b-tabs>
            </div>
        </div>

        <nl-modal title="Evolution des positions" size="xl" :open-modal="positionModal" @hidden="positionModal = false">
            <RankCharts :infos="positionInfo" />
        </nl-modal>

        <nl-modal title="Ajouter" :open-modal="newUrlModal" size="xl" @hidden="closeNewUrlModal">
            <NewCampaignUrl ref="newCampaignUrlModal" :url="url" @closeModal="closeNewUrlModal" />
        </nl-modal>
    </div>
</template>

<script>
    import UrlForm from './UrlForm'
    import NlClipboardButton from '../UI/NlClipboardButton'
    import NlModal from '../UI/NlModal'
    import NewCampaignUrl from './NewCampaignUrl'
    import UrlTable from './UrlTable'

    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'UrlsList',
        components: { NlClipboardButton, UrlForm, NlModal, NewCampaignUrl, UrlTable },
        props: {
            readonly: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                campaign_id: this.$route.query.id,
                keywords: {},
                loading: true,
                isLoading: true,
                urlBeingEdited: null,
                showHidden: false,
                loadingModal: false,
                backlinksModalOpen: false,
                backlinks: [],
                lastUrlOpen: null,
                newUrlModal: false,
                urlToPush: '',
                positionInfo: {},
                positionModal: false,
                checkedRows: [],
                mainSwitch: true,

                tabs: [
                    {
                        key: 0,
                        label: 'À travailler',
                        icon: 'play'
                    },
                    {
                        key: 1,
                        label: 'En pause',
                        icon: 'pause'
                    }
                ],
                counts: {
                    workedOn: 0,
                    notWorkedOn: 0
                },
                activeTab: 0,
                allKeywordCreateOnMc: false,
                allKeywordCreateOnMcNotWorkedOn: false
            }
        },
        created() {
            this.getKeywordsWorkedOn()
        },
        computed: {
            ...mapState('campaigns', {
                keywordsWorkedOn: function (state) {
                    return state.keywordsWorkedOn
                },
                keywordsNotWorkedOn: function (state) {
                    return state.keywordsNotWorkedOn
                },
                url: state => state.campaign.information.url
            })
        },
        methods: {
            ...mapActions('campaigns', [
                'getKeywordsWorkedOnByCampaign',
                'getKeywordsNotWorkedOnByCampaign',
                'bulkUpdateCampaignKeywords',
                'createMonitorankEntities',
                'bulkDeleteCampaignKeywords',
                'getMonitorankEntities',
                'setPartnerAvailability'
            ]),
            getKeywordsWorkedOn() {
                this.getKeywordsWorkedOnByCampaign({
                    campaignId: this.campaign_id,
                    page: this.page
                }).then(response => {
                    this.loading = false
                    this.isLoading = false

                    this.counts.workedOn = response.counts.workedOn
                    this.counts.notWorkedOn = response.counts.notWorkedOn

                    this.keywords = this.keywordsWorkedOn
                    this.allKeywordCreateOnMc = this.checkKeywordWorkedOnMc()
                    this.formatTabs()
                })
            },
            getKeywordsNotWorkedOn() {
                this.getKeywordsNotWorkedOnByCampaign({
                    campaignId: this.campaign_id,
                    page: this.page
                }).then(response => {
                    this.loading = false
                    this.isLoading = false

                    this.keywords = this.keywordsNotWorkedOn
                    this.allKeywordCreateOnMcNotWorkedOn = this.checkKeywordNotWorkedOnMc()
                })
            },
            refreshKeywords() {
                if (this.activeTab === 0) {
                    this.getKeywordsWorkedOn()
                } else {
                    this.getKeywordsNotWorkedOn()
                }
            },
            changeTab(tab) {
                this.activeTab = tab
                this.isLoading = true
                this.page = 1

                this.refreshKeywords()
            },
            onPageChange(page) {
                this.isLoading = true
                this.page = page

                this.refreshKeywords()
            },
            changeStatus(ids, status) {
                this.isLoading = true

                this.bulkUpdateCampaignKeywords({
                    ids: ids,
                    data: {
                        to_work_on: status
                    }
                }).then(response => {
                    this.refreshKeywords()
                })
            },
            changeAvailability(id, availability) {
                this.isLoading = true

                this.setPartnerAvailability({
                    id: id,
                    partner_availability: availability
                })
                    .then(() => {
                        this.refreshKeywords()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            updateKeyword() {
                this.isLoading = true
                this.refreshKeywords()
            },
            deleteKeyword(urls) {
                this.isLoading = true

                this.bulkDeleteCampaignKeywords({ urls: urls })
                    .then(() => {
                        this.refreshKeywords()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            checkKeywordWorkedOnMc() {
                return this.keywordsWorkedOn.data.reduce((global, url) => url.query_id !== null && global, true)
            },
            checkKeywordNotWorkedOnMc() {
                return this.keywordsNotWorkedOn.data.reduce((global, url) => url.query_id !== null && global, true)
            },
            formatTabs() {
                this.tabs[0].label = `À travailler (${this.counts.workedOn})`
                this.tabs[1].label = `En pause (${this.counts.notWorkedOn})`
            },
            syncWithMonitorank() {
                this.isLoading = true
                this.createMonitorankEntities({
                    campaignId: this.$route.query.id
                }).finally(() => (this.isLoading = false))
            },
            getPositionFromMonitorank() {
                this.isLoading = true
                this.getMonitorankEntities({ campaignId: this.$route.query.id }).finally(() => (this.isLoading = false))
            },
            openPositionModal(infos) {
                this.positionInfo = {
                    accompagnement_id: this.$route.query.id,
                    accompagnement_url: infos.url,
                    mot_cle: infos.mot_cle
                }
                this.positionModal = true
            },
            createUrl: function () {
                this.newUrlModal = true
            },
            closeNewUrlModal() {
                this.isLoading = true

                this.newUrlModal = false
                this.$refs.newCampaignUrlModal.clean()
                this.refreshKeywords()
            }
        }
    }
</script>
