<template>
    <div>
        <b-modal v-model="modalIsOpen" :title="$t('admin.sites.wordpress')" @close="modalIsOpen = false">
            <b-loading v-model="isLoading" :is-full-page="false"/>
            <template>
                <header class="modal-card-head has-background-warning">
                    <slot name="header">
                        <p class="modal-card-title m-0">
                            {{ $t('wordpress_modal.title') }}
                        </p>
                    </slot>
                </header>
                <section class="modal-card-body">
                    <div class="form-group">
                        <label class="label" for="url">{{ $t('partenaire-site.mainurl') }}</label>
                        <b-input v-model="formData.url" :disabled="true"/>
                    </div>

                    <div class="form-group w-100">
                        <b-field label="API Token">
                            <b-input
                                id="token"
                                v-model="formData.api_key"
                                placeholder="Token"
                                class="w-100"
                                type="password"
                                password-reveal
                            />
                            <p class="control">
                                <b-button
                                    type="is-warning"
                                    :label="$t('wordpress_modal.check_button')"
                                    @click.prevent="checkApiKey"
                                />
                            </p>
                        </b-field>
                        <b-notification v-if="errorApiKey" :closable="false" type="is-danger is-light" role="alert">
                            {{ errorApiKey }}
                        </b-notification>
                        <b-notification v-if="apiKeyIsValid" :closable="false" type="is-success is-light" role="alert">
                            <i class="far fa-check-circle mr-2"/>
                            {{ $t('wordpress_modal.success') }}
                        </b-notification>
                        <p :class="formData.get_infos ? 'text-success' : 'text-danger'">
                            <i v-if="formData.get_infos" class="far fa-check-circle mr-2"/><i
                            v-else
                            class="far fa-times-circle mr-2"
                        />{{ $t('wordpress_modal.getInfos') }}
                        </p>
                        <p :class="formData.get_authors ? 'text-success' : 'text-danger'">
                            <i v-if="formData.get_authors" class="far fa-check-circle mr-2"/><i
                            v-else
                            class="far fa-times-circle mr-2"
                        />{{ $t('wordpress_modal.getAuthors') }}
                        </p>
                        <p :class="formData.get_categories ? 'text-success' : 'text-danger'">
                            <i v-if="formData.get_categories" class="far fa-check-circle mr-2"/><i
                            v-else
                            class="far fa-times-circle mr-2"
                        />{{ $t('wordpress_modal.getCategories') }}
                        </p>
                        <p :class="formData.post_articles ? 'text-success' : 'text-danger'">
                            <i v-if="formData.post_articles" class="far fa-check-circle mr-2"/><i
                            v-else
                            class="far fa-times-circle mr-2"
                        />{{ $t('wordpress_modal.postArticles') }}
                        </p>
                    </div>
                </section>
                <footer class="modal-card-foot h-auto justify-content-end">
                    <slot name="footer">
                        <b-button label="Supprimer le plugin" type="is-danger" @click="confirm" :loading="deleting"/>
                        <b-button :label="$t('global.close')" type="is-primary" @click="modalIsOpen = false"/>
                    </slot>
                </footer>
            </template>
        </b-modal>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: 'NlWordpressModal',
    props: ['data'],
    data() {
        return {
            modalIsOpen: false,
            formData: null,
            errorApiKey: '',
            isLoading: false,
            apiKeyIsValid: false,
            deleting: false,
        }
    },
    watch: {
        formData(newValue) {
            this.formData = newValue
        }
    },
    created() {
        this.formData = this.data
    },
    methods: {
        ...mapActions('sites', ['deletePlugin']),
        confirm() {
            this.$buefy.dialog.confirm({
                message: 'Êtes-vous sûr de vouloir supprimer le plugin ?',
                onConfirm: () => this.confirmSuppress()
            })
        },
        confirmSuppress() {
            this.deleting = true;
            this.deletePlugin(this.formData.id).then(() => {
                this.$buefy.toast.open('Plugin supprimé')
                this.modalIsOpen = false
                this.$emit('pluginDeleted')
            }).catch((e) => {
                this.$store.dispatch('toastError', e)
            }).finally(() => {
                this.deleting = false;
            })

        },
        checkApiKey() {
            this.errorApiKey = ''
            this.apiKeyIsValid = false

            if (!this.formData.api_key.length || this.formData.api_key.length < 6) {
                this.errorApiKey = 'Invalid API key'
                return
            }

            this.isLoading = true

            this.axios
                .post(`/partner-sites/${this.formData.id}/wordpress/check-plugin`, {
                    site_type: this.formData.site_type,
                    api_key: this.formData.api_key
                })
                .then(response => {
                    this.apiKeyIsValid = true
                    this.formData.get_infos = response.data.result.get_infos
                    this.formData.get_authors = response.data.result.get_authors
                    this.formData.get_categories = response.data.result.get_categories
                    this.formData.post_articles = response.data.result.post_articles
                    this.formData.plugin_version = response.data.result.plugin_version
                    this.$emit('update', {
                        token: this.formData.api_key,
                        get_infos: this.formData.get_infos,
                        get_authors: this.formData.get_authors,
                        get_categories: this.formData.get_categories,
                        post_articles: this.formData.post_articles,
                        plugin_version: this.formData.plugin_version
                    })
                })
                .catch(error => {
                    this.formData.get_infos = false
                    this.formData.get_authors = false
                    this.formData.get_categories = false
                    this.formData.post_articles = false
                    this.errorApiKey = error.response.data.message
                })
                .finally(() => (this.isLoading = false))
        },
        openModal(site) {
            this.modalIsOpen = true
            this.formData = site
        }
    }
}
</script>
