<template>
    <div class="card">
        <div class="card-header">Emails</div>
        <div class="card-body">
            <b-loading v-model="isLoading" :is-full-page="false" :can-cancel="true" />
            <div class="text-center">
                <div v-if="mails && mails.launching && mails.launching.sent_at">
                    <span v-if="!mails.launching.mail">
                        <i class="fas fa-check mr-2 text-success" />
                        Mail de lancement envoyé le
                        {{ moment(mails.launching.sent_at).format('DD/MM/YYYY') }}
                    </span>
                    <a v-else class="small alert-link" role="button" @click.prevent="showMail(mails.launching)">
                        <i class="fas fa-eye mr-2" />
                        <i class="fas fa-check mr-2 text-success" />
                        Mail de lancement envoyé le
                        {{ moment(mails.launching.sent_at).format('DD/MM/YYYY') }}
                    </a>
                </div>
            </div>
        </div>

        <nl-modal
            :open-modal="mailModal.isOpen"
            :title="`Mail de rapport mensuel du ${moment(mailModal.data.date).format('DD/MM/YYYY')}`"
            @hidden="mailModal.isOpen = false"
        >
            <iframe class="w-100" style="min-height: 75vh" :srcdoc="mailModal.data.html" />
        </nl-modal>

        <Toaster ref="toaster" />
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        data: function () {
            return {
                emailType: null,
                modalIsOpen: false,
                checkedRows: [],
                mailModal: {
                    data: {
                        html: null,
                        date: null
                    },
                    isOpen: false
                },

                isLoading: false
            }
        },
        methods: {
            showMail(mail) {
                this.mailModal = {
                    data: {
                        html: mail.mail.html,
                        date: mail.options.month_report_date
                    },
                    isOpen: true
                }
            }
        },
        computed: {
            ...mapState('campaigns', {
                campaign: state => state.campaign,
                mails: state => state.campaign.mails
            }),
            startingPeriod() {
                return this.moment(this.campaign.information.start_at).startOf('month').format('YYYY-MM-DD')
            }
        }
    }
</script>
