import ApiService from '../../../services/api.service'

export default {
    list(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get('/alerts', params)
                .then(({ data: { alerts } }) => {
                    resolve(alerts)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    get(context, alertId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/alerts/' + alertId)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    create(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.post('/alerts', model)
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    update(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.put('/alerts/' + model.id, model)
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    delete(context, alertId) {
        return new Promise((resolve, reject) => {
            ApiService.delete('/alerts/' + alertId)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    }
}
