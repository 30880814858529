<template>
    <fragment>
        <router-link v-if="user.role === 'agence'" class="nav_link" to="/agencies">
            <i class="fas fa-building nav_icon" />
            <span class="nav_name">{{ $t('layout.myagency') }}</span>
        </router-link>

        <router-link v-if="user.role === 'manager'" class="nav_link" to="/agency/managers">
            <i class="fas fa-building nav_icon" />
            <span class="nav_name">{{ $t('layout.myagency') }}</span>
        </router-link>

        <router-link v-if="user.role === 'consultant'" class="nav_link" to="/agency/consultants">
            <i class="fas fa-building nav_icon" />
            <span class="nav_name">{{ $t('layout.myagency') }}</span>
        </router-link>
    </fragment>
</template>
<script>
    import { mapState } from 'vuex'

    export default {
        name: 'AgencyMenu',
        computed: {
            ...mapState('auth', {
                user: state => state.user
            })
        }
    }
</script>
