<template>
    <div
        class="card p-3 nav-item"
        :class="[isSelected ? 'panel-selected' : 'panel']"
        @click.prevent="$emit('onSelect')"
    >
        <div class="card-body">
            <h2 class="text-secondary text-center mb-2">
                {{ label }}
            </h2>
        </div>

        <img :src="image" class="card-img-bottom" />
    </div>
</template>

<script>
    export default {
        components: {},
        props: ['image', 'label', 'isSelected'],
        data: function () {
            return {}
        },
        watch: {},
        created() {},
        methods: {}
    }
</script>

<style scoped>
    h2 {
        font-family: 'Lato', Roboto, Arial, sans-serif;
    }

    .panel {
        background-color: #2b383f;
    }

    .card {
        border-radius: 25px;
    }

    .card-deck .card:hover {
        background-color: #384e5a;
        cursor: pointer;
    }

    .card-header {
        background-color: #384e5a;
    }

    .card-img-bottom {
        max-height: 12em;
    }

    .panel-selected {
        background-color: #384e5a;
        border: solid 2px #f9bc13;
    }
</style>
