<template>
    <div>
        <ConfigMailHeader :number="configEmail[configKey].length" :label="$t('config.' + configKey)" :loader="loader" />

        <div class="columns is-flex-direction-column">
            <div v-show="showTrash" class="column is-full trash" @drop="dropHandler" @dragover="dragoverHandler">
                <i class="fas fa-trash-alt mr-4" /> Supprimer
            </div>

            <div
                v-for="(user, index) in configEmail[configKey]"
                :key="`${configKey}-${user.id}`"
                class="column is-full"
                draggable="true"
                @dragstart="startDrag(index, $event)"
                @dragend="dragEndHandler"
            >
                <ConfigMailUserCard :user="user" />
            </div>
        </div>

        <div class="column add is-one-fifth" @click.prevent="prepareAdd">
            <i class="fas fa-plus" />
        </div>
    </div>
</template>

<style scoped>
    .column {
        margin-left: 0.5em;
        margin-right: 0.5em;
        padding: 1em;
        background-color: #212529;
        border-radius: 25px;
    }

    .column .columns {
        margin-right: 0.5em;
    }

    .trash {
        border: dashed #f14668 1px;
        color: white;
        text-align: center;
    }

    .trash:hover {
        background-color: #f14668;
    }

    .add {
        border: dashed #48c78e 1px;
        color: #48c78e;
        text-align: center;
        cursor: pointer;
        margin: auto;
    }

    .add:hover {
        background-color: #48c78e;
        color: white;
    }
</style>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'ConfigMailRow',
        props: ['configKey'],
        data() {
            return {
                showTrash: false,
                loader: false
            }
        },
        computed: {
            ...mapState('config', {
                configEmail: state => state.configEmail,
                admins: state => state.admins
            })
        },
        methods: {
            ...mapActions('config', ['saveEmailConfig']),
            toBackend() {
                let object = {}
                object[this.configKey] = []

                this.configEmail[this.configKey].forEach(user => {
                    object[this.configKey].push(user.id)
                })

                object[this.configKey] = '[' + object[this.configKey].join() + ']'

                return object
            },
            prepareAdd() {
                let data = {
                    key: this.configKey,
                    callback: this.setLoadingFalse
                }
                this.loader = true
                this.$emit('modal:show', data)
            },
            setLoadingFalse() {
                this.loader = false
            },
            startDrag(index, event) {
                event.dataTransfer.setData('index', index)
                this.showTrash = true
            },
            dropHandler(event) {
                var index = event.dataTransfer.getData('index')
                this.configEmail[this.configKey].splice(index, 1)

                let data = this.toBackend()

                this.loader = true
                this.saveEmailConfig(data)
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loader = false
                    })
            },
            dragoverHandler(event) {
                event.preventDefault()
                event.dataTransfer.dropEffect = 'move'
            },
            dragEndHandler() {
                this.showTrash = false
            }
        }
    }
</script>

<style scoped></style>
