<template>
    <div class="row">
        <div class="col-3">
            <nl-alert ref="alert" />
            <form>
                <b-field label="Email">
                    <b-input v-model="email" type="email" />
                </b-field>
                <b-field label="Crédit antérieur">
                    <b-select v-model="previousCredit" expanded>
                        <option value="1">Rétroactif</option>
                        <option value="0">Non-Rétroactif</option>
                    </b-select>
                </b-field>
                <b-button v-if="valide" type="is-primary" @click="save"> Ajouter un filleul</b-button>
                <b-button v-else type="is-primary" @click="checkValidity"> Vérifier avant validation</b-button>
            </form>
        </div>

        <div class="col-2">
            <b-field label="Rémunération Parrainage">
                <b-select v-model="user.type_parrainage" expanded @input="changeSponsorshipRemuneration">
                    <option value="credit">Crédit</option>
                    <option value="gain">Gain</option>
                </b-select>
            </b-field>
        </div>

        <b-table
            class="col-7 pt-0"
            :data="sponsorships"
            :loading="loading"
            paginated
            :striped="true"
            :per-page="10"
            default-sort="created_at"
            default-sort-direction="desc"
        >
            <b-table-column searchable field="firstname" class="pt-0" sortable label="Filleul">
                <template v-slot="props">
                    <span>{{ props.row.firstname }} {{ props.row.lastname }}</span>
                </template>
                <div slot="searchable" slot-scope="props">
                    <b-input v-model="props.filters[props.column.field]" type="text" size="is-small" />
                </div>
            </b-table-column>
            <b-table-column field="depot" label="Dépot">
                <template v-slot="props">
                    <span>{{ props.row.montant > 0 ? 'Oui' : 'Non' }}</span>
                </template>
            </b-table-column>
            <b-table-column searchable field="montant" sortable label="Montant">
                <template v-slot="props">
                    <span>{{ props.row.montant }}€</span>
                </template>
                <div slot="searchable" slot-scope="props">
                    <b-input v-model="props.filters[props.column.field]" type="number" size="is-small" />
                </div>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    import NlAlert from '../../UI/NlAlert.vue'

    export default {
        name: 'PartnerDetailSponsorship',
        components: {
            NlAlert
        },
        props: {
            tabData: {}
        },
        data() {
            return {
                email: '',
                previousCredit: null,
                validity: false,
                user: {},
                sponsorships: [],
                valide: false,
                loading: true
            }
        },
        watch: {
            tabData: {
                handler(newValue) {
                    this.sponsorships = newValue.content
                    this.user = newValue.user
                    this.loading = false
                },
                deep: true
            }
        },
        methods: {
            checkValidity() {
                this.$refs.alert.clean()
                this.axios
                    .post(`/admin/users/${this.user.id}/check-filleul`, {
                        credit_past: this.previousCredit,
                        email: this.email,
                        id: this.user.id
                    })
                    .then(({ data }) => {
                        this.$refs.alert.displayMsg('success', data.message)
                        this.valide = true
                    })
                    .catch(({ response }) => {
                        if (response.status == 422) {
                            this.$refs.alert.showValidationError(response.data)
                        } else {
                            this.$refs.alert.displayMsg('danger', response)
                        }
                    })
            },
            save() {
                this.loading = true
                this.$refs.alert.clean()
                this.axios
                    .post(`/admin/users/${this.user.id}/filleul`, {
                        credit_past: this.previousCredit,
                        email: this.email,
                        id: this.user.id
                    })
                    .then(response => {
                        this.valide = false
                        this.email = ''
                        this.previousCredit = null
                        this.$emit('reloadData', this.tabData.index)
                    })
                    .catch(response => {
                        if (response.status == 422) {
                            this.$refs.alert.showValidationError(response.data)
                        } else {
                            this.$refs.alert.displayMsg('danger', response)
                        }
                    })
            },
            changeSponsorshipRemuneration() {
                this.axios.put(`/admin/users/${this.user.id}`, this.user)
            }
        }
    }
</script>
