<template>
    <div :class="{ inactive: !active, active: active }">
        <slot />
    </div>
</template>

<script>
    export default {
        data: () => ({
            active: false
        }),
        mounted() {
            this.$parent.addItem(this)
        },
        methods: {
            changeTab(index) {
                this.$parent.changeTab(index)
            }
        }
    }
</script>

<style scoped>
    .inactive {
        display: none;
    }

    .active {
        display: block;
    }
</style>
