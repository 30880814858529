<template>
    <b-autocomplete
        v-model="name"
        placeholder="Recherche par prénom ou nom"
        :keep-first="false"
        :data="store === true ? filteredDataObj : managers"
        :custom-formatter="option => `${option.firstname} ${option.lastname}`"
        :clearable="true"
        open-on-focus
        @input="searchManagers"
        @select="option => $emit('select', option)"
    >
        <template slot-scope="props">
            {{ props.option.firstname }} {{ props.option.lastname }}
            <br />
            <div class="font-italic">
                <small><i class="fa fa-at" /> {{ props.option.email }}</small>
            </div>
        </template>
    </b-autocomplete>
</template>
<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'SearchManager',
        props: {
            store: {
                required: false,
                type: Boolean,
                default: false,
                description: 'Determine if data should come from backend or vuex store'
            }
        },
        data() {
            return {
                name: '',
                managers: null,
                isFetching: false,
                timeout: null
            }
        },
        computed: {
            ...mapState('consultants', {
                config: 'searchConfig'
            }),
            filteredDataObj() {
                if (!this.$store.state.consultants.managers || !this.store) return

                return this.$store.state.consultants.managers.data.filter(option => {
                    return (
                        option.firstname.toLowerCase().indexOf(this.name.toLowerCase()) >= 0 ||
                        option.lastname.toLowerCase().indexOf(this.name.toLowerCase()) >= 0
                    )
                })
            }
        },
        methods: {
            ...mapActions('consultants', ['getManagers']),
            searchManagers() {
                if (this.store === true) return

                this.isFetching = true

                if (this.timeout) {
                    clearTimeout(this.timeout)
                }

                this.timeout = setTimeout(this.doSearchManagers, 500)
            },
            doSearchManagers() {
                this.config.filters['computed_name'] = this.name
                this.getManagers({ config: this.config, commit: false })
                    .then(data => {
                        this.managers = data.managers.data
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }
        }
    }
</script>
