<template>
    <section>
        <xlsx-workbook>
            <xlsx-sheet v-for="sheet in sheets" :key="sheet.name" :collection="sheet.data" :sheet-name="sheet.name" />
            <xlsx-download :filename="fileName">
                <b-button :label="$t('global.export')" :type="type" :size="size" :icon-left="icon" :class="className" />
            </xlsx-download>
        </xlsx-workbook>
    </section>
</template>

<script>
    import XlsxWorkbook from 'vue-xlsx/dist/components/XlsxWorkbook'
    import XlsxSheet from 'vue-xlsx/dist/components/XlsxSheet'
    import XlsxDownload from 'vue-xlsx/dist/components/XlsxDownload'

    export default {
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload
        },
        props: {
            sheets: {
                required: false,
                type: Array,
                default: () => []
            },
            type: {
                required: false,
                type: String,
                default: 'is-warning'
            },
            size: {
                required: false,
                type: String,
                default: null
            },
            icon: {
                required: false,
                type: String,
                default: 'file-excel'
            },
            className: {
                required: false,
                type: String,
                default: ''
            },
            fileName: {
                required: false,
                type: String,
                default: 'export'
            }
        }
    }
</script>
