<template>
    <li class="nav-item">
        <router-link class="nav-link" to="/">
            <i class="fas fa-key" /> {{ keywords_count }} {{ $t('global.keywords') }}
        </router-link>
    </li>
</template>

<script>
    export default {
        name: 'KeywordsMenu',

        data: function () {
            return {
                keywords_count: 0
            }
        },

        beforeMount() {
            this.keywords_count = this.getKeywordCount()
        },

        methods: {
            getKeywordCount() {
                this.axios.get('/admin/keywords/fr/count').then(response => {
                    this.keywords_count = response.data[this.$store.state.locale]
                })
            }
        }
    }
</script>

<style scoped></style>
