<template>
    <div>
        <b-button
            v-if="siteData.wp_data !== null"
            size="is-small"
            :type="computeWpStatus(siteData.wp_data)"
            @click.prevent="openWPModal"
        >
            {{ siteData.wp_data.plugin_version !== null ? siteData.wp_data.plugin_version : 'Erreur' }}
        </b-button>
        <b-button v-else size="is-small" icon-right="plus" @click.prevent="openWPModal" />

        <NlWordpressModal ref="wpModal" :data="form" @update="update" @pluginDeleted="$emit('pluginDeleted')"/>
    </div>
</template>

<script>
    import GroupSiteWPModal from '@/components/Admin/Sites/GroupSiteWPModal.vue'
    import NlWordpressModal from '@/components/UI/Wordpress/NlWordpressModal.vue'

    export default {
        name: 'NlWordpress',
        components: {
            NlWordpressModal,
            GroupSiteWPModal
        },
        props: {
            siteType: {
                type: String,
                required: true
            },
            siteData: {
                type: Object | null,
                required: true
            }
        },
        data() {
            return {
                wpModal: false,
                form: {},
                errorApiKey: '',
                isLoading: false,
                apiKeyIsValid: false,
                pluginsTests: {}
            }
        },
        watch: {
            formData(newValue) {
                this.formData = newValue
            }
        },
        created() {
            this.formData = {
                url: this.siteData.url,
                api_key: this.siteData.wp_data !== null ? this.siteData.wp_data.token : '',
                id: this.siteData.id,
                get_infos: this.siteData.wp_data !== null ? this.siteData.wp_data.get_infos : false,
                get_categories: this.siteData.wp_data !== null ? this.siteData.wp_data.get_categories : false,
                get_authors: this.siteData.wp_data !== null ? this.siteData.wp_data.get_authors : false,
                post_articles: this.siteData.wp_data !== null ? this.siteData.wp_data.post_articles : false
            }
        },
        methods: {
            openWPModal() {
                this.$refs.wpModal.openModal({
                    site_type: this.siteType,
                    url: this.siteData.url,
                    api_key: this.siteData.wp_data !== null ? this.siteData.wp_data.token : '',
                    id: this.siteData.id,
                    get_infos: this.siteData.wp_data !== null ? this.siteData.wp_data.get_infos : false,
                    get_categories: this.siteData.wp_data !== null ? this.siteData.wp_data.get_categories : false,
                    get_authors: this.siteData.wp_data !== null ? this.siteData.wp_data.get_authors : false,
                    post_articles: this.siteData.wp_data !== null ? this.siteData.wp_data.post_articles : false
                })
            },
            computeWpStatus(data) {
                if (data.is_active && data.get_infos && data.get_categories && data.get_authors && data.post_articles) {
                    return 'is-success'
                } else if (
                    data.is_active &&
                    (!data.get_infos || !data.get_categories || !data.get_authors || !data.post_articles)
                ) {
                    return 'is-warning'
                } else {
                    return 'is-danger'
                }
            },
            update(data) {
                this.siteData.wp_data.get_infos = data.get_infos
                this.siteData.wp_data.get_categories = data.get_categories
                this.siteData.wp_data.get_authors = data.get_authors
                this.siteData.wp_data.post_articles = data.post_articles
                this.siteData.wp_data.token = data.token
                this.siteData.wp_data.plugin_version = data.plugin_version
            }
        }
    }
</script>
