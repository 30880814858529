export default () => ({
    projects: [],
    projectsConfig: {
        include: '',
        sort: 'name',
        sortDirection: 'desc',
        page: 1,
        filters: {},
        per_page: 25
    }
})
