<template>
    <div>
        <b-button class="btn btn-warning btn-md" @click.prevent="showModal = true"> Lancer l'accompagnement</b-button>
        <form @submit.prevent="submit">
            <nl-modal
                title="Lancer l'accompagnement"
                :no-padding-body="true"
                :open-modal="showModal"
                @hide="showModal = false"
            >
                <b-loading v-model="isLoading" :is-full-page="false" />
                <ul class="list-group list-group-flush">
                    <config-status
                        name="Projet"
                        :value="campaign.project ? campaign.project.name : 'Projet non défini'"
                        :is-valid="campaign.project !== null"
                        required="required"
                    />

                    <config-status
                        name="Durée"
                        :value="campaign.information.duration + 'mois'"
                        :is-valid="campaign.information.duration !== null"
                    />

                    <config-status
                        name="Date de début"
                        :value="
                            campaign.information.start_at
                                ? $d(Date.parse(campaign.information.start_at), 'short')
                                : undefined
                        "
                        :is-valid="campaign.information.start_at !== null"
                        required="required"
                    />

                    <config-status
                        name="Date de fin"
                        :value="
                            campaign.information.end_at
                                ? $d(Date.parse(campaign.information.end_at), 'short')
                                : undefined
                        "
                        :is-valid="campaign.information.end_at !== null"
                        required="required"
                    />

                    <config-status
                        name="Consultant Offsite"
                        :value="
                            consultantOffSite
                                ? consultantOffSite.firstname + ' ' + consultantOffSite.lastname
                                : undefined
                        "
                        :is-valid="consultantOffSite !== null"
                    />

                    <config-status
                        name="Consultant Onsite"
                        :value="
                            consultantOnSite ? consultantOnSite.firstname + ' ' + consultantOnSite.lastname : undefined
                        "
                        :is-valid="consultantOnSite !== null"
                    />

                    <config-status
                        name="Consultant Audit"
                        :value="
                            consultantOnPage ? consultantOnPage.firstname + ' ' + consultantOnPage.lastname : undefined
                        "
                        :is-valid="consultantOnPage !== null"
                    />

                    <config-status
                        name="Lancement Monitorank"
                        :value="monitoRankStatus.value"
                        :is-valid="monitoRankStatus.isValid"
                    />
                </ul>

                <div class="p-4 bg-light">
                    <div class="form-group">
                        <label for="frm_start_campaign_status">statut </label>
                        <select id="frm_start_campaign_status" v-model="status" class="form-control">
                            <option :value="null" disabled>-- {{ $t('global.selectStatus') }} --</option>
                            <option v-for="(item, i) in campaign.meta.status" :key="i" :value="item">
                                {{ $t('accompagnement.status.' + item) }}
                            </option>
                        </select>
                    </div>
                </div>

                <template v-slot:footer>
                    <b-button
                        type="is-warning"
                        native-type="submit"
                        class="btn btn-secondary btn-lg btn-block"
                        :disabled="
                            !campaign.information.start_at ||
                            !campaign.information.end_at ||
                            !campaign.information.status === 'a_confirmer' ||
                            !status
                        "
                    >
                        Lancer l'accompagnement
                    </b-button>
                </template>
            </nl-modal>
        </form>
    </div>
</template>

<script>
    import NlModal from '../UI/NlModal'
    import ConfigStatus from './ConfigStatus'
    import AccompagnementApi from '../../services/accompagnement/Endpoint'

    import { mapActions, mapState } from 'vuex'

    export default {
        components: { ConfigStatus, NlModal },
        data() {
            return {
                status: null,
                showModal: false,
                monitoRankStatus: {
                    isValid: false,
                    value: 'loading...'
                },
                api: new AccompagnementApi(),
                isLoading: false
            }
        },
        computed: {
            ...mapState('campaigns', ['campaign']),
            consultantOnSite: {
                get() {
                    let consultant = this.campaign.consultants.onSite

                    if (consultant && consultant.id) return consultant

                    return null
                }
            },
            consultantOffSite: {
                get() {
                    let consultant = this.campaign.consultants.offSite

                    if (consultant && consultant.id) return consultant

                    return null
                }
            },
            consultantOnPage: {
                get() {
                    let consultant = this.campaign.consultants.onPage

                    if (consultant && consultant.id) return consultant

                    return null
                }
            }
        },
        watch: {
            showModal: function (isShown) {
                if (isShown) {
                    this.checkMonitoRank()
                }
            }
        },
        methods: {
            ...mapActions('campaigns', ['updateCampaign']),
            submit() {
                this.isLoading = true
                this.updateCampaign({
                    campaignId: this.campaign.id,
                    statut: this.status
                }).finally(() => {
                    this.isLoading = false
                    this.showModal = false
                    this.$emit('hide')
                })
            },
            checkMonitoRank: function () {
                this.api.getUrlAndKeywords(this.campaign.id).then(({ data }) => {
                    if (data.reduce((global, url) => url.query_id !== null && global, true)) {
                        this.monitoRankStatus = {
                            isValid: true,
                            value: 'OK'
                        }
                    } else {
                        this.monitoRankStatus = {
                            isValid: false,
                            value: "La création MonitoRank n'est pas complète"
                        }
                    }
                })
            }
        },
        beforeDestroy() {
            this.showModal = false
        }
    }
</script>

<style scoped></style>
