<template>
    <div class="d-flex justify-content-between">
        <div class="col-8 p-0 pr-3">
            <div class="d-flex mb-2">
                <b-field class="mr-2 mb-0 w-100" label="Nom">
                    <b-input v-model="user.lastname" type="text" :disabled="disabledInput" />
                </b-field>
                <b-field class="mr-2 mb-0 w-100" label="Prénom">
                    <b-input v-model="user.firstname" type="text" :disabled="disabledInput" />
                </b-field>
                <b-field class="mb-0 w-100" label="Email">
                    <b-input v-model="user.email" type="email" :disabled="disabledInput" />
                </b-field>
            </div>
            <div class="d-flex mb-2">
                <b-field class="mr-2 mb-0 w-100" label="Mot de passe">
                    <b-input v-model="user.pwd" type="password" :disabled="disabledInput" />
                </b-field>
                <b-field v-if="type == 'user'" class="mr-2 mb-0 w-100" label="Role">
                    <b-select v-model="user.role" placeholder="Selectionner un rôle" :disabled="disabledInput" expanded>
                        <option value="admin">admin</option>
                        <option value="prospect">prospect</option>
                        <option value="partenaire">partenaire</option>
                        <option value="manager" disabled>manager</option>
                        <option value="consultant" disabled>consultant</option>
                        <option value="agence">agence</option>
                        <option value="administratif">administratif</option>
                        <option value="redacteur">redacteur</option>
                    </b-select>
                </b-field>
                <b-field class="mr-2 mb-0 w-100" label="Téléphone">
                    <b-input v-model="user.telephone" type="text" :disabled="disabledInput" />
                </b-field>
                <b-field class="mb-0 w-100" label="Skype">
                    <b-input v-model="user.skype" type="text" :disabled="disabledInput" />
                </b-field>
            </div>
            <div class="d-flex mb-2">
                <b-field class="mr-2 mb-0 w-100" label="Adresse">
                    <b-input v-model="user.address" type="text" :disabled="disabledInput" />
                </b-field>
                <b-field class="mr-2 mb-0 w-100" label="Ville">
                    <b-input v-model="user.ville" type="text" :disabled="disabledInput" />
                </b-field>
                <b-field class="mr-2 mb-0 w-100" label="Code Postal">
                    <b-input v-model="user.cp" type="text" :disabled="disabledInput" />
                </b-field>
                <b-field class="w-100" label="Pays">
                    <b-input v-model="user.country" type="text" :disabled="disabledInput" />
                </b-field>
            </div>
            <div class="d-flex">
                <b-field class="mr-2 mb-0 w-100" label="Société">
                    <b-input v-model="user.company" type="text" :disabled="disabledInput" />
                </b-field>
                <b-field class="mr-2 mb-0 w-100" label="SIRET">
                    <b-input v-model="user.siret" type="text" :disabled="disabledInput" />
                </b-field>
                <b-field class="mr-2 mb-0 w-100" label="TVA">
                    <b-input v-model="user.tva_number" type="text" :disabled="disabledInput" />
                </b-field>
                <!--        <b-field class="w-100 m-auto d-flex justify-content-center align-items-center">
                  <b-tag rounded>{{ user.role[0].toUpperCase() + user.role.slice(1) }}</b-tag>
                </b-field>-->
            </div>
            <div class="d-flex mt-3">
                <div class="flex-grow-1">
                    <b-button
                        v-if="user.role != 'redacteur'"
                        type="is-primary"
                        :disabled="loading"
                        @click="doCreateRedactor"
                    >
                        <i v-if="loading" class="fas fa-circle-notch fa-spin mr-1" />
                        Créer un rédacteur
                    </b-button>
                    <AffectUserToAgencyButton
                        v-if="!user.agency && user.role !== 'agence'"
                        v-model="user"
                        @reload="$emit('reloadData')"
                    />
                </div>
                <div class="d-flex">
                    <div class="pl-0">
                        <b-field class="mr-2 mb-0">
                            <b-button v-if="disabledInput" type="is-primary" @click="disabledInput = !disabledInput">
                                Modifier le profil
                            </b-button>
                            <b-button v-else type="is-warning" @click="editUser"> Sauvegarder le profil</b-button>
                        </b-field>
                    </div>
                    <div class="px-2">
                        <b-field class="mb-0 d-flex align-items-end">
                            <b-button class="" type="is-danger" @click="deleteUser"> Supprimer le profil</b-button>
                        </b-field>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="col-2 p-0 px-3 d-flex flex-column justify-content-between"
            style="border-left: 2px solid #dbdbdb; border-right: 2px solid #dbdbdb"
        >
            <div class="w-100">
                <b-field class="mb-0">
                    <b-switch
                        v-model="user.api_access"
                        true-value="1"
                        false-value="0"
                        type="is-success"
                        @input="editUser"
                    >
                        Accès API
                    </b-switch>
                </b-field>
                <b-field class="mb-0">
                    <b-switch
                        v-model="user.partner_status"
                        true-value="validate"
                        false-value="invalidate"
                        type="is-success"
                        @input="accountingValidationUser"
                    >
                        Validé Compta
                    </b-switch>
                </b-field>
                <b-field class="mb-0">
                    <b-switch v-model="user.actif" true-value="1" false-value="0" type="is-success" @input="activeUser">
                        Compte Actif
                    </b-switch>
                </b-field>
            </div>
            <div class="w-100">
                <SmileySelection :selected="user.emotion" @clickSmile="setEmotion" />
            </div>
            <div class="w-100">
                <div class="mb-2">
                    <LoginAs :user-id="user.id" is-btn class="w-100" expanded type="is-warning">
                        Se connecter avec Urls
                    </LoginAs>
                </div>
                <div>
                    <LoginAs :user-id="user.id" is-btn :show-url="false" class="w-100" expanded type="is-primary">
                        Se connecter sans Urls
                    </LoginAs>
                </div>
            </div>
        </div>

        <div class="col-2 p-2 d-flex flex-wrap">
            <b-tag type="is-primary mx-2" rounded>
                Date d'inscription: {{ moment(user.date_inscription).format('L') }}
            </b-tag>
            <b-tag type="is-primary mx-2" rounded>
                Gains réalisés:
                {{ counts.totalEarn ? parseInt(counts.totalEarn * 100) / 100 : 0 }}€
            </b-tag>
            <b-tag type="is-primary mx-2" rounded>
                Pourcentage de Refus:
                {{ Number.parseFloat(counts.refuseContents).toFixed(2) }}%
            </b-tag>
            <b-tag
                type="mx-2"
                :class="{
                    'is-danger': moment().isSameOrAfter(counts.expiration),
                    'is-primary': moment().isBefore(counts.expiration),
                    'is-warning': moment().isBefore(counts.expiration)
                }"
                rounded
            >
                Expiration:
                {{ counts.expiration == null ? 'Aucun certificat' : moment(counts.expiration).format('DD/MM/YYYY') }}
            </b-tag>

            <div v-if="user.agency" class="bg-light w-100">
                <div class="px-4 py-2">
                    <span class="text-info">Agence :</span><br />
                    <router-link :to="`/admin/users/details?id=${user.agency.id}`">
                        {{ user.agency.firstname }} {{ user.agency.lastname }}
                    </router-link>
                    <br />
                    <div class="font-italic">
                        <small><i class="fa fa-at" /> {{ user.agency.email }}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SmileySelection from './SmileySelection'
    import AffectUserToAgencyButton from '@/components/Agency/Button/AffectUserToAgencyButton'
    import { mapActions } from 'vuex'

    export default {
        name: 'PartnerInfos',
        components: {
            SmileySelection,
            AffectUserToAgencyButton
        },
        props: {
            type: {
                default: 'partner'
            },
            user: {},
            counts: {}
        },
        data() {
            return {
                disabledInput: true,
                loading: false
            }
        },
        watch: {
            user: {
                handler(newValue) {
                    this.emotion = newValue.emotion
                },
                deep: true
            }
        },
        methods: {
            ...mapActions('users', ['createRedactor']),
            editUser() {
                this.axios
                    .put('/admin/partner-details/' + this.$route.query.id, this.user)
                    .then(() => {
                        this.disabledInput = true
                        this.$emit('reloadData')
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
            },
            deleteUser() {
                if (confirm('Êtes-vous sûr ?')) {
                    this.axios
                        .delete('/admin/users/' + this.$route.query.id)
                        .then(() => {
                            this.$router.push('/admin/partners')
                        })
                        .catch(e => {
                            console.log(e)
                            this.$store.dispatch('toastError', e)
                        })
                }
            },
            checkExpiration() {},
            activeUser() {
                this.axios
                    .patch('/admin/partner-details/active/' + this.$route.query.id, {
                        active: this.user.actif
                    })
                    .then(() => {})
                    .catch(() => {})
            },
            accountingValidationUser() {
                this.axios
                    .patch('/admin/partner-details/accounting-validation/' + this.$route.query.id, {
                        accountingValidation: this.user.partner_status
                    })
                    .then(() => {})
                    .catch(() => {})
            },
            setEmotion($data) {
                this.user.emotion = $data

                this.axios
                    .put('/admin/partner-details/' + this.$route.query.id, this.user)
                    .then(() => {})
                    .catch(() => {})
            },
            doCreateRedactor() {
                this.loading = true
                this.createRedactor(this.user.id)
                    .then(() => {
                        this.user.role = 'redacteur'
                        this.$store.dispatch('toast', {
                            type: 'success',
                            message: 'Le rédacteur a bien été créé'
                        })
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        }
    }
</script>
