<template>
    <b-modal v-model="value" :can-cancel="false" style="padding: 1rem">
        <template>
            <header class="modal-card-head">
                <slot name="header">
                    <p class="modal-card-title m-0">
                        {{ modalTitle }}
                    </p>
                    <button type="button" class="delete" @click="cancel" />
                </slot>
            </header>
            <section class="modal-card-body">
                <slot />
            </section>
            <footer class="modal-card-foot h-auto justify-content-end">
                <slot name="footer">
                    <b-button :label="$t('global.cancel')" type="is-danger" @click="cancel" />
                    <b-button :label="$t('global.save')" type="is-warning" @click="save" />
                </slot>
            </footer>
        </template>
    </b-modal>
</template>

<script>
    export default {
        name: 'Modal',
        props: {
            value: {
                type: Boolean,
                default: false
            },
            modalTitle: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: true
            }
        },
        methods: {
            cancel() {
                this.$emit('cancel')
            },
            save() {
                this.$emit('save')
            }
        }
    }
</script>

<style scoped></style>
