import ApiService from '@/services/api.service'

export default {
    getSites(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get('/user/sites?include=majestic_data', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    getSitesCount(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get('/user/sites/count', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    bulkDeleteSites(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/partner-sites/delete`, params.data)
                .then(({ data }) => resolve(data))
                .catch(e => reject(e))
        })
    },
    bulkDisableSites(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/partner-sites/disable`, params)
                .then(({ data }) => resolve(data))
                .catch(e => reject(e))
        })
    },
    bulkEnableSites(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/partner-sites/enable`, params)
                .then(({ data }) => resolve(data))
                .catch(e => reject(e))
        })
    },
    bulkSetRedaction(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/partner-sites/redaction`, params)
                .then(({ data }) => resolve(data))
                .catch(e => reject(e))
        })
    },
    bulkSetWordLimit(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/partner-sites/max-words`, params)
                .then(({ data }) => resolve(data))
                .catch(e => reject(e))
        })
    },
    bulkSetAutoAcceptOrders(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/partner-sites/accept-auto`, params)
                .then(({ data }) => resolve(data))
                .catch(e => reject(e))
        })
    },
    bulkSetAutoAcceptPreOrders(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/partner-sites/accept-auto-preorder`, params)
                .then(({ data }) => resolve(data))
                .catch(e => reject(e))
        })
    },
    scrapSite(context, id) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/partner-sites/${id}/scrap`)
                .then(({ data }) => resolve(data))
                .catch(e => reject(e))
        })
    },
    updateSite(context, { id, params }) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/partner-sites/${id}`, params)
                .then(({ data }) => resolve(data))
                .catch(e => reject(e))
        })
    }
}
