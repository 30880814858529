<template>
    <div>
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <div class="form-group">
                    <h3>
                        {{ $t('commande.mainkeyword') }} {{ index + 1 }}
                        <span class="pl-2">
                            <ChooseLanguage :locale="locale" @change-locale="changeLocale" />
                        </span>
                    </h3>
                    <input
                        v-model="keyword"
                        type="text"
                        :placeholder="$t('commande.keyword')"
                        maxlength="30"
                        class="keyword"
                        :class="['form-control', errorsList.name ? 'is-invalid' : '']"
                    />

                    <div class="invalid-feedback d-block">
                        {{ errorsList.name }}
                    </div>
                </div>

                <div class="form-group">
                    <label for="groups">{{ $t('commande.maincategory') }}</label>
                    <select id="groups" v-model="groupe_id" class="form-control" name="cat_keywords[]">
                        <option v-for="cat in groups" :key="cat.id" :value="cat.id">
                            <div v-if="cat.parent_id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ cat.name }}</div>

                            <div v-else>
                                {{ cat.name }}
                            </div>
                        </option>
                    </select>
                </div>

                <div class="form-group">
                    <label :for="id">{{ $t('commande.numberarticles') }}</label>
                    <input
                        :id="id"
                        v-model="nbArticle"
                        type="number"
                        placeholder="Ex: 5"
                        min="1"
                        :class="['form-control', errorsList.article ? 'is-invalid' : '']"
                    />

                    <div class="invalid-feedback d-block">
                        {{ errorsList.article }}
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div v-for="(secondaryKW, index) in secondaryKeywords" :key="index">
                    <div class="form-group">
                        <div v-if="index === 0">
                            <h3>
                                {{ $t('commande.secondarykeyword') }}
                                <span class="badge badge-primary">
                                    {{ secondaryKeywords.length }} / {{ maxSecondaryKeywords }}
                                </span>
                            </h3>

                            <input
                                id="keyword"
                                v-model="secondaryKW.name"
                                type="text"
                                :placeholder="$t('commande.secondarykeywordexample')"
                                :class="['form-control', secondaryKW.error ? 'is-invalid' : '']"
                                @input="checkLastSecondaryKeyword(secondaryKW, index)"
                            />
                        </div>
                        <div v-else>
                            <input
                                id="keyword"
                                v-model="secondaryKW.name"
                                type="text"
                                :placeholder="$t('commande.secondarykeywordempty')"
                                :class="['form-control', secondaryKW.error ? 'is-invalid' : '']"
                                @input="checkLastSecondaryKeyword(secondaryKW, index)"
                            />
                        </div>

                        <div class="invalid-feedback d-block">
                            {{ secondaryKW.error }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="form-group">
                    <label for="brief">{{ $t('commande.addnote') }}</label>
                    <textarea id="brief" v-model="brief" class="form-control" maxlength="400" rows="10" />
                    <p v-if="brief.length > 0" class="text-right text-small text-muted mt-1">
                        {{ brief.length }} / 400
                    </p>
                </div>
            </div>
            <div class="col-lg-1 align-self-end">
                <p v-if="!isSaved" class="alert alert-warning">
                    {{ $t('commande.notsaved') }}
                </p>
                <button v-if="!isSaved" class="btn btn-secondary btn-block" @click.prevent="save">
                    {{ $t('global.save') }}
                </button>
                <button class="btn btn-primary btn-block" @click.prevent="removeItem">
                    {{ $t('global.delete') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MainKeyword',
        components: {},
        props: ['keywordData', 'groups', 'error', 'index'],
        data: function () {
            return {
                id: null,
                keyword: '',
                groupe_id: 1,
                locale: this.$store.state.locale,
                brief: '',
                date_expiration: this.moment().add(4, 'month').format('YYYY-MM-DD'),
                secondaryKeywords: [],
                maxSecondaryKeywords: 5,
                status: 'A faire',
                isSaved: false,
                nbArticle: '',

                //UI & styling
                errorsList: []
            }
        },
        watch: {
            keywordData: function (value) {
                this.id = value.id

                if (value.error) this.errorsList = value.error
            }
        },
        created() {
            this.id = this.keywordData && this.keywordData.id > 0 ? this.keywordData.id : 0
            this.addSecondaryKeyword()
        },
        mounted() {},
        methods: {
            changeLocale: function (locale) {
                this.locale = locale
            },
            save: function () {
                this.errorsList = {}

                if (this.keyword.length <= 0) {
                    this.errorsList['name'] =
                        this.$t('commande.validation.front.name') + this.$t('commande.validation.front.empty')
                }

                if (this.nbArticle < 1) {
                    this.errorsList['article'] =
                        this.$t('commande.validation.front.articlenumber') + this.$t('commande.validation.front.low')
                }

                var keywordAlreadyExists = this.secondaryKeywords.filter(keyword => {
                    return keyword.hasOwnProperty('error')
                })

                if (keywordAlreadyExists.length > 0) {
                    this.errorsList['secondaryKeywords'] = this.$t('commande.validation.front.secondarykeyworderror')
                }

                if (Object.keys(this.errorsList).length === 0) {
                    this.$emit('save', this.$data)
                }
            },
            removeItem: function () {
                this.$emit('delete')
            },
            checkLastSecondaryKeyword: function (secondaryKW, index) {
                var hasError = false

                if (secondaryKW.hasOwnProperty('error')) {
                    delete secondaryKW.error
                }

                var keywordAlreadyExists = this.secondaryKeywords.filter((keyword, currentIndex) => {
                    if (index != currentIndex && keyword.name.length > 0 && secondaryKW.name.length > 0) {
                        return keyword.name.toLowerCase() == secondaryKW.name.toLowerCase()
                    }

                    return false
                })

                if (keywordAlreadyExists && keywordAlreadyExists.length > 0) {
                    hasError = true
                    secondaryKW.error = this.$t('commande.validation.front.alreadyinlist')
                }

                if (secondaryKW.name.toLowerCase() === this.keyword.toLowerCase()) {
                    secondaryKW.error = this.$t('commande.validation.front.issameasmain')
                }

                if (index == this.secondaryKeywords.length - 1 && !hasError) {
                    this.addSecondaryKeyword()
                }
            },
            addSecondaryKeyword: function () {
                if (this.secondaryKeywords.length < this.maxSecondaryKeywords) this.secondaryKeywords.push({ name: '' })
            },
            setSaved(isSaved) {
                this.isSaved = isSaved
            }
        }
    }
</script>
