<template>
    <div class="card">
        <div class="card-header">Facturation</div>
        <div class="card-body">
            <label for="frm_billing_type">Type de facturation</label>
            <select id="frm_billing_type" v-model="campaign.billing.type" class="form-control" @change="update">
                <option v-for="(type, i) in campaign.meta.billing.types" :key="`billing_type_${i}`" :value="type">
                    {{ type }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        props: {
            campaign: {
                type: Object,
                required: true
            }
        },
        methods: {
            ...mapActions('campaigns', ['updateCampaign']),
            update(e) {
                this.updateCampaign({
                    campaignId: this.campaign.id,
                    type_facturation: e.target.value
                })
            }
        }
    }
</script>
