<template>
    <li class="list-group-item">
        <span
            class="mr-1 d-inline-block"
            :class="{
                'text-success': isValid,
                'text-warning': !isValid && !required,
                'text-danger': !isValid && required
            }"
            style="width: 20px"
        >
            <i class="fas" :class="{ 'fa-check': isValid, 'fa-exclamation': !isValid }" />
        </span>
        {{ name }} :
        <strong
            :class="{
                'text-warning': !isValid && !required,
                'text-danger': !isValid && required
            }"
        >
            <span v-if="value">{{ value }}</span>
            <span v-else>non défini</span>
        </strong>
    </li>
</template>

<script>
    export default {
        props: {
            name: {
                required: true
            },
            value: {
                type: String,
                default: undefined
            },
            isValid: {
                required: true,
                type: Boolean,
                default: false
            },
            required: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped></style>
