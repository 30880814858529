import ApiService from '../../../services/api.service'

export default {
    index(context, { projectId, status, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/tracking-order/${projectId}${status ? `?status=${status}` : ''}`, params)
                .then(({ data }) => {
                    resolve(data.orders)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    counts(context, projectId) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/tracking-order/${projectId}/counts`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    move(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/tracking-order/move`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    }
}
