<template>
    <div class="b-autocomplete">
        <b-autocomplete
            v-model="selectorInput"
            placeholder="Site"
            :data="filteredSites"
            open-on-focus
            :loading="isLoading"
            :disabled="disabled || isLoading"
            @click.native="selectorInput = ''"
            @focus="selectorInput = ''"
            @select="selectSite"
            icon-right="angle-down"
            keep-first
            select-on-click-outside
            clear-on-select
            icon="sitemap"
            rounded
            type="is-warning"
        >
            <template #default="props">
                {{ props.option.ndd }}
            </template>
        </b-autocomplete>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isLoading: true
            }
        },
        methods: {
            ...mapActions('site_selector', ['getSites']),
            selectSite(site) {
                this.$emit('select', site?.id ?? this.site.id)
                if (site && site.id) {
                    this.site = site
                    this.selectorInput = site.ndd
                } else {
                    this.selectorInput = this.site.ndd
                }
            }
        },
        computed: {
            ...mapState('site_selector', ['sites']),
            filteredSites() {
                return [
                    {
                        id: -1,
                        ndd: 'Tous'
                    },
                    ...this.sites
                        .filter(site => {
                            return site?.ndd?.toString()?.toLowerCase()?.includes(this.selectorInput?.toLowerCase())
                        })
                        .sort((a, b) => a.ndd.localeCompare(b.ndd))
                ]
            },
            selectorInput: {
                get() {
                    return this.$store.state.site_selector.selectorInput
                },
                set(value) {
                    this.$store.commit('site_selector/setSelectorInput', value ?? '')
                }
            },
            site: {
                get() {
                    return this.$store.state.site_selector.site
                },
                set(value) {
                    this.$store.commit('site_selector/setSite', value ?? '')
                }
            }
        },
        created() {
            this.getSites().finally(() => (this.isLoading = false))
            if (this.site === null) {
                this.site = {
                    id: -1,
                    ndd: 'Tous'
                }
            }
        }
    }
</script>

<style scoped>
    ::v-deep .dropdown-menu,
    /deep/ .dropdown-content {
        padding: 0 !important;
        border: none;
    }

    ::v-deep .b-autocomplete ::v-deep .input {
        border-color: #f9bc14;
    }
</style>
