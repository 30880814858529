<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 v-if="deleteData.active" class="h4 p-0 mb-0">
                        {{ $t('global.delete') }}
                    </h4>
                    <h4 v-else class="h4 p-0 mb-0">
                        {{ $t('global.modify') }}
                    </h4>
                </header>
                <div class="card-content">
                    <div class="content">
                        <form>
                            <div v-if="deleteData.active" class="col-12">
                                <p class="mt-4 text-center">
                                    Êtes-vous sûr de vouloir supprimer ces accompagnements :
                                    <strong>#{{ this.listIds.join(', #') }}</strong
                                    >.
                                </p>
                            </div>

                            <div v-if="statusData.active" class="col-12">
                                <p class="mt-4 text-center">
                                    Choisissez un nouveau statut pour les accompagnements suivant :
                                    <strong>#{{ this.listIds.join(', #') }}</strong
                                    >.
                                </p>

                                <div class="form-group">
                                    <select v-model="statusData.status" class="form-control">
                                        <option v-for="(status, index) in statusList" :key="index" :value="status">
                                            {{ $t('accompagnement.status.' + status) }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div v-if="paymentData.active" class="col-12">
                                <p class="mt-4 text-center">
                                    Choisissez un type de paiement pour les accompagnements suivant :
                                    <strong>#{{ this.listIds.join(', #') }}</strong
                                    >.
                                </p>

                                <div class="form-group">
                                    <select v-model="paymentData.payment" class="form-control">
                                        <option
                                            v-for="(payment, index) in paymentTypes"
                                            :key="index"
                                            :value="payment.value"
                                        >
                                            {{ payment.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div v-if="auditData.active" class="col-12">
                                <p class="mt-4 text-center">
                                    Définir si l'audit a été fait pour les accompagnements suivant :
                                    <strong>#{{ this.listIds.join(', #') }}</strong
                                    >.
                                </p>

                                <div class="form-group d-flex">
                                    <span class="mx-auto">
                                        <b-switch
                                            v-model="auditData.audit"
                                            type="is-success"
                                            :true-value="1"
                                            :false-value="0"
                                        >
                                            {{ auditData.audit ? 'Fait' : 'Non Fait' }}
                                        </b-switch>
                                    </span>
                                </div>

                                <div class="d-flex justify-content-center">
                                    <b-field label="Veuillez choisir un consultant Audit">
                                        <b-select
                                            v-model="auditData.advisor"
                                            placeholder="Veuillez choisir un consultant Audit"
                                        >
                                            <option :value="null">Aucun</option>
                                            <option
                                                v-for="(advisor, index) in onPageAdvisors"
                                                :key="index"
                                                :value="advisor.id"
                                            >
                                                {{ `${advisor.firstname} ${advisor.lastname} (${advisor.email})` }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                            </div>

                            <div v-if="advisorData.active" class="col-12">
                                <p class="mt-4 text-center">
                                    Choisissez les consultants pour les accompagnements suivant :
                                    <strong>#{{ this.listIds.join(', #') }}</strong
                                    >.
                                </p>

                                <div class="w-75 mx-auto text-center mb-4">
                                    <b-field>
                                        <b-select v-model="advisorData.audit" placeholder="Consultant Audit">
                                            <option :value="null">Aucun</option>
                                            <option
                                                v-for="(advisor, index) in onPageAdvisors"
                                                :key="index"
                                                :value="advisor.id"
                                            >
                                                {{ `${advisor.firstname} ${advisor.lastname} (${advisor.email})` }}
                                            </option>
                                        </b-select>
                                    </b-field>

                                    <b-field>
                                        <b-select v-model="advisorData.onsite" placeholder="Consultant OnSite">
                                            <option :value="null">Aucun</option>
                                            <option
                                                v-for="(advisor, index) in onSiteAdvisors"
                                                :key="index"
                                                :value="advisor.id"
                                            >
                                                {{ `${advisor.firstname} ${advisor.lastname} (${advisor.email})` }}
                                            </option>
                                        </b-select>
                                    </b-field>

                                    <b-field>
                                        <b-select v-model="advisorData.offsite" placeholder="Consultant OffSite">
                                            <option :value="null">Aucun</option>
                                            <option
                                                v-for="(advisor, index) in offSiteAdvisors"
                                                :key="index"
                                                :value="advisor.id"
                                            >
                                                {{ `${advisor.firstname} ${advisor.lastname} (${advisor.email})` }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>

                                <p class="small p-1 rounded bg-light">
                                    <i class="fas fa-info-circle mr-1" /> Laissez vide, ou choissiez
                                    <strong>Aucun</strong> pour retirer un consultant.
                                </p>
                            </div>

                            <div class="buttons justify-content-end">
                                <button class="button is-light" @click.prevent="closeAndResetModal">
                                    {{ $t('global.cancel') }}
                                </button>
                                <button class="button is-warning" @click.prevent="validate">
                                    {{ deleteData.active ? $t('global.delete') : $t('global.validate') }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'AdminAccompagnementModal',
        props: ['checkedRows', 'paymentTypes', 'statusList', 'advisors'],
        data() {
            return {
                modalIsOpen: false,
                currentType: null,
                listIds: [],

                onPageAdvisors: [],
                onSiteAdvisors: [],
                offSiteAdvisors: [],

                statusData: {
                    active: false,
                    status: 'a_confirmer'
                },
                advisorData: {
                    active: false,
                    audit: null,
                    onsite: null,
                    offsite: null
                },
                deleteData: {
                    active: false
                },
                paymentData: {
                    active: false,
                    payment: 'facture'
                },
                auditData: {
                    active: false,
                    audit: 1,
                    advisor: this.advisors.onpage.length ? this.advisors.onpage[0].id : null
                }
            }
        },

        watch: {
            checkedRows() {
                this.listIds = this.checkedRows.map(acc => acc.id)
            }
        },

        created() {
            this.listIds = this.checkedRows.map(acc => acc.id)

            this.onPageAdvisors = this.advisors.onpage
            this.onSiteAdvisors = this.advisors.onsite
            this.offSiteAdvisors = this.advisors.offsite
        },

        methods: {
            validate() {
                switch (this.currentType) {
                    case 'delete':
                        this.axios
                            .delete(`/admin/accompagnements`, {
                                data: {
                                    ids: this.listIds
                                }
                            })
                            .then(() => {
                                this.$store.dispatch('toast', {
                                    title: 'Accompagnement(s) bien supprimé(s)',
                                    type: 'success'
                                })

                                this.$emit('refresh')
                            })
                            .catch(error => {
                                this.$store.dispatch('toast', {
                                    title: 'Une erreur est survenue lors de la mise à jour',
                                    type: 'error'
                                })
                            })
                        break
                    case 'status':
                        this.axios
                            .post(`/admin/accompagnements/status`, {
                                ids: this.listIds,
                                status: this.statusData.status,
                                _method: 'PATCH'
                            })
                            .then(() => {
                                this.$store.dispatch('toast', {
                                    title: 'Accompagnement(s) mis à jour',
                                    type: 'success'
                                })
                            })
                            .catch(error => {
                                this.$store.dispatch('toast', {
                                    title: 'Une erreur est survenue lors de la mise à jour',
                                    type: 'error'
                                })
                            })
                        break
                    case 'payment':
                        this.axios
                            .post(`/admin/accompagnements/payment`, {
                                ids: this.listIds,
                                payment: this.paymentData.payment,
                                _method: 'PATCH'
                            })
                            .then(() => {
                                this.$store.dispatch('toast', {
                                    title: 'Accompagnement(s) mis à jour',
                                    type: 'success'
                                })
                            })
                            .catch(error => {
                                this.$store.dispatch('toast', {
                                    title: 'Une erreur est survenue lors de la mise à jour',
                                    type: 'error'
                                })
                            })
                            .finally(() => (this.isLoading = false))
                        break
                    case 'audit':
                        this.$parent.$emit('refresh')
                        break
                    case 'advisor':
                        this.axios
                            .post(`/admin/accompagnements/advisors`, {
                                ids: this.listIds,
                                audit: this.advisorData.audit,
                                onsite: this.advisorData.onsite,
                                offsite: this.advisorData.offsite,
                                _method: 'PATCH'
                            })
                            .then(() => {
                                this.$store.dispatch('toast', {
                                    title: 'Accompagnement(s) mis à jour',
                                    type: 'success'
                                })

                                this.passOnAdvisorsChanges(params)
                            })
                            .catch(error => {
                                this.$store.dispatch('toast', {
                                    title: 'Une erreur est survenue lors de la mise à jour',
                                    type: 'error'
                                })
                            })
                            .finally(() => (this.isLoading = false))
                        break

                    default:
                        break
                }

                this.$emit('uncheckRows')
                this.closeAndResetModal()
            },

            openModal(type) {
                this.modalIsOpen = true
                this.currentType = type

                switch (type) {
                    case 'delete':
                        this.deleteData.active = true
                        break
                    case 'status':
                        this.statusData.active = true
                        break
                    case 'payment':
                        this.paymentData.active = true
                        break
                    case 'audit':
                        this.auditData.active = true
                        break
                    case 'advisor':
                        this.advisorData.active = true
                        break

                    default:
                        break
                }
            },

            closeAndResetModal() {
                this.modalIsOpen = false
                this.currentType = null

                this.deleteData = {
                    active: false
                }
                this.statusData = {
                    active: false,
                    status: 'a_confirmer'
                }
                this.paymentData = {
                    active: false,
                    payment: 'facture'
                }
                this.auditData = {
                    active: false,
                    audit: 1,
                    advisor: this.advisors.onpage.length ? this.advisors.onpage[0].id : null
                }
                this.advisorData = {
                    active: false,
                    audit: null,
                    onsite: null,
                    offsite: null
                }
            }
        }
    }
</script>
