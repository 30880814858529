import axios from '@/config/backend'

const ApiService = {
    baseURL: process.env.VUE_APP_LARAVEL_URL + '/api',

    get(resource, params = null, extra = {}) {
        let qs = require('qs')
        let p = {
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params, { skipNulls: true })
            }
        }

        let options = { ...p, ...extra }
        return axios.get(this.baseURL + `${resource}`, options)
    },

    post(resource, params, config = {}) {
        return axios.post(this.baseURL + `${resource}`, params, config)
    },

    put(resource, params) {
        return axios.put(this.baseURL + `${resource}`, params)
    },

    patch(resource, params) {
        return axios.patch(this.baseURL + `${resource}`, params)
    },

    delete(resource, params) {
        let qs = require('qs')
        let p = {
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params, { skipNulls: true })
            }
        }

        let options = { ...p }

        return axios.delete(this.baseURL + `${resource}`, options)
    }
}

export default ApiService
