<template>
    <div>
        <form class="mt-2 d-flex flex-column flex-md-row">
            <b-field>
                <b-select v-model="bulkActionType" :placeholder="$t('global.actions')">
                    <option value="addCredit">Ajouter du Crédit</option>
                </b-select>
            </b-field>
            <b-button
                class="mb-2 mb-md-0 ml-md-2"
                type="primary"
                :disabled="consultants.length === 0"
                @click="openModal"
            >
                Valider
            </b-button>
        </form>
        <b-modal v-model="modal">
            <template #default="props">
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">Ajouter du crédit</h4>
                </header>
                <div class="box">
                    <b-loading v-if="loading" v-model="loading" :is-full-page="false" />
                    <div v-else>
                        <div class="modal-body">
                            <b-field label="Montant en Euro / consultant">
                                <b-input v-model="credits" type="number" step="0.01" />
                            </b-field>
                        </div>
                        <div class="modal-footer">
                            <b-button type="button" class="btn btn-danger" @click="props.close">Annuler</b-button>
                            <b-button type="button" class="btn btn-primary" @click="bulk">Modifier</b-button>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        props: {
            consultants: {
                type: Array,
                required: true
            }
        },
        data: () => ({
            modal: false,
            type: null,
            loading: false,
            credits: 0,
            bulkActionType: null
        }),
        computed: {
            ...mapGetters('auth', { me: 'user' })
        },
        methods: {
            ...mapActions('consultants', {
                bulkAddCreditsToConsultants: 'bulkAddCredits'
            }),
            openModal() {
                this.modal = true
            },
            bulk() {
                this.loading = true
                let consultants = this.consultants.map(c => c.id)
                this.bulkAddCreditsToConsultants({
                    consultants,
                    credits: this.credits
                })
                    .then(() => {
                        this.$store.dispatch('toast', {
                            title: 'Crédit ajouté',
                            type: 'success'
                        })

                        this.modal = false
                        this.form = {}
                        this.$emit('reload')
                    })
                    .catch(() => {
                        this.$store.dispatch('toast', {
                            title: 'Une erreur est survenue lors de la mise à jour',
                            type: 'error'
                        })
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        }
    }
</script>
