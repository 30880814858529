<template>
    <b-notification :closable="false" role="alert" type="is-danger is-light">
        <p>
            <i class="fa fa-exclamation-triangle mr-1" />
            Votre article est passé en relecture. Des points sont à réctifier.
        </p>
        <p class="font-weight-bold mb-0">Notes du correcteur :</p>
        <p>{{ reason }}</p>
        <slot />
    </b-notification>
</template>
<script>
    export default {
        name: 'WritingReviewedNotification',
        props: {
            reason: {
                type: String,
                required: true
            }
        }
    }
</script>
