<template>
    <div>
        <hr />
        <div
            class="d-flex justify-content-between align-items-center cursor-pointer"
            @click="
                () => {
                    visibleTab = !visibleTab
                }
            "
        >
            <h2><i class="fas fa-star mr-2" />Top / Flop des sites</h2>
            <i v-if="!visibleTab" class="fas fa-caret-down fa-lg" />
            <i v-if="visibleTab" class="fas fa-caret-up fa-lg" />
        </div>
        <collapse-transition :duration="500" mode="out-in" origin="center">
            <div v-if="visibleTab" class="d-lg-flex">
                <div class="col-lg-6">
                    <h4 class="text-center">Top</h4>
                    <b-table :data="top" class="table-striped">
                        <b-table-column label="ID" field="Top">
                            <div slot="default" slot-scope="props">
                                {{ props.row.id }}
                            </div>
                        </b-table-column>
                        <b-table-column label="NDD" field="Site">
                            <div slot="default" slot-scope="props">
                                {{ props.row.ndd }}
                            </div>
                        </b-table-column>
                        <b-table-column label="Commandes" field="Commandes" centered>
                            <div slot="default" slot-scope="props">
                                {{ props.row.count }}
                                <span
                                    :class="
                                        computeVariation(props.row.count, props.row.count_variation) > 0
                                            ? 'text-success'
                                            : 'text-danger'
                                    "
                                    ><i
                                        v-if="computeVariation(props.row.count, props.row.count_variation) > 0"
                                        class="fas fa-level-up-alt"
                                    /><i v-else class="fas fa-level-down-alt" />{{
                                        computeVariation(props.row.count, props.row.count_variation)
                                    }}</span
                                >
                            </div>
                        </b-table-column>
                        <b-table-column label="CA" centered field="CA">
                            <div slot="default" slot-scope="props">
                                {{ props.row.sum | toCurrency }}
                                <span
                                    :class="
                                        computeVariation(props.row.sum, props.row.sum_variation) > 0
                                            ? 'text-success'
                                            : 'text-danger'
                                    "
                                    ><i
                                        v-if="computeVariation(props.row.sum, props.row.sum_variation) > 0"
                                        class="fas fa-level-up-alt"
                                    /><i v-else class="fas fa-level-down-alt" />{{
                                        computeVariation(props.row.sum, props.row.sum_variation)
                                    }}
                                    €</span
                                >
                            </div>
                        </b-table-column>
                    </b-table>
                </div>
                <div class="vl" />
                <div class="col-lg-6">
                    <h4 class="text-center">Flop</h4>
                    <b-table :data="flop" class="table-striped">
                        <b-table-column label="ID" field="Top">
                            <div slot="default" slot-scope="props">
                                {{ props.row.id }}
                            </div>
                        </b-table-column>
                        <b-table-column label="NDD" field="Site">
                            <div slot="default" slot-scope="props">
                                {{ props.row.ndd }}
                            </div>
                        </b-table-column>
                        <b-table-column label="Commandes" field="Commandes" centered>
                            <div slot="default" slot-scope="props">
                                {{ props.row.count }}
                                <span
                                    :class="
                                        computeVariation(props.row.count, props.row.count_variation) > 0
                                            ? 'text-success'
                                            : 'text-danger'
                                    "
                                    ><i
                                        v-if="computeVariation(props.row.count, props.row.count_variation) > 0"
                                        class="fas fa-level-up-alt"
                                    /><i v-else class="fas fa-level-down-alt" />{{
                                        computeVariation(props.row.count, props.row.count_variation)
                                    }}</span
                                >
                            </div>
                        </b-table-column>
                        <b-table-column label="CA" centered field="CA">
                            <div slot="default" slot-scope="props">
                                {{ props.row.sum | toCurrency }}
                                <span
                                    :class="
                                        computeVariation(props.row.sum, props.row.sum_variation) > 0
                                            ? 'text-success'
                                            : 'text-danger'
                                    "
                                    ><i
                                        v-if="computeVariation(props.row.sum, props.row.sum_variation) > 0"
                                        class="fas fa-level-up-alt"
                                    /><i v-else class="fas fa-level-down-alt" />{{
                                        computeVariation(props.row.sum, props.row.sum_variation)
                                    }}
                                    €</span
                                >
                            </div>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
        </collapse-transition>
    </div>
</template>

<script>
    export default {
        name: 'TopFlopSitesDashboard',
        props: {
            top: {
                type: Array,
                required: false
            },
            flop: {
                type: Array,
                required: false
            }
        },
        data() {
            return {
                visibleTab: false
            }
        },
        methods: {
            computeVariation(value, variation) {
                return (value - variation).toFixed(0)
            }
        }
    }
</script>

<style scoped>
    .vl {
        border-left: 3px solid hsl(0deg, 0%, 86%);
        height: auto;
        margin: 0 10px;
    }
</style>
