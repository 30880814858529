<template>
    <div :class="divClass">
        <div :class="background" class="card text-white">
            <div class="card-content">
                <div class="row">
                    <div class="col-sm-12 col-lg-6 mr-auto">
                        <h3 class="display-4 d-block l-h-n m-0 fw-500">
                            {{ number }}
                        </h3>
                    </div>
                    <div class="col-auto">
                        <i :class="iconClass + ' ' + iconSize" class="text-right opacity-15 mb-n5 mr-n6" />
                    </div>
                </div>
                <p class="text-center text-md-left mt-5 m-md-0 l-h-n" v-html="label" />
            </div>
        </div>
    </div>
</template>

<style scoped>
    .icon-sm {
        font-size: 1rem;
    }

    .icon-md {
        font-size: 2rem;
    }

    .icon-lg {
        font-size: 4rem;
    }

    .icon-xl {
        font-size: 6rem;
    }
</style>

<script>
    export default {
        name: 'DashboardKPI',
        props: {
            number: {
                required: false,
                default: '0'
            },
            label: {
                type: String,
                required: false,
                default: 'Mon label'
            },
            iconClass: {
                type: String,
                required: false,
                default: 'Mon label'
            },
            background: {
                type: String,
                required: false,
                default: 'Mon label'
            },
            divClass: {
                type: String,
                required: false,
                default: 'col-sm-6 col-xl-4 mb-2'
            },
            iconSize: {
                type: String,
                required: false,
                default: 'icon-xl'
            }
        },
        data: function () {
            return {}
        },
        created() {}
    }
</script>
