<template>
    <div class="tabs-container">
        <div class="">
            <div class="tabs">
                <slot />
            </div>
        </div>
        <div v-if="displayMenu" class="relative">
            <div v-click-outside="closeMenu" class="more" @click="menuOpen = !menuOpen">
                Voir plus
                <v-icon icon="TabPlus" class="w-2 h-2" />
            </div>
            <div v-if="menuOpen" class="absolute bg-white shadow-tesbaux right-0 p-4 w-64">
                <ul>
                    <li
                        v-for="tab in hiddenTabs"
                        :key="tab._uid"
                        class="mb-4 cursor-pointer"
                        @click="activateItem(tab)"
                    >
                        {{ tab.text }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: Number,
                default: () => 0
            }
        },
        data: () => ({
            tabs: [],
            containerWidth: null,
            tabsWidth: 0,
            menuWidth: 150,
            resizeTimeout: null,
            menuOpen: false
        }),
        computed: {
            displayMenu() {
                const l = this.tabs ? this.tabs.length : 0
                return this.displayableTabs < l
            },
            hiddenTabs() {
                return this.tabs.filter(t => t.visible === false)
            },
            displayableTabs() {
                let w = this.tabs[this.value] ? this.tabs[this.value].originalWidth : 0

                const inactiveTabs = [...this.tabs]
                inactiveTabs.splice(this.value, 1)

                for (let index = 0; index < inactiveTabs.length; index++) {
                    const tab = inactiveTabs[index]
                    w += tab.originalWidth
                    if (w > this.containerWidth - this.menuWidth) {
                        return index
                    }
                }

                return this.tabs.length
            }
        },
        watch: {
            value() {
                this.activate()
            }
        },
        mounted() {
            window.addEventListener('resize', this.resize)
            // this.tabs[this.value].active()
            this.resize()
        },
        destroyed() {
            window.removeEventListener('resize', this.resize)
        },
        methods: {
            resize() {
                clearTimeout(this.resizeTimeout)
                this.resizeTimeout = window.setTimeout(this.callResize, 50)
            },

            callResize() {
                this.containerWidth = this.$el.offsetWidth
                if (this.containerWidth < this.tabsWidth) {
                    const anotherTabDisplayed =
                        this.displayableTabs < this.value ? this.displayableTabs - 1 : this.displayableTabs
                    for (let index = 0; index < this.tabs.length; index++) {
                        const tab = this.tabs[index]
                        if (index < anotherTabDisplayed || index === this.value) {
                            tab.visible = true
                        } else {
                            tab.visible = false
                        }
                    }
                }
            },
            addItem(item) {
                const index = this.$slots.default.indexOf(item.$vnode)
                this.tabs.splice(index, 0, item)
                this.tabsWidth += item.$el.offsetWidth
                this.activate()
            },
            activateItem(item) {
                const index = this.tabs.findIndex(tab => tab._uid === item._uid)
                this.$emit('input', index)
                // this.activate()
            },

            activate() {
                this.tabs.forEach(tab => {
                    tab.active = false
                })
                if (this.tabs[this.value]) {
                    this.tabs[this.value].active = true
                }
                this.callResize()
            },
            closeMenu() {
                this.menuOpen = false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tabs-container {
        .tabs {
            justify-content: flex-start;
            border-bottom: 1px solid #dee2e6;
            overflow: visible;
            margin-bottom: 20px;
        }
    }

    .more {
        cursor: pointer;
        padding: 10px 20px;
        margin: 10px 0;
        font-weight: bold;
        white-space: nowrap;
    }
</style>
