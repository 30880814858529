<template>
    <div v-if="!editingStatus" class="cursor-pointer" @click="editStatus">
        <span v-if="statusClass[status] && !loading" class="tag" :class="[statusClass[status], sizeClass[size]]">
            {{ $t('accompagnement.status.' + status) }}
        </span>
        <span v-else-if="loading" class="tag is-light" :class="sizeClass[size]">
            <nl-loader width="loader-sm" show="show" />
        </span>
        <span v-else class="tag is-dark">Error</span>
    </div>
    <div v-else>
        <div class="form-group form-group-sm">
            <select class="form-control" @change="setStatus" @keydown.esc="editingStatus = null">
                <option v-for="(item, i) in available" :key="`status_${i}`" :value="item" :selected="status === item">
                    {{ $t(`accompagnement.status.${item}`) }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Status',
        props: {
            status: {
                type: String,
                required: true
            },
            available: {
                type: Array,
                required: true
            },
            size: {
                type: String,
                default: 'small'
            },
            readonly: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                editingStatus: false,
                statusClass: {
                    termine: 'is-dark',
                    en_cours: 'is-warning is-light',
                    a_confirmer: 'is-info',
                    en_attente_credit: 'is-danger',
                    a_faire: 'is-warning',
                    fait: 'is-success',
                    en_attente_retour_client: 'is-danger'
                },
                sizeClass: {
                    small: '',
                    large: 'is-large',
                    medium: 'is-medium'
                }
            }
        },
        methods: {
            editStatus() {
                if (!this.readonly) {
                    this.editingStatus = true
                }
            },
            setStatus(e) {
                this.$emit('change', e.target.value)
                this.editingStatus = null
            }
        }
    }
</script>

<style scoped></style>
