<template>
    <button class="dropdown-item" type="button" @click="$emit('click')">
        <slot />
    </button>
</template>

<script>
    export default {}
</script>

<style scoped>
    .dropdown-item {
        width: min-content;
    }
</style>
