<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">Supprimer</h4>
                </header>
                <div class="card-content">
                    <div class="content">
                        <form v-if="withdrawal">
                            <section class="row mb-3">
                                <div class="col-12">
                                    <p>
                                        Êtes-vous sûr de vouloir supprimer la demande de retrait #{{ withdrawal.id }}
                                        ?
                                    </p>
                                </div>
                            </section>

                            <div class="buttons justify-content-end">
                                <button class="button is-light" @click.prevent="closeAndResetModal">
                                    {{ $t('global.cancel') }}
                                </button>
                                <button class="button is-danger" @click.prevent="validate">Supprimer</button>
                            </div>
                        </form>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'WithdrawalConfirmModal',
        props: [],
        data() {
            return {
                modalIsOpen: false,
                withdrawal: null
            }
        },

        methods: {
            validate() {
                this.$parent.$emit('deleteWithdrawal', this.withdrawal)

                this.closeAndResetModal()
            },

            openModal(withdrawal) {
                this.modalIsOpen = true
                this.withdrawal = withdrawal
            },

            closeAndResetModal() {
                this.modalIsOpen = false
                this.withdrawal = null
            }
        }
    }
</script>
