<template>
    <div class="p-3">
        <b-tabs
            v-model="activeTab"
            @input="
                value => {
                    openOnce[value] = true
                }
            "
        >
            <b-tab-item v-for="tab in tabs" :key="tab.index" :label="tab.label" :disabled="tab.disabled">
                <RedactorTable :status="tab.status" />
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
    import RedactorTable from '@/components/Admin/Redactor/RedactorTable.vue'

    export default {
        name: 'RedactorTabs',
        components: { RedactorTable },
        props: {
            tabCounts: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                activeTab: 0,
                openOnce: {
                    0: true
                },
                statusActive: 'active',
                statusInactive: 'inactive'
            }
        },
        computed: {
            tabs() {
                return [
                    {
                        type: this.statusActive,
                        label: `Actifs (${this.tabCounts.active})`,
                        disabled: false,
                        index: 0,
                        status: false
                    },
                    {
                        type: this.statusInactive,
                        label: `Inactifs (${this.tabCounts.inactive})`,
                        disabled: false,
                        index: 1,
                        status: true
                    }
                ]
            }
        }
    }
</script>

<style scoped></style>
