import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store'
import adminRoutes from './admin'
import adminisrativeRoutes from './administrative'
import agencyRoutes from './agency'
import authRoutes from './auth'
import partnerRoutes from './partner'
import prospectRoutes from './prospect'
import redactorRoutes from './redactor'
import NlStorage from "@/services/nl.storage";

export const ROLES = {
    ADMIN: 'admin',
    ADMINISTRATIVE: 'administratif',
    AGENCY: 'agence',
    CONSULTANT: 'consultant',
    MANAGER: 'manager',
    PARTNER: 'partenaire',
    PROSPECT: 'prospect',
    REDACTOR: 'redacteur'
}

Vue.use(VueRouter)

const baseRoutes = [
    {
        path: '/',
        redirect: '/client'
    },
    {
        path: '/projects/new',
        name: 'FicheProjetCreate',
        components: {
            default: () => import('../views/ProjetEdit.vue')
        }
    },
    {
        path: '/projects/edit',
        name: 'FicheProjet',
        components: {
            default: () => import('../views/ProjetEdit.vue')
        }
    },
    {
        path: '/campaigns/details',
        name: 'AccompagnementApp',
        alias: '/detail-accompagnement.php',
        component: () => import('../views/Campaign/CampaignManager.vue'),
        props: {
            isAdmin: false,
            backLink: '/campaigns'
        }
    },
    {
        path: '/profile',
        name: 'Profil',
        component: () => import('../views/Profil.vue')
    },
    {
        path: '/invoices',
        alias: '/factures.php',
        name: 'Factures',
        components: {
            default: () => import('../views/Factures.vue')
        }
    },
    {
        path: '/buy-credits',
        alias: '/achat-credits.php',
        name: 'Paiement',
        components: {
            default: () => import('../views/Paiement/AchatCreditsTunnel.vue')
        }
    },
    {
        // old
        path: '/_buy-credits',
        name: '_Paiement',
        components: {
            default: () => import('../views/Paiement/_AchatCredits.vue')
        }
    },
    {
        path: '/buy-credits/wire-confirmation',
        name: 'PaiementWireConfirmation',
        components: {
            default: () => import('../views/Paiement/AchatCreditsWireConfirmation.vue')
        }
    },
    {
        path: '/buy-credits/stripe-confirmation',
        name: 'PaiementStripeConfirmation',
        components: {
            default: () => import('../views/Paiement/PaiementStripeConfirmation.vue')
        }
    },
    {
        path: '/buy-credits/card-confirmation',
        name: 'PaiementCardConfirmation',
        components: {
            default: () => import('../views/Paiement/AchatCreditsCardConfirmation.vue')
        }
    },
    {
        path: '/credits',
        alias: '/mes-credits.php',
        name: 'Credits',
        components: {
            default: () => import('../views/Credits.vue')
        }
    },
    {
        path: '/campaigns',
        alias: '/mes-accompagnements.php',
        name: 'Campaigns',
        components: {
            default: () => import('../views/Campaigns.vue')
        },
        meta: {
            isSeo: true,
            roles: [ROLES.ADMIN, ROLES.AGENCY, ROLES.PROSPECT, ROLES.CONSULTANT, ROLES.MANAGER, ROLES.PARTNER]
        }
    },
    {
        path: '/campaigns/new',
        name: 'AccompagnementClientNouveau',
        components: {
            default: () => import('../views/Accompagnements/AccompagnementNew.vue')
        },
        meta: {
            isSeo: true,
            roles: [ROLES.ADMIN, ROLES.AGENCY, ROLES.PROSPECT, ROLES.CONSULTANT, ROLES.MANAGER, ROLES.PARTNER]
        }
    },
    {
        path: '/api/doc',
        name: 'ApiDocumentation',
        components: {
            default: () => import('../views/ApiDocumentation.vue')
        },
        meta: {
            isSeo: true,
            roles: [ROLES.ADMIN, ROLES.AGENCY, ROLES.PROSPECT, ROLES.CONSULTANT, ROLES.MANAGER, ROLES.PARTNER]
        }
    },
    {
        path: '/become-partner',
        name: 'Devenir Partenaire',
        components: {
            default: () => import('../views/BecomePartner.vue')
        }
    },
    {
        path: '/notifications',
        name: 'Notifications',
        components: {
            default: () => import('../views/Notifications.vue')
        }
    },
    {
        path: '/403',
        components: {
            default: () => import('../views/FourOhThree.vue')
        }
    },
    {
        path: '*',
        components: {
            default: () => import('../views/FourOhFour.vue')
        }
    }
]

const routes = baseRoutes.concat(
    authRoutes,
    redactorRoutes,
    adminRoutes,
    adminisrativeRoutes,
    partnerRoutes,
    agencyRoutes,
    prospectRoutes
)

const router = new VueRouter({
    mode: 'history',
    routes
})

function voucher(roles, role, fromAdmin) {
    return roles.includes(role) || role === ROLES.ADMIN || fromAdmin
}

router.beforeEach(async (to, from, next) => {
    let token = NlStorage.getItem('token')
    let isConnected = token && token.length > 0

    if (!isConnected) {
        if ((!to.meta || !to.meta.isPublic) && to.name !== 'Login') {
            next({
                path: '/login'
            })
        } else {
            next()
        }
    } else if (to.meta.isPublic) {
        next({ path: '/' })
    } else {
        let role = store.state.auth.user?.role ?? (await store.dispatch('auth/me'))?.role

        let fromAdmin = store.state.auth.fromAdmin

        if (from.name !== to.name && (!to.meta?.roles || voucher(to.meta.roles, role, fromAdmin))) {
            next()
        } else {
            next({ path: '/' })
        }
    }
})

export default router
