<template>
    <div>
        <b-tabs
            v-model="activeTab"
            @input="
                value => {
                    openOnce[value] = true
                }
            "
        >
            <b-tab-item v-for="tab in tabs" :key="tab.index" :label="tab.label" :disabled="tab.disabled">
                <writing-table-status v-if="openOnce[tab.index]" :status="tab.status" />
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
    import WritingTableStatus from '@/components/Admin/Redactor/WritingTableStatus.vue'

    export default {
        name: 'WritingTabsStatus',
        components: { WritingTableStatus },
        props: {
            tabCounts: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                activeTab: 0,
                openOnce: {
                    0: true
                },

                statusProcessing: 'processing',
                statusReview: 'review',
                statusDone: 'done',
                statusError: 'error'
            }
        },
        computed: {
            tabs() {
                return [
                    {
                        type: this.statusProcessing,
                        label: `En cours et à traiter (${this.tabCounts.processing})`,
                        disabled: false,
                        index: 0,
                        status: this.statusProcessing
                    },
                    {
                        type: this.statusReview,
                        label: `A relire (${this.tabCounts.review})`,
                        disabled: false,
                        index: 1,
                        status: this.statusReview
                    },
                    {
                        type: this.statusDone,
                        label: `Terminé (${this.tabCounts.done})`,
                        disabled: false,
                        index: 2,
                        status: this.statusDone
                    },
                    {
                        type: this.statusError,
                        label: `Erreurs (${this.tabCounts.error})`,
                        disabled: false,
                        index: 3,
                        status: this.statusError
                    },
                    {
                        type: this.statusError,
                        label: `Tous (${this.tabCounts.all})`,
                        disabled: false,
                        index: 4
                    }
                ]
            }
        }
    }
</script>
