<template>
    <span>
        <span v-if="!order.site.partenaire_id">
            <span v-if="status == 'a_valider'" class="tag is-warning">
                <i class="far fa-hourglass mr-1" />
                {{ $t('status.waitingforvalidation') }}
            </span>

            <span v-if="status == 'en_cours' && !order.brief_envoye" class="tag is-info">
                <i class="fas fa-check mr-1" />
                {{ $t('status.accepted') }}
            </span>

            <span v-if="status == 'en_cours' && order.brief_envoye" class="tag is-info">
                <i class="fas fa-pen mr-1" />
                {{ $t('status.redaction') }}
            </span>

            <span v-if="status == 'termine'" class="tag is-success">
                <i class="fas fa-check-circle mr-1" />
                {{ $t('status.published') }}
            </span>

            <span v-if="status == 'annule' || status == 'refuse'" class="tag is-danger">
                <i class="fas fa-ban mr-1" />
                {{ status == 'annule' ? $t('status.canceled') : $t('status.refused') }}
            </span>

            <span v-if="status == 'expire'" class="tag is-danger">
                <i class="fas fa-times mr-1" />
                {{ $t('status.expired') }}
            </span>
        </span>

        <span v-else>
            <span v-if="status == 'a_valider'" class="tag is-warning">
                <i class="far fa-handshake mr-1" />
                {{ $t('status.waitingforvalidation') }}
            </span>

            <span v-if="status == 'en_cours' && order.redaction === 'proprietaire'" class="tag is-info">
                <i class="far fa-handshake mr-1" />
                {{ $t('status.redaction') }}
            </span>

            <span v-if="status == 'en_cours' && order.redaction === 'nextlevel'" class="tag is-info">
                <i class="far fa-handshake mr-1" />
                {{
                    !order.brief_envoye
                        ? $t('status.accepted')
                        : !order.contenu_date_envoi
                        ? $t('status.redaction')
                        : $t('status.publication')
                }}
            </span>

            <span v-if="status == 'termine'" class="tag is-success">
                <i class="far fa-handshake mr-1" />
                {{ $t('status.published') }}
            </span>

            <span v-if="status == 'annule' || status == 'refuse'" class="tag is-danger">
                <i class="fas fa-ban mr-1" />
                {{ status == 'annule' ? $t('status.canceled') : $t('status.refused') }}
            </span>

            <span v-if="status == 'expire'" class="tag is-danger">
                <i class="fas fa-times mr-1" />
                {{ $t('status.expired') }}
            </span>
        </span>

        <!--

        <span class="tag is-dark" v-else>Error</span> -->
    </span>
</template>

<script>
    export default {
        name: 'TrackingOrderStatus',
        props: ['order'],

        data() {
            return {
                status: null,
                currentOrder: null
            }
        },

        watch: {
            order: {
                immediate: true,
                deep: true,
                handler(newValue) {
                    this.currentOrder = newValue
                    this.status = newValue.statut
                }
            }
        },

        created() {
            this.currentOrder = this.order
            this.status = this.currentOrder.statut
        }
    }
</script>
