<template>
    <b-modal v-model="showModal" :width="960" :on-cancel="hide">
        <template>
            <header class="px-4 py-3 has-background-warning shadow-sm">
                <h4 class="h4 p-0 mb-0">Transfert</h4>
            </header>
            <div class="card-content">
                <div class="content">
                    <form>
                        <div class="modal-body">
                            <div class="row mb-3">
                                <div class="control col-md-12 pb-3">
                                    <div class="d-flex">
                                        <b-taglist attached class="mb-0 pr-2">
                                            <b-tag type="is-light" size="is-medium" @click.prevent="transferAmount = benefits">Vos gains</b-tag>
                                            <b-tag type="is-dark" size="is-medium" @click.prevent="transferAmount = benefits">{{ benefits | toCurrency }}</b-tag>
                                        </b-taglist>

                                        <b-taglist attached class="mb-0 pb-0">
                                            <b-tag type="is-light" size="is-medium">Vos crédits actuels</b-tag>
                                            <b-tag type="is-dark" size="is-medium">{{ credits | toCurrency }}</b-tag>
                                        </b-taglist>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <b-field :label="$t('partenaire-gains.amounttransform')">
                                        <b-numberinput
                                            :max="benefits"
                                            :min-step="0.01"
                                            :controls="false"
                                            v-model="transferAmount"
                                            icon="euro-sign"
                                        />
                                    </b-field>
                                </div>
                                <div class="col-sm-6">
                                    <b-field :label="$t('partenaire-gains.gift')">
                                        <b-numberinput
                                            :max="benefits"
                                            :step="0.01"
                                            :value="
                                                transferAmount >= 10
                                                    ? Number.parseFloat(transferAmount * this.bonus / 100)
                                                    : 0
                                            "
                                            :controls="false"
                                            icon="euro-sign"
                                            disabled
                                        />
                                    </b-field>
                                </div>
                            </div>
                            <b-field>
                                <b-checkbox v-model="cgvBool" required type="is-dark">
                                    <a role="button" class="underline">
                                        {{ $t('partenaire-gains.cgvknowledge') }}
                                    </a>
                                </b-checkbox>
                            </b-field>
                        </div>
                        <div class="modal-footer">
                            <b-button
                                type="is-danger"
                                @click="hide()"
                                :disabled="clickButton"
                                :label="$t('global.cancel')"
                            />
                            <b-button
                                type="is-warning"
                                @click.prevent="transferGainToCredit"
                                :disabled="clickButton || !cgvBool || Number(transferAmount) > Number(benefits)"
                                :label="$t('global.validate')"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import { mapMutations, mapState } from 'vuex'

    export default {
        name: 'TransfertGainsModal',
        data() {
            return {
                showModal: false,
                cgvBool: false,
                transferAmount: 0,
                clickButton: false
            }
        },
        computed: {
            ...mapState('partner_sites', ['withdrawGains', 'bonus']),
            ...mapState('auth', {
                benefits: state => state.user?.gains,
                credits: state => state.user?.credit_amount
            })
        },
        methods: {
            ...mapMutations('auth', {
                setBenefits: 'setBenefits',
                setCredits: 'setCredits'
            }),
            show() {
                this.showModal = true
            },
            hide() {
                this.$emit('close')
                this.showModal = false
            },
            transferGainToCredit() {
                if (this.cgvBool) {
                    this.axios
                        .post('/partner-gain/transfer', { amount: this.transferAmount })
                        .then(response => {
                            this.setBenefits(response.data.benefits)
                            this.setCredits(response.data.credits)

                            this.clickButton = false
                            this.cgvBool = false
                        })
                        .finally(() => {
                            this.hide()
                        })
                }
            }
        }
    }
</script>

<style scoped></style>
