<template>
    <div>
        <b-tag type="is-danger" size="is-small" style="cursor: pointer" @click="open = true">
            <div class="flex" style="gap: 0.5rem">
                <b-icon icon="eye" />
                <span>
                    {{ externalLinksCount }}
                </span>
            </div>
        </b-tag>
        <b-modal v-model="open">
            <template>
                <header class="modal-card-head has-background-warning">
                    <div class="modal-card-title">Liens externes</div>
                </header>
                <section class="modal-card-body">
                    <NlTable :async="false" :columns.sync="columns" :data="externalLinks" />
                </section>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable.vue'

    export default {
        name: 'ExternalLinksModal',
        components: { NlTable },
        props: {
            urlId: {
                type: Number,
                required: true
            },
            externalLinksCount: {
                type: Number,
                required: true
            },
            externalLinks: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                open: false,
                columns: [
                    {
                        centered: false,
                        custom: false,
                        field: 'link',
                        label: 'Url',
                        searchable: false,
                        sortable: false
                    }
                ]
            }
        }
    }
</script>
