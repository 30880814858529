<template>
    <nl-modal ref="modal" title="Mettre la commande en attente" class="assign-modal">
        <div class="container-fluid container assign-redactor">
            <div class="input-group mt-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">Ajouter un message</span>
                </div>
                <textarea v-model="writing.reason" class="form-control" aria-label="With textarea" />
            </div>
        </div>

        <template v-slot:footer>
            <div class="buttons justify-content-end">
                <button class="button is-light" @click="hide">
                    <i class="fas fa-times mr-2" />
                    {{ $t('global.cancel') }}
                </button>

                <button class="button is-success" @click="$emit('save')">
                    <i class="fas fa-check mr-2" />
                    Mettre en attente la commande
                </button>
            </div>
        </template>
    </nl-modal>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'AddPendingMessage',

        data() {
            return {}
        },

        computed: {
            ...mapState('redactors', ['writing'])
        },

        methods: {
            show() {
                this.$refs.modal.show()
            },
            hide() {
                this.$refs.modal.hide()
            }
        }
    }
</script>

<style scoped></style>
