<template>
    <div>
        <div style="width: 100%; overflow: auto; position: relative; border-radius: 6px">
            <b-loading :active="loading" :is-full-page="false" />
            <table class="table is-striped">
                <colgroup>
                    <col v-if="detailed" :span="1" style="background: white" />
                    <col
                        v-for="(header, key) in surHeaders"
                        :key="`col_${key}`"
                        :span="header.colspan"
                        :style="{ backgroundColor: key % 2 === 0 ? 'white' : '#fef3c7' }"
                    />
                </colgroup>
                <thead>
                    <tr v-if="surHeaders">
                        <th v-if="detailed" />
                        <th v-for="(header, key) in surHeaders" :key="`surHeader_${key}`" :colspan="header.colspan">
                            {{ header.label }}
                        </th>
                    </tr>
                    <tr>
                        <th v-if="detailed" />
                        <th
                            v-for="(column, index) in filteredColumns"
                            :key="`header_${index}`"
                            :style="{
                                verticalAlign: 'middle',
                                borderBottom: meta.sortBy === column.field ? '2px solid #7a7a7a' : '2px solid #dee2e6',
                                hoverBorderBottom: '2px solid #7a7a7a'
                            }"
                        >
                            <div
                                class="d-flex justify-content-center align-items-center"
                                :class="{'cursor-pointer': column.sortable}"
                                style="gap: 0.5rem"
                                @click="column.sortable ? onSort(column.sortField || column.field) : null"
                            >
                                <b-tooltip v-if="column.tooltip" :label="column.tooltip" dashed>
                                    <span :class="{'cursor-pointer': column.sortable}">
                                        {{ column.label }}
                                    </span>
                                </b-tooltip>
                                <template v-else>
                                    {{ column.label }}
                                </template>
                                <b-icon
                                    v-if="meta.sortBy === column.field"
                                    icon="arrow-up"
                                    size="is-small"
                                    :style="{
                                        transform: meta.orderBy === 'desc' ? 'rotate(180deg)' : 'rotate(0)',
                                        transition: 'transform .2s'
                                    }"
                                />
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <fragment v-for="row in data" :key="`row_${row.id}`">
                        <tr>
                            <td v-for="(column, j) in filteredColumns" :key="`row_${row.id}-col_${j}`">
                                <slot v-if="column.custom" :name="getSlotName(column.field)" :props="{ column, row }" />
                                <NlTableCell
                                    v-else
                                    :centered="column.centered"
                                    :default-value="column.defaultValue"
                                    :field-option="column.fieldOption"
                                    :filter="column.filter"
                                    :prefix="column.prefix"
                                    :suffix="column.suffix"
                                    :type="column.type"
                                    :value="extractValue(row, column.field)"
                                />
                            </td>
                        </tr>
                    </fragment>
                </tbody>
                <tfoot v-if="totalField">
                    <tr>
                        <td :colspan="filteredColumns.length - 1" />
                        <td>Total</td>
                        <td>{{ data.reduce((a, b) => a + b[totalField], 0) }}€</td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <b-pagination
            rounded
            :current="meta.page"
            :per-page="meta.perPage"
            :total="meta.total"
            @change="onPageChange"
        />
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex'
    import NlTableCell from '@/components/UI/Table/NlTableCell.vue'
    import { extractValue } from '@/helpers/objectHelpers'

    export default {
        name: 'NlSearchTable',
        components: { NlTableCell },
        props: {
            centered: {
                type: Boolean,
                required: false,
                default: true
            },
            columns: {
                type: Array,
                required: false
            },
            data: {
                type: Array,
                required: false,
                default: () => []
            },
            detailed: {
                type: Boolean,
                required: false
            },
            loading: {
                type: Boolean,
                required: false,
                default: false
            },
            searchable: {
                type: Boolean,
                required: false,
                default: true
            },
            sortable: {
                type: Boolean,
                required: false,
                default: false
            },
            surHeaders: {
                type: Array,
                required: false
            },
            totalField: {
                type: String,
                required: false
            },
            visible: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        data() {
            return {
                extendedRow: null
            }
        },
        computed: {
            ...mapState('search', { meta: 'meta' }),
            filteredColumns() {
                return this.columns.filter(e => e.visible && !e.hide)
            }
        },
        methods: {
            extractValue,
            ...mapMutations('search', {
                setMeta: 'setMeta'
            }),
            onSort(field) {
                this.setMeta({
                    ...this.meta,
                    sortBy: field,
                    orderBy: this.meta.sortBy === field && this.meta.orderBy === 'asc' ? 'desc' : 'asc'
                })
                this.$emit('search')
            },
            onPageChange(page) {
                this.setMeta({
                    ...this.meta,
                    page
                })
                this.$emit('search')
            },

            getSlotName(name) {
                return name
                    .split('.')
                    .map((value, key) => {
                        return key > 0 ? value.charAt(0).toUpperCase() + value.slice(1) : value
                    })
                    .join('')
            }
        },
        created() {
            this.$emit(
                'update:columns',
                this.columns.map(column => {
                    return {
                        centered: this.centered,
                        searchable: this.searchable,
                        sortable: this.sortable,
                        visible: this.visible,
                        ...column
                    }
                })
            )
        }
    }
</script>

<style lang="scss" scoped>
    table {
        width: 100%;
        border-radius: 4px;
    }

    th {
        border-bottom: 2px solid #dee2e6;
    }

    th {
        text-align: center !important;
    }

    tbody > tr:nth-child(even):not(.sub-row) {
        background-color: #fffbebaa !important;
    }

    tr {
        border: 1px solid transparent;
    }

    ::v-deep .pagination-list {
        justify-content: flex-end;
    }

    .sub-row {
        background-color: #f1f5f9dd !important;
    }
</style>
