import ApiService from '../../../services/api.service'

export default {
    list(context, params) {
        const { page, rowsPerPage, sortField, sortOrder, filters } = params

        let sort = sortField
        if (sortOrder === 'desc') {
            sort = '-' + sort
        }

        let filter = {}
        if (filters) {
            for (const [key, value] of Object.entries(filters)) {
                filter[`filter[${key}]`] = value
            }
        }

        return new Promise((resolve, reject) => {
            ApiService.get('/admin/search-log/list', {
                page,
                rowsPerPage,
                sort,
                sortOrder,
                ...filter
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getMonths(context) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/search-log/months')
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    export(ctx, params) {
        const { page, rowsPerPage, sortField, sortOrder, filters } = params

        let sort = sortField
        if (sortOrder == 'asc') {
            sort = '-' + sort
        }

        let filter = null
        if (filters) {
            filter = []
            for (let i = 0; i < filters.length; i++) {
                let f = filters[i]
                filter[f.key] = f.value
            }
        }
        return new Promise((resolve, reject) => {
            ApiService.get(
                '/admin/search-log/export',
                { page, rowsPerPage, sort, sortOrder, filter },
                {
                    responseType: 'arraybuffer',
                    headers: { Accept: 'application/octet-stream' }
                }
            )
                .then(data => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    }
}
