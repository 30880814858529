<template>
    <a @dblclick="editStatus">
        <span v-if="edit">
            <select id="frm_followup_status" :value="status" class="form-control" @change="onChange($event)">
                <option v-for="(status, i) in available" :key="i" :value="status">
                    {{ $t('accompagnement.followup.status.' + status) }}
                </option>
            </select>
        </span>
        <span
            v-else
            class="badge"
            :class="{
                'badge-warning': status === 'todo',
                'badge-darkorange': status === 'in_progress',
                'badge-danger': status === 'awaiting',
                'badge-success': status === 'done'
            }"
        >
            {{ $t('accompagnement.followup.status.' + status) }}
        </span>
    </a>
</template>

<script>
    export default {
        props: {
            readonly: {
                type: Boolean,
                default: false
            },
            status: {
                required: true
            },
            available: {
                required: true,
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        data: function () {
            return {
                edit: false
            }
        },
        methods: {
            editStatus() {
                if (!this.readonly) {
                    this.edit = true
                }
            },
            onChange(e) {
                this.edit = false
                this.$emit('update', e.target.value)
            }
        }
    }
</script>

<style scoped></style>
