import ProjectSelector from '@/components/Project/ProjectSelector'
import { ROLES } from '@/config/roles'

const metaGroup = {
    roles: [ROLES.PROSPECT, ROLES.PARTNER, ROLES.AGENCY, ROLES.MANAGER, ROLES.CONSULTANT]
}

export default [
    {
        path: '/client',
        alias: '/suivi.php',
        name: 'Client',
        components: {
            default: () => import('../views/Dashboard/TrackingOrder.vue'),
            projects: ProjectSelector
        },
        props: {
            projects: { allowAll: true }
        },
        meta: metaGroup
    },
    {
        path: '/search',
        alias: '/nextlevel.php',
        name: 'ClientSearch',
        components: {
            default: () => import('../views/Search/HomeSearch.vue'),
            projects: ProjectSelector
        },
        meta: metaGroup
    },
    {
        path: '/rd/buy',
        name: 'BuyRd',
        components: {
            default: () => import('../views/Spots/BuySpot.vue')
        },
        meta: metaGroup
    },
    {
        path: '/rd/orders',
        name: 'MyOrders',
        components: {
            default: () => import('../views/Spots/MySpotsOrders.vue')
        },
        meta: metaGroup
    },
    {
        path: '/search/keyword',
        alias: '/nextlevelform.php',
        name: 'SearchByKeywords',
        components: {
            default: () => import('../views/Search/SearchByKeywords.vue'),
            projects: ProjectSelector
        },
        meta: metaGroup
    },
    {
        path: '/search/domain',
        alias: '/recherche-domaine.php',
        name: 'SearchByDomain',
        components: {
            default: () => import('../views/Search/SearchByDomain.vue'),
            projects: ProjectSelector
        },
        meta: metaGroup
    },
    {
        path: '/search/url',
        alias: '/recherche-url.php',
        name: 'SearchByUrl',
        components: {
            default: () => import('../views/Search/SearchByUrl.vue'),
            projects: ProjectSelector
        },
        meta: metaGroup
    },
    {
        path: '/search/theme',
        alias: '/nextlevelthema.php',
        name: 'ThemeSearch',
        component: () => import('../views/Search/ThemeSearch.vue'),
        meta: metaGroup
    },
    {
        path: '/search/gsc',
        alias: '/gsc.php',
        name: 'GscSearch',
        components: {
            default: () => import('../views/Search/SearchGSC.vue'),
            projects: ProjectSelector
        },
        meta: metaGroup
    },
    {
        path: '/favorites',
        alias: '/favorites.php',
        name: 'Favoris',
        components: {
            default: () => import('../views/Favorites.vue'),
            projects: ProjectSelector
        },
        props: {
            projects: { allowAll: true }
        },
        meta: metaGroup
    },
    {
        path: '/alerts',
        alias: '/alertes.php',
        name: 'Alertes',
        components: {
            default: () => import('../views/Customer/Alerts.vue')
        },
        meta: metaGroup
    },
    {
        path: '/projects',
        alias: '/projets.php',
        name: 'Projects',
        components: {
            default: () => import('../views/Projects.vue')
        },
        meta: metaGroup
    },
    {
        path: '/client/preorder/new',
        alias: '/commande.php',
        name: 'PreorderNew',
        components: {
            default: () => import('../views/Commande.vue'),
            projects: ProjectSelector
        },
        props: {
            allowAll: false
        },
        meta: metaGroup
    },
    {
        path: '/client/preorder',
        alias: '/precommande.php',
        name: 'Precommande',
        components: {
            default: () => import('../views/Precommande.vue'),
            projects: ProjectSelector
        },
        props: {
            projects: { allowAll: true }
        },
        meta: metaGroup
    }
]
