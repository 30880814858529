import moment from 'moment'

import ApiService from '../../../services/api.service'

function prepareParams(config) {
    let field = config.sort

    if (config.sortDirection === 'desc') {
        field = '-' + field
    }

    let params = {
        page: config.page,
        include: config.include,
        sort: field,
        per_page: config.per_page
    }

    if (config.filters) {
        for (const [key, value] of Object.entries(config.filters)) {
            params[`filter[${key}]`] = value
        }
    }

    return params
}

export default {
    getReports(context) {
        return new Promise((resolve, reject) => {
            let params = prepareParams(context.state.reportsConfig)
            let dateFrom = moment(context.state.date_from).format('YYYY-MM-DD')
            let dateTo = moment(context.state.date_to).format('YYYY-MM-DD')
            if (context.state.date_from) {
                params[`filter[date_from]`] = dateFrom
            }
            if (context.state.date_to) {
                params[`filter[date_to]`] = dateTo
            }
            ApiService.get(`/admin/orders/report`, params)
                .then(({ data }) => {
                    context.commit('setReports', data.reports)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
