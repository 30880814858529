<template>
    <div v-if="file">
        <div v-if="file.loading" class="">Téléchargement de "{{ originalFilename }}"" {{ file.percent }}%</div>
        <div v-if="file.model" class="">
            <a :href="file.model.url" target="_blank">{{ originalFilename }}</a>
            <span @click="$emit('delete')">X</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            file: null
        }),
        computed: {
            originalFilename() {
                return this.file.importFilename
            }
        },
        watch: {
            value: {
                handler() {
                    this.file = Object.assign({}, this.value)
                },
                deep: true
            }
        },
        mounted() {
            this.file = Object.assign({}, this.value)
        }
    }
</script>

<style></style>
