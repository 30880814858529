<template>
    <div>
        <div class="d-flex align-items-center">
            <h4 class="">
                {{ $d(Date.parse(content.period), 'month') }}
            </h4>
            <div class="ml-auto p-3 text-white" :class="[budgetIsExpended ? 'bg-success' : 'bg-warning']">
                Budget Dépensé : {{ content.depense }} &euro; / {{ content.budget }} &euro;
            </div>
        </div>
        <div v-if="content.orders.length > 0">
            <div ref="collapse" class="collapse">
                <contents-table
                    :contents="content.orders"
                    with-url-information
                    :with-edit-button="!readonly"
                    :readonly="readonly"
                    @edit="$emit('edit-content', $event)"
                />
            </div>
            <div class="mt-1 mb-3">
                <b-button type="is-dark" @click="showOrders">
                    {{ $t(showDetails ? 'global.hide' : 'mes-accompagnements.seeorders') }}
                    ({{ content.orders.length }})
                </b-button>
            </div>
        </div>
        <div v-else>
            <p>{{ $t('mes-accompagnements.noorder') }}.</p>
        </div>
        <hr />
    </div>
</template>

<script>
    import ContentsTable from '../Content/Table'

    const IS_EXPENDED_LIMIT = 40 // à partir de 40€ restant on considère le budget dépensé (GH : #113)
    export default {
        components: { ContentsTable },
        props: {
            content: {
                default: []
            },
            readonly: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                showDetails: false
            }
        },
        computed: {
            budgetIsExpended: function () {
                return parseInt(this.content.budget) - parseInt(this.content.depense) < IS_EXPENDED_LIMIT
            }
        },
        methods: {
            showOrders: function () {
                this.showDetails = !this.showDetails
                $(this.$refs.collapse).collapse('toggle')
            }
        }
    }
</script>

<style scoped></style>
