<template>
    <div>
        <b-tooltip
            label="Veuillez selectionner un consultant offsite"
            type="is-danger"
            position="is-top"
            :active="!campaign.consultants.offSite"
        >
            <b-button :disabled="!campaign.consultants.offSite" type="is-link is-warning" @click="openModal">
                <b-icon icon="fas fa-envelope" />
            </b-button>
        </b-tooltip>
        <nl-modal
            :title="`Mail de rapport mensuel du ${moment(period).format('DD/MM/YYYY')}`"
            :open-modal="showModal"
            @hidden="showModal = false"
        >
            <div v-if="!showPreview || !preview">
                <form @submit.prevent="">
                    <div class="mb-2 d-flex align-items-center">
                        <h3>Email de rapport mensuel</h3>
                    </div>

                    <div class="row mb-2">
                        <div class="col-md-6">
                            <b-field label="Date du rapport">
                                <b-datepicker
                                    v-model="period"
                                    disabled
                                    type="month"
                                    locale="fr-FR"
                                    icon="calendar"
                                    :date-formatter="date => moment(date).format('MMMM YYYY')"
                                    trap-focus
                                    required
                                    :min-date="startDate"
                                    :max-date="endDate"
                                />
                            </b-field>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="label">Destinataire</label>
                                <input v-model="monthlyReportMail.recipient_email" class="form-control" type="email" />
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="label">Émetteur</label>
                                <input v-model="monthlyReportMail.sender_email" class="form-control" type="email" />
                            </div>
                        </div>
                    </div>

                    <div class="mb-2">
                        <div class="form-group">
                            <label class="label">Destinataires en copie (optionnel)</label>
                            <input v-model="monthlyReportMail.cc" class="form-control" type="email" multiple />
                            <small class="text-muted">Séparer les destinataires par une virgule</small>
                        </div>
                    </div>

                    <div class="mb-2">
                        <div class="form-group">
                            <label class="label">Destinataires en copie cachée (optionnel)</label>
                            <input v-model="monthlyReportMail.bcc" class="form-control" type="email" multiple />
                            <small class="text-muted">Séparer les destinataires par une virgule</small>
                        </div>
                    </div>

                    <div class="mb-2">
                        <div class="form-group">
                            <label class="label">Choix des dates d'évolutions des positions</label>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="label" for="date_from">Depuis</label>
                                    <input
                                        id="date_from"
                                        v-model="monthlyReportMail.date_from"
                                        type="date"
                                        class="form-control"
                                        required
                                        :min="startDate"
                                        :max="endDate"
                                    />
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="label" for="date_to">Jusqu'à</label>
                                    <input
                                        id="date_to"
                                        v-model="monthlyReportMail.date_to"
                                        type="date"
                                        class="form-control"
                                        required
                                        :min="startDate"
                                        :max="endDate"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-2">
                        <b-switch v-model="monthlyReportMail.needMoreCredit" :left-label="true">
                            Besoins d'ajout de crédit pour le mois prochain ?
                        </b-switch>
                    </div>

                    <div class="mb-2">
                        <div class="form-group">
                            <label class="label">Introduction du mail</label>
                            <ckeditor v-model="mailParts.intro" :config="editorConfig" :editor="editor" />
                        </div>
                    </div>

                    <div class="mb-2">
                        <div class="row">
                            <div class="form-group col-md-8">
                                <h4 class="h5">Mots clés travaillés à afficher (avec positons monitorank)</h4>
                                <p class="small text-muted">
                                    Seuls les mots clés séléctionnés seront affichés dans l'email.
                                </p>
                            </div>
                            <div class="form-group col-md-4 text-right">
                                <button class="button is-small is-warning" @click="fetchCampaignPosition">
                                    Récupérer les variations
                                </button>
                            </div>
                        </div>

                        <b-table :data="keywords.data" checkable :checked-rows.sync="checkedWorkedRows">
                            <b-table-column centered field="keywords" label="Requêtes" cell-class="align-middle">
                                <div slot="default" slot-scope="props">
                                    <span>{{ props.row.mot_cle }}</span>
                                </div>
                            </b-table-column>

                            <b-table-column centered field="url" label="URL à pousser" cell-class="align-middle">
                                <div slot="default" slot-scope="props">
                                    <span>{{ props.row.url }}</span>
                                </div>
                            </b-table-column>

                            <b-table-column
                                centered
                                field="position"
                                label="Position initiale"
                                cell-class="align-middle"
                            >
                                <div slot="default" slot-scope="props">
                                    <span>{{ props.row.position_initiale }}</span>
                                </div>
                            </b-table-column>

                            <b-table-column centered field="variation" label="Variations" cell-class="align-middle">
                                <div slot="default" slot-scope="props">
                                    <span v-if="props.row.variations" style="white-space: nowrap">
                                        {{ props.row.variations.first_day }} ->
                                        {{ props.row.variations.last_day }}
                                        ({{ props.row.variations.first_day - props.row.variations.last_day }})
                                    </span>
                                </div>
                            </b-table-column>
                        </b-table>
                    </div>

                    <div class="mb-2">
                        <div class="form-group">
                            <label class="label">Ajouter une capture d'écran (optionnel)</label>
                            <b-upload v-model="monthlyReportMail.screen_file" accept="image/*" class="file-label">
                                <span class="file-cta">
                                    <b-icon class="file-icon" icon="upload" />
                                    <span class="file-label">Téléverser un fichier</span>
                                </span>
                                <span v-if="monthlyReportMail.screen_file" class="file-name">
                                    {{ monthlyReportMail.screen_file.name }}
                                </span>
                            </b-upload>

                            <p class="small mb-0">
                                Aller sur
                                <a :href="`https://app.seobserver.com/sites/view/${formattedDomain}`" target="_blank"
                                    >Seobserver</a
                                >
                            </p>
                        </div>
                    </div>

                    <div class="mb-2">
                        <div class="form-group">
                            <label class="label">Ajouter des informations supplémentaires</label>
                            <ckeditor v-model="monthlyReportMail.notes" :config="editorConfig" :editor="editor" />
                        </div>
                    </div>

                    <div class="mb-2">
                        <div class="form-group">
                            <label class="label">Ajouter un fichier en pièce jointe (optionnel)</label>
                            <b-upload v-model="monthlyReportMail.file" class="file-label">
                                <span class="file-cta">
                                    <b-icon class="file-icon" icon="upload" />
                                    <span class="file-label">Téléverser un fichier</span>
                                </span>
                                <span v-if="monthlyReportMail.file" class="file-name">
                                    {{ monthlyReportMail.file.name }}
                                </span>
                            </b-upload>
                        </div>
                    </div>

                    <div class="mb-2">
                        <h4 class="h5">Mots clés à travailler</h4>
                        <p class="small text-muted">Seuls les mots clés séléctionnés seront affichés dans l'email.</p>

                        <b-table :data="keywords.data" checkable :checked-rows.sync="checkedRows">
                            <b-table-column centered field="keywords" label="Requêtes" cell-class="align-middle">
                                <div slot="default" slot-scope="props">
                                    <span>{{ props.row.mot_cle }}</span>
                                </div>
                            </b-table-column>

                            <b-table-column centered field="url" label="URL à pousser" cell-class="align-middle">
                                <div slot="default" slot-scope="props">
                                    <span>{{ props.row.url }}</span>
                                </div>
                            </b-table-column>
                        </b-table>
                    </div>

                    <div class="mb-2">
                        <div class="form-group">
                            <label class="label">Fin du mail</label>
                            <ckeditor v-model="mailParts.footer" :config="editorConfig" :editor="editor" />
                        </div>
                    </div>

                    <div class="mb-2 d-flex flex-column flex-md-row justify-content-end">
                        <button class="button is-light" @click="showModal = false">Annuler</button>
                        <button
                            class="button is-dark mr-2 my-md-0 mx-md-2"
                            type="submit"
                            :disabled="!checkedRows.length && !checkedWorkedRows.length"
                            @click="onSubmit(false)"
                        >
                            Enregistrer
                        </button>
                        <button
                            class="button is-info my-2 my-md-0 mx-md-2"
                            type="submit"
                            :disabled="!checkedRows.length && !checkedWorkedRows.length"
                            @click="getPreview()"
                        >
                            Prévisualiser
                        </button>
                        <button
                            class="button is-warning"
                            type="submit"
                            :disabled="!checkedRows.length && !checkedWorkedRows.length"
                            @click="onSubmit(true)"
                        >
                            Enregistrer et envoyer
                        </button>
                    </div>
                </form>
            </div>
            <div v-else>
                <iframe :srcdoc="preview" frameborder="0" class="w-100" style="min-height: 100vh" />
                <div class="d-flex justify-content-center py-5">
                    <b-button type="is-warning" @click="showPreview = false"> Fermer la prévisualisation</b-button>
                </div>
            </div>
        </nl-modal>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
    import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
    import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
    import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
    import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
    import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
    import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
    import BlockquotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote'
    import IndentPlugin from '@ckeditor/ckeditor5-indent/src/indent'
    import ListPlugin from '@ckeditor/ckeditor5-list/src/list'

    import Image from '@ckeditor/ckeditor5-image/src/image'
    import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
    import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
    import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
    import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize'
    import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage'
    import NlModal from '../UI/NlModal'
    import moment from 'moment'

    export default {
        setup() {
            return {
                moment
            }
        },
        components: { NlModal },
        props: {
            campaign: {
                type: Object,
                required: true
            },
            period: {
                required: true
            }
        },

        data: function () {
            return {
                // Mail data
                monthlyReportMail: {
                    recipient_email: this.campaign.customer.email,
                    sender_email: this.campaign.consultants.offSite.email,
                    cc: null,
                    bcc: this.campaign.consultants.offSite.email,
                    information_credits: null,
                    offered_credits: null,
                    notes: null,
                    needMoreCredit: false,
                    date_from: this.moment(this.period).startOf('month').format('yyyy-MM-DD'),
                    date_to: this.moment(this.period).endOf('month').format('yyyy-MM-DD'),
                    month_report_date: this.moment(this.period).startOf('month').format('yyyy-MM-DD')
                },

                // Mail custom text
                mailParts: {
                    intro: null,
                    footer: null
                },

                currentKeywords: [],

                checkedRows: [],
                checkedWorkedRows: [],

                formattedDomain: '',

                positions: [],

                loading: false,

                showModal: false,
                showPreview: false,
                preview: null,

                //CKEditor
                editor: ClassicEditor,
                ckContent: null, //represent DOM element - see mounted hook
                editorConfig: {
                    removePlugins: ['MediaEmbedToolbar'],
                    copyFormatting_allowedContexts: true,
                    htmlEncodeOutput: false,
                    fullPage: true,
                    basicEntities: false,
                    editorDisabled: false,
                    plugins: [
                        EssentialsPlugin,
                        BoldPlugin,
                        ItalicPlugin,
                        LinkPlugin,
                        ParagraphPlugin,
                        HeadingPlugin,
                        BlockquotePlugin,
                        IndentPlugin,
                        ListPlugin,
                        Image,
                        ImageCaption,
                        ImageToolbar,
                        ImageStyle,
                        ImageResize,
                        LinkImage
                    ],
                    toolbar: {
                        items: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            '|',
                            'link',
                            '|',
                            'outdent',
                            'indent',
                            '|',
                            'bulletedList',
                            'numberedList',
                            '|',
                            'undo',
                            'redo'
                        ]
                    },
                    heading: {
                        options: [
                            {
                                model: 'paragraph',
                                title: 'Paragraphe',
                                class: 'ck-heading_paragraph'
                            },
                            {
                                model: 'heading1',
                                view: 'h1',
                                title: 'Titre 1',
                                class: 'h1'
                            },
                            {
                                model: 'heading2',
                                view: 'h2',
                                title: 'Titre 2',
                                class: 'h2'
                            },
                            {
                                model: 'heading3',
                                view: 'h3',
                                title: 'Titre 3',
                                class: 'h3'
                            }
                        ]
                    }
                }
            }
        },

        methods: {
            ...mapActions('campaigns', ['getCampaignPosition', 'getMailPreview', 'saveMonthlyReportMail']),
            onSubmit(mustBeSent = false) {
                this.$emit('loading', true)

                var formData = new FormData()
                Object.keys(this.monthlyReportMail).forEach(item => {
                    if (this.monthlyReportMail[item]) {
                        formData.append(item, this.monthlyReportMail[item])
                    }
                })

                let keywordsId = this.checkedRows.map(row => row.id)
                let workedKeywordsId = this.checkedWorkedRows.map(row => row.id)

                formData.append('keywords', keywordsId)
                formData.append('worked_keywords', workedKeywordsId)
                formData.append('must_be_sent', mustBeSent)
                formData.append('intro', this.mailParts.intro)
                formData.append('footer', this.mailParts.footer)

                this.saveMonthlyReportMail(formData)
                    .catch(e => console.error(e))
                    .finally(() => {
                        this.showModal = false
                        this.$emit('loading', false)
                    })
            },

            getFormattedDomain() {
                this.formattedDomain = this.campaign.information.url.replace(/(^\w+:|^)\/\//, '')
            },

            getMailParts() {
                this.loading = true
                this.axios.get('/config/monthlyReport').then(response => {
                    this.mailParts.intro = response.data.firstPart
                    this.mailParts.footer = response.data.secondPart
                })
            },

            fetchCampaignPosition() {
                this.getCampaignPosition({
                    accompagnementId: this.campaign.id,
                    dateFrom: this.monthlyReportMail.date_from,
                    dateTo: this.monthlyReportMail.date_to
                }).then(data => {
                    this.positions = data
                    this.positions.forEach(item => {
                        let index = this.keywords.findIndex(keyword => {
                            return item.type === 'type_natural' && keyword.mot_cle === item.keywords
                        })

                        if (index >= 0) {
                            let keyword = this.keywords[index]
                            keyword.variations = item.rank

                            this.keywords.splice(index, 1, keyword)
                        }
                    })

                    this.positions = this.positions.filter(e => e.type === 'type_natural')
                })
            },
            getPreview() {
                this.getMailPreview({
                    campaignId: this.campaign.id,
                    type: 'monthly-report',
                    data: {
                        ...this.monthlyReportMail,
                        keywords: this.checkedRows.map(row => row.id),
                        worked_keywords: this.checkedWorkedRows.map(row => row.id),
                        intro: this.mailParts.intro,
                        footer: this.mailParts.footer
                    }
                }).then(res => {
                    this.preview = res
                    this.showPreview = true
                })
            },
            openModal() {
                this.getMailParts()
                this.showModal = true
            }
        },

        created() {
            this.getFormattedDomain()
        },
        computed: {
            ...mapState('campaigns', {
                keywords: state => state.keywordsWorkedOn
            }),
            startDate() {
                return this.moment(this.campaign.information.start_at).startOf('month').toDate()
            },
            endDate() {
                return this.moment(this.campaign.information.end_at).endOf('month').toDate()
            }
        }
    }
</script>

<style>
    body {
        --ck-z-default: 100;
        --ck-z-modal: calc(var(--ck-z-default) + 999);
    }

    .dropdown-item:hover {
        background: #fff;
    }
</style>
