<template>
    <div>
        <div v-if="type == null" class="w-100 d-flex">
            <div class="px-2 d-flex align-items-center w-100 justify-content-end">
                <input
                    v-model="perPage"
                    type="number"
                    class="form-control w-25"
                    @change="loadAsyncData"
                    @keypress.enter="loadAsyncData"
                />
                <p class="control ml-2 mt-2 mb-2">
                    {{ $t('global.per-page') }}
                </p>
            </div>
        </div>
        <b-table
            :data="type === 'campaign' || type === 'site' ? preordersArray.data : preorders.data"
            :per-page="type === 'campaign' || type === 'site' ? preordersArray.per_page : preorders.per_page"
            :total="type === 'campaign' || type === 'site' ? preordersArray.total : preorders.total"
            paginated
            backend-pagination
            striped
            :loading="loading"
            :row-class="(row, index) => (row.sold == 1 ? 'sold_row' : row.nl_results == 1 ? 'nl_results_row' : '')"
            @page-change="onPageChange"
        >
            <b-table-column v-slot="props" field="id" label="ID">
                <span>{{ props.row.id }}</span>
            </b-table-column>
            <b-table-column v-slot="props" field="status" label="Statut">
                <span v-if="props.row.status === 'todo'" class="tag is-warning">À faire</span>
                <span v-else-if="props.row.status === 'in_progress'" class="tag is-info">En cours</span>
                <span v-else-if="props.row.status === 'done'" class="tag is-success">Fait</span>
                <span v-else class="tag is-dark">Erreur</span>
            </b-table-column>
            <b-table-column v-slot="props" field="parent_thematic" label="Thématique">
                <span v-if="props.row.group_id">{{ findGroup(props.row) }}</span>
                <span v-else>-</span>
            </b-table-column>
            <b-table-column v-if="type == null || type == 'site'" v-slot="props" field="project" label="Projet">
                <span v-if="props.row.project_name">{{ props.row.project_name }}</span>
                <span v-else>-</span>
            </b-table-column>
            <b-table-column v-slot="props" field="keyword" label="Mot-Clé">
                <span v-if="props.row.keyword">{{ props.row.keyword }}</span>
                <span v-else>-</span>
            </b-table-column>
            <b-table-column v-slot="props" field="type" label="Qui ?">
                <span v-if="props.row.type">{{ props.row.type }}</span>
                <span v-else>-</span>
            </b-table-column>
            <b-table-column v-slot="props" field="tag_title" label="H1">
                <span v-if="props.row.tag_title">{{ props.row.tag_title }}</span>
                <span v-else>-</span>
            </b-table-column>
            <b-table-column v-slot="props" field="meta_title" label="Meta">
                <span v-if="props.row.meta_title">{{ props.row.meta_title }}</span>
                <span v-else>-</span>
            </b-table-column>
            <b-table-column v-slot="props" field="meta_title" label="Où ?">
                <span v-if="props.row.site">{{ props.row.site.ndd }}</span>
                <span v-else>-</span>
            </b-table-column>
            <b-table-column v-slot="props" field="url" label="URL">
                <span v-if="props.row.url">{{ props.row.url }}</span>
                <span v-else>-</span>
            </b-table-column>
            <b-table-column v-slot="props" field="position_volume" label="Position / Volume">
                <span
                    v-if="props.row.monitorank_volume != null"
                    :class="[
                        props.row.monitorank_position > 1 &&
                        props.row.monitorank_position < 90 &&
                        !props.row.isurlAbove45days
                            ? 'text-success'
                            : 'text-danger'
                    ]"
                >
                    {{ props.row.monitorank_position }}
                    /
                    {{
                        props.row.monitorank_volume === 0
                            ? props.row.monitorank_volume + 10
                            : props.row.monitorank_volume
                    }}
                    <button v-if="props.row.url" class="btn btn-sm btn-light ml-1" @click="copyKeyword(props.row)">
                        <i class="far fa-copy" aria-hidden="true" /></button
                ></span>
                <span v-else>-</span>
            </b-table-column>
            <b-table-column v-slot="props" field="nl_results" label="Dispo NL">
                <span v-if="props.row.nl_results" class="text-success">Oui</span>
                <span v-else class="text-danger">Non</span>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'PreorderStatisticPreorderTable',
        props: {
            preorders: {
                type: [],
                required: false
            },
            type: {
                type: String,
                required: false
            },
            id: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                preordersArray: [],
                loading: false,
                perPage: 20,
                page: 1
            }
        },
        created() {
            if (this.preorders === undefined) {
                this.getData()
            }
        },
        methods: {
            ...mapActions('statistics_preorders', ['getPreordersByType', 'loadAsyncSubTableData']),
            getData() {
                this.loading = true

                this.getPreordersByType({ type: this.type, id: this.id })
                    .then(response => {
                        this.preordersArray = response.preorders
                    })
                    .catch(error => {
                        this.$store.dispatch('toastError', error)
                        console.log(error)
                    })
                    .finally(() => (this.loading = false))
            },
            findGroup(row) {
                return row.group.translations.find(translation => translation.lang == 'fr').name
            },
            loadAsyncData(data) {
                this.$emit('loadAsyncData', {
                    page: this.page,
                    sortField: this.sortField,
                    sortOrder: this.sortOrder,
                    filters: this.filters,
                    perPage: this.perPage
                })
            },
            loadAsyncSubTable(data) {
                this.loading = true

                data.type = this.type
                data.id = this.id
                this.loadAsyncSubTableData(data)
                    .then(response => {
                        this.preordersArray = response.preorders
                    })
                    .catch(error => {
                        this.$store.dispatch('toastError', error)
                        console.log(error)
                    })
                    .finally(() => (this.loading = false))
            },
            onFilterChange(filter) {
                var filters = []
                Object.keys(filter).forEach(element => {
                    var currentFilter = {
                        key: element,
                        value: filter[element]
                    }
                    filters.push(currentFilter)
                })
                this.filters = filters
                this.loadAsyncData()
            },
            onPageChange(page) {
                this.page = page

                if (this.type === 'campaign' || this.type === 'site') {
                    this.loadAsyncSubTable({ page: page })
                } else {
                    this.loadAsyncData()
                }
            },
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            }
        }
    }
</script>

<style scoped>
    .sold_row {
        background-color: #d7fbd7 !important;
    }

    .nl_results_row {
        background-color: #efd78f !important;
    }
</style>
