<template>
    <div v-if="sites !== null">
        <div class="ml-auto w-25 d-flex align-items-center justify-content-end">
            <label>Élements par pages</label>
            <input
                type="number"
                class="form-control w-25 ml-2"
                v-model="config.per_page"
                style="text-align: right"
                @keypress.enter="loadAsyncData"
                :disabled="isLoading === true"
            />
        </div>
        <b-table
            :data="sites.sites.data"
            :mobile-cards="true"
            :paginated="true"
            :debounce-search="700"
            :current-page.sync="config.page"
            :loading="isLoading"
            :default-sort="[config.sort, config.sortDirection]"
            :total="sites.sites.total"
            :per-page="config.per_page"
            backend-sorting
            backend-pagination
            backend-filtering
            :row-class="(row, index) => (row.already_preordered > 0 ? 'preordered_row' : '')"
            @page-change="onPageChange"
            @sort="onSort"
            @filters-change="onFilterChange"
        >
            <b-table-column field="id" label="ID" sortable>
                <template v-slot="props">
                    #{{ props.row.id }}
                    <span class="tag is-success" v-if="isNew(props.row.created_at)">New</span>
                </template>
            </b-table-column>
            <b-table-column field="ndd" label="NDD" searchable sortable>
                <template v-slot="props">
                    {{ props.row.ndd }}
                </template>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="ndd"
                        v-model="props.filters[props.column.field]"
                    />
                </div>
            </b-table-column>
            <b-table-column field="group_name" label="Thème" searchable>
                <div slot-scope="props" slot="default">
                    {{ findGroupName(props.row.group_id) }}
                </div>
                <div slot="searchable" slot-scope="props">
                    <select class="form-control form-control-sm" v-model="props.filters['group_id']">
                        <option :value="props.filters[props.column.field]">Tous</option>
                        <option :value="g.id" v-for="g in groups" :key="g.id">
                            {{ g.name }}
                        </option>
                    </select>
                </div>
            </b-table-column>
            <b-table-column field="partenaire_id" label="Propriétaire" cell-class="align-middle" searchable centered>
                <div slot-scope="props" slot="default" class="text-center">
                    <span class="tag is-success p-2 text-center" v-if="props.row.partenaire_id !== null"
                        >Partenaire</span
                    >
                    <span class="tag is-warning text-dark p-2 text-center" v-else>NextLevel</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <select class="form-control form-control-sm" v-model="props.filters['is_partner']">
                        <option :value="props.filters[props.column.field]">Tous</option>
                        <option value="0">NL</option>
                        <option value="1">Partenaire</option>
                    </select>
                </div>
            </b-table-column>
            <b-table-column field="partner.user" label="Email" searchable>
                <div slot-scope="props" slot="default">
                    <span v-if="props.row.partner !== null">{{ props.row.partner.user }}</span>
                    <span v-else>-</span>
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Email"
                        v-model="props.filters['partner_email']"
                    />
                </div>
            </b-table-column>
            <b-table-column field="nb_mots_cles_seobserver" label="MC SEOObserver" searchable sortable>
                <div slot-scope="props" slot="default">
                    {{ props.row.nb_mots_cles_seobserver }}
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol" style="display: flex">
                    <input
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm w-50"
                        placeholder="Min."
                        style="width: 50% !important; margin-right: 5px"
                        v-model="props.filters['min_nb_mots_cles_seobserver']"
                    />
                    <input
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm w-50"
                        placeholder="Max."
                        style="width: 50% !important"
                        v-model="props.filters['max_nb_mots_cles_seobserver']"
                    />
                </div>
            </b-table-column>
            <b-table-column field="keywords_count" label="MC NL" searchable sortable>
                <div slot-scope="props" slot="default">
                    {{ props.row.keywords_count }}
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol" style="display: flex">
                    <input
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm w-50"
                        placeholder="Min."
                        style="width: 50% !important; margin-right: 5px"
                        v-model="props.filters['min_keywords_count']"
                    />
                    <input
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm w-50"
                        placeholder="Max."
                        style="width: 50% !important"
                        v-model="props.filters['max_keywords_count']"
                    />
                </div>
            </b-table-column>
            <b-table-column field="orders_count" label="NB de commandes" searchable sortable>
                <div slot-scope="props" slot="default">
                    {{ props.row.orders_count }}
                </div>
                <div slot="searchable" slot-scope="props" class="searchableCol" style="display: flex">
                    <input
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm w-50"
                        placeholder="Min."
                        style="width: 50% !important; margin-right: 5px"
                        v-model="props.filters['min_orders_count']"
                    />
                    <input
                        type="number"
                        step="0.01"
                        class="form-control form-control-sm w-50"
                        placeholder="Max."
                        style="width: 50% !important"
                        v-model="props.filters['max_orders_count']"
                    />
                </div>
            </b-table-column>
            <b-table-column field="preorders_count" label="NB de précommandes" sortable>
                <div slot-scope="props" slot="default">
                    {{ props.row.preorders_count }}
                </div>
            </b-table-column>
            <b-table-column field="wp_data" label="Plugin" searchable>
                <div slot-scope="props" slot="default">
                    <NlWordpress :site-data="props.row" site-type="site" />
                </div>
                <div slot="searchable" slot-scope="props">
                    <select class="form-control form-control-sm" v-model="props.filters['wp_plugin']">
                        <option :value="props.filters[props.column.field]">Tous</option>
                        <option value="0">Sans plugin</option>
                        <option value="1">Avec plugin</option>
                    </select>
                </div>
            </b-table-column>
            <b-table-column label="Choisir ce site" :visible="selected_preorders !== null">
                <div slot-scope="props" slot="default">
                    <a class="btn btn-secondary" type="button" @click="chooseSite(props.row)">
                        <i class="fas fa-plus"></i>
                    </a>
                </div>
            </b-table-column>
        </b-table>
    </div>

    <nl-loader v-else :show="true"></nl-loader>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'AvailableSitesByUser',
        props: {
            groups: {
                type: Array,
                required: true
            },
            selected_preorders: {
                type: Object,
                required: false,
                default: null
            }
        },
        data() {
            return {
                isLoading: false,
                campaignId: null
            }
        },
        mounted() {
            this.campaignId = this.$route.query.id
            this.loadAsyncData()
        },
        computed: {
            ...mapState('sites', {
                config: state => state.sitesAvailablesConfig,
                sites: state => state.sitesAvailables
            })
        },
        methods: {
            ...mapActions('sites', ['getSitesAvailableByUser']),
            chooseSite(site) {
                this.$emit('siteSelected', site)
                this.$emit('closeModal')
                this.loadAsyncData()
            },
            loadAsyncData() {
                this.isLoading = true
                this.getSitesAvailableByUser(this.campaignId)
                    .then(() => {})
                    .catch(error => {
                        console.log(error)
                        this.$store.dispatch('toastError', error)
                    })
                    .finally(() => {
                        this.isLoading = false
                        this.exportReady = false
                    })
            },
            onSort(field, order) {
                this.config.sort = field
                this.config.sortDirection = order

                this.loadAsyncData()
            },
            onFilterChange(filter) {
                var filters = {}
                Object.keys(filter).forEach(element => {
                    filters[element] = filter[element]
                })
                this.config.filters = filters

                this.loadAsyncData()
            },
            onPageChange(page) {
                this.config.page = page
                this.loadAsyncData()
            },
            findGroupName(group_id) {
                var group = this.groups.find(group => group.id == group_id)
                return group ? group.name : '-'
            },
            isNew(created_at) {
                var date = new Date(created_at)
                var now = new Date()
                var diff = now.getTime() - date.getTime()
                var days = Math.floor(diff / (1000 * 60 * 60 * 24))
                return days < 30
            },
            getWordpressVersion(meta) {
                const metaObject = JSON.parse(meta)
                return metaObject.wp_version ?? ''
            },

            getWordpressNlVersion(meta) {
                const metaObject = JSON.parse(meta)
                return metaObject.plugin_version ?? ''
            }
        }
    }
</script>

<style>
    .preordered_row {
        background-color: #efd78f !important;
    }
</style>