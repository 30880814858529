<template>
    <span>
        <b-button
            href="#"
            class="rounded button is-warning rounded-lg text-decoration-none mr-1"
            @click.prevent="$refs.modal.show()"
        >
            <i class="fas fa-plus-circle mr-2" />
            Ajouter un client
        </b-button>

        <CreateClientModal ref="modal" @hide="$emit('reload')" />
    </span>
</template>

<script>
    import CreateClientModal from '../Modal/CreateClientModal'

    export default {
        name: 'CreateAgencyClientButton',
        components: {
            CreateClientModal
        }
    }
</script>
