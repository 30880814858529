<template>
    <nl-modal ref="modal" title="Créer un nouveau client" :add-legacy-events="false" position="right" size="lg">
        <template v-slot:header>
            <div class="bg-antracite text-white w-100 text-center p-4">
                <h1>Créer un nouveau client</h1>
            </div>
        </template>

        <div>
            <CreateClientForm ref="createClientForm" />
            <div class="text-center">
                <button class="btn-action btn-third mt-2 mb-2 mb-xl-4" :disabled="loading" @click.prevent="save">
                    <i v-if="loading" class="fas fa-circle-notch fa-spin mr-1" />
                    Ajouter un client
                </button>
            </div>
        </div>

        <template v-slot:footer>
            <div class="d-flex w-100 justify-content-start">
                <b-button type="button" class="btn btn-danger" @click="$refs.modal.hide()"> Annuler</b-button>
            </div>
        </template>
    </nl-modal>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import AgencyModal from './AgencyModal'
    import CreateClientForm from '../Form/CreateClientForm'

    export default {
        name: 'CreateClientModal',
        components: {
            CreateClientForm
        },
        mixins: [AgencyModal],
        data: () => ({
            loading: false
        }),
        computed: {
            ...mapState('consultants', ['newClient'])
        },
        methods: {
            ...mapActions('consultants', ['createClient']),
            show() {
                this.reset()
                this.$refs.createClientForm.resetResponsible()

                this.$refs.createClientForm.doSearchConsultant()
                this.$refs.createClientForm.doSearchManagers()

                this.$emit('show')
                this.$refs.modal.show()
            },
            save() {
                this.loading = true
                let budget = this.newClient.budget_initial !== null ? this.newClient.budget_initial : 0
                this.createClient()
                    .then(() => {
                        this.reset()
                        this.hide()
                        this.$store.commit('auth/subCredits', budget)
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            reset() {
                this.$store.commit('consultants/setNewClient', {
                    email: null,
                    lastname: null,
                    firstname: null,
                    password: null,
                    budget_initial: null,
                    responsible_id: null,
                    responsible_type: 5
                })
            }
        },
        mounted() {}
    }
</script>
