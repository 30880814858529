<template>
    <b-field label="Utilisateur" class="w-100">
        <b-autocomplete
            v-model="filter"
            placeholder="contact@nextlevel.link"
            :data="users"
            field="email"
            expanded
            :open-on-focus="true"
            :loading="isLoading"
            :clearable="true"
            @select="option => $emit('changeUser', option ? option.id : null)"
            @typing="getAsyncUsers"
        >
            <template slot-scope="props">
                {{ props.option.firstname }} {{ props.option.lastname }}
                <br />
                <div class="font-italic">
                    <small><i class="fa fa-at" /> {{ props.option.email }}</small>
                </div>
            </template>
        </b-autocomplete>
    </b-field>
</template>

<script>
    import { mapActions } from 'vuex'
    import debounce from 'lodash/debounce'

    export default {
        name: 'UserSelector',
        data() {
            return {
                filter: '',
                isLoading: false,
                users: null
            }
        },
        methods: {
            ...mapActions('users', ['getCustomers']),
            getAsyncUsers: debounce(function () {
                this.isLoading = true
                this.getCustomers({
                    filters: this.filter.split(' ').filter(e => e !== null)
                })
                    .then(data => {
                        this.users = data.data
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }, 500)
        }
    }
</script>

<style scoped>
    /deep/ .dropdown-menu,
    .dropdown-content {
        padding: 0 !important;
        border: none;
    }
</style>
