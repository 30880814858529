<template>
    <div ref="modal" class="modal fade" :tabindex="tabIndex" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div
            class="modal-dialog"
            :class="{
                'modal-xxl': size === 'xxl',
                'modal-xl': size === 'xl',
                'modal-lg': size === 'lg',
                'modal-sm': size === 'sm',
                'modal-right': position === 'right'
            }"
        >
            <div class="modal-content" :class="[position === 'right' ? 'content-right' : '']">
                <div v-if="$slots.header" class="modal-header">
                    <slot name="header" />
                </div>
                <div v-else class="modal-header">
                    <h5 id="exampleModalLabel" class="modal-title">
                        {{ title }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" :class="{ 'p-0': noPaddingBody }">
                    <slot />
                </div>
                <div v-if="$slots.footer" class="modal-footer">
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                required: true
            },
            size: {
                default: null
            },
            noPaddingBody: {
                default: false
            },
            openModal: {
                default: false,
                type: Boolean
            },
            tabIndex: {
                type: String,
                default: '-1'
            },
            addLegacyEvents: {
                type: Boolean,
                default: true
            },
            position: {
                type: String,
                default: ''
            }
        },
        watch: {
            openModal: function (isOpen) {
                if (isOpen) {
                    $(this.$refs.modal).modal('show')
                } else {
                    $(this.$refs.modal).modal('hide')
                }
            }
        },
        mounted() {
            if (this.addLegacyEvents) {
                var vm = this
                $(this.$refs.modal)
                    .modal({
                        backdrop: 'static',
                        show: this.openModal
                    })
                    .on('show.bs.modal', function () {
                        vm.$emit('show')
                    })
                    .on('shown.bs.modal', function () {
                        vm.$emit('shown')
                    })
                    .on('hide.bs.modal', function () {
                        vm.$emit('hide')
                    })
                    .on('hidden.bs.modal', function () {
                        vm.$emit('hidden')
                    })
            }
        },
        methods: {
            show() {
                $(this.$refs.modal).modal('show')
            },
            hide() {
                $(this.$refs.modal).modal('hide')
            }
        }
    }
</script>

<style scoped>
    .modal-right {
        position: fixed;
        margin: auto;
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        right: -50px;
        padding: 0;
    }

    .content-right {
        height: 100%;
        max-height: 100%;
    }

    .modal.fade .modal-dialog.modal-right {
        transform: translateY(0) !important;
    }

    .modal.fade.show .modal-dialog.modal-right {
        transition: transform 0.3s ease-out;
        transform: translate(-50px, 0) !important;
    }

    /* .modal.fade .modal-dialog.modal-right {
    transform: translate(0, 0);
} */
</style>
