<template>
    <div class="overflow-x-auto box">
        <div class="d-flex justify-content-between">
            <b-field grouped group-multiline>
                <div class="control">
                    <b-taglist attached>
                        <b-tag type="is-warning" size="is-medium">
                            {{ $t('global.orders') }}
                        </b-tag>
                        <b-tag type="is-dark" size="is-medium">
                            {{ orders.total }}
                        </b-tag>
                    </b-taglist>
                </div>

                <div class="control">
                    <b-taglist attached>
                        <b-tag type="is-warning" size="is-medium">
                            {{ $t('redactor.earnings') }}
                        </b-tag>
                        <b-tag type="is-dark" size="is-medium"> {{ income ? income.toFixed(2) : 0 }}€</b-tag>
                    </b-taglist>
                </div>
            </b-field>
            <b-select v-model="perPage" icon="align-center" :disabled="loading" :loading="loading">
                <option :value="25">25</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
                <option :value="250">250</option>
                <option :value="500">500</option>
            </b-select>
        </div>

        <b-table
            :data="orders.data"
            :mobile-cards="true"
            striped
            :loading="loading"
            :per-page="orders.per_page"
            :total="orders.total"
            paginated
            backend-pagination
            backend-sorting
            :debounce-search="400"
            backend-filtering
            @page-change="onPageChange"
            @sort="onSort"
            @filters-change="onFilterChange"
        >
            <b-table-column
                centered
                field="id"
                sortable
                :searchable="isSearchable"
                :label="$t('global.ref')"
                cell-class="align-middle"
            >
                <template #default="props">
                    {{ props.row.id }}
                </template>
                <template #searchable="props">
                    <b-numberinput v-model="props.filters[props.column.field]" :controls="false" />
                </template>
            </b-table-column>

            <b-table-column
                centered
                field="created_at"
                sortable
                :searchable="isSearchable"
                label="Date"
                cell-class="align-middle text-center"
            >
                <template #default="props">
                    {{ moment(props.row.created_at).format('DD/MM/YYYY') }}
                </template>
            </b-table-column>

            <b-table-column
                centered
                field="statut"
                sortable
                :searchable="isSearchable"
                :label="$t('global.status')"
                cell-class="align-middle text-center"
            >
                <template #default="props">
                    <b-tag v-if="props.row.statut === 'a_valider'" type="is-warning" icon="handshake">
                        {{ $t('status.waitingforvalidation') }}
                    </b-tag>
                    <b-tag v-else-if="props.row.statut === 'en_cours'" type="is-info" icon="info-circle">
                        {{ $t('status.publication') }}
                    </b-tag>
                    <b-tag v-else-if="props.row.statut === 'termine'" type="is-success" icon="check-circle">
                        {{ $t('status.published') }}
                    </b-tag>
                    <b-tag v-else-if="props.row.statut === 'refuse'" type="is-danger" icon="ban">
                        {{ $t('status.refused') }}
                    </b-tag>
                    <b-tag v-else-if="props.row.statut === 'annule'" type="is-danger" icon="ban">
                        {{ $t('status.canceled') }}
                    </b-tag>
                    <b-tag v-else type="is-danger" icon="times-circle"> Erreur</b-tag>
                </template>

                <template #searchable="props">
                    <b-select v-model="props.filters[props.column.field]">
                        <option :value="null">
                            {{ $t('precommande.all') }}
                        </option>
                        <option value="a_valider">
                            {{ $t('status.waitingforvalidation') }}
                        </option>
                        <option value="en_cours">
                            {{ $t('status.publication') }}
                        </option>
                        <option value="termine">
                            {{ $t('status.published') }}
                        </option>
                        <option value="erreur">
                            {{ $t('status.refused') }}
                        </option>
                        <option value="annule">
                            {{ $t('status.canceled') }}
                        </option>
                    </b-select>
                </template>
            </b-table-column>

            <b-table-column
                centered
                field="content_lang"
                :searchable="isSearchable"
                :label="$t('language.title')"
                cell-class="align-middle text-center"
            >
                <template #default="props">
                    <flag class="rounded shadow-sm" :iso="props.row.content_lang == 'fr_fr' ? 'fr' : 'us'" />
                </template>

                <template #searchable="props">
                    <b-select v-model="props.filters[props.column.field]">
                        <option :value="null">
                            {{ $t('precommande.all') }}
                        </option>
                        <option value="fr_fr">
                            {{ $t('language.French') }}
                        </option>
                        <option value="com_en">
                            {{ $t('language.English') }}
                        </option>
                    </b-select>
                </template>
            </b-table-column>
            <b-table-column
                field="buyer_email"
                sortable
                :searchable="isSearchable"
                label="Client"
                cell-class="align-middle"
            >
                <template v-slot:default="props">
                    {{ props.row.buyer_email }}
                </template>
            </b-table-column>

            <b-table-column
                field="bought_kw"
                sortable
                :searchable="isSearchable"
                :label="$t('global.keyword')"
                cell-class="align-middle text-center"
            >
                <template v-slot:default="props">
                    {{ props.row.bought_kw }}
                </template>
            </b-table-column>

            <b-table-column
                field="partner.email"
                sortable
                :searchable="isSearchable"
                label="Partenaire"
                cell-class="align-middle text-center"
            >
                <template #default="props">
                    <LoginAs v-if="props.row.partner?.email" :user-id="props.row.id" class="ml-auto">
                        {{ props.row.partner.email }}
                    </LoginAs>
                    <span v-else> - </span>
                </template>
            </b-table-column>

            <b-table-column field="url" :searchable="isSearchable" label="Page" cell-class="align-middle">
                <template #default="props">
                    {{ props.row.url }}
                </template>
            </b-table-column>

            <b-table-column
                field="option_mots"
                sortable
                :searchable="isSearchable"
                :label="$t('global.content')"
                cell-class="align-middle text-center"
            >
                <template #default="props"> {{ props.row.option_mots + 200 }} {{ $t('global.words') }}</template>
            </b-table-column>

            <b-table-column
                field="link_url"
                :searchable="isSearchable"
                :label="$t('redactor.link-anchors')"
                cell-class="align-middle"
            >
                <template #default="props">
                    <p class="mb-0">
                        {{ props.row.link_url }}
                    </p>
                    <p class="mb-0">
                        {{ props.row.link_anchor }}
                    </p>
                    <div v-if="props.row.link_second_url">
                        <p class="mb-0">
                            {{ props.row.link_second_url }}
                        </p>
                        <p class="mb-0">
                            {{ props.row.link_second_anchor }}
                        </p>
                    </div>
                </template>
            </b-table-column>

            <b-table-column
                field="price_paid"
                sortable
                :searchable="isSearchable"
                :label="$t('global.price')"
                cell-class="align-middle text-center"
            >
                <template #default="props"> {{ props.row.price_paid.toFixed(2) }}€</template>
            </b-table-column>

            <b-table-column
                field="sold_nl"
                sortable
                :searchable="isSearchable"
                label="Vente NL"
                cell-class="align-middle text-center"
            >
                <template v-slot:header="{ column }">
                    <b-tooltip label="Commande Nextlevel <24 mois à garder" append-to-body dashed>
                        {{ column.label }}
                    </b-tooltip>
                </template>
                <template #default="props">
                    <span v-if="props.row.sold_nl" class="tag is-success">Oui</span>
                    <span v-else class="tag is-danger">Non</span>
                </template>
                <template #searchable="props">
                    <b-select v-model="props.filters[props.column.field]">
                        <option :value="null">
                            {{ $t('precommande.all') }}
                        </option>
                        <option :value="1">Oui</option>
                        <option :value="0">Non</option>
                    </b-select>
                </template>
            </b-table-column>

            <b-table-column
                field="date_publication_prevue"
                centered
                sortable
                :searchable="isSearchable"
                :label="$t('redactor.publishdate')"
                cell-class="align-middle text-center"
            >
                <template #default="props">
                    {{
                        props.row.date_publication_prevue
                            ? moment(props.row.date_publication_prevue).format('DD/MM/YYYY')
                            : 'ASAP'
                    }}
                </template>
            </b-table-column>

            <b-table-column
                centered
                field="redaction"
                sortable
                :searchable="isSearchable"
                :label="$t('partenaire-site.redaction')"
                cell-class="align-middle text-center"
            >
                <template #default="props">
                    {{ props.row.redaction === 'nextlevel' ? 'Nextlevel' : $t('partenaire-site.partner') }}
                </template>

                <template #searchable="props">
                    <b-select v-model="props.filters[props.column.field]">
                        <option :value="null">
                            {{ $t('precommande.all') }}
                        </option>
                        <option value="nextlevel">Nextlevel</option>
                        <option value="proprietaire">Proprietaire</option>
                    </b-select>
                </template>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'OrdersTable',
        props: {
            orders: {
                type: Object,
                required: true
            },
            income: {
                type: Number,
                required: false
            }
        },

        data() {
            return {
                isSearchable: true,
                perPage: 50,
                page: 1,
                sortField: 'id',
                sortOrder: 'asc',
                filters: {}
            }
        },
        computed: {
            ...mapState('orders', {
                loading: 'isLoading'
            })
        },
        watch: {
            perPage() {
                this.loadAsyncData()
            }
        },
        created() {
            this.loadAsyncData()
        },
        methods: {
            displayKeywords(content) {
                this.keywords = content.keywords.split(',')
                this.keywordsModal = true
            },

            loadAsyncData() {
                let params = {
                    page: this.page,
                    per_page: this.perPage,
                    sort: `${this.sortOrder === 'desc' ? '-' : ''}${this.sortField}`
                }

                Object.keys(this.filters).forEach(keys => {
                    if (this.filters[keys]) {
                        params[`filter[${keys}]`] = this.filters[keys]
                    }
                })

                this.$emit('loadAsyncData', params)
            },

            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order

                this.loadAsyncData()
            },

            onFilterChange(filters) {
                this.filters = filters
                this.loadAsyncData()
            },

            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },

            close() {
                this.keywordsModal = false
            }
        }
    }
</script>
