<template>
    <div class="back-to-top" @click="scrollToTop">
        <i class="fas fa-chevron-up" />
    </div>
</template>

<script>
    export default {
        name: 'BackTopTop',
        methods: {
            scrollToTop() {
                let appContainer = document.getElementsByClassName('app-container')[0]
                appContainer.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .back-to-top {
        position: fixed;
        bottom: 8%;
        right: 2%;
        z-index: 1000;
        cursor: pointer;
        background: #4a4a4a !important;
        color: white;
        padding: 0.5rem 0.8rem;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
    }
</style>
