<template>
    <field v-model="input" class="" :rules="rules" :name="name">
        <template #field="{ fieldValue, onInput, fieldName }">
            <base-checkbox
                :value="fieldValue"
                :name="fieldName"
                :true-value="trueValue"
                :false-value="falseValue"
                @input="event => onInput(event)"
            >
                <slot />
            </base-checkbox>
        </template>
    </field>
</template>

<script>
    import BaseCheckbox from './BaseCheckbox'
    import Field from './Field'

    export default {
        components: {
            BaseCheckbox,
            Field
        },
        props: {
            label: {
                type: String,
                default: ''
            },
            value: {
                type: [Boolean, Number],
                default: 0
            },
            rules: {
                type: [Object, String],
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            trueValue: {
                type: [Boolean, Number],
                default: 1
            },
            falseValue: {
                type: [Boolean, Number],
                default: 0
            }
        },
        computed: {
            input: {
                get() {
                    return this.value
                },
                set(value) {
                    return this.$emit('input', value)
                }
            }
        }
    }
</script>

<style></style>
