<template>
    <form v-if="value" ref="form">
        <div class="row">
            <b-field :label="$t('projets.name')" class="col-12 col-md-6">
                <b-input v-model="value.name" required :validation-message="$t('register.error.mandatory')" />
            </b-field>

            <b-field :label="$t('projets.website')" :message="$t('projets.website-info')" class="col-12 col-md-6">
                <b-input
                    v-model="value.website"
                    pattern="([A-Za-z0-9-]+\.([A-Za-z]{3,}|[A-Za-z]{2}\.[A-Za-z]{2}|[A-za-z]{2}))\b"
                    required
                />
            </b-field>
        </div>
        <div class="row">
            <b-field :label="$t('global.theme')" class="col-12 col-md-6">
                <b-select v-model="value.group_id" expanded>
                    <option v-for="group in groups" :key="group.id" :value="group.id">
                        {{ group.name }}
                    </option>
                </b-select>
            </b-field>
            <b-field :label="$t('projets.budget')" class="col-12 col-md-6">
                <b-input v-model="value.budget" type="number" required />
            </b-field>
        </div>

        <b-field :label="$t('projets.mailrecipients')">
            <b-input v-model="value.destinataires_mails" />
        </b-field>

        <div class="row">
            <b-field
                label="Blacklist"
                :addons="false"
                :message="$t('projets.filteredLinksDesc')"
                class="col-12 col-md-6"
            >
                <div class="d-flex align-items-center flex-wrap mb-2" style="gap: 0.75rem">
                    <b-upload v-model="fileBlacklist" drag-drop accept=".txt" class="m-0">
                        <section class="p-1 d-flex has-text-centered align-items-center" style="gap: 0.75rem">
                            <b-icon icon="upload" type="is-warning" />
                            <span>{{ $t('partenaire-site.selecttxt') }}</span>
                        </section>
                    </b-upload>
                    <b-tag
                        v-if="fileBlacklist?.name"
                        class="m-0"
                        size="is-medium"
                        close-type="is-danger"
                        attached
                        closable
                        gr
                        @close="fileBlacklist = null"
                    >
                        <b-icon icon="file" class="mr-1" />
                        {{ fileBlacklist.name }}
                    </b-tag>
                    <b-button v-if="fileBlacklist?.name" icon-left="save" type="is-warning" @click="addBlacklistFile">
                        {{ $t('global.save') }}
                    </b-button>
                </div>
                <div class="d-flex flex-column w-100" style="gap: 0.75rem">
                    <div class="d-flex align-items-center bg-light p-2">
                        <b-checkbox
                            class="m-0"
                            @input="toggleAllUrls"
                            :indeterminate="
                                Boolean(checkedUrls.length && checkedUrls.length < filteredBlacklist.length)
                            "
                            v-model="checkedAll"
                        />
                        <b-input
                            v-model="urlSearch"
                            size="is-small"
                            rounded
                            :placeholder="$t('global.research')"
                        ></b-input>
                        <b-icon
                            class="ml-auto cursor-pointer"
                            icon="times"
                            type="is-danger"
                            @click.native="handleDelete(checkedUrls)"
                        />
                    </div>
                    <hr class="m-0" />
                    <div class="overflow-auto d-flex" style="flex: 1; max-height: 325px">
                        <div
                            class="d-flex flex-column overflow-y-scroll"
                            style="gap: 0.5rem; min-height: min-content; width: 100%"
                        >
                            <div
                                class="d-flex bg-light p-2"
                                v-for="(url, index) in filteredBlacklist"
                                :key="`url_${index}`"
                            >
                                <b-checkbox :native-value="url.id ?? index" name="url" v-model="checkedUrls" />
                                {{ url.url ?? url }}
                                <b-icon
                                    class="ml-auto cursor-pointer"
                                    icon="times"
                                    type="is-danger"
                                    @click.native="handleDelete([url.id ?? index])"
                                />
                            </div>
                        </div>
                    </div>
                    <hr class="m-0" />
                    <div class="d-flex align-items-center" style="gap: 0.5rem">
                        <b-input
                            class="w-100"
                            size="is-small"
                            @keydown.enter.native="handleBlacklistInput"
                            @paste.native.prevent="handlePasteBlacklistInput"
                            :disabled="pendingBlacklist"
                            v-model="blacklistInput"
                        />
                        <b-button
                            size="is-small"
                            icon-left="save"
                            type="is-warning"
                            @click.native="handleBlacklistInput"
                            :disabled="pendingBlacklist"
                        />
                    </div>
                </div>
            </b-field>
            <b-field
                :label="$t('projets.brieflabel')"
                :message="$t('projets.briefdescription')"
                class="col-12 col-md-6"
            >
                <b-input style="height: 150px" type="textarea" v-model="value.brief" />
            </b-field>
        </div>

        <div class="d-flex justify-content-end">
            <b-button icon-left="save" type="is-warning" :disabled="formValid" @click.prevent="doSave">
                {{ $t('global.save') }}
            </b-button>
        </div>
    </form>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'ProjectForm',
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data: function () {
            return {
                blacklistsUrl: '',
                fileBlacklist: null,
                groups: [],
                urlSearch: '',
                checkedUrls: [],
                pendingBlacklist: false,
                blacklistInput: '',
                checkedAll: false
            }
        },
        created() {
            this.axios.get('/crud/groups?orderBy=name').then(response => {
                this.groups = response.data.groups
            })
            if (!this.value.id) {
                this.$emit('input', {
                    website: '',
                    project_lang: this.user.locale,
                    budget: null,
                    brief: '',
                    status: 1,
                    group_id: 1,
                    destinataires_mails: this.user.email
                })
            }
            this.formValid
        },
        computed: {
            ...mapState('auth', {
                user: state => state.user
            }),
            filteredBlacklist() {
                if (this.urlSearch) {
                    return this.value.blacklist.filter(url => {
                        return url.url.toLowerCase().includes(this.urlSearch.toLowerCase())
                    })
                }
                return this.value.blacklist
            },
            formValid() {
                return (
                    this.value.name == '' ||
                    this.value.website == '' ||
                    this.value.budget === ''
                )
            }
        },
        methods: {
            ...mapActions('projects', ['getBlacklist', 'addBlacklist', 'deleteBlacklist']),
            doSave: function () {
                let isValid = true
                let form = this.$refs.form

                if (form.checkValidity() === false) {
                    isValid = false
                }

                form.classList.add('was-validated')

                if (isValid) {
                    this.$emit('onSave')
                }
            },
            handleBlacklistInput() {
                this.handleAddBlacklist([this.blacklistInput])
                this.blacklistInput = ''
            },
            handlePasteBlacklistInput(event) {
                this.handleAddBlacklist(
                    event.clipboardData
                        .getData('text/plain')
                        .split(/[,;\s\n]/gm)
                        .map(e => e.trim())
                )
            },
            handleAddBlacklist(value) {
                if (!this.value.id) {
                    this.$emit('input', {
                        ...this.value,
                        blacklist: [...(this.value.blacklist ?? []), ...value]
                    })
                } else {
                    this.pendingBlacklist = true
                    this.addBlacklist({
                        id: this.value.id,
                        params: {
                            urls: value
                        }
                    })
                        .then(blacklist => {
                            this.value.blacklist = blacklist
                        })
                        .catch(e => {
                            console.error(e)
                        })
                        .finally(() => {
                            this.pendingBlacklist = false
                        })
                }
            },
            handleDelete(ids) {
                if (!this.value.id) {
                    ids = ids.sort((a, b) => a - b)

                    let blacklist = [...this.value.blacklist]

                    while (ids.length) {
                        blacklist.splice(ids.pop(), 1)
                    }

                    this.$emit('input', {
                        ...this.value,
                        blacklist
                    })

                    this.checkedUrls = []
                    this.checkedAll = false
                } else {
                    this.pendingBlacklist = true
                    this.deleteBlacklist({ id: this.value.id, params: { ids } })
                        .then(blacklist => {
                            this.$emit('input', {
                                ...this.value,
                                blacklist
                            })
                            this.pendingBlacklist = false
                            this.checkedUrls = []
                        })
                        .catch(e => {
                            console.error(e)
                        })
                        .finally(() => {
                            this.pendingBlacklist = false
                        })
                }
            },
            toggleAllUrls(value) {
                if (!value) {
                    this.checkedUrls = []
                } else {
                    this.checkedUrls = this.filteredBlacklist.map((url, index) => url.id ?? index)
                }
            },
            addBlacklistFile() {
                const formData = new FormData()
                formData.append('blacklist', this.fileBlacklist)

                // TODO : Add action
                this.axios
                    .post(`/projects/${this.value.id}/blacklist/file`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(() => {
                        this.$emit('blacklistAdded')
                        this.fileBlacklist = null
                    })
            }
        }
    }
</script>

<style scoped>
    .wrapper {
        max-width: 1024px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        grid-auto-flow: row dense;
        column-gap: 1.625rem;
    }
</style>