<template>
    <b-tooltip position="is-right">
        <template v-slot:content>
            <div class="text-left">
                <div v-for="(step, i) in steps" :key="i">
                    <i class="fas fa-check pr-2" :class="[step.checked ? 'text-success' : 'text-dark']" />
                    {{ step.name }}
                </div>
            </div>
        </template>

        <span class="badge" :class="badgeClass">
            <i
                v-if="content.partenaire_id !== null && ['proprietaire', 'nextlevel'].includes(content.redaction)"
                class="far fa-handshake"
            />
            {{ statusName }}
        </span>
    </b-tooltip>
</template>

<script>
    export default {
        props: {
            content: {
                required: true
            }
        },
        data: function () {
            return {
                badgeClass: '',
                statusName: '',
                steps: []
            }
        },
        watch: {
            content: function (content) {
                this.init(content)
            }
        },
        mounted() {
            this.init(this.content)
        },
        methods: {
            init: function (content) {
                this.initStatusName(content)
                this.initBadge(content)
                this.initSteps(content)
            },

            initStatusName(content) {
                var statusTranslation = ''
                switch (content.statut) {
                    case 'a_valider':
                        statusTranslation = 'waitingforvalidation'
                        break
                    case 'en_cours':
                        if (!content.partenaire_id) {
                            statusTranslation = content.brief_envoye ? 'redaction' : 'accepted'
                        } else if (content.redaction === 'proprietaire') {
                            statusTranslation = 'redaction'
                        } else if (content.redaction === 'nextlevel') {
                            statusTranslation = 'publication'
                            if (!content.brief_envoye) {
                                statusTranslation = 'accepted'
                            } else if (content.contenu_date_envoi) {
                                statusTranslation = 'redaction'
                            }
                        }
                        break
                    case 'termine':
                        statusTranslation = 'published'
                        break
                    case 'annule':
                        statusTranslation = 'canceled'
                        break
                    case 'refuse':
                        statusTranslation = 'refused'
                }
                this.statusName = this.$t('status.' + statusTranslation)
            },

            initBadge(content) {
                switch (content.statut) {
                    case 'a_valider':
                        this.badgeClass = 'badge-warning'
                        break
                    case 'en_cours':
                        this.badgeClass = 'badge-info'
                        break
                    case 'termine':
                        this.badgeClass = 'badge-success'
                        break
                    case 'annule':
                    case 'refuse':
                        this.badgeClass = 'badge-danger'
                        break
                }
            },

            initSteps(content) {
                let steps = []
                let validated = []

                if (!content.partenaire_id) {
                    steps = ['accepted', 'redaction', 'published']
                    switch (content.statut) {
                        case 'en_cours':
                            validated = ['accepted', 'redaction']
                            if (!content.brief_envoye) {
                                validated = ['waitingforvalidation']
                            }
                            break
                        case 'termine':
                            validated = steps
                    }
                } else if (content.redaction === 'proprietaire') {
                    steps = ['waitingforvalidation', 'redaction', 'published']
                    switch (content.statut) {
                        case 'a_valider':
                            validated = ['waitingforvalidation']
                            break
                        case 'en_cours':
                            validated = ['waitingforvalidation', 'redaction']
                            break
                        case 'termine':
                            validated = steps
                    }
                } else if (content.redaction === 'nextlevel') {
                    steps = ['waitingforvalidation', 'accepted', 'redaction', 'publication', 'published']
                    switch (content.statut) {
                        case 'a_valider':
                            validated = ['waitingforvalidation']
                            break
                        case 'en_cours':
                            validated = ['waitingforvalidation', 'accepted', 'redaction', 'publication']
                            if (!content.brief_envoye) {
                                validated = ['waitingforvalidation', 'accepted']
                            } else if (!content.contenu_date_envoi) {
                                validated = ['waitingforvalidation', 'accepted', 'redaction']
                            }
                            break
                        case 'termine':
                            validated = steps
                    }
                }

                this.steps = steps.map(step => {
                    return {
                        name: this.$t('status.' + step),
                        checked: validated.includes(step)
                    }
                })
            }
        }
    }
</script>

<style scoped></style>
