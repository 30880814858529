<template>
    <nl-modal :open-modal="openModal" :title="$t('users.modify')" @hidden="close">
        <template v-if="user">
            <div class="form-group">
                <text-field v-model="user.user" disabled label="Email" />
            </div>
            <div class="form-group">
                <text-field v-model="user.pwd" type="password" label="Password" />
            </div>
            <div class="form-group">
                <nl-select v-model="user.role" :items="roles" label="Role" />
            </div>
            <div class="form-group">
                <nl-select v-model="user.actif" :items="status" label="Status" />
            </div>
            <div class="form-group">
                <nl-select v-model="user.type_parrainage" :items="sponsorshipTypes" label="Rémunération Parrainage" />
            </div>
            <div class="form-group">
                <v-checkbox v-model="user.cacher_resultats"> Cacher exceptionnellement les résultats</v-checkbox>
                <v-checkbox v-model="user.withdrawal_limit"> Retrait des gains à partir de 200 euros</v-checkbox>
                <v-checkbox v-model="user.displaySerp">
                    Afficher l'URL dans la recherche (Modal et Favoris)
                </v-checkbox>
                <v-checkbox v-model="user.api_access"> Autoriser l'accès à l'API</v-checkbox>
            </div>
        </template>
        <template #footer>
            <button class="btn btn-primary" @click="save">
                {{ $t('global.modify') }}
            </button>
            <button class="btn btn-danger" @click="close">
                {{ $t('global.cancel') }}
            </button>
        </template>
    </nl-modal>
</template>

<script>
    import { mapActions } from 'vuex'
    import { EventManager } from '@/components/EventManager'

    export default {
        components: {
            EventManager
        },
        data: () => ({
            roles: [
                { text: 'admin', id: 'admin' },
                { text: 'prospect', id: 'prospect' },
                { text: 'partenaire', id: 'partenaire' },
                { text: 'consultant', id: 'consultant' },
                { text: 'agence', id: 'agence' },
                { text: 'administratif', id: 'administratif' },
                { text: 'redacteur', id: 'redacteur' }
            ],
            status: [
                { text: 'Actif', id: 1 },
                { text: 'Inactive', id: 0 }
            ],
            sponsorshipTypes: [
                { text: 'Crédit', id: 'credit' },
                { text: 'Gain', id: 'gain' }
            ],
            openModal: false,
            user: null
        }),
        created() {
            EventManager.$on('onModifyUser', this.init)
        },
        methods: {
            ...mapActions('users', { updateUser: 'update' }),
            init(data) {
                this.user = Object.assign({}, data)
                this.openModal = true
            },
            close() {
                this.user = Object.assign({})
                this.openModal = false
            },
            save() {
                this.updateUser(this.user).then(data => {
                    this.$emit('saved', data)
                })
            }
        }
    }
</script>

<style></style>
