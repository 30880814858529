<script>
    import Vue from 'vue'
    import { mapActions } from 'vuex'

    export default {
        props: {
            value: {
                type: Array,
                default: () => []
            },
            type: {
                type: String,
                default: () => 'image'
            }
        },
        data() {
            return {
                files: [],
                originalFileObject: {
                    loading: false,
                    importFilename: null,
                    percent: 0,
                    model: null
                }
            }
        },
        computed: {
            style() {
                if (this.model && this.type === 'image') {
                    return {
                        backgroundImage: "url('" + this.model.thumbnailUrl + "')"
                    }
                } else {
                    return {}
                }
            },
            originalFilename() {
                return this.model ? this.model.originalFilename : this.importFilename
            },
            label() {
                return 'Télécharger'
            },
            filesModel() {
                const models = []
                for (let index = 0; index < this.files.length; index++) {
                    const file = this.files[index]
                    models.push({ file: file.model })
                }
                return models
            }
        },
        watch: {
            value() {
                this.buildFilesArray()
            }
        },
        mounted() {
            this.buildFilesArray()
        },
        methods: {
            ...mapActions('upload', ['upload']),
            buildFilesArray() {
                this.files = []
                for (let index = 0; index < this.value.length; index++) {
                    const f = this.value[index]
                    Vue.set(this.files, index, Object.assign({}, this.originalFileObject))
                    this.files[index].model = f.file
                }
            },
            onFileChanged(event) {
                for (let index = 0; index < event.target.files.length; index++) {
                    const file = event.target.files[index]
                    const fIdx = this.files.length
                    Vue.set(this.files, fIdx, Object.assign({}, this.originalFileObject))
                    this.files[fIdx].loading = true
                    const formData = new FormData()
                    this.files[fIdx].importFilename = file.name
                    formData.append('file', file, file.name)
                    this.upload({
                        model: formData,
                        config: {
                            onUploadProgress: progressEvent => {
                                this.files[fIdx].percent = (progressEvent.loaded / progressEvent.total) * 100
                            }
                        }
                    })
                        .then(({ data }) => {
                            this.files[fIdx].model = data
                            this.$emit('uploaded', this.filesModel)
                        })
                        .catch(({ response }) => {
                            this.$emit('onUploadError', response)
                        })
                        .finally(() => {
                            this.files[fIdx].loading = false
                            this.files[fIdx].percent = 0
                        })
                }
            },
            removeFile(index) {
                this.files = this.files.filter((element, idx) => idx !== index)
                this.$emit('uploaded', this.filesModel)
            }
        }
    }
</script>
