<template>
    <b-modal v-model="activeModal">
        <template>
            <header class="px-4 py-3 has-background-warning shadow-sm">
                <h4 class="h4 p-0 mb-0">Envoyer un mail d'erreur</h4>
            </header>
            <div class="p-3">
                <b-field class="w-100">
                    <b-select v-model="selectAction" class="w-100" :placeholder="$t('global.actions')">
                        <option value="erreur_entete_reponse">Erreur 500 - 400 - Communication</option>
                        <option value="erreur_liens_externes">Liens externes</option>
                        <option value="erreur_lien_url">Aucun lien vers l'URL</option>
                        <option value="erreur_ancre">Ancre incorrect</option>
                    </b-select>
                </b-field>
                <b-button class="mb-2 mb-md-0 ml-md-2" type="is-warning" @click="onValidateAction">
                    {{ $t('global.validate') }}
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'ErrorMailModal',
        data() {
            return {
                activeModal: false,
                data: null,
                selectAction: null
            }
        },
        methods: {
            ...mapActions('orders', ['sendErrorEmail']),
            openModal(data) {
                this.data = data
                this.activeModal = true
            },
            closeModal() {
                this.data = null
                this.activeModal = false
            },
            onValidateAction() {
                this.sendErrorEmail({
                    ids: this.data,
                    type_email_erreur: this.selectAction
                }).then(() => {
                    this.closeModal()
                    this.$emit('refresh')
                })
            }
        }
    }
</script>

<style></style>
