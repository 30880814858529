<template>
    <button type="button" :class="`btn btn-rounded btn-outline-${color}`">
        <i v-show="iconBefore" :class="`${iconBefore}`" />
        <slot class="mr-2 ml-2" />
        <i v-show="iconAfter" :class="`${iconAfter}`" />
    </button>
</template>

<script>
    export default {
        name: 'TabButton',
        props: {
            iconBefore: {
                type: String,
                required: false
            },
            iconAfter: {
                type: String,
                required: false
            },
            color: {
                type: String,
                default: 'primary'
            }
        }
    }
</script>

<style scoped>
    .btn-rounded {
        border-radius: 30px;
    }
</style>
