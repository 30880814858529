<template>
    <div class="row mb-4">
        <div class="col-md-12 col-lg-6">
            <b-notification :closable="false" role="alert" type="is-info is-light">
                Cette commande est terminée 👌
            </b-notification>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'WritingDoneNotification',
        props: {}
    }
</script>
