<template>
    <div>
        <div class="mb-3">
            <div class="form-inline align-items-baseline">
                <label class="label mr-2">Trier selon la thématique :</label>
                <select v-model="selectedGroup" class="form-control form-control-sm" @change="filterByGroups">
                    <option :value="null" selected>TOUTES</option>
                    <option v-for="group in groups" :key="group.id" :value="group.id">
                        {{ group.name }}
                    </option>
                </select>
            </div>
        </div>

        <div class="mb-3">
            <b-notification :closable="false" type="is-warning is-light">
                Pour sélectionner un site veuillez double-cliquer sur la ligne correspondante.
            </b-notification>
        </div>

        <div>
            <b-table :data="filteredSites" :row-class="rowColors" @dblclick="row => selectSite(row)">
                <b-table-column field="id" label="#" sortable>
                    <div slot="default" slot-scope="props">
                        <span>{{ props.row.id }}</span>
                    </div>
                </b-table-column>

                <b-table-column field="ndd" label="Site" sortable>
                    <div slot="default" slot-scope="props">
                        <span>{{ props.row.ndd }}</span>
                    </div>
                </b-table-column>

                <b-table-column field="group" label="Thématique">
                    <div slot="default" slot-scope="props">
                        <span>{{ getGroupName(props.row.group_id) }}</span>
                    </div>
                </b-table-column>

                <b-table-column field="nb_mots_cles_seobserver" label="Mots clés SEObserver" centered sortable>
                    <div slot="default" slot-scope="props">
                        <span>{{ props.row.nb_mots_cles_seobserver }}</span>
                    </div>
                </b-table-column>

                <b-table-column field="nb_keywords" label="Mots clés NL" centered sortable>
                    <div slot="default" slot-scope="props">
                        <span>{{ props.row.nb_keywords }}</span>
                    </div>
                </b-table-column>

                <b-table-column field="nb_commandes" label="Nb commandes" centered sortable>
                    <div slot="default" slot-scope="props">
                        <span>{{ props.row.nb_commandes }}</span>
                    </div>
                </b-table-column>
            </b-table>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CampaignPreOrderSite',

        props: ['sites', 'groups', 'preOrder', 'campaignPreOrdersSites'],

        data() {
            return {
                selectedGroup: null,
                filteredSites: []
            }
        },

        watch: {
            sites() {
                this.filteredSites = this.sites
            }
        },

        methods: {
            selectSite(site) {
                this.$emit('siteSelected', site)
                this.$emit('closeModal')
            },

            getGroupName(groupId) {
                return this.groups.find(group => group.id == groupId).name
            },

            filterByGroups() {
                if (!this.selectedGroup) {
                    this.filteredSites = this.sites
                    return
                }

                this.filteredSites = this.sites.filter(site => {
                    return site.group_id === this.selectedGroup
                })
            },

            rowColors(row, index) {
                if (!Object.keys(this.campaignPreOrdersSites).length) {
                    return
                }

                if (this.campaignPreOrdersSites.launched && this.campaignPreOrdersSites.launched.includes(row.id)) {
                    return 'has-background-danger'
                } else if (
                    this.campaignPreOrdersSites.not_launched &&
                    this.campaignPreOrdersSites.not_launched.includes(row.id)
                ) {
                    return 'has-background-warning'
                }
            }
        }
    }
</script>
