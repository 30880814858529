const urlRegexSafe = require('url-regex-safe')

const UrlValidator = {
    getMessage: field => 'The ' + field + ' value is not truthy.',
    validate: function (value) {
        return urlRegexSafe({
            exact: true,
            strict: true,
            localhost: false
        }).test(value)
    }
}

export default UrlValidator
