<template>
    <div>
        <b-tabs
            v-model="activeTab"
            @input="
                value => {
                    openOnce[value] = true
                }
            "
        >
            <b-tab-item
                v-for="tab in tabs"
                :key="tab.index"
                :label="tab.label"
                :icon="tab.icon"
                :disabled="tab.disabled"
            >
                <slot :open-once="openOnce" :tab="tab" />
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
    export default {
        name: 'NlTabs',
        props: {
            // Tab object: {index,label,type,icon,disabled,counts}
            tabs: {
                type: Array,
                required: true
            },
            defaultTab: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                activeTab: this.defaultTab,
                openOnce: {
                    [this.defaultTab]: true
                }
            }
        }
    }
</script>
