import 'animate.css'
import 'bootstrap/js/src/alert'
import 'bootstrap/js/src/collapse'
import 'bootstrap/js/src/dropdown'
import 'bootstrap/js/src/modal'
import 'bootstrap/js/src/tab'
import 'bootstrap/js/src/tooltip'
import 'bootstrap/js/src/util'
import 'flag-icon-css/css/flag-icon.css'
import 'floatthead'
import 'popper.js'
import 'toastr/build/toastr.css'
import 'vue-select/dist/vue-select.css'

require('@/assets/scss/table.scss')
require('@/assets/scss/style.scss')
require('@/assets/scss/transitions.scss')
require('@/assets/scss/buefy/tile-buefy.scss')
