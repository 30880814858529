import ApiService from '@/services/api.service'

export default {
    getStatusCounts() {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/sites/urls/counts`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getStatusCountsBySite(context, { site_id }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/sites/${site_id}/urls/counts`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getUrls(context, { status, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/sites/urls/${status}`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getUrlsBySite(context, { site_id, status, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/sites/${site_id}/urls/${status}`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getKeywordsByUrl(context, { url_id, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/sites/${url_id}/keywords`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getExternalLinksByUrl(context, { url_id, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/sites/${url_id}/externallinks`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    // PARTNER START
    getUrlsByPartnerSite(context, { site_id, status, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/partner/sites/${site_id}/urls/${status}`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getStatusCountsByPartnerSite(context, { site_id }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/partner/sites/${site_id}/urls/counts`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getKeywordsByPartnerUrl(context, { site_id, url_id, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/partner/sites/${site_id}/${url_id}/keywords`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getExternalLinksByPartnerUrl(context, { site_id, url_id, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/partner/sites/${site_id}/${url_id}/externallinks`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    doScrapAnPartnerUrl(context, { site_id, url_id }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/partner/sites/${site_id}/${url_id}/scrap`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getUrlsPriceSumByPartnerSite(context, { id }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/partner/sites/${id}/urls/price/sum`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    // PARTNER END

    doScrapAnUrl(context, { url_id }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/admin/sites/${url_id}/scrap`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getUrlsHistory(context, params) {
        return new Promise(resolve => {
            ApiService.get(`/admin/orders/list-pending-url`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => console.error(error))
        })
    },

    getBuyModalInfo(context, { url_id, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/urls/${url_id}/buy/info`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    buyUrl(context, { url_id, params }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/urls/${url_id}/buy`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
