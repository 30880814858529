var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',[_c('i',{staticClass:"fas fa-chart-line mr-2"}),_vm._v("Mes stats ("+_vm._s(_vm.computePeriod)+")")]),(_vm.sites.length > 0 && _vm.stats !== null)?_c('div',{staticClass:"d-lg-flex w-100",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"col-lg-3 col-sm-12 pt-3"},[_c('StatCard',{attrs:{"color":'warning',"icon":'fas fa-check'},scopedSlots:_vm._u([{key:"counter",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('h3',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.stats.orders_count)+" ")]),_vm._v("   "),_c('b-tooltip',{attrs:{"position":"is-right"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('b',[_vm._v("Evolution du nombre de commandes sur la période précédente")]),_vm._v(" (Ex : Mois en cours VS Mois dernier) ")]},proxy:true}],null,false,3709217085)},[_c('p',{staticClass:"m-0 p-1 text-sm box ml-2 text-white text-nowrap",class:_vm.computeVariation(_vm.stats.orders_count, _vm.stats.variation_orders_count) > 0
                                        ? 'bg-success'
                                        : 'bg-danger',staticStyle:{"width":"fit-content","margin-left":"auto"}},[_vm._v(" "+_vm._s(_vm.computeVariation(_vm.stats.orders_count, _vm.stats.variation_orders_count) > 0 ? '+' : '')+" "+_vm._s(_vm.computeVariationPercentage(_vm.stats.orders_count, _vm.stats.variation_orders_count))+" % ")])])],1)]},proxy:true},{key:"text",fn:function(){return [_c('span',[_vm._v("Nombre de commandes")])]},proxy:true}],null,false,3837345223)}),_c('StatCard',{attrs:{"color":'info',"icon":'fas fa-euro-sign'},scopedSlots:_vm._u([{key:"counter",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('h3',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.stats.sum))+" ")]),_vm._v("   "),_c('b-tooltip',{attrs:{"position":"is-right"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('b',[_vm._v("Evolution du chiffre d'affaires sur la période précédente")]),_vm._v(" (Ex : Mois en cours VS Mois dernier) ")]},proxy:true}],null,false,534726799)},[_c('p',{staticClass:"m-0 p-1 text-sm box ml-2 text-white text-nowrap",class:_vm.computeVariation(_vm.stats.sum, _vm.stats.variation_sum) > 0
                                        ? 'bg-success'
                                        : 'bg-danger',staticStyle:{"width":"fit-content","margin-left":"auto"}},[_vm._v(" "+_vm._s(_vm.computeVariation(_vm.stats.sum, _vm.stats.variation_sum) > 0 ? '+' : '')+" "+_vm._s(_vm.computeVariationPercentage(_vm.stats.sum, _vm.stats.variation_sum))+" % ")])])],1)]},proxy:true},{key:"text",fn:function(){return [_c('span',[_vm._v("CA Généré")])]},proxy:true}],null,false,2003516593)}),_c('StatCard',{attrs:{"color":'success',"icon":'fas fa-ruler'},scopedSlots:_vm._u([{key:"counter",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('h3',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")((_vm.stats.sum / (_vm.stats.orders_count === 0 ? 1 : _vm.stats.orders_count))))+" ")]),_vm._v("  "),_c('b-tooltip',{attrs:{"position":"is-right"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('b',[_vm._v("Evolution du gain moyen par commande sur la période précédente")]),_vm._v(" (Ex : Mois en cours VS Mois dernier) ")]},proxy:true}],null,false,1995516676)},[_c('p',{staticClass:"m-0 p-1 text-sm box ml-2 text-white text-nowrap",class:_vm.computeMeanPriceVariation(
                                        _vm.stats.sum,
                                        _vm.stats.orders_count,
                                        _vm.stats.variation_sum,
                                        _vm.stats.variation_orders_count
                                    ) > 0
                                        ? 'bg-success'
                                        : 'bg-danger',staticStyle:{"width":"fit-content","margin-left":"auto"}},[_vm._v(" "+_vm._s(_vm.computeMeanPriceVariation( _vm.stats.sum, _vm.stats.orders_count, _vm.stats.variation_sum, _vm.stats.variation_orders_count ) > 0 ? '+' : '')+" "+_vm._s(_vm.computeMeanPriceVariation( _vm.stats.sum, _vm.stats.orders_count, _vm.stats.variation_sum, _vm.stats.variation_orders_count ))+" € ")])])],1)]},proxy:true},{key:"text",fn:function(){return [_c('span',[_vm._v("Gain moyen")])]},proxy:true}],null,false,1512026629)})],1),_c('div',{staticClass:"vl d-xs-none"}),_c('div',{staticClass:"col-lg-8 col-sm-12"},[_c('BarChart',{staticClass:"w-100",attrs:{"options":_vm.chartOptions,"data":_vm.ordersData}})],1)]):_c('div',[_c('nl-loader',{attrs:{"show":true}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }