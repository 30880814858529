<template>
    <div>
        <nl-loader v-if="!loaded" show="show"/>

        <div v-if="loaded">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <b-select
                    v-model="config.per_page"
                    placeholder="Résultats par page"
                    icon="align-center"
                    :disabled="isLoading"
                    :loading="isLoading"
                    @input="doFetch"
                >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                </b-select>
            </div>

            <b-table
                :data="clients.data"
                :default-sort="[config.sort, config.sortDirection]"
                :loading="isLoading"
                :mobile-cards="true"
                :per-page="config.per_page"
                :total="clients.total"
                backend-filtering
                backend-pagination
                backend-sorting
                paginated
                :current-page.sync="clients.current_page"
                detailed
                custom-detail-row
                @sort="onSort"
                @page-change="onPageChange"
                @filters-change="onFilterChange"
            >
                <b-table-column field="user_lastname" searchable sortable label="Client" cell-class="align-middle">
                    <div slot="searchable" slot-scope="props" class="searchableCol" style="display: flex">
                        <input
                            v-model="props.filters['computed_name']"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Nom / Prénom / Email"
                        />
                    </div>
                    <template v-slot="props">
                        {{ props.row.user.firstname }}
                        <b-tooltip :label="'Se connecter au compte de ' + props.row.user.firstname" position="is-top">
                            <LoginAs :user-id="props.row.user_id" class="ml-1"/>
                        </b-tooltip>
                    </template>
                </b-table-column>

                <b-table-column field="user.role" label="Responsable" cell-class="align-middle" searchable>
                    <div slot="searchable" slot-scope="props" class="searchableCol">
                        <input
                            v-model="props.filters['responsible_name']"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Nom / Prénom"
                        />
                    </div>
                    <template v-slot="props">
                        <div v-if="props.row.responsible">
                            <div class="d-flex align-items-center">
                                <div class="">
                                    <span class="mr-2">
                                        <span v-if="props.row.responsible.user.role === 'manager'">
                                            <b-tooltip label="Manager" class="text-info">
                                                <i class="fa fa-users"/>
                                            </b-tooltip>
                                        </span>
                                        <span v-if="props.row.responsible.user.role === 'consultant'">
                                            <b-tooltip label="Consultant" class="text-secondary">
                                                <i class="fa fa-user"/>
                                            </b-tooltip>
                                        </span>
                                    </span>
                                    {{ props.row.responsible.user.firstname }}
                                    {{ props.row.responsible.user.lastname }}
                                    <b-tooltip
                                        :label="
                                            'Se connecter au compte de ' +
                                            props.row.responsible.user.firstname +
                                            ' ' +
                                            props.row.responsible.user.lastname
                                        "
                                        position="is-top"
                                    >
                                        <LoginAs
                                            v-if="me.id !== props.row.responsible.user.id"
                                            :user-id="props.row.responsible.user.id"
                                            class="ml-1"
                                        />
                                    </b-tooltip>
                                </div>
                                <div v-if="isAgency" class="px-2">
                                    <b-tooltip label="Retirer le responsable">
                                        <b-button
                                            type="is-danger"
                                            size="is-small"
                                            rounded
                                            @click.prevent="removeResponsible(props.index, props.row)"
                                        >
                                            <b-icon icon="times"/>
                                        </b-button>
                                    </b-tooltip>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <b-tooltip label="Affecter un responsable">
                                <b-button
                                    type="text-info"
                                    size="is-small"
                                    rounded
                                    @click.prevent="showModal(props.index, props.row)"
                                >
                                    Affecter
                                </b-button>
                            </b-tooltip>
                        </div>
                    </template>
                </b-table-column>

                <b-table-column field="credit_amount" label="Crédit client" cell-class="align-middle" numeric centered>
                    <template v-slot="props">
                        {{ props.row.user.credit_amount | toCurrency }}
                    </template>
                </b-table-column>

                <b-table-column field="budget" label="Budget mensuel" cell-class="align-middle" numeric centered>
                    <template v-slot="props">
                        {{
                            props.row.user.projects.reduce(
                                (n, {price_paid_this_month}) => n + price_paid_this_month,
                                0
                            ) | toCurrency
                        }}
                        /
                        {{ props.row.user.projects_sum_budget || '0' | toCurrency }}
                        <br/>
                        <hr/>
                        <b-progress
                            :value="computeBudget(props.row) * 100"
                            show-value
                            :type="computeBudgetDifference(props.row) > 50 ? 'is-warning' : 'is-success'"
                            format="percent"
                        />
                    </template>
                </b-table-column>

                <b-table-column field="" label="Commandes" cell-class="align-middle" numeric centered>
                    <template v-slot="props">
                        <button
                            class="rounded button px-2 is-light rounded-lg text-decoration-none"
                            title="Voir les commandes du client"
                            @click.prevent="viewContents(props.row)"
                        >
                            {{ props.row.contents_count }}
                            <i class="fas fa-eye ml-2"/>
                        </button>
                    </template>
                </b-table-column>

                <b-table-column field="user.projects_count" label="Projets" cell-class="align-middle" numeric>
                    <template v-slot="props">
                        {{ props.row.user.projects.length }}
                    </template>
                </b-table-column>

                <b-table-column field="actions" sortable label="Actions" cell-class="align-middle" numeric>
                    <template v-slot="props">
                        <div class="d-flex justify-content-end text-left">
                            <ModifyCredits v-model="props.row" @reload="fetchData"/>
                            <RemoveEmployeeButton :target="props.row" @reload="fetchData"
                                                  v-if=" ['agence', 'admin'].includes(me.role) "/>
                        </div>
                    </template>
                </b-table-column>

                <template slot="detail" slot-scope="props">
                    <ClientProjectRow v-for="project in props.row.user.projects" :key="project.id" :project="project"/>
                </template>
            </b-table>
        </div>
        <AffectClientToResponsibleModal ref="modal" @hide="updateClient"/>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import ClientProjectRow from './ClientProjectRow'
import AffectClientToResponsibleModal from '../Modal/AffectClientToResponsibleModal'
import LoginAs from '@/components/Admin/LoginAs.vue'
import ModifyCredits from '@/components/Agency/Button/ModifyCredits.vue'
import RemoveEmployeeButton from "@/components/Agency/Button/RemoveAgencyUserButton.vue";
import EditEmployeeButton from "@/components/Agency/Button/EditEmployeeButton.vue";

export default {
    components: {
        EditEmployeeButton, RemoveEmployeeButton,
        ModifyCredits,
        LoginAs,
        ClientProjectRow,
        AffectClientToResponsibleModal
    },
    data: function () {
        return {
            rows_per_page: 15,
            filters: null,
            sort: null,
            sortOrder: null,
            page: null,
            checkedRows: [],
            isLoading: true,
            loaded: false,
            currentIndex: null
        }
    },
    created() {
    },
    computed: {
        ...mapState('consultants', {
            clients: 'clients',
            config: 'clientsConfig',
            month: 'month'
        }),
        isAgency() {
            return this.$store.state.auth.user.role === 'admin' || this.$store.state.auth.user.role === 'agence'
        },
        me() {
            return this.$store.state.auth.user
        }
    },
    methods: {
        ...mapActions('consultants', {
            getClients: 'getClients',
            affectClientToResponsible: 'affectClientToResponsible'
        }),
        showModal(index, client) {
            this.currentIndex = index
            this.$store.commit('consultants/setNewUser', {
                user: client.user,
                type: 'client',
                user_id: client.user_id,
                agency_id: client.agency_id,
                id: client.id
            })
            this.$refs.modal.show()
        },
        updateClient(client) {
            this.clients.data.splice(this.currentIndex, 1, client)
            this.currentIndex = null
        },
        doFetch() {
            if (this.timeout) clearTimeout(this.timeout)

            this.timeout = setTimeout(this.fetchData, 300)
        },
        fetchData() {
            this.isLoading = true
            this.getClients()
                .then(() => {
                    this.loaded = true
                })
                .catch(e => {
                    this.$store.dispatch('toastError', e)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        onSort(field, order) {
            this.config.sort = field
            this.config.sortDirection = order

            this.fetchData()
        },
        onFilterChange(filter) {
            var filters = {}
            Object.keys(filter).forEach(element => {
                filters[element] = filter[element]
            })
            this.config.filters = filters

            this.fetchData()
        },
        onPageChange(page) {
            this.config.page = page

            this.fetchData()
        },
        computeBudget(row) {
            let budget_prevu = 0
            let budget_depense = 0

            budget_depense = row.user.projects.reduce(
                (n, {price_paid_this_month}) => n + price_paid_this_month,
                0
            )
            budget_prevu = row.user.projects_sum_budget || '0'

            if (budget_prevu > 0) {
                if (budget_depense / budget_prevu > 1) {
                    return 1
                } else {
                    return budget_depense / budget_prevu
                }
            } else {
                return 0
            }
        },
        computeBudgetDifference(row) {
            let budget_prevu = 0
            let budget_depense = 0

            budget_depense = row.user.projects.reduce(
                (n, {price_paid_this_month}) => n + price_paid_this_month,
                0
            )
            budget_prevu = row.user.projects_sum_budget || '0'

            return budget_prevu - budget_depense
        },
        reloadClients() {
            this.fetchData()
        },
        removeResponsible(index, client) {
            let params = {
                agency_id: client.agency_id,
                id: client.id,
                responsible_id: null,
                responsible_type: null,
                type: 'client',
                user_id: client.user_id
            }

            let source, id
            if (client.responsible_type === 4) {
                source = 'managers'
                id = client.responsible_id
            } else if (client.responsible.manager_id) {
                source = 'managers'
                id = client.responsible.manager_id
            } else {
                source = 'consultants'
                id = client.responsible_id
            }

            let employeeIndex = this.$store.state.consultants[source].data.findIndex(employee => employee.id === id)

            let employee = null

            this.affectClientToResponsible(params)
                .then(() => {
                    client.responsible = null
                    client.responsible_id = null
                    client.responsible_type = null

                    if (employeeIndex !== -1) {
                        employee = this.$store.state.consultants[source].data[employeeIndex]
                        employee.clients_count = employee.clients_count - 1 || 0

                        this.$store.state.consultants[source].data.splice(employeeIndex, 1, employee)
                    }

                    this.clients.data.splice(index, 1, client)
                })
                .catch(e => {
                    this.$store.dispatch('toastError', e)
                })
                .finally(() => {
                    this.fetchData()
                    /*this.loading = false;*/
                })
        },
        viewContents(client) {
            this.$router.push(`/agency/client?id=${client.id}&agency=${client.agency_id}`)
        }
    },
    mounted() {
        this.fetchData()
    }
}
</script>

<style>
:v-deep(.is-manager-agency) {
    background-color: #ebeced;
}
</style>
