<template>
    <div class="bg-white w-100">
        <b-table
            :loading="isLoading"
            :data="contents"
            :checkable="withCheckbox"
            :custom-is-checked="(props, row) => props.id === row.id"
            :checked-rows.sync="checkedRows"
            :mobile-cards="true"
            striped
            class="w-100 overflow-x-auto"
        >
            <b-table-column v-slot="props" label="#">
                {{ props.row.id }}
            </b-table-column>
            <b-table-column v-slot="props" label="Date d'achat">
                {{ props.row.created_at ? $d(Date.parse(props.row.created_at), 'short') : '' }}
            </b-table-column>
            <b-table-column v-slot="props" label="Propriétaire">
                <span v-if="!props.row.partenaire_id"> NextLevel </span>
                <span v-else>
                    {{ $t('partenaire-site.partner') }}
                </span>
            </b-table-column>
            <b-table-column v-slot="props" label="Statut">
                <status :content="props.row" />
            </b-table-column>
            <b-table-column v-slot="props" label="Date de publication">
                <span v-if="props.row.finished_at">
                    {{ moment(props.row.finished_at).format('L') }}
                </span>
            </b-table-column>
            <b-table-column v-slot="props" label="Url acheté">
                <div v-if="!readonly || props.row.statut === 'termine'" class="d-flex align-items-center">
                    <b-tooltip position="is-top">
                        <template v-slot:content>
                            {{ props.row.url }}
                        </template>
                        <span class="text-truncate d-inline-block" style="max-width: 200px">
                            {{ props.row.url }}
                        </span>
                    </b-tooltip>
                    <nl-clipboard-button :copy="props.row.url" />
                    <button
                        v-if="withUrlInformation"
                        class="ml-1 btn btn-link p-0"
                        @click="openDataUrlModal(props.row)"
                    >
                        <b-icon icon="eye" />
                    </button>
                </div>
                <div v-else>
                    {{ $t('suivi.availablewhenpublished') }}
                </div>
            </b-table-column>
            <b-table-column v-slot="props" label="Ancre">
                {{ props.row.link_anchor }}
                <hr v-if="props.row.link_second_anchor" />
                <span v-if="props.row.link_second_anchor">
                    {{ props.row.link_second_anchor }}
                </span>
            </b-table-column>
            <b-table-column v-if="!readonly" v-slot="props" label="Lien">
                <div class="d-flex align-items-center">
                    <b-tooltip position="is-top">
                        <template v-slot:content>
                            {{ props.row.link_url }}
                        </template>
                        {{ formatUrl(props.row.link_url) }}
                    </b-tooltip>
                    <nl-clipboard-button :copy="props.row.link_url" />
                </div>
                <hr v-if="props.row.link_second_anchor" />
                <div v-if="props.row.link_second_anchor" class="d-flex align-items-center">
                    <b-tooltip position="is-top">
                        <template v-slot:content>
                            {{ props.row.link_second_url }}
                        </template>
                        {{ formatUrl(props.row.link_second_url) }}
                    </b-tooltip>
                    <nl-clipboard-button :copy="props.row.link_second_url" />
                </div>
            </b-table-column>
            <b-table-column v-slot="props" label="Prix">
                <span>{{ props.row.price_paid }}€</span>
            </b-table-column>
            <b-table-column v-if="withEditButton" v-slot="props" label="">
                <div class="d-flex" style="gap: 0.7rem">
                    <b-button
                        type="is-danger"
                        title="Dissocier de l'accompagnement"
                        @click="detachContent(props.row.id)"
                    >
                        <b-icon icon="unlink" />
                    </b-button>
                    <b-button type="is-light" @click="$emit('edit', props.row)">
                        <b-icon icon="pen" />
                    </b-button>
                </div>
            </b-table-column>
        </b-table>
        <ContentDetailModal ref="contentDetailModal" />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import ContentDetailModal from './DetailModal.vue'
    import Status from './Status'
    import NlClipboardButton from '@/components/UI/NlClipboardButton.vue'

    export default {
        components: { NlClipboardButton, ContentDetailModal, Status },
        props: {
            period: {
                type: String,
                required: true
            },
            withUrlInformation: {
                type: Boolean,
                default: false
            },
            withCheckbox: {
                type: Boolean,
                default: false
            },
            withEditButton: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                showOrderDataModal: false,
                isLoading: true,
                orderData: null,
                checkedRows: [],
                contents: [],
                total: 0,
                per_page: 5,
                page: 1
            }
        },
        computed: {
            ...mapState('auth', {
                isAdmin: state => state.security.isAdmin
            })
        },
        watch: {
            checkedRows() {
                this.$emit(
                    'check',
                    this.checkedRows.map(e => e.id)
                )
            }
        },
        created() {
            this.getContents()
        },
        methods: {
            ...mapActions('contents', ['detachCampaignContent', 'getContentsByPeriod']),
            getContents() {
                this.getContentsByPeriod({
                    campaignId: this.$route.query.id,
                    period: this.period,
                    page: this.page
                }).then(response => {
                    this.contents = response.data
                    this.total = response.total
                    this.per_page = response.per_page
                    this.isLoading = false
                })
            },
            onPageChange(page) {
                this.isLoading = true
                this.page = page
                this.getContents()
            },
            detachContent: function (id) {
                this.detachCampaignContent({
                    content_id: id,
                    accompagnement_id: this.$route.query.id
                })
            },
            openDataUrlModal(order) {
                this.$refs.contentDetailModal.openModal(order)
            },
            formatUrl: function (urlToFormat) {
                if (!urlToFormat) {
                    return ''
                }

                let url = new URL(urlToFormat)
                return url.pathname
            }
        }
    }
</script>

<style scoped></style>
