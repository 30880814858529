import ApiService from '../../../services/api.service'

export default {
    index(context, { projectId, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(projectId ? `/projects/${projectId}/favorites` : '/users/favorites', params)
                .then(({ data }) => {
                    context.commit('setFavorites', data)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    searchByFavorites(context, params) {
        const { sortBy, orderBy, page, nbResults } = params.pagination ? params.pagination : [null, null, null, null]
        const filters = params.filters ? params.filters : []
        return new Promise((resolve, reject) => {
            ApiService.get('/favorite/search-by-favorite', {
                project_id: params.projectId,
                sortBy,
                orderBy,
                page,
                nbResults,
                ...filters
            })
                //ApiService.get('/favorite?project_id='+params.projectId)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    storeFavorite(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/favorites', params)
                .then(({ data }) => {
                    context.commit('setFavorites', [...context.state.favorites, data])
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    updateFavorite(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put('/favorites', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    deleteFavorite(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.delete('/favorites', params)
                .then(() => {
                    context.commit(
                        'setFavorites',
                        context.state.favorites.filter(
                            f => f.project_id !== params.project_id || f.url_id !== params.url_id
                        )
                    )
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
