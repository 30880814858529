<template>
    <div>
        <h5 class="card-title">{{ $t('redactor.recap-infos') }} :</h5>
        <div class="card-text">
            <ul class="p-4">
                <li class="mb-2">
                    {{ $t('redactor.page') }} :
                    <span class="text-muted">{{ writing.page }}</span>
                </li>

                <li class="mb-2">
                    {{ $t('redactor.link') }} :
                    <span>{{ writing.primary_link }}</span>
                    <button class="btn p-0 btn-link text-muted" @click="copyToClipboard(writing.primary_link)">
                        <i class="far fa-copy ml-1" />
                    </button>
                </li>

                <li class="mb-2">
                    {{ $t('global.anchor') }} :
                    <span>{{ writing.primary_anchor }}</span>
                    <button
                        class="btn p-0 btn-link text-muted"
                        @click="copy(writing.primary_link, writing.primary_anchor)"
                    >
                        <i class="far fa-copy ml-1" />
                    </button>
                </li>

                <li v-if="writing.secondary_link" class="mb-2">
                    {{ $t('redactor.link') }} 2:
                    <span>{{ writing.secondary_link }}</span>
                    <button class="btn p-0 btn-link text-muted" @click="copyToClipboard(writing.secondary_link)">
                        <i class="far fa-copy ml-1" />
                    </button>
                </li>

                <li v-if="writing.secondary_link" class="mb-2">
                    {{ $t('global.anchor') }} 2:
                    <span>{{ writing.secondary_anchor }}</span>
                    <button
                        class="btn p-0 btn-link text-muted"
                        @click="copy(writing.secondary_link, writing.secondary_anchor)"
                    >
                        <i class="far fa-copy ml-1" />
                    </button>
                </li>

                <li class="mb-2">
                    {{ $t('redactor.nbWords') }} :
                    <span class="text-muted">{{ writing.words_count }}</span>
                </li>

                <!-- <li class="mb-2">
            {{ $t('global.keywords') }} :
            <span class="badge badge-light mr-1" v-for="word in order.keywords.split(',')" :key="word">{{word}}</span>
        </li> -->

                <li class="mb-2">
                    {{ $t('global.brief') }} :
                    <span class="text-muted">{{ writing.brief }}</span>
                </li>
                <li class="mb-2">
                    {{ $t('global.brief_partenaire') }} :
                    <span v-if="writing.partner_brief" class="text-muted">
                        {{ writing.partner_brief }}
                    </span>
                    <span v-else>Aucun</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'RedactorBriefPanel',

        computed: {
            ...mapState('redactors', {
                writing: state => state.writing
            })
        },

        data: function () {
            return {}
        },

        methods: {
            copy(href, text) {
                let container = document.createElement('div')
                let element = document.createElement('a')
                element.setAttribute('href', href)
                element.innerText = text
                container.appendChild(element)

                try {
                    navigator.clipboard.write([
                        // eslint-disable-next-line no-undef
                        new ClipboardItem({
                            'text/html': new Blob([container.innerHTML], { type: 'text/html' }),
                            'text/plain': new Blob([container.innerHTML], {
                                type: 'text/plain'
                            })
                        })
                    ])
                    this.$buefy.toast.open('Copié !')
                } catch (e) {
                    navigator.clipboard.writeText(container.innerHTML)
                }
            },

            copyToClipboard(text) {
                let container = document.createElement('div')
                container.innerText = text

                try {
                    navigator.clipboard.write([
                        // eslint-disable-next-line no-undef
                        new ClipboardItem({
                            'text/html': new Blob([container.innerHTML], { type: 'text/html' }),
                            'text/plain': new Blob([container.innerHTML], {
                                type: 'text/plain'
                            })
                        })
                    ])
                    this.$buefy.toast.open('Copié !')
                } catch (err) {
                    console.error('No copy ' + err)
                }
            }
        }
    }
</script>
