<template>
    <fragment>
        <li v-if="user.accompagnements_count > 0 && user.role !== 'admin'" class="nav-item dropdown">
            <a
                href="#"
                class="nav-link dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <i class="far fa-eye" /> {{ $t('mes-accompagnements.title') }}
            </a>
            <div class="dropdown-menu">
                <router-link to="/campaigns" class="dropdown-item">
                    {{ $t('mes-accompagnements.title') }}
                </router-link>

                <router-link v-if="user.role === 'admin'" to="/consultant/campaigns" class="dropdown-item">
                    {{ $t('mes-accompagnements.title') }} (NL)
                </router-link>
            </div>
        </li>

        <li v-if="user.accompagnements_count <= 0 && user.role !== 'admin'" class="nav-item">
            <router-link to="/campaigns" class="nav-link">
                <i class="fas fa-life-ring" />
                {{ $t('layout.getcouseilling') }}
            </router-link>
        </li>

        <li v-if="typeof user.accompagnements_count === 'undefined' && user.role !== 'admin'" class="nav-item">
            <router-link to="/campaigns" class="nav-link">
                <i class="fas fa-life-ring" />
                {{ $t('layout.getcouseilling') }}
            </router-link>
        </li>

        <li v-if="user.role === 'admin'" class="nav-item dropdown">
            <a
                href="#"
                class="nav-link dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <i class="far fa-eye" /> {{ $t('mes-accompagnements.title') }}
            </a>
            <div class="dropdown-menu">
                <router-link to="/campaigns" class="dropdown-item">
                    {{ $t('mes-accompagnements.title') }}
                </router-link>
                <router-link to="/consultant/campaigns" class="dropdown-item">
                    {{ $t('mes-accompagnements.title') }} (NL)
                </router-link>
            </div>
        </li>
    </fragment>
</template>
<script>
    import { mapState } from 'vuex'

    export default {
        name: 'AccompagnementsMenu',
        computed: {
            ...mapState('auth', {
                user: state => state.user
            })
        }
    }
</script>
