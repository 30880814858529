<template>
    <nl-modal
        ref="modal"
        title="Affecter un utilisateur à une agence"
        :add-legacy-events="false"
        position="right"
        size="lg"
    >
        <template v-slot:header>
            <div class="bg-antracite text-white w-100 text-center p-4">
                <h1>Affecter à un responsable</h1>
            </div>
        </template>

        <div>
            <AffectClientToResponsibleForm />
            <div class="text-center">
                <button class="btn-action btn-third mt-2 mb-2 mb-xl-4" :disabled="loading" @click.prevent="save">
                    <i v-if="loading" class="fas fa-circle-notch fa-spin mr-1" />
                    Affecter
                </button>
            </div>
        </div>

        <template v-slot:footer>
            <div class="d-flex w-100 justify-content-start">
                <b-button type="button" class="btn btn-danger" @click="$refs.modal.hide()"> Annuler</b-button>
            </div>
        </template>
    </nl-modal>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import AgencyModal from './AgencyModal'
    import AffectClientToResponsibleForm from '../Form/AffectClientToResponsibleForm'

    export default {
        name: 'AffectClientToResponsibleModal',
        components: {
            AffectClientToResponsibleForm
        },
        mixins: [AgencyModal],
        data: () => ({
            loading: false
        }),
        computed: {
            ...mapState('consultants', {
                user: state => state.newUser
            })
        },
        methods: {
            ...mapActions('consultants', ['affectClientToResponsible']),
            save() {
                this.loading = true

                let params = this.user

                if (this.user.user) {
                    params.user_id = this.user.user.id

                    delete params.user
                }

                this.affectClientToResponsible(params)
                    .then(data => {
                        let source, id
                        if (params.responsible_type === 4) {
                            source = 'managers'
                            id = data.client.responsible_id
                        } else if (data.client.responsible.manager_id) {
                            source = 'managers'
                            id = data.client.responsible.manager_id
                        } else {
                            source = 'consultants'
                            id = data.client.responsible_id
                        }

                        let employeeIndex = this.$store.state.consultants[source].data.findIndex(
                            employee => employee.id === id
                        )

                        let employee = null
                        if (employeeIndex !== -1) {
                            employee = this.$store.state.consultants[source].data[employeeIndex]
                            employee.clients_count = employee.clients_count + 1 || 1

                            this.$store.state.consultants[source].data.splice(employeeIndex, 1, employee)
                        }

                        this.$emit('hide', data.client)
                        this.$refs.modal.hide()
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
        mounted() {}
    }
</script>
