<template>
    <div class="p-0 m-0">
        <div id="user-card" class="d-flex flex-row align-items-center">
            <div class="info-card-text flex-1">
                <a class="fs-xl text-truncate text-truncate-lg text-primary" href="#" @click.prevent="">
                    {{ user.firstname }} {{ user.lastname }}
                </a>
                <span class="text-dark text-xs">{{ user.company }}</span>
            </div>
        </div>

        <div class="p-3">
            <a class="mt-1 d-block fs-sm fw-400 text-dark" href="#">
                <i class="fas fa-pen text-muted mr-2" />
                {{ user.content_written }} commande(s)
            </a>
            <a :href="'mailto:' + user.email" class="mt-1 d-block fs-sm fw-400 text-dark">
                <i class="fas fa-at text-muted mr-2" />
                {{ user.email }}
            </a>
        </div>
    </div>
</template>

<style scoped>
    #user-card :hover {
        color: white;
    }
</style>

<script>
    export default {
        name: 'RedactorContactCard',
        components: {},
        props: {
            user: {
                required: true
            }
        },
        data: function () {
            return {}
        },
        watch: {},
        created() {},
        mounted() {},
        methods: {}
    }
</script>
