export default () => ({
    total: 0,
    sites: [],
    domainPosting: null,
    keywords: [],
    restrictions: null,
    sellingWebsites: null,
    sellingWebsitesConfig: {
        include: '',
        sort: 'id',
        sortDirection: 'desc',
        page: 1,
        filters: {},
        per_page: 100
    },
    domainPostingConfig: {
        include: '',
        sort: 'nb_mots_cles_seobserver',
        sortDirection: 'desc',
        page: 1,
        filters: {},
        per_page: 25
    },
    sitesAvailables: null,
    sitesAvailablesConfig: {
        include: '',
        sort: 'id',
        sortDirection: 'desc',
        page: 1,
        filters: {},
        per_page: 25
    },
    ownership: {
        site: null,
        owner: null,
        newOwner: null
    }
})
