<template>
    <div class="p-2" :class="gridClasses">
        <div class="card">
            <div class="card-header px-4 text-light">
                <h3 class="mb-0">
                    {{ title }}
                </h3>
            </div>

            <div class="card-body px-4 pb-3">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NlCard',
        props: ['title', 'gridClasses']
    }
</script>

<style lang="scss" scoped>
    .card-header {
        margin-right: unset;
        margin-left: unset;

        border-top-left-radius: 25px;
        border-top-right-radius: 25px;

        background-color: #2b383f;
    }

    .card {
        border-radius: 25px;
    }

    h2 {
        letter-spacing: 0.2rem;
        line-height: 2.3rem;
        font-weight: 600;
    }

    h3 {
        letter-spacing: 0.2rem;
        line-height: 2rem;
        font-weight: 600;
    }

    .stat {
        background-color: #f9bc14;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > div {
            font-size: 34px;
        }

        .label {
            font-size: 18px;
        }
    }

    .deleteSection {
        margin-top: 20vh;

        h2 {
            color: #dc3545;
        }
    }

    .row {
        margin-bottom: 2rem;

        .col {
        }
    }

    .url {
        display: flex;

        .sponsorshipLink {
            width: 360px;
            background-color: #b7b7b7;
            border: 1px solid #b7b7b7;
            margin-right: 10px;

            &:focus {
                outline: none;
            }

            &::selection {
                background-color: transparent;
            }
        }
    }

    .checkbox-lg .custom-control-label::before,
    .checkbox-lg .custom-control-label::after {
        top: 1rem;
        width: 1.55rem;
        height: 1.55rem;
    }

    .checkbox-lg .custom-control-label {
        padding-top: 16px;
        padding-left: 6px;
    }
</style>
