<template>
    <div>
        <div class="w-100 d-flex">
            <div class="px-2 d-flex align-items-center w-100 justify-content-end">
                <b-select v-model="perPage" icon="align-center" @input="loadAsyncData">
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                </b-select>
            </div>
        </div>
        <b-table
            :data="sites.data"
            :per-page="sites.per_page"
            :total="sites.total"
            paginated
            striped
            custom-detail-row
            detailed
            backend-filtering
            backend-sorting
            pagination-rounded
            backend-pagination
            @filters-change="onFilterChange"
            @page-change="onPageChange"
            @sort="onSort"
        >
            <b-table-column field="parent_thematic" label="Thématique" searchable cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.parent_thematic">
                        {{ props.row.parent_thematic }}
                    </span>
                    <span v-else>-</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <b-select v-model="props.filters['group_id']" expanded size="is-small">
                            <option :value="null">Tous</option>
                            <option v-for="i in groups" :key="i.id" :value="i.id">
                                {{ i.name }}
                            </option>
                        </b-select>
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="ndd" label="Site" cell-class="align-middle" searchable>
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.site">{{ props.row.site }}</span>
                    <span v-else>-</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Site"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="total_preorders" label="Nombre de précos" searchable cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.total_preorders > 0">
                        {{ props.row.total_preorders }}
                        <i class="fas fa-arrow-right mx-2" />
                        {{ props.row.done_preorders }}
                    </span>
                    <span v-else>-</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto d-flex">
                        <input
                            v-model="props.filters['total_preorders']"
                            type="number"
                            class="form-control form-control-sm mr-1"
                            placeholder="Total"
                        />
                        <input
                            v-model="props.filters['done_preorders']"
                            type="number"
                            class="form-control form-control-sm ml-1"
                            placeholder="Faites"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="positioned" label="Positionnés" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.has_position || props.row.sold || props.row.has_results_nl">
                        {{ computePositionTotal(props.row) }}
                    </span>
                    <span v-else>-</span>
                </div>
            </b-table-column>
            <b-table-column field="percent_positioned" label="% Positionnés" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.has_position || props.row.sold || props.row.has_results_nl">
                        {{ computePositionPercentage(props.row) }}%
                    </span>
                    <span v-else>-</span>
                </div>
            </b-table-column>
            <b-table-column field="sold" label="Vendus" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.sold > 0">{{ props.row.sold }}</span>
                    <span v-else>-</span>
                </div>
            </b-table-column>
            <b-table-column field="percent_sold" label="% Vendues" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.sold"> {{ computeSoldPercentage(props.row) }}% </span>
                    <span v-else>-</span>
                </div>
            </b-table-column>
            <b-table-column label="Voir" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <a
                        target="_blank"
                        :href="`/admin/sites/preorders?id=${props.row.id}`"
                        class="button is-light text-decoration-none"
                    >
                        <b-icon icon="eye" size="is-small" />
                    </a>
                </div>
            </b-table-column>
            <template slot="detail" slot-scope="props" style="height: auto !important">
                <tr style="height: auto !important">
                    <td colspan="100%" class="p-2" style="height: auto !important">
                        <PreorderSubTable :id="props.row.id" type="site" />
                    </td>
                </tr>
            </template>
        </b-table>
    </div>
</template>

<script>
    import PreorderSubTable from './PreorderTable'

    export default {
        name: 'PreorderStatisticPreorderTable',
        components: { PreorderSubTable },
        props: {
            sites: {
                type: [],
                required: true
            },
            groups: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                sortField: 'id',
                sortOrder: 'desc',
                filters: {},
                page: 1,
                perPage: 25
            }
        },
        methods: {
            computePositionTotal(row) {
                let total_positioned = 0

                if (row.has_results_nl === row.done_preorders) {
                    return row.has_results_nl
                } else if (row.has_position === row.done_preorders) {
                    return row.has_position
                } else if (row.sold === row.done_preorders) {
                    return row.sold === row.done_preorders
                } else {
                    total_positioned = row.has_results_nl

                    if (row.has_position + total_positioned <= row.done_preorders) {
                        total_positioned += row.has_position
                    } else if (row.sold + total_positioned <= row.done_preorders) {
                        total_positioned += row.sold
                    }

                    return total_positioned
                }
            },
            computePositionPercentage(row) {
                let total_positioned = 0

                if (row.has_results_nl === row.done_preorders) {
                    return Math.round((row.has_results_nl * 100) / row.done_preorders)
                } else if (row.has_position === row.done_preorders) {
                    return Math.round((row.has_position * 100) / row.done_preorders)
                } else if (row.sold === row.done_preorders) {
                    return Math.round((row.sold * 100) / row.done_preorders)
                } else {
                    total_positioned = row.has_results_nl

                    if (row.has_position + total_positioned <= row.done_preorders) {
                        total_positioned += row.has_position
                    } else if (row.sold + total_positioned <= row.done_preorders) {
                        total_positioned += row.sold
                    }

                    return Math.round((total_positioned * 100) / row.done_preorders)
                }
            },
            computeSoldPercentage(row) {
                if (row.sold > 0) {
                    return Math.round((row.sold / row.done_preorders) * 100)
                } else {
                    return 0
                }
            },
            loadAsyncData() {
                this.loading = true

                this.$emit('loadAsyncData', {
                    page: this.page,
                    sortField: this.sortField,
                    sortOrder: this.sortOrder,
                    filters: this.filters,
                    perPage: this.perPage,

                    callback() {
                        this.loading = false
                    }
                })
            },
            onFilterChange(filters) {
                this.filters = filters
                this.loadAsyncData()
            },
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            }
        }
    }
</script>

<style scoped></style>
