<template>
    <div>
        <div class="d-flex justify-content-end bg-white">
            <div class="pt-2 px-2 d-flex align-items-center justify-content-md-end">
                <p class="control mr-2 mt-2 mb-2">Résultats par page</p>
                <input
                    v-model="config.per_page"
                    class="form-control w-25"
                    type="number"
                    @keypress.enter="loadAsyncData"
                />
            </div>
            <div class="pt-2 px-2 d-flex align-items-center justify-content-md-end">
                <b-dropdown aria-role="list" :close-on-click="false" position="is-bottom-left">
                    <template #trigger="{ active }">
                        <b-button
                            :label="$t('global.columns')"
                            type="is-info"
                            class="mr-2"
                            :icon-right="active ? 'caret-up' : 'caret-down'"
                        />
                    </template>

                    <b-dropdown-item v-for="(col, index) in columns" :key="index" aria-role="listitem">
                        <b-field>
                            <b-checkbox v-model="col.active">
                                {{ col.name }}
                            </b-checkbox>
                        </b-field>
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>

        <b-table
            :data="writings.data"
            :default-sort="['id', 'desc']"
            :loading="loading"
            class="table-striped writing-review-table"
            :mobile-cards="true"
            :per-page="config.per_page"
            :show-detail-icon="true"
            :striped="true"
            :total="writings.total"
            backend-filtering
            backend-pagination
            backend-sorting
            paginated
            @sort="onSort"
            @page-change="onPageChange"
            @filters-change="onFilterChange"
        >
            <b-table-column :searchable="isSearchable" centered field="entity_type" label="Type" sortable width="0">
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.entity_type === 1">Content</span>
                    <span v-else>Preorder</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option :value="props.filters[props.column.field]">Tous</option>
                            <option value="1">Content</option>
                            <option value="2">Preorder</option>
                        </select>
                    </div>
                </div>
            </b-table-column>

            <b-table-column :searchable="isSearchable" centered field="entity_id" label="Ref" sortable width="0">
                <div slot="default" slot-scope="props">
                    <div class="tag" :class="[props.row.entity_type === 1 ? 'is-warning' : 'is-info']">
                        #{{ props.row.entity_id }}
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            class="form-control form-control-sm"
                            placeholder="Réf..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="writing_status_id"
                label="Statut"
                searchable
                header-class="display-writing-status"
            >
                <div slot="default" slot-scope="props">
                    <div class="form-group">
                        <select
                            v-model="props.row.writing_status_id"
                            class="form-control form-control-sm"
                            @change="doUpdateStatus(props.row)"
                        >
                            <option value="1">To do</option>
                            <option value="3">Review</option>
                            <option value="6">Done</option>
                            <option value="11" disabled>Duplication check</option>
                        </select>
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option value="">&nbsp;</option>
                            <option value="1">To do</option>
                            <option value="3">Review</option>
                            <option value="11">Duplication check</option>
                        </select>
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                :custom-search="searchDate"
                centered
                field="created_at"
                label="Créé le"
                searchable
                width="0"
            >
                <div slot="default" slot-scope="props">
                    {{ moment(props.row.created_at).format('L') }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <input
                        v-model="props.filters[props.column.field]"
                        class="form-control form-control-sm"
                        placeholder="Date..."
                        type="date"
                    />
                </div>
            </b-table-column>

            <b-table-column
                :custom-search="searchDate"
                centered
                field="written_at"
                label="Rédigé le"
                searchable
                width="0"
            >
                <div slot="default" slot-scope="props">
                    {{ moment(props.row.written_at).format('L') }} à
                    {{ moment(props.row.written_at).format('HH:ss') }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            class="form-control form-control-sm"
                            placeholder="Date..."
                            type="date"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column field="redactor" label="Rédigé par" searchable>
                <div slot="default" slot-scope="props">
                    <div v-if="props.row.redactor">
                        <p>
                            {{ props.row.redactor.user.firstname }}
                            {{ props.row.redactor.user.lastname }}
                        </p>
                        <p>
                            <span class="text-muted">{{ props.row.redactor.user.email }}</span>
                        </p>
                    </div>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters['redactor']"
                            class="form-control form-control-sm"
                            placeholder="Nom..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column field="buyer.firstname" label="Client" searchable>
                <div slot="default" slot-scope="props">
                    <div v-if="props.row.entity_type === 1">
                        <p>
                            {{ props.row.related_order.buyer_firstname }}
                            {{ props.row.related_order.buyer_lastname }}<br />
                            <span class="text-muted">{{ props.row.related_order.buyer_email }}</span>
                        </p>
                    </div>
                    <div v-if="props.row.entity_type === 2">
                        <p v-if="props.row.related_order.campaign && props.row.related_order.campaign.project">
                            {{ props.row.related_order.campaign.project.name }}
                        </p>
                    </div>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters['with_client']"
                            class="form-control form-control-sm"
                            placeholder="Client..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column :label="$t('redactor.bought-keywords')" field="keyword" searchable>
                <div slot="default" slot-scope="props">
                    <p v-if="props.row.related_order">
                        {{ props.row.related_order.keyword }}
                    </p>
                    <span v-if="props.row.entity_type === 1">{{ props.row.page }}</span>
                    <span v-else>{{ props.row.site.url }}</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            class="form-control form-control-sm"
                            placeholder="Mots clés..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                :label="$t('redactor.nbWords')"
                field="words_count"
                searchable
                :visible="columns.words_count.active"
            >
                <div slot="default" slot-scope="props">
                    {{ props.row.words_count }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            class="form-control form-control-sm"
                            placeholder="Option mots..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column :label="$t('partenaire-site.partner')" field="partner_email" searchable>
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.partner_id">
                        {{ props.row.partner_email }}
                        <LoginAs :user-id="props.row.partner_id" class="ml-auto" />
                    </span>
                    <span v-else> Nextlevel </span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters['with_partner']"
                            class="form-control form-control-sm"
                            placeholder="Partenaire..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column :label="$t('redactor.link-anchors')" searchable>
                <div slot="default" slot-scope="props">
                    <div v-if="props.row.entity_type === 1">
                        <p>
                            {{ $t('global.link') }} :
                            <span>{{ props.row.primary_link }}</span>
                        </p>
                        <p>
                            {{ $t('global.anchor') }} :
                            <span class="text-muted">{{ props.row.primary_anchor }}</span>
                        </p>
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters['where_link']"
                            :placeholder="$t('redactor.link') + '...'"
                            class="form-control form-control-sm"
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column field="duplication" label="Duplication">
                <div slot="default" slot-scope="props" class="d-flex">
                    <span v-if="props.row.duplicate_percentage !== null">
                        <span :class="[props.row.duplicate_percentage < 7 ? 'is-success' : 'is-danger']" class="tag">
                            {{ props.row.duplicate_percentage }}%
                        </span>
                    </span>

                    <span v-else class="tag is-warning" title="Non vérifié">
                        <i class="fas fa-times" />
                    </span>
                </div>
            </b-table-column>

            <b-table-column field="has_wordpress_plugin" label="Plugin">
                <div slot="default" slot-scope="props">
                    <p v-if="props.row.has_wordpress_plugin == 1">
                        <i class="fas fa-check-square text-success" />
                    </p>
                    <p v-else>
                        <i class="fas fa-times text-primary" />
                    </p>
                </div>
            </b-table-column>

            <b-table-column :label="$t('language.title')" field="lang" :visible="columns.lang.active">
                <div slot="default" slot-scope="props">
                    <p>
                        <flag :iso="props.row.lang == 'fr_fr' ? 'fr' : 'us'" />
                    </p>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            :placeholder="$t('language.title') + '...'"
                            class="form-control form-control-sm"
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column label="Actions">
                <div slot="default" slot-scope="props">
                    <div v-if="props.row.writing_status_id === 3" class="d-flex flex-column flex-md-row">
                        <router-link
                            :to="`/redactor/writing?id=${props.row.id}`"
                            class="btn btn-light"
                            title="Voir le contenu"
                        >
                            <i class="far fa-eye" />
                        </router-link>

                        <button
                            class="btn btn-light"
                            title="Copier le contenu"
                            @click.prevent="copyContent($event, props.row.writing_content)"
                        >
                            <i class="far fa-copy" />
                        </button>
                    </div>
                </div>
            </b-table-column>
        </b-table>

        <input ref="copyInput" type="hidden" />

        <RedactorConfirmModal ref="confirmModal" @unassignRedactorWriting="doUnassignOrder" />

        <RedactorReviewModal ref="reviewModal" />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'WritingsInReviewTable',
        data: function () {
            return {
                search_field: '',
                loading: false,
                status: 'Tous',

                isSearchable: true,
                isMobileWidth: window.innerWidth < 768
            }
        },
        created() {},
        computed: {
            ...mapState('redactors', {
                writings: state => state.writings,
                config: state => state.writingsConfig,

                wordsRemainingToWrite: state => state.wordsRemainingToWrite,
                maxWordsPerDay: state => state.maxWordsPerDay,

                columns: state => state.columns
            }),
            dailyWordsCount() {
                return (
                    Math.round((parseInt(this.wordsRemainingToWrite) / parseInt(this.maxWordsPerDay)) * 100) / 100
                ).toFixed(1)
            }
        },
        methods: {
            ...mapActions('redactors', ['getWritingDashboard', 'setPriority', 'unassignWriting', 'updateStatus']),

            doUpdateStatus(writing) {
                this.updateStatus(writing)
                    .then(() => {})
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
            },

            searchDate: function (item, inputStr) {
                var itemDate = this.moment(item.created_at).startOf('day').format('L')

                if (itemDate.includes(inputStr)) {
                    return true
                }

                return false
            },

            openConfirmModal(index, writing) {
                var data = {
                    index,
                    writing
                }
                this.$refs.confirmModal.openModal('processing', data)
            },

            doUnassignOrder(data) {
                this.loading = true
                this.unassignWriting(data.writing)
                    .then(() => {
                        var writing = this.writings.data[data.index]
                        writing.redactor = null
                        writing.redactor_id = null

                        this.writings.data.splice(index, 1, writing)
                    })
                    .catch(e => {
                        this.$store.dispatch('toast', {
                            type: 'error',
                            title: 'Une erreur est survenue',
                            message: e.response.message
                        })
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },

            setPrio: function (index, id, isPrio) {
                this.loading = true

                this.setPriority({ id: id, priority: isPrio })
                    .then(() => {
                        var writing = this.writings.data[index]
                        writing.isPrio = isPrio

                        this.writings.data.splice(index, 1, writing)
                    })
                    .catch(e => {
                        this.$store.dispatch('toast', {
                            type: 'error',
                            title: 'Une erreur est survenue',
                            message: e.response.message
                        })
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },

            togglePendingOrder(order) {
                if (order.pending_content) {
                    this.$emit('togglePendingOrder', { order: order, reason: null })
                } else {
                    this.$refs.confirmModal.openModal('pending', order)
                }
            },

            searchWordsCount(item, inputStr) {
                if (item.content.option_mots + 200 == inputStr) {
                    return true
                }

                return false
            },

            copyContent(e, content) {
                let testingCodeToCopy = this.$refs.copyInput
                testingCodeToCopy.setAttribute('type', 'text')

                testingCodeToCopy.value = content
                testingCodeToCopy.select()

                try {
                    document.execCommand('copy')
                    var btn = e.currentTarget

                    btn.classList.replace('btn-light', 'btn-info')
                    setTimeout(() => btn.classList.replace('btn-info', 'btn-light'), 2000)
                } catch (err) {
                    console.error('No copy ' + err)
                }
                testingCodeToCopy.setAttribute('type', 'hidden')
            },

            loadAsyncData() {
                this.loading = true
                this.getWritingDashboard('review').then(() => {
                    this.loading = false
                })
            },
            onSort(field, order) {
                this.config.sort = field
                this.config.sortDirection = order

                this.loadAsyncData()
            },
            onFilterChange(filter) {
                var filters = {}
                Object.keys(filter).forEach(element => {
                    filters[element] = filter[element]
                })
                this.config.filters = filters

                this.loadAsyncData()
            },
            onPageChange(page) {
                this.config.page = page

                this.loadAsyncData()
            }
        },
        mounted() {}
    }
</script>

<style>
    .writing-review-table {
        max-width: 100%;
        overflow-x: scroll;
        overflow-y: visible;
    }

    .display-writing-status {
        min-width: 150px;
    }
</style>
