<template>
    <div>
        <h2>Partenaire Logs</h2>
        <b-button class="mb-2" type="is-warning" @click="openNoteModal()"> Ajouter une note</b-button>

        <b-table
            :data="partnerLogs"
            :mobile-cards="true"
            :debounce-search="400"
            default-sort-direction="desc"
            default-sort="created_at"
            :striped="true"
            per-page="10"
            :paginated="true"
        >
            <b-table-column field="id" width="1.2em" sortable searchable label="Id" cell-class="align-middle">
                <div slot="default" slot-scope="props">#{{ props.row.id }}</div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Id..."
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="status" sortable searchable label="Statut" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    <TagEditable :init-value="props.row.status" :note-id="props.row.id" @input-blur="updateStatus" />
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.ref') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="type" searchable label="Type" cell-class="align-middle">
                <div slot="default" slot-scope="props">{{ props.row.type }} <br /></div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Type..."
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="notes" searchable label="Notes" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    {{ props.row.notes }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Note..."
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="admin" searchable label="Admin" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    {{ props.row.admin_note.user }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Utilisateur..."
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="created_at" sortable searchable label="Date" cell-class="align-middle">
                <div slot="default" slot-scope="props">
                    {{ moment(props.row.created_at).format('DD/MM/YYYY') }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="date"
                            class="form-control form-control-sm"
                            placeholder="Date..."
                        />
                    </div>
                </div>
            </b-table-column>
        </b-table>

        <NoteModal
            ref="noteModal"
            modal-type="free"
            :type-array="['Support', 'Developpement', 'Commercial', 'Communication', 'Comptabilité', 'Autre']"
            @send="addNoteToContent"
        />
    </div>
</template>

<script>
    import TagEditable from './EditableTag'

    export default {
        name: 'PartnerLogTable',
        components: { TagEditable },
        props: {
            partnerLogs: []
        },
        methods: {
            openNoteModal() {
                this.$refs.noteModal.openModal()
            },
            addNoteToContent() {
                this.$emit('reload')
            },
            updateStatus(data) {
                this.axios
                    .patch(`/admin/note/partner/${data.id}`, data)
                    .then(() => {
                        this.$emit('reload')
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
            }
        }
    }
</script>
