<template>
    <div>
        <button class="rounded button is-light rounded-lg text-decoration-none" @click="show = true">
            <b-icon icon="pen" />
        </button>
        <ValidationObserver v-slot="{ invalid }">
            <form class="" @submit.prevent="onSubmit">
                <nl-modal :title="title" :openModal="show" @hide="show = false">
                    <b-loading :is-full-page="false" v-model="isLoading" />
                    <div class="row">
                        <div class="col-12">
                            <ValidationProvider
                                tag="div"
                                v-slot="{ errors }"
                                :rules="`${ids.length ? '' : 'required'}|numeric`"
                                class="form-group"
                            >
                                <label for="">budget Offsite</label>
                                <div class="input-group mb-3">
                                    <input
                                        type="number"
                                        v-model="budget_offsite"
                                        class="form-control"
                                        :class="{ 'is-invalid': errors.length > 0 }"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text">€</span>
                                    </div>
                                </div>
                                <div class="invalid-feedback">
                                    {{ errors }}
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col-12">
                            <ValidationProvider
                                tag="div"
                                v-slot="{ errors }"
                                :rules="`${ids.length ? '' : 'required'}|numeric`"
                                class="form-group"
                            >
                                <label for="">budget Onsite</label>
                                <div class="input-group mb-3">
                                    <input
                                        type="number"
                                        v-model="budget_onsite"
                                        class="form-control"
                                        :class="{ 'is-invalid': errors.length > 0 }"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text">€</span>
                                    </div>
                                </div>
                                <div class="invalid-feedback">{{ errors[0] }}</div>
                            </ValidationProvider>
                        </div>
                        <div class="col-12">
                            <ValidationProvider tag="div" v-slot="{ errors }" class="form-group" rules="">
                                <label for="">observation</label>
                                <textarea
                                    v-model="observation"
                                    class="form-control"
                                    :class="{ 'is-invalid': errors.length > 0 }"
                                />
                                <div class="invalid-feedback">{{ errors[0] }}</div>
                            </ValidationProvider>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="">À facturer</label>
                                <div>
                                    <b-switch v-model="billable" type="is-success" :true-value="1" :false-value="0" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-slot:footer>
                        <b-button type="is-dark" @click="show = false" :disabled="isLoading"> Annuler</b-button>
                        <b-button type="is-warning" native-type="submit" :disabled="isLoading">
                            Sauvegarder les changements
                        </b-button>
                    </template>
                </nl-modal>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
    import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
    import { numeric, required } from 'vee-validate/dist/rules'
    import { mapActions, mapState } from 'vuex'

    import NlModal from '../UI/NlModal'

    extend('required', required)
    extend('numeric', numeric)

    export default {
        components: {
            ValidationObserver,
            ValidationProvider,
            NlModal
        },
        props: {
            budget: {
                type: Object,
                required: false,
                default: () => ({})
            },
            ids: {
                type: Array,
                required: false,
                default: () => []
            }
        },
        data() {
            return {
                show: false,
                isLoading: false,
                budget_offsite: this.budget.budget,
                budget_onsite: this.budget.budget_onsite,
                billable: this.budget.billable,
                observation: this.budget.observation
            }
        },
        computed: {
            ...mapState('campaigns', {
                campaignId: state => state.campaign.id
            }),
            title() {
                return this.budget?.periode ? 'Budget ' + this.$d(Date.parse(this.budget.periode), 'month') : 'Budget'
            }
        },
        watch: {
            show() {
                this.budget_offsite = this.budget.budget
                this.budget_onsite = this.budget.budget_onsite
                this.billable = this.budget.billable
                this.observation = this.budget.observation
            }
        },
        methods: {
            ...mapActions('campaigns', ['updateBudget']),
            onSubmit() {
                const data = {
                    ...(this.budget_offsite ? { budget: this.budget_offsite } : {}),
                    ...(this.budget_onsite ? { budget_onsite: this.budget_onsite } : {}),
                    ...(this.billable ? { billable: this.billable } : {}),
                    ...(this.observation !== null && this.observation !== undefined
                        ? { observation: this.observation }
                        : {})
                }
                if (Object.keys(data).length) {
                    this.isLoading = true
                    this.updateBudget({
                        id: this.campaignId,
                        data,
                        ids: [...this.ids, ...(this.budget?.id ? [this.budget.id] : [])]
                    }).finally(() => {
                        this.isLoading = false
                        this.show = false
                    })
                } else {
                    this.show = false
                }
            }
        }
    }
</script>

<style scoped>
    .icon {
        margin: 0;
    }

    .icon:first-child:last-child {
        margin-left: calc(-0.5em - 1px) !important;
        margin-right: calc(-0.5em - 1px) !important;
    }
</style>
