<template>
    <div>
        <NlTable
            async
            sortable
            :left-buttons="buttons"
            :columns.sync="columns"
            :get-data="loadAsyncData"
            :force-update="forceUpdate"
        >
            <template #keywords="{ props: { row } }">
                <KeywordModal :url-id="row.id" :keywords-count="row.keywords_count" />
            </template>

            <template #saleableSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small" expanded>
                    <option :value="null">Tous</option>
                    <option :value="true">Oui</option>
                    <option :value="false">Non</option>
                </b-select>
            </template>

            <template #saleable="{ props: { row } }">
                <b-tag v-if="row.saleable" type="is-success" size="is-small"> Oui</b-tag>
                <b-tag v-else type="is-danger" size="is-small"> Non</b-tag>
            </template>

            <template #response_codeSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small" expanded>
                    <option :value="null">Tous</option>
                    <option value="0">0</option>
                    <option value="100">1XX</option>
                    <option value="200">2XX</option>
                    <option value="300">3XX</option>
                    <option value="400">4XX</option>
                    <option value="500">5XX</option>
                </b-select>
            </template>

            <template #response_code="{ props: { row } }">
                <template v-if="row.scrapped_at">
                    <b-tag v-if="row.response_code === 200" type="is-success" size="is-small">
                        {{ row.response_code }}
                    </b-tag>
                    <b-tag v-else type="is-danger" size="is-small">
                        {{ row.response_code }}
                    </b-tag>
                </template>
                <template v-else> -</template>
            </template>

            <template #external_linksSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small" expanded>
                    <option :value="null">Tous</option>
                    <option :value="true">Oui</option>
                    <option :value="false">Non</option>
                </b-select>
            </template>

            <template #external_links="{ props: { row } }">
                <template v-if="row.scrapped_at">
                    <div v-if="row.external_links_count === 0">
                        <b-tag v-if="row.response_code === 200" type="is-success" size="is-small">
                            <b-icon icon="check" />
                        </b-tag>
                        <span v-else> - </span>
                    </div>
                    <div v-else>
                        <ExternalLinksModal
                            :external-links-count="row.external_links_count"
                            :external-links="row.external_links"
                            :url-id="row.id"
                        />
                    </div>
                </template>
                <template v-else> -</template>
            </template>

            <template #forbidden_classesSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small" expanded>
                    <option :value="null">Tous</option>
                    <option :value="true">Oui</option>
                    <option :value="false">Non</option>
                </b-select>
            </template>

            <template #forbidden_classes="{ props: { row } }">
                <template v-if="row.scrapped_at">
                    <b-tag
                        v-if="row.response_code === 200 && row.forbidden_classes.length === 0"
                        type="is-success"
                        size="is-small"
                    >
                        <b-icon icon="check" />
                    </b-tag>
                    <span v-else-if="row.response_code !== 200 && row.forbidden_classes.length === 0"> - </span>
                    <b-tag v-else type="is-danger" size="is-small">
                        <b-icon icon="times" />
                    </b-tag>
                </template>
                <template v-else> -</template>
            </template>

            <template #floor_priceSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small" expanded>
                    <option :value="null">Tous</option>
                    <option :value="true">Oui</option>
                    <option :value="false">Non</option>
                </b-select>
            </template>

            <template #floor_price="{ props: { row } }">
                <b-tag :type="isCorrectPrice(row) ? 'is-danger' : 'is-success'" size="is-small">
                    <b-icon :icon="isCorrectPrice(row) ? 'times' : 'check'" />
                    {{ finalPrice(row) | currency }}
                    <span v-if="row.floor_price !== 0 && row.floor_price != null">(Url)</span>
                    <span v-else-if="row.site.prix_minimum_filtre !== 0 && row.site.prix_minimum_filtre != null"
                        >(Global)</span
                    >
                </b-tag>
            </template>

            <template #price="{ props: { row } }">
                <span v-if="row.price !== null">
                    {{ row.site.prix_minimum_base > row.price ? row.site.prix_minimum_base : row.price | currency }}
                    <b-tooltip v-if="row.site.prix_minimum_base > row.price" label="Prix minimum base">
                        <sup>
                            <b-icon type="is-info" size="is-small" class="cursor-pointer" icon="info-circle" />
                        </sup>
                    </b-tooltip>
                </span>
                <span v-else> - </span>
            </template>

            <template #benefit="{ props: { row } }">
                <span v-if="row.price !== null && row.site.commission !== null">
                    {{ benefit(row) | currency }}
                </span>
                <span v-else> - </span>
            </template>

            <template #scrapped_at="{ props: { row } }">
                <span v-if="row.scrapped_at !== null">
                    <b-tag type="is-success" size="is-small">
                        {{ moment(row.scrapped_at).format('L') }}
                    </b-tag>
                </span>
                <span v-else>
                    <b-tag type="is-danger" size="is-small">
                        <b-icon icon="times" />
                    </b-tag>
                </span>
            </template>

            <template #scrapped_atSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small" expanded>
                    <option :value="null">Tous</option>
                    <option :value="true">Oui</option>
                    <option :value="false">Non</option>
                </b-select>
            </template>

            <template #actions="{ props: { row } }">
                <b-button
                    :disabled="scrapPending"
                    class="ml-2"
                    type="is-warning"
                    size="is-small"
                    icon-left="sync"
                    @click="scrapAnUrl(row.id)"
                />
            </template>
        </NlTable>
    </div>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable.vue'
    import { mapActions } from 'vuex'
    import KeywordModal from '@/components/Admin/Sites/Urls/KeywordModal.vue'
    import ExternalLinksModal from '@/components/Admin/Sites/Urls/ExternalLinksModal.vue'

    export default {
        name: 'UrlTable',
        components: { ExternalLinksModal, KeywordModal, NlTable },
        props: {
            displaySerpButtons: {
                type: Boolean,
                default: true
            },
            forceUpdate: {
                type: Boolean,
                default: false
            },
            priceUpdatePending: {
                type: Boolean,
                default: true
            },
            scrapPending: {
                type: Boolean,
                default: true
            },
            serpPending: {
                type: Boolean,
                default: true
            },
            status: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                columns: [
                    {
                        width: 500,
                        centered: false,
                        custom: false,
                        field: 'url',
                        label: 'Url',
                        searchable: true,
                        sortable: false
                    },
                    {
                        centered: false,
                        custom: true,
                        field: 'keywords',
                        label: 'Mots-clés',
                        searchable: false,
                        sortable: true
                    },
                    {
                        centered: false,
                        custom: true,
                        field: 'price',
                        label: 'Prix',
                        searchable: false,
                        sortable: false
                    },
                    {
                        centered: false,
                        custom: true,
                        field: 'benefit',
                        label: 'Gain',
                        searchable: false,
                        sortable: false
                    },
                    {
                        centered: true,
                        custom: true,
                        customSearch: true,
                        field: 'saleable',
                        label: 'Vendable',
                        hide: this.status === 'saleable' || this.status === 'not_saleable',
                        searchable: true,
                        sortable: false
                    },
                    {
                        centered: true,
                        custom: true,
                        customSearch: true,
                        field: 'response_code',
                        label: 'Code réponse',
                        searchable: true,
                        sortable: false
                    },
                    {
                        centered: true,
                        custom: true,
                        customSearch: true,
                        field: 'forbidden_classes',
                        label: 'Classes interdites',
                        searchable: true,
                        sortable: false
                    },
                    {
                        centered: false,
                        custom: true,
                        customSearch: true,
                        field: 'external_links',
                        label: 'Liens externes',
                        searchable: true,
                        sortable: false
                    },
                    {
                        centered: false,
                        custom: true,
                        customSearch: true,
                        field: 'floor_price',
                        label: 'Gain plancher',
                        searchable: true,
                        sortable: false
                    },
                    {
                        centered: true,
                        custom: true,
                        customSearch: true,
                        field: 'scrapped_at',
                        label: 'Date de scrap',
                        searchable: true,
                        sortable: true
                    },
                    {
                        centered: false,
                        custom: true,
                        field: 'actions',
                        label: 'Actions',
                        searchable: false,
                        sortable: false
                    }
                ]
            }
        },
        computed: {
            buttons() {
                return [
                    ...(this.displaySerpButtons ? [{
                        label: 'Serp',
                        type: 'is-info is-light',
                        icon: 'download',
                        action: () => {
                            this.$buefy.dialog.confirm({
                                title: 'Récupérer des mots clés',
                                message: 'Souhaitez-vous vraiment lancer la récupération de mot clés ?',
                                cancelText: 'Annuler',
                                confirmText: 'Lancer la récupération',
                                type: 'is-success',
                                onConfirm: () => this.$emit('serp')
                            })
                        },
                        disabled: this.serpPending || this.priceUpdatePending
                    }] : []),
                    ...(this.displaySerpButtons ? [{
                        label: 'Scrap',
                        type: 'is-info is-light',
                        icon: 'code',
                        action: () => {
                            this.$buefy.dialog.confirm({
                                title: 'Scrapper le site',
                                message: "Souhaitez-vous vraiment scrapper l'intégralité du site ?",
                                cancelText: 'Annuler',
                                confirmText: 'Scrapper',
                                type: 'is-success',
                                onConfirm: () => this.$emit('scrap')
                            })
                        },
                        disabled: this.scrapPending || this.priceUpdatePending
                    }] : []),
                    {
                        label: 'Reindex',
                        type: 'is-info is-light',
                        icon: 'search-plus',
                        action: () => {
                            this.$buefy.dialog.confirm({
                                title: 'Réindexer dans la recherche',
                                message: "Souhaitez-vous vraiment relancer l'indexation des urls dans la recherche ?",
                                cancelText: 'Annuler',
                                confirmText: 'Réindexer',
                                type: 'is-success',
                                onConfirm: () => this.$emit('reindex')
                            })
                        },
                        disabled: this.scrapPending || this.priceUpdatePending
                    }
                ]
            }
        },
        methods: {
            ...mapActions('urls', ['getUrls', 'getUrlsBySite', 'doScrapAnUrl']),

            finalPrice(row) {
                const finalPrice =
                    row.site.prix_minimum_filtre > row.floor_price ? row.site.prix_minimum_filtre : row.floor_price
                return finalPrice === 0 ? null : finalPrice
            },

            benefit(row) {
                const price = row.site.prix_minimum_base > row.price ? row.site.prix_minimum_base : row.price

                return (price * row.site.commission) / 100
            },

            isCorrectPrice(row) {
                return row.floor_price > this.benefit(row) || row.site.prix_minimum_filtre > this.benefit(row)
            },

            scrapAnUrl(urlId) {
                this.doScrapAnUrl({
                    url_id: urlId
                })
            },

            loadAsyncData(params) {
                if (this.type === 'all') {
                    return this.getUrls({
                        status: this.status,
                        params
                    })
                } else {
                    return this.getUrlsBySite({
                        site_id: this.$route.params.id,
                        status: this.status,
                        params
                    })
                }
            }
        }
    }
</script>
