<template>
    <div class="mb-3">
        <b-table :data="form">
            <slot />
        </b-table>
        <b-button
            type="is-primary"
            @click="
                $emit('update', {
                    key: configKey,
                    data: JSON.stringify(form),
                    type: 'table'
                })
            "
        >
            Modifier
        </b-button>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'TableConfig',
        props: {
            configKey: {
                required: true,
                type: String
            }
        },
        computed: {
            ...mapState('config', {
                config: state => state.config
            })
        },
        data() {
            return {
                form: []
            }
        },
        created() {
            this.form = Object.values(JSON.parse(this.config[this.configKey][0].value))
        }
    }
</script>

<style scoped></style>
