<template>
    <b-notification :closable="false" role="alert" type="is-danger is-light">
        <p>
            <i class="fa fa-exclamation-triangle mr-1" />
            <b>Erreur plugin</b> <br />
        </p>
        <slot />
    </b-notification>
</template>
<script>
    export default {
        name: 'WritingPluginErrorNotification',
        props: {}
    }
</script>
