import ApiService from '../../../services/api.service'

export default {
    getRanks(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/rank', params.model, params.config)
                .then(response => {
                    this.commit('setRanks', response.data)
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
