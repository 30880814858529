<template>
    <div class="d-block w-100">
        <div class="d-flex flex-row justify-content-between text-align-center">
            <span class="font-weight-bold">{{ title }}</span>
        </div>
        <div class="card-bottom pt-3 px-3 mb-2">
            <div class="d-flex flex-row justify-content-between text-align-center">
                <div class="d-flex flex-column">
                    <span v-if="subtitle" class="pl-0 ml-0" v-html="subtitle" />
                    <p>
                        <span v-if="amount != null" class="text-white font-weight-bold">{{
                            amount.toLocaleString()
                        }}</span>
                    </p>
                </div>
                <div class="fake-btn ml-3" @click="$emit('click')">
                    <i :class="icon" class="text-white" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Balance',
        props: {
            amount: {
                required: true,
                type: Number
            },
            title: {
                required: true,
                type: String
            },
            subtitle: {
                required: false,
                type: String,
                default: ''
            },
            icon: {
                required: false,
                type: String,
                default: 'fas fa-euro-sign'
            }
        }
    }
</script>
<style scoped>
    .card {
        border: none;
        border-radius: 10px;
        width: 275px;
        background-color: rgba(0, 0, 0, 0);
        /*background: linear-gradient(90deg, rgba(193,215,242,1) 0%, rgba(148,187,233,1) 100%);*/
    }

    .fa-ellipsis-v {
        font-size: 10px;
        color: #c2c2c4;
        margin-top: 6px;
        cursor: pointer;
    }

    .text-dark {
        font-weight: bold;
        margin-top: 8px;
        font-size: 13px;
        letter-spacing: 0.5px;
    }

    .card-bottom {
        background: #3e454d;
        border-radius: 6px;
    }

    .flex-column {
        color: #adb5bd;
        font-size: 13px;
    }

    .flex-column p {
        letter-spacing: 1px;
        font-size: 18px;
    }

    .fake-btn {
        height: 40px !important;
        margin-top: 3px;
        color: #212529;
        background: #f9bc14 linear-gradient(180deg, #f9c537, #f9bc14) repeat-x;
        border-color: #f9bc14;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0.25rem;
    }
</style>
