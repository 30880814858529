<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 v-if="moveData.active" class="h4 p-0 mb-0">
                        {{ $t('global.move') }}
                    </h4>
                    <h4 v-else class="h4 p-0 mb-0">
                        {{ $t('suivi.urldate') }}
                    </h4>
                </header>
                <div class="card-content">
                    <div class="content">
                        <section class="row">
                            <div v-if="moveData.active" class="col-12">
                                <p class="mt-4 text-center">
                                    {{ $t('suivi.move-orders') }} :
                                    <strong>#{{ this.listIds.join(', #') }}</strong>
                                    {{ $t('suivi.in-project') }}
                                    <strong>{{ moveData.project.name }}</strong
                                    >.
                                </p>
                            </div>

                            <div v-if="moveData.active" class="col-12 buttons justify-content-end">
                                <button class="button is-light" @click.prevent="closeAndResetModal">
                                    {{ $t('global.cancel') }}
                                </button>
                                <button class="button is-warning" @click.prevent="validate">
                                    {{ $t('global.validate') }}
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'MoveContentToProjectModal',
        props: ['checkedRows'],
        data() {
            return {
                modalIsOpen: false,
                listIds: [],
                dataLoading: false,

                moveData: {
                    active: false,
                    project: {
                        id: null,
                        name: null
                    }
                }
            }
        },

        watch: {
            checkedRows() {
                this.listIds = this.checkedRows.map(order => order.id)
            }
        },

        created() {
            this.listIds = this.checkedRows.map(order => order.id)
        },

        methods: {
            validate() {
                this.$parent.$emit('moveProject', {
                    project_id: this.moveData.project.id,
                    ids: this.listIds
                })

                this.closeAndResetModal()
            },

            openModal(projectOrOrder) {
                this.modalIsOpen = true

                this.moveData = {
                    active: true,
                    project: {
                        id: projectOrOrder.id,
                        name: projectOrOrder.name
                    }
                }
            },

            closeAndResetModal() {
                this.modalIsOpen = false
                this.$emit('uncheckRows')

                this.moveData = {
                    active: false,
                    project: {
                        id: null,
                        name: null
                    }
                }
            }
        }
    }
</script>

<style>
    label[for^='scrape_'] {
        color: #363636;
        display: block;
        font-size: 1rem;
        font-weight: 700;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
