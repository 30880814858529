<template>
    <tr>
        <td />
        <td>
            <span v-if="consultant.role === 'manager'">
                <b-tooltip label="Manager" class="is-primary">
                    <i class="fa fa-users" />
                </b-tooltip>
            </span>
            <span v-if="consultant.role === 'consultant'">
                <b-tooltip label="Consultant" class="text-secondary">
                    <i class="fa fa-user" />
                </b-tooltip>
            </span>
        </td>
        <td>
            <router-link :to="`/agency/consultants?id=${consultant.id}`">
                {{ consultant.firstname }} {{ consultant.lastname }}
            </router-link>
            <b-tooltip
                :label="'Se connecter au compte de ' + consultant.firstname + ' ' + consultant.lastname"
                position="is-top"
            >
                <LoginAs :user-id="consultant.user_id" class="ml-auto" />
            </b-tooltip>
        </td>
        <td>{{ consultant.email }}</td>
        <!--        <td class="text-right">{{ consultant.budget_initial | toCurrency }}</td>-->
        <td class="text-right">
            {{ consultant.credit_amount | toCurrency }}
        </td>
        <td class="text-right" />
        <td class="text-right">
            {{ consultant.clients_count }}
        </td>
        <td class="text-right">
            <slot />
        </td>
    </tr>
</template>

<style scoped>
    td {
        vertical-align: middle;
    }
</style>

<script>
    import LoginAs from '../../Admin/LoginAs'

    export default {
        components: { LoginAs },
        props: ['consultant'],
        data: () => ({})
    }
</script>
