<script>
    import '@/utils/validation'
    import NlLoader from '@/components/UI/NlLoader.vue'
    import countryList from '@/utils/countries'
    import { mapActions, mapGetters } from 'vuex'

    export default {
        components: {
            NlLoader
        },
        props: ['userProfile'],
        data: function () {
            return {
                langues: [
                    { text: 'Français', id: 'fr' },
                    { text: 'Anglais', id: 'en' }
                ],
                confirmDelete: null,
                confirmPassword: null,
                newPassword: null,
                profile: {},
                tab: 0,
                messages: [],
                alertType: null,
                attestation: null,
                savingAttestation: false,
                loading: false
            }
        },
        watch: {
            userProfile(user) {
                this.profile = user
            }
        },
        created() {
            this.profile = this.userProfile && this.userProfile?.id ? this.userProfile : {}
        },
        computed: {
            ...mapGetters('auth', ['user', 'checkRole']),
            partnerRules() {
                if (this.checkRole('partenaire')) {
                    return 'required'
                }
                return null
            },
            countries() {
                return countryList[this.$store.state.locale]
            },
            typesParrainage() {
                return [
                    { text: this.$t('profil.sponsorship.credit'), id: 'credit' },
                    { text: this.$t('profil.sponsorship.earn'), id: 'gain' }
                ]
            },
            totalFilleulsAmount() {
                let total = 0
                if (this.profile && this.profile.filleuls) {
                    this.profile.filleuls.forEach(f => {
                        total += f.montant
                    })
                }
                return total
            },
            isFrench() {
                return this.profile.country === 'FR'
            },
            isEuropean() {
                return [
                    'DE',
                    'AU',
                    'BE',
                    'BU',
                    'CY',
                    'HR',
                    'DK',
                    'ES',
                    'EE',
                    'FI',
                    'GR',
                    'HU',
                    'IR',
                    'IT',
                    'LV',
                    'LT',
                    'LU',
                    'MT',
                    'NL',
                    'PL',
                    'PT',
                    'CZ',
                    'RO',
                    'SK',
                    'SI',
                    'SE'
                ].includes(this.profile.country)
            },
            alertClass() {
                if (this.alertType === 'error') {
                    return 'alert-danger'
                }
                return 'alert-success'
            }
        },
        methods: {
            ...mapActions('auth', {
                updateUser: 'update',
                updatePassword: 'updatePassword',
                deleteUser: 'delete',
                saveAttestation: 'saveAttestation'
            }),
            ...mapActions('auth', ['me'])
        }
    }
</script>
