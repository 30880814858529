<template>
    <div>
        <div class="d-flex justify-content-end bg-white">
            <div class="pt-2 px-2 d-flex align-items-center justify-content-md-end">
                <p class="control mr-2 mt-2 mb-2">Résultats par page</p>
                <input
                    v-model="config.per_page"
                    class="form-control w-25"
                    type="number"
                    @keypress.enter="loadAsyncData"
                />
            </div>
            <div class="pt-2 px-2 d-flex align-items-center justify-content-md-end">
                <b-dropdown aria-role="list" :close-on-click="false" position="is-bottom-left">
                    <template #trigger="{ active }">
                        <b-button
                            :label="$t('global.columns')"
                            type="is-info"
                            class="mr-2"
                            :icon-right="active ? 'caret-up' : 'caret-down'"
                        />
                    </template>

                    <b-dropdown-item v-for="(col, index) in columns" :key="index" aria-role="listitem">
                        <b-field>
                            <b-checkbox v-model="col.active">
                                {{ col.name }}
                            </b-checkbox>
                        </b-field>
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>

        <b-table
            :data="writings.data"
            :default-sort="['id', 'desc']"
            :loading="loading"
            class="table-striped"
            :mobile-cards="true"
            :per-page="config.per_page"
            :show-detail-icon="true"
            :striped="true"
            :total="writings.total"
            backend-filtering
            backend-pagination
            backend-sorting
            paginated
            @sort="onSort"
            @page-change="onPageChange"
            @filters-change="onFilterChange"
        >
            <b-table-column :searchable="isSearchable" centered field="entity_type" label="Type" sortable width="0">
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.entity_type === 1">Content</span>
                    <span v-else>Preorder</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option :value="props.filters[props.column.field]">Tous</option>
                            <option value="1">Content</option>
                            <option value="2">Preorder</option>
                        </select>
                    </div>
                </div>
            </b-table-column>

            <b-table-column :searchable="isSearchable" centered field="entity_id" label="Ref" sortable width="0">
                <div slot="default" slot-scope="props">
                    <div class="tag" :class="[props.row.entity_type === 1 ? 'is-warning' : 'is-info']">
                        #{{ props.row.entity_id }}
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            class="form-control form-control-sm"
                            placeholder="Réf..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                centered
                field="writing_status_id"
                label="Statut"
                searchable
                header-class="display-writing-status"
            >
                <div slot="default" slot-scope="props">
                    <div class="form-group">
                        <select
                            v-model="props.row.writing_status_id"
                            class="form-control form-control-sm"
                            @change="doUpdateStatus(props.row)"
                        >
                            <option value="1">To do</option>
                            <option value="3">Review</option>
                            <option value="6">Done</option>
                            <option value="7">Error</option>
                        </select>
                    </div>
                </div>
            </b-table-column>

            <b-table-column :custom-search="searchDate" centered field="created_at" label="Créé le">
                <div slot="default" slot-scope="props">
                    {{ moment(props.row.created_at).format('L') }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            class="form-control form-control-sm"
                            placeholder="Date..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column :custom-search="searchDate" centered field="written_at" label="Rédigé le">
                <div slot="default" slot-scope="props">
                    {{ moment(props.row.written_at).format('L') }} à
                    {{ moment(props.row.written_at).format('HH:ss') }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            class="form-control form-control-sm"
                            placeholder="Date..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column field="redactor" label="Rédigé par">
                <div slot="default" slot-scope="props">
                    <div v-if="props.row.redactor">
                        <p>
                            {{ props.row.redactor.user.firstname }}
                            {{ props.row.redactor.user.lastname }}
                        </p>
                        <p>
                            <span class="text-muted">{{ props.row.redactor.user.email }}</span>
                        </p>
                    </div>
                </div>
            </b-table-column>

            <b-table-column field="buyer.firstname" label="Client">
                <div slot="default" slot-scope="props">
                    <div v-if="props.row.entity_type === 1">
                        <p>
                            {{ props.row.related_order.buyer_firstname }}
                            {{ props.row.related_order.buyer_lastname }}<br />
                            <span class="text-muted">{{ props.row.related_order.buyer_email }}</span>
                        </p>
                    </div>
                    <div v-if="props.row.entity_type === 2">
                        <p v-if="props.row.related_order.campaign && props.row.related_order.campaign.project">
                            {{ props.row.related_order.campaign.project.name }}
                        </p>
                    </div>
                </div>
            </b-table-column>

            <b-table-column :label="$t('redactor.bought-keywords')" field="keyword" searchable>
                <div slot="default" slot-scope="props">
                    <p v-if="props.row.related_order">
                        {{ props.row.related_order.keyword }}
                    </p>
                    <span v-if="props.row.entity_type === 1">{{ props.row.page }}</span>
                    <span v-else>{{ props.row.site.url }}</span>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            class="form-control form-control-sm"
                            placeholder="Mots clés..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column :label="$t('redactor.nbWords')" field="words_count" :visible="columns.words_count.active">
                <div slot="default" slot-scope="props">
                    {{ props.row.words_count }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            class="form-control form-control-sm"
                            placeholder="Option mots..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column :label="$t('partenaire-site.partner')" field="partner_email">
                <div slot="default" slot-scope="props">
                    {{ props.row.partner_id ? props.row.partner_email : 'Nextlevel' }}
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            class="form-control form-control-sm"
                            placeholder="Partenaire..."
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column :label="$t('redactor.link-anchors')" field="link" searchable>
                <div slot="default" slot-scope="props">
                    <div v-if="props.row.entity_type === 1">
                        <p>
                            {{ $t('global.link') }} :
                            <span>{{ props.row.primary_link }}</span>
                        </p>
                        <p>
                            {{ $t('global.anchor') }} :
                            <span class="text-muted">{{ props.row.primary_anchor }}</span>
                        </p>
                    </div>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            :placeholder="$t('redactor.link') + '...'"
                            class="form-control form-control-sm"
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column field="has_wordpress_plugin" label="Plugin">
                <div slot="default" slot-scope="props">
                    <p v-if="props.row.has_wordpress_plugin == 1">
                        <i class="fas fa-check-square text-success" />
                    </p>
                    <p v-else>
                        <i class="fas fa-times text-primary" />
                    </p>
                </div>
            </b-table-column>

            <b-table-column :label="$t('language.title')" field="lang" :visible="columns.lang.active">
                <div slot="default" slot-scope="props">
                    <p>
                        <flag :iso="props.row.lang == 'fr_fr' ? 'fr' : 'us'" />
                    </p>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            :placeholder="$t('language.title') + '...'"
                            class="form-control form-control-sm"
                            type="text"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column label="Actions">
                <div slot="default" slot-scope="props">
                    <div class="d-flex flex-column flex-md-row">
                        <b-tooltip size="is-small" position="is-left" label="Copier le contenu">
                            <button
                                class="btn btn-light"
                                @click.prevent="copyContent($event, props.row.writing_content)"
                            >
                                <i class="far fa-copy" />
                            </button>
                        </b-tooltip>

                        <b-tooltip size="is-small" position="is-left" label="Réessayer">
                            <button
                                class="btn btn-light ml-2"
                                :disabled="loadingIndex === props.index"
                                @click.prevent="retrySend(props.index, props.row)"
                            >
                                <i class="fas fa-redo-alt" />
                            </button>
                        </b-tooltip>

                        <b-tooltip size="is-small" position="is-left" label="J'ai publié manuellement">
                            <button
                                class="btn btn-success ml-2"
                                :disabled="loadingIndex === props.index"
                                @click="doWritingPublished(props.index, props.row)"
                            >
                                <i class="fas fa-clipboard-check" />
                            </button>
                        </b-tooltip>
                    </div>
                </div>
            </b-table-column>
        </b-table>

        <input ref="copyInput" type="hidden" />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'WritingsInReviewTable',
        data: function () {
            return {
                search_field: '',
                loading: false,
                loadingIndex: -1,
                status: 'Tous',

                isSearchable: true,
                isMobileWidth: window.innerWidth < 768
            }
        },
        created() {},
        computed: {
            ...mapState('redactors', {
                writings: state => state.writings,
                config: state => state.writingsConfig,

                wordsRemainingToWrite: state => state.wordsRemainingToWrite,
                maxWordsPerDay: state => state.maxWordsPerDay,

                columns: state => state.columns
            }),
            dailyWordsCount() {
                return (
                    Math.round((parseInt(this.wordsRemainingToWrite) / parseInt(this.maxWordsPerDay)) * 100) / 100
                ).toFixed(1)
            }
        },
        methods: {
            ...mapActions('redactors', [
                'getWritingDashboard',
                'retry',
                'setPriority',
                'unassignWriting',
                'setWritingPublished',
                'updateStatus'
            ]),

            doUpdateStatus(writing) {
                this.updateStatus(writing)
                    .then(() => {})
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
            },

            retrySend(index, writing) {
                this.loading = true
                this.loadingIndex = index
                this.retry(writing.id)
                    .then(() => {
                        this.writings.data.splice(index, 1)
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: "It's all good",
                            message: 'La commande a bien été publiée 👌'
                        })
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingIndex = -1
                    })
            },

            doWritingPublished(index, writing) {
                this.loading = true
                this.loadingIndex = index
                this.setWritingPublished(writing.id)
                    .then(() => {
                        this.writings.data.splice(index, 1)
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: "It's all good",
                            message: 'La commande a bien été publiée 👌'
                        })
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingIndex = -1
                    })
            },

            copyContent(e, content) {
                let testingCodeToCopy = this.$refs.copyInput
                testingCodeToCopy.setAttribute('type', 'text')

                testingCodeToCopy.value = content
                testingCodeToCopy.select()

                try {
                    document.execCommand('copy')
                    var btn = e.currentTarget

                    btn.classList.replace('btn-light', 'btn-info')
                    setTimeout(() => btn.classList.replace('btn-info', 'btn-light'), 2000)
                } catch (err) {
                    console.error('No copy ' + err)
                }
                testingCodeToCopy.setAttribute('type', 'hidden')
            },

            searchDate: function (item, inputStr) {
                var itemDate = this.moment(item.created_at).startOf('day').format('L')

                if (itemDate.includes(inputStr)) {
                    return true
                }

                return false
            },

            loadAsyncData() {
                this.loading = true
                this.getWritingDashboard('error').then(() => {
                    this.loading = false
                })
            },
            onSort(field, order) {
                this.config.sort = field
                this.config.sortDirection = order

                this.loadAsyncData()
            },
            onFilterChange(filter) {
                var filters = {}
                Object.keys(filter).forEach(element => {
                    filters[element] = filter[element]
                })
                this.config.filters = filters

                this.loadAsyncData()
            },
            onPageChange(page) {
                this.config.page = page

                this.loadAsyncData()
            }
        },
        mounted() {}
    }
</script>
