<template>
    <div>
        <b-loading v-model="isLoading" :is-full-page="false" />
        <div class="p-3 d-flex flex-column align-items-center w-100">
            <div class="d-flex w-100">
                <div class="p-2 w-100 mx-2">
                    <p class="font-weight-bold">Ajout des MC à travailler</p>
                    <div>
                        <div v-for="i in kw" :key="i.id">
                            <input v-model="checkKW" type="checkbox" :value="i" />
                            <label>{{ i.keyword }}</label>
                            <br />
                        </div>
                    </div>
                </div>

                <!--        <div class="p-2 w-100 mx-2">
                          <p class="font-weight-bold fs-5">Récupération SERP</p>
                          <div class="custom-loader container flex justify-content-centeralign-items-center"
                               v-if="isLoadingSerp"
                               style="margin-top: 10%"
                          ></div>
                          <div v-for="i in serpKW" :key="i.id" v-else>
                            <input type="checkbox" :value="i.keyword" v-model="checkKWS"/>
                            <label>{{ i.keyword }}</label>
                            <br/>
                          </div>
                        </div>-->
                <div class="p-2 w-100 mx-2">
                    <p class="font-weight-bold fs-5">Ajout manuel</p>
                    <textarea
                        v-model="customKW"
                        class="form-control"
                        style="height: 75% !important"
                        placeholder="Séparer les mots-clés par une virgule"
                    />
                </div>
            </div>
            <b-button class="mt-3 btn" type="is-warning" @click="startWork"> Valider</b-button>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'PreOrdersKWModal',
        props: {
            settings: {
                type: Object,
                required: false
            }
        },
        data() {
            return {
                kw: [],
                serpKW: null,
                customKW: null,
                isLoading: false,
                isLoadingSerp: false,
                checkKW: [],
                checkKWS: [],
                config: ''
            }
        },
        computed: {
            computeKWList() {
                let list = []
                this.checkKW.forEach(e => {
                    list.push(e)
                })
                if (this.customKW) {
                    let customKWList = this.customKW.split(/[\n,]/)
                    customKWList.forEach(e => {
                        list.push(e)
                    })
                }

                return list
            }
        },
        async created() {
            this.getConfigByName({ name: 'brief_preorders' })
                .then(config => (this.config = config))
                .catch(e => console.error(e))
            await this.loadKW()
            //this.loadSerp();
        },
        methods: {
            ...mapActions('config', ['getConfigByName']),
            closeModal() {
                this.$emit('closeModal')
            },
            loadKW() {
                this.isLoading = true
                this.axios
                    .get(`/admin/site-preorder/campaign/keywords`)
                    .then(response => {
                        for (const campaign of response.data.campaigns) {
                            for (const url of campaign.urls) {
                                for (const keyword of url.keywords) {
                                    this.kw.push({
                                        id: keyword.id,
                                        campaignId: campaign.id,
                                        keyword: keyword.mot_cle
                                    })
                                }
                            }
                        }
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            startWork() {
                let keywords = []
                this.computeKWList.forEach(element => {
                    let keywordTemp
                    keywordTemp = {
                        keyword: element,
                        posts_count: 1
                    }
                    keywords.push(keywordTemp)
                })
                let params = {
                    words_count: 500,
                    keywords: keywords,
                    lang: 'fr',
                    brief: this.config,
                    site_id: this.$route.query.id
                }
                this.isLoading = true
                this.axios
                    .post(`/admin/site-preorder/preorder`, params)
                    .then(response => console.log(response))
                    .catch(error => console.log(error))
                    .finally(() => {
                        this.isLoading = false
                        this.$emit('fetchData')
                        this.closeModal()
                    })
            }
        }
    }
</script>

<style></style>
