<template>
    <nl-modal ref="modal" :title="title" :add-legacy-events="false" position="right" size="lg">
        <template v-slot:header>
            <div class="bg-antracite text-white w-100 text-center p-4">
                <h1>{{ title }}</h1>
            </div>
        </template>

        <div v-if="loading">
            <nl-loader :show="true" />
        </div>
        <div v-else>
            <div class="row">
                <div class="col">
                    <Balance :amount="manager.consultants.length" icon="fas fa-user" subtitle="Consultants" title="" />
                </div>
                <div class="col">
                    <Balance
                        :amount="totalContents"
                        icon="fas fa-shopping-cart"
                        subtitle="Commandes totales"
                        title=""
                    />
                </div>
                <div class="col">
                    <Balance :amount="totalPaid" icon="fas fa-euro-sign" subtitle="Budget dépensé ce mois" title="" />
                </div>
            </div>

            <hr />
            <div class="d-flex mt-2">
                <div class="flex-grow-1">
                    <h2 class="h2">Consultants</h2>
                </div>
                <div class="">
                    <form class="form-inline">
                        <div class="form-group">
                            <label for="month">Mois :</label>
                            <select id="month" v-model="month" class="form-control ml-2">
                                <option
                                    v-for="n in 12"
                                    :value="
                                        moment()
                                            .startOf('year')
                                            .add(n - 1, 'month')
                                            .format('YYYY-MM-DD')
                                    "
                                >
                                    {{
                                        moment()
                                            .startOf('year')
                                            .add(n - 1, 'month')
                                            .format('MMMM')
                                    }}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>

            <b-table
                :data="manager.consultants"
                :mobile-cards="true"
                :narrow="true"
                detailed
                detail-key="id"
                :show-detail-icon="false"
                :opened-detailed="defaultOpenedDetails"
            >
                <b-table-column label="Consultant" numeric cell-class="align-middle">
                    <template v-slot="props">
                        <b-tooltip label="Affecter à un autre manager" position="is-right">
                            <button class="btn btn-secondary" @click.prevent="edit(props.row.id)">
                                <i class="fa fa-random" />
                            </button>
                        </b-tooltip>
                        <b-tooltip label="Retirer" position="is-right">
                            <button class="btn btn-secondary" @click.prevent="remove(props.index, props.row)">
                                <i class="fa fa-times" style="min-width: 15px" />
                            </button>
                        </b-tooltip>
                    </template>
                </b-table-column>
                <b-table-column cell-class="align-middle">
                    <template v-slot="props">
                        <router-link :to="`/consultant-details.php?id=${props.row.id}`">
                            {{ props.row.user.firstname }} {{ props.row.user.lastname }}
                        </router-link>
                        <br />
                        <div class="font-italic">
                            <small><i class="fa fa-at" /> {{ props.row.user.email }}</small>
                        </div>
                    </template>
                </b-table-column>
                <b-table-column field="contents_count" label="Commandes" numeric cell-class="align-middle">
                    <template v-slot="props">
                        {{ props.row.contents_count }}
                    </template>
                </b-table-column>
                <b-table-column
                    field="contents_sum_price_paid"
                    label="Budget dépensé"
                    numeric
                    cell-class="align-middle"
                >
                    <template v-slot="props">
                        {{ (props.row.contents_sum_price_paid || 0) | toCurrency }}
                    </template>
                </b-table-column>
                <b-table-column field="credit_amount" label="Crédit restant" numeric cell-class="align-middle">
                    <template v-slot="props">
                        {{ props.row.user.credit_amount | toCurrency }}
                    </template>
                </b-table-column>

                <template #detail="props">
                    <div class="d-flex">
                        <div class="p-0">
                            <b-field label="Attacher le consultant au manager...">
                                <SearchManager
                                    store
                                    @select="option => (props.row.manager_id = option ? option.id : null)"
                                />
                            </b-field>
                        </div>
                        <div class="p-0 flex-grow-1" />
                        <div class="p-0 flex-shrink-1 d-flex flex-column justify-content-end">
                            <button
                                class="btn btn-primary d-block my-1"
                                :disabled="updating"
                                @click.prevent="defaultOpenedDetails = []"
                            >
                                Annuler
                            </button>
                            <button
                                class="btn btn-secondary d-block my-1"
                                :disabled="updating"
                                @click.prevent="reaffectConsultant(props.index, props.row)"
                            >
                                Sauvegarder
                            </button>
                        </div>
                    </div>
                </template>
            </b-table>
        </div>
    </nl-modal>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import NlModal from '@/components/UI/NlModal'
    import NlLoader from '@/components/UI/NlLoader'
    import DashboardKPI from '../../UI/DashboardKPI'
    import AgencyModal from './AgencyModal'
    import SearchManager from '../Button/Search/SearchManager'

    export default {
        name: 'PreviewManagerModal',
        components: {
            SearchManager,
            DashboardKPI,
            NlLoader,
            NlModal
        },
        mixins: [AgencyModal],
        data: () => ({
            title: '',
            loading: true,
            updating: false,
            totalPaid: 0,
            totalContents: 0,
            defaultOpenedDetails: [1]
        }),
        watch: {
            selectedManager: function (newValue, oldValue) {
                if (!oldValue || (newValue && oldValue && newValue.id !== oldValue.id)) {
                    this.fetchData()
                }
            }
        },
        computed: {
            ...mapState('consultants', ['manager', 'managers', 'consultants']),
            selectedManager: {
                get() {
                    return this.$store.state.consultants.selectedManager
                },
                set(value) {
                    this.$store.commit('consultants/setSelectedManager', value)
                }
            },
            month: {
                get() {
                    return this.$store.state.consultants.month
                },
                set(value) {
                    this.$store.commit('consultants/setMonth', value)
                    this.fetchData()
                }
            }
        },
        methods: {
            ...mapActions('consultants', ['getManager', 'affectConsultant']),
            show() {
                if (this.selectedManager) {
                    this.title = `Manager ${this.selectedManager.firstname} ${this.selectedManager.lastname}`
                }
                this.$emit('show')
                this.$refs.modal.show()
            },
            edit(id) {
                this.defaultOpenedDetails = [id]
            },
            reaffectConsultant(index, consultant) {
                this.updating = true
                const payload = {
                    manager_id: consultant.manager_id,
                    id: consultant.id
                }
                this.affectConsultant(payload)
                    .then(data => {
                        this.defaultOpenedDetails = []
                        this.manager.consultants.splice(index, 1)

                        let managerIndex = this.managers.data.findIndex(manager => manager.id === consultant.manager_id)
                        if (managerIndex >= 0) {
                            let manager = this.managers.data[managerIndex]
                            manager.consultants.push(consultant)

                            this.managers.data.splice(managerIndex, 1, manager)
                        }

                        managerIndex = this.managers.data.findIndex(manager => manager.id === this.manager.id)
                        if (managerIndex >= 0) {
                            this.managers.data.splice(managerIndex, 1, this.manager)
                        }
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.updating = false
                    })
            },
            remove(index, consultant) {
                this.updating = true
                const payload = {
                    manager_id: null,
                    id: consultant.id
                }

                this.affectConsultant(payload)
                    .then(data => {
                        consultant.manager_id = null
                        this.manager.consultants.splice(index, 1)

                        this.consultants.data.push(consultant)

                        let managerIndex = this.managers.data.findIndex(manager => manager.id === this.manager.id)
                        if (managerIndex >= 0) {
                            this.managers.data.splice(managerIndex, 1, this.manager)
                        }
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            fetchData() {
                if (!this.selectedManager) {
                    return
                }

                this.loading = true
                this.getManager(this.selectedManager.id)
                    .then(() => {
                        this.totalPaid = 0
                        this.totalContents = 0

                        this.manager.consultants.map(consultant => {
                            this.totalPaid += consultant.contents_sum_price_paid
                            this.totalContents += consultant.contents_count
                        })
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
        mounted() {
            this.fetchData()
        }
    }
</script>
