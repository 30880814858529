<template>
    <tr>
        <td />
        <td>
            <span class="mr-2">
                <flag :iso="project.project_lang" />
            </span>
            {{ project.name }}
        </td>

        <td />
        <td />

        <td class="text-center">
            {{ project.price_paid_this_month | toCurrency }} /
            {{ project.budget | toCurrency }}
            <br />
            <hr />
            <b-progress :value="progession" show-value format="percent" />
        </td>
    </tr>
</template>

<style scoped>
    td {
        vertical-align: middle;
    }
</style>

<script>
    export default {
        props: ['project'],
        data: () => ({}),
        computed: {
            // un accesseur (getter) calculé
            progession: function () {
                if (!this.project.budget || this.project.budget === 0) {
                    return 0
                }

                return (this.project.price_paid_this_month / this.project.budget) * 100
            }
        }
    }
</script>
