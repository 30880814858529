<template>
    <div>
        <NlTable
            :columns.sync="columns"
            :get-data="loadAsyncData"
            :left-buttons="buttons"
            checkable
            :is-row-checkable="row => row.status === 'ai_completed'"
            :checked-rows.sync="checkedRows"
            :force-update="forceUpdate"
        >
            <template #statusSearch="{ props }">
                <b-select v-model="props.filters['status']" size="is-small" expanded>
                    <option :value="null">
                        {{ $t('global.all') }}
                    </option>
                    <option value="ai_in_progress">En cours</option>
                    <option value="ai_generated">Généré</option>
                    <option value="ai_canceled">Annulé</option>
                </b-select>
            </template>

            <template #status="{ props: { row } }">
                <span v-if="row.status === 'ai_in_progress'" class="tag is-warning">En cours</span>
                <span v-else-if="row.status === 'ai_completed'" class="tag is-success">Généré</span>
                <span v-else-if="row.status === 'ai_canceled'" class="tag is-danger">Annulé</span>
                <span v-else-if="row.status === 'published'" class="tag is-primary">Publié</span>
                <span v-else> - </span>
            </template>
        </NlTable>
        <AddContentItemModal :spot-id="spotId" @addContentItem="saveContentItem" ref="addContentItemModal" />
        <SpotsPublishModal
            :categories="wordpressCategories"
            :content-items-ids="checkedRows"
            @publish="bulkPublish"
            ref="spotPublishModal"
        />
    </div>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable.vue'
    import { mapActions } from 'vuex'
    import AddContentItemModal from './AddContentItemModal.vue'
    import SpotsPublishModal from './SpotsPublishModal.vue'

    export default {
        name: 'ContentItemTable',
        components: { SpotsPublishModal, AddContentItemModal, NlTable },
        props: {
            spotId: {
                type: Number,
                required: false,
                default: null
            }
        },
        data() {
            return {
                wordpressCategories: [],
                forceUpdate: false,
                checkedRows: [],
                buttons: [
                    {
                        label: 'Ajouter des articles',
                        icon: 'plus-circle',
                        type: 'is-warning',
                        action: this.addContentItems
                    },
                    {
                        label: 'Publier les articles',
                        icon: 'plus-circle',
                        type: 'is-warning',
                        action: this.publishContentItems,
                        disabled: true
                    }
                ],
                columns: [
                    {
                        field: 'id',
                        searchable: false,
                        sortable: false,
                        label: 'ID'
                    },
                    {
                        field: 'tag_title',
                        searchable: false,
                        sortable: false,
                        label: 'H1'
                    },
                    {
                        field: 'meta_title',
                        searchable: false,
                        sortable: false,
                        label: 'Meta Title'
                    },
                    {
                        field: 'url_target',
                        searchable: false,
                        sortable: false,
                        label: 'Url Target'
                    },
                    {
                        field: 'status',
                        custom: true,
                        searchable: true,
                        customSearch: true,
                        sortable: false,
                        label: 'Statut'
                    }
                ]
            }
        },
        watch: {
            checkedRows() {
                this.buttons[1].disabled = this.checkedRows.length <= 0
            }
        },
        created() {
            this.getWordpressCategories({ spotId: this.spotId }).then(response => {
                this.wordpressCategories = response.categories
            })
        },
        methods: {
            ...mapActions('spots', {
                getContentItems: 'getContentItems',
                createContentItem: 'createContentItem',
                getWordpressCategories: 'getWordpressCategories',
                spotPublishContentItems: 'spotPublishContentItems'
            }),
            loadAsyncData(params) {
                return this.getContentItems({
                    spot: this.spotId,
                    params: params
                })
            },
            addContentItems() {
                this.$refs.addContentItemModal.show()
            },
            saveContentItem(contentItem) {
                this.createContentItem({
                    spot_id: contentItem.spot_id,
                    params: {
                        tag_title: contentItem.tag_title,
                        meta_title: contentItem.meta_title
                    }
                })
                    .then(() => {
                        this.checkedRows = []
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'La génération des contenus a bien été lancée'
                        })
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(() => {
                        this.$store.dispatch('toastError', 'Une erreur est survenue')
                    })
            },
            publishContentItems() {
                this.$refs.spotPublishModal.show()
            },
            bulkPublish(data) {
                this.spotPublishContentItems({ spot: this.spotId, data })
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(() => {
                        this.$store.dispatch('toastError', 'Une erreur est survenue')
                    })
            }
        }
    }
</script>