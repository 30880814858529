<template>
    <div>
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: Number,
                default: () => 0
            }
        },
        data: () => ({
            tabs: []
        }),
        watch: {
            value() {
                this.activate()
            }
        },
        methods: {
            addItem(item) {
                const index = this.$slots.default.indexOf(item.$vnode)
                this.tabs.splice(index, 0, item)
                this.activate()
            },
            activate() {
                this.tabs.forEach(tab => {
                    tab.active = false
                })
                this.tabs[this.value].active = true
            },
            changeTab(index) {
                this.$emit('input', index)
            }
        }
    }
</script>

<style></style>
