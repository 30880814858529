<template>
    <div class="card">
        <div class="card-header">Projet</div>
        <div class="card-body">
            <div>
                <div v-if="readonly" class="d-flex justify-content-center px-2">
                    <span class="text-muted mr-1">
                        {{ campaign.project.project_lang === 'en' ? '🇬🇧' : '🇫🇷' }}
                        {{ campaign.project.name }}
                    </span>
                    <span class="ml-auto text-truncate"> {{ campaign.project.budget }} € </span>
                </div>
                <select v-else class="form-control" @change="updateProject($event)">
                    <option disabled :value="null" :selected="!campaign.project">-</option>
                    <option
                        v-for="project in campaign.meta.projects"
                        :key="project.id"
                        :value="project.id"
                        :selected="campaign.project && project.id === campaign.project.id"
                    >
                        {{ project.project_lang === 'en' ? '🇬🇧' : '🇫🇷' }}
                        {{ project.name }}
                        ({{ project.budget }} €)
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        props: {
            campaign: {
                required: true
            },
            readonly: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            ...mapActions('campaigns', ['updateCampaign']),
            updateProject(e) {
                this.updateCampaign({
                    campaignId: this.campaign.id,
                    project_id: e.target.value
                })
            }
        }
    }
</script>

<style scoped></style>
