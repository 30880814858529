import ApiService from '../../../services/api.service'

export default {
    list(context) {
        return new Promise((resolve, reject) => {
            ApiService.get('/user-attestation')
                .then(({ data }) => {
                    context.commit('setUserAttestation', data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    update(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.put('/user-attestation/' + model.id, model)
                .then(({ data }) => {
                    context.commit('setUserAttestation', data.list)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    delete(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.delete('/user-attestation/' + model.id)
                .then(({ data }) => {
                    context.commit('setUserAttestation', data.list)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    download(context, attestationId) {
        return new Promise((resolve, reject) => {
            ApiService.get(
                '/user-attestation/' + attestationId + '/download',
                {},
                {
                    responseType: 'arraybuffer',
                    headers: { Accept: 'application/octet-stream' }
                }
            )
                .then(data => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    validateUrssaf(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.post('/user-attestation/' + model.id + '/validate-urssaf', {
                code: model.code
            })
                .then(data => {
                    context.commit('setUserAttestation', data.list)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    }
}
