<template>
    <div id="columnEditable">
        <div v-if="type == 'input'">
            <span v-if="!editable" class="is-editable" @click="editable = !editable">{{ newValue }}</span>
            <input v-else v-model="newValue" class="input" type="text" @blur="inputBlur" />
        </div>
        <div v-else-if="type == 'select'">
            <span v-if="!editable" class="is-editable" @click="editable = !editable">{{
                groups != null
                    ? findGroup(newValue)
                    : newValue == 'nextlevel'
                    ? 'NL'
                    : newValue == 'proprietaire'
                    ? 'P'
                    : newValue
            }}</span>
            <div v-else class="select">
                <select v-model="newValue" @change="inputBlur($event.target)">
                    <slot />
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: () => 'input'
            },
            initValue: '',
            siteId: '',
            field: '',
            groups: null
        },
        data() {
            return {
                editable: false,
                newValue: ''
            }
        },
        watch: {
            initValue: function (newVal, oldVal) {
                this.newValue = newVal
            }
        },
        created() {
            this.newValue = this.initValue
        },
        methods: {
            inputBlur(test) {
                this.editable = !this.editable
                this.$emit('input-blur', {
                    id: this.siteId,
                    field: this.field,
                    value: this.newValue
                })
            },
            findGroup(groupId) {
                let group = this.groups.find(group => group.id == groupId)
                return group.name
            }
        }
    }
</script>
