<template>
    <div>
        <div @click="isOpen = true">
            <slot />
        </div>
        <nl-modal :open-modal="isOpen" :title="title" @hidden="isOpen = false">
            <iframe class="w-100" style="min-height: 75vh" :srcdoc="html" />
        </nl-modal>
    </div>
</template>

<script>
    import moment from 'moment'
    import NlModal from '../UI/NlModal'

    export default {
        name: 'MailPreviewModal',
        components: {
            NlModal
        },
        setup() {
            return {
                moment
            }
        },
        props: {
            html: {
                required: true,
                type: String
            },
            title: {
                required: true,
                type: String
            }
        },
        data: function () {
            return {
                isOpen: false
            }
        }
    }
</script>
