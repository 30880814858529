<template>
    <fragment>
        <hr />
        <div
            class="d-flex justify-content-between align-items-center cursor-pointer"
            @click="
                () => {
                    visibleTab = !visibleTab
                }
            "
        >
            <h2>Répartition</h2>
            <i v-if="!visibleTab" class="fas fa-caret-down fa-lg" />
            <i v-if="visibleTab" class="fas fa-caret-up fa-lg" />
        </div>
        <!--    <collapse-transition :duration="500" mode="out-in" origin="center">-->
        <div v-if="visibleTab" class="d-flex">
            <PieChart :data="sitesData" :options="chartOptions" />
        </div>
        <!--    </collapse-transition>-->
    </fragment>
</template>

<script>
    export default {
        name: 'RepartitionDashboard',
        props: {
            sites: {
                type: Array,
                required: false
            }
        },
        data() {
            return {
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false
                },
                visibleTab: false
            }
        },
        computed: {
            sitesData() {
                return {
                    //insert in labels the name of the sites
                    labels: this.sites.map(site => site.name),
                    datasets: [
                        {
                            label: 'Répartition des sites',
                            backgroundColor: this.sites.map(
                                () => '#' + Math.floor(Math.random() * 16777215).toString(16)
                            ),
                            data: this.sites.map(site => site.count)
                        }
                    ]
                }
            }
        }
    }
</script>

<style scoped></style>
