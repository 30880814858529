import ApiService from '../../../services/api.service'

export default {
    getSitePreorder(context, params) {
        const { campaignId, sort, page, per_page, filter } = params ? params : [null, null, null, null, null]

        return new Promise((resolve, reject) => {
            ApiService.get('/sites/preorders/' + campaignId, {
                sort,
                page,
                per_page,
                filter
            })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
