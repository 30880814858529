<template></template>

<script>
    import * as Toastr from 'toastr'

    export default {
        name: 'Toaster',
        components: {},
        data: function () {
            return {}
        },
        created() {
            Toastr.options = {
                closeButton: false,
                debug: false,
                newestOnTop: true,
                progressBar: true,
                positionClass: 'toast-top-right',
                preventDuplicates: true,
                onclick: null,
                showDuration: 300,
                hideDuration: 100,
                timeOut: 3500,
                extendedTimeOut: 1000,
                showEasing: 'swing',
                hideEasing: 'linear',
                showMethod: 'fadeIn',
                hideMethod: 'fadeOut'
            }
        },
        mounted() {},
        methods: {
            fire: function (type, message1, message2) {
                Toastr[type](message1, message2)
            },
            showValidationError: function (validationError) {
                Toastr.options['timeOut'] = 6000

                var str = ''
                Object.keys(validationError.errors).forEach(element => {
                    str += element + ' : '
                    str += validationError.errors[element]
                })

                Toastr['error'](str, validationError.message)
            }
        }
    }
</script>
