import Vue from 'vue'

import i18n from '../i18n/i18n.js'

Vue.filter('truncate', function (value, limit) {
    if (value.length > limit) {
        value = value.substring(0, limit - 3) + '...'
    }

    return value
})

Vue.filter('toCents', function (value) {
    return (value / 100).toFixed(2)
})

Vue.filter('toCurrency', function (value) {
    return new Intl.NumberFormat(i18n.locale, {
        style: 'currency',
        currency: 'EUR'
    }).format(value)
})

Vue.filter('toThousands', function (value) {
    return new Intl.NumberFormat(i18n.locale).format(value)
})

Vue.filter('hideProtocol', function (value) {
    if (!value) return ''
    return value.replace(new RegExp(/(http)s?:\/\/(www\.)?|\/$/gi), '')
})
