<template>
    <div>
        <Toaster ref="toaster" />
        <div :class="wrapperClass">
            <div class="actions-container">
                <per-page-selector
                    :value="meta.perPage"
                    @input="
                        e => {
                            setMeta({ perPage: e })
                            $emit('search')
                        }
                    "
                    :loading="loading"
                />
                <table-column-select :auto-set-visible="false" v-model="columns" />
            </div>
            <NlSearchTable
                :columns.sync="columns"
                :data="results"
                :sur-headers="surHeaders"
                :meta="meta"
                @meta="
                    e => {
                        setMeta(e)
                        $emit('search')
                    }
                "
                @search="$emit('search')"
                :total-field="isFavorite ? 'price' : null"
                :loading="loading"
                sortable
            >
                <template #actions="{ props: { row } }">
                    <div class="d-flex align-items-center h-100" style="flex: 1 1 auto; gap: 0.5rem">
                        <b-button
                            class="cursor-pointer text-warning"
                            type="is-ghost"
                            icon-left="star"
                            :icon-pack="findFavorites(row.id) ? 'fa' : 'far'"
                            @click="handleToggleFavorite(row.id)"
                        />
                        <notes v-if="findFavorites(row.id)" :favorite="findFavorites(row.id)" />
                        <b-tooltip label="Blacklist l'url projet" position="is-right" v-if="security.isAdmin">
                            <b-icon
                                class="cursor-pointer"
                                type="is-danger"
                                icon="times"
                                @click.native="() => openBlackListModal('project', row.id)"
                            />
                        </b-tooltip>
                        <b-tooltip
                            label="blacklist l'url pour tous les mots-clés"
                            position="is-right"
                            v-if="security.isAdmin"
                        >
                            <b-icon
                                class="cursor-pointer"
                                type="is-danger"
                                icon="ban"
                                @click.native="() => openBlackListModal('global', row.id)"
                            />
                        </b-tooltip>

                        <b-tooltip
                            label="Acceptation auto des commandes"
                            position="is-right"
                            v-if="security.isAdmin && row.site.accept_auto_orders_at"
                        >
                            <b-icon class="cursor-pointer" type="is-success" icon="check" />
                        </b-tooltip>
                    </div>
                </template>
                <template #project="{ props }">
                    <b-tooltip
                        v-if="favorites.find(e => e.stat_id === props.row.id)"
                        position="is-right"
                        :label="favorites.find(e => e.stat_id === props.row.id).project.name"
                    >
                        <div class="d-flex" style="gap: 0.5rem">
                            <b-icon icon="folder-open" type="is-dark" />
                            {{ favorites.find(e => e.stat_id === props.row.id).project.name }}
                        </div>
                    </b-tooltip>
                </template>
                <template #current_url="{ props }">
                    <a :href="props.row.current_url" target="_blank" rel="noreferrer noopener">
                        {{ props.row.current_url | hideProtocol }}
                    </a>
                </template>
                <template #keyword="{ props }">
                    {{ findMainKeyword(props.row).keyword }}
                </template>
                <template #position="{ props }"> Top {{ findMainKeyword(props.row).position }}</template>
                <template #volume="{ props }">
                    {{ findMainKeyword(props.row).volume }}
                </template>
                <template #url="{ props }">
                    <a :href="props.row.url" target="_blank">
                        {{ props.row.url | hideProtocol }}
                    </a>
                </template>
                <template #keywordsCount="{ props }">
                    <KeywordsModal
                        :keywords="props.row.keywords"
                        :keywords-count="props.row.keywordsCount"
                        button-outlined
                        button-type="is-dark"
                    />
                </template>
                <template #topTenCount="{ props }">
                    <KeywordsTopTenModal
                        :keywords="props.row.keywords"
                        :keywords-count="props.row.topTenCount"
                        button-outlined
                        button-type="is-dark"
                        v-if="props.row.topTenCount"
                    />
                    <div v-if="!props.row.topTenCount" style="text-align: center;">
                        —
                    </div>
                </template>
                <template #maxIndex="{ props }">
                    {{
                        Math.max.apply(
                            Math,
                            props.row.keywords?.map(k => k.index)
                        ) || '-'
                    }}
                </template>
                <template #siteSource="{ props }">
                    <div style="text-align: center">
                        <b-tooltip
                            :label="extractValue(props.row, props.column.field) ? $t('global.partner') : 'NextLevel'"
                        >
                            <b-icon
                                :icon="extractValue(props.row, props.column.field) ? 'handshake' : 'handshake-slash'"
                            />
                        </b-tooltip>
                    </div>
                </template>
                <template #groupName="{ props }">
                    {{ props.row?.site?.group?.find(g => g.lang === $store.state.locale)?.name || '-' }}
                </template>
                <template #price="{ props }">
                    <PurchaseModal
                        :contentModal="modalInfos.content"
                        :credits="credit"
                        :enabled="extractValue(props.row, props.column.field) > credit"
                        :informationModal="modalInfos.info"
                        :keyword="findMainKeyword(props.row).keyword"
                        :price="extractValue(props.row, props.column.field)"
                        :priceSupp="Math.round(extractValue(props.row, props.column.field) * 0.15)"
                        :security="security"
                        :site-id="siteId"
                        :url="props.row"
                        @bought="e => $emit('search', e)"
                        @error="e => $emit('search', e)"
                    />
                </template>
            </NlSearchTable>
        </div>

        <Blacklist
            :show-modal="showBlackListModal"
            :type="blackListType"
            @confirm="addToBlackList()"
            @close="resetBlackListModal"
        >
        </Blacklist>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex'
    import Toaster from '@/components/UI/Toaster'
    import ApiService from '../../services/api.service'
    import Blacklist from './Blacklist'
    import NlSearchTable from '@/components/Search/NlSearchTable'
    import TableColumnSelect from '@/components/UI/Table/TableColumnSelect'
    import PerPageSelector from '@/components/UI/Table/PerPageSelector'
    import Notes from '@/components/Favorites/Notes.vue'
    import { extractValue } from '@/helpers/objectHelpers'
    import KeywordsModal from '@/components/Modals/KeywordsModal.vue'
    import PurchaseModal from '@/components/Search/PurchaseModal/PurchaseModal.vue'
    import KeywordsTopTenModal from "@/components/Modals/KeywordsTopTenModal.vue";

    export default {
        name: 'SearchResult',
        components: {
            PurchaseModal,
            KeywordsModal,
            Blacklist,
            Toaster,
            NlSearchTable,
            TableColumnSelect,
            PerPageSelector,
            Notes,
            KeywordsTopTenModal
        },
        props: {
            results: Array,
            credit: {
                type: Number,
                required: true
            },
            projectId: { required: true },
            projectName: { type: String, default: null },
            selectable: { type: Boolean, default: false },
            displayCurrentPosition: { type: Boolean, default: false },
            displayCurrentUrl: { type: Boolean, default: false },
            modalInfos: {
                type: Object,
                required: true
            },
            isFavorite: { type: Boolean, default: false },
            loading: { type: Boolean, default: false },
            wrapperClass: {
                type: String,
                default: 'box'
            },
            innerHitsKey: {
                type: String,
                default: 'keywords_hits'
            }
        },
        data() {
            return {
                siteId: null,
                showBlackListModal: false,
                blackListType: null,

                columns: []
            }
        },
        computed: {
            ...mapState('auth', {
                security: state => state.security,
                credits: state => state.user?.credit_amount
            }),
            ...mapState('favorites', {
                favorites: 'favorites'
            }),
            ...mapState({
                project: 'project'
            }),
            ...mapState('project_selector', {
                defaultProject: 'defaultProject'
            }),
            ...mapState('search', { filters: 'filters', meta: 'meta' }),
            surHeaders() {
                return [
                    ...(this.displayCurrentUrl || this.displayCurrentPosition
                        ? [
                              {
                                  label: 'Votre Site',
                                  colspan: this.columns.filter(e => e.visible && !e.hide && e.surHeader === 'yourSite')
                                      .length
                              }
                          ]
                        : []),
                    {
                        label: 'Mot-clé',
                        colspan: this.columns.filter(e => e.visible && !e.hide && e.surHeader === 'keyword').length
                    },
                    {
                        label: 'Url',
                        colspan: this.columns.filter(e => e.visible && !e.hide && e.surHeader === 'url').length
                    },
                    {
                        label: 'Site',
                        colspan: this.columns.filter(e => e.visible && !e.hide && e.surHeader === 'site').length
                    },
                    {
                        label: 'Commander',
                        colspan: this.columns.filter(e => e.visible && !e.hide && e.surHeader === 'command').length
                    }
                ]
            }
        },
        methods: {
            extractValue,
            ...mapMutations('search', {
                setMeta: 'setMeta'
            }),
            ...mapActions('favorites', {
                getFavorites: 'index',
                storeFavorite: 'storeFavorite',
                deleteFavorite: 'deleteFavorite'
            }),
            findMainKeyword(row) {
                if (this.isFavorite) {
                    const filtered = row.keywords.filter(
                        k => !this.filters.keyword || k.keyword.includes(this.filters.keyword)
                    )

                    return (filtered.length ? filtered : row.keywords).reduce((prevent, current) => {
                        return prevent.index < current.index ? prevent : current
                    })
                } else {
                    return (
                        row?.[this.innerHitsKey]?.[0]._source ??
                        row.keywords.reduce((prevent, current) => {
                            return prevent.index < current.index ? prevent : current
                        })
                    )
                }
            },
            findFavorites(id) {
                return this.favorites?.find(e => e.url_id === id) || null
            },
            openBlackListModal(projectOrGlobal, id) {
                this.blackListType = projectOrGlobal
                this.statId = id
                this.showBlackListModal = true
            },
            addToBlackList() {
                let stat = this.results.find(r => r.id === this.statId)

                if (this.blackListType === 'project') {
                    this.addToBlackListProject(stat)
                } else {
                    this.addToBlackListGlobal(stat)
                }
            },
            addToBlackListGlobal(stat) {
                this.resetBlackListModal()
                ApiService.post('/admin/blacklist', {
                    blacklist: stat.url + '#####' + stat.keyword
                })
                    .then(response => {
                        this.$delete(
                            this.results,
                            this.results.findIndex(r => r.url === stat.url)
                        ) // Remove line

                        let toastParams = {
                            message: response.data.message,
                            type: 'is-success'
                        }
                        this.$buefy.toast.open(toastParams)

                        // TODO l'appel pour suppression ne devrait avoir lieu que après avoir vérifié qu'il y a au moins 1 favori à supprimer
                        ApiService.put('/admin/blacklist/byUrl', {
                            id: stat.id,
                            project_id: this.projectId,
                            url: stat.url
                        })
                            .then(response => {
                                if (response.data.success) {
                                    let toastParams = {
                                        message: response.data.message,
                                        type: 'is-success',
                                        duration: 5000,
                                        queue: false
                                    }
                                    this.$buefy.toast.open(toastParams)
                                }
                            })
                            .catch(() => {
                                let toastParams = {
                                    message: 'Une erreur est survenue pendant la suppression de votre favori',
                                    type: 'is-danger',
                                    duration: 5000,
                                    queue: false
                                }
                                this.$buefy.toast.open(toastParams)
                            })
                    })
                    .catch(() => {
                        this.$buefy.toast.open({
                            message: 'Une erreur est survenue pendant le blacklistage',
                            type: 'is-danger'
                        })
                    })
            },
            addToBlackListProject(stat) {
                this.resetBlackListModal()
                ApiService.post('/admin/blacklist/project', {
                    blacklist: stat.url,
                    project_id: this.projectId
                })
                    .then(response => {
                        let host = response.data.host
                        this.results = this.results.filter(r => {
                            return r.url.toLowerCase().indexOf(host) === -1
                        }) // Remove lines

                        let toastParams = {
                            message: response.data.message,
                            type: 'is-success'
                        }
                        this.$buefy.toast.open(toastParams)
                    })
                    .catch(() => {
                        this.$buefy.toast.open({
                            message: 'Une erreur est survenue pendant le blacklistage',
                            type: 'is-danger'
                        })
                    })
            },
            handleToggleFavorite(url_id) {
                if (this.findFavorites(url_id)) {
                    this.deleteFavorite({
                        url_id,
                        project_id: this.project.id ?? this.defaultProject.id
                    })
                } else {
                    this.storeFavorite({
                        url_id,
                        project_id: this.project.id ?? this.defaultProject.id
                    })
                }
            },
            resetBlackListModal() {
                this.statId = null
                this.showBlackListModal = false
                this.blackListType = null
            }
        },
        created() {
            this.columns = [
                {
                    field: 'actions',
                    label: '',
                    custom: true,
                    required: true,
                    surHeader: this.displayCurrentUrl || this.displayCurrentPosition ? 'yourSite' : 'keyword',
                    sortable: false
                },
                {
                    field: 'current_url',
                    label: this.$t('tableau-keywords.yoururl'),
                    hide: !this.displayCurrentUrl,
                    custom: true,
                    surHeader: 'yourSite',
                    centered: false,
                    sortable: false
                },
                {
                    field: 'current_position',
                    label: this.$t('tableau-keywords.yourposition'),
                    hide: !this.displayCurrentPosition,
                    surHeader: 'yourSite',
                    centered: true,
                    sortable: false
                },
                {
                    field: 'project',
                    label: this.$t('global.project'),
                    custom: true,
                    hide: !this.isFavorite,
                    surHeader: 'keyword',
                    sortable: false
                },
                {
                    field: 'keyword',
                    label: this.$t('global.keyword'),
                    tooltip: this.$t('tableau-keywords.keywordavailable'),
                    surHeader: 'keyword',
                    custom: true,
                    sortable: false
                },
                {
                    field: 'position',
                    label: this.$t('tableau-keywords.position'),
                    tooltip: this.$t('tableau-keywords.serpPosition'),
                    offended:
                        this.security.isProspect &&
                        !this.credits &&
                        !this.security.seeKeyword &&
                        !this.security.displaySearchResults,
                    prefix: 'Top',
                    surHeader: 'keyword',
                    custom: true,
                    sortable: false
                },
                {
                    field: 'volume',
                    label: this.$t('global.quantity'),
                    tooltip: this.$t('tableau-keywords.quantity'),
                    surHeader: 'keyword',
                    custom: true,
                    sortable: false
                },
                {
                    field: 'url',
                    label: 'Url',
                    custom: true,
                    tooltip: '',
                    hide: !this.security.displayUrl,
                    surHeader: 'url',
                    filter: new RegExp(/(http)s?:\/\/(www\.)?|\/$/gi)
                },
                {
                    field: 'keywordsCount',
                    label: `${this.$t('tableau-keywords.numberkeywordpositionned')}`,
                    tooltip: this.$t('tableau-keywords.keywordpositionnednumber'),
                    surHeader: 'url',
                    custom: true,
                },
                {
                    field: 'topTenCount',
                    label: 'Top 10',
                    tooltip: this.$t('tableau-keywords.keywordtop10'),
                    custom: true,
                    offended:
                        this.security.isProspect &&
                        !this.credits &&
                        !this.security.seeKeyword &&
                        !this.security.displaySearchResults,
                    surHeader: 'url',
                },
                {
                    field: 'maxIndex',
                    label: this.$t('tableau-keywords.urlindice'),
                    tooltip: this.$t('tableau-keywords.indiceURL'),
                    surHeader: 'url',
                    custom: true,
                    sortField: 'index',
                },
                {
                    field: 'site.source',
                    label: 'Source',
                    custom: true,
                    sortable: false,
                    surHeader: 'site'
                },
                {
                    field: 'site.type',
                    label: this.$t('global.category'),
                    tooltip: this.$t('tableau-keywords.grades'),
                    surHeader: 'site',
                    sortable: false
                },
                {
                    field: 'site.kpis.rd',
                    label: 'RD',
                    tooltip: 'Referring Domains (Majestic)',
                    hide: !this.security.displaySiteKpi,
                    surHeader: 'site',
                },
                {
                    field: 'site.kpis.tf',
                    label: 'TF',
                    tooltip: 'Trust Flow (Majestic)',
                    hide: !this.security.displaySiteKpi,
                    surHeader: 'site',
                },
                {
                    field: 'site.kpis.cf',
                    label: 'CF',
                    tooltip: 'Citation Flow (Majestic)',
                    hide: !this.security.displaySiteKpi,
                    surHeader: 'site',
                },
                {
                    field: 'site.kpis.dr',
                    label: 'DR',
                    tooltip: 'Domain Rating (a href)',
                    hide: !this.security.displaySiteKpi,
                    surHeader: 'site',
                },
                {
                    field: 'groupName',
                    label: this.$t('global.theme'),
                    tooltip: this.$t('tableau-keywords.themeorglobal'),
                    surHeader: 'site',
                    custom: true,
                    sortable: false
                },
                {
                    field: 'price',
                    label: this.$t('global.price'),
                    tooltip: this.$t('tableau-keywords.pricewithoutVAT'),
                    custom: true,
                    surHeader: 'commande',
                    centered: false,
                }
            ]
        }
    }
</script>

<style lang="scss" scoped>
    .actions-container {
        display: flex;
        gap: 0.5rem;
        padding: 0.5rem 0;
    }

    @media screen and (min-width: 768px) {
        .actions-container {
            justify-content: flex-end;
        }
    }

    @media screen and (max-width: 768px) {
        .actions-container > ::v-deep * {
            flex: 1 1 100px;
        }
    }
</style>