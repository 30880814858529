<template>
    <div>
        <router-link class="nav_link" :to="`/redactor/details?id=${user.id}`">
            <i class="fas fa-tachometer-alt nav_icon" />
            <span class="nav_name">{{ $t('layout.dashboard') }}</span>
        </router-link>

        <router-link class="nav_link" to="/redactor">
            <i class="fas fa-pen nav_icon" />
            <span class="nav_name">{{ $t('redactor.title') }}</span>
        </router-link>
    </div>
</template>
<script>
    import { mapState } from 'vuex'

    export default {
        name: 'RedactorMenu',
        computed: {
            ...mapState('auth', {
                user: state => state.user
            })
        }
    }
</script>
