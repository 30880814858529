<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">Confirmer</h4>
                </header>
                <div class="card-content">
                    <div class="content">
                        <form>
                            <section class="row mb-3">
                                <div v-if="typeUnassigned.active && writing && user" class="col-12">
                                    <p class="mt-4 text-center">
                                        Êtes-vous sûr de vouloir désaffecter
                                        <strong>{{ `${user.firstname} ${user.lastname} (${user.email})` }}</strong>
                                        de la commande <strong>#{{ writing.id }}</strong
                                        >.
                                    </p>
                                </div>

                                <div v-if="typePending.active && writing && !writing.pending_content" class="col-12">
                                    <div class="form-group">
                                        <label class="label">Vous devez indiquer un motif de suspension</label>
                                        <textarea
                                            v-model="typePending.reason"
                                            class="form-control"
                                            rows="4"
                                            placeholder="Ex: en attente de retour partenaire"
                                            required
                                        />
                                        <small v-if="errorReason" class="text-danger">{{ errorReason }}.</small>
                                    </div>
                                </div>
                            </section>

                            <div class="buttons justify-content-end">
                                <button class="button is-light" @click.prevent="closeAndResetModal">
                                    {{ $t('global.cancel') }}
                                </button>
                                <button class="button is-warning" @click.prevent="validate">
                                    {{ $t('global.validate') }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'RedactorConfirmModal',
        props: [],
        data() {
            return {
                modalIsOpen: false,
                writing: null,
                index: null,
                user: null,

                typeUnassigned: {
                    active: false
                },
                typePending: {
                    active: false,
                    reason: ''
                },
                errorReason: ''
            }
        },

        methods: {
            validate() {
                if (this.typeUnassigned.active) {
                    this.$emit('unassignRedactorWriting', {
                        writing: this.writing,
                        index: this.index
                    })
                }

                if (this.typePending.active) {
                    if (!this.typePending.reason.length) {
                        this.errorReason = 'Le motif de suspension est requis'
                        return
                    }
                    this.errorReason = null
                    this.$emit('togglePendingWriting', {
                        writing: this.writing,
                        reason: this.typePending.reason
                    })
                }

                this.closeAndResetModal()
            },

            openModal(type, data) {
                this.modalIsOpen = true
                this.writing = data.writing
                this.index = data.index

                if (type === 'unassigned-redactors') {
                    this.typeUnassigned.active = true
                    this.user = writing.user
                    this.writing = writing.redactor_content.content
                }

                if (type === 'processing') {
                    this.typeUnassigned.active = true
                    if (this.writing.redactor.user) {
                        this.user = this.writing.redactor.user
                    } else {
                        this.user = {
                            id: this.writing.redactor.id,
                            firstname: this.writing.redactor.firstname,
                            lastname: this.writing.redactor.lastname,
                            email: this.writing.redactor.email
                        }
                    }
                }

                if (type === 'pending') {
                    this.typePending.active = true
                    this.user = null
                }
            },

            closeAndResetModal() {
                this.modalIsOpen = false
                this.writing = null
                this.index = null
                this.user = null

                this.typeUnassigned.active = false
                this.typePending = {
                    active: false,
                    reason: null
                }
            }
        }
    }
</script>
