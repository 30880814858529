<template>
    <ValidationProvider :name="name" :rules="rules" slim >
        <template #default="{valid, touched}">
            <b-field :label="label" :message="!valid && touched ? 'Veuillez saisir un URL valide' : null" :type="{'is-danger': !valid && touched}">
                <b-autocomplete
                        :value="value"
                        @input="handleInput"
                        :data="filteredSuggestions"
                        dropdown-position='top'
                        clearable
                        open-on-focus
                        expanded
                        class="w-100"
                >
                    <template #empty> Pas de résultats</template>
                </b-autocomplete>
            </b-field>
        </template>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate'

    export default {
    name: 'PurchaseModalLink',
    components: {ValidationProvider},
    props: {
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: false,
            default: 'URL du lien à placer'
        },
        value: {
            type: String,
            required: false
        },
        rules: {
            type: String,
            default: 'required|url'
        },
        suggestions: {
            type: Array,
            required: true
        }
    },
    methods: {
        handleInput(value) {
            this.$emit('input', value)
        }
    },
    computed: {
        filteredSuggestions() {
            return this.suggestions.filter(option => {
                return option.toString().toLowerCase().includes(this.value?.toLowerCase() ?? '')
            })
        }
    }
}
</script>
