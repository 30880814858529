import ApiService from '../../../services/api.service'
import { getCache, setCache } from '@/helpers/localStorageHelper'

let searchAbortController = new AbortController()

export default {
    getAutocompletePartners(context, value) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/user/partner/search?filter[search]=${value}`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    getFiltersOptions() {
        return (
            getCache('filtersOptions') ??
            new Promise((resolve, reject) => {
                ApiService.get('/filter/init')
                    .then(({ data }) => {
                        setCache('filtersOptions', data)
                        resolve(data)
                    })
                    .catch(e => {
                        reject(e)
                    })
            })
        )
    },
    async searchByKeywords(context, params) {
        searchAbortController.abort()

        searchAbortController = new AbortController()

        return new Promise((resolve, reject) => {
            ApiService.get('/search/keyword', params, {
                signal: searchAbortController.signal
            })
                .then(({ data }) => {
                    context.commit('setResults', { data: data.results })
                    context.commit('setMeta', { total: data.total })
                    resolve(data)
                })
                .catch(e => {
                    context.commit('resetResults')
                    reject(e)
                })
        })
    },
    searchByUrl(context, params) {
        searchAbortController.abort()

        searchAbortController = new AbortController()

        return new Promise((resolve, reject) => {
            ApiService.get('/search/url', params, {
                signal: searchAbortController.signal
            })
                .then(({ data }) => {
                    context.commit('setResults', { data: data.results })
                    context.commit('setMeta', { total: data.total })
                    resolve(data)
                })
                .catch(e => {
                    context.commit('resetResults')
                    reject(e)
                })
        })
    },
    searchByDomain(context, params) {
        searchAbortController.abort()

        searchAbortController = new AbortController()

        return new Promise((resolve, reject) => {
            ApiService.get('/search/domain', params, {
                signal: searchAbortController.signal
            })
                .then(({ data }) => {
                    context.commit('setResults', { data: data.results })
                    context.commit('setMeta', { total: data.total })
                    resolve(data)
                })
                .catch(e => {
                    context.commit('resetResults')
                    reject(e)
                })
        })
    },
    searchByFavorites(context, params) {
        searchAbortController.abort()

        searchAbortController = new AbortController()

        return new Promise((resolve, reject) => {
            ApiService.get('/favorite/search-by-favorite', params, {
                signal: searchAbortController.signal
            })
                .then(({ data }) => {
                    context.commit('setResults', { data: data.results })
                    context.commit('setMeta', { total: data.total })
                    resolve(data)
                })
                .catch(e => {
                    context.commit('resetResults')
                    reject(e)
                })
        })
    },
    prepareGSCSearch(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/search_gsc/prepare', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(() => {
                    context.commit('resetResults')
                    reject()
                })
        })
    },
    getGSCKeywords(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/search_gsc', params)
                .then(({ data }) => {
                    context.commit('setResults', { data: data.results })
                    context.commit('setMeta', { total: data.total })
                    resolve(data)
                })
                .catch(() => {
                    context.commit('resetResults')
                    reject()
                })
        })
    },

    resetSearch(context, deep = true) {
        context.commit('resetResults')
        context.commit('setFilters', {
            thematic: [],
            thematicExcluded: [],
            price: null,
            price_min: null,
            source: null,
            adult: false,
            top: null,
            volume: null,
            index: null,
            category: null,
            keywordsCount: null,
            top10: null,
            partner: [],
            lang: 'fr',
            rd_min: null,
            rd_max: null,
            tf_min: null,
            tf_max: null,
            cf_min: null,
            cf_max: null,
            dr_min: null,
            dr_max: null,
            siteAge: null,

            volumeMin: 10,
            volumeMax: null,
            keywordSearch: null,
            positionMin: 1,
            positionMax: 99,
            ...(deep
                ? {
                      urlSearch: null,
                      keyword: null
                  }
                : {})
        })
        context.commit('setMeta', {
            orderBy: 'desc',
            sortBy: 'keywordsCount',
            page: 1,
            perPage: 25,
            total: 0
        })
    }
}
