<template>
    <modal v-model="activeModal" :modal-title="$t('global.addsource')" @cancel="closeModal" @save="addSource">
        <nl-alert ref="alert" />
        <form>
            <b-field label="Source">
                <b-select
                    v-model="modalData.source"
                    :items="sources"
                    item-text="name"
                    placeholder="Source"
                    expanded
                    required
                >
                    <option v-for="(value, key) in sources" :key="key" :value="key">
                        {{ value }}
                    </option>
                </b-select>
            </b-field>
        </form>
    </modal>
</template>

<script>
    import Modal from '../../../components/UI/Modal/Modal'

    export default {
        components: {
            Modal
        },
        props: {
            activeModal: {
                type: Boolean,
                required: true
            },
            sources: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            modalData: { source: null }
        }),
        methods: {
            addSource() {
                if (this.modalData.source != null) {
                    this.$emit('addSource', this.modalData.source)
                    this.closeModal()
                }
            },
            resetData() {
                this.modalData.source = null
            },
            closeModal() {
                this.resetData()
                this.$emit('closeModal')
            }
        }
    }
</script>

<style></style>
