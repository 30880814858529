export default {
    filters: {
        adult: false,
        allowBought: false,
        cf_max: null,
        cf_min: null,
        clicksMax: null,
        clicksMin: null,
        dr_max: null,
        dr_min: null,
        impressionsMax: null,
        impressionsMin: null,
        index: null,
        keyword: null,
        keywordSearch: null,
        keywordsCount: null,
        lang: 'fr',
        partner: [],
        positionMax: 99,
        positionMin: 1,
        price_max: null,
        price_min: null,
        rd_max: null,
        rd_min: null,
        site: null,
        siteAge: null,
        source: null,
        tf_max: null,
        tf_min: null,
        thematic: [],
        thematicExcluded: [],
        top: null,
        top10: null,
        type: null,
        url: null,
        urlSearch: null,
        volume: null,
        volumeMax: null,
        volumeMin: 10
    },
    filterExtended: false,
    loading: false,
    meta: {
        orderBy: 'desc',
        sortBy: null,
        page: 1,
        perPage: 25,
        total: 0
    },
    results: []
}
