<template>
    <div v-if="show">
        <div :class="customizeLoader">
            <div :class="width" />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            show: {
                required: true,
                type: Boolean
            },
            width: {
                required: false,
                default: 'loader-xl'
            },
            customizeLoader: {
                type: String,
                required: false,
                default: 'd-flex justify-content-center align-items-center'
            }
        },
        mounted() {}
    }
</script>

<style scoped>
    .loader-sm,
    .loader-sm:after {
        border-radius: 50%;
        width: 5em;
        height: 5em;
    }

    .loader-sm {
        content: ' ';
        font-size: 4px;
        position: relative;
        border-top: 1.1em solid rgba(64, 63, 63, 0.2);
        border-right: 1.1em solid rgba(64, 63, 63, 0.2);
        border-bottom: 1.1em solid rgba(64, 63, 63, 0.2);
        border-left: 1.1em solid #403f3f;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }

    .loader-md,
    .loader-md:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }

    .loader-md {
        content: ' ';
        font-size: 4px;
        position: relative;
        border-top: 1.1em solid rgba(64, 63, 63, 0.2);
        border-right: 1.1em solid rgba(64, 63, 63, 0.2);
        border-bottom: 1.1em solid rgba(64, 63, 63, 0.2);
        border-left: 1.1em solid #403f3f;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }

    .loader-xl,
    .loader-xl:after {
        border-radius: 50%;
        width: 40em;
        height: 40em;
    }

    .loader-xl {
        content: ' ';
        font-size: 4px;
        position: relative;
        border-top: 1.1em solid rgba(64, 63, 63, 0.2);
        border-right: 1.1em solid rgba(64, 63, 63, 0.2);
        border-bottom: 1.1em solid rgba(64, 63, 63, 0.2);
        border-left: 1.1em solid #403f3f;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }

    .custom-loader,
    .custom-loader:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }

    .custom-loader {
        font-size: 4px;
        position: relative;
        border-top: 1.1em solid rgba(64, 63, 63, 0.2);
        border-right: 1.1em solid rgba(64, 63, 63, 0.2);
        border-bottom: 1.1em solid rgba(64, 63, 63, 0.2);
        border-left: 1.1em solid #403f3f;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }

    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>
