<template>
    <div>
        <nl-table
            async
            :per-page="100"
            :columns.sync="columns"
            :get-data="loadAsyncData"
            :force-update="forceUpdate"
            default-sort-direction="asc"
        >
            <template #id="{ props }">
                <span class="font-weight-bold">Id : </span>{{ props.row.id }}<br />
                <span class="font-weight-bold">User id : </span>{{ props.row.user_id }}
            </template>

            <template #redactor="{ props }">
                <p>
                    <router-link :to="`/redactor/details?id=${props.row.user.id}`">
                        {{ props.row.user.firstname }}
                        {{ props.row.user.lastname }}
                    </router-link>
                    <LoginAs :user-id="props.row.user.id" class="ml-auto" />
                </p>
                <p>
                    <span class="text-muted">{{ props.row.user.email }}</span>
                </p>
            </template>

            <template #entity_type="{ props }">
                <div v-if="props.row.orders" class="tag is-warning mb-2">Commande</div>
                <div v-if="props.row.preorders" class="tag is-info">Précommande</div>
                <div v-if="props.row.spots" class="tag is-link">Spots</div>
            </template>

            <template #in_progress="{ props }">
                <div v-if="props.row.entity_id">
                    <span v-if="props.row.entity_type === 1">Content</span>
                    <span v-else-if="props.row.entity_type === 2">Preorder</span>
                    <span v-else>Spot</span>

                    <span class="tag">#{{ props.row.entity_id }}</span>

                    <button
                        v-if="props.row.content_id"
                        class="button tag is-small ml-2 is-light"
                        title="Retirer de la commande"
                        @click.prevent="unassignOrderModal(props.row)"
                    >
                        <b-icon icon="times" size="is-small" />
                    </button>
                </div>
                <span v-else>-</span>
            </template>

            <template #prices="{ props }">
                <div v-if="props.row.prices && props.row.prices.length">
                    <div v-for="price in props.row.prices" :key="price.id">
                        <span class="mr-4">
                            <flag
                                :iso="
                                    price.lang.startsWith('com') || price.lang.startsWith('en')
                                        ? 'us'
                                        : price.lang.substr(0, 2)
                                "
                            />
                        </span>
                        {{ price.price | toCents }}€ / 100 mots
                    </div>
                </div>
            </template>

            <template #word_count="{ props }">
                <div class="row">
                    <div class="col-md-12">
                        <div class="d-flex align-items-center">
                            <div class="col">
                                <span v-if="props.row.word_count">{{ props.row.word_count }}</span>
                                <span v-else>0</span>
                            </div>
                            <div class="col">
                                <vue-easy-pie-chart
                                    :percent="getPercent(props.row.written_words_today, props.row.word_count)"
                                    :size="75"
                                    bar-color="#F9BC14"
                                    font-size=".95rem"
                                    scale-color="#a0a0a0"
                                    track-color="#c0c0c0"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        Taux de redaction hebdo :
                        <div class="progress">
                            <b-progress
                                v-if="props.row.word_count > 0"
                                :value="parseInt((props.row.written_words_weekly / (7 * props.row.word_count)) * 100)"
                                format="percent"
                                show-value
                                type="is-warning"
                            />
                            <b-progress v-else :value="0" format="percent" show-value />
                        </div>
                    </div>
                </div>
            </template>

            <template #review_percent="{ props }">
                <span
                    :class="
                        props.row.review_percent < 50 ? '' : props.row.review_percent > 99 ? 'is-danger' : 'is-warning'
                    "
                    class="font-weight-bold tag"
                >
                    {{ props.row.review_percent }}%
                </span>
            </template>

            <template #refusals="{ props }">
                <div v-if="props.row.blacklisted_subjects && props.row.blacklisted_subjects.length > 0">
                    <span v-for="refusal in props.row.blacklisted_subjects" :key="refusal.id" class="m-1 tag">
                        {{ refusal.localized_translations.name }}
                    </span>
                </div>
                <div v-else>
                    {{ $t('redactor.no-info') }}
                </div>
            </template>
        </nl-table>

        <redactor-confirm-modal ref="confirmModal" />
    </div>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable.vue'
    import { mapActions } from 'vuex'
    import LoginAs from '@/components/Admin/LoginAs.vue'
    import RedactorConfirmModal from '@/components/Redactor/Modals/RedactorPixabayModal.vue'
    import VueEasyPieChart from 'vue-easy-pie-chart'
    import 'vue-easy-pie-chart/dist/vue-easy-pie-chart.css'

    export default {
        name: 'RedactorTable',
        components: { RedactorConfirmModal, LoginAs, NlTable, VueEasyPieChart },
        props: {
            status: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                forceUpdate: false,
                columns: [
                    {
                        field: 'id',
                        centered: false,
                        cellClass: 'align-middle',
                        custom: true,
                        searchable: false,
                        sortable: false,
                        label: 'Id',
                        width: '150px'
                    },
                    {
                        field: 'redactor',
                        centered: false,
                        cellClass: 'align-middle',
                        custom: true,
                        searchable: true,
                        sortable: false,
                        label: 'Rédacteur'
                    },
                    {
                        field: 'entity_type',
                        centered: false,
                        cellClass: 'align-middle',
                        custom: true,
                        searchable: false,
                        sortable: false,
                        label: 'Type'
                    },
                    {
                        field: 'in_progress',
                        centered: false,
                        cellClass: 'align-middle',
                        custom: true,
                        searchable: false,
                        sortable: false,
                        label: 'En cours'
                    },
                    {
                        field: 'prices',
                        centered: false,
                        cellClass: 'align-middle',
                        custom: true,
                        searchable: false,
                        sortable: false,
                        label: 'Langue de rédaction',
                        width: '200px'
                    },
                    {
                        field: 'word_count',
                        cellClass: 'align-middle',
                        custom: true,
                        searchable: false,
                        sortable: true,
                        label: 'Nombre de mots / jour',
                        width: '400px'
                    },
                    {
                        field: 'review_percent',
                        centered: true,
                        cellClass: 'align-middle',
                        custom: true,
                        searchable: false,
                        sortable: false,
                        label: 'Relecture'
                    },
                    {
                        field: 'refusals',
                        centered: false,
                        cellClass: 'align-middle',
                        custom: true,
                        searchable: false,
                        sortable: false,
                        label: 'Sujets refusés'
                    }
                ]
            }
        },
        methods: {
            ...mapActions('redactors', ['getRedactorByStatus', 'unassignWriting']),

            unassignOrderModal(redactor) {
                this.$buefy.dialog.confirm({
                    title: `Êtes-vous sûr ?`,
                    message: `Souhaitez-vous vraiment retirer la commande à ${redactor.user.firstname} ${redactor.user.lastname} ?`,
                    confirmText: 'Confirmer',
                    cancelText: 'Annuler',
                    type: 'is-danger',
                    onConfirm: () => this.doUnassignOrder(redactor)
                })
            },

            doUnassignOrder(redactor) {
                this.loading = true
                this.unassignWriting({ id: redactor.writing_id })
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(e => {
                        this.$store.dispatch('toast', {
                            type: 'error',
                            title: 'Une erreur est survenue',
                            message: e.response.message
                        })
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },

            getPercent(words_written_today, word_count) {
                let result = parseInt(((words_written_today * 100) / word_count).toFixed(2))

                return result <= 0 || isNaN(result) ? 0 : result
            },

            loadAsyncData(params) {
                return this.getRedactorByStatus({
                    status: this.status,
                    params: params
                })
            }
        }
    }
</script>

<style scoped></style>
