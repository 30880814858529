import ApiService from '../../../services/api.service'

function prepareParams(config) {
    let field = config.sort

    if (config.sortDirection === 'desc') {
        field = '-' + field
    }

    let params = {
        page: config.page,
        include: config.include,
        sort: field,
        per_page: config.per_page
    }

    if (config.filters) {
        for (const [key, value] of Object.entries(config.filters)) {
            params[`filter[${key}]`] = value
        }
    }

    return params
}

export default {
    computeOrdersNotes(context, data) {
        let orders = data.data
        orders.data.forEach((order, index) => {
            order.content_logs.notes_by_status = {
                pending: [],
                urgent: [],
                following: []
            }

            order.content_logs.map(note => {
                if (note.notes) {
                    if (note.status === 'suivi') {
                        order.content_logs.notes_by_status.following.push(note)
                    }
                    if (note.status === 'urgent') {
                        order.content_logs.notes_by_status.urgent.push(note)
                    }
                    if (note.status === 'en cours') {
                        order.content_logs.notes_by_status.pending.push(note)
                    }
                }
            })
            orders.data.splice(index, 1, order)
        })
        context.commit('setOrders', orders)
    },

    getOrders(context) {
        return new Promise((resolve, reject) => {
            if (context.state.ordersConfig.filters.isTrusted !== null) {
                delete context.state.ordersConfig.filters.isTrusted
            }
            let params = prepareParams(context.state.ordersConfig)

            params.expired = context.state.expired
            context.commit('setIsLoading', true)
            ApiService.get(`/admin/orders`, params)
                .then(({ data }) => {
                    if (context.state.shouldComputeOrdersNotes === true) {
                        context.dispatch({ type: 'computeOrdersNotes', data: data.orders })
                    } else {
                        context.commit('setOrders', data.orders)
                    }
                    context.commit('setCounts', data.counts)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    context.commit('setIsLoading', false)
                })
        })
    },

    getOrdersBySite(context, { siteId, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/sites/${siteId}/orders`, params)
                .then(({ data }) => {
                    context.commit('setOrders', data.orders)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    context.commit('setIsLoading', false)
                })
        })
    },

    addNote(context, payload) {
        return new Promise(resolve => {
            ApiService.post('/admin/note/content', payload)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(e => {
                    this.$store.dispatch('toastError', e)
                })
        })
    },

    addNoteSite(context, payload) {
        return new Promise(resolve => {
            ApiService.post('/admin/note/site', payload)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(e => {
                    this.$store.dispatch('toastError', e)
                })
        })
    },

    addNoteFree(context, payload) {
        return new Promise(resolve => {
            ApiService.post('/admin/note/partner', payload)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(e => {
                    this.$store.dispatch('toastError', e)
                })
        })
    },

    updateContent(context, payload) {
        return new Promise(resolve => {
            if (payload.id !== null) {
                payload.ref = payload.id
            }
            ApiService.put('/admin/partner-details/contents/' + payload.ref, payload)
                .then(({ data }) => {
                    resolve(data)
                    this.getOrders(context)
                })
                .catch(e => {
                    console.log(e)
                    this.$store.dispatch('toastError', e)
                })
        })
    },

    validateContent(context, payload) {
        return new Promise(resolve => {
            ApiService.post('/admin/content/validate', {
                ids: payload
            })
                .then(({ data }) => {
                    // this.$store.dispatch('toast', {
                    //     type: 'success',
                    //     message: 'Les contenus ont bien été validés'
                    //     });
                    resolve(data)
                })
                .catch(e => {
                    console.log(e)
                    this.$store.dispatch('toastError', e)
                })
        })
    },
    cancelContent(context, payload) {
        context.commit('setIsLoading', true)
        return new Promise((resolve, reject) => {
            ApiService.post('/admin/content/cancel', {
                ids: payload
            })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(e => {
                    reject(e)
                    this.$store.dispatch('toastError', e)
                })
        })
    },
    sendErrorEmail(context, payload) {
        return new Promise(resolve => {
            ApiService.post('/admin/content/email-error', {
                ids: payload.ids,
                type_email_erreur: payload.type_email_erreur
            })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(e => {
                    console.log(e)
                    this.$store.dispatch('toastError', e)
                })
        })
    },

    checkContent(context, ids) {
        return new Promise(resolve => {
            ApiService.post('/admin/content/check', {
                ids
            })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(e => {
                    console.log(e)
                    this.$store.dispatch('toastError', e)
                })
        })
    },

    getPendingOrdersCount(context, payload) {
        return new Promise(resolve => {
            ApiService.get('/partner-orders/pending', payload)
                .then(({ data }) => {
                    resolve(data)
                    context.commit('setPendingOrdersCount', data)
                })
                .catch(e => {
                    console.log(e)
                    this.$store.dispatch('toastError', e)
                })
        })
    }
}
