<template>
    <b-notification :closable="false" role="alert" type="is-danger is-light">
        <p class="mb-0">
            <i class="fa fa-exclamation-triangle mr-1" />
            <span v-if="timeout">
                La commande est prioritaire. Elle doit être terminée
                {{ moment().isSame(timeout, 'day') ? "aujourd'hui" : moment(timeout).format('L') }}
                avant {{ moment(timeout).format('HH:mm') }}. Passé ce délai, elle sera de nouveau en distribution.
            </span>
            <span v-else>
                La commande est prioritaire. Vous disposez d'une heure pour la réaliser. Passé ce délai, elle sera de
                nouveau en distribution.
            </span>
        </p>
    </b-notification>
</template>
<script>
    export default {
        name: 'WritingIsPrioNotification',
        props: {
            timeout: {
                required: true
            }
        }
    }
</script>
