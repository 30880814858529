import ApiService from '../../../services/api.service'

function prepareParams(config) {
    let field = config.sort

    if (config.sortDirection === 'desc') {
        field = '-' + field
    }

    let params = {
        page: config.page,
        include: config.include,
        sort: field,
        per_page: config.per_page
    }

    if (config.filters) {
        for (const [key, value] of Object.entries(config.filters)) {
            params[`filter[${key}]`] = value
        }
    }

    return params
}

export default {
    list(context) {
        return new Promise((resolve, reject) => {
            ApiService.get('/crud/projects/list')
                .then(({ data }) => {
                    context.commit('setList', data)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getByUser(context, userId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/project/user/' + userId)
                .then(({ data }) => {
                    context.commit('setList', data.projects)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    listProjects(context) {
        return new Promise((resolve, reject) => {
            let params = prepareParams(context.state.projectsConfig)
            ApiService.get('/projects', params)
                .then(({ data }) => {
                    context.commit('setProjects', data)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    create(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.post('/crud/projects', model)
                .then(({ data }) => {
                    //context.commit("setList", data)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    update(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.put('/crud/projects/' + model.id, model)
                .then(({ data }) => {
                    //context.commit("setList", data)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    delete(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.delete('/crud/projects/' + model.id, model)
                .then(({ data }) => {
                    //context.commit("setList", data)
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    getBlacklist(context, { id, params }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/projects/${id}/blacklist`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    addBlacklist(context, { id, params }) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/projects/${id}/blacklist`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    deleteBlacklist(context, { id, params }) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/projects/${id}/blacklist`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
