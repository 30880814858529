<template>
    <div class="dropdown">
        <button
            id="dropdownMenu"
            class="btn btn-secondary dropdown-toggle"
            :class="{ 'btn-lg': styleLg }"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
        >
            <slot name="current" />
        </button>

        <div class="dropdown-menu" :class="{ 'dropdown-menu-right': menuRight }" aria-labelledby="dropdownMenu">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            menuRight: {
                default: false
            },
            styleLg: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped></style>
