<template>
    <div>
        <b-table
            :data="contents.periods"
            detailed
            striped
            :has-detailed-visible="row => row.total_orders > 0"
            custom-detail-row
            :loading="isLoadingTable"
        >
            <b-table-column field="period" label="Période">
                <template v-slot="props">
                    {{
                        $d(Date.parse(props.row.period), 'month').charAt(0).toUpperCase() +
                        $d(Date.parse(props.row.period), 'month').slice(1)
                    }}
                </template>
            </b-table-column>
            <b-table-column field="nb" label="Nombre de commandes">
                <template v-slot="props">
                    {{ props.row.orders }}
                </template>
            </b-table-column>
            <b-table-column field="depense" label="Budget dépensé">
                <template v-slot="props">
                    <b-tag
                        class="text-white"
                        style="min-width: 200px"
                        :type="props.row.budget - props.row.depense < 40 ? 'bg-success' : 'bg-warning'"
                        size="is-medium"
                        rounded
                    >
                        {{ props.row.depense }} &euro; / {{ props.row.budget }} &euro;
                    </b-tag>
                </template>
            </b-table-column>
            <template #detail="props" style="height: auto !important">
                <tr style="height: auto !important">
                    <td colspan="100%" class="p-2" style="height: auto !important">
                        <contents-table
                            :id="props.row.period"
                            :period="props.row.period"
                            :contents="props.row.orders"
                            with-url-information
                            :with-edit-button="!readonly"
                            :readonly="readonly"
                            @edit="openPeriodModal"
                        />
                    </td>
                </tr>
            </template>
        </b-table>

        <form v-if="!readonly" @submit.prevent="submit">
            <nl-modal :open-modal="contentBeingEdited !== null" title="Edit Content" @hide="contentBeingEdited = null">
                <div class="form-group">
                    <label for="frm_content_period">Période</label>
                    <select id="frm_content_period" v-model="period" class="form-control" name="">
                        <option
                            v-for="(period, index) in contents.periods"
                            :key="`period_${index}`"
                            :value="period.period"
                        >
                            {{ $d(Date.parse(period.period), 'month') }}
                        </option>
                    </select>
                </div>

                <template v-slot:footer>
                    <button class="btn btn-primary" data-dismiss="modal" type="button">Annuler</button>
                    <button
                        :disabled="!contentBeingEdited || (contentBeingEdited && period === contentBeingEdited.period)"
                        class="btn btn-secondary"
                        type="submit"
                    >
                        Modifier la période
                    </button>
                </template>
            </nl-modal>
        </form>
        <PeriodModal ref="periodModal" :periods="contents.periods" @changePeriod="changePeriod" />
        <Toaster ref="toaster" />
    </div>
</template>

<script>
    import NlModal from '../UI/NlModal'
    import { mapActions } from 'vuex'
    import ContentsTable from '../Content/Table'

    export default {
        components: { ContentsTable, NlModal },
        props: {
            activeTab: {
                type: Number,
                required: true
            },
            contents: {
                required: true
            },
            readonly: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                contentBeingEdited: null,
                period: null,
                isLoadingTable: false
            }
        },
        watch: {
            activeTab(newValue, oldValue) {
                if (newValue === 1 && oldValue !== 1) {
                    this.isLoadingTable = true
                    this.getContentsByCampaign(this.$route.query.id).finally(() => {
                        this.isLoadingTable = false
                    })
                }
            }
        },
        methods: {
            ...mapActions('campaigns', ['getCampaignById', 'getKeywordsByCampaign']),
            ...mapActions('contents', ['getContentsByCampaign', 'attachCampaignContent', 'updateCampaignContent']),
            openPeriodModal(content) {
                this.$refs.periodModal.show(content)
            },
            changePeriod(id, period) {
                console.log(id, period)
                this.axios
                    .patch(`/admin/campaign/${id}/period`, {
                        period: period
                    })
                    .then(() => {
                        this.$refs.toaster.success('La période a bien été modifiée')
                    })
                    .catch(() => {
                        this.$refs.toaster.error('Une erreur est survenue')
                    })
                    .finally(() => {
                        this.isLoadingTable = true
                        this.getContentsByCampaign(this.$route.query.id).finally(() => {
                            this.isLoadingTable = false
                        })
                    })
            },
            submit() {
                this.updateCampaignContent({
                    period: this.period,
                    accompagnement_id: this.$route.query.id,
                    content_id: this.contentBeingEdited.id
                })

                this.contentBeingEdited = null
            },
            detachContent() {
                this.$emit('detach', {
                    content_id: this.contentBeingEdited.id
                })
                this.contentBeingEdited = null
            }
        }
    }
</script>

<style scoped></style>
