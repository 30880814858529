import ApiService from '../../../services/api.service'
import { getCache, setCache } from '@/helpers/localStorageHelper'

export default {
    list(context, params) {
        const { sort, page, rowsPerPage, filter } = params ? params : [null, null, null, null]
        context.commit('setLoading')
        return new Promise((resolve, reject) => {
            ApiService.get('/crud/groups', {
                sort,
                page,
                rowsPerPage,
                filter
            })
                .then(({ data }) => {
                    context.commit('setList', data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    getTranslatedGroups(context) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/groups/translated')
                .then(({ data }) => {
                    context.commit('setTranslatedGroups', data.groups)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    adminList(context) {
        return new Promise((resolve, reject) => {
            ApiService.get('/admin/groups')
                .then(({ data }) => {
                    context.commit('setAdminList', data.groups)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    update(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put('/admin/groups/' + params.id, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    add(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/admin/groups', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    delete(context, groupId) {
        return new Promise((resolve, reject) => {
            ApiService.delete('/admin/groups/' + groupId)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    async getAll({ state, commit }) {
        if (state.groups.length) {
            return state.groups
        } else {
            const cache = getCache('groups')

            const groups = cache ?? (await ApiService.get('/group/all'))?.data

            if (!cache) {
                setCache('groups', groups)
            }

            commit('setGroups', groups)
            return groups
        }
    }
}
