<template>
    <nl-loader v-if="isLoading" :show="true"></nl-loader>
    <div v-else-if="!isLoading && me">
        <div class="d-flex justify-content-between align-items-center">
            <b-field grouped group-multiline>
                <div class="control">
                    <b-taglist attached>
                        <b-tag type="is-warning" size="is-medium">Nombre de sites en vente :</b-tag>
                        <b-tag type="is-dark" size="is-medium">{{ sellingWebsites.total }}</b-tag>
                    </b-taglist>
                </div>
            </b-field>
            <div class="d-flex">
                <b-select
                    icon="align-center"
                    v-model="config.per_page"
                    @input="loadAsyncData"
                    :disabled="loading"
                    :loading="loading"
                >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                </b-select>
                <b-dropdown aria-role="list" class="ml-2" :close-on-click="false" position="is-bottom-left">
                    <template #trigger="{ active }">
                        <b-button
                            :label="$t('global.columns')"
                            type="is-info"
                            class="mr-2"
                            :icon-right="active ? 'caret-up' : 'caret-down'"
                        />
                    </template>

                    <b-dropdown-item aria-role="listitem" v-for="(col, index) in columns" :key="index">
                        <b-field>
                            <b-checkbox v-model="col.visible">{{ col.name }}</b-checkbox>
                        </b-field>
                    </b-dropdown-item>
                </b-dropdown>
                <a href="https://www.nextlevel.link/vente-sites.html" class="btn bg-warning shadow ml-2" target="_blank"
                    ><i class="fas fa-info-circle mr-2"></i>Mode d'emploi</a
                >
            </div>
        </div>

        <b-table
            :data="sellingWebsites.data"
            :mobile-cards="true"
            :paginated="true"
            :current-page.sync="config.page"
            default-sort-direction="desc"
            :striped="true"
            :debounce-search="200"
            class="table-striped"
            :loading="loading"
            :default-sort="[config.sortField, config.sortOrder]"
            :per-page="sellingWebsites.per_page"
            :total="sellingWebsites.total"
            backend-sorting
            backend-pagination
            backend-filtering
            pagination-rounded
            @page-change="onPageChange"
            @sort="onSort"
            @filters-change="onFilterChange"
        >
            <b-table-column
                field="id"
                searchable
                sortable
                :visible="columns['id'].visible"
                :label="columns['id'].name"
                cell-class="align-middle"
            >
                <div slot-scope="props" slot="default">
                    {{ props.row.id }}
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="ID"
                            v-model="props.filters['id']"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="group_id"
                sortable
                searchable
                :visible="columns['theme'].visible"
                :label="columns['theme'].name"
                cell-class="align-middle"
            >
                <div slot-scope="props" slot="default">
                    {{ props.row.theme }}
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <select
                            placeholder="Tous"
                            class="form-control form-control-sm"
                            v-model="props.filters[props.column.field]"
                            :disabled="groups.length === 0"
                        >
                            <option :value="props.filters[props.column.filter]" selected disabled hidden>Tous</option>
                            <option value="" selected>Tous</option>
                            <option v-for="g in groups" :key="g.id" :value="g.id" selected>
                                {{ g.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </b-table-column>
            <b-table-column field="ndd" sortable searchable :visible="isAdmin" label="NDD" cell-class="align-middle">
                <div slot-scope="props" slot="default">
                    <div v-if="isAdmin">{{ props.row.url }}</div>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="URL"
                            v-model="props.filters['ndd']"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="site_type"
                sortable
                searchable
                :visible="columns['site_type'].visible"
                :label="columns['site_type'].name"
                cell-class="align-middle"
            >
                <template v-slot:header="{ column }">
                    <b-tooltip label="Bronze, Bronze VIP, Argent, Argent VIP, Or, Or VIP" append-to-body dashed>
                        {{ column.label }}
                    </b-tooltip>
                </template>
                <div slot-scope="props" slot="default">
                    <span class="tag" v-if="props.row.site_type === 'or'" style="background-color: goldenrod">{{
                        $t('global.sites-types.gold')
                    }}</span>
                    <span class="tag" v-else-if="props.row.site_type === 'argent'" style="background-color: silver">{{
                        $t('global.sites-types.silver')
                    }}</span>
                    <span
                        class="tag"
                        v-else-if="props.row.site_type === 'bronze'"
                        style="background-color: #cd7f32; color: white"
                        >{{ $t('global.sites-types.bronze') }}</span
                    >
                    <span
                        class="tag"
                        v-else-if="props.row.site_type === 'vipbronze'"
                        style="background-color: #cd7f32"
                        >{{ $t('global.sites-types.bronze') }}</span
                    >
                    <span
                        class="tag"
                        v-else-if="props.row.site_type === 'vipargent'"
                        style="background-color: silver"
                        >{{ $t('global.sites-types.silver') }}</span
                    >
                    <span class="tag" v-else-if="props.row.site_type === 'vipor'" style="background-color: gold">{{
                        $t('global.sites-types.vip')
                    }}</span>
                    <span class="tag" v-else-if="props.row.site_type === 'media'" style="background-color: lightblue"
                        >Media</span
                    >
                    <span class="tag is-dark" v-else>{{ $t('sites-types.unknown') }}</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <select
                        placeholder="Tous"
                        class="form-control form-control-sm"
                        v-model="props.filters[props.column.field]"
                    >
                        <option :value="props.filters[props.column.filter]" selected disabled hidden>Tous</option>
                        <option value="" selected>Tous</option>
                        <option value="or">Or</option>
                        <option value="argent">Argent</option>
                        <option value="bronze">Bronze</option>
                        <option value="vipor">VIP Or</option>
                        <option value="vipargent">VIP Argent</option>
                        <option value="vipbronze">VIP Bronze</option>
                        <option value="media">Media</option>
                    </select>
                </div>
            </b-table-column>
            <b-table-column
                field="gnews"
                sortable
                searchable
                align="center"
                :visible="columns['gnews'].visible"
                :label="columns['gnews'].name"
                cell-class="align-middle"
            >
                <template v-slot:header="{ column }">
                    <b-tooltip label="Site Google News" append-to-body dashed>
                        {{ column.label }}
                    </b-tooltip>
                </template>
                <div slot-scope="props" slot="default">
                    <span v-if="props.row.gnews === 1">{{ $t('global.yes') }}</span>
                    <span v-else-if="props.row.gnews === 0">{{ $t('global.no') }}</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <select class="form-control form-control-sm" v-model="props.filters[props.column.field]">
                        <option :value="props.filters[props.column.filter]" selected disabled hidden>Tous</option>
                        <option value="" selected>Tous</option>
                        <option value="1">Oui</option>
                        <option value="0">Non</option>
                    </select>
                </div>
            </b-table-column>
            <b-table-column
                field="lang"
                sortable
                searchable
                align="center"
                :visible="columns['locale'].visible"
                :label="columns['locale'].name"
                cell-class="align-middle"
            >
                <div slot-scope="props" slot="default">
                    <flag class="rounded shadow-sm" :iso="props.row.lang === 'fr_fr' ? 'fr' : 'us'" />
                </div>
                <div slot="searchable" slot-scope="props">
                    <select class="form-control form-control-sm" v-model="props.filters[props.column.field]">
                        <option :value="props.filters[props.column.filter]" selected disabled hidden>Tous</option>
                        <option value="" selected>Tous</option>
                        <option value="fr_fr">FR</option>
                        <option value="com_en">EN</option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column
                field="nl_keywords_count"
                sortable
                searchable
                :visible="columns['kw_mc'].visible"
                :label="columns['kw_mc'].name"
                cell-class="align-middle"
            >
                <div slot-scope="props" slot="default">
                    {{ props.row.nl_keywords_count }}
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto d-flex">
                        <input
                            type="number"
                            class="form-control form-control-sm"
                            placeholder="min."
                            v-model="props.filters['min_nl_keywords_count']"
                        />
                        <input
                            type="number"
                            class="form-control form-control-sm ml-1"
                            placeholder="max."
                            v-model="props.filters['max_nl_keywords_count']"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="seobserver_keywords_count"
                sortable
                searchable
                :visible="columns['kw_seo'].visible"
                :label="columns['kw_seo'].name"
                cell-class="align-middle"
            >
                <div slot-scope="props" slot="default">
                    {{ props.row.seobserver_keywords_count }}
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto d-flex">
                        <input
                            type="number"
                            class="form-control form-control-sm"
                            placeholder="min."
                            v-model="props.filters['min_seobserver_keywords_count']"
                        />
                        <input
                            type="number"
                            class="form-control form-control-sm ml-1"
                            placeholder="max."
                            v-model="props.filters['max_seobserver_keywords_count']"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="orders_count"
                sortable
                searchable
                :visible="columns['orders_count'].visible"
                :label="columns['orders_count'].name"
                cell-class="align-middle"
            >
                <div slot-scope="props" slot="default">
                    {{ props.row.orders_count }}
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto d-flex">
                        <input
                            type="number"
                            class="form-control form-control-sm"
                            placeholder="min."
                            v-model="props.filters['min_orders_count']"
                        />
                        <input
                            type="number"
                            class="form-control form-control-sm ml-1"
                            placeholder="max."
                            v-model="props.filters['max_orders_count']"
                        />
                    </div>
                </div>
            </b-table-column>
            <b-table-column
                field="sales_revenue"
                sortable
                searchable
                :visible="columns['ca'].visible"
                :label="columns['ca'].name"
                cell-class="align-middle"
            >
                <template v-slot:header="{ column }">
                    <b-tooltip label="Chiffre d'affaire global depuis l'ajout dans NextLevel" append-to-body dashed>
                        {{ column.label }}
                    </b-tooltip>
                </template>
                <div slot-scope="props" slot="default">{{ roundAmount(props.row.sales_revenue) }}€ HT</div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto d-flex">
                        <input
                            type="number"
                            class="form-control form-control-sm"
                            placeholder="min."
                            v-model="props.filters['min_sales_revenue']"
                        />
                        <input
                            type="number"
                            class="form-control form-control-sm ml-1"
                            placeholder="max."
                            v-model="props.filters['max_sales_revenue']"
                        />
                    </div>
                </div>
            </b-table-column>
            <!-- <b-table-column
        field="gain"
        :label="$t('sellingwebsite.label.potentialGain')"
        cell-class="align-middle"
      >
        <div slot-scope="props" slot="default">{{ props.row.saleable.gain }}€</div>
      </b-table-column> -->
            <b-table-column
                field="total_saleable"
                :label="$t('sellingwebsite.label.saleable')"
                cell-class="align-middle"
                v-if="isAdmin"
            >
                <div slot-scope="props" slot="default">
                    <span>
                        {{ props.row.saleableUrl }}
                    </span>
                </div>
            </b-table-column>
            <b-table-column field="price" label="Acheter" cell-class="align-middle" searchable sortable>
                <div slot-scope="props" slot="default" class="d-flex">
                    <b-button
                        @click="openBuyModal(props.row)"
                        v-if="Number(me.gains) >= props.row.price || Number(me.credit_amount) >= props.row.price"
                        type="is-warning"
                        icon-pack="fas"
                        icon-left="cart-plus"
                    >
                        {{ Math.round(props.row.price) }}€ HT
                    </b-button>
                    <b-button
                        @click="redirectTo('/buy-credits')"
                        v-else
                        type="is-danger"
                        icon-pack="fas"
                        icon-left="cart-plus"
                    >
                        {{ Math.round(props.row.price) }}€ HT
                    </b-button>
                    <TabButton
                        @click.native.prevent="listSites(props.row)"
                        color="danger"
                        v-if="isAdmin"
                        class="ml-1"
                        iconBefore="fas fa-times"
                    />
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto d-flex">
                        <input
                            type="number"
                            class="form-control form-control-sm"
                            placeholder="min."
                            v-model="props.filters['min_price']"
                        />
                        <input
                            type="number"
                            class="form-control form-control-sm ml-1"
                            placeholder="max."
                            v-model="props.filters['max_price']"
                        />
                    </div>
                </div>
            </b-table-column>
        </b-table>
        <BuySiteModal ref="buyWebsiteModal" />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import TabButton from '@/components/Tabs/TabButton.vue'
    import BuySiteModal from '@/components/Sites/BuySiteModal.vue'

    export default {
        name: 'SellingWebsiteTable',
        components: { BuySiteModal, TabButton },
        data() {
            return {
                isLoading: true,
                loading: false,
                columns: {
                    id: {
                        name: 'ID',
                        visible: true
                    },
                    theme: {
                        name: this.$t('thematique.title'),
                        visible: true
                    },
                    site_type: {
                        name: this.$t('group-site.table.type'),
                        visible: true
                    },
                    gnews: {
                        name: 'GNews',
                        visible: true
                    },
                    locale: {
                        name: this.$t('precommande.columns.locale'),
                        visible: true
                    },
                    kw_mc: {
                        name: 'MC NextLevel',
                        visible: true
                    },
                    kw_seo: {
                        name: 'MC Seobserver',
                        visible: true
                    },
                    orders_count: {
                        name: this.$t('group-site.table.nb_orders'),
                        visible: true
                    },
                    ca: {
                        name: 'CA',
                        visible: true
                    },
                    price: {
                        name: this.$t('global.price'),
                        visible: true
                    }
                }
            }
        },
        created() {
            this.getSitesOnSales()
                .then(() => {
                    this.getMe().then(() => {
                        this.getGroups()
                    })
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        computed: {
            ...mapState('sites', {
                sellingWebsites: state => state.sellingWebsites,
                config: state => state.sellingWebsitesConfig
            }),
            ...mapState('auth', {
                me: state => state.user
            }),
            ...mapState('group', {
                groups: state => state.list
            }),
            ...mapState('auth', ['user']),
            isAdmin() {
                return this.user.role === 'admin'
            }
        },
        methods: {
            ...mapActions('sites', {
                getSitesOnSales: 'getSitesOnSales',
                listSitesOnSale: 'listSitesOnSale'
            }),
            ...mapActions('auth', {
                getMe: 'me'
            }),
            ...mapActions('group', {
                getGroups: 'list'
            }),
            roundAmount(amount) {
                return Math.round(amount * 100) / 100
            },
            openBuyModal(content) {
                this.$refs.buyWebsiteModal.openModal(content)
            },
            loadAsyncData() {
                this.loading = true
                this.getSitesOnSales()
                    .then(() => {})
                    .catch(error => {
                        console.log(error)
                        this.$store.dispatch('toastError', error)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },

            onSort(field, order) {
                this.config.sort = field
                this.config.sortDirection = order

                this.loadAsyncData()
            },
            onFilterChange(filter) {
                var filters = {}
                Object.keys(filter).forEach(element => {
                    filters[element] = filter[element]
                })
                this.config.filters = filters

                this.loadAsyncData()
            },
            onPageChange(page) {
                this.config.page = page
                this.loadAsyncData()
            },
            redirectTo(url) {
                this.$router.push(url)
            },
            listSites(row) {
                this.listSitesOnSale({
                    ids: [row.id],
                    price: 0,
                    saleable: 0,
                    gnews: 0
                })
                    .then(() => {
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'Le site a bien été retiré de la vente'
                        })
                    })
                    .catch(e => {
                        this.$store.dispatch('toast', {
                            type: 'error',
                            title: 'Une erreur est survenue',
                            message: e.message
                        })
                    })
                    .finally(() => {
                        this.loadAsyncData()
                    })
            }
        }
    }
</script>

<style scoped>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
</style>
