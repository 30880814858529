<template>
    <div>
        <assign-redactor-modal ref="assignRedactorModal" @redactor:selected="doAssignRedactor" />

        <add-pending-message ref="addPendingMessage" @save="savePendingMessage" />

        <redactor-confirm-modal ref="confirmModal" @unassignRedactorWriting="doUnAssignOrder" />

        <nl-table
            searchable
            sortable
            :per-page="100"
            :columns.sync="columns"
            :get-data="loadAsyncData"
            :force-update="forceUpdate"
            :default-sort="'isPrio,-redactor_id,entity_type,created_at'"
        >
            <template #actions-processing="{ props: { index, row } }">
                <div class="d-flex">
                    <div class="cursor-pointer mr-4">
                        <div v-if="loadingCancel === true && loadingIndex === index">
                            <i class="fas fa-times fa-spin" />
                        </div>
                        <div v-else>
                            <b-tooltip size="is-small" position="is-right" label="Supprimer">
                                <a href="#" class="text-danger" @click="showDeleteModal(row, index)">
                                    <i class="fas fa-times" />
                                </a>
                            </b-tooltip>
                        </div>
                    </div>

                    <div class="mr-4">
                        <div v-if="loading && loadingIndex && loadingIndex === index">
                            <i class="fas fa-cog fa-spin" />
                        </div>
                        <div v-else class="cursor-pointer">
                            <span v-if="row.isPrio">
                                <i class="fas fa-flag prio" @click="setPrio(index, row.id, false)" />
                            </span>
                            <span v-else>
                                <i class="far fa-flag" @click="setPrio(index, row.id, true)" />
                            </span>
                        </div>
                    </div>

                    <div class="cursor-pointer mr-4">
                        <div v-if="loadingPending === true && loadingIndex === index">
                            <i class="far fa-hourglass fa-spin" />
                        </div>
                        <div v-else>
                            <span v-if="row.writing_status_id === 10">
                                <b-tooltip
                                    size="is-small"
                                    position="is-right"
                                    :label="row.reason || 'Mettre cette commande à traiter'"
                                >
                                    <i class="fas fa-hourglass prio" @click="setPendingWriting(row, false, index)" />
                                </b-tooltip>
                            </span>
                            <span v-else>
                                <b-tooltip size="is-small" position="is-right" label="Mettre cette commande en attente">
                                    <i class="far fa-hourglass" @click="showPendingModal(row, index)" />
                                </b-tooltip>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="cursor-pointer mr-4">
                        <b-tooltip label="Force review" position="is-top">
                            <a href="#" @click.prevent="doForceReview(row, index)">
                                <i
                                    class="fas fa-sync"
                                    :class="{
                                        'text-danger': row.force_review === 1,
                                        'fa-spin': forceReviewIndex === index
                                    }"
                                />
                            </a>
                        </b-tooltip>
                    </div>

                    <div class="cursor-pointer mr-4">
                        <div v-if="loadingFinished === true && loadingIndex === index">
                            <i class="far fa-check fa-spin" />
                        </div>
                        <div v-else>
                            <b-tooltip size="is-small" position="is-right" label="Mettre en terminer">
                                <a href="#" class="text-success" @click="showSetWritingFinishedModal(row, index)">
                                    <i class="fas fa-check" />
                                </a>
                            </b-tooltip>
                        </div>
                    </div>

                    <div
                        class="cursor-pointer"
                        v-if="row.entity_type === 1 && (row.writing_status_id === 1 || row.writing_status_id === 2)"
                    >
                        <div v-if="loadingGenerating === true && loadingIndex === index">
                            <i class="far fa-check fa-spin" />
                        </div>
                        <div v-else>
                            <b-tooltip size="is-small" position="is-right" label="Générer le contenu">
                                <a
                                    href="#"
                                    :class="getGeneratedStatusClass(row)"
                                    @click="showGenerateContentModal(row, index)"
                                >
                                    <i class="fas fa-magic" />
                                </a>
                            </b-tooltip>
                        </div>
                    </div>
                </div>
            </template>

            <template #entity_type="{ props }">
                <span v-if="props.row.entity_type === 1">Content</span>
                <span v-else-if="props.row.entity_type === 2">Preorder</span>
                <span v-else>Spot</span>
            </template>

            <template #entity_typeSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small" expanded>
                    <option :value="null">Tous</option>
                    <option value="1">Content</option>
                    <option value="2">Preorder</option>
                    <option value="6">Spot</option>
                </b-select>
            </template>

            <template
                #entity_id="{
                    props: {
                        row: { entity_type, entity_id }
                    }
                }"
            >
                <template>
                    <div :class="`tag ${entity_type === 1 ? 'is-warning' : entity_type === 2 ? 'is-info' : 'is-link'}`">
                        #{{ entity_id }}
                    </div>
                </template>
            </template>

            <template #writing_status_id="{ props }">
                <b-select v-model="props.row.writing_status_id" size="is-small" @input="doUpdateStatus(props.row)">
                    <option value="1">To do</option>
                    <option value="3">Review</option>
                    <option value="6">Done</option>
                    <option value="11" disabled>Duplication check</option>
                </b-select>
            </template>

            <template #writing_status_idSearch="{ props }">
                <b-select v-model="props.filters[props.column.field]" size="is-small">
                    <option :value="null">Tous</option>
                    <option value="1">To do</option>
                    <option value="3">Review</option>
                    <option value="11">Duplication check</option>
                </b-select>
            </template>

            <template #created_at="{ props: { row } }">
                {{ moment(row.created_at).format('L') }}
            </template>

            <template #created_atSearch="{ props }">
                <b-input v-model="props.filters[props.column.field]" size="is-small" type="date" />
            </template>

            <template #redactor_id="{ props: { index, row } }">
                <div class="d-flex">
                    <span v-if="row.redactor_id" class="tag is-success">
                        Oui
                        <b-tooltip
                            v-if="row.redactor && row.redactor.user"
                            :label="row.redactor.user.email"
                            class="ml-2"
                        >
                            <b-icon icon="info-circle" size="is-small" />
                        </b-tooltip>
                    </span>

                    <div v-if="!row.redactor_id">
                        <span class="tag is-danger cursor-pointer" @click="openAssignModal(row.id, index)"> Non </span>
                    </div>

                    <button
                        v-if="row.redactor_id"
                        class="button tag is-small ml-2 is-light"
                        title="Retirer de la commande"
                        @click.prevent="openConfirmModal(index, row)"
                    >
                        <b-icon icon="times" size="is-small" />
                    </button>
                </div>
            </template>

            <template #redactor_idSearch="{ props }">
                <b-select v-model="props.filters['is_assigned']" size="is-small" expanded>
                    <option :value="null">Tous</option>
                    <option value="true">Oui</option>
                    <option value="false">Non</option>
                </b-select>
            </template>

            <template #written_at="{ props }">
                <span v-if="props.row.written_at">
                    {{ moment(props.row.written_at).format('L') }} à
                    {{ moment(props.row.written_at).format('HH:ss') }}
                </span>
                <span v-else>-</span>
            </template>

            <template #redactor="{ props }">
                <template v-if="props.row.redactor">
                    <p>
                        {{ props.row.redactor.user.firstname }}
                        {{ props.row.redactor.user.lastname }}
                    </p>
                    <p>
                        <span class="text-muted">{{ props.row.redactor.user.email }}</span>
                    </p>
                </template>
                <template v-else>
                    <span>-</span>
                </template>
            </template>

            <template #client="{ props: { row } }">
                <div v-if="row.entity_type === 1">
                    <p class="mb-0">
                        {{ row.related_order.buyer_firstname }}
                        {{ row.related_order.buyer_lastname }}<br />

                        <span class="text-muted">
                            {{ row.related_order.buyer_email }}
                        </span>
                    </p>
                </div>
                <div v-else-if="row.entity_type === 2 && row.related_order !== null">
                    <p v-if="row.related_order.campaign.project != null" class="mb-0">
                        {{ row.related_order.campaign.project.name }}
                    </p>
                    <p v-else class="mb-0">-</p>
                </div>
                <div v-else-if="row.entity_type === 6 && row.related_order !== null">
                    <p v-if="row.related_order.buyer !== null" class="mb-0">
                        {{ row.related_order.buyer.email }}
                        <LoginAs :user-id="row.related_order.buyer_id" />
                    </p>
                    <p v-else class="mb-0">-</p>
                </div>
                <div v-else>
                    <p class="mb-0">-</p>
                </div>
            </template>

            <template #clientSearch="{ props }">
                <b-input v-model="props.filters['with_client']" size="is-small" placeholder="Client" type="text" />
            </template>

            <template #partner.email="{ props: { row } }">
                <span v-if="row.partner_id">
                    {{ row.partner_email }}
                    <LoginAs :user-id="row.partner_id" class="ml-auto" />
                </span>
                <span v-else> Nextlevel </span>
            </template>

            <template #keyword="{ props: { row } }">
                <div>
                    <p v-if="row.related_order != null">
                        {{ row.related_order.keyword }}
                    </p>
                    <p v-else>-</p>
                    <span v-if="row.entity_type === 1">{{ row.page }}</span>
                    <span v-else>{{ row.site?.url }}</span>
                </div>
            </template>

            <template #primary_link="{ props: { row } }">
                <div v-if="row.entity_type === 1 || row.entity_type === 6">
                    <p>
                        {{ $t('global.link') }} : <span>{{ row.primary_link }}</span>
                    </p>
                    <p>
                        {{ $t('global.anchor') }} :
                        <span class="text-muted">{{ row.primary_anchor }}</span>
                    </p>

                    <template v-if="row.secondary_link">
                        <hr />

                        <p>
                            {{ $t('global.link') }} 2 : <span>{{ row.secondary_link }}</span>
                        </p>
                        <p>
                            {{ $t('global.anchor') }} 2 :
                            <span class="text-muted">{{ row.secondary_anchor }}</span>
                        </p>
                    </template>
                </div>
                <div v-else>
                    <p>-</p>
                </div>
            </template>

            <template #words_count="{ props: { row } }">
                {{ row.words_count }}
            </template>

            <template #duplication="{ props }">
                <template v-if="props.row.duplicate_percentage !== null">
                    <span :class="[props.row.duplicate_percentage < 7 ? 'is-success' : 'is-danger']" class="tag">
                        {{ props.row.duplicate_percentage }}%
                    </span>
                </template>
                <template v-else>
                    <span class="tag is-warning" title="Non vérifié">
                        <i class="fas fa-times" />
                    </span>
                </template>
            </template>

            <template #has_wordpress_plugin="{ props: { row } }">
                <NlWordpress :site-data="row.site" :site-type="row.entity_type === 6 ? 'spot' : 'site'" />
            </template>

            <template #lang="{ props: { row } }">
                <p class="mb-0">
                    <flag :iso="row.lang === 'fr_fr' ? 'fr' : 'us'" />
                </p>
            </template>

            <template #desired_publication_date="{ props: { row } }">
                <span v-if="row.desired_publication_date">
                    {{ moment(row.desired_publication_date).fromNow() }}
                </span>
                <span v-else>ASAP</span>
            </template>

            <template #desired_publication_dateSearch="{ props }">
                <b-input v-model="props.filters[props.column.field]" size="is-small" type="date" />
            </template>

            <template #actions-other="{ props: { index, row } }">
                <div
                    v-if="row.writing_status_id === 3 && ['done', 'review'].includes(status)"
                    class="d-flex flex-column flex-md-row"
                >
                    <router-link :to="`/redactor/writing?id=${row.id}`" class="btn btn-light" title="Voir le contenu">
                        <i class="far fa-eye" />
                    </router-link>

                    <button
                        class="btn btn-light"
                        title="Copier le contenu"
                        @click.prevent="copyContent($event, row.writing_content)"
                    >
                        <i class="far fa-copy" />
                    </button>
                </div>
                <div v-else class="d-flex flex-column flex-md-row">
                    <router-link
                        v-if="['error'].includes(status)"
                        :to="`/redactor/writing?id=${row.id}`"
                        class="btn btn-light"
                        title="Voir le contenu"
                    >
                        <i class="far fa-eye" />
                    </router-link>
                    <b-tooltip size="is-small" position="is-left" label="Copier le contenu">
                        <button class="btn btn-light" @click.prevent="copyContent($event, row.writing_content)">
                            <i class="far fa-copy" />
                        </button>
                    </b-tooltip>

                    <b-tooltip size="is-small" position="is-left" label="Réessayer">
                        <button
                            class="btn btn-light ml-2"
                            :disabled="loadingIndex === index"
                            @click.prevent="retrySend(index, row)"
                        >
                            <i class="fas fa-redo-alt" />
                        </button>
                    </b-tooltip>

                    <b-tooltip size="is-small" position="is-left" label="J'ai publié manuellement">
                        <button
                            class="btn btn-success ml-2"
                            :disabled="loadingIndex === index"
                            @click="doWritingPublished(index, row)"
                        >
                            <i class="fas fa-clipboard-check" />
                        </button>
                    </b-tooltip>
                </div>
            </template>
        </nl-table>

        <GenerateContentModal @generate-content="doGenerateContent" ref="generateContentModal" />

        <input ref="copyInput" type="hidden" />
    </div>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable.vue'
    import { mapActions } from 'vuex'
    import LoginAs from '@/components/Admin/LoginAs.vue'
    import AssignRedactorModal from '@/components/Redactor/Modals/AssignRedactorModal.vue'
    import AddPendingMessage from '@/components/Redactor/Modals/AddPendingMessage.vue'
    import RedactorConfirmModal from '@/components/Redactor/Modals/RedactorConfirmModal.vue'
    import NlWordpress from '@/components/UI/Wordpress/NlWordpress.vue'
    import GenerateContentModal from '@/components/Admin/Redactor/GenerateContentModal.vue'

    export default {
        name: 'WritingTableStatus',
        components: {
            GenerateContentModal,
            NlWordpress,
            RedactorConfirmModal,
            AddPendingMessage,
            AssignRedactorModal,
            LoginAs,
            NlTable
        },
        props: {
            status: {
                type: String,
                required: false,
                default: null
            }
        },
        data() {
            return {
                columns: [
                    {
                        custom: true,
                        field: 'actions-processing',
                        hide: this.status !== 'processing',
                        label: 'Actions',
                        searchable: false,
                        sortable: false
                    },
                    {
                        custom: true,
                        customSearch: true,
                        field: 'entity_type',
                        label: 'Type'
                    },
                    {
                        custom: true,
                        field: 'entity_id',
                        label: 'Ref'
                    },
                    {
                        custom: true,
                        customSearch: true,
                        field: 'created_at',
                        label: 'Créé le'
                    },
                    {
                        custom: true,
                        customSearch: true,
                        field: 'writing_status_id',
                        hide: this.status !== 'review',
                        label: 'Statut',
                        sortable: false
                    },
                    {
                        centered: false,
                        custom: true,
                        field: 'redactor',
                        label: 'Rédacteur',
                        sortable: false
                    },
                    {
                        custom: true,
                        customSearch: true,
                        field: 'written_at',
                        hide: !['review', 'done', 'error'].includes(this.status),
                        label: 'Rédigé le',
                        sortable: false
                    },
                    {
                        custom: true,
                        customSearch: true,
                        field: 'redactor_id',
                        hide: this.status !== 'processing',
                        label: 'Assignée',
                        sortable: false
                    },
                    {
                        centered: false,
                        custom: true,
                        customSearch: true,
                        field: 'client',
                        label: 'Client',
                        sortable: false
                    },
                    {
                        centered: false,
                        custom: true,
                        field: 'partner.email',
                        label: 'Partenaire'
                    },
                    {
                        centered: false,
                        custom: true,
                        field: 'keyword',
                        label: 'Mot-clé acheté',
                        sortable: false
                    },
                    {
                        centered: false,
                        custom: true,
                        field: 'primary_link',
                        label: 'Liens / ancres',
                        sortable: false
                    },
                    {
                        custom: true,
                        field: 'words_count',
                        label: 'Nombre de mots',
                        visible: false
                    },
                    {
                        custom: true,
                        field: 'duplication',
                        hide: this.status !== 'review',
                        label: 'Duplication',
                        searchable: false,
                        sortable: false
                    },
                    {
                        custom: true,
                        centered: false,
                        field: 'has_wordpress_plugin',
                        label: 'Plugin',
                        searchable: false,
                        sortable: false
                    },
                    {
                        custom: true,
                        field: 'lang',
                        label: 'Langue',
                        sortable: false,
                        visible: false
                    },
                    {
                        custom: true,
                        customSearch: true,
                        field: 'desired_publication_date',
                        hide: this.status !== 'processing',
                        label: 'Deadlines'
                    },
                    {
                        custom: true,
                        field: 'actions-other',
                        hide: this.status === 'processing',
                        label: 'Actions',
                        searchable: false,
                        sortable: false
                    }
                ],
                forceReviewIndex: -1,
                forceUpdate: false,
                loading: false,
                loadingCancel: false,
                loadingFinished: false,
                loadingGenerating: false,

                loadingIndex: null,
                loadingPending: false
            }
        },
        methods: {
            ...mapActions('redactors', [
                'getWritingByStatus',
                'setPriority',
                'setPending',
                'forceReview',
                'unassignWriting',
                'findRedactor',
                'assignRedactor',
                'deleteWriting',
                'setWritingFinished',
                'retry',
                'updateStatus',
                'generateContent'
            ]),

            doUpdateStatus(writing) {
                console.log('test')
                this.updateStatus(writing)
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
            },

            doWritingPublished(index, writing) {
                this.loading = true
                this.loadingIndex = index
                this.setWritingPublished(writing.id)
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: "It's all good",
                            message: 'La commande a bien été publiée 👌'
                        })
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingIndex = -1
                    })
            },

            retrySend(index, writing) {
                this.loading = true
                this.loadingIndex = index
                this.retry(writing.id)
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: "It's all good",
                            message: 'La commande a bien été publiée 👌'
                        })
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingIndex = -1
                    })
            },

            copyContent(e, content) {
                let testingCodeToCopy = this.$refs.copyInput
                testingCodeToCopy.setAttribute('type', 'text')

                testingCodeToCopy.value = content
                testingCodeToCopy.select()

                try {
                    document.execCommand('copy')
                    let btn = e.currentTarget

                    btn.classList.replace('btn-light', 'btn-info')
                    setTimeout(() => btn.classList.replace('btn-info', 'btn-light'), 2000)
                } catch (err) {
                    console.error('No copy ' + err)
                }
                testingCodeToCopy.setAttribute('type', 'hidden')
            },

            doUnAssignOrder(data) {
                this.loading = true
                this.unassignWriting(data.writing)
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },

            doAssignRedactor(redactor) {
                this.$refs.assignRedactorModal.hide()

                this.assignRedactor({
                    redactor: redactor,
                    id: this.writingId
                })
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'La commande a bien été attribué au rédacteur',
                            message: ''
                        })
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.writingId = -1
                        this.writingIndex = -1
                    })
            },

            openAssignModal(id, index) {
                this.writingId = id
                this.writingIndex = index
                this.$refs.assignRedactorModal.show()
            },

            openConfirmModal(index, writing) {
                let data = {
                    index,
                    writing
                }
                this.$refs.confirmModal.openModal('processing', data)
            },

            showSetWritingFinishedModal(writing, index) {
                const orderType = writing.entity_type === 1 ? 'commande' : 'précommande'

                this.$buefy.dialog.confirm({
                    title: 'Êtes-vous sûr ?',
                    message: `Mettre la <b>${orderType} #${writing.entity_id}</b> en terminé ?
                            Cela ne publiera rien, personne ne sera crédité, et vous devrez gérer la publication vous-même manuellement.`,
                    confirmText: 'Mettre en terminer',
                    cancelText: 'Annuler',
                    type: 'is-warning',
                    onConfirm: () => this.doSetWritingFinished(writing, index)
                })
            },

            doSetWritingFinished(writing, index) {
                this.writingIndex = index
                this.loadingCancel = true

                this.setWritingFinished(writing)
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.writingIndex = -1
                        this.loadingCancel = false
                    })
            },

            doForceReview(writing, index) {
                this.forceReviewIndex = index

                this.forceReview(writing.id)
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.forceReviewIndex = -1
                    })
            },

            savePendingMessage() {
                this.setPendingWriting(this.$store.state.redactors.writing, true, this.writingIndex)
                this.$refs.addPendingMessage.hide()
            },

            showPendingModal(writing, index) {
                this.$store.commit('redactors/setWriting', writing)
                this.writingId = writing.id
                this.writingIndex = index
                this.$refs.addPendingMessage.show()
            },

            setPendingWriting(writing, isPending, index) {
                this.loadingPending = true
                this.loadingIndex = index

                let reason = isPending ? writing.reason : null

                this.setPending({ id: writing.id, pending: isPending, reason: reason })
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loadingPending = false
                    })
            },

            setPrio: function (index, id, isPrio) {
                this.loading = true
                this.loadingIndex = index

                this.setPriority({ id: id, priority: isPrio })
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },

            showDeleteModal(writing, index) {
                const orderType = writing.entity_type === 1 ? 'commande' : 'précommande'

                this.$buefy.dialog.confirm({
                    title: 'Êtes-vous sûr ?',
                    message: `Êtes-vous sûr de vouloir supprimer la rédaction pour la <b>${orderType} #${writing.entity_id}</b>`,
                    confirmText: 'Supprimer',
                    cancelText: 'Annuler',
                    type: 'is-danger',
                    onConfirm: () => this.doDeleteWriting(writing, index),
                    onCancel: () => {}
                })
            },

            doDeleteWriting(writing, index) {
                this.writingIndex = index
                this.loadingCancel = true

                this.deleteWriting(writing)
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.writingIndex = -1
                        this.loadingCancel = false
                    })
            },

            showGenerateContentModal(writing, index) {
                this.$refs.generateContentModal.show(writing, index)
            },

            doGenerateContent(generate) {
                this.loadingGenerating = true
                this.loadingIndex = generate.index

                this.generateContent({ writing: generate.writing.id, params: { titles: generate.titles } })
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loadingGenerating = false
                        this.loadingIndex = null
                    })
            },

            getGeneratedStatusClass(writing) {
                let statusClass = ''
                if (writing.generating_status_id === 1) {
                    statusClass = 'text-warning'
                } else if (writing.generating_status_id === 0) {
                    statusClass = 'text-success'
                } else if (writing.generating_status_id === 2) {
                    statusClass = 'text-danger'
                }

                return statusClass
            },

            loadAsyncData(params) {
                return this.getWritingByStatus({
                    status: this.status,
                    params
                })
            }
        }
    }
</script>