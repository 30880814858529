<template>
    <div class="mb-3">
        <b-field :label="label">
            <div class="row">
                <div class="col-sm-6">
                    <b-field>
                        <p class="control m-0">
                            <span class="button is-static h-100">FR</span>
                        </p>
                        <b-input :type="type" v-model="form.fr" expanded></b-input>
                    </b-field>
                </div>
                <div class="col-sm-6">
                    <b-field>
                        <p class="control m-0">
                            <span class="button is-static h-100">EN</span>
                        </p>
                        <b-input :type="type" v-model="form.en" expanded></b-input>
                    </b-field>
                </div>
            </div>
        </b-field>
        <b-button
            type="is-primary"
            @click="
                $emit('update', {
                    key: configKey,
                    fr: form.fr,
                    en: form.en,
                    type: 'translation'
                })
            "
            >Modifier
        </b-button>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'TranslationConfig',
        props: ['configKey', 'label', 'type'],
        computed: {
            ...mapState('config', {
                config: state => state.config
            })
        },
        data() {
            return {
                form: {
                    fr: '',
                    en: ''
                }
            }
        },
        created() {
            if (this.config[this.configKey]) {
                this.config[this.configKey].forEach(config => {
                    this.form[config.lang ? config.lang : 'fr'] = config.value ?? ''
                })
            }
        }
    }
</script>

<style scoped></style>
