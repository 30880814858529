<template>
    <div>
        <b-table
            ref="table"
            :sticky-header="true"
            :data="keywords"
            :mobile-cards="hasMobileCards"
            :checked-rows.sync="checkedRows"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            default-sort="created_at"
            default-sort-direction="desc"
            detailed
            custom-detail-row
            :show-detail-icon="false"
            checkable
            :striped="true"
            :debounce-search="200"
            :checkbox-position="checkboxPosition"
        >
            <b-table-column
                field="keyword"
                label="Mot clé"
                sortable
                centered
                header-class="cat-1 align-middle export"
                cell-class="cat-1"
            >
                <div slot="default" slot-scope="props">
                    <div v-if="cacherResultats || (isProspect && userCredit == 0 && peutVoirMotsClesSansCondition)">
                        <span
                            v-if="isAdmin"
                            data-toggle="tooltip"
                            data-placement="top"
                            title=""
                            data-original-title="blacklist couple générale"
                        >
                            <i class="text-normal fa-ban fa cursor-pointer" :data-id="props.row.urlId" />
                        </span>

                        <a
                            v-if="props.row.partenaireId && props.row.partenaireId > 0"
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            :title="$t('tableau-keywords.partnersite')"
                        >
                            <i class="far fa-handshake" />
                        </a>

                        <i
                            class="text-secondary fa-star favorite"
                            :class="[favorites.includes(props.row.urlId) ? 'fa' : 'far']"
                            :data-id="props.row.urlId"
                        />

                        <span class="pre-formatted">{{ props.row.keyword }}</span>

                        <span v-if="props.row.other_kw && props.row.other_kw.length > 0">
                            <span class="fas fa-plus ml-1 mr-1" :data-id="props.row.urlId" />
                        </span>
                    </div>
                    <div v-else>
                        <span
                            v-if="isAdmin"
                            data-toggle="tooltip"
                            data-placement="top"
                            title=""
                            data-original-title="blacklist couple générale"
                        >
                            <i class="text-normal fa-ban fa cursor-pointer" :data-id="props.row.urlId" />
                        </span>

                        <a
                            v-if="props.row.partenaireId && props.row.partenaireId > 0"
                            href="#"
                            data-toggle="tooltip"
                            data-placement="top"
                            :title="$t('tableau-keywords.partnersite')"
                        >
                            <i class="far fa-handshake" />
                        </a>

                        <i
                            class="text-secondary fa-star favorite"
                            :class="[favorites.includes(props.row.urlId) ? 'fa' : 'far']"
                            :data-id="props.row.urlId"
                        />

                        <span class="pre-formatted">{{ props.row.keyword }}</span>

                        <a v-if="isFavorites" class="projects-move" href="#" :data-id="props.row.urlId">
                            <i class="far fa-folder-open" /> {{ props.row.project_name }}
                        </a>

                        <span v-if="props.row.other_kw && props.row.other_kw.length > 0">
                            <a @click.prevent="toggle(props.row)">
                                <template v-if="props.row.isOpened && props.row.isOpened === true">
                                    <span class="fas fa-minus ml-1 mr-1 showOtherKw" :data-id="props.row.urlId" />
                                </template>
                                <template v-else>
                                    <span class="fas fa-plus ml-1 mr-1 showOtherKw" :data-id="props.row.urlId" />
                                </template>
                            </a>
                        </span>

                        <div v-if="isAdmin">
                            <br />
                            <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="blacklist url projet"
                            >
                                <i class="text-normal fa-times-circle fa cursor-pointer" :data-id="props.row.urlId" />
                            </span>
                            <a :href="props.row.url" target="_blank" rel="noreferrer noopener">
                                {{ props.row.url }}
                            </a>
                            - {{ $t('global.redaction') }}

                            <span v-if="props.row.redaction === 'nextlevel'">NL</span>
                            <span v-else>{{ $t('partenaire-site.partner') }}</span>
                        </div>
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="positionCustomer"
                label="Votre position"
                numeric
                sortable
                centered
                header-class="cat-1 align-middle export"
                cell-class="cat-1"
            >
                <div slot="default" slot-scope="props">
                    {{ props.row.positionCustomer }}
                </div>
            </b-table-column>

            <b-table-column
                field="position"
                label="Position"
                sortable
                centered
                header-class="cat-1 align-middle export"
                cell-class="cat-1"
            >
                <div slot="default" slot-scope="props">
                    <div v-if="cacherResultats || (isProspect && userCredit == 0 && peutVoirMotsClesSansCondition)">
                        <span style="display: block; background-color: #404e67; width: 50px; height: 20px" />
                    </div>
                    <div v-else>
                        {{ props.row.position }}
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="volume"
                label="Volume"
                numeric
                sortable
                centered
                header-class="cat-1 align-middle export"
                cell-class="cat-1"
            >
                <div slot="default" slot-scope="props">
                    {{ props.row.volume }}
                </div>
            </b-table-column>

            <b-table-column v-slot="props" header-class="table-separation" cell-class="table-separation" />

            <b-table-column
                field="countKeywords"
                label="NB de mots clés positionnés"
                numeric
                sortable
                centered
                header-class="cat-2 align-middle export"
                cell-class="cat-2"
            >
                <div slot="default" slot-scope="props">
                    {{ props.row.countKeywords }}
                </div>
            </b-table-column>

            <b-table-column
                field="countTopTen"
                label="Top 10"
                numeric
                sortable
                centered
                header-class="cat-2 align-middle export"
                cell-class="cat-2"
            >
                <div slot="default" slot-scope="props">
                    <div v-if="cacherResultats || (isProspect && userCredit == 0 && peutVoirMotsClesSansCondition)">
                        <span style="display: block; background-color: #404e67; width: 50px; height: 20px" />
                    </div>
                    <div v-else>
                        {{ props.row.countTopTen }}
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="indice"
                label="Indice url"
                numeric
                sortable
                centered
                header-class="cat-4 align-middle export"
                cell-class="cat-4"
            >
                <div slot="default" slot-scope="props">
                    {{ props.row.indice }}
                </div>
            </b-table-column>

            <b-table-column v-slot="props" header-class="table-separation" cell-class="table-separation" />

            <b-table-column
                field="type"
                label="Catégorie"
                sortable
                centered
                header-class="cat-3 align-middle export"
                cell-class="cat-3"
            >
                <div slot="default" slot-scope="props">
                    {{ props.row.type }}
                </div>
            </b-table-column>

            <b-table-column
                field="thematique"
                label="Thématique"
                sortable
                centered
                header-class="cat-3 align-middle export"
                cell-class="cat-3"
            >
                <div slot="default" slot-scope="props">
                    {{ props.row.thematique }}
                </div>
            </b-table-column>

            <b-table-column v-slot="props" header-class="table-separation" cell-class="table-separation" />

            <b-table-column
                field="price"
                label="Prix"
                numeric
                sortable
                centered
                header-class="cat-5 align-middle export"
                cell-class="cat-5"
            >
                <div slot="default" slot-scope="props">
                    <div v-if="cacherResultats">
                        <a
                            href="#"
                            class="btn btn-danger"
                            data-toggle="tooltip"
                            data-placement="top"
                            :title="$t('tableau-keywords.contact')"
                        >
                            <i class="fa fa-exclamation-triangle" aria-hidden="true" />
                            {{ props.row.price }}€
                        </a>
                    </div>

                    <div v-else-if="userCredit == 0 && props.row.price > 0">
                        <a
                            href="/buy-credits"
                            class="btn btn-danger"
                            data-toggle="tooltip"
                            data-placement="top"
                            :title="$t('tableau-keywords.credit')"
                        >
                            <i class="fa fa-exclamation-triangle" aria-hidden="true" />
                            {{ props.row.price }}€
                        </a>
                    </div>

                    <div v-else-if="userCredit < props.row.price && props.row.price > 0">
                        <a
                            href="/buy-credits"
                            class="btn btn-danger"
                            data-toggle="tooltip"
                            data-placement="top"
                            :title="$t('tableau-keywords.credittoorder')"
                        >
                            <i class="fa fa-exclamation-triangle" aria-hidden="true" />
                            {{ props.row.price }}€
                        </a>
                    </div>

                    <div v-else-if="props.row.price > 0">
                        <button
                            type="button"
                            class="btn btn-secondary achat-lien"
                            :class="[props.row.solde ? 'promo' : '']"
                            :data-price="props.row.price"
                            :data-price-lien-supplementaire="props.row.price * 0.15"
                            :data-stat-id="props.row.urlId"
                        >
                            <i class="fa fa-cart-plus" aria-hidden="true" />
                            {{ props.row.price }}€ HT

                            <div v-if="props.row.solde">
                                <br />
                                <span style="font-size: 75%"> au lieu de {{ props.row.price * 2 }}€ HT </span>
                            </div>
                        </button>
                    </div>

                    <div v-else>
                        <!-- Noting to do here -->
                    </div>
                </div>
            </b-table-column>

            <template slot="detail" slot-scope="props">
                <tr
                    v-for="subkw in props.row.other_kw"
                    :key="subkw.urlId"
                    style="text-align: center"
                    class="sub-item-view sub-item"
                >
                    <td style="vertical-align: top" class="cat-1">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="resultExport[]"
                                :value="subkw.urlId"
                            />
                        </div>
                    </td>

                    <td v-if="cacherResultats || (isProspect && userCredit == 0 && !peutVoirMotsClesSansCondition)">
                        <span class="pre-formatted">
                            {{ subkw.keyword }}
                        </span>

                        <span
                            v-if="subkw.nbUrl > 1"
                            class="fas fa-plus ml-1 mr-1 showOtherKw"
                            :data-stat-id="subkw.urlId"
                        />
                    </td>

                    <td v-else class="cat-1 align-middle">
                        <span
                            v-if="isAdmin"
                            data-toggle="tooltip"
                            data-placement="top"
                            title=""
                            data-original-title="blacklist couple générale"
                        >
                            <i class="text-normal fa-ban fa cursor-pointer" :data-id="subkw.urlId" />
                        </span>

                        <span v-if="subkw.solde" class="badge badge-danger promo">
                            <i class="fas fa-tags" /> {{ $t('tableau-keywords.promo') }} -50%
                        </span>

                        <i
                            class="text-secondary fa-star favorite"
                            :class="[favorites.includes(subkw.urlId) ? 'fa' : 'far']"
                            :data-id="subkw.urlId"
                        />

                        <span v-if="subkw.partenaireId && subkw.partenaireId > 0">
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                :title="$t('tableau-keywords.partnersite')"
                            >
                                <i class="far fa-handshake" />
                            </a>
                        </span>

                        <span class="pre-formatted">
                            {{ subkw.keyword }}
                        </span>

                        <div v-if="isFavorites">
                            <a class="projects-move" href="#" :data-id="subkw.urlId">
                                <i class="far fa-folder-open" />
                                {{ subkw.project_name }}
                            </a>
                        </div>

                        <div v-if="subkw.nbUrl > 1">
                            <span
                                class="fas fa-plus ml-1 mr-1 showOtherKw"
                                style="cursor: pointer"
                                :data-stat-id="subkw.urlId"
                            />
                        </div>

                        <div v-if="isAdmin">
                            <br />
                            <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="blacklist url projet"
                            >
                                <i class="text-normal fa-times-circle fa cursor-pointer" :data-id="subkw.urlId" />
                            </span>
                            <a :href="subkw.url" target="_blank" rel="noreferrer noopener">
                                {{ subkw.url }}
                            </a>
                            - {{ $t('global.redaction') }}

                            <span v-if="subkw.redaction === 'nextlevel'">NL</span>
                            <span v-else>{{ $t('partenaire-site.partner') }}</span>
                        </div>
                    </td>

                    <td class="cat-1 align-middle export">
                        {{ subkw.positionCustomer }}
                    </td>

                    <td class="cat-1 align-middle">
                        <div v-if="cacherResultats || (isProspect && userCredit == 0 && peutVoirMotsClesSansCondition)">
                            <span style="display: block; background-color: #404e67; width: 50px; height: 20px" />
                        </div>
                        <div v-else>
                            {{ subkw.position }}
                        </div>
                    </td>

                    <td class="cat-1 align-middle">
                        <div v-if="subkw.volume == 11">N/C</div>
                        <div v-else>
                            {{ subkw.volume }}
                        </div>
                    </td>

                    <td class="table-separation" />

                    <td class="cat-2 ok align-middle">
                        {{ subkw.countKeywords == 0 ? '1' : subkw.countKeywords }}
                    </td>

                    <template
                        v-if="cacherResultats || (isProspect && userCredit == 0 && peutVoirMotsClesSansCondition)"
                    >
                        <td class="cat-2">
                            <span style="display: block; background-color: #404e67; width: 50px; height: 20px" />
                        </td>
                    </template>

                    <template v-else>
                        <td class="cat-2 align-middle">
                            {{ subkw.countTopTen }}
                        </td>
                    </template>

                    <td class="cat-4 align-middle">
                        {{ subkw.indice }}
                    </td>

                    <td class="table-separation" />

                    <td class="cat-3 align-middle">
                        {{ subkw.type }}
                    </td>

                    <td class="cat-3 align-middle">
                        {{ subkw.thematique }}
                    </td>

                    <td class="table-separation" />

                    <td>
                        <div v-if="cacherResultats">
                            <a
                                href="#"
                                class="btn btn-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                :title="$t('tableau-keywords.contact')"
                            >
                                <i class="fa fa-exclamation-triangle" aria-hidden="true" />
                                {{ subkw.price }}€
                            </a>
                        </div>

                        <div v-else-if="userCredit == 0 && subkw.price > 0">
                            <a
                                href="/buy-credits"
                                class="btn btn-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                :title="$t('tableau-keywords.credit')"
                            >
                                <i class="fa fa-exclamation-triangle" aria-hidden="true" />
                                {{ subkw.price }}€
                            </a>
                        </div>

                        <div v-else-if="userCredit < subkw.price && subkw.price > 0">
                            <a
                                href="/buy-credits"
                                class="btn btn-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                :title="$t('tableau-keywords.credittoorder')"
                            >
                                <i class="fa fa-exclamation-triangle" aria-hidden="true" />
                                {{ subkw.price }}€
                            </a>
                        </div>

                        <div v-else-if="subkw.price > 0">
                            <button
                                type="button"
                                class="btn btn-secondary achat-lien"
                                :class="[subkw.solde ? 'promo' : '']"
                                :data-price="subkw.price"
                                :data-price-lien-supplementaire="subkw.price * 0.15"
                                :data-stat-id="subkw.urlId"
                            >
                                <i class="fa fa-cart-plus" aria-hidden="true" />
                                {{ subkw.price }}€ HT

                                <div v-if="subkw.solde">
                                    <br />
                                    <span style="font-size: 75%"> au lieu de {{ subkw.price * 2 }}€ HT </span>
                                </div>
                            </button>
                        </div>

                        <div v-else>
                            <!-- Noting to do here -->
                        </div>
                    </td>
                </tr>
            </template>

            <template #bottom-left>
                <div v-show="checkedRows.length > 0" />
            </template>
        </b-table>
    </div>
</template>

<script>
    export default {
        name: 'TabSearchDomain',
        props: {
            list: {
                type: Array,
                default: [],
                description: 'Data the table has to show'
            },
            isAdmin: {
                type: Boolean,
                default: false,
                description: 'Is the current user an admin ?'
            },
            favoritesNL: {
                type: Array,
                default: [],
                description: 'Id URL Array of NL favorites links'
            },
            favorites: {
                type: Array,
                default: [],
                description: 'Id URL Array of current user favorites links'
            },
            isFavoritesNL: {
                type: Boolean,
                default: false,
                description: '  ¯\_(ツ)_/¯  '
            },
            isFavorites: {
                type: Boolean,
                default: false,
                description: '  ¯\_(ツ)_/¯  '
            },
            isProspect: {
                type: Boolean,
                default: false,
                description: 'Is the current user a prospect ?'
            },
            cacherResultats: {
                type: Boolean,
                default: false,
                description: 'should hide results ?'
            },
            userCredit: {
                type: Number,
                default: 0,
                description: 'Credit of the current user'
            }
        },
        data: function () {
            return {
                keywords: [],
                showDetailIcon: true,
                checkboxPosition: 'left',
                hasMobileCards: true,
                checkedRows: [],
                isPaginated: true,
                defaultSortDirection: 'asc',
                currentPage: 1,
                perPage: 50,
                project: {},
                appUrl: '',
                status: 'Tous'
            }
        },
        watch: {
            list: function (newValue) {
                this.keywords = newValue
                this.checkedRows = []
            }
        },
        created() {
            this.keywords = this.list && this.list.length > 0 ? this.list : []
        },
        mounted() {},
        methods: {
            toggle(row) {
                if (row.hasOwnProperty('isOpened')) {
                    row.isOpened = !row.isOpened
                } else {
                    row.isOpened = true
                }

                this.$refs.table.toggleDetails(row)
            }
        }
    }
</script>
