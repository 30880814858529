<template>
    <div class="tac__config mb-3">
        <b-field :label="label">
            <div class="row">
                <div class="col-sm-6">
                    <b-field>
                        <p class="control m-0">
                            <span class="button is-static h-100">FR</span>
                        </p>
                        <b-input v-model="tacs.fr.tac" type="textarea" expanded />
                    </b-field>
                </div>
                <div class="col-sm-6">
                    <b-field>
                        <p class="control m-0">
                            <span class="button is-static h-100">EN</span>
                        </p>
                        <b-input v-model="tacs.en.tac" type="textarea" expanded />
                    </b-field>
                </div>
            </div>
        </b-field>
        <b-button type="is-primary" @click="newTacs"> Modifier</b-button>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'TacsConfig',
        props: {
            label: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                tacs: {
                    fr: {
                        tac: ''
                    },
                    en: {
                        tac: ''
                    }
                },
                form: {
                    fr: {
                        tac: '',
                        lang: 'fr',
                        version: '0.9'
                    },
                    en: {
                        tac: '',
                        lang: 'en',
                        version: '0.9'
                    }
                }
            }
        },
        created() {
            this.lastTacs()
        },
        methods: {
            ...mapActions('config', ['getLastTacs', 'createTacs']),

            newTacs() {
                this.createTacs({
                    fr: this.tacs.fr,
                    en: this.tacs.en
                }).then(() => {
                    this.lastTacs()
                })
            },
            lastTacs() {
                this.getLastTacs().then(data => {
                    this.tacs.fr = data.tacs.fr
                    this.tacs.en = data.tacs.en
                })
            }
        }
    }
</script>

<style scoped></style>
