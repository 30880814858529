export default {
    setOrders(state, data) {
        state.orders = data
    },
    setStartDate(state, date) {
        state.startDate = date
    },
    setEndDate(state, date) {
        state.endDate = date
    },
    setWithdrawal(state, data) {
        state.withdrawal = data
    }
}