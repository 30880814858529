<template>
    <div>
        <b-table v-if="keywords.length" :data="keywords" checkable :checked-rows.sync="checkedRows">
            <b-table-column field="keyword" label="Mot clé" width="15%">
                <div slot="default" slot-scope="props">
                    <span>{{ props.row.mot_cle }}</span>
                </div>
            </b-table-column>

            <b-table-column field="url" label="URL" width="30%">
                <div slot="default" slot-scope="props">
                    <input v-model="props.row.url" type="text" class="form-control form-control-sm" />
                </div>
            </b-table-column>

            <b-table-column field="position" label="Position" width="1em">
                <div slot="default" slot-scope="props" class="text-center">
                    <span>{{ props.row.position_initiale }}</span>
                </div>
            </b-table-column>

            <b-table-column field="rd" label="RD" width="1em">
                <div slot="default" slot-scope="props" class="text-center">
                    <span>{{ props.row.rd_kw }}</span>
                </div>
            </b-table-column>

            <b-table-column field="note" label="Note">
                <div slot="default" slot-scope="props">
                    <input v-model="props.row.notes" type="text" class="form-control form-control-sm" />
                </div>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    export default {
        name: 'NewCampaignUrlTable',
        props: {
            keywords: {
                required: true,
                type: Array
            }
        },
        data() {
            return {
                checkedRows: [],
                allKeywords: []
            }
        },
        watch: {
            checkedRows() {
                this.$emit('rowsChecked', this.checkedRows)
            },
            keywords() {
                this.checkedRows = []
            }
        }
    }
</script>
