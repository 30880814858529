<style scoped>
    /deep/ .modal-header {
        padding: 0;
    }

    .bg-antracite {
        background: #3e454d;
    }

    .btn-third {
        background-color: #3e454d !important;
    }

    .btn-action {
        color: white;
        font-size: 110%;
        border: none;
        padding: 15px 35px;
        border-radius: 90px 13px 90px 13px;
        font-family: 'Poppins';
    }

    .btn-third:disabled {
        color: white;
        background-color: #b7b7b7 !important;
        cursor: not-allowed;
    }
</style>

<script>
    export default {
        name: 'AgencyModal',
        methods: {
            show() {
                this.$emit('show')
                this.$refs.modal.show()
            },
            hide() {
                this.$emit('hide')
                this.$refs.modal.hide()
            }
        }
    }
</script>
