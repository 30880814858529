export default {
    setDateFrom(state, data) {
        state.date_from = data
    },
    setDateTo(state, data) {
        state.date_to = data
    },
    setReports(state, data) {
        state.reports = data
    }
}
