import ApiService from '../../../services/api.service'

export default {
    list(context, params) {
        const { sort, page, rowsPerPage, filter } = params ? params : [null, null, null, null]
        context.commit('setLoading')
        return new Promise((resolve, reject) => {
            ApiService.get('/agency/consultant', {
                sort,
                page,
                rowsPerPage,
                filter
            })
                .then(({ data }) => {
                    context.commit('setList', data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    async getAgencies(context, args) {
        const clientConfig = args && args.config ? args.config : context.state.agenciesConfig

        const params = await context.dispatch('prepareParams', clientConfig, {
            root: true
        })

        return new Promise((resolve, reject) => {
            ApiService.get(`/agencies`, params)
                .then(({ data }) => {
                    if (!args || (args && args.commit && args.commit === true)) {
                        context.commit('setAgencies', data.agencies)
                    }

                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    getAgency(context) {
        context.commit('setLoading', true)

        return new Promise((resolve, reject) => {
            ApiService.get(`/agencies/${context.rootState.auth.user.id}`)
                .then(({ data }) => {
                    context.commit('setAgency', data.agency)
                    context.commit('setManagers', data.managers)
                    context.commit('setConsultants', data.consultants)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
                .finally(() => {
                    context.commit('setLoading', false)
                })
        })
    },

    getAgencyOrg(context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/agencies/${context.rootState.auth.user.id}/org`)
                .then(({ data }) => {
                    context.commit('setOrg', data.org)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    async getManagers(context, args) {
        const clientConfig = args && args.config ? args.config : context.state.managersConfig

        const params = await context.dispatch('prepareParams', clientConfig, {
            root: true
        })

        return new Promise((resolve, reject) => {
            ApiService.get(`/agencies/${context.state.agency.id}/managers`, params)
                .then(({ data }) => {
                    if (!args || (args && args.commit && args.commit === true)) {
                        context.commit('setManagers', data.managers)
                    }

                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    getManager(context, managerId) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/agencies/${context.state.agency.id}/managers/${managerId}?month=${context.state.month}`)
                .then(({ data }) => {
                    context.commit('setManager', data.manager)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    getConsultant(context) {
        return new Promise((resolve, reject) => {
            context.dispatch('getRootUrlByScope', context.state.scope).then(baseUrl => {
                ApiService.get(`${baseUrl}`)
                    .then(({ data }) => {
                        context.commit('setConsultant', data.consultant)
                        resolve(data)
                    })
                    .catch(({ response }) => {
                        reject(response)
                    })
            })
        })
    },

    getAgencyClient(context, data) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/agencies/${data.agency}/clients/${data.client}`)
                .then(({ data }) => {
                    context.commit('setAgencyClient', data.client)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    async getAgencyClientContents(context, data) {
        let params
        if (data.params) {
            params = await context.dispatch('prepareParams', data.params, {
                root: true
            })
        } else {
            params = {}
        }

        return new Promise((resolve, reject) => {
            ApiService.get(`/agencies/${data.agency}/clients/${data.client}/contents`, params)
                .then(({ data }) => {
                    context.commit('setAgencyClientContents', data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    async getConsultantContents(context, data) {
        let params
        if (data.params) {
            params = await context.dispatch('prepareParams', data.params, {
                root: true
            })
        } else {
            params = {}
        }

        params[`filter[for_month]`] = `${context.state.month}`

        return new Promise((resolve, reject) => {
            let endpoint

            if (data && data.manager) {
                endpoint = ApiService.get(
                    `/agencies/${data.agency}/managers/${data.manager}/consultants/${data.consultant}/contents`,
                    params
                )
            } else {
                endpoint = ApiService.get(`/agencies/${data.agency}/consultants/${data.consultant}/contents`, params)
            }

            endpoint
                .then(({ data }) => {
                    context.commit('setConsultantContents', data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    getConsultantInfo(context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/consultants/${id}`)
                .then(({ data }) => {
                    context.commit('setConsultant', data.consultant)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    async getConsultants(context, args) {
        const clientConfig = args && args.config ? args.config : context.state.consultantsConfig

        const params = await context.dispatch('prepareParams', clientConfig, {
            root: true
        })
        const baseUrl = await context.dispatch('getRootUrlByScope', context.state.scope)

        return new Promise((resolve, reject) => {
            ApiService.get(`${baseUrl}/consultants`, params)
                .then(({ data }) => {
                    if (!args || (args && args.commit && args.commit === true)) {
                        context.commit('setConsultants', data.consultants)
                    }

                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    async getClients(context) {
        let params = await context.dispatch('prepareParams', context.state.clientsConfig, { root: true })
        const baseUrl = await context.dispatch('getRootUrlByScope', context.state.scope)

        return new Promise((resolve, reject) => {
            ApiService.get(`${baseUrl}/clients`, params)
                .then(({ data }) => {
                    context.commit('setClients', data.clients)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    getCreditsLog(context, consultantId) {
        return new Promise((resolve, reject) => {
            ApiService.get('/agency/consultant/' + consultantId + '/credits-log')
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    create(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/agencies/${context.rootState.auth.user.id}/consultants`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    update(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put('/agency/consultant/' + params.id, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    enabled(context, consultantId) {
        return new Promise((resolve, reject) => {
            ApiService.put('/agency/consultant/' + consultantId + '/enabled')
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    disabled(context, consultantId) {
        return new Promise((resolve, reject) => {
            ApiService.put('/agency/consultant/' + consultantId + '/disabled')
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    addCredits(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put('/agency/consultant/' + params.id + '/add-credits', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    removeCredits(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put('/agency/consultant/' + params.id + '/remove-credits', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    bulkAddCredits(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/agency/consultant/bulk-add-credits', params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    creditUser(context) {
        let params = context.state.credits
        params.target = context.state.target.user_id
        params.source = context.state.source ?? context.state.agency.id

        delete params.type

        return new Promise((resolve, reject) => {
            ApiService.post(`/agencies/${context.state.agency.id}/credits`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    debitUser(context) {
        let params = context.state.credits
        params.target = context.state.target.user_id
        params.source = context.state.source ?? context.state.agency.id

        delete params.type

        return new Promise((resolve, reject) => {
            ApiService.post(`/agencies/${context.state.agency.id}/debits`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    createConsultant(context) {
        const params = context.state.newEmployee

        if (params.budget_initial == null) {
            params.budget_initial = 0
        }

        return new Promise((resolve, reject) => {
            ApiService.post(`/agencies/${context.state.agency.id}/consultants`, params)
                .then(({ data }) => {
                    context.state.agency.consultants_count += 1
                    context.dispatch(
                        'toast',
                        {
                            type: 'success',
                            title: 'Le manager a bien été créé'
                        },
                        { root: true }
                    )
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    createManager(context) {
        const params = context.state.newEmployee

        if (params.budget_initial == null) {
            params.budget_initial = 0
        }

        return new Promise((resolve, reject) => {
            ApiService.post(`/agencies/${context.state.agency.id}/managers`, params)
                .then(({ data }) => {
                    context.state.agency.managers_count += 1
                    context.dispatch(
                        'toast',
                        {
                            type: 'success',
                            title: 'Le manager a bien été créé'
                        },
                        { root: true }
                    )
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    createClient(context) {
        const params = context.state.newClient

        if (params.budget_initial == null) {
            params.budget_initial = 0
        }

        return new Promise((resolve, reject) => {
            ApiService.post(`/agencies/${context.state.agency.id}/clients`, params)
                .then(({ data }) => {
                    resolve(data)
                    context.state.agency.agency_clients_count = context.state.agency.agency_clients_count + 1
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    affectUserToAgency(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/agencies`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    promote(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/agencies/${context.state.agency.id}/consultants/${params.id}/promote`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    demote(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/agencies/${context.state.agency.id}/managers/${params.id}/demote`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    updateManager(context) {
        const params = context.state.newEmployee

        return new Promise((resolve, reject) => {
            ApiService.put(`/agencies/${context.state.agency.id}/managers/${params.id}`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    updateConsultant(context) {
        const params = context.state.newEmployee

        return new Promise((resolve, reject) => {
            ApiService.put(`/agencies/${context.state.agency.id}/consultants/${params.id}`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    affectConsultant(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/agencies/${context.state.agency.id}/consultants/${params.id}`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    affectClientToResponsible(context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/agencies/${context.state.agency.id}/clients/${params.id}`, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    getAgencyFromUser(context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/agencies/users/${context.rootState.auth.user.id}`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    removeUserFromAgency(context, userId) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/agencies/${context.state.agency.id}/delete/${userId}`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },

    getRootUrlByScope(context, scope) {
        switch (scope) {
            case 'manager':
                return `/agencies/${context.state.agency.id}/managers/${context.state.manager.id}`

            case 'consultant':
                if (context.state.manager?.id)
                    return `/agencies/${context.state.agency.id}/managers/${context.state.manager.id}/consultants/${context.state.consultant.id}`

                return `/agencies/${context.state.agency.id}/consultants/${context.state.consultant.id}`

            default: // case for agency or null or unknown state
                return `/agencies/${context.state.agency.id}`
        }
    }
}
