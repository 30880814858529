<template>
    <div>
        <div class="d-flex align-items-center justify-content-end w-100">
            <div class="d-flex align-items-center justify-content-end">
                <button id="rechargement" type="button" class="btn btn-primary mr-2" @click="loadAsyncData">
                    <i class="fas fa-sync-alt" />
                </button>
                <input v-model="perPage" type="number" class="input mr-2" style="width: 50% !important" />
                <b-dropdown aria-role="list" :close-on-click="false" position="is-bottom-left" class="mr-2">
                    <template #trigger="{ active }">
                        <b-button
                            :label="$t('global.columns')"
                            type="is-info"
                            :icon-right="active ? 'caret-up' : 'caret-down'"
                        />
                    </template>

                    <b-dropdown-item v-for="(col, index) in columns" :key="index" aria-role="listitem">
                        <b-field>
                            <b-checkbox v-model="col.visible">
                                {{ col.name }}
                            </b-checkbox>
                        </b-field>
                    </b-dropdown-item>
                </b-dropdown>
                <b-field class="mr-2">
                    <b-select v-model="bulkActionType" :placeholder="$t('global.actions')">
                        <option value="setPartner">Disponibilité Partenaire</option>
                        <option value="launchPreOrders">Lancement de précommandes</option>
                        <option value="setWhitePage">White Page</option>
                        <option value="setAI">Text Bulker</option>
                        <option value="setRedactor">Redactor</option>
                        <option value="bulkDuplicate">Dupliquer</option>
                        <option value="bulkDelete">Supprimer</option>
                    </b-select>
                    <b-button
                        :disabled="!checkedRows.length || bulkActionType === null"
                        type="is-warning"
                        class="mb-2 mb-md-0 ml-md-2"
                        @click="openConfirmModal"
                    >
                        {{ $t('global.validate') }}
                    </b-button>
                </b-field>
            </div>
        </div>

        <b-table
            :data="preorders.data"
            checkable
            :checked-rows.sync="checkedRows"
            :is-row-checkable="row => !row.launched_at"
            :loading="tableLoading"
            :default-sort="[sortField, sortOrder]"
            :per-page="preorders.per_page"
            :total="preorders.total"
            backend-sorting
            backend-pagination
            backend-filtering
            :row-class="row => (row.sold === 1 ? 'sold_row' : row.nl_results === 1 ? 'nl_results_row' : '')"
            paginated
            @page-change="onPageChange"
            @sort="onSort"
            @filters-change="onFilterChange"
        >
            <b-table-column field="status" :searchable="isSearchable" sortable label="Statut">
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.status === 'todo'">
                        <span class="tag is-warning">À faire</span>
                    </span>
                    <span v-else-if="props.row.status === 'in_progress'">
                        <span class="tag is-info">En cours</span>
                    </span>
                    <span v-else-if="props.row.status === 'ai_in_progress'">
                        <span class="tag is-info">Texte IA En cours</span>
                    </span>
                    <span v-else-if="props.row.status === 'ai_completed'">
                        <span class="tag is-info">Texte IA généré</span>
                    </span>
                    <span v-else-if="props.row.status === 'ai_canceled'">
                        <span class="tag is-danger">Texte IA annulé</span>
                    </span>
                    <span v-else-if="props.row.status === 'done'">
                        <span class="tag is-success">Fait</span>
                    </span>
                    <span v-else>
                        <span class="tag is-dark">Erreur</span>
                    </span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <select v-model="props.filters[props.column.field]" class="form-control form-control-sm p-1">
                        <option :value="props.filters[props.column.field]" disabled hidden>
                            {{ props.column.label }}
                        </option>
                        <option :value="null" selected>Tous</option>
                        <option value="todo">À faire</option>
                        <option value="in_progress">En cours</option>
                        <option value="ai_in_progress">IA en cours</option>
                        <option value="ai_completed">IA généré</option>
                        <option value="ai_canceled">IA annulé</option>
                        <option value="done">Fait</option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column
                field="created_at"
                sortable
                :searchable="isSearchable"
                label="Date"
                :visible="columns.date.visible"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ moment(props.row.created_at).format('DD/MM/YYYY') }}</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="date"
                            class="form-control form-control-sm"
                            :placeholder="$t('global.time.date') + '...'"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="campaign_url"
                sortable
                :searchable="isSearchable"
                label="Accompagnement"
                :visible="isFullPage"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ props.row.campaign.url.replace(/^(https?:\/\/(?:www\.|(?!www)))/i, '') }}</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="props.column.label"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column field="type" sortable :searchable="isSearchable" label="Qui">
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.launched_at">
                        {{ props.row.type === 'whitepage' || 'redactor' || 'textbulker' ? props.row.type : 'Interne' }}
                    </span>
                    <span
                        v-else-if="props.row.type && editType !== props.row.id"
                        :class="!props.row.launched_at ? 'is-editable' : ''"
                        @click="editType = props.row.id"
                    >
                        {{ props.row.type === 'whitepage' || 'redactor' || 'textbulker' ? props.row.type : 'Interne' }}
                    </span>

                    <div v-else-if="!props.row.type || editType === props.row.id">
                        <select
                            v-model="props.row.type"
                            class="form-control form-control-sm"
                            @keydown.esc="editType = null"
                            @change="
                                editType = null
                                updatePreOrder(props.row)
                            "
                        >
                            <option :value="null">-</option>
                            <option value="whitepage">Whitepage</option>
                            <option value="textbulker">TextBulker</option>
                            <option value="redactor">Redactor</option>
                            <!-- <option value="internal">Interne</option> -->
                        </select>
                    </div>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="props.column.label"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="group_id"
                sortable
                :label="columns.group_id.name"
                :visible="columns.group_id.visible"
            >
                <div slot="default" slot-scope="props">
                    <span
                        v-if="props.row.group_id && editGroup !== props.row.id"
                        class="is-editable"
                        @click="editGroup = props.row.id"
                    >
                        {{ findGroup(props.row.group_id) }}
                    </span>

                    <div v-else-if="!props.row.group_id || editGroup === props.row.id">
                        <select
                            v-model="props.row.group_id"
                            class="form-control form-control-sm"
                            @keydown.esc="editGroup = null"
                            @change="
                                () => {
                                    editGroup = null
                                    updatePreOrder(props.row)
                                }
                            "
                        >
                            <option :value="null">-</option>
                            <option v-for="group in groups" :key="group.id" :value="group.id">
                                {{ group.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </b-table-column>

            <b-table-column field="keyword" sortable :searchable="isSearchable" label="Mot clé">
                <div slot="default" slot-scope="props">
                    <a role="button" @click="goToSearch(props.row.keyword)">
                        {{ props.row.keyword }}
                    </a>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="props.column.label"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column field="tag_title" :searchable="isSearchable" sortable label="H1">
                <div slot="default" slot-scope="props">
                    <input
                        v-if="!props.row.launched_at"
                        v-model="props.row.tag_title"
                        type="text"
                        class="form-control form-control-sm"
                        size="80"
                        @change="updatePreOrder(props.row)"
                    />
                    <span v-else>{{ props.row.tag_title }}</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="props.column.label"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column field="meta_title" sortable :searchable="isSearchable" label="Meta Title">
                <div slot="default" slot-scope="props">
                    <input
                        v-if="!props.row.launched_at"
                        v-model="props.row.meta_title"
                        type="text"
                        class="form-control form-control-sm"
                        size="80"
                        @change="updatePreOrder(props.row)"
                    />
                    <span v-else>{{ props.row.meta_title }}</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="props.column.label"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column field="site_id" sortable :searchable="isSearchable" label="Où" centered>
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.site_id && props.row.launched_at">
                        {{ props.row.site.ndd }}
                    </span>

                    <button
                        v-else-if="!props.row.site_id"
                        class="btn btn-sm btn-light"
                        @click="openSitesModal(props.row)"
                    >
                        <i class="fas fa-plus-circle text-muted" />
                    </button>

                    <button v-else class="btn btn-sm btn-light" @click="openSitesModal(props.row)">
                        {{ props.row.site.ndd }}
                    </button>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="props.column.label"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column field="url" label="URL" :visible="columns.url.visible">
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.url">{{ props.row.url }}</span>
                    <span v-else>-</span>
                </div>
            </b-table-column>

            <b-table-column field="url" sortable label="URL">
                <div slot="default" slot-scope="props">
                    <button v-if="props.row.url" class="btn btn-sm btn-light" @click="copylink(props.row)">
                        <i class="fa fa-link" aria-hidden="true" />
                    </button>
                    <span v-else>-</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="props.column.label"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="position_volume"
                sortable
                :label="columns.position_volume.name"
                :visible="columns.position_volume.visible"
            >
                <div slot="default" slot-scope="props">
                    <span
                        v-if="props.row.monitorank_volume"
                        :class="[
                            props.row.monitorank_position > 1 &&
                            props.row.monitorank_position < 90 &&
                            !props.row.isurlAbove45days
                                ? 'text-success'
                                : 'text-danger'
                        ]"
                        >{{ props.row.monitorank_position }}/{{ props.row.monitorank_volume
                        }}<button
                            v-if="props.row.url"
                            class="btn btn-sm btn-light ml-1"
                            @click="copyKeyword(props.row)"
                        >
                            <i class="far fa-copy" aria-hidden="true" /></button
                    ></span>
                    <span v-else>-</span>
                </div>
            </b-table-column>

            <b-table-column
                field="nl_results"
                sortable
                :label="columns.nl_results.name"
                :visible="columns.nl_results.visible"
            >
                <div slot="default" slot-scope="props">
                    <span class="is-hidden">{{ props.row.id }}</span>
                    <span v-if="props.row.nl_results" class="text-success">Oui</span>
                    <span v-else class="text-danger">Non</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="props.column.label"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column field="brief" sortable :label="columns.brief.name" :visible="columns.brief.visible">
                <div slot="default" slot-scope="props">
                    <b-tooltip
                        v-if="props.row.brief"
                        :label="props.row.brief"
                        :triggers="['click']"
                        :auto-close="['outside', 'escape']"
                        multilined
                        position="is-bottom"
                        size="is-large"
                    >
                        <b-button label="Voir" type="is-light" size="is-small" />
                    </b-tooltip>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="props.column.label"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="posts_count"
                searchable
                sortable
                :label="columns.posts_count.name"
                :visible="columns.posts_count.visible"
            >
                <div slot="default" slot-scope="props">
                    <span>{{ props.row.posts_count }}</span>
                </div>
                <div slot="searchable" slot-scope="props">
                    <div class="w-100 mx-auto">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="number"
                            class="form-control form-control-sm"
                            :placeholder="props.column.label"
                        />
                    </div>
                </div>
            </b-table-column>

            <!--<b-table-column
  field="partner_availability"
  sortable
  label="Dispo Partenaire"
>
  <div slot-scope="props" slot="default">
    <b-switch
      v-model="props.row.partner_availability"
      type="is-success"
      @input="
        bulkSetPartnerAvailability(
          [props.row],
          props.row.partner_availability
        )
      "
    >
      {{ props.row.partner_availability ? "Oui" : "Non" }}
    </b-switch>
  </div>
</b-table-column>-->
        </b-table>

        <nl-modal
            title="Sélectionner un site"
            :open-modal="selectSiteModal"
            size="xxl"
            @hidden="selectSiteModal = false"
        >
            <AvailableSitesByUser
                :groups="groups"
                :selected_preorders="selectedPreOrder"
                @closeModal="selectSiteModal = false"
                @siteSelected="siteSelected"
            />
        </nl-modal>

        <nl-modal title="Confirmation" :open-modal="confirmModal" @hidden="confirmModal = false">
            <div v-if="checkedRows.length && bulkActionType === 'setPartner'" class="text-center mb-2">
                <p>
                    Les précommandes séléctionnées seront
                    <strong>{{ partnerIsAvailable ? 'disponible' : 'non-disponible' }}</strong>
                    pour les partenaires.
                </p>

                <b-switch v-model="partnerIsAvailable" type="is-success" :true-value="true" :false-value="false">
                    {{ partnerIsAvailable ? 'Oui' : 'Non' }}
                </b-switch>
            </div>

            <div v-if="checkedRows.length && bulkActionType === 'launchPreOrders'" class="text-center mb-2">
                <p>Vous confirmer vouloir lancer les précommandes séléctionnées ?</p>
            </div>

            <div v-if="checkedRows.length && bulkActionType === 'bulkDuplicate'" class="text-center mb-2">
                <p>Vous confirmer vouloir dupliquer les précommandes séléctionnées ?</p>
            </div>
            <div v-if="checkedRows.length && bulkActionType === 'setWhitePage'" class="text-center mb-2">
                <p>Vous confirmer vouloir ajouter en whitepage les précommandes séléctionnées ?</p>
            </div>
            <div v-if="checkedRows.length && bulkActionType === 'setAI'" class="text-center mb-2">
                <p>Vous confirmer vouloir ajouter dans Text Bulker les précommandes séléctionnées ?</p>
            </div>
            <div v-if="checkedRows.length && bulkActionType === 'setRedactor'" class="text-center mb-2">
                <p>Vous confirmer vouloir ajouter en redactor les précommandes séléctionnées ?</p>
            </div>
            <div v-if="checkedRows.length && bulkActionType === 'bulkDelete'" class="text-center mb-2">
                <p>Vous confirmer vouloir supprimer les précommandes séléctionnées ?</p>
            </div>

            <div class="d-flex justify-content-end">
                <button class="btn btn-light mr-2" @click="confirmModal = false">Annuler</button>
                <button
                    v-if="bulkActionType === 'setPartner'"
                    class="btn btn-warning"
                    @click="bulkSetPartnerAvailability(checkedRows)"
                >
                    Confirmer
                </button>
                <button
                    v-if="bulkActionType === 'launchPreOrders'"
                    class="btn btn-warning"
                    @click="launchPreOrders(checkedRows)"
                >
                    Lancer
                </button>
                <button
                    v-if="bulkActionType === 'setWhitePage'"
                    class="btn btn-warning"
                    @click="setWhitePagePreOrders(checkedRows)"
                >
                    Confirmer
                </button>
                <button v-if="bulkActionType === 'setAI'" class="btn btn-warning" @click="setAIPreOrders(checkedRows)">
                    Confirmer
                </button>
                <button
                    v-if="bulkActionType === 'setRedactor'"
                    class="btn btn-warning"
                    @click="setRedactorPreOrders(checkedRows)"
                >
                    Confirmer
                </button>
                <button
                    v-if="bulkActionType === 'bulkDuplicate'"
                    class="btn btn-warning"
                    @click="duplicatePreOrders(checkedRows)"
                >
                    Dupliquer
                </button>
                <button
                    v-if="bulkActionType === 'bulkDelete'"
                    class="btn btn-danger"
                    @click="deletePreOrders(checkedRows)"
                >
                    Supprimer
                </button>
            </div>
        </nl-modal>
    </div>
</template>

<script>
    import AvailableSitesByUser from '@/components/Sites/AvailableSitesByUser.vue'

    export default {
        components: { AvailableSitesByUser },
        props: {
            preOrdersList: {
                type: Object,
                required: true
            },

            sites: {
                type: Array,
                required: true
            },

            groups: {
                type: Array,
                required: true
            },

            isFullPage: {
                type: Boolean,
                required: false,
                default: false
            },

            campaignPreOrdersSites: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                preorders: {},
                campaignPreOrdersSitesFiltered: {},
                checkedRows: [],
                editType: null,
                editGroup: null,
                isSearchable: true,
                selectSiteModal: false,
                confirmModal: false,
                bulkActionType: null,
                partnerIsAvailable: false,
                selectedPreOrder: null,
                sitesSelected: [],

                perPage: 20,
                sortField: 'id',
                sortOrder: 'desc',
                filters: '',
                page: 1,
                tableLoading: false,

                columns: {
                    date: {
                        visible: false,
                        name: 'Date'
                    },
                    brief: {
                        visible: false,
                        name: 'Brief'
                    },
                    url: {
                        visible: false,
                        name: 'URL Complète'
                    },
                    posts_count: {
                        visible: false,
                        name: 'Nb articles'
                    },
                    group_id: {
                        visible: false,
                        name: 'Thématique'
                    },
                    indexer: {
                        visible: true,
                        name: 'Indexés'
                    },
                    position_volume: {
                        visible: true,
                        name: 'Position VOL (24h)'
                    },
                    nl_results: {
                        visible: true,
                        name: 'Résultats NL'
                    }
                }
            }
        },

        watch: {
            preOrdersList: {
                deep: false,
                handler(newValue) {
                    this.preorders = newValue
                    this.setSitesSelected()
                }
            },

            campaignPreOrdersSites(newValue) {
                this.campaignPreOrdersSitesFiltered = newValue
            }
        },

        methods: {
            updatePreOrder(preOrder) {
                this.$emit('updatePreOrder', preOrder)
            },

            bulkSetPartnerAvailability(listIds, isAvailable) {
                if (isAvailable !== undefined) {
                    this.partnerIsAvailable = isAvailable
                }

                this.$emit('bulkSetPartnerAvailability', {
                    ids: listIds.map(row => row.id),
                    partner_availability: this.partnerIsAvailable
                })

                this.partnerIsAvailable = false

                this.cleanUp()
            },

            launchPreOrders(preOrders) {
                this.$emit('launchPreOrders', preOrders)

                this.cleanUp()
            },

            deletePreOrders(preOrders) {
                this.$emit('deletePreOrders', preOrders)

                this.cleanUp()
            },

            setWhitePagePreOrders(preOrders) {
                this.$emit('setWhitePagePreOrders', preOrders)

                this.cleanUp()
            },

            setAIPreOrders(preOrders) {
                this.$emit('setAIPreOrders', preOrders)

                this.cleanUp()
            },

            setRedactorPreOrders(preOrders) {
                this.$emit('setRedactorPreOrders', preOrders)

                this.cleanUp()
            },

            duplicatePreOrders(preOrders) {
                this.$emit('duplicatePreOrders', preOrders)

                this.cleanUp()
            },

            findGroup(groupId) {
                if (!groupId) {
                    return '-'
                }

                let group = this.groups.find(group => group.id === groupId)

                return group.name
            },

            siteSelected(site) {
                if (this.selectedPreOrder.site_id) {
                    this.campaignPreOrdersSitesFiltered.not_launched =
                        this.campaignPreOrdersSitesFiltered.not_launched.filter(
                            siteId => siteId !== this.selectedPreOrder.site_id
                        )
                }

                this.selectedPreOrder.site_id = site.id
                this.selectedPreOrder.site = site
                this.setSitesSelected(site.id)

                this.updatePreOrder(this.selectedPreOrder)
            },

            setSitesSelected(siteId) {
                this.sitesSelected = this.preorders.data
                    .filter(preorder => preorder.site_id)
                    .map(preorder => preorder.site_id)

                if (siteId) {
                    this.campaignPreOrdersSitesFiltered.not_launched.push(siteId)
                }
            },
            copylink(row) {
                var $temp = $('<input>')
                $('body').append($temp)
                $temp.val(row.url).select()
                document.execCommand('copy')

                $temp.remove()
            },
            copyKeyword(row) {
                var $temp = $('<input>')
                $('body').append($temp)
                $temp
                    .val(row.keyword + ',' + row.url + ',' + row.monitorank_position + ',' + row.monitorank_volume)
                    .select()
                document.execCommand('copy')
            },
            openSitesModal(row) {
                this.selectedPreOrder = row

                if (this.isFullPage) {
                    this.tableLoading = true
                    var self = this

                    this.$emit('getSelectedSites', {
                        preorderId: row.campaign_id,
                        callback: () => {
                            self.selectSiteModal = true
                            self.tableLoading = false
                        }
                    })
                } else {
                    this.selectSiteModal = true
                }
            },

            openConfirmModal() {
                this.confirmModal = true
            },

            cleanUp() {
                this.confirmModal = false
                this.checkedRows = []
            },

            /** Table filtering, sorting and pagination methods */
            loadAsyncData() {
                this.tableLoading = true
                var self = this

                this.$emit('loadAsyncData', {
                    page: this.page,
                    sortField: this.sortField,
                    sortOrder: this.sortOrder,
                    filters: this.filters,
                    includes: 'user',
                    path: this.preorders.path,
                    index: this.indexType,
                    per_page: this.perPage,

                    callback() {
                        self.tableLoading = false
                    }
                })
            },

            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order

                this.loadAsyncData()
            },

            onFilterChange(filter) {
                var filters = []
                Object.keys(filter).forEach(element => {
                    var currentFilter = {
                        key: element,
                        value: filter[element]
                    }
                    filters.push(currentFilter)
                })
                this.filters = filters
                this.loadAsyncData()
            },

            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },

            goToSearch(keyword) {
                const route = this.$router.resolve({
                    name: 'SearchByKeywords',
                    query: { keyword }
                })
                window.open(route.href, '_blank')
            }
        }
    }
</script>

<style>
    .sold_row {
        background-color: #d7fbd7 !important;
    }

    .nl_results_row {
        background-color: #efd78f !important;
    }
</style>