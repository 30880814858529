<template>
    <b-modal v-model="showModal" :width="960" :on-cancel="hide">
        <template>
            <header class="px-4 py-3 has-background-warning shadow-sm">
                <h4 class="h4 p-0 mb-0">Retirer mes gains</h4>
            </header>
            <div class="card-content">
                <div class="content">
                    <form>
                        <div class="modal-body">
                            <b-field
                                :label="$t('partenaire-gains.amounttowithdraw')"
                                :type="isAmountBelowMin ? 'is-danger' : ''"
                            >
                                <b-numberinput
                                    required
                                    icon="euro-sign"
                                    v-model="amount"
                                    :max="maxWithdrawal"
                                    :min-step="0.01"
                                    :autocomplete="'off'"
                                    :controls="false"
                                />
                                <span v-if="isAmountBelowMin" class="has-text-danger is-size-7">
                                    Cette valeur doit être supérieure ou égale à
                                    {{ minWithdrawal }}
                                </span>
                            </b-field>
                            <hr class="my-4" />
                            <b-field label="IBAN">
                                <b-input v-model="withdrawGains.iban" required />
                            </b-field>
                            <b-field label="BIC">
                                <b-input v-model="withdrawGains.bic" required />
                            </b-field>
                            <b-field :label="$t('user.payment.bankname')">
                                <b-input v-model="withdrawGains.bankName" required />
                            </b-field>
                            <b-checkbox required v-model="withdrawGains.saveDefaultPayment" type="is-dark">
                                {{ $t('user.payment.save') }}
                            </b-checkbox>
                            <hr class="my-4" />
                            <b-field :label="$t('partenaire-gains.preferedinvoicing')">
                                <b-select v-model="withdrawGains.invoiceChoice">
                                    <option value="generation">
                                        {{ $t('partenaire-gains.generate') }}
                                    </option>
                                    <option value="envoi">
                                        {{ $t('partenaire-gains.mail') }}
                                    </option>
                                </b-select>
                            </b-field>
                            <div v-if="withdrawGains.invoiceChoice === 'generation'">
                                <div class="row mb-3">
                                    <div class="col-sm-6">
                                        <b-field :label="$t('user.firstname')">
                                            <b-input v-model="withdrawGains.firstname" required />
                                        </b-field>
                                    </div>
                                    <div class="col-sm-6">
                                        <b-field :label="$t('user.lastname')">
                                            <b-input v-model="withdrawGains.lastname" required />
                                        </b-field>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-6">
                                        <b-field :label="$t('user.company')">
                                            <b-input v-model="withdrawGains.company" required />
                                        </b-field>
                                    </div>
                                    <div class="col-sm-6" v-if="!withdrawGains.noSiret">
                                        <b-field label="SIRET">
                                            <b-input v-model="withdrawGains.siret" required />
                                        </b-field>
                                    </div>
                                </div>
                                <b-checkbox required type="is-dark" v-model="withdrawGains.noSiret">
                                    {{ $t('user.nosiretnumber') }}
                                </b-checkbox>
                                <b-field :label="$t('user.address')">
                                    <b-input v-model="withdrawGains.address" required />
                                </b-field>
                                <div class="row mb-3">
                                    <div class="col-sm-6">
                                        <b-field :label="$t('user.postal-code')">
                                            <b-input v-model="withdrawGains.postalCode" required />
                                        </b-field>
                                    </div>
                                    <div class="col-sm-6">
                                        <b-field :label="$t('user.city')">
                                            <b-input v-model="withdrawGains.city" required />
                                        </b-field>
                                    </div>
                                </div>
                                <b-field v-if="!withdrawGains.noTva" :label="$t('user.TVAnumber')">
                                    <b-input v-model="withdrawGains.tvaNumber" required />
                                </b-field>
                                <b-checkbox required type="is-dark" v-model="withdrawGains.noTva">
                                    {{ $t('user.noTVA') }}
                                </b-checkbox>
                                <b-field :label="$t('partenaire-gains.invoicenumber')">
                                    <b-input v-model="withdrawGains.invoiceNumber" required />
                                </b-field>
                            </div>
                            <div id="envoi" v-else>
                                <p>{{ $t('partenaire-gains.invoicemail') }}.</p>
                            </div>
                            <hr class="my-4" />
                            <b-field>
                                <b-checkbox v-model="withdrawGains.cgv" required type="is-dark">
                                    {{ $t('partenaire-gains.exact') }}
                                    <a role="button" @click.prevent="toggleGTCModal()" class="underline">
                                        {{ $t('partenaire-gains.cgv') }}
                                    </a>
                                </b-checkbox>
                            </b-field>
                            <b-field>
                                <b-checkbox v-model="withdrawGains.engagement" type="is-dark" required>
                                    {{ $t('partenaire-gains.honor') }}
                                </b-checkbox>
                            </b-field>
                            <b-field>
                                <b-checkbox
                                    v-if="withdrawGains.noSiret"
                                    v-model="withdrawGains.noSiret"
                                    required
                                    type="is-dark"
                                >
                                    {{ $t('partenaire-gains.honorgain') }}
                                </b-checkbox>
                            </b-field>
                        </div>
                        <div class="modal-footer">
                            <b-button
                                @click="hide"
                                type="is-danger"
                                :disabled="clickButton"
                                :label="$t('global.cancel')"
                            />
                            <b-button
                                type="is-warning"
                                @click.prevent="doWithdrawGain"
                                :disabled="
                                    clickButton ||
                                    !withdrawGains.cgv ||
                                    !withdrawGains.engagement ||
                                    amount > maxWithdrawal ||
                                    amount < minWithdrawal ||
                                    (withdrawGains.invoiceChoice === 'generation' && !withdrawGains.invoiceNumber)
                                "
                            >
                                <i v-if="clickButton" class="fas fa-circle-notch fa-spin mr-1"></i>
                                {{ $t('global.validate') }}
                            </b-button>
                        </div>
                    </form>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import { mapMutations, mapState } from 'vuex'

    export default {
        name: 'TransfertCreditsModal',

        props: {
            maxWithdrawal: {
                type: Number,
                default: 0
            },
            minWithdrawal: {
                type: Number,
                default: 0
            }
        },

        data() {
            return {
                showModal: false,
                clickButton: false,
                amount: 0
            }
        },
        computed: {
            ...mapState('partner_sites', {
                withdrawGains: state => state.withdrawGains,
                user: state => state.user
            }),
            ...mapState('auth', {
                benefits: state => state.user?.gains
            }),
            isAmountBelowMin() {
                return this.amount < this.minWithdrawal
            }
        },
        methods: {
            ...mapMutations('auth', { setBenefits: 'setBenefits' }),
            show() {
                this.amount = this.maxWithdrawal
                this.showModal = true
            },
            hide() {
                this.$emit('close')
                this.showModal = false
            },
            doWithdrawGain() {
                this.clickButton = true

                var data = {
                    paymentType: 'virement',
                    invoiceChoice: this.withdrawGains.invoiceChoice,
                    amount: this.amount,

                    saveDefaultPayment: this.withdrawGains.saveDefaultPayment,

                    info_paiement: this.withdrawGains.iban,

                    iban: this.withdrawGains.iban,
                    bic: this.withdrawGains.bic,
                    bankName: this.withdrawGains.bankName
                }

                if (this.withdrawGains.invoiceChoice === 'generation') {
                    data.invoice_number = this.withdrawGains.invoiceNumber
                    data.noSiret = this.withdrawGains.noSiret
                    data.noTva = this.withdrawGains.noTva

                    data.nom = this.withdrawGains.lastname
                    data.prenom = this.withdrawGains.firstname
                    data.entreprise = this.withdrawGains.company

                    data.adresse = this.withdrawGains.address
                    data.cp = this.withdrawGains.postalCode
                    data.ville = this.withdrawGains.city

                    if (!this.withdrawGains.noSiret) {
                        data.siret = this.withdrawGains.siret
                        data.noSiret = false
                    }

                    if (!this.withdrawGains.noTva) {
                        data.numero_tva = this.withdrawGains.tvaNumber
                        data.noTva = false
                    }
                }

                this.axios
                    .post('/partner-gain/withdrawal', data)
                    .then(({ data }) => {
                        this.setBenefits(data.benefits)
                        this.hide()
                    })
                    .catch(error => {
                        this.$store.dispatch('toastError', error.response)
                    })
                    .finally(() => {
                        this.clickButton = false
                    })
            }
        }
    }
</script>

<style scoped></style>