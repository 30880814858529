<template>
    <span>
        <a v-if="!isBtn" href="#" :class="$attrs.class" @click.prevent="doLogin">
            <slot />
            <i class="fas fa-sign-in-alt" />
        </a>
        <b-button v-if="isBtn" :class="$attrs.class" :type="type" :expanded="expanded" @click.prevent="doLogin">
            <slot />
        </b-button>
    </span>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'LoginAs',
        props: {
            userId: {
                required: true,
                type: Number
            },
            managerId: {
                required: false,
                type: Number
            },
            isBtn: {
                required: false,
                type: Boolean,
                default: false
            },
            showUrl: {
                required: false,
                type: Boolean,
                default: true
            },
            type: {
                required: false,
                type: String,
                default: 'is-info'
            },
            expanded: {
                required: false,
                type: Boolean,
                default: false
            },
            redirectTo: {
                required: false,
                type: String,
                default: null
            }
        },
        methods: {
            ...mapActions('auth', ['loginAs']),
            doLogin() {
                this.loginAs({
                    user_id: this.userId,
                    showUrl: this.showUrl,
                    url: this.$route.fullPath
                }).then(() => {
                    if (this.$route.path === this.$store.state.homepage) {
                        this.$router.go()
                    } else if (this.redirectTo !== null) {
                        this.$router.push(this.redirectTo)
                    } else {
                        this.$router.push(this.$store.state.homepage)
                    }
                })
            }
        }
    }
</script>

<style scoped></style>
