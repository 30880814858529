<template>
    <div class="box flex flex-column align-items-center">
        <div v-if="promos" class="flex-column">
            <h3>{{ $t('next-level.nodiscount') }}.</h3>
            {{ $t('next-level.recommandation') }}: - {{ $t('next-level.recommandation_02') }} -
            {{ $t('next-level.recommandation_03') }} .
        </div>
        <div v-else class="d-flex flex-column align-items-center justify-content-center">
            <h3>{{ $t('next-level.noarticle') }}</h3>
            <h3>{{ $t('next-level.noworries') }}</h3>
            <p>{{ $t('next-level.twopossibility') }}</p>
            <div class="d-flex justify-content-center flex-wrap" style="max-width: 900px; gap: 1rem">
                <div class="d-flex align-items-center flex-column" style="flex: 1 1 300px">
                    <p class="text-center">{{ $t('next-level.preorder') }}.</p>
                    <b-button
                        type="is-dark"
                        :label="$t('next-level.preorderfree')"
                        @click="$router.push({ name: 'PreorderNew', params: { keyword } })"
                    />
                </div>
                <div class="d-flex flex-column align-items-center" style="flex: 1 1 300px">
                    <p class="text-center">
                        {{ $t('next-level.newpartners') }}
                        <strong>{{ keyword }}</strong>
                    </p>
                    <b-button type="is-warning" :label="$t('alertes.setalert')" @click="showModal = true" />
                </div>
            </div>
        </div>
        <add-alert :active.sync="showModal" :value="modalData" />
    </div>
</template>

<script>
    import AddAlert from '../Alerte/AddAlert.vue'

    export default {
        name: 'Card',
        components: {
            AddAlert
        },
        props: {
            keyword: {
                type: String,
                default: ''
            },
            email: {
                type: String,
                default: ''
            },
            lang: {
                type: String,
                default: 'fr_fr'
            },
            promos: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                showModal: false,
                modalData: {
                    keyword: this.keyword,
                    lang: this.lang,
                    recipient: this.email,
                    volume: 0,
                    price: 0,
                    top10: 0,
                    top100: 0
                }
            }
        }
    }
</script>
