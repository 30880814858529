<template>
    <nl-modal :open-modal="openModal" title="Ajouter un utilisateur" @hidden="close">
        <template>
            <nl-alert ref="alert" />
            <v-form ref="addUserForm">
                <div class="form-group">
                    <text-field v-model="model.email" label="Email" rules="required" name="Email" />
                </div>
                <div class="form-group">
                    <text-field
                        v-model="model.password"
                        type="password"
                        label="Mot de passe"
                        rules="required"
                        name="Mot de passe"
                    />
                </div>
                <div class="form-group">
                    <text-field v-model="model.firstname" label="Prénom" rules="required" name="Prénom" />
                </div>
                <div class="form-group">
                    <text-field v-model="model.lastname" label="Nom" rules="required" name="Nom" />
                </div>
                <div class="form-group">
                    <nl-select v-model="model.role" :items="roles" name="Role" label="Role" rules="required" />
                </div>
                <div class="form-group">
                    <text-field
                        v-model="model.credit_amount"
                        type="number"
                        name="Crédits"
                        label="Crédits"
                        rules="required"
                    />
                </div>
            </v-form>
        </template>
        <template #footer>
            <button class="btn btn-primary" @click="save">
                {{ $t('global.save') }}
            </button>
            <button class="btn btn-danger" @click="close">
                {{ $t('global.cancel') }}
            </button>
        </template>
    </nl-modal>
</template>

<script>
    import { mapActions } from 'vuex'
    import { EventManager } from '@/components/EventManager'
    import NlAlert from '../../UI/NlAlert.vue'

    export default {
        components: {
            EventManager,
            NlAlert
        },
        data: () => ({
            roles: [
                { text: 'admin', id: 'admin' },
                { text: 'prospect', id: 'prospect' },
                { text: 'partenaire', id: 'partenaire' },
                { text: 'administratif', id: 'administratif' }
            ],
            openModal: false,
            model: {}
        }),
        created() {
            EventManager.$on('onAddUser', this.init)
        },
        methods: {
            ...mapActions('users', { addUser: 'create' }),
            init() {
                this.openModal = true
            },
            close() {
                this.model = Object.assign({})
                this.$refs.addUserForm.reset()
                this.openModal = false
                this.$refs.alert.clean()
            },
            save() {
                this.$refs.alert.clean()
                this.$refs.addUserForm.validate().then(success => {
                    if (success) {
                        this.addUser(this.model)
                            .then(data => {
                                this.$emit('saved', data)
                                this.close()
                            })
                            .catch(response => {
                                if (response.status == 422) {
                                    this.$refs.alert.showValidationError(response.data)
                                } else {
                                    this.$refs.alert.displayMsg('danger', response)
                                }
                            })
                    }
                })
            }
        }
    }
</script>

<style></style>
