var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item dropdown m-0"},[_c('a',{staticClass:"nav-link dropdown-toggle p-0",attrs:{"href":"#","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[(_vm.$store.state.auth.fromAdmin)?_c('i',{staticClass:"far fas fa-user-check mr-1",staticStyle:{"font-size":"1.5rem","color":"#2b383f"}}):_c('i',{staticClass:"far fa-user mr-1",staticStyle:{"font-size":"1.5rem","color":"#2b383f"}}),_c('span',{staticStyle:{"color":"#2b383f","font-weight":"bold","font-size":"1rem"}},[_vm._v(_vm._s(_vm.user.firstname))])]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/profile"}},[_c('b-icon',{attrs:{"icon":"user"}}),_vm._v(" "+_vm._s(_vm.$t('layout.profil'))+" ")],1),_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/invoices"}},[_c('b-icon',{attrs:{"icon":"file-invoice"}}),_vm._v(" "+_vm._s(_vm.$t('factures.title'))+" ")],1),(
                ['prospect', 'agence', 'partenaire', 'consultant', 'admin'].includes(_vm.user.role) &&
                (_vm.isAdminMenuOpen || !_vm.isPartnerMenuOpen)
            )?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/credits"}},[_c('b-icon',{attrs:{"icon":"euro-sign"}}),_vm._v(" "+_vm._s(_vm.$t('mes-credits.title'))+" ")],1):_vm._e(),(
                ['prospect', 'agence', 'partenaire', 'consultant', 'admin'].includes(_vm.user.role) &&
                (_vm.isAdminMenuOpen || _vm.isPartnerMenuOpen)
            )?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/partner/earnings"}},[_c('b-icon',{attrs:{"icon":"piggy-bank"}}),_vm._v(" "+_vm._s(_vm.$t('global.earnings'))+" ")],1):_vm._e(),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('b-icon',{attrs:{"icon":"sign-out-alt"}}),_vm._v(" "+_vm._s(_vm.$t('global.disconnect'))+" ")],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }