<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 v-if="desactivateData.active" class="h4 p-0 mb-0">
                        {{ $t('partenaire-site.desactivationwebsites') }}
                    </h4>
                    <h4 v-if="deleteData.active" class="h4 p-0 mb-0">
                        {{ $t('partenaire-site.deletesites') }}
                    </h4>
                    <h4 v-if="reactivateData.active" class="h4 p-0 mb-0">
                        {{ $t('partenaire-site.reactivationwebsites') }}
                    </h4>
                    <h4 v-else class="h4 p-0 mb-0">
                        {{ $t('global.modify') }}
                    </h4>
                </header>
                <div class="card-content">
                    <div class="content">
                        <form>
                            <section v-if="desactivateData.active" class="row mb-3">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="label" for="choice">{{ $t('partenaire-site.choice') }}</label>
                                        <select
                                            id="choice"
                                            v-model="desactivateData.choice"
                                            name="choice"
                                            class="form-control"
                                            autocomplete="off"
                                        >
                                            <option value="1">
                                                {{ $t('partenaire-site.desactivationtemporary') }}
                                            </option>
                                            <option value="2">
                                                {{ $t('partenaire-site.desactivationwithoutdate') }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div v-if="showDesactivateDate" class="col-md-6">
                                    <div class="form-group">
                                        <label class="label" for="scheduled_at">{{
                                            $t('partenaire-site.startdate')
                                        }}</label>
                                        <input
                                            id="scheduled_at"
                                            v-model="desactivateData.scheduled_at"
                                            type="date"
                                            class="form-control"
                                            required
                                        />
                                    </div>
                                </div>

                                <div v-if="showDesactivateDate" class="col-md-6">
                                    <div class="form-group">
                                        <label class="label" for="desactivation_site_to">{{
                                            $t('partenaire-site.enddate')
                                        }}</label>
                                        <input
                                            id="desactivation_site_to"
                                            v-model="desactivateData.desactivation_site_to"
                                            type="date"
                                            class="form-control"
                                            required
                                        />
                                    </div>
                                </div>
                            </section>

                            <section v-if="deleteData.active" class="row mb-3">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="label" for="choice_delete">{{
                                            $t('partenaire-site.choice')
                                        }}</label>
                                        <select
                                            id="deleteData"
                                            v-model="deleteData.choice"
                                            name="deleteData"
                                            class="form-control"
                                            autocomplete="off"
                                        >
                                            <option :value="$t('partenaire-site.dontownthesite')">
                                                {{ $t('partenaire-site.dontownthesite') }}
                                            </option>
                                            <option :value="$t('partenaire-site.disapointed')">
                                                {{ $t('partenaire-site.disapointed') }}
                                            </option>
                                            <option :value="$t('partenaire-site.dontapprouve')">
                                                {{ $t('partenaire-site.dontapprouve') }}
                                            </option>
                                            <option :value="$t('partenaire-site.toomuchsales')">
                                                {{ $t('partenaire-site.toomuchsales') }}
                                            </option>
                                            <option value="5">
                                                {{ $t('partenaire-site.others') }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div v-if="deleteData.choice == 5" class="col-12">
                                    <b-field :label="$t('partenaire-site.motif')">
                                        <b-input v-model="deleteData.notes" maxlength="200" type="textarea" />
                                    </b-field>
                                </div>
                            </section>

                            <section v-if="reactivateData.active" class="mb-5">
                                <p class="h4 text-center">
                                    {{ $t('partenaire-site.reactivationwebsites-text') }}
                                </p>
                            </section>

                            <section v-if="redactionData.active" class="mb-4">
                                <div class="form-group">
                                    <label class="label" for="redaction">{{ $t('global.redaction') }}</label>
                                    <select
                                        id="redaction"
                                        v-model="redactionData.redaction"
                                        name="redaction"
                                        class="form-control"
                                        autocomplete="off"
                                    >
                                        <option value="nextlevel">
                                            {{ $t('partenaire-site.redactionnextlevel') }}
                                        </option>
                                        <option value="proprietaire">
                                            {{ $t('partenaire-site.redactionmyself') }}
                                        </option>
                                    </select>
                                </div>
                            </section>

                            <section v-if="maxWordsData.active" class="mb-4">
                                <div class="form-group">
                                    <label class="label" for="contenu_redige_max">
                                        {{ $t('partenaire-site.maxcontent') }}
                                        <i
                                            class="fas fa-info-circle"
                                            data-toggle="tooltip"
                                            data-html="true"
                                            :title="$t('partenaire-site.addcontent')"
                                        />
                                    </label>
                                    <select
                                        id="contenu_redige_max"
                                        v-model="maxWordsData.contenu_redige_max"
                                        name="contenu_redige_max"
                                        class="form-control"
                                        autocomplete="off"
                                    >
                                        <option value="200">200 {{ $t('global.words') }}</option>
                                        <option value="300">300 {{ $t('global.words') }}</option>
                                        <option value="400">400 {{ $t('global.words') }}</option>
                                        <option value="500">500 {{ $t('global.words') }}</option>
                                        <option value="600">600 {{ $t('global.words') }}</option>
                                        <option value="700" selected>
                                            700 {{ $t('partenaire-site.wordsrecommandation') }}
                                        </option>
                                    </select>
                                </div>
                            </section>

                            <section v-if="acceptAutoData.active" class="mb-4 text-center">
                                <div class="form-group">
                                    <label class="label" for="accept_auto_orders">
                                        {{ $t('partenaire-site.accept-auto-orders') }}
                                        <i class="fas fa-info-circle" :title="$t('partenaire-site.accept-auto-info')" />
                                    </label>
                                    <b-switch v-model="acceptAutoData.accept_auto_orders" type="is-success">
                                        {{ acceptAutoData.accept_auto_orders ? $t('global.yes') : $t('global.no') }}
                                    </b-switch>
                                </div>
                            </section>

                            <section v-if="acceptAutoPreorderData.active" class="mb-4 text-center">
                                <div class="form-group">
                                    <label class="label" for="accept_auto_orders">
                                        {{ $t('partenaire-site.accept-auto-preorders') }}
                                        <i
                                            class="fas fa-info-circle"
                                            :title="$t('partenaire-site.accept-auto-info-preorder')"
                                        />
                                    </label>
                                    <b-switch v-model="acceptAutoPreorderData.accept_preorders_auto" type="is-success">
                                        {{
                                            acceptAutoPreorderData.accept_preorders_auto
                                                ? $t('global.yes')
                                                : $t('global.no')
                                        }}
                                    </b-switch>
                                </div>
                            </section>

                            <b-field v-if="desactivateData.active || deleteData.active">
                                <b-checkbox v-model="acceptCGV">
                                    {{ $t('partenaire-site.engagementmonths') }}
                                    <a href="#" class="form-check-label" @click.prevent="toggleGTCModal()">{{
                                        $t('partenaire-site.cgv')
                                    }}</a>
                                    {{ $t('partenaire-site.soldlinks') }}
                                </b-checkbox>
                            </b-field>

                            <div class="buttons justify-content-end">
                                <button class="button is-light" @click.prevent="closeAndResetModal">
                                    {{ $t('global.cancel') }}
                                </button>
                                <button
                                    class="button is-warning"
                                    :disabled="
                                        !acceptCGV &&
                                        !reactivateData.active &&
                                        !maxWordsData.active &&
                                        !redactionData.active &&
                                        !acceptAutoData.active &&
                                        !acceptAutoPreorderData.active
                                    "
                                    @click.prevent="validate"
                                >
                                    {{ $t('global.validate') }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex'

    export default {
        name: 'PartnerSiteConfirmModal',
        props: ['checkedRows'],
        data() {
            return {
                modalIsOpen: false,
                acceptCGV: false,
                showDesactivateDate: true,
                currentType: null,

                desactivateData: {
                    active: false,
                    choice: 1,
                    scheduled_at: null,
                    desactivation_site_to: null
                },
                deleteData: {
                    active: false,
                    choice: this.$t('partenaire-site.dontownthesite'),
                    notes: ''
                },
                reactivateData: {
                    active: false
                },
                redactionData: {
                    active: false,
                    redaction: 'nextlevel'
                },
                maxWordsData: {
                    active: false,
                    contenu_redige_max: '700'
                },
                acceptAutoData: {
                    active: false,
                    accept_auto_orders: false
                },
                acceptAutoPreorderData: {
                    active: false,
                    accept_preorders_auto: false
                }
            }
        },

        watch: {
            'desactivateData.choice': function () {
                if (this.desactivateData.choice == 2) {
                    this.showDesactivateDate = false
                } else {
                    this.showDesactivateDate = true
                }
            }
        },

        created() {},

        methods: {
            ...mapMutations(['toggleGTCModal']),
            validate() {
                let listIds = this.checkedRows.map(site => site.id)

                switch (this.currentType) {
                    case 'desactivate':
                        this.$parent.$emit('desactivateBulkSites', {
                            ids: listIds,
                            scheduled_at: this.desactivateData.scheduled_at,
                            desactivation_site_to: this.desactivateData.desactivation_site_to,
                            choice_type: this.desactivateData.choice
                        })
                        break
                    case 'reactivate':
                        this.$parent.$emit('reactivateBulkSites', {
                            ids: listIds
                        })
                        break
                    case 'delete':
                        this.$parent.$emit('deleteBulkSites', {
                            ids: listIds,
                            notes: this.deleteData.notes.length > 0 ? this.deleteData.notes : this.deleteData.choice
                        })
                        break
                    case 'redaction':
                        this.$parent.$emit('redactionBulkSites', {
                            ids: listIds,
                            redaction: this.redactionData.redaction
                        })
                        break
                    case 'contenu_redige_max':
                        this.$parent.$emit('maxWordsBulkSites', {
                            ids: listIds,
                            contenu_redige_max: this.maxWordsData.contenu_redige_max
                        })
                        break
                    case 'accept_auto_orders':
                        this.$parent.$emit('setAcceptAutoBulkSites', {
                            ids: listIds,
                            accept_auto_orders: this.acceptAutoData.accept_auto_orders
                        })
                        break
                    case 'accept_auto_preorders':
                        this.$parent.$emit('setAcceptAutoPreorderBulkSites', {
                            ids: listIds,
                            accept_preorders_auto: this.acceptAutoPreorderData.accept_preorders_auto
                        })
                        break

                    default:
                        break
                }

                this.$emit('uncheckRows')
                this.closeAndResetModal()
            },

            openModal(type) {
                this.modalIsOpen = true

                switch (type) {
                    case 'desactivate':
                        this.desactivateData.active = true
                        this.currentType = 'desactivate'
                        break
                    case 'reactivate':
                        this.reactivateData.active = true
                        this.currentType = 'reactivate'
                        break
                    case 'delete':
                        this.deleteData.active = true
                        this.currentType = 'delete'
                        break
                    case 'redaction':
                        this.redactionData.active = true
                        this.currentType = 'redaction'
                        break
                    case 'contenu_redige_max':
                        this.maxWordsData.active = true
                        this.currentType = 'contenu_redige_max'
                        break
                    case 'accept_auto_orders':
                        this.acceptAutoData.active = true
                        this.currentType = 'accept_auto_orders'
                        break
                    case 'accept_auto_preorders':
                        this.acceptAutoPreorderData.active = true
                        this.currentType = 'accept_auto_preorders'
                        break

                    default:
                        break
                }
            },

            closeAndResetModal() {
                this.modalIsOpen = false
                this.currentType = null

                this.desactivateData = {
                    active: false,
                    choice: 1,
                    scheduled_at: null,
                    desactivation_site_to: null
                }
                this.deleteData = {
                    active: false,
                    choice: this.$t('partenaire-site.dontownthesite'),
                    notes: ''
                }
                this.reactivateData = {
                    active: false
                }
                this.redactionData = {
                    active: false,
                    redaction: 'nextlevel'
                }
                this.maxWordsData = {
                    active: false,
                    contenu_redige_max: '700'
                }
                this.acceptAutoData = {
                    active: false,
                    accept_auto_orders: false
                }
            }
        }
    }
</script>

<style>
    label[for^='scrape_'] {
        color: #363636;
        display: block;
        font-size: 1rem;
        font-weight: 700;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
