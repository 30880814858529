<template>
    <div class="row px-xl-5 mb-xl-5">
        <div class="p-2 col-md-12">
            <div class="card">
                <div class="card-header row px-4 py-3 text-light" style="">
                    <div class="col-md-8">
                        <h3 class="mb-0">
                            {{ $t('profil.account') }}
                        </h3>
                    </div>
                </div>
                <div class="card-body px-4 py-0">
                    <v-form @submit="save">
                        <div class="row mb-xl-0">
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-5">
                                        <v-textfield
                                            v-model="profile.lastname"
                                            class-label="form-control-label-profil"
                                            :label="$t('user.lastname') + '*'"
                                            :name="$t('user.lastname')"
                                            rules="required"
                                        />
                                    </div>
                                    <div class="col-md-5">
                                        <v-textfield
                                            v-model="profile.firstname"
                                            class-label="form-control-label-profil"
                                            :label="$t('user.firstname') + '*'"
                                            rules="required"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-lg-10">
                                        <v-textfield
                                            v-model="profile.email"
                                            class-label="form-control-label-profil"
                                            disabled
                                            :label="$t('user.email') + '*'"
                                        />
                                        <label
                                        >Merci de contacter le support NextLevel afin de changer votre adresse
                                            email</label
                                        >
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-lg-10">
                                        <v-textfield
                                            v-model="profile.internet_bs_email"
                                            class-label="form-control-label-profil"
                                            :label="$t('user.internet_bs_email')"
                                        />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col col-md-12 col-lg-5">
                                        <label class="form-control-label-profil" for="profil-language">{{
                                                $t('profil.interface-language')
                                            }}</label>
                                        <select id="profil-language" v-model="profile.locale" class="form-control">
                                            <option v-for="item in langues" :key="item.id" :value="item.id">
                                                {{ item.text }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="row mb-4">
                                    <div class="col">
                                        <v-textfield
                                            v-model="profile.skype"
                                            class-label="form-control-label-profil"
                                            name="Skype"
                                            label="Skype"
                                        >
                                            <template #prepend>
                                                <i class="fab fa-skype"/>
                                            </template>
                                        </v-textfield>
                                    </div>
                                </div>
                                <div class="row mb-xl-0 pt-2">
                                    <div class="col">
                                        <label for="phone" class="form-control-label-profil">{{
                                                $t('user.phone')
                                            }}</label>
                                        <vue-tel-input
                                            v-model="userPhone"
                                            style-classes="w-full py-1"
                                            :default-country="country[$i18n.locale]"
                                            :input-options="options"
                                            @validate="setValid"
                                        />

                                        <div v-show="validated && (!phone || !phone.valid)" class="errorPhone">
                                            <span v-if="userPhone.length <= 0">{{
                                                    $t('register.error.mandatory')
                                                }}</span>
                                            <span v-else>{{ $t('register.error.phoneFormat') }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="profile.api_token !== null && profile.api_token !== ''" class="row mt-4">
                                    <div class="col">
                                        <v-textfield
                                            v-model="profile.api_token"
                                            disabled
                                            class-label="form-control-label-profil"
                                            name="Skype"
                                            :label="$t('user.api_key')"
                                        />
                                    </div>
                                </div>
                                <div
                                    v-if="profile.extension_token !== null && profile.extension_token !== ''"
                                    class="row mt-4"
                                >
                                    <div class="col">
                                        <v-textfield
                                            v-model="profile.extension_token"
                                            disabled
                                            class-label="form-control-label-profil"
                                            name="Skype"
                                            :label="$t('user.api_key')"
                                        />
                                    </div>
                                </div>
                                <a class="text-primary" href="/api/doc">{{ $t('profil.api-documentation') }}</a>
                            </div>

                            <div v-if="['partenaire', 'admin'].includes(profile.role)" class="col">
                                <div
                                    class="custom-control custom-control-alternative custom-checkbox mt-1 checkbox-lg mb-3"
                                >
                                    <input
                                        id="alertSms"
                                        v-model="profile.accept_sms"
                                        class="custom-control-input"
                                        type="checkbox"
                                    />
                                    <label class="custom-control-label" for="alertSms">
                                        <span class="form-control-label-profil">{{ $t('register.alertSMS') }}</span>
                                    </label>
                                </div>

                                <div
                                    class="custom-control custom-control-alternative custom-checkbox mt-1 checkbox-lg mb-3"
                                >
                                    <input
                                        id="displayUrl"
                                        v-model="profile.partner_display_urls"
                                        class="custom-control-input"
                                        type="checkbox"
                                    />
                                    <label class="custom-control-label" for="displayUrl">
                                        <span class="form-control-label-profil">Afficher les urls de mes sites</span>
                                    </label>
                                </div>
                            </div>

                        </div>

                        <div class="row pb-0 mb-4">
                            <div class="col-auto mr-auto">&nbsp;</div>
                            <div class="col-auto">
                                <div class="row mb-0">
                                    <button class="btn btn-secondary mr-2" :disabled="loading">
                                        {{ $t('global.save') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <b-loading v-model="loading" :is-full-page="false"/>
                    </v-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AbstractProfile from './AbstractProfile.vue'

export default {
    name: 'AccountProfile',
    mixins: [AbstractProfile],
    data: function () {
        return {
            userPhone: '',
            country: {
                fr: 'FR',
                en: 'GB'
            },
            options: {
                placeholder: this.$t('user.phone'),
                id: 'phone'
            },
            validated: false,
            phone: null,
            telephoneMessage: ''
        }
    },
    watch: {
        profile: function () {
            if (this.profile.telephone) {
                this.userPhone = this.profile.telephone
            }
        }
    },
    created() {
        if (this.profile.telephone) {
            this.userPhone = this.profile.telephone
        }
    },
    methods: {
        save: function () {
            var isValid = true
            this.validated = true

            if (this.phone && this.phone.valid && this.phone.valid === true) {
                this.telephoneMessage = ''
            } else {
                isValid = false
            }

            if (isValid) {
                this.profile.telephone = this.phone.number
                this.loading = true

                this.$emit('save', {
                    callback: () => {
                        this.loading = false
                    }
                })
            }
        },
        setValid: function (phoneObject) {
            if (phoneObject && phoneObject.valid === true) {
                this.phone = phoneObject
            } else {
                this.phone = null
            }
        }
    }
}
</script>
