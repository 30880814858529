<template>
    <modal v-model="activeModal" modal-title="Ajouter des mots-clés" @cancel="cancelModal">
        <template #default>
            <div class="d-flex" style="gap: 10px">
                <div class="w-100">
                    <p class="font-weight-bold">Ajout des MC à travailler</p>
                    <div v-for="(i, index) in kw" :key="i.id">
                        <input :id="'kc_' + index" v-model="checkKW" class="mr-2" type="checkbox" :value="i.mot_cle" />
                        <label :for="'kc_' + index">{{ i.mot_cle }}</label>
                    </div>
                </div>
                <div class="w-100">
                    <p class="font-weight-bold fs-5">Ajout manuel</p>
                    <textarea
                        v-model="customKW"
                        class="form-control"
                        placeholder="Séparer les mots-clés par une virgule"
                    />
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <b-button type="is-warning" @click="startWork"> Valider</b-button>
        </template>
    </modal>
</template>

<script>
    import { mapState } from 'vuex'
    import Modal from '@/components/UI/Modal/Modal.vue'

    export default {
        name: 'CampaignPreOrdersKWModal',
        components: {
            Modal
        },
        props: {
            activeModal: {
                type: Boolean,
                required: true
            },
            settings: {
                type: Object,
                required: false
            }
        },
        data() {
            return {
                kw: null,
                serpKW: null,
                customKW: null,
                isLoading: false,
                isLoadingSerp: false,
                checkKW: [],
                checkKWS: []
            }
        },
        async created() {
            this.loadKW()
        },
        computed: {
            ...mapState('campaigns', ['keywords']),
            computeKWList() {
                let list = []
                this.checkKW.forEach(e => {
                    list.push(e)
                })
                if (this.customKW) {
                    let customKWList = this.customKW.split(/[\n,]/)
                    customKWList.forEach(e => {
                        list.push(e)
                    })
                }

                return list
            }
        },
        methods: {
            closeModal() {
                this.resetData()
                this.$emit('closeModal')
            },
            loadKW() {
                this.isLoading = true
                this.axios
                    .get(`/campaigns/url/` + this.$route.query.id)
                    .then(response => {
                        this.kw = response.data.data
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            startWork() {
                let keywords = []
                this.computeKWList.forEach(element => {
                    let keywordTemp
                    keywordTemp = {
                        keyword: element,
                        posts_count: this.settings.articles_count
                    }
                    keywords.push(keywordTemp)
                })
                let params = {
                    words_count: this.settings.brief_count,
                    keywords: keywords,
                    lang: this.settings.lang,
                    brief: this.settings.brief
                }
                this.isLoading = true
                this.axios
                    .post(`/campaigns/` + this.$route.query.id + `/preorders`, params)
                    .then(response => console.log(response))
                    .catch(error => console.log(error))
                    .finally(() => {
                        this.isLoading = false
                        this.$emit('fetchData')
                        this.closeModal()
                    })
            },
            resetData() {
                this.customKW = null
                this.checkKW = []
            },
            cancelModal() {
                this.resetData()
                this.$emit('closeModal')
            }
        }
    }
</script>

<style></style>
