<template>
    <div class="d-flex align-items-center w-100 justify-content-around" style="gap: 20px; font-size: 50px">
        <i
            class="far fa-smile text-success"
            :style="{ opacity: selected == 'smile' ? 1 : 0.3 }"
            role="button"
            @click="$emit('clickSmile', 'smile')"
        />
        <i
            class="far fa-meh text-warning"
            :style="{ opacity: selected == 'meh' ? 1 : 0.3 }"
            role="button"
            @click="$emit('clickSmile', 'meh')"
        />
        <i
            class="far fa-frown text-danger"
            :style="{ opacity: selected == 'frown' ? 1 : 0.3 }"
            role="button"
            @click="$emit('clickSmile', 'frown')"
        />
    </div>
</template>

<script>
    export default {
        name: 'SmileySelection',
        props: {
            selected: ''
        }
    }
</script>
