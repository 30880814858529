<template>
    <section class="position-relative">
        <b-loading v-model="isLoading" :is-full-page="false" />
        <div class="d-flex flex-column align-items-center">
            <div class="d-md-flex">
                <div class="mx-md-5 w-md-30">
                    <div class="form-inline align-items-baseline">
                        <label class="label mr-2">Mettre le nombre d'articles à</label>
                        <input
                            v-model="settingsData.articles_count"
                            type="number"
                            class="form-control form-control-sm w-25"
                        />
                    </div>
                    <div class="form-group">
                        <label class="label">Brief par défaut</label>
                        <textarea
                            v-model="settingsData.brief"
                            class="form-control"
                            cols="30"
                            rows="5"
                            :placeholder="config"
                        />
                        <small class="text-muted">
                            En indiquant <strong>###</strong> dans le brief, il sera remplacé par le mot clé
                            correspondant.
                        </small>
                    </div>
                </div>
                <div>
                    <div class="form-group">
                        <label class="label">Nombre de mots par défaut</label>
                        <input
                            v-model="settingsData.brief_count"
                            type="number"
                            class="form-control"
                            placeholder="Exemple: 300"
                        />
                    </div>

                    <div class="form-group">
                        <label>Langue par défaut</label>
                        <b-field>
                            <b-radio-button v-model="settingsData.lang" native-value="fr" type="is-warning">
                                <flag iso="fr" class="mr-2 rounded" />
                                <span>Français</span>
                            </b-radio-button>
                            <b-radio-button v-model="settingsData.lang" native-value="en" type="is-warning">
                                <flag iso="us" class="mr-2 rounded" />
                                <span>Anglais</span>
                            </b-radio-button>
                        </b-field>
                    </div>
                </div>
            </div>

            <hr class="my-4" />

            <button class="button is-warning" @click="updateSettings(settings)">Enregistrer les paramètres</button>
        </div>
    </section>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'CampaignsSettingsModal',
        props: ['settings'],
        data() {
            return {
                isLoading: false,
                settingsData: {
                    lang: 'fr',
                    brief_count: 0,
                    articles_count: 0,
                    brief: ''
                },
                config: ''
            }
        },
        watch: {
            settings: {
                handler(newValue) {
                    this.settingsData = newValue
                },
                deep: true
            }
        },
        created() {
            this.getConfigByName({ name: 'brief_preorders' })
                .then(config => (this.config = config))
                .catch(e => console.error(e))
            this.settingsData = this.settings
        },
        methods: {
            ...mapActions('config', ['getConfigByName']),
            closeModal() {
                this.$emit('closeModal')
            },
            updateSettings() {
                this.isLoading = true
                var self = this
                this.$emit('updateSettings', {
                    settings: this.settingsData,
                    callback: () => {
                        self.isLoading = false
                        this.closeModal()
                    }
                })
            }
        }
    }
</script>

<style></style>
