<template>
    <div>
        <div class="row">
            <div v-if="user" class="col-4">
                <StatCard :color="user.gains >= 200 ? 'success' : 'danger'" :icon="'fas fa-euro-sign'">
                    <template v-slot:counter>
                        <h3>{{ (user.gains ? parseInt(user.gains * 100) / 100 : 0) | toCurrency }}</h3>
                    </template>
                    <template v-slot:text>
                        <span>Gains</span>
                    </template>
                </StatCard>
                <b-field label="Gain en Euros">
                    <b-input v-model="amount" type="number" step="0.01" required />
                </b-field>
                <b-field label="Motif">
                    <b-input v-model="log" type="text" required />
                </b-field>
                <b-field>
                    <b-switch
                        v-model="user.withdrawal_limit"
                        true-value="1"
                        false-value="0"
                        type="is-success"
                        @input="changeLimitWithdraw"
                    >
                        Retrait des gains à partir de 200$
                    </b-switch>
                </b-field>
                <b-button type="is-primary" @click="save"> Modifier les gains</b-button>
            </div>

            <b-table
                class="col-8 pt-0"
                :data="earningLog"
                :loading="loading"
                paginated
                :striped="true"
                :per-page="10"
                default-sort="created_at"
                default-sort-direction="desc"
            >
                <b-table-column searchable field="created_at" class="pt-0" sortable label="Date">
                    <template v-slot="props">
                        {{ moment(props.row[props.column.field]).format('L LT') }}
                    </template>
                    <div slot="searchable" slot-scope="props">
                        <b-input v-model="props.filters[props.column.field]" type="date" size="is-small" />
                    </div>
                </b-table-column>
                <b-table-column searchable field="log" sortable label="Log">
                    <template v-slot="props">
                        <span v-html="props.row[props.column.field]" />
                    </template>
                    <div slot="searchable" slot-scope="props">
                        <b-input v-model="props.filters[props.column.field]" type="text" size="is-small" />
                    </div>
                </b-table-column>
                <b-table-column searchable field="montant" sortable label="Montant">
                    <template v-slot="props">
                        {{ props.row[props.column.field] | toCurrency }}
                    </template>
                    <div slot="searchable" slot-scope="props">
                        <b-input v-model="props.filters[props.column.field]" type="number" size="is-small" />
                    </div>
                </b-table-column>
            </b-table>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PartnerDetailEarningTable',
        props: {
            tabData: {}
        },
        data: () => ({
            openModal: false,
            user: {},
            earningLog: [],
            amount: null,
            log: null,
            loading: true
        }),
        watch: {
            tabData: {
                handler(newValue) {
                    this.earningLog = newValue.content
                    this.user = newValue.user
                    this.loading = false
                },
                deep: true
            }
        },
        methods: {
            close() {
                this.amount = null
                this.log = null
            },
            save() {
                this.loading = true
                this.axios
                    .put(`/admin/users/${this.user.id}/earning`, {
                        id: this.user.id,
                        amount: this.amount,
                        log: this.log
                    })
                    .then(response => {
                        this.user.gains = parseFloat(this.user.gains, 10) + parseFloat(this.amount, 10)
                        this.$emit('reloadData', this.tabData.index)
                        this.close()
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
            },
            changeLimitWithdraw() {
                this.axios.put(`/admin/users/${this.user.id}`, this.user)
            }
        }
    }
</script>
