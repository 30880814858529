<template>
    <nl-modal :open-modal="openModal" title="Ajouter un Filleul" @hidden="close">
        <template v-if="user">
            <nl-alert ref="alert" />
            <v-form ref="addFilleulForm">
                <div class="form-group">
                    <text-field v-model="filleulEmail" label="Email" rules="required" name="Email" />
                </div>
                <div class="form-group">
                    <nl-select
                        v-model="creditPast"
                        :items="creditPasts"
                        label="Crédit Antérieur"
                        rules="required"
                        name="Crédit antérieur"
                    />
                </div>
                <div class="form-group">
                    <button class="btn btn-primary" @click="check">Vérifier avant validation</button>
                </div>
            </v-form>
        </template>
        <template #footer>
            <button class="btn btn-primary" @click="save">
                {{ $t('global.save') }}
            </button>
            <button class="btn btn-danger" @click="close">
                {{ $t('global.cancel') }}
            </button>
        </template>
    </nl-modal>
</template>

<script>
    import { mapActions } from 'vuex'
    import { EventManager } from '@/components/EventManager'
    import NlAlert from '../../UI/NlAlert.vue'

    export default {
        components: {
            EventManager,
            NlAlert
        },
        data: () => ({
            creditPasts: [
                { text: 'Rétroactif', id: 1 },
                { text: 'Non-Rétroactif', id: 0 }
            ],
            openModal: false,
            creditPast: null,
            filleulEmail: null,
            user: null
        }),
        created() {
            EventManager.$on('onAddFilleul', this.init)
        },
        methods: {
            ...mapActions('users', {
                updateUser: 'update',
                addFilleul: 'addFilleul',
                checkAddFilleul: 'checkAddFilleul'
            }),
            init(data) {
                this.user = Object.assign({}, data)
                this.openModal = true
            },
            close() {
                this.user = Object.assign({})
                this.creditPast = null
                this.filleulEmail = null
                this.$refs.addFilleulForm.reset()
                this.openModal = false
                this.$refs.alert.clean()
            },
            save() {
                this.$refs.alert.clean()
                this.$refs.addFilleulForm.validate().then(success => {
                    if (success) {
                        this.addFilleul({
                            id: this.user.id,
                            email: this.filleulEmail,
                            credit_past: this.creditPast
                        })
                            .then(data => {
                                this.$emit('saved', data)
                            })
                            .catch(response => {
                                if (response.status == 422) {
                                    this.$refs.alert.showValidationError(response.data)
                                } else {
                                    this.$refs.alert.displayMsg('danger', response)
                                }
                            })
                    }
                })
            },
            check() {
                this.$refs.alert.clean()
                this.$refs.addFilleulForm.validate().then(success => {
                    if (success) {
                        this.checkAddFilleul({
                            id: this.user.id,
                            email: this.filleulEmail,
                            credit_past: this.creditPast
                        })
                            .then(({ data }) => {
                                this.$refs.alert.displayMsg('success', data.message)
                            })
                            .catch(response => {
                                if (response.status == 422) {
                                    this.$refs.alert.showValidationError(response.data)
                                } else {
                                    this.$refs.alert.displayMsg('danger', response)
                                }
                            })
                    }
                })
            }
        }
    }
</script>

<style></style>
