<template>
    <div v-if="editable" class="editable">
        <div v-if="choices.length" class="mr-2">
            <nl-select v-model="model" :items="choices" />
        </div>
        <div v-else class="mr-2">
            <text-field v-model="model" />
        </div>
        <div class="btnHolder">
            <button class="btn btn-sm mr-1 btn-success" @click="save">
                <i class="fas fa-check-circle" />
            </button>
            <button class="btn btn-sm mr-1 btn-danger" @click="cancel">
                <i class="fas fa-times-circle" />
            </button>
        </div>
    </div>
    <div v-else class="value" @click="edit">
        {{ displayValue ? displayValue : model }}
        <i class="far fa-edit ml-1 mt-1" />
    </div>
</template>

<script>
    export default {
        props: {
            choices: {
                type: Array,
                default: function () {
                    return []
                }
            },
            displayValue: {
                type: String,
                default: ''
            },
            value: {
                type: [String, Number, null],
                default: ''
            }
        },
        data: () => ({
            editable: false,
            defaultValue: null
        }),
        computed: {
            model: {
                set(value) {
                    this.$emit('input', value)
                },
                get() {
                    return this.value
                }
            }
        },
        methods: {
            edit() {
                this.defaultValue = this.model
                this.editable = true
            },
            cancel() {
                this.model = this.defaultValue
                this.defaultValue = null
                this.editable = false
            },
            save() {
                if (this.defaultValue != this.model) {
                    this.$emit('change')
                }
                this.defaultValue = null
                this.editable = false
            }
        }
    }
</script>

<style lang="scss">
    .value {
        display: inline-block;

        i {
            display: none;
            position: absolute;
        }

        &:hover {
            border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
            cursor: pointer;

            i {
                display: inline;
            }
        }
    }

    .editable {
        display: flex;

        .btnHolder {
            display: flex;
        }
    }
</style>
