<template>
    <div v-if="!loading">
        <slide-x-right-transition mode="out-in" :duration="800">
            <template v-if="projects && projects.length > 0">
                <div class="b-autocomplete">
                    <b-autocomplete
                        ref="projectSelector"
                        v-model="projectName"
                        rounded
                        type="is-warning"
                        icon="folder"
                        :placeholder="$t('global.project')"
                        icon-right="angle-down"
                        open-on-focus
                        expanded
                        keep-first
                        select-on-click-outside
                        clear-on-select
                        :custom-formatter="formatter"
                        :data="filteredProjects"
                        @click.native="projectName = ''"
                        @focus="projectName = ''"
                        @select="selectProject"
                    />
                </div>
            </template>
        </slide-x-right-transition>

        <template v-if="!projects || projects.length <= 0">
            <div class="row">
                <div class="col" />
                <div class="mr-auto">
                    <div class="custom-loader" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex'

    export default {
        name: 'Projects',
        props: {
            allowAll: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                projectName: '',
                loading: true
            }
        },
        computed: {
            ...mapState({
                project: 'project'
            }),
            ...mapState('project_selector', {
                projects: 'projects'
            }),
            filteredProjects() {
                //sort projects list by name
                let projects = [...this.projects].sort((a, b) => {
                    if (a.name < b.name) {
                        return -1
                    }
                    if (a.name > b.name) {
                        return 1
                    }
                    return 0
                })
                if (this.allowAll) {
                    projects = [
                        {
                            id: -1,
                            name: 'Tous'
                        },
                        ...projects
                    ]
                }
                return projects.filter(project => {
                    return (
                        project.name.toString().toLowerCase().indexOf(this.projectName.toLowerCase()) >= 0 &&
                        (project.status || this.allowAll)
                    )
                })
            }
        },
        methods: {
            ...mapMutations({
                setProject: 'setProject'
            }),
            ...mapActions('project_selector', {
                getProjects: 'projects',
                getProject: 'getDefaultProject'
            }),
            update(id) {
                this.setProject(
                    this.projects.find(e => e.id === id) || {
                        id: -1,
                        name: 'all',
                        status: 1
                    }
                )
            },
            formatter(project) {
                const maxChars = 30
                const pricePaid = (project ? project.price_paid_this_month : 0) || 0
                const budget = (project ? project.budget : 0) || 0
                if (project) {
                    if (project.name === 'non-classe') {
                        return `${this.$t('projets.notsorted')} (${pricePaid}€ / ${budget}€)`
                    } else if (project.name === 'Tous') {
                        return project.name
                    } else if (project.name) {
                        const projectName =
                            project.name.length >= maxChars ? project.name.substring(0, maxChars) + '...' : project.name
                        return `${projectName} (${pricePaid}€ / ${budget}€)`
                    }
                }
                return 'err: no name'
            },
            selectProject(option) {
                if (option && option.id) {
                    this.setProject(option)
                    this.projectName = this.formatter(option)
                } else {
                    this.projectName = this.formatter(this.project)
                }
            }
        },
        watch: {
            project() {
                this.projectName = this.formatter(this.project)
            },
            allowAll(value) {
                if (!value && this.project.id === -1) {
                    this.getProject()
                }
            }
        },
        mounted() {
            const projectsPromise = this.getProjects()
            const projectPromise = this.getProject()

            Promise.all([projectsPromise, projectPromise]).then(() => {
                if (!this.project && this.projects.length >= 0) {
                    this.$store.commit('setProject', this.projects[0])
                }
                this.projectName = this.formatter(this.project)

                this.loading = false
            })
        }
    }
</script>

<style scoped>
    /deep/ .dropdown-menu,
    /deep/ .dropdown-content {
        padding: 0 !important;
        border: none;
    }

    /deep/ .b-autocomplete .input {
        border-color: #f9bc14;
    }
</style>
