<template>
    <div>
        <div v-show="checkedRows.length > 0" class="mt-4 mb-4">
            <div v-if="actions.includes('project')">
                <form class="form-inline">
                    <label v-if="checkedRows.length > 1" for="project">{{
                        $t('precommande.changeprojects', { length: checkedRows.length })
                    }}</label>
                    <label v-else for="project">{{
                        $t('precommande.changeproject', { length: checkedRows.length })
                    }}</label>
                    <select id="project" v-model="project" class="form-control ml-2 mr-2">
                        <option v-for="project in projects" :key="project.id" :value="project">
                            {{ project.name }}
                        </option>
                    </select>

                    <button class="btn btn-secondary" @click.prevent="changeProject">
                        {{ $t('precommande.validate') }}
                    </button>
                </form>
            </div>

            <div v-if="actions.includes('restart')">
                <button v-if="checkedRows.length > 1" class="btn btn-primary" @click.prevent="restartPreorder">
                    {{ $t('precommande.restartorders', { length: checkedRows.length }) }}
                </button>

                <button v-else class="btn btn-primary" @click.prevent="restartPreorder">
                    {{ $t('precommande.restartorder', { length: checkedRows.length }) }}
                </button>
            </div>

            <div v-if="actions.includes('cancel')">
                <button v-if="checkedRows.length > 1" class="btn btn-primary" @click.prevent="cancelPreorder">
                    {{ $t('precommande.cancelpreorders', { length: checkedRows.length }) }}
                </button>

                <button v-else class="btn btn-primary" @click.prevent="cancelPreorder">
                    {{ $t('precommande.cancelpreorder', { length: checkedRows.length }) }}
                </button>
            </div>
        </div>

        <b-table
            :sticky-header="true"
            :data="orders"
            :mobile-cards="hasMobileCards"
            :checked-rows.sync="checkedRows"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            default-sort="created_at"
            default-sort-direction="desc"
            checkable
            :striped="true"
            :debounce-search="200"
            :checkbox-position="checkboxPosition"
        >
            <b-table-column field="status" label="Status" sortable searchable width="10%">
                <div slot="default" slot-scope="props">
                    <Status :status="props.row.status" />
                </div>

                <div slot="searchable" slot-scope="props">
                    <select v-model="props.filters[props.column.field]" class="form-control">
                        <option :value="props.filters[status]">
                            {{ $t('precommande.all') }}
                        </option>
                        <option value="A faire">
                            {{ $t('precommande.status.todo') }}
                        </option>
                        <option value="En rédaction">
                            {{ $t('precommande.status.inprogress') }}
                        </option>
                        <option value="Attente positionnement">
                            {{ $t('precommande.status.awaiting') }}
                        </option>
                        <option value="Fait">
                            {{ $t('precommande.status.done') }}
                        </option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column
                field="locale"
                sortable
                searchable
                :label="$t('precommande.columns.locale')"
                class="searchableCol"
                width="10%"
                centered
            >
                <div slot="default" slot-scope="props">
                    <flag :iso="'en' === props.row.locale ? 'us' : 'fr'" />
                </div>

                <div slot="searchable" slot-scope="props">
                    <select v-model="props.filters[props.column.field]" class="form-control">
                        <option :value="props.filters[status]">
                            {{ $t('precommande.all') }}
                        </option>
                        <option value="fr">Français</option>
                        <option value="en">English</option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column
                field="keyword"
                sortable
                searchable
                :label="$t('precommande.columns.keyword')"
                class="searchableCol"
            >
                <div slot="default" slot-scope="props">
                    <a :href="appUrl + '/search/keyword?keyword_search=' + encodeURIComponent(props.row.keyword)">
                        {{ props.row.keyword }}
                    </a>
                </div>

                <div slot="searchable" slot-scope="props">
                    <div class="is-full w-100">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control"
                            :placeholder="$t('precommande.columns.keywordplaceholder')"
                        />
                    </div>
                </div>
            </b-table-column>

            <b-table-column
                field="secondaryKeywords"
                searchable
                :custom-search="searchSecondary"
                :label="$t('precommande.columns.secondarykeyword')"
                centered
            >
                <div slot="default" slot-scope="props">
                    <span
                        v-for="keyword in props.row.secondaryKeywords"
                        :key="keyword.name"
                        class="badge badge-secondary p-2 m-1"
                    >
                        <a :href="appUrl + '/search/keyword?keyword_search=' + encodeURIComponent(keyword.name)">
                            {{ keyword.name }}
                        </a>
                    </span>
                </div>

                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        class="form-control"
                        :placeholder="$t('precommande.columns.secondarykeywordplaceholder')"
                    />
                </div>
            </b-table-column>

            <b-table-column field="brief" :label="$t('precommande.columns.brief')" width="30%">
                <div slot="default" slot-scope="props">
                    {{ props.row.brief }}
                </div>
            </b-table-column>

            <b-table-column field="nbArticle" :label="$t('precommande.columns.nbArticle')" centered>
                <div slot="default" slot-scope="props">
                    {{ props.row.nbArticle }}
                </div>
            </b-table-column>

            <b-table-column field="project.id" sortable searchable :label="$t('precommande.columns.project')" centered>
                <div slot="default" slot-scope="props">
                    <span v-if="props.row.project">{{ props.row.project.name }}</span>
                    <span v-else>-</span>
                </div>

                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <select v-model="props.filters[props.column.field]" class="form-control">
                        <option :value="props.filters[status]">
                            {{ $t('precommande.all') }}
                        </option>
                        <option v-for="project in projects" :key="project.id" :value="project.id">
                            {{ project.name }}
                        </option>
                    </select>
                </div>
            </b-table-column>

            <b-table-column
                field="created_at"
                sortable
                searchable
                :custom-search="searchDate"
                :label="$t('precommande.columns.createdat')"
                centered
            >
                <div slot="default" slot-scope="props">
                    {{ moment(props.row.created_at).format('L') }}
                </div>

                <div slot="searchable" slot-scope="props" class="searchableCol">
                    <input
                        v-model="props.filters[props.column.field]"
                        type="text"
                        class="form-control"
                        :placeholder="$t('precommande.columns.createdatplaceholder')"
                    />
                </div>
            </b-table-column>

            <b-table-column
                v-slot="props"
                field="date_expiration"
                sortable
                :label="$t('precommande.columns.expires')"
                centered
            >
                <template v-if="props.row.date_expiration">
                    <span id="date_expiration">{{ moment(props.row.date_expiration).fromNow() }}</span>
                </template>
            </b-table-column>

            <template #bottom-left>
                <div v-show="checkedRows.length > 0">
                    <div v-if="actions.includes('project')">
                        <form class="form-inline">
                            <label v-if="checkedRows.length > 1" for="project">{{
                                $t('precommande.changeprojects', { length: checkedRows.length })
                            }}</label>
                            <label v-else for="project">{{
                                $t('precommande.changeproject', { length: checkedRows.length })
                            }}</label>
                            <select id="project" v-model="project" class="form-control ml-2 mr-2">
                                <option v-for="project in projects" :key="project.id" :value="project">
                                    {{ project.name }}
                                </option>
                            </select>

                            <button class="btn btn-secondary" @click.prevent="changeProject">
                                {{ $t('precommande.validate') }}
                            </button>
                        </form>
                    </div>

                    <div v-if="actions.includes('restart')">
                        <button v-if="checkedRows.length > 1" class="btn btn-primary" @click.prevent="restartPreorder">
                            {{ $t('precommande.restartorders', { length: checkedRows.length }) }}
                        </button>

                        <button v-else class="btn btn-primary" @click.prevent="restartPreorder">
                            {{ $t('precommande.restartorder', { length: checkedRows.length }) }}
                        </button>
                    </div>

                    <div v-if="actions.includes('cancel')">
                        <button v-if="checkedRows.length > 1" class="btn btn-primary" @click.prevent="cancelPreorder">
                            {{
                                $t('precommande.cancelpreorders', {
                                    length: checkedRows.length
                                })
                            }}
                        </button>

                        <button v-else class="btn btn-primary" @click.prevent="cancelPreorder">
                            {{ $t('precommande.cancelpreorder', { length: checkedRows.length }) }}
                        </button>
                    </div>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
    export default {
        name: 'TabOrders',
        props: ['ordersList', 'projects', 'actions'],
        data: function () {
            return {
                orders: [],
                checkboxPosition: 'left',
                hasMobileCards: true,
                checkedRows: [],
                isPaginated: true,
                defaultSortDirection: 'asc',
                currentPage: 1,
                perPage: 50,
                project: {},
                appUrl: process.env.LARAVEL_APP_URL,
                status: 'Tous'
            }
        },
        watch: {
            ordersList: function (newValue) {
                this.orders = newValue
                this.checkedRows = []
            }
        },
        created() {
            this.orders = this.ordersList && this.ordersList.length > 0 ? this.ordersList : []
            this.project_id = this.projects && this.projects.length > 0 ? this.projects[0].id : 0
        },
        mounted() {},
        methods: {
            searchSecondary: function (item, inputStr) {
                if (item.secondaryKeywords && item.secondaryKeywords.length > 0) {
                    for (var i = 0; i < item.secondaryKeywords.length; i++) {
                        if (item.secondaryKeywords[i].name.toLowerCase().includes(inputStr.toLowerCase())) {
                            return true
                        }
                    }
                }

                return false
            },
            searchDate: function (item, inputStr) {
                var itemDate = this.moment(item.created_at).startOf('day').format('L')

                if (itemDate.includes(inputStr)) {
                    return true
                }

                return false
            },
            cancelPreorder: function () {
                this.$emit('cancel', this.checkedRows)
            },
            changeProject: function () {
                this.$emit('project', {
                    preorders: this.checkedRows,
                    project: this.project
                })
            },
            restartPreorder: function () {
                this.$emit('restart', this.checkedRows)
            }
        }
    }
</script>
