<template>
    <div>
        <b-modal v-model="modalIsOpen" :width="960" :on-cancel="closeAndResetModal">
            <b-loading v-model="isLoading" :is-full-page="false" />
            <template>
                <header class="px-4 py-3 has-background-warning shadow-sm">
                    <h4 class="h4 p-0 mb-0">
                        {{ $t('wordpress_modal.title') }}
                    </h4>
                </header>
                <div class="card-content">
                    <div class="content">
                        <div class="form-group">
                            <label class="label" for="url">{{ $t('partenaire-site.mainurl') }}</label>
                            <b-input v-model="formData.url" :disabled="true" />
                        </div>

                        <div class="form-group w-100">
                            <b-field label="API Token">
                                <b-input
                                    id="token"
                                    v-model="formData.api_key"
                                    placeholder="Token"
                                    class="w-100"
                                    type="password"
                                    password-reveal
                                />
                                <p class="control">
                                    <b-button
                                        type="is-warning"
                                        :label="$t('wordpress_modal.check_button')"
                                        @click.prevent="checkApiKey"
                                    />
                                </p>
                            </b-field>
                            <b-notification v-if="errorApiKey" :closable="false" type="is-danger is-light" role="alert">
                                {{ errorApiKey }}
                            </b-notification>
                            <b-notification
                                v-if="apiKeyIsValid"
                                :closable="false"
                                type="is-success is-light"
                                role="alert"
                            >
                                <i class="far fa-check-circle mr-2" />
                                {{ $t('wordpress_modal.success') }}
                            </b-notification>
                            <p :class="formData.get_infos ? 'text-success' : 'text-danger'">
                                <i v-if="formData.get_infos" class="far fa-check-circle mr-2" /><i
                                    v-else
                                    class="far fa-times-circle mr-2"
                                />getInfos
                            </p>
                            <p :class="formData.get_authors ? 'text-success' : 'text-danger'">
                                <i v-if="formData.get_authors" class="far fa-check-circle mr-2" /><i
                                    v-else
                                    class="far fa-times-circle mr-2"
                                />getAuthors
                            </p>
                            <p :class="formData.get_categories ? 'text-success' : 'text-danger'">
                                <i v-if="formData.get_categories" class="far fa-check-circle mr-2" /><i
                                    v-else
                                    class="far fa-times-circle mr-2"
                                />getCategories
                            </p>
                            <p :class="formData.post_articles ? 'text-success' : 'text-danger'">
                                <i v-if="formData.post_articles" class="far fa-check-circle mr-2" /><i
                                    v-else
                                    class="far fa-times-circle mr-2"
                                />postArticles
                            </p>
                        </div>

                        <div class="buttons justify-content-end">
                            <button class="button is-warning" @click.prevent="save">
                                {{ $t('global.close') }}
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'PartnerSiteWPModal',
        props: ['data'],
        data() {
            return {
                modalIsOpen: false,
                editMode: false,

                formData: null,
                errorApiKey: '',
                isLoading: false,
                apiKeyIsValid: false,
                pluginsTests: {}
            }
        },

        watch: {
            formData(newValue) {
                this.formData = newValue
            }
        },

        created() {
            this.formData = this.data
        },

        methods: {
            save() {
                this.closeAndResetModal()
            },

            checkApiKey() {
                this.errorApiKey = ''
                this.apiKeyIsValid = false

                if (!this.formData.api_key.length || this.formData.api_key.length < 6) {
                    this.errorApiKey = 'Invalid API key'
                    return
                }

                this.isLoading = true

                this.axios
                    .post(`/partner-sites/${this.formData.id}/wordpress/check-plugin`, {
                        api_key: this.formData.api_key
                    })
                    .then(response => {
                        this.apiKeyIsValid = true
                        this.formData.get_infos = response.data.result.get_infos
                        this.formData.get_authors = response.data.result.get_authors
                        this.formData.get_categories = response.data.result.get_categories
                        this.formData.post_articles = response.data.result.post_articles
                    })
                    .catch(error => {
                        this.formData.get_infos = false
                        this.formData.get_authors = false
                        this.formData.get_categories = false
                        this.formData.post_articles = false
                        this.errorApiKey = error.response.data.message
                    })
                    .finally(() => (this.isLoading = false))
            },

            openModal(site) {
                this.modalIsOpen = true

                this.formData = site
            },

            closeAndResetModal() {
                this.modalIsOpen = false
                this.apiKeyIsValid = false
                this.errorApiKey = ''

                this.formData = {
                    url: '',
                    api_key: '',
                    id: ''
                }
            }
        }
    }
</script>

<style>
    label[for^='scrape_'] {
        color: #363636;
        display: block;
        font-size: 1rem;
        font-weight: 700;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
