export const ROLES = {
    ADMIN: 'admin',
    ADMINISTRATIVE: 'administratif',
    AGENCY: 'agence',
    CONSULTANT: 'consultant',
    MANAGER: 'manager',
    PARTNER: 'partenaire',
    PROSPECT: 'prospect',
    REDACTOR: 'redacteur'
}
