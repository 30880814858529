<template>
    <div>
        <NlTable
            :columns.sync="columns"
            :get-data="loadAsyncData"
            :force-update="forceUpdate"
            checkable
            :is-row-checkable="row => row.status === 'todo'"
            :left-buttons="buttons"
            :kpis="kpis"
            :checked-rows.sync="checkedRows"
        >
            <template #status="{ props: { row } }">
                <span v-if="row.status === 'todo'" class="tag is-info">À faire</span>
                <span
                    v-else-if="['unassigned', 'unpublished', 'in_progress', 'reviewed'].includes(row.status)"
                    class="tag is-warning"
                >
                    En cours
                </span>
                <span v-else-if="row.status === 'completed'" class="tag is-success">Fait</span>
            </template>

            <template
                #textbulker_statusSearch="{
                    props: {
                        filters,
                        column: { field }
                    }
                }"
            >
                <b-select size="is-small" v-model="filters[field]">
                    <option :value="null">Tous</option>
                    <option value="null-value">A faire</option>
                    <option value="0">En cours</option>
                    <option value="1">Généré</option>
                    <option value="2">Annulé</option>
                </b-select>
            </template>

            <template #textbulker_status="{ props: { row } }">
                <span v-if="row.textbulker_status === 0" class="tag is-warning">En cours</span>
                <span v-else-if="row.textbulker_status === 1" class="tag is-success">Généré</span>
                <span v-else-if="row.textbulker_status === 2" class="tag is-danger">Annulé</span>
                <span v-else> - </span>
            </template>

            <template #buyer="{ props: { row } }">
                {{ row.buyer.user }}
            </template>

            <template #meta_title="{ props: { row } }">
                <!--        <span v-if="row.meta_title !== null">{{ row.meta_title }}</span>-->
                <b-input
                    v-model="row.meta_title"
                    size="is-small"
                    @blur="updateOrder('meta', row.id, $event.target.value)"
                />
            </template>

            <template #tag_title="{ props: { row } }">
                <!--        <span v-if="row.tag_title !== null">{{ row.tag_title }}</span>-->
                <b-input
                    v-model="row.tag_title"
                    size="is-small"
                    @blur="updateOrder('tag', row.id, $event.target.value)"
                />
            </template>

            <template #spot="{ props: { row } }">
                {{ row.spot.url }}
            </template>

            <template #price_paid="{ props: { row } }"> {{ row.price_paid / 100 }} € </template>
        </NlTable>
        <BulkTitleModal ref="titleModal" @saveTitles="updateBulkTitles" />
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import NlTable from '@/components/UI/Table/NlTable'
    import BulkTitleModal from '@/components/Spots/BulkTitleModal.vue'

    export default {
        name: 'SpotsOrdersTable',
        components: {
            BulkTitleModal,
            NlTable
        },
        props: {
            type: {
                type: String,
                required: true
            },
            status: {
                type: String,
                required: false
            }
        },
        watch: {
            checkedRows(newVal) {
                if (this.checkedRows.length > 0) {
                    newVal.forEach(row => {
                        this.buttons[1].disabled = !(row.meta_title && row.tag_title)
                    })
                    this.buttons[0].disabled = false
                    this.buttons[2].disabled = false
                } else {
                    this.buttons[0].disabled = true
                    this.buttons[1].disabled = true
                    this.buttons[2].disabled = true
                }
            }
        },
        data() {
            return {
                groups: null,
                forceUpdate: false,
                checkedRows: [],
                buttons: [
                    {
                        label: 'Générer par IA',
                        icon: 'check',
                        type: 'is-warning',
                        action: this.generateContentFromAI,
                        disabled: true
                    },
                    {
                        label: 'Envoyer en rédaction',
                        icon: 'check',
                        type: 'is-warning',
                        action: this.redactor,
                        disabled: true
                    },
                    {
                        label: 'Modifier les titres',
                        icon: 'check',
                        type: 'is-warning',
                        action: this.bulkTitleUpdate,
                        disabled: true
                    },
                    {
                        label: '',
                        icon: 'sync-alt',
                        type: 'is-dark',
                        action: () => (this.forceUpdate = !this.forceUpdate)
                    }
                ],
                kpis: [
                    {
                        label: 'Crédits TextBulker',
                        value: 0
                    }
                ],
                columns: [
                    {
                        field: 'id',
                        searchable: true,
                        sortable: true,
                        label: 'ID'
                    },
                    {
                        field: 'status',
                        custom: true,
                        searchable: false,
                        sortable: false,
                        label: 'Statut'
                    },
                    {
                        field: 'textbulker_status',
                        custom: true,
                        searchable: true,
                        customSearch: true,
                        sortable: false,
                        label: 'Génération IA'
                    },
                    {
                        field: 'anchor',
                        searchable: false,
                        sortable: false,
                        label: 'Ancre'
                    },
                    {
                        field: 'meta_title',
                        custom: true,
                        searchable: false,
                        sortable: false,
                        label: 'Meta Title',
                        width: '200'
                    },
                    {
                        field: 'tag_title',
                        custom: true,
                        searchable: false,
                        sortable: false,
                        label: 'H1'
                    },
                    {
                        field: 'spot',
                        custom: true,
                        searchable: false,
                        sortable: false,
                        label: 'Root'
                    },
                    {
                        field: 'url_source',
                        searchable: true,
                        sortable: true,
                        label: 'URL'
                    },
                    {
                        field: 'url_target',
                        searchable: true,
                        sortable: true,
                        label: 'Page'
                    },
                    {
                        custom: true,
                        field: 'buyer',
                        searchable: true,
                        sortable: true,
                        label: 'Client'
                    },
                    {
                        searchable: false,
                        custom: true,
                        sortable: true,
                        field: 'price_paid',
                        label: 'Prix'
                    }
                ]
            }
        },
        mounted() {
            if (this.type === 'todo') {
                this.columns[1].visible = false
                this.columns[6].visible = false
                this.columns[7].visible = false
                this.columns[8].visible = false
            }
        },
        methods: {
            ...mapActions('spots', {
                getOrders: 'listOrders',
                addTitle: 'addTitle',
                sendToRedactor: 'sendToRedactor',
                getRemainingCredits: 'getRemainingCredits'
            }),
            ...mapActions('group', {
                getGroups: 'adminList'
            }),
            findGroupName(id) {
                let group = this.groups.find(group => group.id === id)
                return group ? group.name : ''
            },
            formatWPMeta(meta) {
                if (!meta) {
                    return null
                } else {
                    return JSON.parse(meta)
                }
            },
            updateOrder(type, id, value) {
                // remove extra spaces
                value = value.replace(/\s+/g, ' ').trim()

                let params = {
                    id: id
                }
                if (type === 'meta') {
                    params.meta_title = value
                } else if (type === 'tag') {
                    params.tag_title = value
                }
                this.addTitle(params)
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'Le titre a bien été modifié'
                        })
                    })
                    .catch(() => {
                        this.$store.dispatch('toast', {
                            type: 'error',
                            title: 'Une erreur est survenue'
                        })
                    })
            },
            bulkTitleUpdate() {
                this.$refs.titleModal.show(this.checkedRows)
            },
            updateBulkTitles(data) {
                let params = {
                    data: data,
                    action: 'titles'
                }
                this.sendToRedactor(params)
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'Les commandes ont bien été modifiées'
                        })
                        this.loadAsyncData()
                    })
                    .catch(() => {
                        this.$store.dispatch('toast', {
                            type: 'error',
                            title: 'Une erreur est survenue'
                        })
                    })
                    .finally(() => {
                        this.checkedRows = []
                    })
            },
            redactor() {
                let params = {
                    ids: this.checkedRows.map(row => row.id),
                    action: 'redactor'
                }
                this.sendToRedactor(params)
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'Les commandes ont bien été envoyées en rédaction'
                        })
                        this.loadAsyncData()
                    })
                    .catch(() => {
                        this.$store
                            .dispatch('toast', {
                                type: 'error',
                                title: 'Une erreur est survenue'
                            })
                            .finally(() => {
                                this.checkedRows = []
                            })
                    })
            },
            generateContentFromAI() {
                let params = {
                    ids: this.checkedRows.map(row => row.id),
                    action: 'ai'
                }
                this.sendToRedactor(params)
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'Les contenus sont en cours de génération par IA'
                        })
                        this.loadAsyncData()
                    })
                    .catch(() => {
                        this.$store.dispatch('toast', {
                            type: 'error',
                            title: 'Une erreur est survenue'
                        })
                    })
                    .finally(() => {
                        this.checkedRows = []
                    })
            },
            loadAsyncData(params) {
                this.getRemainingCredits().then(res => {
                    this.kpis[0].value = this.$options.filters.toCurrency(res)
                })

                return this.getOrders({
                    status: this.status,
                    params
                })
            }
        }
    }
</script>