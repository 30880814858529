<template>
    <nl-modal
        ref="modal"
        :title="`Ajouter des ancres spécifiques`"
        :add-legacy-events="false"
        position="center"
        size="md"
    >
        <div class="d-flex justify-content-between" style="gap: 1rem">
            <div class="w-50">
                <b-field label="Ancres spécifiques" class="min-width-input">
                    <b-input v-model="anchors" rows="15" type="textarea" placeholder="Ancre spécifique" />
                </b-field>
            </div>
            <div class="w-50">
                <b-field label="Nombre de RD par ancre">
                    <b-numberinput
                        controls-alignment="right"
                        controls-position="compact"
                        min="1"
                        v-model="nbRDByAnchor"
                        expanded
                    ></b-numberinput>
                </b-field>
            </div>
        </div>

        <template #footer>
            <b-button type="is-primary" @click="hide">Annuler</b-button>
            <b-button type="is-warning" :disabled="!(anchors.length > 0)" @click="onSave">Ajouter</b-button>
        </template>
    </nl-modal>
</template>

<script>
    export default {
        name: 'AddSpecificAnchorsModal',
        data() {
            return {
                showModal: true,
                anchors: '',
                nbRDByAnchor: 1
            }
        },
        methods: {
            show() {
                this.anchors = ''
                this.$refs.modal.show()
            },
            hide() {
                this.$refs.modal.hide()
            },
            onSave() {
                if (this.anchors.length > 0) {
                    this.$emit('addAnchors', {
                        rd_number: this.nbRDByAnchor,
                        anchors: this.anchors.split('\n').filter(anchor => anchor.length > 0)
                    })
                    this.hide()
                }
            }
        }
    }
</script>