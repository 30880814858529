import ApiService from '../../../services/api.service'

export default {
    getPreorders(context, { perPage }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/statistics-preorders/preorders?per_page=${perPage}`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getPreordersByCampaigns(context, { perPage }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/statistics-preorders/campaigns?per_page=${perPage}`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getPreordersBySites(context, { perPage }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/admin/statistics-preorders/sites?per_page=${perPage}`)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getPreordersByType(context, { type, id, perPage = 20 }) {
        let route =
            type === 'site'
                ? `/campaigns/preorders/site/${id}?per_page=${perPage}`
                : `/campaigns/preorders/${id}?per_page=${perPage}`

        return new Promise((resolve, reject) => {
            ApiService.get(route)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    loadAsyncData(context, { type, page, sortField, sortOrder, filters, perPage }) {
        let route =
            type === 'sites'
                ? `/admin/statistics-preorders/sites`
                : type === 'campaigns'
                ? `/admin/statistics-preorders/campaigns`
                : `/admin/statistics-preorders/preorders`
        let field = sortField

        if (sortOrder === 'desc') {
            field = '-' + sortField
        }

        let params = {
            page: page,
            sort: field,
            per_page: perPage
        }

        Object.keys(filters).forEach(keys => {
            if (filters[keys]) {
                params[`filter[${keys}]`] = filters[keys]
            }
        })

        return new Promise((resolve, reject) => {
            ApiService.get(route, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    loadAsyncSubTableData(context, { type, id, page }) {
        let route =
            type === 'site' ? `/campaigns/preorders/site/${id}?per_page=20` : `/campaigns/preorders/${id}?per_page=20`

        let params = {
            page: page
        }

        return new Promise((resolve, reject) => {
            ApiService.get(route, params)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
