<template>
    <b-modal v-model="active">
        <template>
            <header class="px-4 py-3 has-background-warning shadow-sm">
                <h4 class="h4 p-0 mb-0">Modification de commande</h4>
            </header>
            <form id="commande-modification-form" method="post" action="">
                <div class="modal-body">
                    <b-field label="Page">
                        <b-input v-model="content.url" type="text" expanded />
                    </b-field>
                    <b-field label="Lien 1">
                        <b-input v-model="content.link_url" type="text" expanded />
                    </b-field>
                    <b-field label="Ancré 1">
                        <b-input v-model="content.link_anchor" type="text" expanded />
                    </b-field>
                    <b-field label="Lien 2">
                        <b-input v-model="content.link_second_url" type="text" expanded />
                    </b-field>
                    <b-field label="Ancre 2">
                        <b-input v-model="content.link_second_anchor" type="text" expanded />
                    </b-field>
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <b-field label="Rédaction">
                                <b-select v-model="content.redaction" expanded>
                                    <option value="proprietaire">Partenaire</option>
                                    <option value="nextlevel">NextLevel</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="col-sm-6">
                            <b-field label="Contenu">
                                <b-select
                                    v-model="content.option_mots == null ? content.nb_words : content.option_mots"
                                    expanded
                                >
                                    <option value="0">200 mots</option>
                                    <option value="100">300 mots</option>
                                    <option value="200">400 mots</option>
                                    <option value="300">500 mots</option>
                                    <option value="400">600 mots</option>
                                    <option value="500">700 mots</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                    <b-field label="Brief">
                        <b-input v-model="content.brief" type="textarea" expanded />
                    </b-field>
                    <div class="is-flex align-items-center">
                        <b-radio v-model="content.date_publication_prevue" :native-value="null">
                            Publication dès que possible
                        </b-radio>
                        <b-radio
                            v-model="content.date_publication_prevue"
                            :native-value="
                                content.date_publication_prevue != null ? content.date_publication_prevue : ''
                            "
                        >
                            Publication planifiée
                        </b-radio>
                        <b-input
                            type="date"
                            :value="moment(content.date_publication_prevue).format('YYYY-MM-DD')"
                            :disabled="content.date_publication_prevue == null"
                            @input="setDate"
                        />
                    </div>
                    <b-checkbox
                        v-model="content.verification_ne_plus_verifier_code_reponse"
                        true-value="1"
                        false-value="0"
                    >
                        Empêcher la vérification régulière automatique du code réponse HTTP.
                    </b-checkbox>
                    <b-checkbox v-model="content.verification_ne_plus_verifier_liens" true-value="1" false-value="0">
                        Empêcher la vérification régulière automatique des liens et ancres postés.
                    </b-checkbox>
                    <b-checkbox
                        v-model="content.verification_ne_plus_verifier_autres_liens_externes"
                        true-value="1"
                        false-value="0"
                    >
                        Empêcher la vérification régulière automatique de la présence de liens externes interdits.
                    </b-checkbox>
                    <b-field label="Statut">
                        <b-select v-model="content.statut">
                            <option value="a_valider">A valider</option>
                            <option value="en_cours">A traiter</option>
                            <option value="termine">Terminé</option>
                            <option value="annule">Annulé</option>
                            <option value="refuse">Refusé</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="modal-footer">
                    <b-button type="button" class="btn btn-danger" @click="closeModal"> Annuler</b-button>
                    <b-button type="button" class="btn btn-primary" @click="update"> Sauvegarder</b-button>
                </div>
            </form>
        </template>
    </b-modal>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'ContentModal',
        data() {
            return {
                active: false,
                content: {}
            }
        },
        methods: {
            ...mapActions('orders', ['updateContent']),
            openModal(content) {
                this.content = Object.assign({}, content)
                this.active = true
            },
            closeModal() {
                this.active = false
                this.$emit('refresh')
            },
            update() {
                this.updateContent(this.content).then(() => {
                    this.closeModal()
                })
            },
            setDate(date) {
                this.content.date_publication_prevue = date
            }
        }
    }
</script>
