<template>
    <div>
        <div v-if="loaded" class="box">
            <b-table
                :data="managers.data"
                :default-sort="[config.sort, config.sortDirection]"
                :loading="isLoading"
                :row-class="row => row.role === 'manager' && 'is-manager-agency'"
                :mobile-cards="true"
                :per-page="config.per_page"
                :total="managers.total"
                backend-filtering
                backend-pagination
                backend-sorting
                paginated
                detailed
                detail-key="id"
                :opened-detailed="openedConsultants"
                custom-detail-row
                :has-detailed-visible="row => row.consultants.length > 0"
                @sort="onSort"
                @page-change="onPageChange"
                @filters-change="onFilterChange"
            >
                <b-table-column field="" label="Role" cell-class="align-middle">
                    <template v-slot="props">
                        <span v-if="props.row.role === 'manager'">
                            <b-tooltip label="Manager" class="text-info">
                                <i class="fa fa-users"/>
                            </b-tooltip>
                        </span>
                        <span v-if="props.row.role === 'consultant'">
                            <b-tooltip label="Consultant" class="is-info">
                                <i class="fa fa-user"/>
                            </b-tooltip>
                        </span>
                    </template>
                </b-table-column>
                <b-table-column field="lastname" sortable label="Nom" cell-class="align-middle">
                    <template v-slot="props">
                        <a href="#" @click.prevent="viewManager(props.row)">
                            {{ props.row.firstname }} {{ props.row.lastname }}
                        </a>
                        <b-tooltip
                            :label="'Se connecter au compte de ' + props.row.firstname + ' ' + props.row.lastname"
                            position="is-top"
                        >
                            <LoginAs :user-id="props.row.user_id" class="ml-auto"/>
                        </b-tooltip>
                    </template>
                </b-table-column>
                <b-table-column field="email" sortable label="Email" cell-class="align-middle">
                    <template v-slot="props">
                        {{ props.row.email }}
                    </template>
                </b-table-column>
                <b-table-column
                    field="credit_amount"
                    sortable
                    label="Crédits restant"
                    cell-class="align-middle"
                    numeric
                >
                    <template v-slot="props">
                        {{ props.row.credit_amount | toCurrency }}
                    </template>
                </b-table-column>
                <b-table-column field="consultants_count" label="Consultants" cell-class="align-middle" numeric>
                    <template v-slot="props">
                        {{ props.row.consultants.length || 0 }}
                    </template>
                </b-table-column>
                <b-table-column field="clients_count" label="Clients" cell-class="align-middle" numeric>
                    <template v-slot="props">
                        {{ props.row.clients_count || 0 }}
                    </template>
                </b-table-column>
                <b-table-column field="actions" label="Actions" cell-class="align-middle" numeric>
                    <template v-slot="props">
                        <div class="d-flex justify-content-end text-left">
                            <ModifyCredits v-model="props.row" @reload="fetchData"/>
                            <EditEmployeeButton :target="props.row" type="manager" @reload="doReload"/>
                            <RemoveEmployeeButton :target="props.row" @reload="doReload"/>
                        </div>
                    </template>
                </b-table-column>

                <template slot="detail" slot-scope="props">
                    <ConsultantRow
                        v-for="consultant in props.row.consultants"
                        :key="`${consultant.manager_id}-${consultant.id}`"
                        :consultant="consultant"
                    >
                        <div class="d-flex justify-content-end text-left">
                            <ModifyCredits :target="consultant" @reload="fetchData"/>
                            <EditEmployeeButton :target="consultant" type="consultant" @reload="fetchData"/>
                            <RemoveEmployeeButton :target="props.row" @reload="doReload"
                                                  v-if=" ['agence', 'admin'].includes(me.role) "/>
                        </div>
                    </ConsultantRow>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import ModifyCredits from '@/components/Agency/Button/ModifyCredits.vue'
import {mapActions, mapState} from 'vuex'
import ConsultantRow from './ConsultantRow'
import EditEmployeeButton from '../Button/EditEmployeeButton'
import RemoveEmployeeButton from '../Button/RemoveAgencyUserButton.vue'
import LoginAs from '@/components/Admin/LoginAs.vue'

export default {
    components: {
        LoginAs,
        EditEmployeeButton,
        ConsultantRow,
        ModifyCredits,
        RemoveEmployeeButton,
    },
    data: () => ({
        rows_per_page: 15,
        filters: null,
        sort: null,
        sortOrder: null,
        page: null,
        checkedRows: [],
        isLoading: true,
        loaded: false,
        openedConsultants: []
    }),
    computed: {
        ...mapState('consultants', {
            managers: 'managers',
            config: 'managersConfig'
        }),
        me() {
            return this.$store.state.auth.user
        }
    },
    methods: {
        ...mapActions('auth', {getUser: 'me'}),
        ...mapActions('consultants', {
            createConsultant: 'create',
            updateConsultant: 'update',
            enabledConsultant: 'enabled',
            disablesConsultant: 'disabled',
            addCreditsToConsultant: 'addCredits',
            removeCreditsToConsultant: 'removeCredits',
            bulkAddCreditsToConsultants: 'bulkAddCredits',
            getManagers: 'getManagers'
        }),
        viewManager(manager) {
            this.$router.push(`/agency/managers?id=${manager.id}`)
        },
        viewConsultant(consultant) {
            this.$router.push(`/consultant-details.php?id=${consultant.id}`)
        },
        fetchData() {
            this.isLoading = true
            this.getManagers()
                .catch(e => {
                    this.$store.dispatch('toastError', e)
                })
                .finally(() => {
                    this.isLoading = false
                    this.loaded = true

                    if (this.managers && this.managers.data && this.managers.data.length > 0) {
                        if (this.openedConsultants.length <= 0) {
                            this.openedConsultants = [this.managers.data[0].id]
                        }
                    }
                })
        },
        onSort(field, order) {
            this.config.sort = field
            this.config.sortDirection = order

            this.fetchData()
        },
        onFilterChange(filter) {
            var filters = {}
            Object.keys(filter).forEach(element => {
                filters[element] = filter[element]
            })
            this.config.filters = filters

            this.fetchData()
        },
        onPageChange(page) {
            this.config.page = page

            this.fetchData()
        },
        doReload() {
            this.$emit('reload')
        }
    },
    mounted() {
        this.fetchData()
    }
}
</script>

<style>
::v-deep(.is-manager-agency) {
    background-color: #ebeced;
}
</style>
