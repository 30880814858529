<template>
    <div class="mr-auto">
        <div class="float-left mr-3">
            <Paginate
                v-model="paginate.page"
                :click-handler="changePage"
                :page-count="paginate.numberPage"
                :page-range="paginate.nbPageDisplay"
                :prev-text="'Précédent'"
                :prev-class="'prev'"
                :prev-link-class="'page-link'"
                :next-text="'Suivant'"
                :next-class="'next'"
                :next-link-class="'page-link'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
            />
        </div>
        <div class="float-left">
            <div class="input-group">
                <div class="btn-group">
                    <button
                        type="button"
                        :class="['btn btn-lignes-par-page', 15 === paginate.nbResults ? 'btn-primary' : 'page-link']"
                        @click="changeNbResults(15)"
                    >
                        15
                    </button>
                    <button
                        type="button"
                        :class="['btn btn-lignes-par-page', 30 === paginate.nbResults ? 'btn-primary' : 'page-link']"
                        @click="changeNbResults(30)"
                    >
                        30
                    </button>
                    <button
                        type="button"
                        :class="['btn btn-lignes-par-page', 50 === paginate.nbResults ? 'btn-primary' : 'page-link']"
                        @click="changeNbResults(50)"
                    >
                        50
                    </button>
                    <button
                        type="button"
                        :class="['btn btn-lignes-par-page', 100 === paginate.nbResults ? 'btn-primary' : 'page-link']"
                        @click="changeNbResults(100)"
                    >
                        100
                    </button>
                    <button
                        type="button"
                        :class="['btn btn-lignes-par-page', 500 === paginate.nbResults ? 'btn-primary' : 'page-link']"
                        @click="changeNbResults(500)"
                    >
                        500
                    </button>
                </div>
                <div class="input-group-append">
                    <span class="input-group-text">{{ $t('global.elementbypage') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Paginate from 'vuejs-paginate'

    export default {
        name: 'Pagination',
        components: {
            Paginate
        },
        props: {
            paginate: Object
        },
        data: function () {
            return {}
        },
        created() {},
        mounted() {},
        methods: {
            changePage: function () {
                this.$emit('change-page', this.paginate.page)
            },
            changeNbResults: function (nbResult) {
                this.$emit('change-nb-result', nbResult)
            }
        }
    }
</script>
