import { isArray } from 'lodash'

export function extractValue(obj, path) {
    return path.split('.').reduce((o, i) => (o ? o[i] : null), obj)
}

export function cleanObject(obj) {
    return Object.fromEntries(
        Object.entries(obj).filter(([, v]) => v !== null && v !== '' && (!isArray(v) || v.length))
    )
}
