import i18n from '@/i18n/i18n'
import store from '@/store'

function getTitle(vm) {
    const { title } = vm.$options
    if (title) {
        return typeof title === 'function' ? title.call(vm) : title
    }
}

export default {
    created() {
        const title = getTitle(this)

        if (title) {
            this.setTitle(title)
        }
    },
    methods: {
        setTitle(title) {
            let translatedTitle = i18n.te(title) ? i18n.t(title) : title

            document.title = `NextLevel - ${translatedTitle}`
            store.commit('setTitle', translatedTitle)
        }
    }
}
