import ApiService from '../../../services/api.service'

export default {
    get(context) {
        return new Promise((resolve, reject) => {
            ApiService.get('/user')
                .then(({ data }) => {
                    context.commit('setUser', data)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    update(context, model) {
        return new Promise((resolve, reject) => {
            ApiService.put('/user', model)
                .then(({ data }) => {
                    context.commit('setUser', data.user)
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    }
}
