import alert from '@/store/modules/alert'
import campaigns from '@/store/modules/campaigns'
import client_preorders from '@/store/modules/client_preorders'
import config from '@/store/modules/config'
import contents from '@/store/modules/contents'
import favorites from '@/store/modules/favorites'
import group from '@/store/modules/group'
import invoices from '@/store/modules/invoices'
import notifications from '@/store/modules/notifications'
import partner_sites from '@/store/modules/partner_sites'
import projects from '@/store/modules/projects'
import search from '@/store/modules/search'
import tracking_order from '@/store/modules/tracking_order'
import moment from 'moment/moment'
import * as Toastr from 'toastr'
import Vue from 'vue'
import FlagIcon from 'vue-flag-icon'
import Vuex from 'vuex'

import i18n from '../i18n/i18n.js'
import modules from './modules'
import auth from './modules/auth'
import orders from './modules/orders'
import project_selector from './modules/project_selector'
import redactors from './modules/redactors'
import sites from './modules/sites'
import keyword from '@/store/modules/keyword'
import urls from '@/store/modules/urls'
import NlStorage from '@/services/nl.storage'

Vue.use(Vuex)
Vue.use(FlagIcon)

export default new Vuex.Store({
    state: {
        isMobile: false,
        locale: null,
        project: null,
        projectEdit: null,
        title: 'Nextlevel',
        homepage: '/client',

        isMenuOpen: NlStorage.getItem('isMenuOpen') ? JSON.parse(NlStorage.getItem('isMenuOpen')) : false,

        isPartnerMenuOpen: NlStorage.getItem('isPartnerMenuOpen')
            ? JSON.parse(NlStorage.getItem('isPartnerMenuOpen'))
            : this?.auth.user?.role === 'partenaire',

        isAdminMenuOpen: NlStorage.getItem('isAdminMenuOpen')
            ? JSON.parse(NlStorage.getItem('isAdminMenuOpen'))
            : this?.auth.user?.role === 'admin',

        subMenuOpen: NlStorage.getItem('subMenuOpen'),

        toaster: {
            closeButton: false,
            debug: false,
            newestOnTop: true,
            progressBar: true,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            onclick: null,
            showDuration: 300,
            hideDuration: 100,
            timeOut: 3500,
            extendedTimeOut: 1000,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut'
        },
        GTCModalOpened: false
    },
    mutations: {
        setProjectEdit: (state, project) => (state.projectEdit = project),
        setLocale: (state, locale) => {
            if (locale === 'us') {
                locale = 'en'
            }

            i18n.locale = locale
            moment.locale(locale)
            state.locale = locale
        },
        setHomepage: (state, homepage) => {
            state.homepage = homepage
        },
        setProject: (state, project) => {
            NlStorage.setItem('project', JSON.stringify(project))
            state.project = project
        },
        setTitle: (state, title) => {
            state.title = title
        },
        setIsMenuOpen: (state, isOpen) => {
            NlStorage.setItem('isMenuOpen', JSON.stringify(isOpen))
            state.isMenuOpen = isOpen
        },
        setIsPartnerMenuOpen: (state, isOpen) => {
            NlStorage.setItem('isPartnerMenuOpen', JSON.stringify(isOpen))
            state.isPartnerMenuOpen = isOpen
        },
        setIsAdminMenuOpen: (state, isOpen) => {
            NlStorage.setItem('isAdminMenuOpen', JSON.stringify(isOpen))
            state.isAdminMenuOpen = isOpen
        },
        setSubMenuOpen: (state, subMenu = null) => {
            NlStorage.setItem('subMenuOpen', subMenu)
            state.subMenuOpen = subMenu
        },
        setIsMobile: (state, isOpen) => {
            state.isMobile = isOpen
        },
        toggleGTCModal(state, value = true) {
            state.GTCModalOpened = value
        }
    },
    getters: {
        project(state) {
            return state.project
        },
        navigatorLanguage() {
            return navigator.language.substring(0, 2) === 'en' ? 'en' : 'fr'
        }
    },
    actions: {
        initialiseStore(context) {
            let project = NlStorage.getItem('project')
            let userStr = NlStorage.getItem('user')

            if (userStr) {
                this.dispatch('auth/getInfos')
                let user = JSON.parse(userStr)
                context.commit('auth/setUser', user)
                context.commit('setLocale', user.locale)

                const homePages = {
                    admin: '/admin/orders',
                    administratif: '/admin/withdrawal-requests',
                    redacteur: '/redactor/details?id=' + user.id,
                    agence: '/agencies',
                    manager: '/agency/managers',
                    consultant: '/agency/consultants',
                    prospect: '/client',
                    partenaire: '/partner'
                }

                if (typeof homePages[user.role] !== 'undefined') {
                    context.commit('setHomepage', homePages[user.role])
                }
            } else {
                context.commit('setLocale', process.env.VUE_APP_LOCALE)
            }

            if (project) {
                context.state.project = JSON.parse(project)
                if (
                    context.state.auth.user &&
                    context.state.project &&
                    context.state.auth.user.id !== context.state.project.user_id
                ) {
                    context.state.project = null
                    NlStorage.setItem('project', JSON.stringify({}))
                }
            } else if (!project && userStr) {
                void context.dispatch('project_selector/projects')
                void context.dispatch('project_selector/getDefaultProject')
            }
        },
        setMenuPreference(context) {
            context.commit('setIsPartnerMenuOpen', context.state.auth.user.role === 'partenaire')
            context.commit('setIsAdminMenuOpen', context.state.auth.user.role === 'admin')
        },
        toast: (state, data) => {
            Toastr.options = state.toaster
            Toastr[data.type](data.message, data.title)
        },
        toastError: ({ dispatch }, error) => {
            let title = i18n.t('error.message.default')
            let message = error?.message || ''

            if (error?.status && error?.data) {
                switch (error.status) {
                    case 400:
                    case 401:
                    case 403:
                        message = error.data?.message || ''
                        break

                    case 422:
                        title = i18n.t('error.message.validation')
                        message = ''

                        Object.keys(error.data.errors).forEach(item => {
                            if (i18n.te(`validation.errors.${item}`)) {
                                message += `${i18n.t(`validation.errors.${item}`)} : `
                            } else {
                                message += `${item} : `
                            }

                            for (let i = 0; i < error.data.errors[item].length; i++) {
                                if (i > 0) message += ', '
                                if (i18n.te(error.data.errors[item][i])) {
                                    message += i18n.t(error.data.errors[item][i])
                                } else {
                                    message += `${error.data.errors[item][i]}`
                                }
                            }
                        })
                        break

                    case 500:
                        title = error.data?.message || i18n.t('error.message.server')
                        break
                }
            }

            void dispatch('toast', {
                type: 'error',
                title: title,
                message: message
            })
        },
        prepareParams(context, config) {
            let field = config.sort

            if (config.sortDirection === 'desc') {
                field = '-' + field
            }

            let params = {
                page: config.page,
                include: config.include,
                sort: field,
                per_page: config.per_page
            }

            if (config.filters) {
                for (const [key, value] of Object.entries(config.filters)) {
                    params[`filter[${key}]`] = value
                }
            }

            return params
        },
        resetMenu(context) {
            context.commit('setIsPartnerMenuOpen', false)
            context.commit('setIsAdminMenuOpen', false)
        }
    },
    modules: {
        ...modules,
        alert,
        auth,
        campaigns,
        client_preorders,
        config,
        contents,
        favorites,
        group,
        invoices,
        keyword,
        notifications,
        orders,
        partner_sites,
        project_selector,
        projects,
        redactors,
        search,
        sites,
        tracking_order,
        urls
    }
})
