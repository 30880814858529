<template>
    <div class="d-flex flex-column" style="gap: 0.5rem">
        <slot v-if="!removeHeader" name="header">
            <b-field grouped group-multiline>
                <div class="control" v-for="kpi in kpis" :key="kpi.label">
                    <b-taglist attached>
                        <b-tag type="is-warning" size="is-medium">
                            {{ kpi.label }}
                        </b-tag>
                        <b-tag type="is-dark" size="is-medium">
                            {{ kpi.value }}
                        </b-tag>
                    </b-taglist>
                </div>
            </b-field>
            <div class="actions-container">
                <b-tooltip
                    v-for="button in leftButtons"
                    :key="button.label"
                    :label="button.tooltip"
                    :active="!!button.tooltip"
                >
                    <b-button
                        :disabled="button.disabled"
                        :icon-left="button.icon"
                        :icon-pack="button.iconPack"
                        :label="button.label"
                        :type="button.type || 'is-warning'"
                        @click="button.action"
                    />
                </b-tooltip>
                <div style="margin-left: auto">
                    <per-page-selector
                        v-if="canChangePerPage"
                        v-model="meta.per_page"
                        :loading="isLoading || loading"
                        @perPageChange="loadAsyncData"
                    />
                </div>
                <table-column-select
                    v-if="canSelectColumn"
                    :auto-set-visible="false"
                    :value="columns"
                    @input="e => $emit('update:columns', e)"
                />
                <table-actions
                    v-if="Boolean(actions && actions.length) && $screen.width > 768"
                    :actions="actions"
                    :disabled="checkedRows.length <= 0"
                />
                <b-button
                    v-for="button in rightButtons"
                    :key="button.label"
                    :disabled="button.disabled"
                    :icon-left="button.icon"
                    :icon-pack="button.iconPack"
                    :label="button.label"
                    :type="button.type || 'is-warning'"
                    style="justify-self: end"
                    @click="button.action"
                />
            </div>
        </slot>
        <b-table
            mobile-cards
            :loading="isLoading || loading"
            :checkable="(Boolean(actions && actions.length) && $screen.width > 768) || checkable"
            :checked-rows="checkedRows"
            :is-row-checkable="isRowCheckable"
            :custom-is-checked="(props, checkedRow) => props.id === checkedRow.id"
            :data="data || asyncData"
            :current-page="meta.current_page"
            :paginated="true"
            :per-page="meta.per_page"
            :total="meta.total"
            :backend-pagination="async"
            pagination-rounded
            :default-sort="defaultSort"
            :default-sort-direction="defaultSortDirection"
            :backend-sorting="async"
            :backend-filtering="async"
            :debounce-search="500"
            :detailed="detailed"
            custom-detail-row
            :narrowed="narrowed"
            :striped="striped"
            @check="value => $emit('update:checked-rows', value)"
            @page-change="onPageChange"
            @sort="onSort"
            @filters-change="onFilterChange"
        >
            <b-table-column
                v-for="column in columns"
                :key="column.field"
                :cell-class="column.cellClass"
                :centered="column.centered && $screen.width > 768"
                :field="column.field"
                :label="column.label"
                :searchable="column.searchable"
                :sortable="column.sortable"
                :visible="column.visible && !column.hide"
                :width="column.width"
                style="min-width: 500px"
            >
                <template #searchable="props">
                    <slot v-if="column.customSearch" :name="`${column.field}Search`" :props="props" />
                    <b-input
                        v-else
                        v-model="props.filters[column.searchField || column.field]"
                        :type="column.inputType"
                        :placeholder="column.placeholder || column.label"
                        size="is-small"
                    />
                </template>
                <template #default="props">
                    <slot v-if="column.custom" :name="getSlotName(column.field)" :props="props" />
                    <NlTableCell
                        v-else
                        :centered="column.centered"
                        :default-value="column.defaultValue"
                        :field-option="column.fieldOption"
                        :filter="column.filter"
                        :prefix="column.prefix"
                        :suffix="column.suffix"
                        :type="column.type"
                        :value="extractValue(props.row, column.field)"
                    />
                </template>
            </b-table-column>
            <template v-if="detailed" #detail="props">
                <tr>
                    <td colspan="100%" class="p-2">
                        <slot name="detailed" :props="props"></slot>
                    </td>
                </tr>
            </template>
        </b-table>
        <slot name="footer" />
    </div>
</template>

<script>
    import PerPageSelector from './PerPageSelector'
    import TableColumnSelect from './TableColumnSelect'
    import TableActions from './TableActions'
    import NlTableCell from '@/components/UI/Table/NlTableCell.vue'
    import { extractValue } from '@/helpers/objectHelpers'

    export default {
        name: 'NlTable',
        components: {
            NlTableCell,
            PerPageSelector,
            TableActions,
            TableColumnSelect
        },
        props: {
            kpis: {
                type: Array,
                required: false
            },
            actions: {
                type: Array,
                required: false
            },
            async: {
                type: Boolean,
                required: false,
                default: true
            },
            centered: {
                type: Boolean,
                required: false,
                default: true
            },
            checkable: {
                type: Boolean,
                required: false,
                default: false
            },
            isRowCheckable: {
                type: Function,
                required: false
            },
            checkedRows: {
                type: Array,
                required: false,
                default: () => []
            },
            columns: {
                type: Array,
                required: true
            },
            data: {
                type: Array,
                required: false
            },
            defaultSort: {
                type: String,
                required: false,
                default: 'id'
            },
            defaultSortDirection: {
                type: String,
                required: false,
                default: 'desc'
            },
            detailed: {
                type: Boolean,
                required: false
            },
            detailedKey: {
                type: String,
                required: false
            },
            getData: {
                type: Function,
                required: false
            },
            forceUpdate: {
                required: false
            },
            leftButtons: {
                type: Array,
                required: false
            },
            loading: {
                type: Boolean,
                required: false
            },
            rightButtons: {
                type: Array,
                required: false
            },
            searchable: {
                type: Boolean,
                required: false,
                default: true
            },
            sortable: {
                type: Boolean,
                required: false,
                default: true
            },
            visible: {
                type: Boolean,
                required: false,
                default: true
            },
            narrowed: {
                type: Boolean,
                required: false,
                default: false
            },
            striped: {
                type: Boolean,
                required: false,
                default: true
            },
            canSelectColumn: {
                type: Boolean,
                required: false,
                default: true
            },
            canChangePerPage: {
                type: Boolean,
                required: false,
                default: true
            },
            removeHeader: {
                type: Boolean,
                required: false
            }
        },
        data: function () {
            return {
                meta: {
                    current_page: 1,
                    per_page: 25,
                    total: 0
                },
                sort: null,
                filters: {},

                isLoading: false,

                asyncData: []
            }
        },
        computed: {
            filteredColumns() {
                return this.columns.filter(column => column.visible && !column.hide)
            }
        },
        watch: {
            forceUpdate() {
                this.loadAsyncData()
            }
        },
        created() {
            this.$emit(
                'update:columns',
                this.columns.map(column => {
                    return {
                        centered: this.centered,
                        searchable: this.searchable,
                        sortable: this.sortable,
                        visible: this.visible,
                        ...column
                    }
                })
            )
            this.onSort(this.defaultSort, this.defaultSortDirection)
        },
        methods: {
            extractValue,
            loadAsyncData() {
                this.$emit('loadAsyncData')
                if (this.async) {
                    this.isLoading = true
                    this.getData({
                        filter: this.filters,
                        page: this.meta.current_page,
                        per_page: this.meta.per_page,
                        sort: this.sort
                    })
                        .then(({ data, total, per_page, current_page }) => {
                            this.$emit('then', data)
                            this.asyncData = data
                            this.meta = {
                                ...this.meta,
                                ...{
                                    total: Number(total),
                                    per_page: Number(per_page),
                                    current_page: Number(current_page)
                                }
                            }
                        })
                        .catch(e => {
                            this.$emit('catch', e)
                            console.error(e)
                        })
                        .finally(() => {
                            this.$emit('finally')
                            this.isLoading = false
                        })
                }
            },

            onPageChange(page) {
                this.meta.current_page = page
                this.loadAsyncData()
            },

            onSort(field, order) {
                if (order === 'desc') {
                    this.sort = '-' + field
                } else {
                    this.sort = field
                }
                this.loadAsyncData()
            },

            onFilterChange(filters) {
                this.filters = filters
                this.loadAsyncData()
                this.$emit('filtersChanged', this.filters)
            },

            getSlotName(name) {
                return name
                    .split('.')
                    .map((value, key) => {
                        return key > 0 ? value.charAt(0).toUpperCase() + value.slice(1) : value
                    })
                    .join('')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .actions-container {
        padding: 0.5rem 0;
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
    }

    @media screen and (max-width: 768px) {
        .actions-container {
            display: flex;

            > ::v-deep * {
                flex: 1 1 125px;
            }
        }
    }
</style>