<template>
    <div>
        <a role="button" @click="sort(field, 'DESC')">↓</a>
        <a role="button" @click="sort(field, 'ASC')">↑</a>
    </div>
</template>

<script>
    export default {
        name: 'Sort',
        components: {},
        props: {
            field: String
        },
        data: function () {
            return {}
        },
        created() {},
        mounted() {},
        methods: {
            sort(field, order) {
                this.$emit('sort-request', field, order)
            }
        }
    }
</script>
