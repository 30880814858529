var render = function render(){var _vm=this,_c=_vm._self._c;return (!this.isAdminMenuOpen)?_c('div',{staticClass:"d-flex align-items-center"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(
                ['prospect', 'agence', 'partenaire', 'manager', 'consultant', 'admin'].includes(_vm.role) &&
                _vm.credits <= 0 &&
                _vm.displayBalance === 'credits'
            )?_c('div',{staticClass:"nav-item"},[_c('router-link',{staticClass:"buy_credits",attrs:{"to":"/buy-credits"}},[_c('i',{staticClass:"fas fa-cart-plus mr-2"}),_vm._v(" "+_vm._s(_vm.$t('mes-credits.buycredits'))+" ")])],1):(
                ['prospect', 'agence', 'partenaire', 'manager', 'consultant', 'admin'].includes(_vm.role) &&
                _vm.credits > 0 &&
                _vm.displayBalance === 'credits'
            )?_c('div',{staticClass:"nav-item"},[(!_vm.isSidebar)?_c('router-link',{staticClass:"balance__item",attrs:{"to":"/credits"}},[_c('div',{staticClass:"balance__subitem"},[_c('span',[_vm._v(_vm._s(_vm.$t('profil.sponsorship.credit')))]),(_vm.credits)?_c('span',[_vm._v(_vm._s(_vm.credits.toFixed(2)))]):_c('b-skeleton',{staticClass:"mt-3",attrs:{"animated":""}})],1),_c('i',{staticClass:"fas fa-euro-sign balance__icon"})]):_c('router-link',{staticClass:"text-white",attrs:{"to":"/credits"}},[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('profil.sponsorship.credit'))+" :  ")]),(_vm.credits)?_c('span',[_vm._v(_vm._s(_vm.credits.toFixed(2))+" €")]):_c('b-skeleton',{staticClass:"mt-3",attrs:{"animated":""}})],1)])],1):(this.$parent.checkRole('partenaire') === true && _vm.displayBalance === 'benefits')?_c('div',{staticClass:"nav-item"},[(!_vm.isSidebar)?_c('router-link',{staticClass:"balance__item",attrs:{"to":"/partner/earnings"}},[_c('div',{staticClass:"balance__subitem"},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.gains')))]),(_vm.benefits || typeof _vm.benefits === 'number')?_c('span',[_vm._v(_vm._s(_vm.benefits.toFixed(2)))]):_c('b-skeleton',{attrs:{"animated":""}})],1),_c('i',{staticClass:"fas fa-euro-sign balance__icon"})]):_c('router-link',{staticClass:"text-white",attrs:{"to":"/credits"}},[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('layout.gains'))+"  :  ")]),(_vm.benefits || typeof _vm.benefits === 'number')?_c('span',[_vm._v(_vm._s(_vm.benefits.toFixed(2))+" €")]):_c('b-skeleton',{staticClass:"mt-3",attrs:{"animated":""}})],1)])],1):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }